import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { unitFormatter, showPrice } from 'common/utilFunctions';
import { useOrders } from 'context/order/ordersContext';
import StarRateIcon from '@material-ui/icons/StarRate';
import { getShopTotalRating } from 'common/utilFunctions';

const OVER_ALL_FIELDS = [
  'receivedOrders',
  'deliveredOrders',
  'notDeliveredOrders',
  'ratings'
];

const STATUS_AND_COLORS = {
  receivedOrders: '#ADD8E6',
  deliveredOrders: '#B0E57C ',
  notDeliveredOrders: '#FFA07A',
  ratings: '#FFFF99'
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '3rem',
    borderBottom: '1px solid #444',
    paddingBottom: '2rem'
  },
  metricContainer: {
    border: '1px solid #444',
    padding: '1rem',
    borderRadius: '8px',
    textAlign: 'center',
    color: '#444'
  },
  orderCount: {
    textAlign: 'center',
    width: '100%',
    fontWeight: '500',
    fontSize: 40,
    lineHeight: '3rem'
  },
  orderStatus: {
    textAlign: 'center',
    width: '100%',
    marginBottom: '0.5rem',
    paddingBottom: '0.5rem',
    borderBottom: '1px solid #444',
    fontSize: 18
  },
  revenue: {
    marginTop: '1.5rem',
    fontSize: 22,
    backgroundColor: '#4CAF50',
    color: '#fff',
    padding: '1rem',
    borderRadius: 8,
    width: '100%',
    textAlign: 'center'
  }
}));

const ShopOverAllMetrics = ({ selectedShop }) => {
  const classes = useStyles();
  const { orders } = useOrders();

  const receivedOrders = orders?.length;
  const deliveredOrders = orders.filter((order) => order.status === 'delivered')
    ?.length;
  const notDeliveredOrders = orders.filter(
    (order) => order.status === 'declined' || order.status === 'cancelled'
  )?.length;

  const ratings =
    selectedShop?.reviews?.items.length > 0
      ? getShopTotalRating(selectedShop.reviews.items)
      : 0;
  const revenue =
    orders
      ?.filter((order) => order.paymentStatus === 'paid')
      ?.reduce((total, curr) => {
        return (total += curr.total || 0);
      }, 0) || 0;

  return (
    <Grid container className={classes.root}>
      <Typography variant="h4" style={{ marginBottom: '1rem' }}>
        Overall Performance
      </Typography>

      <Grid container justify="space-between" style={{ gap: '1rem' }}>
        {OVER_ALL_FIELDS.map((item) => {
          return (
            <Grid
              container
              item
              xs={12}
              sm={3}
              md={2}
              key={`order-${item}`}
              justify="center"
              className={classes.metricContainer}
              style={{
                backgroundColor: `${STATUS_AND_COLORS[item]}`
              }}>
              <Typography variant="h6" className={classes.orderStatus}>
                {unitFormatter(item)}
              </Typography>
              <Typography className={classes.orderCount}>
                {item === 'receivedOrders'
                  ? receivedOrders
                  : item === 'deliveredOrders'
                  ? deliveredOrders
                  : item === 'notDeliveredOrders'
                  ? notDeliveredOrders
                  : item === 'ratings'
                  ? ratings
                  : null}
                {item === 'ratings' ? <StarRateIcon fontSize="large" /> : null}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
      <Typography variant="h4" className={classes.revenue}>
        Total Revenue : {showPrice(revenue)}
      </Typography>
    </Grid>
  );
};

export default ShopOverAllMetrics;
