import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid
} from '@material-ui/core';
import { StatusShopCounts, TotalShops, ShopsCharts } from './components';
import GoogleMapReact from 'google-map-react';
import LOCATIONS_DATA from './constants/locationsData';
import DashboardMarker from './components/DasboardMarker/DashboardMarker';
import { API, graphqlOperation } from 'aws-amplify';
import { listShops } from 'context/shop/shopsQueries';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}));

function createMapOptions(maps) {
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.LEFT_TOP,
      style: maps.ZoomControlStyle.SMALL
    }
  };
}

const Dashboard = () => {
  const [shops, setShops] = useState();
  const classes = useStyles();

  useEffect(() => {
    let mounted = true;
    API.graphql(graphqlOperation(listShops, { limit: 8000 })).then((data) => {
      const shops = data.data.listShops.items;
      mounted && setShops(shops);
    });
    return () => {
      mounted = false;
    };
  }, []);

  const pendingShops = shops?.filter(
    (shop) => shop.status === 'pending' || !shop.status
  );
  const activeShops = shops?.filter(
    (shop) => shop.status === 'active' && shop.isLive
  );
  const offlineShops = shops?.filter(
    (shop) => shop.status === 'active' && !shop.isLive
  );
  const otherShops = shops?.filter(
    (shop) =>
      shop.status !== 'pending' &&
      shop.status !== 'active' &&
      shop.status !== null
  );

  const defaultProps = {
    center: {
      lat: 51.509865,
      lng: -0.118092
    },
    zoom: 12
  };

  const storeLocation = {
    lat: 51.509865,
    lng: -0.118092
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item lg={2} sm={6} xl={2} xs={12}>
          <StatusShopCounts title="Pending" numbers={pendingShops?.length} />
        </Grid>
        <Grid item lg={2} sm={6} xl={2} xs={12}>
          <StatusShopCounts title="Active" numbers={activeShops?.length} />
        </Grid>
        <Grid item lg={2} sm={6} xl={2} xs={12}>
          <StatusShopCounts title="Offline" numbers={offlineShops?.length} />
        </Grid>
        <Grid item lg={2} sm={6} xl={2} xs={12}>
          <StatusShopCounts title="Other" numbers={otherShops?.length} />
        </Grid>
        <Grid item lg={4} sm={6} xl={4} xs={12}>
          <TotalShops title="Total Shops" numbers={shops?.length} />
        </Grid>
        <Grid item lg={8} md={12} xl={9} xs={12}>
          <Card>
            <CardHeader title="London" />
            <Divider />
            <CardContent style={{ height: '500px', width: '100%' }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
                center={storeLocation}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                options={createMapOptions}>
                {LOCATIONS_DATA.map((data, i) => {
                  return (
                    <DashboardMarker
                      lat={data.lat}
                      lng={data.lng}
                      area={data.area}
                      text={data.text}
                      postalCode={data.postalCode}
                      range={data.range}
                      key={i}
                    />
                  );
                })}
              </GoogleMapReact>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={4} md={6} xl={3} xs={12}>
          <ShopsCharts
            pendingShops={pendingShops?.length}
            activeShops={activeShops?.length}
            onHoldShops={offlineShops?.length}
            otherShops={otherShops?.length}
          />
        </Grid>
        {/* <Grid item lg={4} md={6} xl={3} xs={12}>
          <LatestProducts />
        </Grid>
        <Grid item lg={8} md={12} xl={9} xs={12}>
          <LatestOrders />
        </Grid> */}
      </Grid>
    </div>
  );
};

export default Dashboard;
