export const customMessage = /* GraphQL */ `
  {
    id
    orderID
    sender
    shopID
    message
    messageIntent
    photo {
      photoURL
      thumbURL
      MIMETypes
    }
    status
    notificationID
    readAt
    createdAt
    order {
      id
      shopID
      orderID
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    senderDetails {
      id
      picture
      username
      firstName
      lastName
      title
      email
    }
  }
`;
export const customMessageNotification = /* GraphQL */ `
  {
    id
    userID
    shopID
    orderID
    messageID
    isRead
    createdAt
    updatedAt
    message {
      id
      sender
      orderID
      message
      status
      readAt
      senderDetails {
        picture
        firstName
        lastName
        title
      }
      order {
        orderID
        status
      }
      _version
      _deleted
    }
    _version
    _deleted
    _lastChangedAt
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) ${customMessage}
  }
`;
export const getMessageNotification = /* GraphQL */ `
  query GetMessageNotification($id: ID!) {
    getMessageNotification(id: $id) ${customMessageNotification}
  }
`;
export const getMessageNotificationByUser = /* GraphQL */ `
  query GetMessageNotificationByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessageNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessageNotificationByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${customMessageNotification}
      nextToken
      startedAt
    }
  }
`;
export const getMessageNotificationByShop = /* GraphQL */ `
  query GetMessageNotificationByShop(
    $shopID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessageNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessageNotificationByShop(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${customMessageNotification}
      nextToken
      startedAt
    }
  }
`;
