import React from 'react';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const ShopsPerformanceOrderFilters = ({
  filterValue,
  statusFilterHandler,
  clearStatusFilter,
  monthRange,
  handleMonthChange,
  resetHandler,
  currentMonthYear
}) => {
  const handleDateRangeUpdate = (e) => {
    handleMonthChange({
      target: {
        name: e.target.name,
        value: e.target.value
      }
    });
  };

  return (
    <Grid container>
      <Typography variant="h4" style={{ marginBottom: '1rem' }}>
        Monthly Performance
      </Typography>

      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ gap: '1rem' }}>
        {/* Order status filter */}

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Order status"
            margin="none"
            size="small"
            name={'Order status'}
            select
            onChange={statusFilterHandler}
            value={filterValue}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {filterValue ? (
                    <IconButton
                      size="small"
                      edge="end"
                      style={{ marginRight: '0.5rem' }}
                      onClick={clearStatusFilter}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </InputAdornment>
              )
            }}>
            <MenuItem value="newOrder">New Order</MenuItem>
            <MenuItem value="accepted">Accepted</MenuItem>
            <MenuItem value="itemized">Itemized</MenuItem>
            <MenuItem value="inProgress">In Progress</MenuItem>
            <MenuItem value="readyForDelivery">Ready For Delivery</MenuItem>
            <MenuItem value="outForDelivery">Out For Delivery</MenuItem>
            <MenuItem value="delivered">Delivered</MenuItem>
            <MenuItem value="declined">Declined</MenuItem>
            <MenuItem value="cancelled">Cancelled</MenuItem>
          </TextField>
        </Grid>
        {/* Order month range filter */}
        <Grid container item xs={12} sm={6}>
          <Grid item xs={5}>
            <TextField
              type="month"
              size="small"
              name="start"
              variant="outlined"
              value={monthRange.start}
              inputProps={{
                max: currentMonthYear
              }}
              onChange={handleDateRangeUpdate}
              fullWidth
            />
          </Grid>
          <Grid item xs={2} container justify="center" alignItems="center">
            <Typography>to</Typography>
          </Grid>
          <Grid item xs={5}>
            <TextField
              size="small"
              variant="outlined"
              type="month"
              name="end"
              value={monthRange.end}
              inputProps={{
                min: monthRange.start,
                max: currentMonthYear
              }}
              onChange={handleDateRangeUpdate}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid item sm={1}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={
              !filterValue &&
              monthRange.start === currentMonthYear &&
              monthRange.end === currentMonthYear
            }
            onClick={resetHandler}>
            Reset
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ShopsPerformanceOrderFilters;
