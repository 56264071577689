import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLoader } from 'layouts/loaderContext';
import { useSnackbar } from 'notistack';
import { StickyTableCell } from 'components/molecules';
import ProductsRow from './ProductsRow';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  autoComplete: {
    margin: theme.spacing(1),
    minWidth: 500
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  footer: {
    flexGrow: 1,
    padding: '0.5rem'
  },
  table: {},
  amount: {
    width: 60,
    '& input': {
      padding: '10.5px 8px'
    }
  },
  column: {
    width: '10rem',
    minWidth: '9rem'
  },
  checkboxColumn: {
    left: 0,
    backgroundColor: '#fafafa'
  },
  nameColumn: {
    left: 44,
    backgroundColor: '#fafafa'
  },
  imgHolder: {
    width: 38,
    height: 38,
    textAlign: 'center',
    border: '1px solid #bbb',
    marginRight: 10
  },
  image: {
    maxHeight: 36,
    maxWidth: 36
  },
  imageColumn: {
    width: 50
  }
}));

const ProductsTable = ({
  services,
  products,
  dispatch,
  initialProducts,
  updateInitialProducts = () => {},
  updateTab = () => {},
  disabled = false,
  setDisabled = () => {},
  items = [],
  searchBy = ''
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { loading } = useLoader();
  const classes = useStyles();
  const [priceHelper, setPriceHelper] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    setFilteredProducts(
      items.filter((item) => item.name.toLowerCase().includes(searchBy))
    );
  }, [items, searchBy]);

  const onCancel = () => {
    dispatch({
      type: 'resetProducts',
      payload: initialProducts
    });
    setDisabled(true);
  };

  const onSubmit = () => {
    const serviceIDs = services
      .map((item) => item?.id || '')
      .filter((item) => !!item);

    const invalidProduct = Object.values(products).find((product) => {
      let rangeMin = 0,
        rangeMax = 0;

      if (product.priceRange)
        [rangeMin, rangeMax] = product.priceRange.split('-');

      const isPriceValid =
        product?.unit &&
        (product?.price ||
          (!!rangeMin &&
            (!!rangeMax ? parseFloat(rangeMin) < parseFloat(rangeMax) : true)));

      return (
        product.enabled &&
        serviceIDs.includes(product.serviceID) &&
        !isPriceValid
      );
    });
    if (invalidProduct) {
      enqueueSnackbar('Enter valid product prices and units', {
        variant: 'error',
        persist: true,
        preventDuplicate: true
      });
      setPriceHelper('invalid');
      const tabWithErrorIndex = serviceIDs.indexOf(invalidProduct.serviceID);
      if (Number.isInteger(tabWithErrorIndex)) {
        updateTab(tabWithErrorIndex);
      }
    } else {
      updateInitialProducts();
      dispatch({
        type: 'saveProducts'
      });
      setDisabled(true);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" className={classes.table}>
          <TableHead>
            <TableRow>
              <StickyTableCell
                padding="checkbox"
                className={classes.checkboxColumn}
              />
              <StickyTableCell className={classes.nameColumn}>
                Name
              </StickyTableCell>
              <TableCell align="center">Image</TableCell>
              <TableCell align="center">Item Description</TableCell>
              <TableCell align="center">Pieces</TableCell>
              <TableCell align="center" className={classes.column}>
                Price Type
              </TableCell>
              <TableCell align="center">Amount</TableCell>
              <TableCell align="center" className={classes.column}>
                Units
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products !== undefined &&
              filteredProducts.map((row, i) => (
                <ProductsRow
                  dispatch={dispatch}
                  key={`product_${row.serviceID}_${row.id}`}
                  product={products[row.id]}
                  products={products}
                  row={row}
                  setDisabled={setDisabled}
                  priceHelper={priceHelper}
                />
              ))}
          </TableBody>
        </Table>
        {filteredProducts.length < 1 && !loading ? (
          <Typography
            align="center"
            variant="h6"
            style={{ paddingTop: '1rem' }}>
            No Product found
          </Typography>
        ) : null}
      </TableContainer>

      <Grid container className={classes.footer}>
        <Grid container justify="flex-end">
          <Grid item>
            <Button
              onClick={onCancel}
              style={{ marginRight: '1rem' }}
              disabled={disabled}
              variant="outlined">
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              onClick={onSubmit}
              disabled={disabled}
              variant="contained">
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ProductsTable;
