import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import BankAccountForm from './components/BankAccountForm';
import BankAccountDetails from './components/BankAccountDetails';
import { useShops } from 'context/shop/shopsContext';
import { useSnackbar } from 'notistack';
import { useAmplifyAuth } from 'context';
import { useLoader } from 'layouts/loaderContext';
import { Base64 } from 'js-base64';
import { getShopBankAccountDetails } from 'graphql/queries';
import {
  addShopBankAccountDetails,
  updateShopBankAccountDetails,
  generateOTP
} from 'graphql/mutations';
import { B64_SEPARATOR } from 'common/constants';
import { INITIAL_BANK_DETAILS } from './constants';

const BankAccount = () => {
  const {
    state: { isAdmin }
  } = useAmplifyAuth();
  const { setLoading } = useLoader();
  const { selectedShop } = useShops();
  const { enqueueSnackbar } = useSnackbar();
  const [editAccountForm, setEditAccountForm] = useState(false);
  const [accountDetails, setAccountDetails] = useState(INITIAL_BANK_DETAILS);

  useEffect(() => {
    if (selectedShop?.id) {
      fetchShopBankAccountDetails();
    }
  }, [selectedShop]);

  const fetchShopBankAccountDetails = () => {
    setLoading(true);
    API.graphql(
      graphqlOperation(getShopBankAccountDetails, {
        shopID: selectedShop.id
      })
    )
      .then((res) => {
        const data = res.data.getShopBankAccountDetails;
        if (!data?.id) setEditAccountForm(true);
        else
          setAccountDetails({
            ...data,
            accountNumber: Base64.decode(
              data.accountNumber.split(B64_SEPARATOR).at(0)
            ),
            accountSortCode: Base64.decode(
              data.accountSortCode.split(B64_SEPARATOR).at(0)
            )
          });
        setLoading(false);
      })
      .catch((err) => {
        setEditAccountForm(true);
        console.error('something went wrong', err);
      });
  };

  const accountFormValidationHandler = (accountDetails = {}) => {
    if (
      !!accountDetails.accountHolderName &&
      !!accountDetails.name &&
      accountDetails.accountSortCode.length === 6 &&
      accountDetails.accountNumber.length === 8 &&
      (isAdmin ? true : accountDetails.otp.length === 8)
    )
      return true;
    enqueueSnackbar(
      'All fields are required. Please double-check before submitting.',
      {
        variant: 'warning',
        preventDuplicate: true,
        autoHideDuration: 3000
      }
    );
    return false;
  };

  const handleSubmit = async (input, callback = () => {}) => {
    if (!selectedShop?.id) return;
    if (!accountFormValidationHandler(input)) return;

    setLoading(true);
    try {
      const bankDetailsInput = {
        accountHolderName: input.accountHolderName,
        name: input.name,
        accountSortCode: `${Base64.encode(
          input.accountSortCode
        )}${B64_SEPARATOR}${Math.random().toString(36).slice(2)}`,
        accountNumber: `${Base64.encode(
          input.accountNumber
        )}${B64_SEPARATOR}${Math.random().toString(36).slice(2)}`
      };
      if (!isAdmin) bankDetailsInput.otpInput = input.otp;

      if (accountDetails?.id) {
        bankDetailsInput.id = accountDetails.id;
        bankDetailsInput._version = accountDetails._version;
        await API.graphql(
          graphqlOperation(updateShopBankAccountDetails, bankDetailsInput)
        );
        enqueueSnackbar('Account details have been successfully updated.', {
          variant: 'success',
          preventDuplicate: true,
          autoHideDuration: 3000
        });
      } else {
        bankDetailsInput.shopID = selectedShop.id;
        await API.graphql(
          graphqlOperation(addShopBankAccountDetails, bankDetailsInput)
        );
        enqueueSnackbar('Account details have been successfully added.', {
          variant: 'success',
          preventDuplicate: true,
          autoHideDuration: 3000
        });
      }
      fetchShopBankAccountDetails();
      callback();
      setEditAccountForm(false);
    } catch (err) {
      console.error('Something went wrong in bank account details update...');
      const errorMessage = err?.errors[0]?.message || '';
      enqueueSnackbar(
        errorMessage.toLowerCase().includes('otp')
          ? errorMessage
          : 'Something went wrong. Please try again...',
        {
          variant: 'error',
          preventDuplicate: true,
          persist: true
        }
      );
      setLoading(false);
    }
  };

  const handleCancel = () => setEditAccountForm(false);

  const handleGenerateOTPClick = async () => {
    setLoading(true);
    try {
      await API.graphql(graphqlOperation(generateOTP));
    } catch (error) {
      console.error('something went wrong while generating otp...');
    } finally {
      setLoading(false);
    }
  };

  return !editAccountForm ? (
    <BankAccountDetails
      accountDetails={accountDetails}
      setEditAccountForm={setEditAccountForm}
    />
  ) : (
    <BankAccountForm
      accountDetails={accountDetails}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      handleGenerateOTPClick={handleGenerateOTPClick}
    />
  );
};

export default BankAccount;
