import React from 'react';
import {
  CardContent,
  CardHeader,
  Checkbox,
  Grid,
  TextField
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { languages, paymentMethods } from 'common/constants';
import ShopCharges from './ShopCharges';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(() => ({
  darkColor: {
    color: '#444'
  },
  rightMargin: {
    marginRight: 8
  }
}));

const SelectPaymentAndLanguage = ({
  selectedShop,
  setSelectedShop,
  handleChange,
  handleNumberChange
}) => {
  const classes = useStyles();

  return (
    <>
      <ShopCharges
        selectedShop={selectedShop}
        setSelectedShop={setSelectedShop}
        handleChange={handleChange}
        handleNumberChange={handleNumberChange}
      />

      <Grid container>
        <Grid item xs={12} md={6}>
          <CardHeader
            subheader={
              <span className={classes.darkColor}>
                Choose the language which the shop person speak
              </span>
            }
            title="Language"
          />
          <CardContent>
            <Autocomplete
              getOptionLabel={(option) => option}
              multiple
              name="language"
              onChange={(e, newValue) =>
                handleChange({ target: { name: 'language' } }, newValue)
              }
              options={languages}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="language"
                  variant="outlined"
                />
              )}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    checked={selected}
                    checkedIcon={checkedIcon}
                    icon={icon}
                    className={classes.rightMargin}
                  />
                  {option}
                </React.Fragment>
              )}
              value={(selectedShop && selectedShop.language) || []}
              disableClearable
            />
          </CardContent>
        </Grid>
        <Grid item xs={12} md={6}>
          <CardHeader
            title="Payment Methods"
            subheader={
              <span className={classes.darkColor}>
                Choose the payment methods you want to enable
              </span>
            }
          />
          <CardContent>
            <Autocomplete
              fullWidth
              multiple
              name="paymentMethods"
              getOptionLabel={(option) =>
                option.label ||
                paymentMethods.find((method) => method.value === option)
                  ?.label ||
                option
              }
              getOptionSelected={(option, value) => option.value === value}
              onChange={(_, newValue) => {
                handleChange(
                  { target: { name: 'paymentMethods' } },
                  newValue.map((item) => item.value || item)
                );
              }}
              options={paymentMethods}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="payment methods"
                  variant="outlined"
                />
              )}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    checked={selected}
                    checkedIcon={checkedIcon}
                    icon={icon}
                    className={classes.rightMargin}
                  />
                  {option.label}
                </React.Fragment>
              )}
              value={(selectedShop && selectedShop.paymentMethods) || []}
              disableClearable
            />
          </CardContent>
        </Grid>
      </Grid>
    </>
  );
};

export default SelectPaymentAndLanguage;
