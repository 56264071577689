import React, { Fragment, useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Grid,
  Collapse,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Chip,
  useMediaQuery,
  Box,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Avatar,
  Tooltip,
  Divider
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {
  showPrice,
  getTotalQuantity,
  unitFormatter,
  inWords,
  capitalizeStr,
  getCollectionAndDeliveryFee,
  getCollectionAndDeliveryFeeText,
  getFormattedPriceRange,
  addRangeToPrice,
  getOrderDiscountTotal,
  getOrderTotal,
  getOrderTotalRating
} from 'common/utilFunctions';
import { PAYMENT_CANCELLED_ORDER_STATUSES } from 'common/constants';
import {
  OrderStatusConnector,
  OrderStatusConnectorIcon,
  AddressCard
} from '../components';
import { Link } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import {
  getPaymentByOrderId,
  getIsDeliveryIssueMessagePresent
} from 'graphql/queries';
import CatRewards from 'assets/svg/rewardsSvg.svg';
import { routes } from 'common/constants';
import moment from 'moment';
import clsx from 'clsx';

const THREE_MINUTES_IN_MILLISECONDS = 180000;

const useStyles = makeStyles((theme) => ({
  ordersCard: {
    borderRadius: '8px',
    border: '1px solid',
    borderColor: theme.palette.grey.secondary,
    marginBottom: '10px'
  },
  ordersCardAnimation: {
    borderRadius: '8px',
    border: '1px solid',
    borderColor: theme.palette.grey.secondary,
    marginBottom: '10px',
    animation: `$opac 1.5s ${theme.transitions.easing.easeInOut}`
  },
  ordersCardTopRoot: {
    position: 'relative',
    borderLeft: `8px solid ${theme.palette.secondary.main}`,
    borderRadius: '8px'
  },
  ordersCardTopSection: {
    marginTop: '1rem',
    padding: '0 10px 20px 10px',
    [theme.breakpoints.up('sm')]: {
      padding: '0 35px 20px 35px'
    }
  },
  orderPaymentStatusChip: {
    backgroundColor: '#4caf50',
    color: 'white',
    margin: '0.5rem 0 0',
    padding: '0 0.5rem',
    borderRadius: '1rem 0 0 1rem'
  },
  orderAcceptedStatusChip: {
    position: 'absolute',
    backgroundColor: '#194376',
    color: 'white',
    margin: '0.5rem 0 0 0',
    padding: '0 0.5rem',
    borderRadius: '0.5rem 0 1rem 0',
    top: '20px',
    left: '12px'
  },
  orderFowardedStatusChip: {
    backgroundColor: '#FFBF00',
    color: '#000',
    margin: '0.5rem 0 0',
    padding: '0 0.5rem',
    borderRadius: '1rem 0 0 1rem'
  },
  orderCancelStatusChip: {
    backgroundColor: '#ff6100d4',
    color: 'white',
    margin: '0.5rem 0 0',
    padding: '0 0.5rem',
    borderRadius: '1rem 0 0 1rem'
  },
  orderDeclineStatusChip: {
    backgroundColor: '#ff0000b0',
    color: 'white',
    margin: '0.5rem 0 0',
    padding: '0 0.5rem',
    borderRadius: '1rem 0 0 1rem'
  },
  orderDetailsContainer: {
    width: '100%',
    padding: '0px 35px',
    '@media (max-width:426px)': {
      padding: '0 5px'
    }
  },
  orderCardText: {
    lineHeight: '28px',
    fontWeight: '500'
  },
  orderCardText2: {
    fontWeight: '400',
    lineHeight: '28px'
  },
  orderDetailItem: {
    borderBottom: '0.3px solid rgba(119, 119, 119, 0.5)',
    padding: '10px 0 8px',
    '& > td': {
      borderBottom: '0.3px solid rgba(119, 119, 119, 0.5)'
    }
  },
  orderDetailHeading2: {
    borderBottom: '3px solid #DFDFDF'
  },
  paymentBtn: {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main
  },
  cancelBtn: {
    borderColor: 'red',
    color: 'red'
  },
  reportBtn: {
    color: '#fff',
    letterSpacing: '0.1rem',
    backgroundColor: '#ff7c2b',
    '&:hover': {
      backgroundColor: '#ff9f5e'
    }
  },
  viewDetailBtn: {
    borderColor: theme.palette.info.main,
    color: theme.palette.info.main
  },
  acceptBtn: {
    borderColor: 'green',
    color: 'green'
  },
  '@keyframes opac': {
    '0%': {
      backgroundColor: 'white'
    },
    '50%': {
      backgroundColor: 'orange'
    },
    '100%': {
      backgroundColor: 'white'
    }
  },
  orderTypeBadge: {
    position: 'absolute',
    backgroundColor: '#194376',
    color: 'white',
    margin: '0.5rem 0 0 0',
    padding: '0 0.5rem',
    borderRadius: '0 1rem 1rem 0'
  },
  forwardedShopBox: {
    textAlign: 'center',
    border: '1px solid #979797',
    borderRadius: '11px',
    padding: '16px',
    marginTop: '16px'
  },
  dropPickStyles: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #979797',
    borderRadius: '11px',
    padding: '16px',
    marginTop: '16px',
    height: '180px'
  },
  orderCancelReasonCard: {
    padding: '0.5rem',
    marginBottom: '1rem',
    fontWeight: '500'
  },
  errorColor: {
    backgroundColor: '#ff000040'
  },
  warningColor: {
    backgroundColor: '#edd242cc'
  },
  tableCellRoot: {
    padding: 0
  },
  tableHeadingCellText: {
    fontWeight: 700,
    '@media (max-width:426px)': {
      fontSize: 13
    }
  },
  tableHeadingEndcellText: {
    color: '#979797',
    '@media (max-width:426px)': {
      marginLeft: 5
    }
  },
  tableContainer: {
    marginTop: '3rem'
  },
  tableHeadingCell: {
    paddingBottom: '0.625rem'
  },
  tableBodyCell: {
    padding: '0.625rem 0 0.5rem'
  },
  note: {
    '& > p': {
      borderRadius: '3rem',
      padding: '0 0.5rem',
      backgroundColor: theme.palette.warning.main,
      textAlign: 'center',
      color: 'white',
      margin: '1rem 0',
      [theme.breakpoints.up('md')]: {
        textAlign: 'left',
        margin: 0
      }
    }
  },
  discountChip: {
    backgroundColor: '#8a0c0c',
    color: 'white'
  },
  auctionIndicator: {
    margin: '0 0.5rem',
    backgroundColor: '#EDD242',
    color: 'black',
    fontSize: '0.625rem'
  },
  messageBtn: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  },
  finalPayment: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#444'
  },
  feedbackText: {
    fontWeight: 600,
    color: '#444',
    [theme.breakpoints.down('md')]: {
      fontSize: 14
    }
  },
  feedbackBtn: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main
  },
  paymentRow: {
    margin: '0.3rem 0'
  }
}));

const steps = [
  {
    id: 0,
    label: 'New Order',
    name: 'newOrder'
  },
  {
    id: 1,
    label: 'Accepted',
    name: 'accepted'
  },
  {
    id: 2,
    label: 'Itemized',
    name: 'itemized'
  },
  {
    id: 3,
    label: 'In Progress',
    name: 'inProgress'
  },
  {
    id: 4,
    label: 'Ready for Delivery',
    name: 'readyForDelivery'
  },
  {
    id: 5,
    label: 'Out for Delivery',
    name: 'outForDelivery'
  },
  {
    id: 6,
    label: 'Delivered',
    name: 'delivered'
  }
];

const OrderCard = ({
  order,
  selectedOrder,
  hideReportBtn = false,
  handleCancelClick = () => {},
  showCancelButton = false,
  handlePaymentClick = () => {},
  handleForwardRequestActionClick = () => {},
  handleOrderDelivered = () => {},
  showEditDeliveryHandler = () => {},
  handleFeedbackButtonClick = () => {},
  handleOrderDeliveryIssue = () => {}
}) => {
  let balance = order.total;
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const reportIssueRenameAsNo = useMediaQuery(
    '(max-width: 400px), (min-width: 600px) and (max-width: 760px), (min-width: 990px) and (max-width: 1280px)'
  );
  const [showOrderDetail, setShowOrderDetail] = useState(false);
  const [shouldShowReportButton, setShouldShowReportButton] = useState(false);
  const [partiallyPaidRecord, setPartiallyPaidRecord] = useState([]);
  const [_, __, totalDiscount] = getOrderDiscountTotal(order);
  const OrderForwardedFrom = order?.forwardRequests?.items[0]?.requestShop;
  const OrderForwardedTo =
    order?.forwardRequests?.items[0]?.acceptedShop?.name || 'pending';
  const orderForwardedFromData = {
    name: OrderForwardedFrom?.name,
    address: OrderForwardedFrom?.address,
    postcode: OrderForwardedFrom?.postcode
  };
  const isForwardRequestAccepted =
    order?.forwardRequests?.items[0]?.status === 'accepted';

  useEffect(() => {
    if (
      order?.id &&
      order.paymentStatus === 'paid' &&
      ['readyForDelivery', 'outForDelivery'].includes(order.status)
    ) {
      API.graphql(
        graphqlOperation(getIsDeliveryIssueMessagePresent, {
          orderID: order?.id
        })
      ).then(({ data }) => {
        if (data.getIsDeliveryIssueMessagePresent.statusCode == 200) {
          const body = JSON.parse(data.getIsDeliveryIssueMessagePresent.body);
          !body?.isMessageExists && setShouldShowReportButton(true);
        } else setShouldShowReportButton(true);
      });
    }
  }, [order]);

  useEffect(() => {
    if (hideReportBtn) setShouldShowReportButton(false);
  }, [hideReportBtn]);

  useEffect(() => {
    if (selectedOrder?.id === order?.id) {
      setShowOrderDetail(true);
    }
  }, [selectedOrder]);

  const getCurrentSteps = (o) =>
    !(o.status === 'declined' || o.status === 'cancelled')
      ? steps
      : [
          steps[0],
          {
            id: 1,
            label: o.status,
            name: o.status
          }
        ];

  const getPartialPaymentsRecord = async () => {
    if (order?.id) {
      const response = await API.graphql(
        graphqlOperation(getPaymentByOrderId, {
          orderID: order?.id
        })
      );
      const data = await response.data.getPaymentByOrderId.items;
      const partiallyPaidAmounts = (data) => {
        const recordWithDates = data
          .map((el) => ({
            amount: el.amount,
            createdAt: el.createdAt
          }))
          .sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        setPartiallyPaidRecord(recordWithDates);
      };
      partiallyPaidAmounts(data);
    }
  };

  const handleViewDetailsClick = () => {
    setShowOrderDetail(true);
    getPartialPaymentsRecord();
  };

  return (
    <Grid
      container
      justify="space-between"
      className={
        new Date(order.createdAt).getTime() + THREE_MINUTES_IN_MILLISECONDS >=
        new Date().getTime()
          ? classes.ordersCardAnimation
          : classes.ordersCard
      }>
      <Grid container className={classes.ordersCardTopRoot}>
        {order.collection?.type === 'asap' ? (
          <Grid container justify="flex-start">
            <Chip
              size="small"
              label="ASAP"
              className={classes.orderTypeBadge}
            />
          </Grid>
        ) : (
          <Grid container justify="flex-start">
            <Chip
              size="small"
              label="Standard"
              className={classes.orderTypeBadge}
            />
          </Grid>
        )}

        {order.paymentStatus === 'paid' && (
          <Grid container justify="flex-end" style={{ marginBottom: '5px' }}>
            <Chip
              size="small"
              label="Paid"
              className={classes.orderPaymentStatusChip}
              style={{ marginBottom: '-10px' }}
            />
          </Grid>
        )}

        {(order.status === 'forwarded' || order.status === 'pending') && (
          <Grid container justify="flex-end" style={{ marginBottom: '5px' }}>
            <Chip
              size="small"
              label="Order Forwarded"
              className={classes.orderFowardedStatusChip}
              style={{ marginBottom: '-10px' }}
            />
          </Grid>
        )}

        {order.paymentStatus === 'partiallyPaid' && (
          <Grid container justify="flex-end" style={{ marginBottom: '5px' }}>
            <Chip
              size="small"
              label={`Partially ${showPrice(order.paidAmount || 0)} paid`}
              className={classes.orderPaymentStatusChip}
              style={{ marginBottom: '-10px' }}
            />
          </Grid>
        )}

        {order.status === 'delivered' && (
          <Grid container justify="flex-end">
            <Chip
              size="small"
              label="Delivered"
              className={classes.orderPaymentStatusChip}
              style={{ marginBottom: '-10px' }}
            />
          </Grid>
        )}
        {order.status === 'cancelled' && (
          <Grid container justify="flex-end">
            <Chip
              size="small"
              label="Cancelled"
              className={classes.orderCancelStatusChip}
              style={{ marginBottom: '-10px' }}
            />
          </Grid>
        )}
        {order.status === 'declined' && (
          <Grid container justify="flex-end">
            <Chip
              size="small"
              label="Declined "
              className={classes.orderDeclineStatusChip}
              style={{ marginBottom: '-10px' }}
            />
          </Grid>
        )}
        <Grid
          container
          justify="space-between"
          style={{
            paddingTop: order.paymentStatus === 'paid' ? '0' : '20px'
          }}
          className={classes.ordersCardTopSection}>
          <Grid container item xs={12} sm={8} md={6}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText}>
                  Order ID:
                </Typography>
              </Grid>
              <Grid item xs={6} container>
                <Typography variant="h6" className={classes.orderCardText2}>
                  {order.orderID}
                </Typography>
                {!!order.bidID ? (
                  <Tooltip
                    title="this order comes from an Auction you placed"
                    arrow>
                    <Chip
                      size="small"
                      label="From Auction"
                      className={classes.auctionIndicator}
                    />
                  </Tooltip>
                ) : null}
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText}>
                  Shop Name:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText2}>
                  {orderForwardedFromData.name || order?.shop?.name}
                </Typography>
              </Grid>
            </Grid>

            {order.status === 'forwarded' || order.status === 'pending' ? (
              <>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="h6" className={classes.orderCardText}>
                      Order Forwarded To:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Tooltip
                      title={
                        order.status === 'forwarded'
                          ? 'Waiting for another shop to accept your order'
                          : ''
                      }
                      placement="top">
                      <Typography
                        variant="h6"
                        className={classes.orderCardText2}>
                        {OrderForwardedTo}
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="h6" className={classes.orderCardText}>
                      Forward Reason:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6" className={classes.orderCardText2}>
                      {order.forwardRequests?.items[0]?.forwardReason}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : null}

            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText}>
                  Order Placed on:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText2}>
                  {moment(order.createdAt).format('MMMM Do YYYY') || ''}
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText}>
                  {!!order.collection.address ? 'Collection ' : 'Drop '}
                  Date &amp; Time:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText2}>
                  {order.collection.date
                    ? `${moment(order.collection.date).format('MMMM Do YYYY')}`
                    : 'Pending'}
                </Typography>
                {order.collection.time ? (
                  <Typography variant="body1">
                    ({order.collection.time})
                  </Typography>
                ) : null}
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText}>
                  {!!order.delivery.address ? 'Delivery ' : 'Pickup '}
                  Date &amp; Time:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText2}>
                  {order.delivery.date
                    ? `${moment(order.delivery.date).format('MMMM Do YYYY')}`
                    : 'Pending'}
                </Typography>
                {order.delivery.time ? (
                  <Typography variant="body1">
                    ({order.delivery.time})
                  </Typography>
                ) : null}
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText}>
                  Total Amount:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText2}>
                  {!!order.variableTotal
                    ? getFormattedPriceRange(
                        addRangeToPrice(order.total || 0, order.variableTotal)
                      )
                    : showPrice(order.total || 0)}
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText}>
                  Payment Mode:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText2}>
                  {capitalizeStr(order.paymentMethod)}
                </Typography>
              </Grid>
            </Grid>

            {!!order.variableTotal ? (
              <Grid container className={classes.note}>
                <Typography variant="body1" className={classes.orderCardText}>
                  Final price will be updated once the Itemizing and Pricing is
                  completed
                </Typography>
              </Grid>
            ) : null}
            {['readyForDelivery', 'outForDelivery'].includes(order.status) &&
            order.paymentStatus === 'paid' ? (
              <Grid
                container
                alignItems="center"
                style={{ margin: '0.5rem 0' }}>
                <Grid item xs={6} sm={5} md={6}>
                  <Typography variant="h5" className={classes.feedbackText}>
                    Have you received this order?
                  </Typography>
                </Grid>
                <Grid item xs={3} lg={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      handleOrderDelivered(order);
                    }}>
                    Yes
                  </Button>
                </Grid>
                {shouldShowReportButton ? (
                  <Grid item xs={3} sm={4} md={3} lg={4}>
                    <Button
                      // fullWidth
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.reportBtn}
                      onClick={() => handleOrderDeliveryIssue(order)}>
                      {reportIssueRenameAsNo ? 'No' : 'Report an issue'}
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
            ) : null}
          </Grid>

          <Grid
            container
            item
            direction="column"
            xs={12}
            sm={4}
            md={5}
            lg={3}
            style={{ gap: '20px' }}
            justify="center"
            alignItems="flex-end">
            {!!(
              order.paymentMethod === 'card' &&
              order.paymentStatus !== 'paid' &&
              !['newOrder', 'accepted', 'declined', 'cancelled'].includes(
                order.status
              )
            ) ? (
              <>
                {order.status === 'delivered' ? (
                  <Typography variant="body2">
                    The order has been marked as delivered by the shop. Kindly
                    complete the payment at your earliest convenience to
                    finalize the transaction.
                  </Typography>
                ) : null}
                <Button
                  variant="outlined"
                  fullWidth
                  className={classes.paymentBtn}
                  onClick={handlePaymentClick}>
                  PAY {showPrice(order.total - (order.paidAmount || 0))}
                </Button>
              </>
            ) : null}
            <Link
              to={`${routes.customer.chat}?orderID=${order.orderID}`}
              style={{ width: '100%' }}>
              <Button
                className={classes.messageBtn}
                variant="outlined"
                fullWidth>
                CHAT WITH SHOP
              </Button>
            </Link>

            {order.status === 'pending' ? (
              <Button
                variant="outlined"
                fullWidth
                className={classes.acceptBtn}
                onClick={() => handleForwardRequestActionClick(order)}>
                Accept
              </Button>
            ) : null}
            {showCancelButton ? (
              <Button
                variant="outlined"
                fullWidth
                disabled={
                  !(
                    order.status === 'newOrder' ||
                    order.status === 'forwarded' ||
                    order.status === 'pending'
                  )
                }
                className={classes.cancelBtn}
                onClick={handleCancelClick}>
                CANCEL
              </Button>
            ) : null}
            <Button
              variant="outlined"
              className={classes.viewDetailBtn}
              fullWidth
              onClick={
                showOrderDetail
                  ? () => setShowOrderDetail(false)
                  : handleViewDetailsClick
              }>
              {showOrderDetail ? 'VIEW LESS' : 'VIEW DETAILS'}
            </Button>

            {order.customerReview.items.length > 0 ? (
              <Grid container justify="center">
                <Rating
                  name="customized-empty"
                  precision={0.5}
                  value={getOrderTotalRating(
                    order.customerReview.items[0].ratings
                  )}
                  readOnly
                />
              </Grid>
            ) : !!(
                order.status === 'delivered' && order.paymentStatus === 'paid'
              ) ? (
              <Button
                variant="outlined"
                fullWidth
                className={classes.feedbackBtn}
                onClick={handleFeedbackButtonClick}>
                FEEDBACK
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={showOrderDetail} className={classes.orderDetailsContainer}>
        <Typography
          variant="h4"
          style={{ fontWeight: '700', marginTop: '25px' }}>
          Order details
        </Typography>
        <Typography
          variant="subtitle2"
          color="primary"
          style={{
            fontSize: '15px',
            marginBottom: '30px'
          }}>
          Order #{order.orderID || 'N/A'} was placed on{' '}
          {moment(order.createdAt).format('MMMM Do YYYY') || ''}.
        </Typography>

        {isForwardRequestAccepted ? (
          <Grid container style={{ marginBottom: '2rem' }}>
            <Grid
              container
              justify="space-around"
              alignItems="center"
              item
              spacing={3}>
              {[1, 2].map((item, i) => {
                const lastItem = 1;
                let bgc =
                  i === 0 ? '#FFD580' : i === lastItem ? '#90EE90' : '#fff';
                let forwardStatus =
                  i === lastItem ? 'Order Accepted' : 'Order Forwarded';

                return (
                  <Fragment key={i}>
                    <Grid item md={4} xs={12} style={{ position: 'relative' }}>
                      <Grid
                        container
                        item
                        justify="flex-end"
                        style={{ marginBottom: '5px' }}>
                        <Chip
                          size="small"
                          label={forwardStatus}
                          className={classes.orderAcceptedStatusChip}
                        />
                      </Grid>

                      <Box
                        className={classes.forwardedShopBox}
                        style={{ backgroundColor: bgc, fontSize: '14px' }}>
                        <Typography
                          align="center"
                          style={{
                            fontSize: '14px',
                            marginTop: '10px',
                            padding: 8,
                            fontWeight: 600,
                            color: '#444'
                          }}>
                          {i !== 0
                            ? order.shop.name || 'pending'
                            : orderForwardedFromData.name}
                        </Typography>
                        <Typography
                          align="center"
                          style={{ fontSize: '13px', marginBottom: '0.3rem' }}>
                          {i !== 0
                            ? order.shop.postcode
                            : orderForwardedFromData.postcode}
                        </Typography>
                        <Typography align="center" style={{ fontSize: '13px' }}>
                          {i !== 0 ? (
                            order.shop.address
                          ) : (
                            <span style={{ fontWeight: 600, color: '#444' }}>
                              {order?.forwardRequests?.items[0]?.forwardReason}
                            </span>
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                    {i == 0 ? (
                      sm ? (
                        <ArrowDownwardIcon />
                      ) : (
                        <ArrowRightAltIcon fontSize="large" />
                      )
                    ) : null}
                  </Fragment>
                );
              })}
            </Grid>
          </Grid>
        ) : null}

        <Stepper
          alternativeLabel={md}
          activeStep={
            getCurrentSteps(order).find((step) => step.name === order.status)
              ?.id || 0
          }
          orientation={md ? 'horizontal' : 'vertical'}
          style={{ padding: 0, margin: '1rem' }}
          connector={md ? <OrderStatusConnector /> : <StepConnector />}>
          {getCurrentSteps(order).map((item) => (
            <Step key={`order-track-steps-items-${item.id}`}>
              <StepLabel
                style={{
                  textTransform: 'capitalize'
                }}
                StepIconComponent={OrderStatusConnectorIcon}>
                {item.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {!!order.orderCancelOrDeclineReason ? (
          <Grid
            container
            item
            justify="center"
            className={clsx(classes.orderCancelReasonCard, classes.errorColor)}>
            {order.orderCancelOrDeclineReason}
          </Grid>
        ) : null}

        {order?.onHoldPaymentAmount > 0 &&
          PAYMENT_CANCELLED_ORDER_STATUSES.includes(order?.status) && (
            <Grid
              container
              item
              justify="center"
              className={clsx(
                classes.orderCancelReasonCard,
                classes.warningColor
              )}>
              The authorised payment has been revoked, and it may take a few
              days for the funds to be credited to your bank account.
            </Grid>
          )}

        <Grid container className={classes.tableContainer}>
          <Table>
            <TableHead style={{ backgroundColor: '#fff' }}>
              <TableRow className={classes.orderDetailHeading2}>
                <TableCell
                  classes={{ root: classes.tableCellRoot }}
                  className={classes.tableHeadingCell}>
                  <Typography
                    variant="subtitle1"
                    className={classes.tableHeadingCellText}>
                    Product
                  </Typography>
                </TableCell>
                <TableCell
                  classes={{ root: classes.tableCellRoot }}
                  className={classes.tableHeadingCell}
                  align="center">
                  <Typography
                    variant="subtitle1"
                    className={classes.tableHeadingCellText}>
                    Quantity
                  </Typography>
                </TableCell>
                {!order.bidID ? (
                  <TableCell
                    classes={{ root: classes.tableCellRoot }}
                    className={classes.tableHeadingCell}
                    align="center">
                    <Typography
                      variant="subtitle1"
                      className={classes.tableHeadingCellText}>
                      Rate
                    </Typography>
                  </TableCell>
                ) : null}
                <TableCell
                  classes={{ root: classes.tableCellRoot }}
                  className={classes.tableHeadingCell}
                  align="center">
                  <Typography
                    variant="subtitle1"
                    className={classes.tableHeadingCellText}>
                    Discount
                  </Typography>
                </TableCell>
                <TableCell
                  classes={{ root: classes.tableCellRoot }}
                  className={classes.tableHeadingCell}
                  align="right">
                  <Typography
                    variant="subtitle1"
                    className={clsx(
                      classes.tableHeadingCellText,
                      classes.tableHeadingEndcellText
                    )}>
                    Amount
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order?.orderList?.map((item, index) => (
                <TableRow
                  className={classes.orderDetailItem}
                  key={`my-orders-order-detail-items-${index}`}>
                  <TableCell
                    classes={{ root: classes.tableCellRoot }}
                    className={classes.tableBodyCell}>
                    <Typography variant="h6">{`${item.name || ''}`}</Typography>
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableCellRoot }}
                    className={classes.tableBodyCell}
                    align="center">
                    <Typography variant="h6">
                      {`x ${item.quantity || 0}`}
                    </Typography>
                  </TableCell>
                  {!order.bidID ? (
                    <TableCell
                      classes={{ root: classes.tableCellRoot }}
                      className={classes.tableBodyCell}
                      align="center">
                      <Typography variant="h6">
                        {`${
                          item.price
                            ? showPrice(item.price)
                            : getFormattedPriceRange(item.priceRange)
                        } ${unitFormatter(item.unit).toLowerCase()}`}
                      </Typography>
                    </TableCell>
                  ) : null}
                  <TableCell
                    classes={{ root: classes.tableCellRoot }}
                    className={classes.tableBodyCell}
                    align="center">
                    <Typography variant="h6">
                      {item.discount
                        ? `${showPrice(item.discount || 0)} ${unitFormatter(
                            item.unit
                          )}`.toLowerCase()
                        : item.overallDiscount
                        ? `${showPrice(item.overallDiscount)}`
                        : 0}
                    </Typography>
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableCellRoot }}
                    className={classes.tableBodyCell}
                    align="right">
                    <Typography variant="h6">
                      {item.totalPrice
                        ? showPrice(item.totalPrice)
                        : item.price
                        ? showPrice(
                            item.price * item.quantity -
                              (item.overallDiscount || 0)
                          )
                        : getFormattedPriceRange(
                            item.priceRange,
                            item.quantity
                          )}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid
          container
          className={classes.orderDetailItem}
          justify="space-between">
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Subtotal
          </Typography>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            {!!order.variableTotal
              ? getFormattedPriceRange(
                  addRangeToPrice(
                    getOrderTotal(order) || 0,
                    order.variableTotal
                  )
                )
              : showPrice(getOrderTotal(order) || 0)}
          </Typography>
        </Grid>
        {/* order total discounts */}
        {order.coupons?.length > 0 ? (
          <Grid
            container
            className={classes.orderDetailItem}
            style={{ gap: '0.25rem' }}>
            <Grid container>
              <Typography variant="h6">Coupons</Typography>
            </Grid>
            {order.coupons.map((_item, index) => {
              const orderCoupon = JSON.parse(_item);
              return (
                <Grid
                  container
                  justify="space-between"
                  key={`order-coupons-${orderCoupon.id || ''}-items-${index}`}>
                  <Typography variant="body1">
                    {orderCoupon.title || ''}
                  </Typography>
                  <Typography variant="body1">
                    - {showPrice(orderCoupon.discountAmountApplied || 0)}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        ) : null}
        {/* order tax */}
        {process.env.REACT_APP_VAT === 'enabled' ? (
          <Grid
            container
            className={classes.orderDetailItem}
            justify="space-between">
            <Typography variant="h6">VAT (20%)</Typography>
            <Typography variant="h6">{showPrice(order?.tax || 0)}</Typography>
          </Grid>
        ) : null}
        {/* asap fee */}
        {order.collection.type == 'asap' ? (
          <Grid
            container
            className={classes.orderDetailItem}
            justify="space-between">
            <Typography variant="h6">ASAP Fee</Typography>
            <Typography variant="h6">
              {showPrice(order?.shop?.AsapDeliveryFee)}
            </Typography>
          </Grid>
        ) : null}
        {/* collection and delivery fee */}
        <Grid
          container
          className={classes.orderDetailItem}
          justify="space-between">
          <Typography variant="h6">
            {getCollectionAndDeliveryFeeText(order)}
          </Typography>
          <Typography variant="h6">
            {getCollectionAndDeliveryFee(order)}
          </Typography>
        </Grid>
        <Grid
          container
          justify="space-between"
          className={classes.orderDetailItem}>
          <Typography
            color="secondary"
            variant="h6"
            style={{ fontWeight: '800' }}>
            {`Total: (x ${getTotalQuantity(order)})`}
          </Typography>
          <Typography
            color="secondary"
            variant="h6"
            style={{ fontWeight: '800' }}>
            {!!order.variableTotal
              ? getFormattedPriceRange(
                  addRangeToPrice(order.total || 0, order.variableTotal)
                )
              : showPrice(order.total || 0)}
          </Typography>
        </Grid>

        {!!totalDiscount ? (
          <Grid container style={{ margin: '0.5rem 0' }}>
            <Chip
              label={
                <>
                  you saved
                  <span style={{ fontWeight: 'bolder' }}>
                    {' '}
                    {showPrice(totalDiscount)}{' '}
                  </span>
                  on this order
                </>
              }
              avatar={<Avatar src={CatRewards} alt="offers image" />}
              className={classes.discountChip}
            />
          </Grid>
        ) : null}

        {order.onHoldPaymentAmount > 0 && order.paymentMethod === 'card' && (
          <Grid
            container
            justify="space-between"
            className={classes.paymentRow}>
            <Grid item>
              <Typography variant="h6">Authorised Amount</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6">
                {showPrice(order.onHoldPaymentAmount)}
              </Typography>
            </Grid>
          </Grid>
        )}

        {order.paymentStatus === 'paid' && order.paymentMethod === 'card' && (
          <>
            <Grid
              container
              justify="space-between"
              className={classes.paymentRow}>
              <Grid item>
                <Typography variant="h6">Refunded</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {showPrice(
                    order.total > order.onHoldPaymentAmount
                      ? order.onHoldPaymentAmount
                      : order.onHoldPaymentAmount - order.total
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justify="space-between"
              className={classes.paymentRow}>
              <Grid item>
                <Typography variant="h6" className={classes.finalPayment}>
                  Finally paid
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.finalPayment}>
                  {showPrice(order.total || 0)}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}

        {order.onHoldPaymentAmount > 0 &&
          PAYMENT_CANCELLED_ORDER_STATUSES.includes(order.status) && (
            <Grid
              container
              justify="space-between"
              className={classes.paymentRow}>
              <Grid item>
                <Typography variant="h6">Refunded</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {showPrice(order.onHoldPaymentAmount)}
                </Typography>
              </Grid>
            </Grid>
          )}

        <Divider />

        {partiallyPaidRecord?.length > 0 && (
          <Grid>
            {partiallyPaidRecord?.map((el, i) => {
              balance -= el.amount;
              return (
                <Grid
                  container
                  className={classes.orderDetailItem}
                  justify="space-between"
                  key={i}>
                  <Typography variant="h6">
                    {order.paymentMethod !== 'card' && 'Partial '}
                    {unitFormatter(order.paymentMethod)} Payment on&nbsp;
                    {moment(el.createdAt).format('DD/MM/YYYY, h:mm a')}
                  </Typography>
                  <Typography key={i} variant="h6">
                    {showPrice(el.amount)}
                  </Typography>
                  <Grid container justify="space-between">
                    <Typography variant="h6">
                      {balance <= 0 ? 'Total Amount Due:' : 'Balance Due:'}
                    </Typography>
                    <Typography variant="h6">
                      <strong>{showPrice(balance)}</strong>
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        )}

        {order.paymentStatus === 'paid' && (
          <Typography
            align="center"
            style={{
              padding: '0.5rem',
              fontWeight: 'bold'
            }}
            variant="h6">
            A {unitFormatter(order.paymentMethod)} Payment of{' '}
            {capitalizeStr(inWords(order?.total))} is Paid.
          </Typography>
        )}

        <Grid
          container
          justify="center"
          alignItems="flex-end"
          style={{
            marginBottom: '40px',
            marginTop: '21px'
          }}
          spacing={4}>
          <Grid item md={6} xs={12}>
            {!!order.collection.address ? (
              <AddressCard
                address={order.collection.address}
                heading="Collection"
                driverDetails={order.collection.driver}
              />
            ) : (
              <Box>
                <Typography
                  align="center"
                  variant="h5"
                  className={classes.dropPickStyles}>
                  The order will be dropped off by the customer.
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            {!!order.delivery.address ? (
              <AddressCard
                address={order.delivery.address}
                heading="Delivery"
                driverDetails={order.delivery.driver}
              />
            ) : (
              <Box>
                <Typography
                  align="center"
                  variant="h5"
                  className={classes.dropPickStyles}>
                  The order will be picked up by the customer.
                </Typography>
              </Box>
            )}
          </Grid>
          {['itemized', 'inProgress', 'readyForDelivery'].includes(
            order.status
          ) &&
          !order.bidID &&
          order.paymentStatus === 'unpaid' &&
          order.shop?.deliveryHrs?.length > 0 ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => showEditDeliveryHandler(order)}>
              Edit Delivery
            </Button>
          ) : null}
        </Grid>
      </Collapse>
    </Grid>
  );
};

export default OrderCard;
