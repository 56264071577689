import React, { useEffect, useContext } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useCoupons } from 'context/coupons/couponsContext';
import { CartContext } from 'context/cart/cartContext';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1rem 0 0',
    padding: '1rem 0',
    borderTop: '1px solid #D2D8DD',
    borderBottom: '1px solid #D2D8DD'
  },
  heading: {
    marginBottom: '0.25rem'
  },
  footer: {
    margin: '0.5rem 0 0'
  },
  totalText: {
    fontWeight: 850,
    color: '#1f6893'
  },
  coupon: {
    borderRadius: '8px',
    margin: '0.5rem 0',
    padding: '1rem 0.5rem',
    boxShadow: '0px 0px 3px 0px #00000033'
  },
  appliedCoupon: {
    boxShadow: '0px 0px 3px 0px #1f6893'
  },
  couponBtn: {
    borderRadius: '8px',
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    height: 'fit-content'
  },
  couponTitle: {
    fontSize: '18px',
    fontWeight: '600',
    marginBottom: '0.2rem',
    background: '#EDD242',
    background: 'linear-gradient(to bottom, #EDD242 21%, #F94E4E 50%)',

    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
  },
  applyBtn: {
    backgroundColor: theme.palette.blue.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.blue.secondary
    }
  },
  clearBtn: {
    backgroundColor: theme.palette.warning.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.warning.dark
    }
  }
}));

const CartCoupons = ({
  handleApplyCouponClick = () => {},
  handleClearCouponClick = () => {},
  showAppliedOnly = false
}) => {
  const classes = useStyles();
  const { cart } = useContext(CartContext);
  const { coupons = [], dispatch } = useCoupons();
  const filteredCoupons = coupons.filter(
    (item) =>
      (item.product ? !!item.product.price : true) &&
      item.discountType === 'conditional'
  );
  // to hide coupons in checkout page
  // .filter((item) =>
  //   showAppliedOnly
  //     ? cart.items.some((product) => product.discountCouponID === item.id)
  //     : true
  // );

  useEffect(() => {
    let shopID = '';
    if (cart.items.length > 0) shopID = cart.items.at(0).shopID;
    if (shopID && coupons?.length <= 0)
      dispatch({
        type: 'getCouponsByShop',
        payload: { shopID, currentOnly: true }
      });
  }, []);

  return filteredCoupons.length > 0 ? (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.heading}>
        <Typography variant="h5" className={classes.totalText}>
          Coupons
        </Typography>
      </Grid>

      {filteredCoupons.map((item, index) => {
        const isCouponApplied = item.productID
          ? cart.items.some((product) => product.discountCouponID === item.id)
          : cart.coupons?.some(
              (_coupon) => JSON.parse(_coupon)?.id === item.id
            );
        return (
          <Grid
            item
            xs={12}
            key={`cart-card-coupons-items-${index}`}
            container
            justify="space-between"
            alignItems="center"
            className={clsx(
              classes.coupon,
              isCouponApplied && classes.appliedCoupon
            )}>
            <Grid item xs={9}>
              <Typography variant="subtitle1" className={classes.couponTitle}>
                {item.title || ''}
              </Typography>

              <Typography variant="body2">{item.description || ''}</Typography>
            </Grid>
            <Grid item xs={3}>
              {cart.items?.length > 0 &&
              (!item.productID ||
                cart.items.some((product) => product.id === item.productID)) ? (
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={
                    isCouponApplied
                      ? handleClearCouponClick(item)
                      : handleApplyCouponClick(item)
                  }
                  className={clsx(
                    classes.couponBtn,
                    isCouponApplied ? classes.clearBtn : classes.applyBtn
                  )}
                  fullWidth>
                  {isCouponApplied ? 'Clear' : 'Apply'}
                </Button>
              ) : null}
            </Grid>
          </Grid>
        );
      })}

      <Grid item xs={12} className={classes.footer}>
        <Typography variant="body2">
          Disclaimer: Coupons are only applicable to products with fixed
          pricing. Coupons will be available to apply once again during payment.
        </Typography>
      </Grid>
    </Grid>
  ) : null;
};

export default CartCoupons;
