import React, {
  createContext,
  useReducer,
  useCallback,
  useContext
} from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { getFavouriteShopByUser } from './favouriteShopsQueries';
import {
  createFavouriteShop,
  updateFavouriteShop
} from './favouriteShopsMutations';

const FavouriteShopsContext = createContext({});

const favouriteShopsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'updateData': {
      return payload || [];
    }
    case 'addData': {
      return [...state, payload];
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

const FavouriteShopsProvider = (props) => {
  const [favouriteShops, dispatch] = useReducer(favouriteShopsReducer, []);

  const asyncDispatch = useCallback(
    async (action) => {
      switch (action.type) {
        case 'getFavouriteShops': {
          try {
            const { userId, limit = 5000 } = action.payload;
            const resp = await API.graphql(
              graphqlOperation(getFavouriteShopByUser, {
                userID: userId,
                limit
              })
            );
            const data = resp.data.getFavouriteShopByUser.items;
            dispatch({ type: 'updateData', payload: data });
          } catch (e) {
            console.log(e);
          }
          break;
        }
        case 'createFavouriteShops': {
          try {
            const resp = await API.graphql(
              graphqlOperation(createFavouriteShop, {
                input: action.payload
              })
            );
            const data = resp.data.createFavouriteShop;
            dispatch({ type: 'addData', payload: data });
          } catch (e) {
            console.log(e);
          }
          break;
        }
        case 'updateFavouriteShops': {
          try {
            const resp = await API.graphql(
              graphqlOperation(updateFavouriteShop, {
                input: action.payload
              })
            );
            const data = resp.data.updateFavouriteShop;
            dispatch({
              type: 'updateData',
              payload: favouriteShops.map((item) =>
                item.id === data.id ? data : item
              )
            });
          } catch (e) {
            console.log(e);
          }
          break;
        }
        default: {
          break;
        }
      }
    },
    [favouriteShops]
  );

  const value = {
    favouriteShops,
    dispatch: asyncDispatch
  };

  return (
    <FavouriteShopsContext.Provider value={value}>
      {props.children}
    </FavouriteShopsContext.Provider>
  );
};

const useFavouriteShops = () => {
  const context = useContext(FavouriteShopsContext);
  if (context === undefined || !Object.keys(context).length) {
    throw new Error(
      'useFavouriteShops must be used within a FavouriteShopsContext'
    );
  }
  return context;
};

export { FavouriteShopsProvider, useFavouriteShops };
