import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PaymentIcon from '@material-ui/icons/Payment';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import HttpsOutlinedIcon from '@material-ui/icons/HttpsOutlined';

const tabs = [
  {
    name: 'Profile Information',
    icon: <EditOutlinedIcon fontSize="small" />
  },
  {
    name: 'Payment Methods',
    icon: <PaymentIcon fontSize="small" />
  },
  {
    name: 'Manage Addresses',
    icon: <LocationOnOutlinedIcon fontSize="small" />
  },
  {
    name: 'Change Password',
    icon: <HttpsOutlinedIcon fontSize="small" />
  }
];

const addressAttributes = {
  buildingNumber: 'Building No:',
  buildingName: 'Building Name:',
  line1: 'Line 1:',
  line2: 'Line 2:',
  postcode: 'Post Code:'
};

export { tabs, addressAttributes };
