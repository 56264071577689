import React, { useState } from 'react';
import './cookies.css';

const Cookies = () => {
  const [showCookies, setShowCookies] = useState(
    !localStorage.getItem('cookie')
  );

  const cookieBtn = () => {
    localStorage.setItem('cookie', 'true');
    setShowCookies(false);
  };

  return (
    showCookies && (
      <div className="cookie-container">
        <p>
          We use cookies to ensure you have the best browsing experience on our
          website. By using our site, you acknowledge that you have understood
          our Cookie Policy &amp; Privacy Policy
        </p>
        <button className="cookie-btn" onClick={cookieBtn}>
          Okay
        </button>
        <button
          className="cookie-btn closed"
          onClick={() => setShowCookies(false)}>
          Close
        </button>
      </div>
    )
  );
};

export default Cookies;
