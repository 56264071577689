import React, { useEffect, useState } from 'react';
import { DocumentDeleteDialog } from 'components/organisms';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useCoupons } from 'context/coupons/couponsContext';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Pagination } from '@material-ui/lab';
import CouponFilterTools from '../CouponFilterTools';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '2rem 0 0'
  },
  couponItemContainer: {
    padding: '1rem',
    boxShadow:
      'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
    borderRadius: 4
  },
  btnContainer: {
    marginTop: '1rem',
    gap: '1rem'
  },
  deleteBtn: {
    height: 'fit-content',
    color: 'white',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light
    }
  },
  editBtn: {
    height: 'fit-content',
    color: 'white'
  },
  listRoot: {
    width: '100%'
  }
}));

const INITIAL_COUPON_DATA = {
  page: 1,
  startDate: '',
  endDate: '',
  showCurrentCoupons: true
};
const CURR_TIME = new Date().toISOString();

const CouponTable = () => {
  const classes = useStyles();
  const history = useHistory();
  const { coupons = [], dispatch } = useCoupons();
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState('');
  const [filters, setFilters] = useState(INITIAL_COUPON_DATA);
  const [showResetButton, setShowResetButton] = useState(false);

  const ITEMS_PER_PAGE = 8;

  useEffect(() => {
    const query = Object.fromEntries(
      history.location.search
        .slice(1)
        .split('&')
        .filter((item) => !!item)
        .map((item) => {
          const temp = item.split('=');
          if (temp) {
            if (temp[0] === 'showCurrentCoupons') {
              temp[1] = temp[1] === 'true' ? true : false;
            } else if (temp[0] === 'page') {
              temp[1] = Number(temp[1]);
            }
          }
          return temp;
        })
    );

    setFilters({ ...filters, ...query });

    if (
      Object.keys(query).length > 0 &&
      (!!query.startDate || !!query.endDate || !query.showCurrentCoupons)
    ) {
      setShowResetButton(true);
    }
  }, []);

  useEffect(() => {
    history.replace(
      `/service-provider/coupons?${Object.entries(filters)
        .map(([key, value]) => (value !== '' ? `${key}=${value}` : ''))
        .filter((item) => !!item)
        .join('&')}`
    );
  }, [filters]);

  const filterCoupons = coupons
    ?.filter((coupon) =>
      filters.showCurrentCoupons
        ? CURR_TIME <= coupon?.endDateTime
        : CURR_TIME > coupon?.endDateTime
    )
    ?.filter((coupon) =>
      filters?.startDate && filters?.endDate
        ? (filters?.startDate <= coupon.startDateTime &&
            coupon.startDateTime <= filters?.endDate) ||
          (filters?.startDate <= coupon.endDateTime &&
            coupon.endDateTime <= filters?.endDate)
        : true
    );

  const noOfPages = Math.ceil(filterCoupons?.length / ITEMS_PER_PAGE);

  const displayCoupons = filterCoupons
    ?.slice((filters.page - 1) * ITEMS_PER_PAGE, filters.page * ITEMS_PER_PAGE)
    ?.sort((a, b) => b._lastChangedAt - a._lastChangedAt);

  const handleDelete = (id) => () => {
    setSelectedCoupon(id);
    setShowDeleteConfirmDialog(true);
  };

  const handleEdit = (item) => () => {
    history.push(`/service-provider/coupons/edit?id=${item.id}`);
  };

  const handleModalClose = () => setShowDeleteConfirmDialog(false);

  const handleDeleteConfirm = () => {
    dispatch({
      type: 'deleteCoupon',
      payload: { couponID: selectedCoupon }
    });
    if (displayCoupons.length === 1 && filters.page !== 1) {
      setFilters({
        ...filters,
        page: filters.page - 1
      });
    }
    handleModalClose();
  };

  const paginationHandler = (event, value) => {
    setFilters({
      ...filters,
      page: Number(value)
    });
  };

  const handleFilterUpdate = (e) => {
    setFilters({
      ...filters,
      page: 1,
      [e.target.name]: e.target.value
    });

    setShowResetButton(true);
  };

  const handleResetFilters = () => {
    setShowResetButton(false);
    setFilters(INITIAL_COUPON_DATA);
  };

  return (
    <>
      <CouponFilterTools
        filters={filters}
        handleFilterUpdate={handleFilterUpdate}
        handleResetFilters={handleResetFilters}
        showResetButton={showResetButton}
      />
      <Grid container className={classes.root}>
        {displayCoupons?.length > 0 ? (
          displayCoupons.map((item, index) => (
            <Grid
              container
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={`coupons-table-items-${index}`}
              style={{ padding: '1rem' }}>
              <Grid
                container
                item
                xs={12}
                className={classes.couponItemContainer}>
                <Grid container item alignItems="center">
                  <Grid item xs={12}>
                    <Typography variant="h4">{item.title || ''}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ marginTop: 5 }}>
                      {item.description || ''}
                    </Typography>
                  </Grid>

                  <Grid item style={{ marginTop: 5 }}>
                    <Typography variant="h6">
                      {`From:
                    ${moment(item?.startDateTime).format(
                      'MMMM Do YYYY, hh:mm a'
                    )}`}
                    </Typography>
                    <Typography variant="h6">
                      {`To: ${moment(item?.endDateTime).format(
                        'MMMM Do YYYY, hh:mm a'
                      )}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  className={classes.btnContainer}
                  alignItems="flex-end">
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    className={classes.editBtn}
                    onClick={handleEdit(item)}>
                    Edit
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    className={classes.deleteBtn}
                    onClick={handleDelete(item.id)}>
                    Delete
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ))
        ) : (
          <Grid container justify="center" style={{ marginTop: '1rem' }}>
            <Typography variant="h4">No coupons available</Typography>
          </Grid>
        )}

        {noOfPages > 1 && (
          <Grid
            container
            item
            xs={12}
            justify="center"
            style={{ marginTop: '2rem' }}>
            <Pagination
              count={noOfPages}
              page={filters.page}
              onChange={paginationHandler}
              defaultPage={1}
              color="secondary"
              shape="rounded"
            />
          </Grid>
        )}

        <DocumentDeleteDialog
          open={showDeleteConfirmDialog}
          handleCancel={handleModalClose}
          handleConfirm={handleDeleteConfirm}
          confirmText="delete this coupon"
        />
      </Grid>
    </>
  );
};

export default CouponTable;
