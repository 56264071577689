import React, { useEffect, useState } from 'react';
import {
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from '@material-ui/core';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { useOrders } from 'context/order/ordersContext';
import { useLoader } from 'layouts/loaderContext';
import {
  unitFormatter,
  getFormattedName,
  getOrderTotalRating
} from 'common/utilFunctions';
import Rating from '@material-ui/lab/Rating';
import OrderDetailsDialog from 'views/Orders/components/dialogs/OrderDetailsDialog';
import { showPrice } from 'common/utilFunctions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '1rem'
  },
  content: {
    marginTop: theme.spacing(2)
  },
  searchContainer: {
    margin: '1rem 0'
  },
  orderId: {
    cursor: 'pointer'
  },
  tableHeading: {
    backgroundColor: '#fff',
    padding: '0.5rem'
  },
  paidStatus: {
    color: '#4caf50',
    fontWeight: 500
  }
}));

const AdminOrdersTable = ({
  selectedShop,
  filterValue,
  monthRange,
  page,
  setPage
}) => {
  const classes = useStyles();
  const [sortDirection, setSortDirection] = useState('asc');
  const { loading } = useLoader();
  const { orders, isMoreOrdersAvailable, dispatch } = useOrders();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState(null);
  const [orderDetailDialog, setOrderDetailDialog] = useState(false);

  useEffect(() => {
    dispatch({
      type: 'sortOrders',
      payload: {
        property: 'orderId',
        direction: sortDirection
      }
    });
  }, [sortDirection]);

  useEffect(() => {
    setPage(0);
  }, [filterValue, monthRange]);

  const handleRequestSort = (event) => {
    setSortDirection((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
  };

  const filteredOrders = orders
    ?.filter((order) => (filterValue ? order.status === filterValue : true))
    .filter(
      (order) =>
        moment(order.createdAt).format('YYYY-MM') >= monthRange.start &&
        moment(order.createdAt).format('YYYY-MM') <= monthRange.end
    );

  const orderDetailHandler = (order) => () => {
    setOrderDetailDialog(true);
    setOrder(order);
  };

  const handleModalClose = () => {
    setOrderDetailDialog(false);
    setOrder(null);
  };

  const handlePageChange = (_event, page) => {
    setPage(page);
  };
  const handleRowsPerPageChange = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <div className={classes.root}>
      {selectedShop ? (
        orders.length < 1 && !loading ? (
          <Typography align="center" variant="body1" style={{ fontSize: 18 }}>
            This shop has not initiated order processing.
          </Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="user-orders-table">
              <TableHead>
                <TableRow style={{ backgroundColor: '#F6F6F6' }}>
                  <TableCell align="left">CUSTOMER</TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={true}
                      direction={sortDirection}
                      onClick={handleRequestSort}>
                      ORDER NO.
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">DATE ORDERED</TableCell>
                  <TableCell align="center">ORDER DELIVERED</TableCell>
                  <TableCell align="center">STATUS</TableCell>
                  <TableCell align="center">PAYMENT</TableCell>
                  <TableCell align="center">RATINGS</TableCell>
                </TableRow>
              </TableHead>

              {filteredOrders?.length > 0 ? (
                <>
                  <TableBody>
                    {filteredOrders?.length > 0
                      ? filteredOrders
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((order, index) => {
                            const amountWithStatus =
                              order.paymentStatus === 'unpaid' &&
                              order.paymentMethod === 'card'
                                ? `Authorized ${showPrice(
                                    order.onHoldPaymentAmount
                                  )}`
                                : order.paymentStatus === 'unpaid' &&
                                  order.paymentMethod === 'cash'
                                ? `${unitFormatter(
                                    order.paymentStatus
                                  )} ${showPrice(order.total)}`
                                : `${unitFormatter(
                                    order.paymentStatus
                                  )} ${showPrice(order.paidAmount)}`;

                            return (
                              <TableRow
                                key={`user-order-table-row-${order.id}`}
                                style={{
                                  backgroundColor:
                                    index % 2 !== 0 ? '#F6F6F6' : '#FFFEFE'
                                }}>
                                <TableCell align="left">
                                  {getFormattedName(order.userDetail)}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="center"
                                  className={classes.orderId}>
                                  <Link onClick={orderDetailHandler(order)}>
                                    {order.orderID}
                                  </Link>
                                </TableCell>
                                <TableCell align="center">
                                  {moment(order.createdAt).format(
                                    'MMMM Do YYYY'
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  {order.delivery?.date
                                    ? moment(order.delivery?.date).format(
                                        'MMMM Do YYYY'
                                      )
                                    : 'N/A'}
                                </TableCell>
                                <TableCell align="center">
                                  {unitFormatter(order?.status)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={
                                    order.paymentStatus === 'paid'
                                      ? classes.paidStatus
                                      : ''
                                  }>
                                  {amountWithStatus}
                                </TableCell>
                                <TableCell align="center">
                                  {order.customerReview.items.length > 0 ? (
                                    <Rating
                                      value={getOrderTotalRating(
                                        order.customerReview.items[0].ratings
                                      )}
                                      precision={0.5}
                                      readOnly
                                    />
                                  ) : (
                                    'Not yet rated'
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })
                      : null}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        count={
                          isMoreOrdersAvailable ? -1 : filteredOrders.length
                        }
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowsPerPageChange}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                      />
                    </TableRow>
                  </TableFooter>
                </>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Typography variant="h6" align="center">
                        No Records Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )
      ) : null}

      <OrderDetailsDialog
        order={order}
        showOrderDetails={orderDetailDialog}
        handleModalClose={handleModalClose}
        isOrdersModal={false}
        showClosedBtn={true}
      />
    </div>
  );
};

export default AdminOrdersTable;
