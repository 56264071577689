import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { useAdTemplateStyles } from '../templateStyles';
import { Image } from 'components/organisms';
import TemplateFooter from './TemplateFooter';
import clsx from 'clsx';

const Template6 = ({
  content = {
    image: ''
  },
  shopName = '',
  cardStyles = ''
}) => {
  const classes = useAdTemplateStyles();

  return (
    <Grid
      container
      item
      justify="space-between"
      component={Paper}
      className={clsx(cardStyles, classes.shopListAdsRootStyles)}>
      <Grid container item xs={12} className={classes.shopListAdsImage}>
        <Grid item xs={12}>
          <Image
            docKey={content?.image || ''}
            loadFromStorage={content?.image?.split('/').at(0) === 'images'}
            width="100%"
            alt="Advertisement image"
            className={classes.shopListAdsImage}
          />
        </Grid>
      </Grid>
      <TemplateFooter shopName={shopName} />
    </Grid>
  );
};

export default Template6;
