import React from 'react';
import moment from 'moment';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image
} from '@react-pdf/renderer';
import {
  showPrice,
  unitFormatter,
  getCollectionAndDeliveryFee,
  getCollectionAndDeliveryFeeText,
  getOrderTotal,
  showPartialPaymentRecord,
  capitalizeStr,
  inWords,
  getFormattedPriceRange,
  addRangeToPrice,
  getFormattedPhoneNumber
} from 'common/utilFunctions';
import MainLogo from '../../assets/png/Launder-it-logo.png';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    fontSize: '14px',
    padding: 10,
    lineHeight: 1.5
  },
  logoContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 5
  },
  logo: {
    width: 150
  },
  tableHeaderContainer: {
    backgroundColor: '#bff0fd',
    textAlign: 'center',
    flexDirection: 'row',
    padding: 10,
    justifyContent: 'space-between'
  },
  tableHeader: {
    fontSize: '12px',
    width: '25%'
  },
  leftAlign: {
    textAlign: 'left'
  },
  rightAlign: {
    textAlign: 'right'
  },
  centerAlign: {
    textAlign: 'center'
  },
  tableContent: {
    fontSize: '12px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    borderBottom: 1,
    borderBottomColor: '#bff0fd'
  },
  paymentContent: {
    fontSize: '10px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 2,
    marginRight: 8,
    marginTop: 10,
    marginBottom: 10,
    borderBottom: 1,
    borderBottomColor: '#bff0fd'
  },
  tableCell: {
    marginLeft: 10
  },
  tableTotalContainer: {
    width: '50%',
    marginLeft: '50%'
  },
  tableTotalContent: {
    fontSize: '12px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10
  },
  tableDiscountContent: {
    fontSize: '10px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10
  },
  totalPrice: {
    fontSize: '13px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    backgroundColor: '#bff0fd'
  },
  tableCellWidth: {
    width: '20%'
  },
  paymentStatus: {
    textAlign: 'center',
    fontSize: '12px',
    marginTop: '15px'
  }
});

const OrderTable = ({ orderList, total, order, payment }) => {
  let balance = total;

  return (
    <View>
      <View style={styles.tableHeaderContainer}>
        <Text style={[styles.tableHeader, styles.leftAlign]}>ITEM</Text>
        <Text style={[styles.tableHeader, styles.centerAlign]}>QUANTITY</Text>
        {!order.bidID ? (
          <Text style={[styles.tableHeader, styles.centerAlign]}>PRICE</Text>
        ) : null}
        <Text style={[styles.tableHeader, styles.centerAlign]}>DISCOUNT</Text>
        <Text style={[styles.tableHeader, styles.rightAlign]}>TOTAL</Text>
      </View>
      {orderList.length > 0 &&
        orderList.map((item, index) => (
          <View
            style={styles.tableContent}
            key={`order-document-pdf-order-items-${index}-${item.productID}`}>
            <Text
              style={[
                styles.tableCellWidth,
                styles.leftAlign
              ]}>{`${item.name}`}</Text>
            <Text style={[styles.tableCellWidth, styles.centerAlign]}>
              {`${item.quantity}`}
            </Text>
            {!order.bidID ? (
              <Text style={[styles.tableCellWidth, styles.centerAlign]}>
                {`${showPrice(item.price)} ${unitFormatter(
                  item.unit
                )}`.toLowerCase()}
              </Text>
            ) : null}
            <Text style={[styles.tableCellWidth, styles.centerAlign]}>
              {item.discount
                ? `${showPrice(item.discount || 0)} ${unitFormatter(
                    item.unit
                  )}`.toLowerCase()
                : item.overallDiscount
                ? `${showPrice(item.overallDiscount)}`
                : 0}
            </Text>
            <Text style={[styles.tableCellWidth, styles.rightAlign]}>
              {item.totalPrice
                ? showPrice(item.totalPrice)
                : showPrice(
                    item.price * item.quantity - (item.overallDiscount || 0)
                  )}
            </Text>
          </View>
        ))}
      <View style={styles.tableTotalContainer}>
        <View style={styles.tableTotalContent}>
          <Text>Subtotal</Text>
          <Text>
            {!!order.variableTotal
              ? getFormattedPriceRange(
                  addRangeToPrice(
                    getOrderTotal(order) || 0,
                    order.variableTotal
                  )
                )
              : showPrice(getOrderTotal(order) || 0)}
          </Text>
        </View>
        {order.coupons?.length > 0 ? (
          <View>
            <View style={styles.tableTotalContent}>
              <Text>Coupons</Text>
            </View>
            {order.coupons.map((_item, index) => {
              const orderCoupon = JSON.parse(_item);
              return (
                <View
                  style={styles.tableDiscountContent}
                  key={`order-coupons-${orderCoupon.id || ''}-items-${index}`}>
                  <Text>{orderCoupon.title || ''}</Text>
                  <Text>
                    - {showPrice(orderCoupon.discountAmountApplied || 0)}
                  </Text>
                </View>
              );
            })}
          </View>
        ) : null}
        {process.env.REACT_APP_VAT === 'enabled' ? (
          <View style={styles.tableTotalContent}>
            <Text>VAT (20%)</Text>
            <Text>{showPrice(order?.tax || 0)}</Text>
          </View>
        ) : null}
        {order?.collection?.type === 'asap' ? (
          <View style={styles.tableTotalContent}>
            <Text>ASAP Fee</Text>
            <Text>{showPrice(order.shop?.AsapDeliveryFee)}</Text>
          </View>
        ) : null}
        {order?.collectionAndDeliveryFee > 0 ? (
          <View style={styles.tableTotalContent}>
            <Text>{getCollectionAndDeliveryFeeText(order)}</Text>
            <Text>{getCollectionAndDeliveryFee(order)}</Text>
          </View>
        ) : null}
        <View style={styles.totalPrice}>
          <Text>Total : in {order.paymentMethod}</Text>
          <Text>
            {!!order.variableTotal
              ? getFormattedPriceRange(
                  addRangeToPrice(order.total || 0, order.variableTotal)
                )
              : showPrice(order.total || 0)}
          </Text>
        </View>

        {payment?.length > 0 &&
          (showPartialPaymentRecord(payment, total) ? (
            <>
              {payment.map((el, i) => {
                balance -= el.amount;

                return (
                  <View
                    key={`partialPaymentRecordsItems-${i}`}
                    style={styles.paymentContent}>
                    <View colSpan={2}>
                      <Text>
                        Partial {unitFormatter(order.paymentMethod)} Payment on
                        :&nbsp;
                        {moment(el.date).format('DD/MM/YYYY, h:mm a')}
                        &ensp;
                      </Text>
                      <br />
                      <Text>
                        {balance <= 0 ? 'Total Amount Due:' : 'Balance Due:'}
                      </Text>
                    </View>
                    <View>
                      <Text align="right">{showPrice(el.amount)}</Text>
                      <br />
                      <Text>{showPrice(balance)}</Text>
                    </View>
                  </View>
                );
              })}
            </>
          ) : (
            <>
              <View style={styles.paymentContent}>
                <View colSpan={2}>
                  <Text>
                    {unitFormatter(order.paymentMethod)} Payment on :&nbsp;
                    {moment(payment[0].date).format('DD/MM/YYYY, h:mm a')}
                    &ensp;
                  </Text>
                </View>
                <View>
                  <Text align="right">{showPrice(payment[0].amount)}</Text>
                </View>
              </View>
            </>
          ))}

        {order.onHoldPaymentAmount > 0 && order.paymentMethod === 'card' && (
          <View style={styles.tableTotalContent}>
            <View colSpan={2}>
              <Text>Authorised Amount</Text>
            </View>
            <Text align="right">{showPrice(order.onHoldPaymentAmount)}</Text>
          </View>
        )}

        {order.paymentStatus === 'paid' && order.paymentMethod === 'card' && (
          <>
            <View style={styles.tableTotalContent}>
              <View colSpan={2}>
                <Text>Refunded</Text>
              </View>
              <Text align="right">
                {showPrice(
                  order.total > order.onHoldPaymentAmount
                    ? order.onHoldPaymentAmount
                    : order.onHoldPaymentAmount - order.total
                )}
              </Text>
            </View>

            <View style={styles.tableTotalContent}>
              <View colSpan={2}>
                <Text>Finally paid</Text>
              </View>
              <Text align="right">{showPrice(order.total || 0)}</Text>
            </View>
          </>
        )}
      </View>
    </View>
  );
};

// PDF Document Component
const OrderDocumentPDF = ({ order, payment }) => {
  const forwardedFrom = order?.forwardRequests?.items[0]?.requestShop.name;
  const forwardedTo =
    order?.forwardRequests?.items[0]?.acceptedShop?.name || 'pending';
  const forwardReason = order?.forwardRequests?.items[0]?.forwardReason;

  return (
    <Document>
      <Page size="A4">
        <View style={styles.logoContainer}>
          <Image src={MainLogo} style={styles.logo} alt="launder-it logo" />
        </View>
        <View>
          <Text
            style={{
              textAlign: 'center',
              fontSize: '16px',
              marginTop: 15,
              backgroundColor: '#bff0fd',
              padding: 10
            }}>
            {order?.shop?.name.toUpperCase()}
          </Text>
          <Text
            style={{
              textAlign: 'center',
              fontSize: '11px',
              marginBottom: 15,
              marginTop: '5px',
              padding: 2
            }}>
            {order?.shop?.address}.
          </Text>
        </View>

        <View style={styles.section}>
          <View>
            <Text style={{ fontSize: '15px' }}>{order?.orderID}</Text>
            <Text style={{ fontSize: '12px' }}>
              Ordered on
              {moment(order?.createdAt).format(' MMMM Do YYYY')}
            </Text>
            <Text
              style={{
                fontSize: '12px'
              }}>
              {order?.collection?.date &&
                `${
                  !!order?.collection?.address ? 'Collection' : 'Drop'
                } Date: ${moment(order?.collection?.date).format(
                  ' MMMM Do YYYY'
                )}`}
            </Text>
            <Text
              style={{
                fontSize: '12px'
              }}>
              {order?.delivery?.date &&
                `${
                  !!order?.delivery?.address ? 'Delivery' : 'Pickup'
                } Date: ${moment(order?.delivery?.date).format(
                  ' MMMM Do YYYY'
                )}`}
            </Text>
            <View>
              <Text style={{ fontSize: '12px' }}>
                Contact Name:
                {`${order?.userDetail?.title || ''} ${
                  order?.userDetail?.firstName || ''
                } ${order?.userDetail?.lastName || ''}`}
              </Text>
              <Text style={{ fontSize: '12px' }}>
                {`Email address: ${order?.userDetail?.email}`}
              </Text>
              <Text style={{ fontSize: '12px' }}>
                Phone number:{' '}
                {getFormattedPhoneNumber(order?.userDetail?.phoneNumber)}
              </Text>
            </View>
          </View>

          {forwardedFrom ? (
            <View>
              <Text style={{ fontSize: '12px' }}>
                Order placed on: {forwardedFrom} -{' '}
                <Text style={{ color: '#f94e4e' }}>{forwardReason}</Text>
              </Text>
              <Text style={{ fontSize: '12px' }}>
                Order accepted by: {forwardedTo}
              </Text>
            </View>
          ) : null}

          <View>
            {order?.collection?.address && (
              <View>
                <Text style={{ fontSize: '12px' }}>
                  Collection Address:{' '}
                  {JSON.parse(order?.collection?.address)['line1']}
                  {','}
                  {JSON.parse(order?.collection?.address)['town']}
                </Text>
              </View>
            )}
            {order?.delivery?.address && (
              <View>
                <Text style={{ fontSize: '12px' }}>
                  Delivery Address:{' '}
                  {JSON.parse(order?.delivery?.address)['line1']}
                  {','}
                  {JSON.parse(order?.delivery?.address)['town']}
                </Text>
              </View>
            )}
            {order?.collection?.type === 'asap' && (
              <Text
                style={{
                  textAlign: 'center',
                  backgroundColor: '#e53935e3',
                  color: '#ffff',
                  marginTop: '5px',
                  borderRadius: '2px',
                  padding: '2px'
                }}>
                This is an ASAP Order
              </Text>
            )}
          </View>
        </View>

        <View>
          <OrderTable
            orderList={order?.orderList || []}
            total={order?.total || 0}
            order={order}
            payment={payment}
          />
        </View>

        {order?.paymentStatus === 'paid' && (
          <View>
            <Text style={styles.paymentStatus}>
              A {unitFormatter(order.paymentMethod)} Payment of{' '}
              {capitalizeStr(inWords(order?.total))} is Paid.
            </Text>
          </View>
        )}
        {order?.paymentStatus === 'partiallyPaid' && (
          <View>
            <Text style={styles.paymentStatus}>
              Payment of {showPrice(order?.paidAmount)} out of{' '}
              {showPrice(order?.total)} was Paid
            </Text>
          </View>
        )}
        {order?.paymentStatus === 'unpaid' && (
          <View>
            <Text style={styles.paymentStatus}>Payment unpaid</Text>
          </View>
        )}

        <View>
          <Text
            style={{
              textAlign: 'center',
              fontSize: '15px',
              marginTop: '20px'
            }}>
            Thank You for choosing {order?.shop?.name}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default OrderDocumentPDF;
