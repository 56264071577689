import React from 'react';
// import { Context } from '../../../context/shopDetail/shopContext';
import { CardActions, Grid, Button } from '@material-ui/core';

// const buttonAction = {
//   SUBMIT: 0,
//   SAVE: 1,
//   SAVE_AND_CLOSE: 2
// };

function ActionButtons(props) {
  // const { selectedShop } = useContext(Context);
  const {
    // isAddNewPage,
    // pageType,
    // setDeleteDialog
    handleCancel,
    handleSubmit,
    disabled = false
  } = props;

  return (
    <Grid container item justify="flex-end" alignItems="center" xs={12}>
      <Button
        onClick={handleCancel}
        disabled={disabled}
        variant="outlined"
        size="small"
        style={{ marginRight: '0.5rem' }}>
        Cancel
      </Button>
      <Button
        color="primary"
        size="small"
        onClick={handleSubmit}
        disabled={disabled}
        variant="contained">
        Save
      </Button>
    </Grid>
  );
}

export default ActionButtons;

// /* {pageType != 'shop' && (
//       <Button
//         color="primary"
//         onClick={e => handleSubmit(e, buttonAction.SAVE_AND_CLOSE)}
//         variant="outlined">
//         Save and Close
//       </Button>
//     )}
//     {pageType != 'shop' && (
//       <Button
//         color="primary"
//         onClick={() => {
//           setDeleteDialog(true);
//         }}
//         variant="outlined">
//         Delete
//       </Button>
//     )} */
// /* {isAddNewPage ? (
//   <Button
//     color="primary"
//     disabled={!selectedShop}
//     type="submit"
//     variant="contained">
//     Add Shop details
//   </Button>
// ) : (
// )} */
