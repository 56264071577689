import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button,
  Menu,
  MenuItem,
  Grid
} from '@material-ui/core';
import { useLoader } from 'layouts/loaderContext';
import { getInitials } from 'helpers';
import { API, graphqlOperation } from 'aws-amplify';
import { getAllShopUsers } from 'graphql/queries';
import { disableUser, enableUser, resetPassword } from 'graphql/mutations';
import { useAmplifyAuth } from 'context';
import { searchStringInItems } from 'common/utilFunctions';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  disabled: {
    color: 'red'
  },
  enabled: {
    color: 'green'
  },
  tableButton: {
    textTransform: 'none'
  }
}));

const UsersTable = (props) => {
  const {
    className,
    searchBy,
    selectedShopId,
    handleUserNameClick = () => {},
    ...rest
  } = props;
  const classes = useStyles();
  const { setLoading } = useLoader();
  const [users, setUsers] = useState([]);
  const {
    state: { user, isLaundryAdmin }
  } = useAmplifyAuth();
  const [selectedUsers, setSelectedUsers] = useState({});
  const [isUserEnabled, setIsUserEnabled] = useState(false);
  const [enableResetPassword, setEnableResetPassword] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleEnableUser = (e) => {
    if (selectedUsers) {
      API.graphql(
        graphqlOperation(enableUser, {
          username: selectedUsers.Username,
          id: selectedUsers.Attributes.find((item) => item.Name === 'sub')
            ?.Value
        })
      ).then(() => fetchUser());
    }
    setAnchorEl(null);
  };

  const handleDisableUser = (e) => {
    if (selectedUsers) {
      API.graphql(
        graphqlOperation(disableUser, {
          username: selectedUsers.Username,
          id: selectedUsers.Attributes.find((item) => item.Name === 'sub')
            ?.Value
        })
      ).then(() => fetchUser());
    }
    setAnchorEl(null);
  };

  const handleResetPassword = (e) => {
    if (selectedUsers) {
      API.graphql(
        graphqlOperation(resetPassword, {
          username: selectedUsers.Username
        })
      ).then(() => fetchUser());
    }
    setAnchorEl(null);
  };

  const handleClick = (event, user, isEnabled, enableReset) => {
    setSelectedUsers(user);
    setIsUserEnabled(isEnabled);
    setEnableResetPassword(enableReset);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const getName = (attributes) => {
    const firstName = attributes.find(
      ({ Name }) => Name === 'custom:firstName'
    ) || { Value: '' };
    const lastName = attributes.find(
      ({ Name }) => Name === 'custom:lastName'
    ) || { Value: '' };
    return `${firstName.Value} ${lastName.Value}`;
  };

  const getAttribute = (attributes, attributeName) => {
    const attribute = attributes.find(({ Name }) => Name === attributeName) || {
      Value: ''
    };
    return attribute.Value;
  };

  const fetchUser = () => {
    if (!(user && user.username && selectedShopId)) return;
    setLoading(true);
    API.graphql(
      graphqlOperation(getAllShopUsers, {
        shopId: selectedShopId
      })
    )
      .then(({ data }) => {
        const users = data.getAllShopUsers;
        const sortedUsers = users.sort(
          (a, b) =>
            new Date(b?.UserLastModifiedDate) -
            new Date(a?.UserLastModifiedDate)
        );
        setUsers(sortedUsers);
      })
      .catch((error) => console.log('error', error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    !!selectedShopId && fetchUser();
  }, [selectedShopId, isLaundryAdmin, user]);

  const filteredUsers = users.filter((user) =>
    !!user.Username
      ? searchStringInItems(searchBy, [
          user.Username,
          getName(user.Attributes),
          getAttribute(user.Attributes, 'phone_number')
        ])
      : true
  );

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>User Status</TableCell>
                  <TableCell>Enabled/Disabled</TableCell>
                  <TableCell> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user, index) => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={`admin-users-table-rows-${index}`}
                      selected={selectedUsers === user}>
                      <TableCell>
                        <Button
                          onClick={handleUserNameClick(
                            getAttribute(user.Attributes, 'sub')
                          )}
                          className={classes.tableButton}>
                          <Grid container alignItems="center">
                            <Avatar
                              className={classes.avatar}
                              src={user.avatarUrl}>
                              {getInitials(getName(user.Attributes))}
                            </Avatar>
                            <Typography variant="body1">
                              {getName(user.Attributes)}
                            </Typography>
                          </Grid>
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={handleUserNameClick(
                            getAttribute(user.Attributes, 'sub')
                          )}
                          className={classes.tableButton}>
                          {user.Username}
                        </Button>
                      </TableCell>
                      <TableCell>
                        {getAttribute(user.Attributes, 'phone_number')}
                      </TableCell>
                      <TableCell>{user.UserStatus}</TableCell>
                      <TableCell>
                        {user.Enabled ? (
                          <Typography
                            className={classes.enabled}
                            variant="body1">
                            Enabled
                          </Typography>
                        ) : (
                          <Typography
                            className={classes.disabled}
                            variant="body1">
                            Disabled
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={(e) =>
                            handleClick(
                              e,
                              user,
                              user.Enabled,
                              user.UserStatus === 'CONFIRMED'
                            )
                          }
                          variant="outlined">
                          Action
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {(searchBy || selectedShopId) && filteredUsers?.length < 1 ? (
              <Typography
                align="center"
                variant="h6"
                style={{ paddingTop: '1rem' }}>
                No user found
              </Typography>
            ) : null}
            <Menu
              anchorEl={anchorEl}
              id="simple-menu"
              keepMounted
              onClose={handleClose}
              open={Boolean(anchorEl)}>
              {isUserEnabled ? (
                <MenuItem onClick={handleDisableUser}>Disable</MenuItem>
              ) : (
                <MenuItem onClick={handleEnableUser}>Enable</MenuItem>
              )}
              <MenuItem
                disabled={!enableResetPassword}
                onClick={handleResetPassword}>
                Reset Password
              </MenuItem>
            </Menu>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={filteredUsers.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string
  // users: PropTypes.array.isRequired
};

export default UsersTable;
