import React, { useState, useEffect } from 'react';
import SearchShop from 'components/SearchShop';
import { Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useRedirects } from 'context/redirect/redirectsContext';
import { RedirectsTable } from './components';
import { useShops } from 'context/shop/shopsContext';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1.5rem',
    gap: '1rem'
  },
  searchBarDiv: {
    [theme.breakpoints.up('md')]: {
      padding: '0 0.5rem'
    }
  }
}));

const Insights = () => {
  const classes = useStyles();
  const [shop, setShop] = useState(null);
  const { shops } = useShops();
  const [filterDate, setFilterDate] = useState('');
  const { redirects, selectedShopID, selectedDate, dispatch } = useRedirects();
  const [redirectsByDate, setRedirectsByDate] = useState([]);

  useEffect(() => {
    if (redirects.length > 0 && shops.length > 0) {
      const currentShop = shops.find((item) => item?.id === selectedShopID);
      currentShop && setShop(currentShop);
      selectedDate && setFilterDate(selectedDate);
    }
  }, []);

  useEffect(() => {
    if (shop?.id) {
      dispatch({
        type: 'getRedirects',
        payload: { shopID: shop.id, newQuery: true, date: filterDate }
      });
    }
  }, [shop, filterDate]);

  useEffect(() => {
    const dates = [
      ...new Set(
        redirects.map((item) => item?.createdAt?.split('T')?.at(0) || '')
      )
    ];
    const results = dates
      .filter((item) => !!item)
      .map((item) => ({
        date: item,
        redirects: redirects
          .filter((redirectItem) =>
            redirectItem?.createdAt
              ? redirectItem?.createdAt?.split('T')?.at(0) === item
              : false
          )
          .map((item) => ({ ...item, event: JSON.parse(item.event) }))
      }));
    setRedirectsByDate(results);
  }, [redirects]);

  const handleShopSelect = async (e, selectedShop) => {
    setShop(selectedShop);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h3">
          Records of Redirects to Shop Website
        </Typography>
      </Grid>
      <Grid item xs={12} container justify="space-between">
        <Grid item xs={12} md={6} className={classes.searchBarDiv}>
          <SearchShop onChange={handleShopSelect} value={shop} />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className={classes.searchBarDiv}
          container
          alignItems="center">
          {shop ? (
            <TextField
              type="date"
              label="Select Date"
              variant="outlined"
              value={filterDate}
              size="small"
              fullWidth
              onChange={(e) => setFilterDate(e.target.value)}
              inputProps={{
                max: new Date().toISOString().split('T').at(0)
              }}
              InputLabelProps={{ shrink: true }}
            />
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={12} container justify="center">
        {redirectsByDate.length > 0 ? (
          <RedirectsTable redirects={redirectsByDate} />
        ) : shop ? (
          <Typography variant="h6">No Data Found</Typography>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default Insights;
