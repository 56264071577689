import React, { useEffect, useState, useRef } from 'react';
import { Button, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ServicesToolbar, ServicesList } from './components';
import { useServices } from './serviceContext';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    margin: theme.spacing(-1),
    marginTop: theme.spacing(1),
    '& > *': {
      padding: theme.spacing(1)
    }
  },
  serviceHeading: {
    paddingBottom: '1.2rem'
  },
  divider: {
    margin: '1rem'
  },
  dialog: {
    minWidth: 500
  }
}));

const Services = () => {
  const classes = useStyles();
  const { services, dispatch } = useServices();
  const [showNewService, setShowNewService] = useState(false);
  const [isDomesticDrag, setIsDomesticDrag] = useState(false);
  const [isCommercialDrag, setIsCommercialDrag] = useState(false);
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const [orderedDomesticServices, setOrderedDomesticServices] = useState([]);
  const [orderedCommercialServices, setOrderedCommercialServices] = useState(
    []
  );

  useEffect(() => {
    dispatch({ type: 'getService' });
  }, []);

  useEffect(() => {
    const domesticServices = services
      .filter(
        (service) =>
          (!service.type || service.type === 'domestic') && !service.serviceID
      )
      .sort((a, b) => a.orderIndex - b.orderIndex);

    const commercialServices = services
      .filter((service) => service.type === 'commercial' && !service.serviceID)
      .sort((a, b) => a.orderIndex - b.orderIndex);

    setOrderedDomesticServices(domesticServices);
    setOrderedCommercialServices(commercialServices);
  }, [services]);

  const serviceTypes = [
    {
      id: 'DomesticServices',
      typeName: 'Domestic Services',
      servicesData: orderedDomesticServices
    },
    {
      id: 'CommercialServices',
      typeName: 'Commercial Services',
      servicesData: orderedCommercialServices
    }
  ];

  const handleDragStart = (index) => {
    dragItem.current = index;
  };

  const handleDragEnter = (index) => {
    dragOverItem.current = index;
  };

  const reorderServices = (serviceData, setServiceData) => {
    let _serviceData = [...serviceData];

    const draggedItemContent = _serviceData.splice(dragItem.current, 1)[0];
    _serviceData.splice(dragOverItem.current, 0, draggedItemContent);

    _serviceData.forEach((el, i) => (el.orderIndex = i));

    if (dragOverItem.current > dragItem.current) {
      for (let i = dragItem.current; i <= dragOverItem.current; i++) {
        _serviceData[i].modify = true;
      }
    } else {
      for (let i = dragOverItem.current; i <= dragItem.current; i++) {
        _serviceData[i].modify = true;
      }
    }

    dragItem.current = null;
    dragOverItem.current = null;
    setServiceData(_serviceData);
  };

  const updateOrderServices = (reorderServices) => {
    let isModified = false;
    const services = reorderServices.map((item) => {
      if (item.modify) isModified = true;
      return {
        id: item.id,
        modify: item.modify,
        orderIndex: item.orderIndex,
        type: item.type,
        _version: item._version
      };
    });

    if (isModified) {
      dispatch({
        type: 'updateOrderIndex',
        payload: services
      });
    }
  };

  const callGetService = () => {
    dispatch({ type: 'getService', payload: { cancelTest: false } });
    setIsDomesticDrag(false);
    setIsCommercialDrag(false);
  };

  return (
    <div className={classes.root}>
      <ServicesToolbar addService={() => setShowNewService(true)} />
      <Divider variant="middle" className={classes.divider} />
      <Grid container className={classes.content}>
        {showNewService && (
          <ServicesList hideNewService={() => setShowNewService(false)} />
        )}

        {serviceTypes.map((service, i) => {
          const disabledServiceReorder =
            i == 1 && isDomesticDrag
              ? true
              : i == 0 && isCommercialDrag
              ? true
              : false;

          const reorderServicesHandler = () => {
            if (service.id === 'DomesticServices') {
              reorderServices(service.servicesData, setOrderedDomesticServices);
            } else {
              reorderServices(
                service.servicesData,
                setOrderedCommercialServices
              );
            }
          };

          return (
            <Grid item key={i} xs={12} md={6}>
              <Grid
                container
                className={classes.serviceHeading}
                justify="space-between"
                alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="h4">{service.typeName}</Typography>
                </Grid>
                <Grid container item xs={6} justify="flex-end">
                  {(i === 0 && !isDomesticDrag) ||
                  (i === 1 && !isCommercialDrag) ? (
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      disabled={disabledServiceReorder}
                      onClick={() => {
                        if (i === 0) {
                          setIsDomesticDrag(!isDomesticDrag);
                        } else {
                          setIsCommercialDrag(!isCommercialDrag);
                        }
                      }}>
                      Re-order
                    </Button>
                  ) : (
                    <>
                      <Button
                        size="small"
                        variant="contained"
                        color="secondary"
                        style={{ marginRight: '1rem' }}
                        onClick={callGetService}>
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => {
                          if (i === 0) {
                            updateOrderServices(orderedDomesticServices);
                            setIsDomesticDrag(!isDomesticDrag);
                          } else {
                            updateOrderServices(orderedCommercialServices);
                            setIsCommercialDrag(!isCommercialDrag);
                          }
                        }}>
                        save
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
              {service.servicesData.map((service, i) => (
                <ServicesList
                  key={i}
                  service={service}
                  handleDragStart={() => handleDragStart(i)}
                  handleDragEnter={() => handleDragEnter(i)}
                  handleDragEnd={reorderServicesHandler}
                  isDomesticDrag={isDomesticDrag}
                  isCommercialDrag={isCommercialDrag}
                />
              ))}
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default Services;
