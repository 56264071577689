import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Badge,
  Link
} from '@material-ui/core';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import { SelectContext } from '../../store';
import { useHistory } from 'react-router-dom';
import CartMenu from './CartMenu';
import { CartContext } from 'context/cart/cartContext';
import SignInButton from './SignInButton';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  appBar: {
    boxShadow: 'none',
    backgroundColor: theme.palette.white
  }
}));

const Header = (props) => {
  const { className, hideCart } = props;
  const history = useHistory();
  const { setUserFilterItems } = useContext(SelectContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const { cart } = useContext(CartContext);

  useEffect(() => {
    setUserFilterItems([]);
  }, [setUserFilterItems]);

  const classes = useStyles();
  const handleCartMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className={classes.root}>
      <AppBar className={clsx(classes.appBar, className)}>
        <Toolbar>
          <Typography className={classes.title} variant="h6">
            <Link
              color="initial"
              component="button"
              onClick={() => {
                history.push('/home');
              }}>
              Laundry
            </Link>
          </Typography>
          <SignInButton />
          {!(cart.items.length === 0 || hideCart) && (
            <div>
              <IconButton
                className={classes.shoppingIcon}
                onClick={handleCartMenu}>
                <Badge
                  badgeContent={cart.items.reduce((r, o) => o.quantity + r, 0)}>
                  <ShoppingBasketIcon size="small" />
                </Badge>
              </IconButton>
            </div>
          )}
        </Toolbar>
      </AppBar>

      {cart.items?.length > 0 && anchorEl !== null && (
        <CartMenu
          anchorEl={anchorEl}
          cartItems={cart.items}
          setAnchorEl={setAnchorEl}
        />
      )}
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  postCode: PropTypes.any,
  setPostCode: PropTypes.func
};

export default Header;
