import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { ShopImage } from 'components/organisms';
import { getFormattedPhoneNumber } from 'common/utilFunctions';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '40.7rem',
    border: '2px solid #0000003b',
    backgroundColor: theme.palette.white,
    marginTop: '1rem',
    padding: '0 1.5rem',
    borderRadius: '0.25rem',
    [theme.breakpoints.up('md')]: {
      marginTop: 0
    }
  },
  avatar: {
    height: 140,
    width: 140,
    borderRadius: '50%'
  },
  iconColor: {
    color: '#444'
  }
}));

const ChatShopDetailBox = ({ orderActive }) => {
  const classes = useStyles();

  return (
    <Grid container item alignItems="center" className={classes.container}>
      <Grid
        container
        item
        justify="center"
        alignItems="center"
        xs={12}
        style={{ margin: '2rem 0 1rem', borderBottom: '1px solid black' }}>
        <Grid item>
          <ShopImage
            shop={orderActive.shop}
            width="100%"
            className={classes.avatar}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            style={{ textAlign: 'center', marginTop: '1rem' }}
            variant="h4">
            {orderActive.shop.name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              textAlign: 'center',
              marginTop: '5px',
              marginTop: '0.5rem',
              marginBottom: '1.5rem'
            }}
            variant="h6">
            {orderActive.shop.contactName}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ margin: '1rem 0 2rem' }}>
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          style={{ margin: '6px 0' }}>
          <Grid item xs={1} style={{ marginRight: '5px' }}>
            <HomeIcon className={classes.iconColor} />
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h6" style={{ textTransform: 'capitalize' }}>
              {orderActive.shop.address}.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          alignItems="center"
          style={{ margin: '6px 0' }}>
          <Grid item xs={1} style={{ marginRight: '5px' }}>
            <LocationOnIcon className={classes.iconColor} />
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h6">
              {orderActive.shop.postcode}, {orderActive.shop.town},{' '}
              {orderActive.shop.country}.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          alignItems="center"
          style={{ margin: '6px 0' }}>
          <Grid item xs={1} style={{ marginRight: '5px' }}>
            <a
              href={`tel:${getFormattedPhoneNumber(
                orderActive.shop.phoneNumber
              )}`}>
              <PhoneIcon className={classes.iconColor} />
            </a>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h6">
              {getFormattedPhoneNumber(orderActive.shop.phoneNumber)}.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          alignItems="center"
          style={{ margin: '6px 0' }}>
          <Grid item xs={1} style={{ marginRight: '5px' }}>
            <a href={`mailTo:${orderActive.shop.email}`}>
              <EmailIcon className={classes.iconColor} />
            </a>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h6">{orderActive.shop.email}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChatShopDetailBox;
