import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Typography,
  Grid,
  Tabs,
  Tab,
  Tooltip,
  IconButton
} from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useHistory } from 'react-router-dom';
import { useOrders } from 'context/order/ordersContext';
import { useAuctions } from 'context/auctions/auctionsContext';
import { useLoader } from 'layouts/loaderContext';
import { useAmplifyAuth } from 'context';
import { AuctionCollapse } from './components';
import {
  NavigationBreadcrumbs,
  AuctionCard,
  DocumentDeleteDialog
} from 'components/organisms';
import { MyAuctionSkeleton } from 'components/skeletons';
import { routes } from 'common/constants';
import { ITEMS_PER_PAGE, TABS } from './constants';
import { omit } from 'lodash';
import AuctionsPaymentDialog from 'components/organisms/AuctionsPaymentDialog';
import {
  useStripe,
  useElements,
  CardNumberElement
} from '@stripe/react-stripe-js';
import { useSnackbar } from 'notistack';
import { API } from 'aws-amplify';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    padding: '0 30px',
    marginBottom: '100px',
    borderTop: '1px solid #0000001A',
    [theme.breakpoints.up('md')]: {
      padding: '0 5rem'
    }
  },
  tabSelected: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white !important'
  },
  tabRoot: {
    color: theme.palette.grey.secondary,
    textTransform: 'none',
    maxWidth: '100%'
  },
  tabsRoot: {
    borderRadius: '8px',
    border: '1px solid',
    borderColor: theme.palette.grey.secondary
  },
  header: {
    width: '100%',
    textAlign: 'left',
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    lineHeight: '0.1em',
    margin: '10px 0',
    padding: '0 0 0.8rem'
  },
  headerTitle: {
    background: '#fff',
    paddingRight: '10px',
    fontWeight: '600'
  },
  pageContentBox: {
    paddingTop: '1.5rem',
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
      paddingLeft: '2.125rem'
    }
  },
  cardRoot: {
    width: '100%'
  },
  cardRoot2: {
    width: '100%',
    borderBottom: 'none',
    borderRadius: '0.25rem 0.25rem 0 0'
  },
  cardRoot3: {
    borderTop: 'none',
    borderRadius: '0 0 0.25rem 0.25rem'
  },
  cardsContainer: {
    marginTop: '18px',
    gap: '1rem'
  },
  cardContainer: {
    border: `1px solid ${theme.palette.grey.main}`,
    borderRadius: '0.25rem'
  }
}));

const a11yProps = (index) => ({
  id: `vertical-tab-${index}`,
  'aria-controls': `vertical-tabpanel-${index}`
});

const MyAuctions = () => {
  const classes = useStyles();
  const history = useHistory();
  const { loading, setLoading } = useLoader();
  const { dispatch: ordersDispatch } = useOrders();
  const { auctions, dispatch } = useAuctions();
  const [currentTab, setCurrentTab] = useState(
    TABS.findIndex((tab) => tab.route === history.location.pathname) || 0
  );
  const [currentAuctions, setCurrentAuctions] = useState([]);
  const [collapseAuction, setCollapseAuction] = useState(null);
  const [page, setPage] = useState(1);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showAuctionPaymentDialog, setShowAuctionPaymentDialog] = useState(
    false
  );
  const [selectedBid, setSelectedBid] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [cardDetails, setCardDetails] = useState({
    selectedCardID: '',
    cardName: { value: '', error: false },
    shouldSaveCard: false,
    customerID: ''
  });
  // stripe
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [confirmationDialogParams, setConfirmationDialogParams] = useState({
    title: '',
    confirmText: '',
    handleConfirm: () => {}
  });
  const {
    state: { user }
  } = useAmplifyAuth();
  const noOfPages = Math.ceil(currentAuctions.length / ITEMS_PER_PAGE);

  useEffect(() => {
    loadAuctionsByUser();
  }, [user]);

  useEffect(() => {
    const tab = TABS[currentTab];
    history.replace(tab?.route);
    setCurrentAuctions(auctions.filter(tab.filter));
  }, [auctions, currentTab]);

  const loadAuctionsByUser = () => {
    if (user?.id || user?.sub) {
      dispatch({
        type: 'getAuctionsByUser',
        payload: {
          userID: user.id || user.sub
        }
      });
    }
  };

  const handleTabSwitch = (_, newVal) => {
    setCurrentTab(newVal);
    setPage(1);
  };

  const paginationHandler = (_, value) => setPage(value);

  const handleCloseConfirmationModal = () => setShowConfirmationDialog(false);

  const handleCloseAuctionPaymentModal = () => {
    setPaymentMethod('');
    setCardDetails({
      selectedCardID: '',
      cardName: { value: '', error: false },
      shouldSaveCard: false,
      customerID: ''
    });
    setShowAuctionPaymentDialog(false);
    setSelectedBid(null);
  };

  const handleDeleteConfirm = () => {
    handleCloseConfirmationModal();
    dispatch({
      type: 'deleteAuction',
      payload: { auctionID: collapseAuction }
    });
  };

  const handleDeleteClick = () => {
    setConfirmationDialogParams({
      title: 'Delete Auction',
      confirmText: 'delete this Auction',
      handleConfirm: handleDeleteConfirm
    });
    setShowConfirmationDialog(true);
  };

  const handleToggleCollapse = (auctionID = '') => () =>
    setCollapseAuction((_prev) => (_prev === auctionID ? null : auctionID));

  const handleCloseAuctionConfirm = () => {
    const auction = auctions.find(
      (_auction) => _auction.id === collapseAuction
    );
    handleCloseConfirmationModal();
    dispatch({
      type: 'updateAuction',
      payload: {
        auction: {
          ...omit(auction, [
            'user',
            'bids',
            'items',
            'updatedAt',
            '_deleted',
            '_lastChangedAt'
          ]),
          isClosed: true
        },
        successMessage: 'Auction closed'
      }
    });
    setCurrentTab(2);
  };

  const handleCloseAuctionClick = () => {
    setConfirmationDialogParams({
      title: 'Close Auction',
      confirmText: 'close this Auction',
      handleConfirm: handleCloseAuctionConfirm
    });
    setShowConfirmationDialog(true);
  };

  const handlePlaceOrderClick = (bidID = '') => () => {
    setShowAuctionPaymentDialog(true);
    setSelectedBid(bidID);
  };

  const handlePlaceOrderConfirm = (bidID = '') => async () => {
    // getting selected auction and bid
    const auction = auctions.find(
      (_auction) => _auction.id === collapseAuction
    );
    const bid = auction.bids.items.find((_bid) => _bid.id === bidID);

    // creating products for the order
    const _products = auction.items.items.map((_auctionItem) => {
      const _bidItem = bid.items.items.find(
        (_bidItem) => _bidItem.auctionItemID === _auctionItem.id
      );
      return {
        name: _auctionItem.name,
        description: _auctionItem.description,
        unit: 'perItem',
        quantity: _auctionItem.quantity,
        serviceID: _auctionItem.serviceID,
        totalPrice: _bidItem.price
      };
    });

    // creating payload for the order
    const payload = {
      shopID: bid.shopID,
      orderList: JSON.stringify(_products),
      collection: JSON.stringify({
        ...auction.collection,
        address: !!auction.collection.address
          ? JSON.parse(auction.collection.address)
          : null
      }),
      delivery: JSON.stringify({
        ...auction.delivery,
        address: !!auction.delivery.address
          ? JSON.parse(auction.delivery.address)
          : null
      }),
      total: bid.totalPrice,
      collectionAndDeliveryFee: bid.shippingCharges || 0,
      currentShopFees: JSON.stringify({
        standardDeliveryFee: bid.shop.standardDeliveryFee,
        AsapDeliveryFee: bid.shop.asapDeliveryFee || 0,
        minPriceForFree: bid.shop.minPriceForFree
      }),
      bidID: bid.id,
      tax: 0
    };

    // checking payment method
    if (!paymentMethod) {
      enqueueSnackbar('Please select a payment method...', {
        variant: 'error',
        persist: true,
        preventDuplicate: true
      });
      return;
    }

    // setting payment method
    payload.paymentMethod = paymentMethod;

    // if payment method is cash place order
    if (paymentMethod === 'cash') {
      ordersDispatch({
        type: 'placeOrder',
        payload: {
          payload,
          successCallback: () => {
            handleCloseAuctionPaymentModal();
            history.push(routes.customer.order);
          }
        }
      });
    } else {
      // attempting payment
      if (!stripe || !elements) return;
      if (!cardDetails.selectedCardID && !cardDetails.cardName.value) {
        setCardDetails({
          ...cardDetails,
          cardName: { value: '', error: true }
        });
        enqueueSnackbar('Please enter your full name', {
          variant: 'error',
          persist: true,
          preventDuplicate: true
        });
        return;
      }

      let snackBar;
      try {
        setLoading(true);
        snackBar = enqueueSnackbar('Attempting payment, please wait...', {
          variant: 'info',
          persist: true,
          preventDuplicate: true
        });

        // getting client secret for payment
        const clientSecretResp = await API.post(
          'laundryapi',
          '/payment-intents',
          {
            body: {
              bidID: bid.id,
              manual_capture: true,
              customerID: cardDetails.customerID
            }
          }
        );

        // confirming payment
        const confirmPaymentParams = {};
        if (cardDetails.shouldSaveCard) {
          confirmPaymentParams['setup_future_usage'] = 'off_session';
        }
        if (!!cardDetails.selectedCardID) {
          confirmPaymentParams['payment_method'] = cardDetails.selectedCardID;
        } else {
          confirmPaymentParams['payment_method'] = {
            card: elements.getElement(CardNumberElement),
            billing_details: { name: cardDetails.cardName.value }
          };
        }
        const result = await stripe.confirmCardPayment(
          clientSecretResp.client_secret,
          confirmPaymentParams
        );
        closeSnackbar(snackBar);

        if (result.error) {
          // Show error to your customer (for example, insufficient funds)
          enqueueSnackbar(result.error.message, {
            variant: 'error',
            persist: true,
            preventDuplicate: true
          });
          console.error(result.error.message);
          setLoading(false);
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'requires_capture') {
            payload.onHoldPaymentIntentID = result.paymentIntent.id;
            payload.onHoldPaymentAmount = payload.total;
            ordersDispatch({
              type: 'placeOrder',
              payload: {
                payload,
                successCallback: () => {
                  handleCloseAuctionPaymentModal();
                  history.push(routes.customer.order);
                }
              }
            });
          }
        }
      } catch (error) {
        setLoading(false);
        console.log('Something went wrong', error);
        enqueueSnackbar('Something went wrong', {
          variant: 'error',
          autoHideDuration: 2500
        });
      } finally {
        closeSnackbar(snackBar);
      }
    }
  };

  const getAuctionCardActions = (id = '') => [
    {
      title: id === collapseAuction ? 'Hide Details' : 'View Details',
      onClick: handleToggleCollapse(id),
      color: 'primary',
      variant: 'contained'
    }
  ];

  return user ? (
    <>
      <Helmet>
        {/* <meta
          name="description"
          content="Want to keep track of your orders and packages with ease? Look no further than our website! We offer a simple and convenient way to monitor all of your current and past orders with our My Orders feature.
          With our user-friendly platform, you can easily access and track your orders, including your current orders and order history. Plus, our order tracking system allows you to monitor the progress of your shipments every step of the way.
          If you're waiting on an Amazon package, our platform makes it easy to track your Amazon order number, so you'll always know when your package is expected to arrive.
          So why wait? Sign up with us today to start tracking your packages and orders with ease. With our advanced tracking features and user-friendly interface, you'll never miss a delivery again!"
        /> */}
        <meta
          name="keywords"
          content="launder-it, launder it, My auctions, Current auctions, biddings, Current biddings"
        />
      </Helmet>

      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <NavigationBreadcrumbs currentLink="Auctions" />
        </Grid>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          style={{ minHeight: '64px' }}>
          <Typography variant="h3">My Auctions</Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} md={2}>
            <Tabs
              classes={{ root: classes.tabsRoot }}
              orientation="vertical"
              value={currentTab}
              onChange={handleTabSwitch}
              aria-label="my-orders-vertical-tabs">
              {TABS.map((tab, index) => (
                <Tab
                  value={index}
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected
                  }}
                  label={
                    <Grid container justify="space-between" alignItems="center">
                      <Grid
                        item
                        container
                        alignItems="center"
                        justify="space-between"
                        style={{ width: 'fit-content' }}>
                        {tab.icon}
                        &nbsp; &nbsp;
                        {tab.name}
                      </Grid>
                      <Grid
                        item
                        container
                        alignItems="center"
                        style={{ width: 'fit-content' }}>
                        <ChevronRightIcon fontSize="small" />
                      </Grid>
                    </Grid>
                  }
                  {...a11yProps(index)}
                  key={`my-orders-vertical-tabs-items-${index}`}
                />
              ))}
            </Tabs>
          </Grid>
          <Grid item xs={12} md={10} className={classes.pageContentBox}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.header}>
                <span className={classes.headerTitle}>
                  {TABS[currentTab].name}
                </span>
                <Tooltip title="Refresh" placement="right">
                  <IconButton
                    onClick={loadAuctionsByUser}
                    style={{ padding: '0 0.5rem' }}>
                    <ReplayIcon style={{ color: '#194376' }} />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>

            <Grid container className={classes.cardsContainer}>
              {loading ? (
                [...Array(ITEMS_PER_PAGE).keys()].map((item) => (
                  <Grid
                    container
                    key={`my-auctions-skeleton-items-${item}`}
                    className={classes.cardContainer}>
                    <MyAuctionSkeleton status={currentTab} />
                  </Grid>
                ))
              ) : currentAuctions.length > 0 ? (
                currentAuctions
                  .slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)
                  .map((auction, index) => {
                    const showCollapse = collapseAuction === auction.id;

                    return (
                      <Grid
                        container
                        key={`auctions-items-id-${index}`}
                        className={classes.cardContainer}>
                        <AuctionCard
                          item={auction}
                          status={currentTab}
                          actions={getAuctionCardActions(auction.id)}
                          className={
                            showCollapse ? classes.cardRoot2 : classes.cardRoot
                          }
                        />
                        <AuctionCollapse
                          show={showCollapse}
                          auction={auction}
                          status={currentTab}
                          handleDelete={handleDeleteClick}
                          handleCloseAuction={handleCloseAuctionClick}
                          handlePlaceOrder={handlePlaceOrderClick}
                          className={showCollapse ? classes.cardRoot3 : ''}
                        />
                      </Grid>
                    );
                  })
              ) : (
                <Grid container justify="center">
                  <Typography variant="h6">No auctions available.</Typography>
                </Grid>
              )}
              <Grid container item xs={12} justify="center">
                <Pagination
                  count={noOfPages}
                  page={page}
                  onChange={paginationHandler}
                  defaultPage={1}
                  color="secondary"
                  shape="rounded"
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <AuctionsPaymentDialog
        open={showAuctionPaymentDialog}
        handleCancel={handleCloseAuctionPaymentModal}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        cardDetails={cardDetails}
        setCardDetails={setCardDetails}
        handleConfirm={handlePlaceOrderConfirm(selectedBid)}
      />
      <DocumentDeleteDialog
        open={showConfirmationDialog}
        handleCancel={handleCloseConfirmationModal}
        {...confirmationDialogParams}
      />
    </>
  ) : (
    <Grid container justify="center">
      <Typography variant="h3">Please login to review your orders</Typography>
    </Grid>
  );
};

export default MyAuctions;
