import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
  useState
} from 'react';
import { useLoader } from './../../layouts/loaderContext';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import serviceReducer from './serviceReducer';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  loadService,
  addNewService,
  addNewItem,
  performDelete,
  performUpdate,
  updateNewService
} from './serviceAPIs';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { Grid, Typography } from '@material-ui/core';
import { graphqlOperation, API, Storage } from 'aws-amplify';
import { getFileUploadKey } from 'common/utilFunctions';
import { Image } from 'components/organisms';
import { getGallaryPhotoByServiceID } from 'graphql/queries';
import PhotoGalleryDialog from 'components/organisms/PhotoGalleryDialog';

const useStyles = makeStyles((theme) => ({
  upload: {
    marginTop: 8
  },
  preview: {
    maxWidth: 100,
    maxHeight: 100,
    border: '1px solid #bbb',
    display: 'block',
    marginBottom: 8
  },
  errorFont: {
    color: theme.palette.error.main
  }
}));

//TODO : Need to move context outside of view

const ServicesContext = createContext({});

const ServicesProvider = (props) => {
  const classes = useStyles();
  const [services, dispatch] = useReducer(serviceReducer, []);
  const [editData, setEditData] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const { setLoading } = useLoader();
  const [file, setFile] = useState(null); // TODO : check for optimize
  const [selectedImg, setSelectedImg] = useState('');
  const [photoGalleryData, setPhotoGalleryData] = useState({
    isUploadPhoto: false,
    serviceId: '',
    serviceName: ''
  });
  const [previewGalleryImg, setPreviewGalleryImg] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const asyncDispatch = useCallback(
    async (action) => {
      switch (action.type) {
        case 'getService': {
          let cancelTest = true;
          if (action.payload?.hasOwnProperty('cancelTest')) {
            cancelTest = action.payload.cancelTest;
          }
          if (cancelTest && services.length > 0) return services;
          let showLoader = true;
          if (action.payload?.hasOwnProperty('showLoader'))
            showLoader = action.payload.showLoader;
          let snackBar;
          if (showLoader) {
            setLoading(true);
            snackBar = enqueueSnackbar('Services and Items are loading...', {
              variant: 'info',
              persist: true
            });
          }
          const data = await loadService();
          if (showLoader) {
            closeSnackbar(snackBar);
            setLoading(false);
          }
          dispatch({
            type: 'updateData',
            payload: data
          });
          return data;
        }
        case 'addNewService': {
          setLoading(true);
          const snackBar = enqueueSnackbar('Saving Service ....', {
            variant: 'info',
            persist: true
          });
          try {
            const data = await addNewService(action.payload);
            enqueueSnackbar('Service saved successfully', {
              variant: 'success',
              preventDuplicate: true,
              autoHideDuration: 2000
            });
            dispatch({
              type: 'addServiceToState',
              payload: data.data.createService
            });
          } catch (error) {
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              preventDuplicate: true,
              autoHideDuration: 2000
            });
          } finally {
            closeSnackbar(snackBar);
            setLoading(false);
          }
          break;
        }
        case 'updateNewService': {
          setLoading(true);
          const snackBar = enqueueSnackbar('Saving Service ....', {
            variant: 'info',
            persist: true
          });
          const {
            name,
            serviceID = '',
            id,
            _version,
            orderIndex,
            type = 'domestic'
          } = action.payload;
          const service = { name, id, serviceID, orderIndex, _version, type };
          try {
            const data = await updateNewService(service);
            enqueueSnackbar('Service updated successfully', {
              variant: 'success',
              preventDuplicate: true,
              autoHideDuration: 2000
            });
            dispatch({
              type: 'updateService',
              payload: data.data.updateService
            });
          } catch (error) {
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              preventDuplicate: true,
              autoHideDuration: 2000
            });
          } finally {
            closeSnackbar(snackBar);
            setLoading(false);
          }
          break;
        }
        case 'updateOrderIndex': {
          setLoading(true);
          const snackBar = enqueueSnackbar('Updating order of services...', {
            variant: 'info',
            persist: true
          });
          try {
            for (let service of action.payload) {
              if (service.modify) {
                delete service.modify;
                const data = await updateNewService(service);
                dispatch({
                  type: 'updateService',
                  payload: data.data.updateService
                });
              }
            }
            enqueueSnackbar('Service updated successfully', {
              variant: 'success',
              preventDuplicate: true,
              autoHideDuration: 2000
            });
          } catch (error) {
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              preventDuplicate: true,
              autoHideDuration: 2000
            });
          } finally {
            closeSnackbar(snackBar);
            setLoading(false);
          }
          break;
        }

        case 'addNewItem': {
          const { item, successCallback = () => {} } = action.payload;
          if (!item?.name) {
            enqueueSnackbar('Please enter a valid item name', {
              variant: 'error',
              preventDuplicate: true
            });
            break;
          }

          setLoading(true);
          const snackBar = enqueueSnackbar('Saving Item ....', {
            variant: 'info',
            persist: true
          });

          try {
            const data = await addNewItem(item);
            enqueueSnackbar('Item saved successfully', {
              variant: 'success',
              preventDuplicate: true
            });
            dispatch({
              type: 'addItemToService',
              payload: data.data.createAnItem
            });
            successCallback();
          } catch (error) {
            console.log('error saving new item', error);
            enqueueSnackbar('Something went wrong', {
              variant: 'error',
              preventDuplicate: true
            });
          } finally {
            setLoading(false);
            closeSnackbar(snackBar);
          }
          break;
        }
        case 'delete': {
          setLoading(true);
          const { moduleType } = action.payload;
          const snackBar = enqueueSnackbar(`Deleting ${moduleType} ....`, {
            variant: 'info',
            persist: true
          });
          try {
            const data = await performDelete(action);
            enqueueSnackbar(`${moduleType} deleted successfully`, {
              variant: 'success',
              preventDuplicate: true
            });
            setEditData(null);
            setDeleting(false);

            dispatch({
              type: action.type + moduleType,
              payload: data.data[action.type + moduleType]
            });
          } catch (error) {
            console.log('error saving new item', error);
            enqueueSnackbar('Something went wrong', {
              variant: 'error',
              preventDuplicate: true
            });
          } finally {
            setLoading(false);
            closeSnackbar(snackBar);
          }
          break;
        }
        case 'update': {
          setLoading(true);
          const { moduleType } = action.payload;
          const snackBar = enqueueSnackbar(`Updating ${moduleType} ....`, {
            variant: 'info',
            persist: true
          });
          try {
            const data = await performUpdate(action, file);
            setFile(null);
            enqueueSnackbar(`${moduleType} updated successfully`, {
              variant: 'success',
              preventDuplicate: true
            });
            setEditData(null);
            setDeleting(false);

            dispatch({
              type: action.type + moduleType,
              payload: data.data[action.type + moduleType]
            });
          } catch (error) {
            console.log('error saving new item', error);
            enqueueSnackbar('Something went wrong', {
              variant: 'error',
              preventDuplicate: true
            });
          } finally {
            setLoading(false);
            closeSnackbar(snackBar);
          }
          break;
        }
        default:
          dispatch(action);
      }
    },
    [services, file, closeSnackbar, enqueueSnackbar, setLoading]
  );
  const value = {
    setEditData,
    setDeleting,
    setPhotoGalleryData,
    setPreviewGalleryImg,
    setSelectedImg,
    services,
    dispatch: asyncDispatch
  };

  const handleClose = () => {
    setPhotoGalleryData({
      isUploadPhoto: false,
      serviceId: '',
      serviceName: ''
    });
    setSelectedImg('');
    setPreviewGalleryImg([]);
    setDeleting(false);
    setEditData(null);
    setFile(null);
  };
  const handleChange = (event) =>
    setEditData({ ...editData, [event.target.name]: event.target.value });
  const handleDelete = () => {
    setDeleting(true);
  };
  const handleFileChange = (event) => {
    const {
      target: { value, files }
    } = event;
    const fileForUpload = files[0];
    setFile(fileForUpload || value);
    readFile(fileForUpload || value);
  };

  const readFile = (_file) => {
    if (_file) {
      setLoading(true);
      const reader = new FileReader();
      reader.onload = async () => {
        setSelectedImg(reader.result);
        setLoading(false);
      };
      reader.readAsDataURL(_file);
    }
    setLoading(false);
  };

  const photoGalleryHandler = () => {
    setPhotoGalleryData({
      ...photoGalleryData,
      isUploadPhoto: true
    });
    fetchItemPhotos();
  };

  const onCancelSelectingImg = () => {
    setPhotoGalleryData({
      ...photoGalleryData,
      isUploadPhoto: false
    });
    setSelectedImg('');
  };
  const fetchItemPhotos = () => {
    try {
      setLoading(true);
      API.graphql(
        graphqlOperation(getGallaryPhotoByServiceID, {
          serviceID: photoGalleryData.serviceId
        })
      ).then(({ data }) => {
        const itemImgs = data.getGallaryPhotoByServiceID.items
          .filter((item) => !item._deleted)
          .map((img) => img);
        setPreviewGalleryImg(itemImgs);
        setLoading(false);
      });
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };
  const selectImageHandler = (img = '') => {
    if (!img) {
      setSelectedImg(null);
      setEditData((prevData) => ({ ...prevData, image: null }));
    } else {
      setSelectedImg(img.picture.photoURL);
      setEditData((prevData) => ({ ...prevData, image: img.picture.photoURL }));
    }
  };

  const onConfirmImage = () => {
    setPhotoGalleryData({
      ...photoGalleryData,
      isUploadPhoto: false
    });
  };

  const onUpdateHandler = async () => {
    if (!selectedImg?.includes('images/') && file) {
      setLoading(true);
      const { type: mimeType } = file;
      const key = getFileUploadKey(file, 'images');
      const response = Storage.put(key, file, {
        contentType: mimeType,
        level: 'public'
      })
        .then(() => ({
          photoURL: key,
          MIMETypes: mimeType
        }))
        .catch((error) => {
          console.log('error', error);
          return null;
        });

      const result = await Promise.resolve(response);
      setEditData((prevData) => ({ ...prevData, image: result.photoURL }));
      setLoading(false);
    }
    asyncDispatch({ type: 'update', payload: editData });
    setSelectedImg('');
  };

  const removePhotoHandler = () => {
    setSelectedImg(null);
    setEditData((prevData) => ({ ...prevData, image: null }));
  };

  return (
    <ServicesContext.Provider value={value}>
      {props.children}
      {editData && (
        <Dialog open={!!editData && !deleting} onClose={handleClose}>
          <DialogTitle>Edit {editData.moduleType}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              variant="outlined"
              margin="dense"
              id="name"
              name="name"
              onChange={handleChange}
              label="Name"
              type="text"
              value={editData.name}
              fullWidth
            />
            <TextField
              variant="outlined"
              margin="dense"
              id="description"
              name="description"
              label="Description"
              type="text"
              inputProps={{ maxLength: 200 }}
              value={editData.description || ''}
              onChange={handleChange}
              fullWidth
            />
            {editData.moduleType === 'Item' && (
              <div className={classes.upload}>
                {selectedImg ? (
                  selectedImg.includes('images/') ? (
                    <Image
                      className={classes.preview}
                      docKey={selectedImg}
                      alt={'Item'}
                    />
                  ) : (
                    <img
                      src={selectedImg}
                      alt={'Item'}
                      className={classes.preview}
                    />
                  )
                ) : null}
                <Grid container item justify="flex-start">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    size="small"
                    style={{ marginRight: '1rem' }}
                    onClick={photoGalleryHandler}>
                    Select Photo
                  </Button>
                  {selectedImg ? (
                    <Button
                      variant="contained"
                      component="span"
                      size="small"
                      style={{ backgroundColor: '#ff0033', color: '#fff' }}
                      onClick={removePhotoHandler}>
                      Remove photo
                    </Button>
                  ) : null}
                </Grid>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDelete}>Delete</Button>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={onUpdateHandler}
              color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {editData && (
        <Dialog open={deleting} onClose={handleClose}>
          <DialogTitle>Delete {editData.moduleType}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure to delete {editData.moduleType} "{editData.name}" ?
            </DialogContentText>
            <Typography className={classes.errorFont}>
              Note: Before deleting please make sure that no shop has product
              related to this item. Otherwise it will create problems.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="outlined"
              color="secondary"
              size="small">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                asyncDispatch({ type: 'delete', payload: editData });
              }}
              color="primary"
              size="small">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {photoGalleryData.isUploadPhoto && (
        <PhotoGalleryDialog
          open={photoGalleryData.isUploadPhoto}
          onClose={onCancelSelectingImg}
          previewGalleryImg={previewGalleryImg}
          selectImageHandler={selectImageHandler}
          handleFileChange={handleFileChange}
          serviceName={photoGalleryData.serviceName}
          onConfirmImage={onConfirmImage}
          imgData={selectedImg}
          setImgData={setSelectedImg}
        />
      )}
    </ServicesContext.Provider>
  );
};

function useServices() {
  const context = useContext(ServicesContext);
  if (context === undefined) {
    throw new Error('useServices must be used within a ServicesProvider');
  }
  return context;
}

export { ServicesProvider, useServices };
