import React from 'react';
import { HomeFooter, Copyright } from 'components/organisms';
import PropTypes from 'prop-types';
import userFlowTheme from '../../theme/HomeTheme';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    minHeight: 'calc(100vh - 212px)',
    [theme.breakpoints.up('md')]: {
      minHeight: 'calc(100vh - 148px)'
    }
  }
}));

const Minimal = (props) => {
  const classes = useStyles();
  const { children } = props;

  return (
    <ThemeProvider theme={userFlowTheme}>
      <main className={classes.content}>{children}</main>
      <HomeFooter />
      <Copyright />
    </ThemeProvider>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Minimal;
