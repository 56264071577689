import React from 'react';
import userFlowTheme from '../../theme/HomeTheme';
import { ThemeProvider } from '@material-ui/core/styles';

const HomeThemeBasic = (props) => {
  const { children } = props;

  return (
    <ThemeProvider theme={userFlowTheme}>
      <main>{children}</main>
    </ThemeProvider>
  );
};

export default HomeThemeBasic;
