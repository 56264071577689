export const createAddress = /* GraphQL */ `
  mutation CreateAddress(
    $input: CreateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    createAddress(input: $input, condition: $condition) {
      id
      userID
      postcode
      buildingName
      buildingNumber
      line1
      line2
      town
      county
      district
      country
      phoneNumber
      contactName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;

export const createCustomerReview = /* GraphQL */ `
  mutation CreateCustomerReview(
    $input: CreateCustomerReviewInput!
    $condition: ModelCustomerReviewConditionInput
  ) {
    createCustomerReview(input: $input, condition: $condition) {
      id
      shopID
      userID
      orderID
      title
      ratings {
        title
        rating
      }
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
