import React from 'react';
import { Popover as MuiPopover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: theme.spacing(1)
  }
}));

const Popover = ({
  children,
  anchorEl = null,
  handlePopoverClose = () => {},
  anchorOrigin = {
    vertical: 'center',
    horizontal: 'center'
  },
  transformOrigin = {
    vertical: 'center',
    horizontal: 'center'
  }
}) => {
  const classes = useStyles();

  return (
    <MuiPopover
      id="mouse-over-popover"
      className={classes.popover}
      classes={{
        paper: classes.paper
      }}
      open={!!anchorEl}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={handlePopoverClose}
      disableRestoreFocus>
      {children}
    </MuiPopover>
  );
};

export default Popover;
