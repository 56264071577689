import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';

const useStyles = makeStyles(() => ({
  root: {
    padding: '12px 6px',
    minHeight: '3.5rem',
    fontSize: '14px',
    fontWeight: '900',
    marginTop: '10px',
    backgroundColor: '#99ccee44'
  },
  blackText: {
    color: 'black'
  }
}));

const CollectionDeliveryMilesCard = ({ distanceRange }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      component={Paper}
      className={classes.root}>
      <LocalShippingOutlinedIcon className={classes.blackText} />
      <Typography variant="h6" className={classes.blackText}>
        &nbsp; Collection and delivery upto{' '}
        <strong>{distanceRange} miles</strong>
      </Typography>
    </Grid>
  );
};

export default CollectionDeliveryMilesCard;
