import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { validatePhoneNumber } from 'common/validators';
import { updateUser, updateUserDetail } from 'graphql/mutations';
import {
  getFileUploadKey,
  getStorageUrl,
  compressImage
} from 'common/utilFunctions';
import { useAmplifyAuth } from 'context';
import { useSnackbar } from 'notistack';
import { DocumentDeleteDialog } from 'components/organisms';

const useStyles = makeStyles((theme) => ({
  saveBtn: {
    borderRadius: '10px',
    padding: '15px',
    minWidth: '185px',
    backgroundColor: '#46C6CE',
    color: 'white',
    [theme.breakpoints.up('md')]: {
      marginRight: '15px'
    }
  },
  mdPaddingLeft: {
    marginTop: '15px',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '15px',
      marginTop: '0'
    }
  },
  mdPaddingRight: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '15px'
    }
  },
  cancelButton: {
    borderRadius: '10px',
    padding: '15px',
    minWidth: '185px',
    marginTop: '15px',
    [theme.breakpoints.up('md')]: {
      marginLeft: '15px',
      marginTop: '0'
    }
  },
  profileContainer: {
    margin: '30px 0'
  },
  imgBtnContainer: {
    gap: '1rem'
  },
  profileImgBtn: {
    borderRadius: '10px',
    padding: '15px 10px'
  },
  photoDesc: {
    margin: '11px 0 22px'
  },
  profileImgContainer: {
    marginBottom: '1rem'
  },
  profileImg: {
    height: '165px',
    width: '165px'
  }
}));

const initialState = {
  firstName: '',
  lastName: '',
  phoneNumber: ''
};

const Details = () => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [userData, setUserData] = useState(initialState);
  const [onEdit, setOnEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [userProfilePictureData, setUserProfilePictureData] = useState({
    picture: '',
    _version: ''
  });
  let {
    state: { user },
    _refreshUser
  } = useAmplifyAuth();

  useEffect(() => {
    if (user?.username) {
      if (user?.picture) {
        getStorageUrl(user.picture)
          .then((picture) =>
            setUserProfilePictureData({
              picture,
              _version: user._version
            })
          )
          .catch((error) => console.log("couldn't fetch picture", error));
      } else {
        setUserProfilePictureData({
          picture: '',
          _version: user._version
        });
      }
      setUserData({
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber
      });
    }
  }, [user]);

  const updateUserData = (key, value) => {
    if (key === 'phoneNumber') {
      value = value.replace(/[^0-9]/g, '');
    }
    setUserData({ ...userData, [key]: value });
  };

  const updateProfile = async () => {
    const error = validatePhoneNumber(userData.phoneNumber);
    if (!!error) {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    } else {
      const snackBar = enqueueSnackbar('Updating...', {
        variant: 'info',
        persist: true
      });
      API.graphql(
        graphqlOperation(updateUserDetail, {
          firstName: userData.firstName,
          lastName: userData.lastName,
          phoneNumber: userData.phoneNumber
        })
      )
        .then(() => {
          _refreshUser();
          closeSnackbar(snackBar);
          enqueueSnackbar('Saved', {
            variant: 'success'
          });
          setOnEdit(false);
        })
        .catch((err) => {
          closeSnackbar(snackBar);
          enqueueSnackbar('Something went wrong', {
            variant: 'error'
          });
          console.log('error', err);
        });
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const { type: mimeType } = file;
    const compressedFile = await compressImage(file);
    const key = getFileUploadKey(compressedFile, 'images');
    const input = {
      id: user.id,
      _version: userProfilePictureData._version,
      picture: key
    };
    let sBar;
    try {
      sBar = enqueueSnackbar('uploading...', {
        variant: 'info',
        preventDuplicate: true,
        persist: true
      });
      await Storage.put(key, compressedFile, {
        contentType: mimeType,
        level: 'public'
      });
      API.graphql(graphqlOperation(updateUser, { input })).then(
        async (data) => {
          const picture = await getStorageUrl(data.data.updateUser.picture);
          setUserProfilePictureData({
            picture,
            _version: data.data.updateUser._version
          });
          _refreshUser();
          enqueueSnackbar('Profile picture updated', {
            variant: 'success',
            preventDuplicate: true,
            autoHideDuration: 1500
          });
        }
      );
    } catch (error) {
      console.log('error', error);
    } finally {
      closeSnackbar(sBar);
    }
  };

  const handleCancel = () => {
    setUserData({
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber
    });
    setOnEdit(false);
  };

  const handleRemoveProfilePicture = async () => {
    const input = {
      id: user.id,
      picture: '',
      _version: userProfilePictureData._version
    };
    let sBar;
    try {
      sBar = enqueueSnackbar('Removing Profile Picture...', {
        variant: 'info',
        preventDuplicate: true,
        persist: true
      });
      await Storage.remove(user.picture);
      API.graphql(graphqlOperation(updateUser, { input })).then(
        async (data) => {
          setUserProfilePictureData({
            picture: '',
            _version: data.data.updateUser._version
          });
          user.picture = ''; // FIXME
          enqueueSnackbar('Profile picture Removed', {
            variant: 'success',
            preventDuplicate: true,
            autoHideDuration: 1500
          });
        }
      );
    } catch (error) {
      console.log('error', error);
    } finally {
      closeSnackbar(sBar);
      setOpenModal(false);
    }
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          className={classes.profileContainer}>
          <Grid
            container
            item
            xs={12}
            md={4}
            lg={3}
            justify={smDown ? 'center' : 'flex-start'}
            className={classes.profileImgContainer}>
            <Avatar
              src={userProfilePictureData.picture}
              alt="Profile photo"
              className={classes.profileImg}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={8}
            lg={9}
            justify={smDown ? 'center' : 'flex-start'}
            direction="column">
            <Typography variant="h6" align={smDown ? 'center' : 'left'}>
              Upload your Profile picture here.
            </Typography>
            <Typography
              variant="body2"
              className={classes.photoDesc}
              align={smDown ? 'center' : 'left'}>
              JPG or PNG file format | At least 400x400 pixels large within the
              size of 2MB
            </Typography>
            <input
              accept="image/*"
              id="user-profile-pic-upload-btn"
              name="picture"
              onChange={handleImageUpload}
              type="file"
              hidden
            />
            <Grid
              container
              item
              justify={smDown ? 'center' : 'flex-start'}
              className={classes.imgBtnContainer}>
              <label htmlFor="user-profile-pic-upload-btn">
                <Button
                  variant="outlined"
                  component="span"
                  size="small"
                  className={classes.profileImgBtn}>
                  {!!userProfilePictureData.picture
                    ? 'UPDATE PICTURE'
                    : 'UPLOAD PICTURE'}
                </Button>
              </label>
              {userProfilePictureData.picture ? (
                <Button
                  variant="outlined"
                  component="span"
                  size="small"
                  onClick={() => setOpenModal(true)}
                  className={classes.profileImgBtn}>
                  REMOVE PICTURE
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className={classes.mdPaddingRight}>
          <TextField
            variant="outlined"
            label="First Name"
            fullWidth
            name="firstName"
            disabled={!onEdit}
            value={userData.firstName || ''}
            onChange={(e) => updateUserData('firstName', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.mdPaddingLeft}>
          <TextField
            variant="outlined"
            label="Last Name"
            fullWidth
            name="lastName"
            disabled={!onEdit}
            value={userData.lastName || ''}
            onChange={(e) => updateUserData('lastName', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} style={{ margin: '20px 0' }}>
          <TextField
            variant="outlined"
            label="Mobile Number"
            name="phoneNumber"
            fullWidth
            FormHelperTextProps={{ style: { color: 'red' } }}
            value={userData.phoneNumber || ''}
            disabled
            helperText={
              !userData.phoneNumber
                ? ''
                : validatePhoneNumber(userData.phoneNumber)
            }
            onChange={(e) => updateUserData('phoneNumber', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            label="Email Address"
            fullWidth
            disabled
            value={user && user.email ? user.email : ''}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        justify="center"
        style={{ marginTop: '70px' }}>
        {onEdit ? (
          <>
            <Button
              variant="contained"
              onClick={updateProfile}
              className={classes.saveBtn}>
              SAVE
            </Button>
            <Button
              onClick={handleCancel}
              variant="outlined"
              className={classes.cancelButton}>
              CANCEL
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            onClick={() => setOnEdit(true)}
            className={classes.saveBtn}>
            EDIT
          </Button>
        )}
      </Grid>

      <DocumentDeleteDialog
        open={openModal}
        handleCancel={() => setOpenModal(false)}
        handleConfirm={handleRemoveProfilePicture}
        confirmText="remove your profile picture"
        title="Remove profile picture"
      />
    </>
  );
};

export default Details;
