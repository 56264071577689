import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { launderItBlogLink, googleFormLinks } from 'common/constants';
import CallIcon from '@material-ui/icons/Call';
import MailIcon from '@material-ui/icons/Mail';

const useStyles = makeStyles((theme) => ({
  listTextContent: {
    fontSize: 12,
    color: '#fff',
    '&:hover': {
      textDecoration: 'underline'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  linkContainer: {
    padding: '0.3rem',
    alignItems: 'center'
  },
  icon: {
    color: '#fff',
    marginRight: '0.1rem',
    fontSize: 12,
    [theme.breakpoints.up('sm')]: {
      marginRight: '0.2rem',
      fontSize: 14
    }
  }
}));

const FooterLinks = () => {
  const classes = useStyles();

  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item md={2} xs={6} className={classes.linkContainer}>
        <Link
          to="/about-us"
          target="_blank"
          rel="noreferrer"
          onClick={() => window.scrollTo(0, 0)}
          className={classes.listTextContent}>
          About us
        </Link>
      </Grid>
      <Grid item md={2} xs={6} className={classes.linkContainer}>
        <Link
          to={{ pathname: launderItBlogLink }}
          target="_blank"
          rel="noreferrer"
          className={classes.listTextContent}>
          Our blogs
        </Link>
      </Grid>
      <Grid item md={1} xs={6} className={classes.linkContainer}>
        <a
          href={googleFormLinks.joinUs}
          target="_blank"
          rel="noreferrer"
          className={classes.listTextContent}>
          Join us
        </a>
      </Grid>
      <Grid item md={2} xs={6} className={classes.linkContainer}>
        <a
          href="/business-with-us"
          target="_blank"
          rel="noreferrer"
          className={classes.listTextContent}>
          Business with us
        </a>
      </Grid>
      <Grid container item md={2} xs={6} className={classes.linkContainer}>
        <CallIcon fontSize="small" className={classes.icon} />
        <a href="tel:07771038880" className={classes.listTextContent}>
          07771038880
        </a>
      </Grid>
      <Grid container item md={3} xs={6} className={classes.linkContainer}>
        <MailIcon fontSize="small" className={classes.icon} />
        <a
          href="mailto:info@launder-it.co.uk"
          className={classes.listTextContent}>
          info@launder-it.co.uk
        </a>
      </Grid>
    </Grid>
  );
};

export default FooterLinks;
