import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Grid,
  MenuItem
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

const AddProductModal = ({
  open = false,
  handleClose = () => {},
  addProduct = () => {},
  services = [],
  products = [],
  values,
  handleChange = () => {},
  nameAsSelect = false
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (Object.keys(values).some((item) => !values[item])) {
      enqueueSnackbar('All fields are mendatory', {
        persist: true,
        variant: 'error'
      });
      return;
    }
    if (
      products
        ?.filter((item) => item.serviceID === values.service.id)
        .some(
          (item) =>
            (item?.name || item?.item?.name)
              ?.trim()
              .toLowerCase()
              .replace(/\s+/g, ' ') ===
            values.name.trim().toLowerCase().replace(/\s+/g, ' ')
        )
    ) {
      enqueueSnackbar(`Product named \"${values.name}\" already exists`, {
        persist: true,
        variant: 'error'
      });
      return;
    }

    addProduct();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="add-product-dialog-title"
      aria-describedby="add-product-dialog-description">
      <DialogTitle id="add-product-dialog-title">Add Product</DialogTitle>
      <form noValidate>
        <DialogContent>
          <Grid container item xs={12}>
            <TextField
              margin="dense"
              InputLabelProps={{ shrink: true }}
              select
              label="Select Service"
              fullWidth
              onChange={handleChange}
              name="service"
              value={values.service}
              variant="outlined">
              <MenuItem value={''}>
                <i>None</i>
              </MenuItem>
              {services.map((item, index) => (
                <MenuItem
                  key={`add-product-form-service-items-${index}`}
                  value={item}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid container item xs={12} style={{ margin: '1rem 0' }}>
            <TextField
              margin="dense"
              select={nameAsSelect}
              InputLabelProps={{ shrink: true }}
              label="New Product Name"
              onChange={handleChange}
              value={values.name}
              variant="outlined"
              disabled={!values.service}
              name="name"
              fullWidth>
              {nameAsSelect
                ? [
                    <MenuItem value="" key="add-product-form-name-items-NONE">
                      <i>None</i>
                    </MenuItem>,
                    !!values.service
                      ? values.service.items.items.map((_item, index) => (
                          <MenuItem
                            key={`add-product-form-name-items-${index}`}
                            value={_item.name}>
                            {_item.name}
                          </MenuItem>
                        ))
                      : null
                  ]
                : null}
            </TextField>
          </Grid>
          <Grid container item xs={12}>
            <TextField
              margin="dense"
              label="Description"
              InputLabelProps={{ shrink: true }}
              value={values.description}
              name="description"
              disabled={!values.service}
              onChange={handleChange}
              variant="outlined"
              placeholder="Please specify if quantities should be in per/pair, per/kg, per/item, or per/load when providing product details"
              rows={3}
              multiline
              fullWidth
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justify="flex-end">
            <Button onClick={handleClose} size="small">
              Cancel
            </Button>
            <Button
              size="small"
              type="submit"
              onClick={handleSubmit}
              color="primary"
              variant="contained">
              Save
            </Button>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddProductModal;
// TODO : add proptypes
