import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { useAmplifyAuth } from 'context';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Timer } from 'components/molecules';

const useStyles = makeStyles((theme) => ({
  root: {},
  fontStyle: {
    fontSize: 16
  },
  accountNumber: {
    fontSize: 16,
    letterSpacing: '0.1rem'
  },
  showCodeBtn: {
    color: '#3f51b5',
    cursor: 'pointer',
    margin: '0 0.5rem 0 1rem'
  }
}));

const BankAccountDetails = ({ accountDetails, setEditAccountForm }) => {
  const classes = useStyles();
  const {
    state: { isAdmin }
  } = useAmplifyAuth();
  const mdDown = useMediaQuery(useTheme().breakpoints.down('md'));
  const [showAccountInfo, setShowAccountInfo] = useState(false);
  const formatedSortCode = accountDetails.accountSortCode
    ?.slice(-3)
    .padStart(accountDetails.accountSortCode.length, '*');
  const formatedAccountNumber = accountDetails.accountNumber
    ?.slice(-4)
    .padStart(accountDetails.accountNumber.length, '*');

  const handleAccountInfoVisibilitySwitch = () =>
    setShowAccountInfo((prev) => !prev);

  useEffect(() => {
    const timeout =
      showAccountInfo && setTimeout(handleAccountInfoVisibilitySwitch, 30000);

    return () => clearTimeout(timeout);
  }, [showAccountInfo]);

  return (
    <Card style={{ minWidth: '100%', minHeight: '98%' }}>
      <CardHeader title="Bank Account Details" />
      <CardContent>
        <Grid container style={{ gap: '1rem' }}>
          <Grid container justify={!isAdmin ? 'center' : 'flex-start'}>
            <Grid item xs={mdDown ? 6 : 3}>
              <Typography variant="h6" className={classes.fontStyle}>
                Account Holder Name:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.fontStyle}>
                {accountDetails.accountHolderName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify={!isAdmin ? 'center' : 'flex-start'}>
            <Grid item xs={mdDown ? 6 : 3}>
              <Typography variant="h6" className={classes.fontStyle}>
                Bank Name:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.fontStyle}>
                {accountDetails.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify={!isAdmin ? 'center' : 'flex-start'}>
            <Grid item xs={mdDown ? 6 : 3}>
              <Typography variant="h6" className={classes.fontStyle}>
                Account Number:
              </Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography className={classes.accountNumber}>
                {showAccountInfo
                  ? accountDetails.accountNumber
                  : formatedAccountNumber}
              </Typography>
              {showAccountInfo ? (
                <>
                  <VisibilityOffIcon
                    className={classes.showCodeBtn}
                    onClick={handleAccountInfoVisibilitySwitch}
                  />
                  <Timer
                    endTime={new Date(new Date().getTime() + 30000)}
                    timerStyleType="text"
                  />
                </>
              ) : (
                <VisibilityIcon
                  className={classes.showCodeBtn}
                  onClick={handleAccountInfoVisibilitySwitch}
                />
              )}
            </Grid>
          </Grid>
          <Grid container justify={!isAdmin ? 'center' : 'flex-start'}>
            <Grid item xs={mdDown ? 6 : 3}>
              <Typography variant="h6" className={classes.fontStyle}>
                Sort Code:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.accountNumber}>
                {showAccountInfo
                  ? accountDetails.accountSortCode
                  : formatedSortCode}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions style={{ marginTop: '0.5rem', padding: '8px 16px' }}>
        <Grid
          container
          item
          justify={!isAdmin ? 'center' : 'flex-start'}
          alignItems="center"
          xs={12}
          style={{ gap: '1rem' }}>
          <Button
            color="primary"
            variant="contained"
            size={!isAdmin ? 'medium' : 'small'}
            onClick={() => setEditAccountForm(true)}>
            Edit
          </Button>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default BankAccountDetails;
