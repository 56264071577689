import React from 'react';
import { Typography, Grid, Divider, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getFormattedName } from 'common/utilFunctions';

const useStyles = makeStyles(() => ({
  addressContainerCard: {
    border: '1px solid #979797',
    borderRadius: '11px',
    padding: '16px',
    marginTop: '16px'
  },
  fontColorForAddress: {
    color: '#454545'
  },
  driverHeading: {
    fontWeight: 600,
    fontSize: '14px',
    marginBottom: '0.3rem',
    color: '#1f6893'
  }
}));

const AddressCard = ({ heading = '', address, driverDetails = {} }) => {
  const classes = useStyles();
  const {
    buildingName = '',
    buildingNumber = '',
    country = '',
    district = '',
    line1 = '',
    line2 = '',
    phoneNumber = '',
    postcode = '',
    town = ''
  } = JSON.parse(address);

  return (
    <Grid container direction="column">
      <Typography variant="h4">{heading} Address</Typography>
      <Grid
        container
        className={classes.addressContainerCard}
        direction="column">
        <Box style={{ marginBottom: '0.5rem' }}>
          <Typography
            variant="subtitle2"
            className={classes.fontColorForAddress}>
            {line1}
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.fontColorForAddress}>
            {line2}
          </Typography>
          <Typography
            variant="subtitle2"
            className={
              classes.fontColorForAddress
            }>{`${buildingNumber}, ${buildingName}`}</Typography>
          <Typography
            variant="subtitle2"
            className={
              classes.fontColorForAddress
            }>{`${district}, ${postcode}`}</Typography>
          <Typography
            variant="subtitle2"
            className={classes.fontColorForAddress}>
            {town}
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.fontColorForAddress}>
            {country}
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.fontColorForAddress}>
            {phoneNumber}
          </Typography>
        </Box>

        {/* TODO ORDER CARD DRIVERS DETAILS */}
        {/* <Divider />
        <Box style={{ marginTop: '0.5rem' }}>
          <Typography className={classes.driverHeading}>
            {heading} Driver
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.fontColorForAddress}>
            Driver name : {getFormattedName(driverDetails) || 'pending'}
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.fontColorForAddress}>
            Phone:{' '}
            {driverDetails?.phoneNumber ? (
              <a href={`tel:${driverDetails.phoneNumber}`}>
                {driverDetails.phoneNumber}
              </a>
            ) : (
              'pending'
            )}
          </Typography>
        </Box> */}
      </Grid>
    </Grid>
  );
};

export default AddressCard;
