import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Button,
  Typography
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import WorkingHoursEditPanel from './WorkingHoursEditPanel';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginRight: theme.spacing(1),
    width: 200
  },
  days: {
    minWidth: 150,
    display: 'inline-block',
    textTransform: 'capitalize',
    verticalAlign: 'top'
  },
  hoursGroup: {
    display: 'inline-block',
    verticalAlign: 'top',
    paddingTop: 5
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  right: {
    textAlign: 'right'
  }
}));

const WorkingHours = (props) => {
  const {
    title,
    subheader,
    type,
    sorryMsg,
    hours,
    setSelectedShop,
    selectedShop,
    setDisabled,
    workingDays = []
  } = props;

  const is24Hours = (filteredHours = []) => {
    if (filteredHours.length > 0) {
      const [{ closeDay = false, openTime }] = filteredHours;
      if (!closeDay && openTime === 0) {
        return true;
      }
    }
    return false;
  };

  const [canEdit, setCanEdit] = React.useState(false);
  const [selectedDay, setSelectedDay] = React.useState();
  const classes = useStyles();
  const daysArr = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
  ];

  const timeFormatter = (intValue) => {
    if (typeof intValue !== 'number') return;
    const str = ('0000' + intValue).slice(-4);
    const hours = str.slice(0, 2);
    const AmOrPm = hours >= 12 ? 'PM' : 'AM';
    const newHours = hours % 12 || 12;
    const minutes = str.slice(2);
    return newHours + ':' + minutes + ' ' + AmOrPm;
  };
  const renderHours = (i, day) => {
    const filteredHours = hours && hours.filter((o) => o.openDay === i);
    const _is24Hours = is24Hours(filteredHours);
    if (!_is24Hours && hours.length === 0) {
      return null;
    } else if (filteredHours.length === 0) {
      return <div className={classes.hoursGroup}>{sorryMsg} </div>;
    }

    return (
      <div className={classes.hoursGroup}>
        {_is24Hours ? (
          <Typography display="block" variant="body1">
            Open 24 hours
          </Typography>
        ) : (
          filteredHours.map(({ closeTime, openTime }, j) => {
            return (
              <Typography key={j} display="block" variant="body1">
                {timeFormatter(openTime)} - {timeFormatter(closeTime)}
              </Typography>
            );
          })
        )}
      </div>
    );
  };

  return (
    <div>
      <div>
        <Grid container direction="row" className={classes.flex}>
          <Grid item xs={6}>
            <CardHeader
              subheader={<span style={{ color: '#444' }}>{subheader}</span>}
              title={title}
            />
          </Grid>
          {(!hours || !hours.length) && (
            <Grid item xs={6} className={classes.right}>
              <Button
                color="primary"
                onClick={() => {
                  setCanEdit(true);
                }}>
                Edit Hours
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
      <Divider />
      <CardContent>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            {hours &&
              hours.length > 0 &&
              daysArr.map((day, i) => (
                <div key={i}>
                  <Typography className={classes.days} variant="subtitle1">
                    {day}
                  </Typography>
                  {renderHours(i, day)}

                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      setSelectedDay(i);
                      setCanEdit(true);
                    }}
                    disabled={
                      workingDays.length > 1
                        ? !workingDays.some((el) => el === i)
                        : false
                    }>
                    <EditIcon fontSize="default" />
                  </IconButton>
                </div>
              ))}
          </Grid>
        </Grid>
      </CardContent>
      {canEdit && (
        <WorkingHoursEditPanel
          hours={hours}
          type={type}
          selectedDay={selectedDay}
          setCanEdit={setCanEdit}
          selectedShop={selectedShop}
          setSelectedShop={setSelectedShop}
          setDisabled={setDisabled}
          workingDays={workingDays}
        />
      )}
    </div>
  );
};

export default WorkingHours;
