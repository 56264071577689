import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Typography,
  Paper,
  ListItem,
  List,
  IconButton,
  FormControlLabel,
  Switch,
  Collapse,
  MenuItem,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CustomListItem from './CustomListItem';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TocIcon from '@material-ui/icons/Toc';
import { useServices } from '../serviceContext';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';

const initialService = {
  name: '',
  serviceID: '',
  orderIndex: 0,
  type: 'domestic',
  disableType: false
};

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: '1rem' },
  childRoot: { marginBottom: 0 },
  inputContainer: {
    padding: '0.25rem'
  },
  buttonsContainer: {
    padding: '0.25rem'
  },
  heading: {
    fontSize: '1.25rem',
    padding: '0.25rem 0',
    marginLeft: '0.5rem',
    '& > span': {
      fontSize: '0.9rem',
      color: '#999'
    }
  },
  subHeading: {
    fontSize: '0.75rem',
    color: '#aaa',
    '& > span': {
      fontSize: '1rem',
      color: '#000'
    }
  }
}));

const listStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  disabled: {
    color: 'black',
    fontSize: '1.25rem'
  },
  iconButton: {
    padding: 0
  },
  divider: {
    height: 28,
    margin: 4
  },
  listItem: {
    padding: '0 5px'
  }
}));

export default function ServicesList({
  className = '',
  service = {},
  hideNewService = () => {},
  handleDragStart = () => {},
  handleDragEnter = () => {},
  handleDragEnd = () => {},
  isDomesticDrag = false,
  isCommercialDrag = false
}) {
  const classes = useStyles();
  const listClasses = listStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [currentService, setCurrentService] = useState(initialService);
  const [editing, setEditing] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const { services, setEditData, dispatch } = useServices();
  const md = useMediaQuery(useTheme().breakpoints.up('md'));
  const isDrag =
    service.type === 'commercial' ? isCommercialDrag : isDomesticDrag;

  useEffect(() => {
    if (service?.name) {
      setCurrentService({
        ...service,
        serviceID: service.serviceID || '',
        orderIndex: service.orderIndex || 0,
        type: service.type || 'domestic',
        disableType: !!service.serviceID
      });
      setEditing(false);
    } else {
      setCurrentService(initialService);
      setEditing(true);
    }
  }, [service?.id, newItem]);

  const handleChange = (e) => {
    e.stopPropagation();
    if (e.target.name === 'type') {
      setCurrentService({
        ...currentService,
        [e.target.name]: e.target.checked ? 'commercial' : 'domestic'
      });
    } else if (e.target.name === 'serviceID') {
      setCurrentService({
        ...currentService,
        [e.target.name]: e.target.value,
        type: !!e.target.value
          ? services.find((_service) => _service.id === e.target.value)?.type ||
            currentService.type
          : currentService.type,
        disableType: !!e.target.value
      });
    } else {
      setCurrentService({
        ...currentService,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleSubmit = (e) => {
    e.stopPropagation();
    const isServiceExists = services
      .filter((_service) => _service.id !== currentService.id)
      .find(
        (item) =>
          item.name.toLowerCase().trim() ===
          currentService.name.toLowerCase().trim()
      );
    if (currentService.name && currentService.orderIndex >= 0) {
      if (isServiceExists) {
        enqueueSnackbar(
          `Service named "${currentService.name}" already exists`,
          {
            variant: 'error',
            preventDuplicate: true,
            persist: true
          }
        );
        return;
      }

      const { disableType, ...payload } = currentService;

      // updating orderIndex and type for services with parent
      if (!!payload.serviceID) {
        const parent = services.find(
          (_service) => _service.id === payload.serviceID
        );
        payload.orderIndex = parent.orderIndex + 0.1;
        payload.type = parent.type || 'domestic';
      }

      if (currentService.id) {
        dispatch({
          type: 'updateNewService',
          payload
        }).then(() => setEditing(false));
      } else {
        dispatch({
          type: 'addNewService',
          payload
        }).then(hideNewService);
      }
    }
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    setEditData({
      name: service.name,
      description: service.description,
      id: service.id,
      _version: service._version,
      moduleType: 'Service'
    });
  };

  const handleClose = (e) => {
    e.stopPropagation();
    if (service?.name) {
      setCurrentService({
        ...service,
        orderIndex: service.orderIndex || 0,
        type: service.type || 'domestic'
      });
    }
    setEditing(false);
    hideNewService();
  };

  const saveItem = (name = '') => {
    const isServiceItemExists = currentService.items.items.find(
      (item) => item.name.toLowerCase().trim() === name.toLowerCase().trim()
    );
    if (isServiceItemExists) {
      enqueueSnackbar(`Product named "${name}" already exists`, {
        variant: 'error',
        preventDuplicate: true,
        persist: true
      });
      return;
    }
    dispatch({
      type: 'addNewItem',
      payload: {
        item: { name: name.trim(), serviceID: service.id },
        successCallback: () => setNewItem(false)
      }
    });
  };

  const handleAddItem = (e) => {
    e.stopPropagation();
    setShowDropDown(true);
    setNewItem(true);
  };

  const handleEditService = (e) => {
    e.stopPropagation();
    setEditing(true);
  };

  const isNotSubService = (_service) =>
    _service.id !== service?.id &&
    (!!_service.serviceID
      ? _service.serviceID !== service?.id &&
        isNotSubService(
          services.find((item) => item.id === _service.serviceID) || {}
        )
      : true);

  return (
    <Grid item xs={12} className={clsx(classes.root, className)}>
      <List className={listClasses.root} component={Paper}>
        <ListItem
          draggable={isDrag}
          onDragStart={handleDragStart}
          onDragEnter={handleDragEnter}
          onDragEnd={handleDragEnd}
          onDragOver={(e) => e.preventDefault()}
          className={listClasses.listItem}
          component="div"
          button
          onClick={() => {
            if (!isDrag) setShowDropDown(!showDropDown);
          }}>
          <Grid container alignItems="center">
            {editing ? (
              <Grid container justify="space-between" alignItems="center">
                <Grid item xs={12} sm={4} className={classes.inputContainer}>
                  <TextField
                    placeholder="Enter Service name"
                    inputProps={{ 'aria-label': 'enter service name' }}
                    value={currentService.name}
                    name="name"
                    onChange={handleChange}
                    size="small"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4} className={classes.inputContainer}>
                  <TextField
                    select
                    name="serviceID"
                    variant="outlined"
                    size="small"
                    label="Select Service"
                    value={currentService.serviceID || ''}
                    onChange={handleChange}
                    fullWidth>
                    <MenuItem value="">
                      <i>None</i>
                    </MenuItem>
                    {services.filter(isNotSubService).map((item, index) => (
                      <MenuItem
                        value={item?.id || ''}
                        key={`service-form-services-items-${index}`}>
                        {item?.name || ''}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={2}
                  className={classes.inputContainer}
                  container
                  justify="center">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={currentService.type === 'commercial'}
                        onChange={handleChange}
                        name="type"
                        disabled={currentService.disableType}
                        color="primary"
                      />
                    }
                    label={currentService.type}
                    labelPlacement={md ? 'bottom' : 'start'}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={2}
                  className={classes.buttonsContainer}
                  container
                  justify="space-around">
                  <IconButton
                    title="Save"
                    className={listClasses.iconButton}
                    onClick={handleSubmit}>
                    <CheckBoxIcon />
                  </IconButton>
                  <IconButton
                    title="Cancel"
                    className={listClasses.iconButton}
                    onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ) : (
              <Grid container item justify="space-between" alignItems="center">
                {isDrag && <TocIcon />}
                <Grid item xs={7} className={classes.inputContainer}>
                  <Typography className={classes.heading}>
                    {currentService.name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  className={classes.buttonsContainer}
                  container
                  justify="space-around">
                  <IconButton
                    title="Add item"
                    className={listClasses.iconButton}
                    onClick={handleAddItem}
                    disabled={isDrag}>
                    <AddBoxRoundedIcon />
                  </IconButton>
                  <IconButton
                    title="Edit item"
                    className={listClasses.iconButton}
                    onClick={handleEditService}
                    disabled={isDrag}>
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    title="Delete item"
                    className={listClasses.iconButton}
                    onClick={handleDelete}
                    disabled={isDrag}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </ListItem>

        {newItem && (
          <CustomListItem
            editable={true}
            setNewItem={setNewItem}
            saveItem={saveItem}
          />
        )}

        {service?.items?.items?.length > 0 ? (
          <Collapse in={showDropDown}>
            {service.items.items.map((item, i) => (
              <CustomListItem item={item} key={i} />
            ))}
            {services
              .filter((_service) => _service.serviceID === service.id)
              .map((_service, index) => (
                <ServicesList
                  className={classes.childRoot}
                  key={`sub-service-items-${index}`}
                  service={_service}
                />
              ))}
          </Collapse>
        ) : null}
      </List>
    </Grid>
  );
}
