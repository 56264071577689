export const getCurrentOpenAds = /* GraphQL */ `
  query GetCurrentOpenAds($page: Pages!) {
    getCurrentOpenAds(page: $page) {
      id
      shopID
      isClosed
      template
      content
      page
      priority
      shop {
        id
        shopID
        name
      }
      type
      redirectLink
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;

export const listAddresss = /* GraphQL */ `
  query ListAddresss(
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddresss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        postcode
        buildingName
        buildingNumber
        line1
        line2
        town
        county
        district
        country
        phoneNumber
        contactName
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const getAddress = /* GraphQL */ `
  query GetAddress($id: ID!) {
    getAddress(id: $id) {
      id
      userID
      postcode
      buildingName
      buildingNumber
      line1
      line2
      town
      county
      district
      country
      phoneNumber
      contactName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
