import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  Grid,
  TextField,
  MenuItem,
  Typography,
  Select,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  InputLabel
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useShops } from 'context/shop/shopsContext';

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 550
    }
  }
};

// TODO: need to configure for URL
export function OrderForwardDialog({
  onSuccess = () => {},
  dialogType,
  handleModalClose = () => {},
  order
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedShops, setSelectedShops] = useState([]);
  const [forwardReason, setForwardReason] = useState('');
  const [otherForwardReason, setOtherForwardReason] = useState('');
  const [isOtherForward, setIsOtherForward] = useState(false);
  const { shops = [], selectedShop, dispatch } = useShops();

  useEffect(() => {
    if (dialogType === 'forwarded' && selectedShop) {
      dispatch({
        type: 'getShopsByPostCode',
        payload: {
          postalCode: selectedShop.postcode,
          showSnackbar: false
        }
      });
    }
  }, [selectedShop]);

  const selectShopsHandler = (event) => {
    if (event.target.value.length > 5) {
      enqueueSnackbar(
        'You can select a maximum of five shops for your order to forward',
        {
          variant: 'error',
          preventDuplicate: true,
          persist: true
        }
      );
      return;
    }
    setSelectedShops(event.target.value);
  };

  const handleDeclineMsgChange = (event) => {
    setForwardReason(event.target.value);
    event.target.value === 'other'
      ? setIsOtherForward(true)
      : setIsOtherForward(false);
  };

  const otherDeclineReasonHandler = (event) => {
    setOtherForwardReason(event.target.value);
  };

  const handleClose = () => {
    setSelectedShops([]);
    setForwardReason('');
    handleModalClose();
    setIsOtherForward(false);
  };

  const handleSubmit = () => {
    if (forwardReason === 'other' && !otherForwardReason) {
      enqueueSnackbar('please provide a valid reason for forwarding', {
        variant: 'error',
        preventDuplicate: true
      });
      return;
    }

    const forwardRequestData = {
      orderID: order.id,
      forwardReason:
        forwardReason === 'other' ? otherForwardReason : forwardReason,
      requestShopID: order.shopID,
      forwardShopIDs: selectedShops.map((shop) => shop.id)
    };

    onSuccess({
      forwardRequest: forwardRequestData,
      order: { ...order, status: 'forwarded' },
      sts: 'forwarded'
    });

    setSelectedShops([]);
    setOtherForwardReason('');
    setForwardReason('');
    setIsOtherForward(false);
  };

  return (
    <Dialog
      open={dialogType === 'forwarded'}
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      {shops.length > 0 ? (
        <Grid container direction="column" justify="center">
          <Grid item xs={12}>
            <DialogTitle id="form-dialog-title">
              Select the shops you need this order to forward
            </DialogTitle>
            <DialogContent
              style={{ height: 'fit-content', width: 850, overflow: 'hidden' }}>
              <Grid container>
                <Grid item xs={12} style={{ marginBottom: '0.5rem' }}>
                  <Select
                    multiple
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                    value={selectedShops}
                    renderValue={(selected) => {
                      return selected.map((item) => item.name).join(', ');
                    }}
                    onChange={selectShopsHandler}
                    MenuProps={MenuProps}>
                    {shops
                      .filter((shop) => shop.id !== selectedShop.id)
                      .map((shop) => {
                        return (
                          <MenuItem key={shop.id} value={shop}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedShops.some((item) => {
                                    return item.id === shop.id;
                                  })}
                                />
                              }
                              label={`${shop.name} - ${shop.postcode}, ${shop.address}`}
                            />
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>

          <Grid item xs={12}>
            <DialogTitle id="form-dialog-title">
              Please select a reason why you are not able to accept the order ?
            </DialogTitle>
            <DialogContent
              style={{ height: 'fit-content', overflow: 'hidden' }}>
              <Grid container>
                <Grid item xs={12} style={{ marginBottom: '0.5rem' }}>
                  <TextField
                    select
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={forwardReason}
                    error={forwardReason === ''}
                    required
                    onChange={handleDeclineMsgChange}
                    label="Reason for forwarding">
                    <MenuItem value={'Delivery date & time is unachievable'}>
                      Delivery date &amp; time is unachievable
                    </MenuItem>
                    <MenuItem
                      value={'All our washing machines are fully occupied'}>
                      All our washing machines are fully occupied
                    </MenuItem>
                    <MenuItem
                      value={'unexpectedly we are not taking orders now'}>
                      Unexpectedly we are not taking orders now
                    </MenuItem>
                    <MenuItem value={'other'}>Other reasons</MenuItem>
                  </TextField>
                </Grid>

                {isOtherForward && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="other-reasons-text"
                      label="Other Reasons"
                      multiline
                      rows={3}
                      variant="outlined"
                      onChange={otherDeclineReasonHandler}
                    />
                  </Grid>
                )}
              </Grid>
            </DialogContent>
          </Grid>

          <Typography
            style={{ padding: '1.7rem', color: 'red' }}
            variant="body2">
            Note: after forwarding, this order will be treated as declined from
            your shop.
          </Typography>

          <DialogActions>
            <Button onClick={handleClose} variant="contained" autoFocus>
              Close
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={!selectedShops.length > 0 || !forwardReason}
              autoFocus>
              Forward
            </Button>
          </DialogActions>
        </Grid>
      ) : (
        <DialogContent style={{ height: 370, width: 850, textAlign: 'center' }}>
          <Grid
            container
            item
            justify="center"
            alignItems="center"
            style={{ height: '50%' }}>
            <Grid item xs={12}>
              <Typography variant="h4" align="center">
                Shops are loading...
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  );
}
