import React, { useState, useEffect } from 'react';
import { StyledTabs, StyledTab, TabPanel } from '../../components/molecules';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Button,
  Grid,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { useOrders } from 'context/order/ordersContext';
import { useAmplifyAuth } from 'context';
import { DeclineDialog } from '../Orders/components/dialogs/DeclineDialog';
import { ConfirmOrderDialog } from '../Orders/components/dialogs/ConfirmOrderDialog';
import { ItemisedDialog } from '../Orders/components/dialogs/ItemisedDialog';
import AppBar from '@material-ui/core/AppBar';
import SearchIcon from '@material-ui/icons/Search';
import OrdersTable from '../Orders/components/OrdersTable';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper
  }
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const Messages = () => {
  const classes = useStyles();
  const { orders, dispatch } = useOrders();
  const {
    state: { user }
  } = useAmplifyAuth();
  const [dialogType, setDialogType] = useState(null);
  const [status, setStatus] = useState(null);
  const [payload, setPayload] = useState({});
  const [value, setValue] = React.useState(0);
  const [searchBy, setSearchBy] = React.useState('');
  const [badge, setBadge] = React.useState(true);

  useEffect(() => {
    const { shopId, shopID } = user;
    if (shopId || shopID) {
      dispatch({ type: 'getOrders', payload: { shopId: shopID || shopId } });
    }
  }, [user]);
  const onSearchChange = (event) => {
    setSearchBy(event.target.value.toLowerCase());
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setBadge(false);
  };
  const updateOrder = (order, sts) => {
    setPayload({ order, sts });
    setDialogType(sts);
  };
  const onSuccess = () => {
    setDialogType(null);
    dispatch({ type: 'updateOrder', payload });
    setStatus(null);
  };

  return (
    <>
      <Grid className={classes.root}>
        <Grid container item justify="flex-end" xs={12}>
          <Button
            color="secondary"
            component="a"
            href="/admin/OrderHistory"
            variant="contained">
            order history
          </Button>
        </Grid>
        <AppBar
          position="static"
          style={{
            backgroundColor: 'transparent',
            color: '#263238',
            paddingBottom: '1rem',
            marginTop: '1rem'
          }}>
          <Grid container justify="space-between" alignItems="center">
            <Grid container item xs={8} justify="flex-start">
              <StyledTabs
                value={value}
                onChange={handleChange}
                aria-label="orders-tabs">
                <StyledTab
                  label={
                    value === 0 ? (
                      'New Orders'
                    ) : (
                      <Grid container justify="center" alignItems="center">
                        New Orders
                        <FiberManualRecordIcon
                          style={{ fontSize: '10px', color: 'red' }}
                          color="secondary"
                        />
                      </Grid>
                    )
                  }
                  showBadge={value === 0 ? true : false}
                  badgeContent="1"
                  style={{ marginTop: value === 0 ? 0 : '0.8rem' }}
                  {...a11yProps(0)}
                />
                <StyledTab
                  label="Item & Pricing"
                  {...a11yProps(1)}
                  style={{ marginTop: '0.8rem' }}
                />
                <StyledTab
                  label={
                    value === 2 ? (
                      'Processing'
                    ) : (
                      <Grid container justify="center" alignItems="center">
                        Processing
                        <FiberManualRecordIcon
                          style={{ fontSize: '10px', color: 'red' }}
                          color="secondary"
                        />
                      </Grid>
                    )
                  }
                  showBadge={value === 2 ? true : false}
                  badgeContent="1"
                  style={{ marginTop: value === 2 ? 0 : '0.8rem' }}
                  {...a11yProps(2)}
                />
                <StyledTab
                  label="Delivery"
                  {...a11yProps(3)}
                  style={{ marginTop: '0.8rem' }}
                />
              </StyledTabs>
            </Grid>
            <Grid
              item
              container
              xs={3}
              justify="flex-end"
              style={{ paddingRight: '0.5rem' }}>
              <TextField
                label="Search Order"
                size="small"
                value={searchBy}
                onChange={onSearchChange}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="order-search-input" edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </AppBar>
        <TabPanel value={value} index={0}>
          <OrdersTable
            stage={1}
            statuses={['newOrder']}
            updateOrder={updateOrder}
            searchBy={searchBy}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <OrdersTable
            stage={2}
            statuses={['newOrder']}
            updateOrder={updateOrder}
            searchBy={searchBy}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <>
            <OrdersTable
              stage={3}
              statuses={['newOrder']}
              updateOrder={updateOrder}
              style={{ marginBottom: '1rem' }}
              searchBy={searchBy}
            />
            <OrdersTable
              stage={4}
              statuses={['newOrder']}
              updateOrder={updateOrder}
              searchBy={searchBy}
            />
          </>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <>
            <OrdersTable
              stage={5}
              statuses={['newOrder']}
              setDialogType={setDialogType}
              searchBy={searchBy}
              updateOrder={updateOrder}
              style={{ marginBottom: '1rem' }}
            />
            <OrdersTable
              stage={6}
              statuses={['newOrder']}
              updateOrder={updateOrder}
              searchBy={searchBy}
            />
          </>
        </TabPanel>
      </Grid>
      <DeclineDialog
        dialogType={dialogType}
        onSuccess={onSuccess}
        setDialogType={setDialogType}
      />
      <ConfirmOrderDialog
        dialogType={dialogType}
        onSuccess={onSuccess}
        setDialogType={setDialogType}
      />
      <ItemisedDialog
        dialogType={dialogType}
        onSuccess={onSuccess}
        order={payload.order}
        setDialogType={setDialogType}
      />
    </>
  );
};

export default Messages;
