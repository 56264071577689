import React from 'react';
import {
  Grid,
  TableCell,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import PaymentStatusTag from 'components/molecules/PaymentStatusTag';
import {
  getFormattedPriceRange,
  addRangeToPrice,
  getOrderTotal,
  showPrice,
  unitFormatter,
  getCollectionAndDeliveryFeeText,
  getCollectionAndDeliveryFee,
  showPartialPaymentRecord
} from 'common/utilFunctions';
import { PAYMENT_CANCELLED_ORDER_STATUSES } from 'common/constants';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  backgroundColor: {
    backgroundColor: '#D5EEFF'
  },
  discountText: {
    fontSize: '0.75rem'
  }
}));

const OrderDetailInvoice = ({
  order,
  partiallyPaidRecord,
  prevTotal,
  sm = false
}) => {
  const classes = useStyles();

  let balance = order?.total;
  return (
    <>
      <TableRow>
        {!sm ? (
          <TableCell
            rowSpan={7 + partiallyPaidRecord?.length}
            colSpan={!order.bidID ? 3 : 2}
            style={{ textAlign: 'center' }}>
            <PaymentStatusTag order={order} prevTotal={prevTotal} />
          </TableCell>
        ) : null}

        <TableCell colSpan={2}>Subtotal</TableCell>
        <TableCell align="right">
          {!!order.variableTotal
            ? getFormattedPriceRange(
                addRangeToPrice(getOrderTotal(order) || 0, order.variableTotal)
              )
            : showPrice(getOrderTotal(order) || 0)}
        </TableCell>
      </TableRow>
      {/* order total discounts */}
      {order.coupons?.length > 0 ? (
        <TableRow>
          <TableCell colSpan={3}>
            <Grid container style={{ marginBottom: '0.25rem' }}>
              <Typography variant="body1">Coupons -</Typography>
            </Grid>
            {order.coupons.map((_item, index) => {
              const orderCoupon = JSON.parse(_item);
              return (
                <Grid
                  container
                  justify="space-between"
                  key={`order-coupons-${orderCoupon.id || ''}-items-${index}`}>
                  <Typography variant="body1" className={classes.discountText}>
                    {orderCoupon.title || ''}
                  </Typography>
                  <Typography variant="body1" className={classes.discountText}>
                    - {showPrice(orderCoupon.discountAmountApplied || 0)}
                  </Typography>
                </Grid>
              );
            })}
          </TableCell>
        </TableRow>
      ) : null}
      {/* tax */}
      {process.env.REACT_APP_VAT === 'enabled' ? (
        <TableRow>
          <TableCell colSpan={2}>VAT (20%)</TableCell>
          <TableCell align="right">{showPrice(order?.tax || 0)}</TableCell>
        </TableRow>
      ) : null}
      {/* asap fee */}
      {order.collection.type === 'asap' ? (
        <TableRow>
          <TableCell colSpan={2}>ASAP Fee</TableCell>
          <TableCell align="right">
            {showPrice(order?.shop?.AsapDeliveryFee || 0)}
          </TableCell>
        </TableRow>
      ) : null}
      {/* collection and delivery fee */}
      <TableRow>
        <TableCell colSpan={2}>
          {getCollectionAndDeliveryFeeText(order)}
        </TableCell>
        <TableCell align="right">
          {getCollectionAndDeliveryFee(order)}
        </TableCell>
      </TableRow>
      {/* total */}
      <TableRow className={classes.backgroundColor}>
        <TableCell colSpan={2}>Total : in {order.paymentMethod}</TableCell>
        <TableCell align="right">
          {!!order.variableTotal
            ? getFormattedPriceRange(
                addRangeToPrice(order.total || 0, order.variableTotal)
              )
            : showPrice(order.total || 0)}
        </TableCell>
      </TableRow>

      {order.onHoldPaymentAmount > 0 && order.paymentMethod === 'card' && (
        <TableRow>
          <TableCell colSpan={2}>Authorised Amount</TableCell>
          <TableCell align="right">
            {showPrice(order.onHoldPaymentAmount)}
          </TableCell>
        </TableRow>
      )}

      {order.paymentStatus === 'paid' && order.paymentMethod === 'card' && (
        <>
          <TableRow>
            <TableCell colSpan={2}>Refunded</TableCell>
            <TableCell align="right">
              {showPrice(
                order.total > order.onHoldPaymentAmount
                  ? order.onHoldPaymentAmount
                  : order.onHoldPaymentAmount - order.total
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>Finally paid</TableCell>
            <TableCell align="right">{showPrice(order.total || 0)}</TableCell>
          </TableRow>
        </>
      )}

      {order.onHoldPaymentAmount > 0 &&
        PAYMENT_CANCELLED_ORDER_STATUSES.includes(order.status) && (
          <TableRow>
            <TableCell colSpan={2}>Refunded</TableCell>
            <TableCell align="right">
              {showPrice(order.onHoldPaymentAmount)}
            </TableCell>
          </TableRow>
        )}

      {partiallyPaidRecord?.length > 0 &&
        (showPartialPaymentRecord(partiallyPaidRecord, order.total) ? (
          <>
            {partiallyPaidRecord.map((el, i) => {
              balance -= el.amount;

              return (
                <TableRow key={i}>
                  <TableCell colSpan={2}>
                    Partial {unitFormatter(order.paymentMethod)} Payment on
                    :&nbsp;
                    {moment(el.date).format('DD/MM/YYYY, h:mma')}
                    <br />
                    {balance <= 0 ? 'Total Amount Due:' : 'Balance Due:'}
                  </TableCell>
                  <TableCell align="right">
                    {showPrice(el.amount)}
                    <br />
                    {showPrice(balance)}
                  </TableCell>
                </TableRow>
              );
            })}
          </>
        ) : (
          <TableRow>
            <TableCell colSpan={2}>
              {unitFormatter(order.paymentMethod)} Payment on :&nbsp;
              {moment(partiallyPaidRecord[0].date).format('DD/MM/YYYY, h:mm a')}
            </TableCell>
            <TableCell align="right">
              {showPrice(partiallyPaidRecord[0].amount)}
            </TableCell>
          </TableRow>
        ))}
    </>
  );
};

export default OrderDetailInvoice;
