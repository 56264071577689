import React, { useState, useEffect } from 'react';
import { Grid, Menu, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { days } from 'common/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px'
  },
  mainMenu: {
    width: '300px'
  },
  blueBtn: {
    color: theme.palette.blue.main,
    fontWeight: '400',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  dayRoot: {
    margin: '0.25rem 0'
  }
}));

const convertToTime = (time) => {
  if (time < 1000) {
    time = '0' + time;
  } else {
    time = time.toString();
  }
  const hrs = time.toString().match(/\d{2}/);
  const amOrPm = hrs < 12 ? ' am' : ' pm'; // Set AM/PM
  const hrsIn12 = hrs % 12 || 12; // Adjust hours
  return time.replace(/(\d{2})(\d{2})/, hrsIn12 + ':$2' + amOrPm);
};

const WorkingHoursMenu = ({ anchorEl, handleClose, workingHours = [] }) => {
  const classes = useStyles();
  const [orderedDays, setOrderedDays] = useState([]);

  useEffect(() => {
    setOrderedDays([
      ...days.filter((_, index) => index >= new Date().getDay()),
      ...days.filter((_, index) => index < new Date().getDay())
    ]);
  }, []);

  return (
    <Menu
      anchorEl={anchorEl}
      elevation={1}
      getContentAnchorEl={null}
      id="working-hours-menu"
      keepMounted
      onClose={handleClose}
      open={Boolean(anchorEl)}
      classes={{ list: classes.mainMenu }}>
      <Grid container className={classes.root}>
        <Grid container>
          {orderedDays.map(({ name, id }) => (
            <Grid
              container
              alignItems="center"
              justify="space-between"
              key={`shop-working-hours-menu-days-${id}`}
              className={classes.dayRoot}>
              <Typography
                variant="h6"
                style={{
                  fontWeight: id === new Date().getDay() ? 'bold' : 'normal'
                }}>
                {name}
              </Typography>
              <Typography
                variant="h6"
                align="right"
                style={{
                  fontWeight: id === new Date().getDay() ? 'bold' : 'normal'
                }}>
                {workingHours.filter((item) => item.openDay === id).length > 0
                  ? workingHours
                      .filter((item) => item.openDay === id)
                      .map(({ openTime, closeTime }, index) => (
                        <span key={`shop-working-hours-menu-times-${index}`}>
                          {!closeTime
                            ? 'Open 24 hours'
                            : `${convertToTime(openTime)} - ${convertToTime(
                                closeTime
                              )}`}
                          <br />
                        </span>
                      ))
                  : 'Closed'}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Menu>
  );
};

export default WorkingHoursMenu;
