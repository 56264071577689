import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SearchShop from 'components/SearchShop';
import { API, graphqlOperation } from 'aws-amplify';
// import { createOrder } from 'graphql/mutations';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useProducts } from 'context/products/productsContext';
import { getItem } from 'graphql/queries';
import { useLoader } from 'layouts/loaderContext';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  title: {
    margin: '25px 0 10px'
  }
}));

const DummyOrder = () => {
  const classes = useStyles();
  const [state, setState] = useState({ status: 'newOrder' });
  const { products, dispatch } = useProducts();
  const [items, cacheItems] = useState({});
  const { setLoading } = useLoader();

  // const _createOrder = () => {
  //   const payload = { input: state };
  //   API.graphql(graphqlOperation(createOrder, payload)).then((data) =>
  //     console.log(data)
  //   );
  // };
  const handleShopChange = (e, shop) => {
    dispatch({ type: 'getProductsByShopId', payload: shop });
    setState({ ...state, shopID: shop.id });
  };
  const updateOrder = async (product, quantity) => {
    const { orderList: _orderList = [] } = state;
    const { id, _version, itemID } = product;
    const orderList = _orderList.filter(({ productID: p }) => p !== id);
    let item = items[itemID];
    if (!item && itemID) {
      setLoading(true);
      const { data } = await API.graphql(
        graphqlOperation(getItem, { id: itemID })
      );
      item = data.getItem;
      cacheItems({ ...items, [itemID]: item });
      setLoading(false);
    }
    if (quantity > 0) {
      const updateData = {
        category: product.category || item.category,
        name: product.name || item.name,
        description: product.description || item.description,
        unit: product.unit || item.unit,
        price: product.price || item.price || 1
      };
      orderList.push({ productID: id, quantity, ...updateData });
    }
    setState({ ...state, orderList });
  };
  const updateValue = (property, value) => {
    setState({ ...state, [property]: value });
  };
  return (
    <div className={classes.root}>
      <SearchShop onChange={handleShopChange} />
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell align="right">quantity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products &&
            Object.keys(products).map((product) => (
              <TableRow key={product}>
                <TableCell component="th" scope="row">
                  {products[product].id}
                </TableCell>
                <TableCell align="right">
                  {' '}
                  <TextField
                    onChange={(e) =>
                      updateOrder(products[product], e.currentTarget.value)
                    }
                    variant="outlined"
                    color="secondary"
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <div>
        userID :{' '}
        <TextField
          onChange={(e) => updateValue('userID', e.currentTarget.value)}
          variant="outlined"
          color="secondary"
        />
      </div>
      <br />
      {/* <Button
        variant="contained"
        disabled={!state.shopID}
        onClick={_createOrder}>
        create offer
      </Button> */}
    </div>
  );
};

export default DummyOrder;
