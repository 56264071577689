import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useAuctions } from 'context/auctions/auctionsContext';
import { useAmplifyAuth } from 'context';
import { useSnackbar } from 'notistack';
import { DocumentDeleteDialog } from 'components/organisms';
import { showPrice } from 'common/utilFunctions';
import omit from 'lodash/omit';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    marginBottom: '1rem'
  },
  userAddress: {
    marginBottom: '1rem',
    borderBottom: '1px solid #ccc',
    paddingBottom: '1rem'
  },
  priceTextbox: {
    padding: '1rem 0 1.5rem',
    [theme.breakpoints.up('md')]: {
      padding: 0
    }
  },
  footer: {
    padding: '0.5rem 0.5rem 0',
    [theme.breakpoints.up('md')]: {
      padding: '1rem 2rem 0'
    },
    gap: '1rem'
  },
  dialogTitle: {
    backgroundColor: '#1a237e',
    marginBottom: '1rem'
  },
  whiteTxt: { color: '#fff' },
  productsContainer: {
    gap: '1rem'
  },
  shippingChargesContainer: {
    borderTop: '1px solid #ccc',
    paddingTop: '1rem',
    columnGap: '1rem'
  },
  contentContainer: {
    padding: '0 1.5rem 0.5rem'
  }
}));

const BidPlaceModal = ({
  open = false,
  handleClose = () => {},
  auctionID = ''
}) => {
  const classes = useStyles();
  const {
    state: { user }
  } = useAmplifyAuth();
  const { auctions = [], dispatch } = useAuctions();
  const { enqueueSnackbar } = useSnackbar();
  const [auction, setAuction] = useState(null);
  const [items, setItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [shippingCharges, setShippingCharges] = useState('');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const shouldEnableBidPlace = useMemo(
    () =>
      auction &&
      !auction.isClosed &&
      new Date(auction.startDateTime) < new Date() &&
      new Date(auction.endDateTime) > new Date(),
    [auction]
  );

  useEffect(() => {
    setAuction(auctions.find((item) => item?.id === auctionID));
  }, [auctionID]);

  useEffect(() => {
    auction &&
      setItems(
        auction.items.items.map((item) => ({
          auctionItemID: item.id,
          price: '',
          auctionItem: item
        }))
      );
  }, [auction]);

  useEffect(() => {
    setTotalPrice(
      items.reduce((p, c) => p + parseFloat(c?.price || 0), 0) +
        parseFloat(shippingCharges || 0)
    );
  }, [items, shippingCharges]);

  const handleSubmit = () => {
    // prices validation check
    if (items.some((item) => !item.price)) {
      enqueueSnackbar('Enter valid prices for all products', {
        variant: 'error',
        autoHideDuration: '3000',
        persist: true
      });
      return;
    }

    // time validation
    if (new Date(auction?.endDateTime) < new Date()) {
      enqueueSnackbar('Sorry, cannot place bid. Auction expired.', {
        variant: 'error',
        autoHideDuration: '3000',
        persist: true
      });
      return;
    }

    // shop validation check
    if (!user.id || !(user.shopID || user.shopId)) {
      enqueueSnackbar('Something went wrong...', {
        variant: 'error',
        autoHideDuration: '3000'
      });
      return;
    }

    // show confirmation dialog
    setShowConfirmationDialog(true);
  };

  const handleConfirmationDialogCancel = () => {
    setShowConfirmationDialog(false);
  };

  const handleConfirmSubmit = () => {
    handleConfirmationDialogCancel();

    // submit bid
    const bid = {
      totalPrice,
      auctionID,
      items: items.map((item) => omit(item, ['auctionItem'])),
      shopID: user.shopID || user.shopId,
      createdBy: user.id,
      shippingCharges: shippingCharges ? parseFloat(shippingCharges) : null
    };
    dispatch({
      type: 'createBid',
      payload: {
        bid,
        successCallback: () => {
          setItems([]);
          setTotalPrice(0);
          modalClosedHandler();
        }
      }
    });
  };

  const handlePriceChange = (auctionItemID = '') => (e) => {
    const re = /^[0-9]+\.?[0-9]{0,2}$/;
    const newVal = e.target.value;
    if (newVal === '' || re.test(newVal)) {
      setItems(
        items.map((item) =>
          item.auctionItemID === auctionItemID
            ? {
                ...item,
                price: newVal
              }
            : item
        )
      );
    }
  };

  const modalClosedHandler = () => {
    handleClose();
    setShippingCharges('');
  };

  const collectionInstructions = auction?.collection?.instructions;
  const deliveryInstructions = auction?.delivery?.instructions;

  return (
    <>
      <Dialog
        aria-labelledby="bid-placing-form-dialog-title"
        fullWidth
        maxWidth="md"
        onClose={modalClosedHandler}
        open={open}>
        <DialogTitle
          id="bid-placing-form-dialog-title"
          disableTypography
          className={classes.dialogTitle}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" className={classes.whiteTxt}>
                Auction #{auction?.auctionID || ''}
              </Typography>
              <Typography variant="body1" className={classes.whiteTxt}>
                Postal code: {auction?.postcode || ''}
              </Typography>
            </Grid>
            {auction?.total && (
              <Grid item>
                <Typography variant="h4" className={classes.whiteTxt}>
                  Expected Bid Total: {showPrice(auction?.total)}
                </Typography>
              </Grid>
            )}
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.contentContainer}>
          <Grid container className={classes.userAddress}>
            <Grid container style={{ marginBottom: '0.5rem' }}>
              <Typography variant="h5">Collection:&nbsp;</Typography>
              <Typography variant="body1">
                {!!auction?.collection?.date
                  ? `on ${moment(auction.collection?.date).format(
                      ' MMMM Do YYYY'
                    )} at ${JSON.parse(auction.collection.address)['line1']}, ${
                      JSON.parse(auction.collection.address)['town']
                    }.`
                  : 'Order will be dropped off by the customer.'}
              </Typography>
              {!!collectionInstructions && (
                <Grid item xs={12}>
                  <Typography variant="body2">
                    <span style={{ fontWeight: 600 }}>Instructions: </span>
                    {collectionInstructions}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid container>
              <Typography variant="h5">Delivery:&nbsp;</Typography>
              <Typography variant="body1">
                {!!auction?.delivery?.date
                  ? `on ${moment(auction.delivery?.date).format(
                      ' MMMM Do YYYY'
                    )} at ${JSON.parse(auction.delivery.address)['line1']}, ${
                      JSON.parse(auction.delivery.address)['town']
                    }.`
                  : 'Order will be picked up by the customer.'}
              </Typography>
              {!!deliveryInstructions && (
                <Grid item xs={12}>
                  <Typography variant="body2">
                    <span style={{ fontWeight: 600 }}>Instructions: </span>
                    {deliveryInstructions}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.headerContainer}
            direction="column">
            <Typography variant="h5">Products</Typography>
            {shouldEnableBidPlace ? (
              <Typography variant="body2">
                Enter your best price for each item if you wish to place a bid.
              </Typography>
            ) : null}
          </Grid>
          <Grid container className={classes.productsContainer}>
            {items.map((item, index) => (
              <Grid
                container
                justify="space-between"
                key={`new-auction-items-${index}`}
                alignItems="center">
                <Grid item md={7} xs={9}>
                  <Typography variant="h5">
                    {index + 1}. {item.auctionItem.name || ''}
                  </Typography>
                  <Typography variant="body2">
                    {item.auctionItem.service?.name || ''}
                  </Typography>
                  <Typography variant="subtitle1">
                    {item.auctionItem.description || ''}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={2}
                  xs={3}
                  container
                  justify={md ? 'center' : 'flex-end'}>
                  {item.auctionItem.quantity} items
                </Grid>
                {shouldEnableBidPlace ? (
                  <Grid
                    item
                    md={3}
                    xs={12}
                    container
                    justify="flex-end"
                    className={classes.priceTextbox}>
                    <TextField
                      value={item.price}
                      onChange={handlePriceChange(item.auctionItemID)}
                      variant="outlined"
                      label="Enter Price"
                      placeholder="Enter your bid price"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        )
                      }}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                ) : null}
              </Grid>
            ))}

            {shouldEnableBidPlace && (
              <Grid
                container
                justify="flex-end"
                alignItems="center"
                className={classes.shippingChargesContainer}>
                <Grid item>
                  <Typography variant="h5">Shipping Charges: </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    value={shippingCharges}
                    onChange={(e) => setShippingCharges(e.target.value)}
                    variant="outlined"
                    label="Enter Price"
                    placeholder="Enter your shipping charges"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      )
                    }}
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justify="flex-end" className={classes.footer}>
            {!!totalPrice ? (
              <Grid container justify={md ? 'flex-end' : 'center'}>
                Total Value for the Bid :&nbsp; &nbsp;
                <strong>{showPrice(totalPrice)}</strong>
              </Grid>
            ) : null}
            <Grid container justify="center" style={{ gap: '0.5rem' }}>
              <Button onClick={modalClosedHandler} size="small">
                Close
              </Button>
              {shouldEnableBidPlace ? (
                <Button
                  color="primary"
                  onClick={handleSubmit}
                  variant="contained"
                  size="small">
                  Place Bid
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <DocumentDeleteDialog
        open={showConfirmationDialog}
        handleCancel={handleConfirmationDialogCancel}
        handleConfirm={handleConfirmSubmit}
        confirmText={
          auction?.bids?.items.length ? 'place another bid' : 'place this bid'
        }
        title="Confirm Bid"
      />
    </>
  );
};

export default BidPlaceModal;
