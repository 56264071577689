import React, { useState, useEffect, useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CallRoundedIcon from '@material-ui/icons/CallRounded';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Typography,
  CardActions,
  Container,
  Paper,
  TableSortLabel,
  Button
} from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  getFormattedPhoneNumber,
  showPrice,
  unitFormatter
} from '../../common/utilFunctions';
import moment from 'moment';
import { CustomSearchBar, Image } from 'components/organisms';
import Pagination from '@material-ui/lab/Pagination';
import { useOrders } from 'context/order/ordersContext';
import { getUser } from 'graphql/queries';
import { useLoader } from 'layouts/loaderContext';
import defaultUserImage from 'assets/png/default-image-user.png';

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650
  },
  profileImg: {
    height: 40,
    width: 40,
    borderRadius: '50%',
    marginRight: '1rem'
  }
}));

const INITIAL_FILTERS = {
  page: 1,
  searchBy: ''
};
const filterReducer = (state, action) => {
  const { page, searchBy } = action.payload;
  switch (action.type) {
    case 'updatePageNum': {
      return { ...state, page };
    }
    case 'updateSearchBy': {
      return { ...state, searchBy };
    }
    case 'updateAllFilters': {
      return { page, searchBy };
    }
    default: {
      throw new Error('Action type not defined');
    }
  }
};

function CustomerDetails() {
  let { userId } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const { loading } = useLoader();
  const { orders, dispatch } = useOrders();
  const [customerDetails, setCustomerDetails] = useState();
  const [sortDirection, setSortDirection] = useState('asc');
  const [filters, dispatchFilter] = useReducer(filterReducer, INITIAL_FILTERS);

  const itemsPerPage = 10;

  const paginationHandler = (event, value) => {
    dispatchFilter({ type: 'updatePageNum', payload: { page: value } });
  };

  const onSearchChange = (event) => {
    if (filters.page !== 1) {
      dispatchFilter({ type: 'updatePageNum', payload: { page: 1 } });
    }
    dispatchFilter({
      type: 'updateSearchBy',
      payload: { searchBy: event.target.value }
    });
  };

  useEffect(() => {
    if (userId) {
      API.graphql(graphqlOperation(getUser, { id: userId }))
        .then((res) => {
          setCustomerDetails(res.data.getUser);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: 'getOrdersByUserId',
      payload: {
        userId
      }
    });
  }, [userId]);

  useEffect(() => {
    dispatch({
      type: 'sortOrders',
      payload: {
        property: 'orderId',
        direction: sortDirection
      }
    });
  }, [sortDirection]);

  useEffect(() => {
    const url = history.location.search.split('&');
    const pageNum = +url[0]?.split('=')[1] || 1;
    const searchString = url[1]?.split('=')[1] || '';
    dispatchFilter({
      type: 'updateAllFilters',
      payload: { page: pageNum, searchBy: searchString }
    });
  }, []);

  useEffect(() => {
    const { page, searchBy } = filters;
    if (searchBy) {
      history.replace(
        `/admin/customers/${userId}?page=${page}&searchBy=${searchBy}`
      );
    } else {
      history.replace(`/admin/customers/${userId}?page=${page}`);
    }
  }, [filters]);

  const filterSearchOrder = ({ orderID = '' }) => {
    return orderID?.toLowerCase().includes(filters.searchBy.toLowerCase());
  };

  const handleRequestSort = (event) => {
    setSortDirection((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
  };

  const searchedOrders = orders?.filter(filterSearchOrder);
  const filteredOrders = searchedOrders.slice(
    (filters.page - 1) * itemsPerPage,
    filters.page * itemsPerPage
  );
  const noOfPages =
    searchedOrders && Math.ceil(searchedOrders.length / itemsPerPage);

  return (
    <Container style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>
      <Grid
        container
        justify="space-between"
        style={{ padding: '0.5rem', marginBottom: '0.5rem' }}>
        <Grid container item justify="flex-start" xs={2} alignItems="center">
          <Button onClick={() => history.goBack()}>
            <ArrowBackIcon />
          </Button>
        </Grid>
        <Grid item container md={3} xs={12} justify="flex-end">
          <CustomSearchBar
            value={filters.searchBy}
            onChange={onSearchChange}
            placeholder="Search Order"
            onClear={() =>
              dispatchFilter({
                type: 'updateSearchBy',
                payload: { searchBy: '' }
              })
            }
          />
        </Grid>
      </Grid>

      <Card
        style={{
          marginBottom: '1rem',
          paddingTop: '0.5rem',
          height: '9rem'
        }}>
        <CardContent>
          <Grid
            container
            justify="space-between"
            style={{ marginLeft: '1.5rem', marginBottom: '1rem' }}>
            <Grid
              container
              item
              xs={4}
              spacing={5}
              justify="flex-start"
              alignItems="center"
              direction="row">
              <Image
                docKey={customerDetails?.picture}
                alt={`${customerDetails?.firstName || 'customer'} ${
                  customerDetails?.lastName || 'photo'
                }`}
                loadFromStorage={
                  customerDetails?.picture?.split('/').at(0) === 'images'
                }
                onError={(e) => (e.target.src = defaultUserImage)}
                className={classes.profileImg}
              />
              <Typography>
                {`${customerDetails?.firstName} ${customerDetails?.lastName}`}
                <br />
                {`${getFormattedPhoneNumber(customerDetails?.phoneNumber)}`}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            item
            xs={12}
            style={{ padding: '1rem' }}>
            <Grid container item xs={6}>
              {customerDetails?.createdAt ? (
                <Typography variant="body1">
                  {`Joined on:
                   ${moment(customerDetails?.createdAt).format(
                     'MMMM Do YYYY'
                   )}`}
                </Typography>
              ) : null}
            </Grid>

            <Grid container item spacing={4} xs={6} justify="flex-end">
              <IconButton color="primary">
                <a
                  href={`tel:${getFormattedPhoneNumber(
                    customerDetails?.phoneNumber
                  )}`}>
                  <CallRoundedIcon />
                </a>
              </IconButton>
              <IconButton color="primary">
                <a href={`mailTo:${customerDetails?.email}`}>
                  <MailOutlineRoundedIcon />
                </a>
              </IconButton>
            </Grid>
          </Grid>
        </CardActions>
      </Card>

      {orders.length < 1 && !loading ? (
        <Typography align="center" variant="body1" style={{ fontSize: 18 }}>
          The user didn't place any order yet.
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="user-orders-table">
            <TableHead>
              <TableRow style={{ backgroundColor: '#F6F6F6' }}>
                <TableCell>
                  <TableSortLabel
                    active={true}
                    direction={sortDirection}
                    onClick={handleRequestSort}>
                    ORDER NO.
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">DATE ORDERED</TableCell>
                <TableCell align="left">SHOP NAME</TableCell>
                <TableCell align="left">ORDER DELIVERED</TableCell>
                <TableCell align="left">PRICE</TableCell>
                <TableCell align="left">STATUS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredOrders.map((order, index) => (
                <TableRow
                  key={`user-order-table-row-${order.id}`}
                  style={{
                    backgroundColor: index % 2 !== 0 ? '#F6F6F6' : '#FFFEFE'
                  }}>
                  <TableCell component="th" scope="row">
                    <Link
                      to={`/admin/customers/${order.userID}/orders/${order.orderID}`}>
                      {order.orderID}
                    </Link>
                  </TableCell>
                  <TableCell align="left">
                    {moment(order.createdAt).format('MMMM Do YYYY')}
                  </TableCell>
                  <TableCell align="left">{order.shop?.name}</TableCell>
                  <TableCell align="left">
                    {order.delivery?.date
                      ? moment(order.delivery?.date).format('MMMM Do YYYY')
                      : 'N/A'}
                  </TableCell>
                  <TableCell align="left">
                    {showPrice(order?.total || 0)}
                  </TableCell>
                  <TableCell align="left" style={{ color: '#3F51B5' }}>
                    {unitFormatter(order?.status)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Grid
        container
        item
        xs={12}
        justify="center"
        style={{ marginTop: '10px' }}>
        <Pagination
          count={noOfPages}
          page={filters.page}
          onChange={paginationHandler}
          defaultPage={1}
          color="secondary"
          shape="rounded"
        />
      </Grid>
    </Container>
  );
}

export default CustomerDetails;
