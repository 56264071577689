import { shopItemQuery, shopReviewReplyQuery } from './shopsQueries';

export const addShop = /* GraphQL */ `
  mutation AddShop($input: ShopInput!) {
    addShop(input: $input) {
      ${shopItemQuery}
      deliveryLocations
    }
  }
`;
export const updateShop = /* GraphQL */ `
  mutation UpdateShop(
    $input: UpdateShopInput!
    $condition: ModelShopConditionInput
  ) {
    updateShop(input: $input, condition: $condition) {
      ${shopItemQuery}
      deliveryLocations
    }
  }
`;

export const createReviewReply = /* GraphQL */ `
  mutation CreateReviewReply($reviewID: String!, $description: String!) {
    createReviewReply(reviewID: $reviewID, description: $description) ${shopReviewReplyQuery}
  }
`;

export const updateReviewReply = /* GraphQL */ `
  mutation UpdateReviewReply(
    $input: UpdateReviewReplyInput!
    $condition: ModelReviewReplyConditionInput
  ) {
    updateReviewReply(input: $input, condition: $condition) ${shopReviewReplyQuery}
  }
`;
