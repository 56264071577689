import { API, graphqlOperation } from 'aws-amplify';
import queries from './productQueries';
import mutations from './productMutations';
import {
  uploadImage,
  getCustomShopProductName,
  compressImage
} from 'common/utilFunctions';
import omit from 'lodash/omit';

export const getProductsByShop = (shopID) => {
  return new Promise((resolve, reject) => {
    API.graphql(
      graphqlOperation(queries.getProductByShop, { shopID, limit: 5000 })
    )
      .then((data) => resolve(data.data.getProductByShop.items))
      .catch((err) => reject(err));
  });
};

// function to save all products
export const saveProducts = async (products, shopID) => {
  let modifiedProducts = [];
  for (const [, value] of Object.entries(products)) {
    if (value.modify) {
      modifiedProducts.push({ ...value, shopID });
    }
  }
  let imageUploadData = [];
  await Promise.allSettled(
    modifiedProducts.map((item) => {
      if (item.file) {
        return new Promise(async (resolve) => {
          const compressedFile = await compressImage(item.file);
          uploadImage(compressedFile).then((data) =>
            resolve({ id: item.itemID, data })
          );
        });
      }
      return {};
    })
  ).then((results) => {
    results.forEach(({ value = {} }) => {
      imageUploadData[value.id] = value.data;
    });
  });

  const data = await Promise.allSettled(
    modifiedProducts.map((item) => {
      return new Promise((resolve) => {
        if (item.file) {
          item.image = imageUploadData[item.itemID];
        }
        if (item.id) {
          const input = omit(item, [
            'modify',
            'file',
            '_deleted',
            'enabled',
            'isValid',
            'item',
            'service',
            '_lastChangedAt',
            'createdAt',
            'updatedAt',
            'editors',
            'tags'
          ]);
          if (item.enabled) {
            API.graphql(graphqlOperation(mutations.updateProduct, { input }))
              .then((data) => resolve(data))
              .catch((err) => {});
          } else {
            API.graphql(
              graphqlOperation(mutations.deleteProduct, {
                input: { id: input.id, _version: input._version }
              })
            )
              .then((data) => resolve(data))
              .catch((err) => {});
          }
        } else {
          const input = omit(item, [
            'modify',
            'file',
            '_deleted',
            'enabled',
            'isValid',
            '_version'
          ]);
          if (item.enabled) {
            API.graphql(
              graphqlOperation(mutations.createAProduct, { ...input })
            ).then((data) => resolve(data));
          } else {
            resolve({});
          }
        }
      });
    })
  ).then((results) => {
    let newProducts = Object.assign({}, products);
    results.forEach(({ value: { data = {} } = {} }) => {
      const respData =
        data.createAProduct || data.updateProduct || data.deleteProduct;
      if (respData && respData._deleted) {
        delete newProducts[
          respData.itemID ||
            getCustomShopProductName(respData.name, respData.serviceID)
        ];
      } else if (respData) {
        const isValid =
          respData.price && !(!respData.unit || respData.unit === 'na');
        newProducts[
          respData?.itemID ||
            getCustomShopProductName(respData.name, respData.serviceID)
        ] = {
          ...respData,
          enabled: true,
          modify: false,
          isValid
        };
      }
    });
    return newProducts;
  });

  return data;
};
