import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useLoader } from 'layouts/loaderContext';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { SubSideNavBar } from 'components/organisms';
import { SalesUsers, Marketing } from './components';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper
} from '@material-ui/core';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import { makeStyles } from '@material-ui/core/styles';
import { getSalesUsers } from 'graphql/queries';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#eeeeee',
    overflowY: 'hidden',
    padding: '0',
    margin: '0'
  },
  content: {
    padding: '0 0.5rem'
  },
  contentPaper: {
    padding: '1rem',
    height: 'fit-content',
    minHeight: '100%'
  },
  navbar: {
    backgroundColor: 'white'
  },
  scrollContainer: {
    height: '80vh',
    overflow: 'scroll',
    width: '100%'
  }
}));

const tabs = [
  {
    title: 'Facility Manager',
    description: 'manage facility managers',
    icon: <EmojiPeopleIcon />,
    component: SalesUsers
  },
  {
    title: 'Leads',
    description: 'generate consent letters',
    icon: <LoyaltyIcon />,
    component: Marketing
  }
];

const ComponentWithProps = ({ Component, ...props }) => (
  <Component {...props} />
);

const SalesAndMarketing = () => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const { setLoading } = useLoader();
  const [salesUsers, setSalesUsers] = useState([]);
  const [dateRange, setDateRange] = useState({ from: '', to: '' });

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      setLoading(true);
      const result = await API.graphql(graphqlOperation(getSalesUsers));
      const users = result.data.getSalesUsers || [];
      users
        .sort((a, b) => {
          const aName = a?.firstName?.toUpperCase();
          const bName = b?.firstName?.toUpperCase();
          return aName > bName ? 1 : bName > aName ? -1 : 0;
        })
        .sort((a, b) => {
          return new Date(b?.updatedAt) - new Date(a?.updatedAt);
        });

      setSalesUsers(users);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={3} className={classes.navbar}>
        <SubSideNavBar
          currentItem={tabs.find((item, index) => index === currentTab)}>
          <List style={{ padding: 0 }}>
            {tabs.map((item, index) => (
              <ListItem
                component={Paper}
                key={`marketing-tab-items-${index}`}
                button
                onClick={() => setCurrentTab(index)}
                selected={currentTab === index}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={item.title}
                  secondary={item.description || ''}
                />
              </ListItem>
            ))}
          </List>
        </SubSideNavBar>
      </Grid>
      <Grid item xs={12} md={9} className={classes.content}>
        <PerfectScrollbar className={classes.scrollContainer}>
          <Paper className={classes.contentPaper}>
            <ComponentWithProps
              Component={tabs[currentTab].component}
              salesUsers={salesUsers}
              setSalesUsers={setSalesUsers}
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          </Paper>
        </PerfectScrollbar>
      </Grid>
    </Grid>
  );
};

export default SalesAndMarketing;
