const serviceReducer = (state, action) => {
  switch (action.type) {
    case 'updateData': {
      return action.payload || [];
    }
    case 'addServiceToState': {
      return [action.payload, ...state];
    }
    case 'addItemToService': {
      const data = action.payload;
      const newState = state.map((service) => {
        if (service.id === data.serviceID) {
          service.items.items.unshift(data);
        }
        return service;
      });
      return newState;
    }
    case 'updateItem': {
      const data = action.payload;
      const newState = state.map((service) => {
        if (service.id === data.serviceID) {
          service.items.items = service.items.items.map((item) => {
            if (item.id === data.id) {
              return data;
            }
            return item;
          });
        }
        return service;
      });
      return newState;
    }
    case 'updateService': {
      const data = action.payload;
      const newState = state.map((service) => {
        if (service.id === data.id) {
          data.items.items = data.items.items
            ? data.items.items.filter((item) => !item._deleted)
            : [];
          return data;
        }
        return service;
      });
      return newState;
    }
    case 'deleteItem': {
      const data = action.payload;
      const newState = state.map((service) => {
        if (service.id === data.serviceID) {
          service.items.items = service.items.items.filter(
            (item) => item.id !== data.id
          );
        }
        return service;
      });
      return newState;
    }
    case 'deleteService': {
      const data = action.payload;
      const newState = state.filter((service) => service.id !== data.id);
      return newState;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export default serviceReducer;
