import React, { useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { routes } from 'common/constants';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Menu, MenuItem, Typography } from '@material-ui/core';
import { useAmplifyAuth } from 'context';
import PersonIcon from '@material-ui/icons/Person';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.yellow.main,
    color: theme.palette.text.navyLight,
    textTransform: 'capitalize',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: theme.palette.yellow.main
    },
    [theme.breakpoints.down('md')]: {
      padding: '5px 6px',
      margin: '0'
    }
  },
  menu: {
    margin: '1.3rem 0 0 0',
    textAlign: 'center'
  },
  userName: {
    fontWeight: 600,
    color: theme.palette.text.navyLight,
    maxWidth: 75,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'block',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 65
    }
  }
}));

const MENU_LINKS = [
  {
    title: 'My Profile',
    url: routes.customer.profile
  },
  {
    title: 'My Orders',
    url: routes.customer.order
  },
  {
    title: 'My Auctions',
    url: routes.customer.auctionPending,
    baseUrl: routes.customer.auction
  },
  {
    title: 'Chat',
    url: routes.customer.chat
  }
];

const SignInButton = () => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  let {
    state: { user },
    handleSignOut
  } = useAmplifyAuth();

  const handleClick = (event) => {
    setAnchorMenuEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorMenuEl(null);
  };

  return (
    <div>
      {user?.email ? (
        <>
          <Button
            variant="contained"
            onClick={handleClick}
            size="small"
            className={classes.button}
            startIcon={<PersonIcon />}
            endIcon={<ExpandMoreIcon />}>
            <Typography className={classes.userName}>
              {user.firstName
                ? user.email === user.firstName
                  ? user.email
                  : `${user.firstName} ${user.lastName || ''}`
                : 'guest'}
            </Typography>
          </Button>
          <Menu
            anchorEl={anchorMenuEl}
            onClick={handleClose}
            onClose={handleClose}
            open={!!anchorMenuEl}
            className={classes.menu}>
            {MENU_LINKS.filter((item) =>
              process.env.REACT_APP_AUCTIONS === 'enabled'
                ? true
                : item.title !== 'My Auctions'
            ).map((item, index) => (
              <MenuItem
                component={RouterLink}
                to={item.url}
                selected={history.location.pathname.includes(
                  item.baseUrl || item.url
                )}
                disabled={history.location.pathname.includes(
                  item.baseUrl || item.url
                )}
                key={`customer-topbar-menu-items-${index}`}>
                {item.title}
              </MenuItem>
            ))}
            <MenuItem onClick={handleSignOut}>Logout</MenuItem>
          </Menu>
        </>
      ) : (
        <Button
          size="small"
          variant="contained"
          className={classes.button}
          style={{ padding: '0.125rem 0.5rem' }}
          href={`${routes.customer.login}?redirect=${history.location.pathname}${history.location.search}`}>
          Login
        </Button>
      )}
    </div>
  );
};

SignInButton.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default SignInButton;
