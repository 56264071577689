import { StepConnector } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const OrderStatusConnectorVertical = withStyles({
  active: {
    '& $line': {
      backgroundColor: '#194378'
    }
  },
  completed: {
    '& $line': {
      backgroundColor: '#194378'
    }
  },
  line: {
    border: 0,
    backgroundColor: '#F0F1F3',
    borderRadius: '15px'
  }
})(StepConnector);

export default OrderStatusConnectorVertical;
