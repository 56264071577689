import React, { useReducer, useState, useEffect, createContext } from 'react';
import { graphqlOperation, API, Auth, Hub } from 'aws-amplify';
import { getUser } from './amplifyAuthQueries';
import {
  updateAnUserLoggedInStatus,
  updateUser
} from '../../graphql/mutations';
import amplifyAuthReducer from './amplifyAuthReducer';
import { throttle } from 'lodash';

const validGroups = [
  'admin',
  'laundryAdmin',
  'sales',
  'laundryDriver',
  'laundrySales',
  'laundryStaff',
  'customer'
];
const initialState = {
  isLoading: false,
  isError: false,
  user: {},
  isAdmin: false,
  isLaundryAdmin: false,
  isLaundryStaff: false,
  isSales: false
};
const AmplifyAuthContext = createContext({});

const getRedirectURL = () => {
  const url = localStorage.getItem('redirectUrl') || '/home';
  return url;
};

const loadUserDetail = (id) =>
  new Promise((resolve) => {
    API.graphql(graphqlOperation(getUser, { id }))
      .then((data) => {
        resolve(data.data.getUser);
      })
      .catch((err) => console.log('error', err));
  });

// update user last seen instantly
const updateUserLastSeenInstant = async (status = 'online') => {
  try {
    await API.graphql(graphqlOperation(updateAnUserLoggedInStatus, { status }));
  } catch (error) {
    console.log('something went wrong', error);
  }
};

// update user last seen by with throttle period of 2 minutes
const updateUserLastSeenThrottled = throttle((status = 'online') => {
  API.graphql(
    graphqlOperation(updateAnUserLoggedInStatus, { status })
  ).catch((error) => console.log('something went wrong', error));
}, 120000);

function AmplifyAuthProvider({ children }) {
  // const [state, dispatch] = useReducer(amplifyAuthReducer, { count: 0 })
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  /// const value = { state, dispatch }
  const [state, dispatch] = useReducer(amplifyAuthReducer, initialState);
  const [triggerFetch, setTriggerFetch] = useState(false);

  const fetchUserData = async () => {
    try {
      const data = await Auth.currentAuthenticatedUser();
      const { attributes } = data;
      const groupsFromCognito =
        data?.signInUserSession?.accessToken?.payload['cognito:groups'] || [];
      const groups = groupsFromCognito.filter((g) => validGroups.includes(g));
      let [, shopId] =
        (attributes['custom:shopId'] &&
          attributes['custom:shopId'].split('--')) ||
        []; // TODO will be removed
      if (!shopId) {
        shopId = attributes['custom:shopId'];
      }
      const shopLabel = attributes['custom:shopLabel'];
      // user data from cognito user pool
      const userData = {
        firstName: attributes.given_name || attributes['custom:firstName'],
        lastName: attributes.family_name || attributes['custom:lastName'],
        phoneNumber:
          attributes['custom:phoneNumber'] || attributes['phone_number'],
        defaultAddress: attributes['custom:defaultAddress'],
        shopId: shopId,
        shopLabel: shopLabel,
        title: attributes['custom:title'],
        email: attributes['email'],
        sub: attributes['sub'],
        username: data.username,
        groups
      };
      dispatch({
        type: 'FETCH_USER_DATA_SUCCESS',
        payload: { user: userData }
      });
      if (data) {
        let userData = await loadUserDetail(data.attributes.sub);
        if (!userData.userId) {
          const input = {
            id: userData.id,
            userId: data.attributes.sub || '',
            groups: groups
          };
          try {
            userData = await API.graphql(
              graphqlOperation(updateUser, { input })
            );
          } catch (e) {
            console.error('Error', e);
          }
        }
        dispatch({
          type: 'FETCH_USER_DATA_SUCCESS',
          payload: { user: userData }
        });
      }
    } catch (error) {
      dispatch({ type: 'FETCH_USER_DATA_FAILURE' });
    }
  };

  const onAuthEvent = (payload) => {
    switch (payload.event) {
      case 'signIn':
        setTriggerFetch(true);
        if (payload.data.username.includes('Google')) {
          const redirectURL = getRedirectURL();
          window.location.replace(redirectURL);
        }
        console.log('signed in');
        break;
      case 'customOAuthState':
        if (payload.data) {
          const data = JSON.parse(payload.data);
          if (data.rURL) {
            window.location.replace(data.rURL);
          }
        }
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    Hub.listen('auth', (data) => {
      const { payload } = data;
      onAuthEvent(payload);
    });
    fetchUserData();
    return () => {
      Hub.remove('auth');
    };
  }, [triggerFetch]);

  const _updateUser = async (data) => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, data);
    fetchUserData();
  };

  const _refreshUser = () => {
    fetchUserData();
  };

  const handleSignOut = async () => {
    try {
      updateUserLastSeenThrottled.cancel();
      await updateUserLastSeenInstant('offline');
    } catch (error) {
      console.error('Error updating user status, ', error);
    }
    try {
      await Auth.signOut();
      console.log('signed out');
      setTriggerFetch(false);
      dispatch({ type: 'RESET_USER_DATA' });
    } catch (error) {
      console.error('Error signing out user, ', error);
    }
  };

  const value = {
    state,
    _updateUser,
    handleSignOut,
    _refreshUser,
    _updateUserLastSeen: updateUserLastSeenThrottled
  };

  return (
    <AmplifyAuthContext.Provider value={value}>
      {children}
    </AmplifyAuthContext.Provider>
  );
}

function useAmplifyAuth() {
  const context = React.useContext(AmplifyAuthContext);
  if (context === undefined) {
    throw new Error('useCount must be used within a AmplifyAuthProvider');
  }
  return context;
}

export { AmplifyAuthProvider, useAmplifyAuth };
