const serviceItem = `{
  id
  name
  image
  codeName
  description
  serviceID
  _deleted
  _version
}`;

export const listServices = /* GraphQL */ `
  query ListServices {
    listServices(limit: 5000) {
      items {
        items {
          nextToken
          startedAt
          items ${serviceItem}
        }
        id
        serviceID
        name
        description
        orderIndex
        type
        _deleted
        _version
      }
    }
  }
`;
export const getItemByService = /* GraphQL */ `
  query GetItemByService(
    $serviceID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getItemByService(
      serviceID: $serviceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${serviceItem}
      nextToken
      startedAt
    }
  }
`;
