import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { useAdTemplateStyles } from '../templateStyles';
import { Image } from 'components/organisms';
import TemplateFooter from './TemplateFooter';
import clsx from 'clsx';

const Template1 = ({
  content = {
    image: '',
    description: ''
  },
  shopName = '',
  cardStyles = ''
}) => {
  const classes = useAdTemplateStyles();

  return (
    <Grid
      container
      justify="space-between"
      component={Paper}
      className={clsx(cardStyles, classes.shopListAdsRootStyles)}>
      <Grid container item xs={12} className={classes.shopListAdsImage}>
        <Grid item xs={12}>
          <Image
            docKey={content?.image || ''}
            loadFromStorage={content?.image?.split('/').at(0) === 'images'}
            width="100%"
            className={classes.shopListAdsImage}
            alt="Advertisement image"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            align="justify"
            style={{
              color: '#454545 ',
              padding: '0.4rem',
              lineHeight: '1.2',
              fontSize: '13px'
            }}>
            {content?.description || ''}
          </Typography>
        </Grid>
      </Grid>
      <TemplateFooter shopName={shopName} />
    </Grid>
  );
};

export default Template1;
