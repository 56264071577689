import React, { useState, useEffect } from 'react';
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import moment from 'moment';

const RedirectsTable = ({ redirects = [] }) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    redirects.length === 1 ? setSelectedRowIndex(0) : setSelectedRowIndex(null);
  }, [redirects]);

  const handleRowsPerPageChange = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handlePageChange = (_event, page) => {
    setPage(page);
  };

  return (
    <Grid container>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Date</TableCell>
            <TableCell align="right">No of Redirects</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {redirects
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, index) => (
              <React.Fragment key={`insight-redirect-dates-items-${index}`}>
                <TableRow>
                  <TableCell style={{ width: '2rem' }}>
                    <IconButton
                      aria-label="row-expand"
                      size="small"
                      onClick={() =>
                        selectedRowIndex === index
                          ? setSelectedRowIndex(null)
                          : setSelectedRowIndex(index)
                      }>
                      {selectedRowIndex === index ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell align="left">
                    {moment(item.date).format('MMMM Do YYYY')}
                  </TableCell>
                  <TableCell align="right">{item.redirects.length}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={3}>
                    <Collapse
                      in={selectedRowIndex === index}
                      timeout="auto"
                      unmountOnExit>
                      <Box margin={1} style={{ gap: '0.5rem' }}>
                        <Typography variant="h6" gutterBottom component="div">
                          Users
                        </Typography>
                        <Table size="small" aria-label="users">
                          <TableBody>
                            {item.redirects.map(
                              (redirectItem, redirectIndex) => (
                                <TableRow
                                  key={`insight-redirect-dates-items-${redirectIndex}`}>
                                  <TableCell>
                                    {redirectItem.event.identity?.claims
                                      ?.email || 'Anonymous User'}
                                  </TableCell>
                                  <TableCell align="right">
                                    {redirectItem?.createdAt
                                      ? moment(redirectItem.createdAt).format(
                                          'h:mm:ss a'
                                        )
                                      : ''}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </Table>
      <Grid container justify="flex-end">
        {redirects.length > 5 ? (
          <TablePagination
            component="div"
            count={redirects.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default RedirectsTable;
