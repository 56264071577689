import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
  useState
} from 'react';
import { useLoader } from '../../layouts/loaderContext';
import { getProductsByShop, saveProducts } from './productAPIs';
import { useSnackbar } from 'notistack';
import { useServices } from 'views/Services/serviceContext';
import { getCustomShopProductName } from 'common/utilFunctions';

const ProductsContext = createContext({});

function productsReducer(state, action) {
  switch (action.type) {
    case 'updateData': {
      return action.payload || {};
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const ProductsProvider = (props) => {
  let currShop = null;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [products, dispatch] = useReducer(productsReducer, {});
  const { services } = useServices();
  const [selectedShopID, setSelectedShopID] = useState(null);
  const { setLoading } = useLoader();

  const asyncDispatch = useCallback(
    async (action) => {
      switch (action.type) {
        case 'getProductsByShopId': {
          setLoading(true);
          let resProducts = {};
          const { payload } = action;
          const { id: shopID } = payload;
          if (currShop !== shopID) {
            currShop = shopID;
            setSelectedShopID(shopID);
            const items = await getProductsByShop(shopID);
            setLoading(false);
            items.map((item) => {
              if (!item._deleted) {
                const isValid =
                  item.price && !(!item.unit || item.unit === 'na');
                const productKey =
                  item.itemID ||
                  getCustomShopProductName(item.name, item.serviceID);
                resProducts[productKey] = {
                  ...item,
                  enabled: true,
                  modify: false,
                  isValid
                };
              }
            });
            dispatch({
              type: 'updateData',
              payload: resProducts
            });
            return resProducts;
          }
          break;
        }
        case 'getProductsWithService': {
          setLoading(true);
          const {
            payload: {
              callback = () => {},
              order: { orderList = [] }
            }
          } = action;
          let serviceWithProduct = [];

          services.forEach((service) => {
            const {
              items: { items = [], ...restItems },
              id,
              ...rest
            } = service;
            const newItems = [
              ...Object.values(products)
                .filter((product) => product.serviceID === id)
                .map((product) => ({
                  ...product,
                  product,
                  productID: product.id
                }))
            ];

            newItems.length > 0 &&
              serviceWithProduct.push({
                ...rest,
                id,
                items: {
                  ...restItems,
                  // items sorted by selected in the order
                  items: [
                    ...newItems.filter((item) =>
                      orderList.some(
                        (orderItem) => orderItem.productID === item.productID
                      )
                    ),
                    ...newItems.filter(
                      (item) =>
                        !orderList.some(
                          (orderItem) => orderItem.productID === item.productID
                        )
                    )
                  ]
                }
              });
          });

          // sort services based on if they are included in the order or not
          serviceWithProduct.sort((a, b) => {
            const orderIncludesServiceA = a.items.items.some((item) =>
              orderList.some(
                (orderItem) => orderItem.productID === item.productID
              )
            );
            const orderIncludesServiceB = b.items.items.some((item) =>
              orderList.some(
                (orderItem) => orderItem.productID === item.productID
              )
            );
            return orderIncludesServiceA === orderIncludesServiceB
              ? 0
              : orderIncludesServiceB
              ? 1
              : -1;
          });

          // check if any product in order list is deleted
          const _deleted = orderList.filter(
            (item) =>
              !Object.values(products).some(
                (_prod) => _prod.id === item?.productID
              )
          );
          if (_deleted.length > 0)
            serviceWithProduct.push({
              id: '_deleted_',
              name: '_deleted_',
              items: {
                items: _deleted.map((item, _i) => ({
                  name: '',
                  description: '',
                  image: '',
                  id: `_deleted-products-items-${item?.productID || ''}-${_i}`,
                  serviceID: `_deleted-products`,
                  product: item,
                  productID: item?.productID || ''
                }))
              }
            });

          setLoading(false);
          callback(serviceWithProduct);
          break;
        }
        case 'saveProducts': {
          setLoading(true);
          const sBar = enqueueSnackbar('Saving Products ....', {
            variant: 'info',
            persist: true
          });
          try {
            const data = await saveProducts(products, selectedShopID);
            dispatch({
              type: 'updateData',
              payload: data
            });
            enqueueSnackbar('Products saved successfully', {
              variant: 'success',
              preventDuplicate: true
            });
          } catch (error) {
            enqueueSnackbar('Something went wrong', {
              variant: 'error',
              preventDuplicate: true
            });
            console.log('error', error);
          } finally {
            closeSnackbar(sBar);
            setLoading(false);
          }
          break;
        }
        case 'addProduct': {
          setLoading(true);
          try {
            const newProduct = {
              ...action.payload,
              enabled: true,
              modify: false,
              isValid: false
            };
            dispatch({
              type: 'updateData',
              payload: {
                [getCustomShopProductName(
                  action.payload.name,
                  action.payload.serviceID
                )]: newProduct,
                ...products
              }
            });
            enqueueSnackbar('Product added...', {
              variant: 'success',
              preventDuplicate: true,
              autoHideDuration: 1500
            });
          } catch (error) {
            enqueueSnackbar('Something went wrong', {
              variant: 'error',
              preventDuplicate: true
            });
            console.log('error', error);
          } finally {
            setLoading(false);
          }
          break;
        }
        case 'resetProducts': {
          setLoading(true);
          try {
            dispatch({
              type: 'updateData',
              payload: action.payload
            });
          } catch (error) {
            enqueueSnackbar('Something went wrong', {
              variant: 'error',
              preventDuplicate: true
            });
            console.log('error', error);
          } finally {
            setLoading(false);
          }
          break;
        }
        default:
          dispatch(action);
      }
    },
    [products, services]
  );
  const value = {
    products,
    dispatch: asyncDispatch,
    selectedShopID
  };

  return (
    <ProductsContext.Provider value={value}>
      {props.children}
    </ProductsContext.Provider>
  );
};

function useProducts() {
  const context = useContext(ProductsContext);
  if (context === undefined || !Object.keys(context).length) {
    throw new Error('useProducts must be used within a ProductContext');
  }
  return context;
}

export { ProductsProvider, useProducts };
