import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import AboutUsContent from './components/AboutUsContent';
import CustomerBenefits from './components/CustomerBenefits';
import ShopOwnerBenefits from './components/ShopOwnerBenefits';
import AboutUsConclusion from './components/AboutUsConclusion';

const useStyles = makeStyles({
  root: {
    maxWidth: '90%',
    margin: '1rem auto'
  }
});

const AboutUs = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <AboutUsContent />
      <CustomerBenefits />
      <ShopOwnerBenefits />
      <AboutUsConclusion />
    </Grid>
  );
};

export default AboutUs;
