import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { getPaymentByOrderId } from 'graphql/queries';
import {
  Grid,
  Typography,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useShops } from 'context/shop/shopsContext';
import ItemizeRow from './ItemizeRow';
import { PaymentStatusTag, StickyTableCell } from 'components/molecules/';
import {
  showPrice,
  getOrderTotal,
  unitFormatter,
  getCollectionAndDeliveryFeeText,
  getCollectionAndDeliveryFee,
  showPartialPaymentRecord
} from 'common/utilFunctions';
import moment from 'moment';
import {
  getDistanceBetweenPostcodesInMiles,
  shouldApplyCollectionOrDeliveryFee
} from 'common/utilFunctions';

const useStyles = makeStyles((theme) => ({
  tableContainer: {},
  table: {
    '& td, th': {
      border: '1px solid #ccc',
      borderCollapse: 'separate'
    }
  },
  nameColumn: {
    paddingLeft: '3rem',
    width: '9rem',
    backgroundColor: '#C2E7FF',
    left: 0
  },
  column: {
    maxWidth: 100
  },
  detailsContainer: {
    padding: '2rem 0',
    gap: '1rem 0',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse'
    }
  },
  paymentStatusTag: {
    padding: '1rem'
  }
}));

const ItemizeTable = ({ value, order, setOrder }) => {
  const classes = useStyles();
  const { selectedShop, dispatch } = useShops();
  const items = value?.items?.items || [];
  const [prevTotal, setPrevTotal] = useState(0);
  const [partiallyPaidRecord, setPartiallyPaidRecord] = useState([]);
  const [addressDistance, setAddressDistance] = useState({
    collection: 0,
    delivery: 0
  });

  useEffect(() => {
    let mounted = true;
    mounted && setPrevTotal(order?.total);
    mounted && getPartialPaymentsRecord(order?.id);

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    const collectionAddress = JSON.parse(order?.collection?.address);
    const deliveryAddress = JSON.parse(order?.delivery?.address);

    if (selectedShop && collectionAddress?.postcode) {
      getDistanceBetweenPostcodesInMiles(
        selectedShop.location,
        collectionAddress.postcode
      ).then((distance) => {
        setAddressDistance((prev) => ({
          ...prev,
          collection: distance
        }));
      });
    }
    if (selectedShop && deliveryAddress?.postcode) {
      getDistanceBetweenPostcodesInMiles(
        selectedShop.location,
        deliveryAddress.postcode
      ).then((distance) => {
        setAddressDistance((prev) => ({
          ...prev,
          delivery: distance
        }));
      });
    }
  }, [order.collection]);

  useEffect(() => {
    let mounted = true;

    const subTotal = getOrderTotal(order);
    const tax =
      process.env.REACT_APP_VAT === 'enabled'
        ? (subTotal - (order?.discount || 0)) * 0.2
        : 0;
    const minPriceForFree = order?.currentShopFees?.minPriceForFree || 0;
    let collectionAndDeliveryFee =
      order?.collection?.type === 'asap'
        ? order?.currentShopFees?.AsapDeliveryFee
        : 0;

    if (!!order.collection.address) {
      if (
        shouldApplyCollectionOrDeliveryFee(
          subTotal,
          minPriceForFree,
          addressDistance?.collection,
          selectedShop?.distanceRangeForFree
        )
      ) {
        collectionAndDeliveryFee += order?.currentShopFees?.standardDeliveryFee;
      }
    }
    if (!!order.delivery.address) {
      if (
        shouldApplyCollectionOrDeliveryFee(
          subTotal,
          minPriceForFree,
          addressDistance?.delivery,
          selectedShop?.distanceRangeForFree
        )
      ) {
        collectionAndDeliveryFee += order?.currentShopFees?.standardDeliveryFee;
      }
    }

    mounted &&
      setOrder({
        ...order,
        tax: Math.round(tax * 100) / 100,
        collectionAndDeliveryFee:
          Math.round(collectionAndDeliveryFee * 100) / 100,
        total:
          Math.round(
            (subTotal +
              tax +
              collectionAndDeliveryFee -
              (order.discount || 0)) *
              100
          ) / 100
      });

    return () => {
      mounted = false;
    };
  }, [order.orderList, addressDistance]);

  const getPartialPaymentsRecord = async (id) => {
    if (id) {
      const response = await API.graphql(
        graphqlOperation(getPaymentByOrderId, {
          orderID: id
        })
      );
      const data = await response.data.getPaymentByOrderId.items;
      setPartiallyPaidRecord(
        data
          .map((el) => ({
            amount: el.amount,
            date: el.createdAt,
            lastChanged: el._lastChangedAt
          }))
          .sort((a, b) => a.lastChanged - b.lastChanged)
      );
    }
  };

  const handleNumberChange = (event) => {
    const replaceRegex = /[^0-9.]/g;
    event.target.value = event.target.value.replace(replaceRegex, '');
  };

  let localTotal = order?.total || 0;

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} aria-label="itemize-items-table">
          <TableHead>
            <TableRow style={{ background: '#C2E7FFB2' }}>
              <StickyTableCell className={classes.nameColumn}>
                NAME
              </StickyTableCell>
              <TableCell className={classes.column} align="center">
                ITEM DESC.
              </TableCell>
              <TableCell className={classes.column} align="center">
                QUANTITY
              </TableCell>
              <TableCell className={classes.column} align="center">
                UNITS
              </TableCell>
              <TableCell className={classes.column} align="center">
                PRICE
              </TableCell>
              <TableCell className={classes.column} align="center">
                DISCOUNT
              </TableCell>
              <TableCell className={classes.column} align="center">
                TOTAL
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row, i) => (
              <ItemizeRow
                key={`product_${i}`}
                row={row}
                order={order}
                setOrder={setOrder}
              />
            ))}
            <TableRow style={{ background: '#C2E7FFB2' }}>
              <TableCell
                colSpan={8}
                align="right"
                style={{ paddingRight: '2rem' }}>
                SUB TOTAL &nbsp; &nbsp; {showPrice(getOrderTotal(order))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container className={classes.detailsContainer}>
        <Grid
          container
          item
          xs={12}
          sm={4}
          md={8}
          justify="center"
          alignItems="center"
          className={classes.paymentStatusTag}>
          <PaymentStatusTag order={order} prevTotal={prevTotal} />
        </Grid>
        <Grid container item xs={12} sm={8} md={4} spacing={2}>
          {/* subtotal */}
          <Grid item xs={8}>
            <Typography>Sub Total</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align="right">
              {showPrice(getOrderTotal(order))}
            </Typography>
          </Grid>

          {/* discount */}
          <Grid item xs={7}>
            <Typography>Discount on subtotal</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography align="right">
              - {showPrice(order.discount || 0)}
            </Typography>
          </Grid>

          {/* tax */}
          {process.env.REACT_APP_VAT === 'enabled' ? (
            <>
              <Grid item xs={8}>
                <Typography>VAT (20%)</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right">
                  {showPrice(order.tax || 0)}
                </Typography>
              </Grid>
            </>
          ) : null}

          {/* asap fee */}
          {order.collection.type === 'asap' ? (
            <>
              <Grid item xs={8}>
                <Typography>Asap Fee</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right">
                  {showPrice(order?.shop?.AsapDeliveryFee || 0)}
                </Typography>
              </Grid>
            </>
          ) : null}

          {/* collection & delivery fee */}
          {!!order?.collection?.address || !!order?.delivery?.address ? (
            <>
              <Grid item xs={8}>
                <Typography>
                  {getCollectionAndDeliveryFeeText(order)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right">
                  {getCollectionAndDeliveryFee(order)}
                </Typography>
              </Grid>
            </>
          ) : null}

          <Grid
            item
            container
            justify="flex-end"
            style={{ backgroundColor: '#C2E7FFB2' }}>
            <Typography variant="h6">
              TOTAL &nbsp; &nbsp; {showPrice(order?.total)}
            </Typography>
          </Grid>
          {(order?.paymentStatus === 'partiallyPaid' ||
            (order?.paymentStatus === 'paid' && order?.total > prevTotal)) && (
            <Grid item container justify="flex-end">
              <Typography variant="body1">
                Balance &nbsp; &nbsp;
                {showPrice(order?.total - (order?.paidAmount || 0))}
              </Typography>
            </Grid>
          )}
          {partiallyPaidRecord?.length > 0 ? (
            showPartialPaymentRecord(partiallyPaidRecord, localTotal) ? (
              partiallyPaidRecord.map((el, i) => {
                localTotal = localTotal - el.amount;
                return (
                  <Grid
                    container
                    item
                    justify="space-between"
                    key={`partial-payment-records-in-item-and-pricing-items-${i}`}>
                    <Grid container>
                      <Grid item xs={8}>
                        <Typography>
                          Partial {unitFormatter(order.paymentMethod)} Payment
                          on :&nbsp;
                          {moment(el.date).format('MMM Do, YYYY')}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body1" align="right">
                          {showPrice(el.amount)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={8}>
                        <Typography>
                          {localTotal <= 0
                            ? 'Total Amount Due:'
                            : 'Balance Due:'}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body1" align="right">
                          {showPrice(localTotal)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Grid
                container
                item
                justify="space-between"
                key={`partial-payment-records-in-item-and-pricing-items`}>
                <Typography>
                  {unitFormatter(order.paymentMethod)} Payment on :&nbsp;
                  {moment(partiallyPaidRecord[0]?.date).format('MMM Do, YYYY')}
                </Typography>
                <Typography variant="body1" align="right">
                  {showPrice(partiallyPaidRecord[0]?.amount)}
                </Typography>
              </Grid>
            )
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ItemizeTable;
