import { Toolbar, Typography } from '@material-ui/core';
import React from 'react';

const Header = ({ content }) => {
  return (
    <>
      <Toolbar style={{ paddingLeft: '0px' }}>
        <Typography variant="h3">{content}</Typography>
      </Toolbar>
    </>
  );
};

export default Header;
