import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Typography,
  Card,
  Tooltip,
  useMediaQuery,
  CardContent,
  Button,
  IconButton,
  Link as MUILink,
  Box
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { capitalizeStr } from 'common/utilFunctions';
import { googleFormLinks } from 'common/constants';
import { Link } from 'react-router-dom';
import ShopCardContent from './ShopCardContent';
import { ShopWrapper, ShopListWrapper } from '../HOCs';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    height: 'fit-content',
    boxShadow: 'none',
    border: 'none',
    position: 'relative',
    '&:hover #ribbon': {
      display: 'none'
    },
    maxWidth: 'calc(100vw * 0.9)',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '13.875rem'
    }
  },
  cardAnimation: {
    transition: 'transform .2s',
    '&:hover': {
      transform: 'scale(1.2)',
      zIndex: 100
    }
  },
  viewBtn: {
    fontSize: '12px',
    color: '#030045',
    fontWeight: '700'
  },
  scrollBtn: {
    maxHeight: '26px',
    maxWidth: '26px',
    boxShadow: '0px 8px 18px rgb(45 69 157 / 16%)',
    margin: '0 5px',
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.white,
      color: theme.palette.primary.main
    }
  },
  listContainer: {
    margin: '1rem 0'
  },
  serviceName: {
    margin: '1rem 0 0.5rem'
  },
  cardContent: {
    padding: '0 !important',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      height: '240px'
    }
  },
  list: {
    transform: 'translateZ(0)',
    gap: '1.25rem',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  shopWrapper: {
    [theme.breakpoints.up('lg')]: {
      padding: '0.625rem 0'
    }
  },
  shopGrayWrapper: {
    filter: 'grayscale(100%)'
  },
  onHoldWrapper: {
    left: '0px',
    position: 'absolute',
    top: '0px',
    height: '100px',
    width: '100px',
    overflow: 'hidden',
    zIndex: 1,
    '&:hover': {
      display: 'none'
    }
  },
  onHoldRibbon: {
    left: '-55px',
    position: 'absolute',
    top: '37px',
    height: '16px',
    width: '200px',
    transform: 'rotate(-45deg)',
    textAlign: 'center',
    backgroundColor: theme.palette.warning.light,
    color: '#000',
    fontWeight: 600,
    fontSize: '11px'
  },
  registerLink: {
    color: 'black'
  },
  shopRedirect: {
    fontSize: 11,
    fontWeight: 600,
    color: '#000',
    backgroundColor: '#edd242',
    padding: '0.3rem',
    borderRadius: '0 0 4px 4px'
  }
}));

function getSortedShops(shops, favouriteShopsData = []) {
  const shopsSortedByRange = shops.sort((a, b) => a.range - b.range); //sorting the shops by range

  const favouriteShopIds = favouriteShopsData.map((shop) => shop.shopID);

  const favouriteShops = shopsSortedByRange.filter((sortedShop) =>
    favouriteShopIds.includes(sortedShop.id)
  );

  const onlineShops = shopsSortedByRange.filter(
    (item) =>
      item.status === 'active' &&
      item.isLive &&
      !favouriteShopIds.includes(item.id)
  );
  const offlineShops = shopsSortedByRange.filter(
    (item) => item.status === 'onhold' && !favouriteShopIds.includes(item.id)
  );
  const closeShops = shopsSortedByRange.filter(
    (item) =>
      item.status === 'active' &&
      !item.isLive &&
      !favouriteShopIds.includes(item.id)
  );

  return [...favouriteShops, ...onlineShops, ...offlineShops, ...closeShops];
}

const NUMBER_OF_SHOPS = 10;

const ShopsCarousel = ({
  service = { name: '', id: '' },
  favouriteShops = [],
  className = '',
  onSearch = false
}) => {
  const classes = useStyles();
  const [showAll, setShowAll] = useState(true);
  const theme = useTheme();
  const scrollListDiv = useRef(null);
  const scrollItem = useRef(null);
  const smDown = useMediaQuery(theme.breakpoints.down('xs'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const [favouriteShopsData, setFavouriteShopsData] = useState([]);
  const [showNumberOfShops, setShowNumberOfShops] = useState(NUMBER_OF_SHOPS);
  const [lgUp, lgDown] = [
    useMediaQuery(theme.breakpoints.up('lg')),
    useMediaQuery(theme.breakpoints.down('lg'))
  ];
  const lg = lgUp && lgDown;
  const xl = useMediaQuery(theme.breakpoints.up('xl'));
  const showShowMoreBtn = false;
  // show more button condition based on screen sizes
  //   (xl && service.shops?.length > 6) || (lg && service.shops?.length > 4);

  const slicingEndIndex = !showAll ? (xl ? 6 : lg ? 4 : undefined) : undefined;

  const handleSwitchShowAll = () => setShowAll(!showAll);

  const scrollDiv = (isLeft) => {
    const scrollWidth =
      (scrollItem.current?.offsetWidth || 500) * (isLeft ? 1 : -1);
    scrollListDiv.current.scrollBy({
      left: scrollWidth,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    setFavouriteShopsData(favouriteShops);
  }, []);

  const showMoreShopsHandler = () => {
    setShowNumberOfShops(showNumberOfShops + NUMBER_OF_SHOPS);
  };

  const showMoreShopButton =
    service.shops.length > NUMBER_OF_SHOPS &&
    showNumberOfShops < service.shops.length;

  const sliceLoadShopEndIndex = smDown ? showNumberOfShops : undefined;

  return (
    <Grid container className={clsx(className, classes.root)}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item lg={8} xs={8} className={classes.serviceName}>
          <Typography variant="h3" color="secondary">
            {capitalizeStr(service.name)}
          </Typography>
        </Grid>
        {/* FIXME LEFT RIGHT ARROW BUTTON FOR SMALL SCREEN */}
        {/* {mdDown && service.shops?.length > 4 && (
          <Grid item xs={4} container justify="flex-end" alignItems="center">
            <IconButton
              onClick={() => scrollDiv(false)}
              className={classes.scrollBtn}>
              <ArrowBackIcon style={{ fontSize: '11px' }} />
            </IconButton>
            <IconButton
              onClick={() => scrollDiv(true)}
              className={classes.scrollBtn}>
              <ArrowForwardIcon style={{ fontSize: '11px' }} />
            </IconButton>
          </Grid>
        )} */}

        {showShowMoreBtn ? (
          <Grid
            item
            container
            xs={12}
            lg={4}
            justify="flex-end"
            alignItems="center">
            <Button
              className={classes.viewBtn}
              onClick={handleSwitchShowAll}
              endIcon={showAll ? <ExpandLess /> : <ExpandMore />}>
              Show {showAll ? 'Less' : 'More'}
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <Grid
        container
        justify={smDown ? 'center' : 'flex-start'}
        alignItems="center"
        className={classes.listContainer}>
        {service.shops.length > 0 ? (
          <>
            <ShopListWrapper
              ref={scrollListDiv}
              lg={lgUp}
              className={clsx(classes.list, 'hide-scrollbar')}>
              {getSortedShops(service.shops, favouriteShopsData)
                // .slice(0, slicingEndIndex)
                .slice(0, sliceLoadShopEndIndex)
                .map((item, index) => {
                  const isClosedToday = !item?.workHrs?.some(
                    (item) => item.openDay === new Date().getDay()
                  );
                  const disableShop = item.status === 'active' && !item.isLive;
                  const redirectLink = disableShop
                    ? '#'
                    : !!(item.name && item.shopID)
                    ? `/shop/${item.shopID}/${item.name
                        .toLowerCase()
                        .split(' ')
                        .join('-')}`
                    : '#';
                  const showInGrayscale = disableShop;
                  return (
                    <ShopWrapper
                      ref={scrollItem}
                      key={`popular-shop-search-page-items-${index}`}
                      className={classes.shopWrapper}
                      lg={lgUp}>
                      <Grid container justify="center">
                        <Card
                          className={clsx(
                            classes.card,
                            !disableShop && lgUp && classes.cardAnimation
                          )}>
                          {isClosedToday ? (
                            <Box className={classes.onHoldWrapper} id="ribbon">
                              <Box className={classes.onHoldRibbon}>
                                CLOSED FOR TODAY
                              </Box>
                            </Box>
                          ) : null}
                          <Link
                            to={redirectLink}
                            className={
                              showInGrayscale ? classes.shopGrayWrapper : ''
                            }>
                            <Tooltip
                              title={
                                isClosedToday
                                  ? 'This Shop is Closed for Today'
                                  : disableShop
                                  ? 'Temporarily Closed'
                                  : ''
                              }
                              placement="top"
                              arrow>
                              <CardContent className={classes.cardContent}>
                                <ShopCardContent
                                  item={item}
                                  serviceID={service.id}
                                  disableShop={disableShop}
                                />
                              </CardContent>
                            </Tooltip>
                          </Link>
                          {item.websiteLink?.trim() !== '' ? (
                            <a
                              id="RouterNavLink"
                              href={item.websiteLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) => e.stopPropagation()}>
                              <Typography
                                variant="body1"
                                className={classes.shopRedirect}>
                                {item.websiteLink}
                              </Typography>
                            </a>
                          ) : null}
                        </Card>
                      </Grid>
                    </ShopWrapper>
                  );
                })}
            </ShopListWrapper>
            {smDown && showMoreShopButton ? (
              <Grid
                container
                item
                xs={12}
                justify="center"
                style={{ marginTop: '1rem' }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  endIcon={<ExpandMore />}
                  onClick={showMoreShopsHandler}>
                  Show more
                </Button>
              </Grid>
            ) : null}
          </>
        ) : onSearch ? (
          <Typography align="center" variant="h4">
            Shop not found with the searched name. Please refine your search to
            find your shop.
          </Typography>
        ) : (
          <MUILink
            className={classes.registerLink}
            href={googleFormLinks.requestToOnboardShopFromCustomer}
            variant="h4"
            target="_blank"
            rel="noreferrer"
            align="center">
            No shops available. Click here to register your interest so that we
            can be there shortly.
          </MUILink>
        )}
      </Grid>
    </Grid>
  );
};

export default ShopsCarousel;
