import React from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core';
import WallpaperOutlinedIcon from '@material-ui/icons/WallpaperOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

const UploadedFilesListItem = ({ fileName = '', onClose }) => {
  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid
        container
        alignItems="center"
        item
        xs={11}
        style={{ gap: '0.625rem' }}>
        <WallpaperOutlinedIcon fontSize="large" />
        <Typography>{fileName}</Typography>
      </Grid>
      <Grid container justify="flex-end" item xs={1}>
        <IconButton style={{ padding: 0 }} onClick={onClose}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default UploadedFilesListItem;
