import React from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
  Link as MUILink
} from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import MailIcon from '@material-ui/icons/Mail';
import { Link } from 'react-router-dom';
import { launderItBlogLink, googleFormLinks } from 'common/constants';

const useStyles = makeStyles({
  listHeading: {
    fontSize: 16,
    fontWeight: 800,
    color: '#fff',
    marginTop: '1.5rem',
    marginBottom: '0.5rem'
  },
  listItem: {
    padding: 0
  },
  contectUsListContent: {
    fontWeight: 800,
    fontSize: 13,
    color: '#fff'
  },
  listTextContent: {
    fontSize: 14,
    color: '#C4C4C4',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  listTextTopService: {
    textTransform: 'capitalize',
    fontSize: 14,
    color: '#C4C4C4'
  },
  icon: {
    color: '#46c6ce',
    height: 14,
    width: 14,
    marginRight: 10
  }
});

const HomeSubFooter = () => {
  const classes = useStyles();

  return (
    <Grid container item justify="space-between" spacing={1}>
      <Grid item md={12} sm={4}>
        <List className={classes.listItem}>
          <ListItem className={classes.listItem}>
            <ListItemText
              style={{ marginTop: 0 }}
              primary={
                <Typography variant="h6" className={classes.listHeading}>
                  Our Company
                </Typography>
              }
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={
                <Typography variant="h6">
                  <Link
                    to="/about-us"
                    onClick={() => window.scrollTo(0, 0)}
                    className={classes.listTextContent}>
                    About us
                  </Link>
                </Typography>
              }
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={
                <Typography variant="h6">
                  <Link
                    to={{ pathname: launderItBlogLink }}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.listTextContent}>
                    Our blogs
                  </Link>
                </Typography>
              }
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={
                <Typography variant="h6">
                  <MUILink
                    href="/home#testimonial-section"
                    className={classes.listTextContent}>
                    Customer reviews
                  </MUILink>
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Grid>

      <Grid item md={12} sm={4}>
        <List className={classes.listItem}>
          <ListItem className={classes.listItem}>
            <ListItemText
              style={{ marginTop: 0 }}
              primary={
                <Typography variant="h6" className={classes.listHeading}>
                  Work With Us
                </Typography>
              }
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={
                <Typography variant="h6">
                  <a
                    href={googleFormLinks.joinUs}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.listTextContent}>
                    Join us
                  </a>
                </Typography>
              }
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={
                <Typography variant="h6">
                  <a
                    href="/business-with-us"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.listTextContent}>
                    Business with us
                  </a>
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Grid>

      <Grid item md={12} sm={4}>
        <List className={classes.listItem}>
          <ListItem className={classes.listItem}>
            <ListItemText
              style={{ marginTop: 0 }}
              primary={
                <Typography variant="h6" className={classes.listHeading}>
                  Contact Us
                </Typography>
              }
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <CallIcon className={classes.icon} />
            <ListItemText
              primary={
                <Typography variant="h6">
                  <a
                    href="tel:07771038880"
                    className={classes.contectUsListContent}>
                    07771038880
                  </a>
                </Typography>
              }
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <MailIcon className={classes.icon} />
            <ListItemText
              primary={
                <Typography variant="h6">
                  <a
                    href="mailto:info@launder-it.co.uk"
                    className={classes.contectUsListContent}>
                    info@launder-it.co.uk
                  </a>
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export default HomeSubFooter;
