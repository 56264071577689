import React from 'react';
import {
  Document,
  Page,
  View,
  Image,
  StyleSheet,
  Text,
  Font
} from '@react-pdf/renderer';
import MainLogo from 'assets/png/Launder-it-logo.png';
import ScanMe from 'assets/png/scan_me.png';
import DancingScriptFont from 'assets/font/DancingScript-Bold.ttf';
import InterFont from 'assets/font/Inter-SemiBold.ttf';
import KumarOne from 'assets/font/KumarOne-Regular.ttf';

// register fonts
Font.register({
  family: 'DancingScript',
  src: DancingScriptFont
});
Font.register({
  family: 'InterFont',
  src: InterFont
});
Font.register({
  family: 'KumarOne',
  src: KumarOne
});

// Create styles
const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  containerMargin64: {
    marginTop: 64
  },
  containerMargin48: {
    marginTop: 48
  },
  logo: {
    width: 150
  },
  shopName: {
    fontFamily: 'InterFont',
    textAlign: 'center',
    fontSize: 24,
    backgroundColor: '#194376',
    color: '#fff',
    padding: '14px 60px',
    borderRadius: 8,
    margin: '12px 0',
    maxWidth: '85%'
  },
  address: {
    fontFamily: 'InterFont',
    textAlign: 'center',
    fontSize: 11
  },
  thanksText: {
    fontFamily: 'DancingScript',
    textAlign: 'center',
    fontSize: 24,
    margin: '32px 0'
  },
  tagLineByAdmin: {
    fontFamily: 'KumarOne',
    textAlign: 'center',
    fontSize: 18,
    margin: '0 32px 32px'
  },
  tagLine: {
    fontFamily: 'InterFont',
    textAlign: 'center',
    fontSize: 11,
    letterSpacing: 1
  },
  qrCode: {
    width: 256,
    margin: '12px 0'
  },
  scanMeBlock: {
    width: 150,
    marginBottom: 16
  }
});

const ShopQRCodePDF = ({ shop, qrCodeURL, adminMessage = '' }) => {
  return (
    <Document>
      <Page size="A4">
        <View
          style={[
            styles.container,
            !adminMessage ? styles.containerMargin64 : styles.containerMargin48
          ]}>
          <Image src={MainLogo} style={styles.logo} alt="launder-it-logo" />

          <Text style={styles.shopName}>{shop.name}</Text>

          <Text style={styles.address}>
            {`${shop.address}, ${shop.town}, ${shop.postcode}`}
          </Text>

          <Text style={styles.thanksText}>Thanks for visiting!</Text>

          {!!adminMessage ? (
            <Text style={styles.tagLineByAdmin}>{adminMessage}</Text>
          ) : null}

          <Text style={styles.tagLine}>
            Facing difficulties to place your order? / Do you want to place an
            order?
          </Text>

          {!!qrCodeURL && (
            <Image style={styles.qrCode} src={qrCodeURL} alt="QR-Code" />
          )}

          <Image style={styles.scanMeBlock} src={ScanMe} alt="scan-me" />

          <Text style={styles.tagLine}>
            Scan the barcode, select the services you want, and place your
            order!
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default ShopQRCodePDF;
