import React, { useState } from 'react';
import {
  CardActions,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  MenuItem,
  DialogActions
} from '@material-ui/core';
import { routes } from 'common/constants';
import { useHistory } from 'react-router-dom';
import { useAmplifyAuth } from 'context';
import { API, graphqlOperation } from 'aws-amplify';
import { updateShop } from 'context/shop/shopsMutations';
import { useLoader } from 'layouts/loaderContext';
import { useSnackbar } from 'notistack';
import { useShops } from 'context/shop/shopsContext';

const ChangeShopStatusModal = ({
  open,
  setOpenDialog,
  handleNewShopApprove,
  selectedShop,
  isApproving,
  handleNewShopRejectConfirm,
  dispatch
}) => {
  const { setLoading } = useLoader();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleClose = () => {
    setOpenDialog(false);
  };

  const changeShopStatusHandler = async () => {
    const { _version, id } = selectedShop;
    try {
      if (selectedShop.id) {
        const payload = {
          input: {
            status: isApproving ? 'pending' : 'suspended',
            _version: _version,
            id: id
          }
        };
        let sBar = enqueueSnackbar(`updating...`, {
          variant: 'info',
          persist: true
        });
        setLoading(true);
        const result = await API.graphql(graphqlOperation(updateShop, payload));
        closeSnackbar(sBar);
        const successMessage = isApproving
          ? 'Shop approval successful!'
          : 'Shop rejection successful.';
        enqueueSnackbar(successMessage, {
          variant: 'success',
          preventDuplicate: true,
          autoHideDuration: 1000
        });
        dispatch({
          type: 'updateShop',
          payload: result.data.updateShop
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Something went wrong!!', {
        variant: 'error',
        preventDuplicate: true,
        autoHideDuration: 1000
      });
    } finally {
      if (isApproving) {
        handleNewShopApprove();
      } else {
        handleNewShopRejectConfirm();
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {isApproving
          ? 'Confirming your approval of this shop?'
          : 'Confirming your decision to reject this shop?'}
      </DialogTitle>
      {isApproving && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Verify all shop details before confirming to ensure accuracy.
            Additionally, note that the status of the shop will be marked as
            pending.
          </DialogContentText>
          <TextField
            fullWidth
            label="Status"
            margin="dense"
            name={'status'}
            required
            value="Pending"
            variant="outlined"
            disabled
          />
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={changeShopStatusHandler} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FormActionButton = ({
  isAddNewPage,
  selectedShop,
  resetHandler,
  handleSubmit,
  buttonAction,
  pageType,
  setDeleteDialog
}) => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const { dispatch } = useShops();
  const {
    state: { isAdmin = false }
  } = useAmplifyAuth();

  const openDialogHandler = () => {
    setIsApproving(true);
    setOpenDialog(true);
  };

  const handleNewShopApprove = () => {
    history.push(`/admin/users/add/${selectedShop?.id}`);
  };

  const handleNewShopRejectConfirm = () => {
    setOpenDialog(false);
    history.push(`/admin/shops`);
  };

  const handleNewShopReject = () => {
    setIsApproving(false);
    setOpenDialog(true);
  };

  return (
    <CardActions>
      <ChangeShopStatusModal
        open={openDialog}
        selectedShop={selectedShop}
        setOpenDialog={setOpenDialog}
        handleNewShopApprove={handleNewShopApprove}
        isApproving={isApproving}
        handleNewShopRejectConfirm={handleNewShopRejectConfirm}
        dispatch={dispatch}
      />
      {isAddNewPage ? (
        <React.Fragment>
          <Button
            color="primary"
            disabled={!selectedShop}
            type="submit"
            variant="contained"
            size="small">
            Add Shop details
          </Button>
          <Button
            color="secondary"
            disabled={!selectedShop}
            variant="contained"
            size="small"
            onClick={resetHandler}>
            Reset
          </Button>
        </React.Fragment>
      ) : (
        <>
          {selectedShop?.status === 'new' ? (
            <>
              <Button
                color="primary"
                variant="contained"
                onClick={openDialogHandler}>
                Approved
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={handleNewShopReject}>
                Reject
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  if (pageType == 'shop') {
                    history.push('/');
                  } else {
                    history.push(routes.admin.shops);
                  }
                }}
                variant="outlined">
                Cancel
              </Button>
            </>
          ) : (
            <>
              <Button
                color="primary"
                onClick={(e) => {
                  handleSubmit(e, buttonAction.SAVE);
                }}
                variant="contained">
                Save
              </Button>
              {pageType !== 'shop' && (
                <Button
                  color="primary"
                  onClick={(e) => {
                    handleSubmit(e, buttonAction.SAVE_AND_CLOSE);
                  }}
                  variant="outlined">
                  Save and Close
                </Button>
              )}
              <Button
                color="primary"
                onClick={() => {
                  if (pageType == 'shop') {
                    history.push('/');
                  } else {
                    history.push(routes.admin.shops);
                  }
                }}
                variant="outlined">
                Cancel
              </Button>
              {isAdmin &&
                pageType !== 'shop' &&
                selectedShop?.status !== 'rejected' && (
                  <Button
                    color="primary"
                    onClick={() => {
                      setDeleteDialog(true);
                    }}
                    variant="outlined">
                    Delete
                  </Button>
                )}
            </>
          )}
        </>
      )}
    </CardActions>
  );
};

export default FormActionButton;
