import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { StyledTabs } from 'components/molecules';
import { StyledTab } from 'components/molecules';
import { TABS } from 'views/Auctions/constants';

const useStyles = makeStyles((theme) => ({
  tabsBar: {
    backgroundColor: 'transparent',
    color: '#263238',
    marginTop: '1rem',
    boxShadow: 'none'
  },
  tabsBarContainer: {
    marginTop: '2rem',
    borderBottom: '1px solid #cdcdcd'
  }
}));

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`
});

const AuctionToolbar = (props) => {
  const { currentTabValue, handleTabChange } = props;
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Typography variant="h3">Auctions</Typography>
      </Grid>
      <Grid container className={classes.tabsBarContainer}>
        <AppBar position="static" className={classes.tabsBar}>
          <StyledTabs
            value={currentTabValue}
            onChange={handleTabChange}
            aria-label="auctions-tabs">
            {TABS.map((_item, index) => (
              <StyledTab
                label={_item.name}
                {...a11yProps(index)}
                key={`auctions-tabs-tab-items-${index}`}
              />
            ))}
          </StyledTabs>
        </AppBar>
      </Grid>
    </>
  );
};
AuctionToolbar.defaultProps = {
  currentTabValue: 1,
  handleTabChange: () => {}
};
AuctionToolbar.propTypes = {
  currentTabValue: PropTypes.oneOf([0, 1, 2, 3]).isRequired,
  handleTabChange: PropTypes.func.isRequired
};

export default AuctionToolbar;
