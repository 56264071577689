import React from 'react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Grid } from '@material-ui/core';

const Marker = ({ text }) => {
  return (
    <Grid
      container
      direction="column"
      style={{ minWidth: '12.5rem', cursor: 'pointer' }}>
      <LocationOnIcon style={{ color: '#194376' }} />
      <h3>{text}</h3>
    </Grid>
  );
};

export default Marker;
