import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles({
  container: {
    width: '100%',
    padding: '15px 85px',
    backgroundColor: '#143a69'
  },
  skeletonStyles: {
    marginBottom: 10,
    borderRadius: 4
  }
});

const ShopListAdsSkeleton = () => {
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const sm = useMediaQuery(theme.breakpoints.up('sm'));

  const NUMBER_OF_ADS_CARD = md ? 3 : sm ? 2 : 1;
  const CARD_HEIGHT = md ? 318 : sm ? 250 : 200;
  const CARD_WIDTH = md ? 4 : sm ? 6 : 12;

  return (
    <Grid container item className={classes.container} spacing={2}>
      {[...Array(NUMBER_OF_ADS_CARD).keys()].map((item) => {
        return (
          <Grid item xs={CARD_WIDTH} key={item}>
            <Skeleton
              animation="wave"
              variant="rect"
              width="100%"
              height={CARD_HEIGHT}
              className={classes.skeletonStyles}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ShopListAdsSkeleton;
