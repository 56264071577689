export const initialValues = {
  title: '',
  description: '',
  startDate: null,
  startTime: null,
  endDate: null,
  endTime: null,
  type: '',
  discountFor: '',
  discountType: '',
  discountUnit: '',
  discountAmount: '',
  conditionUnit: '',
  conditionAmount: '',
  maxUsesPerUser: 0,
  serviceID: '',
  productID: ''
};

export const endAdornments = {
  amount: '£',
  percentage: '%',
  noOfItems: 'items'
};

export const fields = [
  {
    name: 'title',
    label: 'Title',
    md: 12
  },
  {
    name: 'description',
    label: 'Description',
    md: 12,
    multiline: true,
    rows: 3,
    placeholder: 'please provide a proper understandable description...'
  }
];

export const requiredFields = {
  title: 'Title',
  description: 'Description',
  startDate: 'Start Date',
  startTime: 'Start Time',
  endDate: 'End Date',
  endTime: 'End Time',
  discountType: 'Discount Type',
  discountUnit: 'Discount Unit',
  discountAmount: 'Discount Amount'
};

export const notes = [
  <>
    Please provide a proper <strong>Description</strong>
  </>,
  <>
    Only <strong>Title</strong> and <strong>Description</strong> will be shown
    to Customers
  </>,
  <>
    <strong>Direct</strong> coupons will be applied automatically and
    <strong> Conditional</strong> coupons can be applied by the customers
  </>
];

export const DISCOUNT_TYPES = [
  {
    name: 'Direct amount discount for product',
    couponAttributes: {
      discountFor: 'product',
      discountType: 'direct',
      discountUnit: 'amount',
      conditionUnit: ''
    }
  },
  {
    name: 'Direct percentage discount for product',
    couponAttributes: {
      discountFor: 'product',
      discountType: 'direct',
      discountUnit: 'percentage',
      conditionUnit: ''
    }
  },
  {
    name: 'Amount discount for product on minimum amount spent',
    couponAttributes: {
      discountFor: 'product',
      discountType: 'conditional',
      discountUnit: 'amount',
      conditionUnit: 'amount'
    }
  },
  {
    name: 'Percentage discount for product on minimum amount spent',
    couponAttributes: {
      discountFor: 'product',
      discountType: 'conditional',
      discountUnit: 'percentage',
      conditionUnit: 'amount'
    }
  },
  {
    name: 'No of products for free on minimum amount spent',
    couponAttributes: {
      discountFor: 'product',
      discountType: 'conditional',
      discountUnit: 'noOfItems',
      conditionUnit: 'amount'
    }
  },
  {
    name: 'Amount discount for product on minimum no of products ordered',
    couponAttributes: {
      discountFor: 'product',
      discountType: 'conditional',
      discountUnit: 'amount',
      conditionUnit: 'noOfItems'
    }
  },
  {
    name: 'Percentage discount for product on minimum no of products ordered',
    couponAttributes: {
      discountFor: 'product',
      discountType: 'conditional',
      discountUnit: 'percentage',
      conditionUnit: 'noOfItems'
    }
  },
  {
    name: 'No of products for free on minimum no of products ordered',
    couponAttributes: {
      discountFor: 'product',
      discountType: 'conditional',
      discountUnit: 'noOfItems',
      conditionUnit: 'noOfItems'
    }
  },
  {
    name: 'Amount discount for whole cart on minimum amount spent',
    couponAttributes: {
      discountFor: '',
      discountType: 'conditional',
      discountUnit: 'amount',
      conditionUnit: 'amount'
    }
  },
  {
    name: 'Percentage discount for whole cart on minimum amount spent',
    couponAttributes: {
      discountFor: '',
      discountType: 'conditional',
      discountUnit: 'percentage',
      conditionUnit: 'amount'
    }
  }
];
