import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { showPrice } from 'common/utilFunctions';

const PaymentStatusTag = ({ order = {}, prevTotal = 0 }) => {
  return (
    <>
      {order?.paymentStatus !== 'unpaid' ? (
        <CheckIcon style={{ verticalAlign: 'middle' }} color="primary" />
      ) : (
        <ClearIcon style={{ verticalAlign: 'middle' }} color="primary" />
      )}

      {order?.paymentStatus === 'partiallyPaid' &&
        `Payment of ${showPrice(order?.paidAmount || 0)} out of ${showPrice(
          order?.total || 0
        )} was Paid`}

      {order?.paymentStatus === 'paid' &&
        order?.total > prevTotal &&
        `Payment of ${showPrice(order?.paidAmount || 0)} out of ${showPrice(
          order?.total || 0
        )} was Paid`}

      {order?.paymentStatus === 'paid' &&
        order?.total === prevTotal &&
        `Payment of ${showPrice(order?.total || 0)} was Paid`}

      {order?.paymentStatus === 'unpaid' && `Payment Unpaid`}
    </>
  );
};

export default PaymentStatusTag;
