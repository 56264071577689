import React, { useState, useEffect } from 'react';
import { SearchBannerModal } from './components';
import { Image, DocumentDeleteDialog } from 'components/organisms';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Typography
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { useLoader } from 'layouts/loaderContext';
import { useSnackbar } from 'notistack';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { listSearchBanners } from 'graphql/queries';
import {
  createSearchBanner,
  updateSearchBanner,
  deleteSearchBanner
} from 'graphql/mutations';
import { uploadImage } from 'common/utilFunctions';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1.5rem 1rem'
  },
  bannersContainer: {
    marginTop: '1.5rem'
  },
  bannerRoot: {
    padding: '1rem'
  }
}));

const SearchBanner = ({ className, ...rest }) => {
  const [banners, setBanners] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const classes = useStyles();
  const { setLoading } = useLoader();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);

  const ITEMS_PER_PAGE = 6;

  useEffect(() => {
    let mounted = { val: true };
    loadBanners(mounted);

    return () => {
      mounted.val = false;
    };
  }, []);

  const loadBanners = async (mounted = { val: false }) => {
    setLoading(true);
    const sBar = enqueueSnackbar('Loading...', {
      variant: 'info',
      persist: true
    });
    try {
      const resp = await API.graphql(
        graphqlOperation(listSearchBanners, { limit: 1000 })
      );
      mounted.val &&
        setBanners(
          resp.data.listSearchBanners?.items.filter((item) => !item._deleted) ||
            []
        );
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Something went wrong...', {
        variant: 'error',
        autoHideDuration: 2000
      });
    } finally {
      closeSnackbar(sBar);
      setLoading(false);
    }
  };

  const handleAddBtnClick = () => setShowModal(true);

  const handleModalClose = (event, reason = '') => {
    if (reason && reason === 'backdropClick') return;
    setShowModal(false);
    setSelectedBanner({});
  };

  const handleEditClick = (item) => () => {
    setSelectedBanner(item);
    setShowModal(true);
  };

  // TODO: move to context
  const handleModalSubmit = async (banner) => {
    if (!banner?.photoURL || !banner?.mobilePhotoURL) {
      enqueueSnackbar('Enter Valid Images', {
        variant: 'error',
        persist: true
      });
      return;
    }
    let sBar;
    setLoading(true);
    try {
      const input = { title: banner.title, color: banner.color };
      if (banner?.file) {
        const { type: mimeType } = banner.file;
        input['MIMETypes'] = mimeType;
        const key = await uploadImage(banner.file, 'images/banners');
        input['photoURL'] = key;
      }
      if (banner?.file2) {
        const { type: mimeType } = banner.file2;
        input['MIMETypes'] = mimeType;
        const key = await uploadImage(banner.file2, 'images/banners');
        input['mobilePhotoURL'] = key;
      }
      if (banner.id) {
        // if photo is updated for a banner then delete the old photo from S3
        if (
          selectedBanner.id === banner.id &&
          selectedBanner.photoURL !== banner.photoURL
        )
          Storage.remove(selectedBanner.photoURL, { level: 'public' });
        if (
          selectedBanner.id === banner.id &&
          selectedBanner.mobilePhotoURL !== banner.mobilePhotoURL
        )
          Storage.remove(selectedBanner.mobilePhotoURL, { level: 'public' });
        sBar = enqueueSnackbar('Updating Banner...', {
          variant: 'info',
          persist: true
        });
        input['id'] = banner.id;
        input['_version'] = banner._version;
        const resp = await API.graphql(
          graphqlOperation(updateSearchBanner, { input })
        );
        setBanners([
          resp.data.updateSearchBanner,
          ...banners.filter((item) => item.id !== banner.id)
        ]);
        enqueueSnackbar('Banner Updated', {
          variant: 'success',
          autoHideDuration: 2000
        });
        setSelectedBanner(null);
      } else {
        sBar = enqueueSnackbar('Adding Banner...', {
          variant: 'info',
          persist: true
        });
        input['enabled'] = true;
        const resp = await API.graphql(
          graphqlOperation(createSearchBanner, { input })
        );
        setBanners([resp.data.createSearchBanner, ...banners]);
        enqueueSnackbar('Banner Added', {
          variant: 'success',
          autoHideDuration: 2000
        });
      }
      handleModalClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Something went wrong...', {
        variant: 'error',
        autoHideDuration: 2000
      });
    } finally {
      closeSnackbar(sBar);
      setLoading(false);
    }
  };

  const handleDeleteClick = (item) => () => {
    setSelectedBanner(item);
    setShowDeleteConfirmModal(true);
  };

  const handleDeleteConfirmModalClose = () => {
    setShowDeleteConfirmModal(false);
    setSelectedBanner({});
  };

  // TODO: move to context
  const handleConfirmDelete = async () => {
    let sBar;
    setLoading(true);
    try {
      sBar = enqueueSnackbar('Deleting Banner...', {
        variant: 'info',
        persist: true
      });
      const input = {
        id: selectedBanner.id,
        _version: selectedBanner._version
      };
      await API.graphql(graphqlOperation(deleteSearchBanner, { input }));
      Storage.remove(selectedBanner.photoURL, { level: 'public' });
      Storage.remove(selectedBanner.mobilePhotoURL, { level: 'public' });
      setBanners(banners.filter((item) => item.id !== selectedBanner.id));
      enqueueSnackbar('Banner Deleted', {
        variant: 'success',
        autoHideDuration: 2000
      });
      handleDeleteConfirmModalClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Something went wrong...', {
        variant: 'error',
        autoHideDuration: 2000
      });
    } finally {
      closeSnackbar(sBar);
      setLoading(false);
    }
  };

  const handleBannerEnabledChange = async (banner) => {
    let sBar;
    setLoading(true);
    try {
      sBar = enqueueSnackbar('Updating Banner...', {
        variant: 'info',
        persist: true
      });
      const input = {
        id: banner.id,
        enabled: !banner.enabled,
        _version: banner._version
      };
      const resp = await API.graphql(
        graphqlOperation(updateSearchBanner, { input })
      );
      setBanners((prev) =>
        prev.map((prev) =>
          prev.id === resp.data.updateSearchBanner.id
            ? resp.data.updateSearchBanner
            : prev
        )
      );
      enqueueSnackbar('Banner Updated', {
        variant: 'success',
        autoHideDuration: 2000
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Something went wrong...', {
        variant: 'error',
        autoHideDuration: 2000
      });
    } finally {
      closeSnackbar(sBar);
      setLoading(false);
    }
  };

  const noOfPages = banners && Math.ceil(banners.length / ITEMS_PER_PAGE);

  const paginationHandler = (_, value) => {
    setPage(value);
  };

  return (
    <>
      <Grid container {...rest} className={clsx(classes.root, className)}>
        <Grid container justify="space-between">
          <Typography variant="h3">Home Page Search Banners</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddBtnClick}>
            Add Banner
          </Button>
        </Grid>
        <Grid container className={classes.bannersContainer}>
          {banners
            ?.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)
            ?.map((item, index) => {
              return (
                <Grid
                  item
                  md={4}
                  sm={6}
                  xs={12}
                  className={classes.bannerRoot}
                  key={`search-banners-items-${index}`}>
                  <Card>
                    <CardContent>
                      <Image
                        docKey={item.photoURL}
                        width={'100%'}
                        alt="Home page image"
                      />
                      <Grid
                        container
                        justify="center"
                        style={{ gap: '0.5rem' }}
                        alignItems="center">
                        <Typography variant="h5" align="center">
                          {item.title}
                        </Typography>
                        <input type="color" value={item.color} disabled />
                      </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions>
                      <Grid container justify="space-around">
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={handleEditClick(item)}>
                          edit
                        </Button>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={item.enabled}
                              onChange={(e) => handleBannerEnabledChange(item)}
                            />
                          }
                          label={item.enabled ? 'Enabled ' : 'Disabled'}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={handleDeleteClick(item)}>
                          delete
                        </Button>
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
        {banners.length > ITEMS_PER_PAGE ? (
          <Grid
            container
            item
            xs={12}
            justify="center"
            style={{ marginTop: '1rem' }}>
            <Pagination
              count={noOfPages}
              page={page}
              onChange={paginationHandler}
              defaultPage={1}
              color="secondary"
              shape="rounded"
              size="small"
            />
          </Grid>
        ) : null}
      </Grid>
      <SearchBannerModal
        open={showModal}
        banner={selectedBanner}
        handleClose={handleModalClose}
        handleSubmit={handleModalSubmit}
      />
      <DocumentDeleteDialog
        open={showDeleteConfirmModal}
        handleCancel={handleDeleteConfirmModalClose}
        handleConfirm={handleConfirmDelete}
        confirmText="delete this banner"
        title="Delete Banner"
      />
    </>
  );
};

export default SearchBanner;
