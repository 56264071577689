/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const disableUser = /* GraphQL */ `
  mutation DisableUser($username: String!, $id: String!) {
    disableUser(username: $username, id: $id) {
      statusCode
      body
      __typename
    }
  }
`;
export const enableUser = /* GraphQL */ `
  mutation EnableUser($username: String!, $id: String!) {
    enableUser(username: $username, id: $id) {
      statusCode
      body
      __typename
    }
  }
`;
export const resetPassword = /* GraphQL */ `
  mutation ResetPassword($username: String!) {
    resetPassword(username: $username) {
      statusCode
      body
      __typename
    }
  }
`;
export const addNewShopUser = /* GraphQL */ `
  mutation AddNewShopUser(
    $email: String!
    $title: String
    $firstName: String
    $lastName: String
    $picture: String
    $groups: String
    $address: String
    $postalCode: String
    $shopId: String
    $phoneNumber: String
    $language: [String]
    $dateOfBirth: AWSDate
    $status: UserStatus
  ) {
    addNewShopUser(
      email: $email
      title: $title
      firstName: $firstName
      lastName: $lastName
      picture: $picture
      groups: $groups
      address: $address
      postalCode: $postalCode
      shopId: $shopId
      phoneNumber: $phoneNumber
      language: $language
      dateOfBirth: $dateOfBirth
      status: $status
    ) {
      id
      groups
      picture
      username
      userId
      firstName
      lastName
      title
      defaultAddress
      email
      address
      postalCode
      phoneNumber
      createdAt
      shopID
      stripeID
      status
      language
      dateOfBirth
      documents {
        name
        documentType
        documentURL
        description
        MIMETypes
        status
        __typename
      }
      updatedAt
      favouriteShops {
        items {
          id
          shopID
          userID
          isFavourite
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAnOrder = /* GraphQL */ `
  mutation CreateAnOrder(
    $shopID: String!
    $orderList: String!
    $collection: String!
    $delivery: String!
    $total: Float!
    $tax: Float!
    $paymentMethod: PaymentMethod!
    $discount: Float
    $variableTotal: String
    $coupons: [String]
    $collectionAndDeliveryFee: Float!
    $currentShopFees: String!
    $onHoldPaymentIntentID: String
    $onHoldPaymentAmount: Float
    $bidID: String
  ) {
    createAnOrder(
      shopID: $shopID
      orderList: $orderList
      collection: $collection
      delivery: $delivery
      total: $total
      tax: $tax
      paymentMethod: $paymentMethod
      discount: $discount
      variableTotal: $variableTotal
      coupons: $coupons
      collectionAndDeliveryFee: $collectionAndDeliveryFee
      currentShopFees: $currentShopFees
      onHoldPaymentIntentID: $onHoldPaymentIntentID
      onHoldPaymentAmount: $onHoldPaymentAmount
      bidID: $bidID
    ) {
      id
      orderID
      shopID
      createdAt
      __typename
    }
  }
`;
export const updateAnOrder = /* GraphQL */ `
  mutation UpdateAnOrder($input: OrderInput!) {
    updateAnOrder(input: $input) {
      id
      shopID
      orderID
      bidID
      orderList {
        productID
        serviceID
        category
        name
        codeName
        noOfPieces
        description
        discount
        discountCoupon
        discountCouponID
        discountDescription
        overallDiscount
        unit
        price
        totalPrice
        priceRange
        quantity
        __typename
      }
      userID
      status
      collection {
        date
        time
        type
        address
        driver {
          userID
          firstName
          lastName
          title
          email
          phoneNumber
          __typename
        }
        instructions
        __typename
      }
      delivery {
        date
        time
        type
        address
        driver {
          userID
          firstName
          lastName
          title
          email
          phoneNumber
          __typename
        }
        instructions
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      paymentStatus
      paymentMethod
      orderCancelOrDeclineReason
      total
      variableTotal
      discount
      tax
      noOfPieces
      paidAmount
      onHoldPaymentIntentID
      onHoldPaymentAmount
      coupons
      collectionAndDeliveryFee
      currentShopFees {
        standardDeliveryFee
        AsapDeliveryFee
        minPriceForFree
        distanceRangeForFree
        distanceRange
        __typename
      }
      itemizedAt
      payments {
        items {
          id
          orderID
          shopID
          userID
          stripePaymentIntentID
          amount
          createdAt
          createdBy
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bid {
        id
        auctionID
        shopID
        createdBy
        createdAt
        totalPrice
        shippingCharges
        items {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        auction {
          id
          auctionID
          userID
          createdAt
          startDateTime
          endDateTime
          postcode
          isClosed
          total
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      forwardRequests {
        items {
          id
          orderID
          forwardReason
          requestShopID
          acceptedShopID
          createdAt
          createdBy
          status
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      userDetail {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customerReview {
        items {
          id
          shopID
          userID
          orderID
          replyID
          title
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const confirmOrderCardPayment = /* GraphQL */ `
  mutation ConfirmOrderCardPayment(
    $orderID: String!
    $paymentIntentID: String
  ) {
    confirmOrderCardPayment(
      orderID: $orderID
      paymentIntentID: $paymentIntentID
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const acceptOrderForwardRequest = /* GraphQL */ `
  mutation AcceptOrderForwardRequest($orderForwardShopID: String!) {
    acceptOrderForwardRequest(orderForwardShopID: $orderForwardShopID) {
      statusCode
      body
      __typename
    }
  }
`;
export const createAnOrderForwardRequest = /* GraphQL */ `
  mutation CreateAnOrderForwardRequest(
    $orderID: String!
    $forwardReason: String!
    $requestShopID: String!
    $forwardShopIDs: [String!]!
  ) {
    createAnOrderForwardRequest(
      orderID: $orderID
      forwardReason: $forwardReason
      requestShopID: $requestShopID
      forwardShopIDs: $forwardShopIDs
    ) {
      id
      orderID
      forwardReason
      requestShopID
      acceptedShopID
      createdAt
      createdBy
      status
      forwardShops {
        items {
          id
          shopID
          forwardRequestID
          status
          forwaredBy
          createdAt
          createdBy
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      order {
        id
        shopID
        orderID
        bidID
        orderList {
          productID
          serviceID
          category
          name
          codeName
          noOfPieces
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          totalPrice
          priceRange
          quantity
          __typename
        }
        userID
        status
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        paymentMethod
        orderCancelOrDeclineReason
        total
        variableTotal
        discount
        tax
        noOfPieces
        paidAmount
        onHoldPaymentIntentID
        onHoldPaymentAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
          distanceRangeForFree
          distanceRange
          __typename
        }
        itemizedAt
        payments {
          nextToken
          startedAt
          __typename
        }
        bid {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        forwardRequests {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        customerReview {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      requestShop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      acceptedShop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createAnAuction = /* GraphQL */ `
  mutation CreateAnAuction(
    $userID: ID!
    $startDateTime: AWSDateTime!
    $endDateTime: AWSDateTime!
    $collection: String!
    $delivery: String!
    $total: Float
    $postcode: String!
  ) {
    createAnAuction(
      userID: $userID
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      collection: $collection
      delivery: $delivery
      total: $total
      postcode: $postcode
    ) {
      id
      auctionID
      userID
      createdAt
      startDateTime
      endDateTime
      collection {
        date
        time
        type
        address
        driver {
          userID
          firstName
          lastName
          title
          email
          phoneNumber
          __typename
        }
        instructions
        __typename
      }
      delivery {
        date
        time
        type
        address
        driver {
          userID
          firstName
          lastName
          title
          email
          phoneNumber
          __typename
        }
        instructions
        __typename
      }
      postcode
      isClosed
      total
      items {
        items {
          id
          auctionID
          serviceID
          name
          description
          quantity
          image
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bids {
        items {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      shops {
        items {
          id
          shopID
          auctionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const addShopBankAccountDetails = /* GraphQL */ `
  mutation AddShopBankAccountDetails(
    $shopID: String!
    $name: String!
    $accountNumber: String!
    $accountSortCode: String!
    $accountHolderName: String!
    $otpInput: String
  ) {
    addShopBankAccountDetails(
      shopID: $shopID
      name: $name
      accountNumber: $accountNumber
      accountSortCode: $accountSortCode
      accountHolderName: $accountHolderName
      otpInput: $otpInput
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const updateShopBankAccountDetails = /* GraphQL */ `
  mutation UpdateShopBankAccountDetails(
    $id: String!
    $_version: Int!
    $name: String!
    $accountNumber: String!
    $accountSortCode: String!
    $accountHolderName: String!
    $otpInput: String
  ) {
    updateShopBankAccountDetails(
      id: $id
      _version: $_version
      name: $name
      accountNumber: $accountNumber
      accountSortCode: $accountSortCode
      accountHolderName: $accountHolderName
      otpInput: $otpInput
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const createReviewReply = /* GraphQL */ `
  mutation CreateReviewReply($reviewID: String!, $description: String!) {
    createReviewReply(reviewID: $reviewID, description: $description) {
      id
      reviewID
      description
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
      review {
        id
        shopID
        userID
        orderID
        replyID
        title
        ratings {
          title
          rating
          __typename
        }
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        reply {
          id
          reviewID
          description
          createdAt
          createdBy
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateUserDetail = /* GraphQL */ `
  mutation UpdateUserDetail(
    $id: String
    $username: String
    $title: String
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $defaultAddress: String
    $picture: String
    $address: String
    $postalCode: String
    $language: [String]
    $dateOfBirth: AWSDate
    $status: UserStatus
  ) {
    updateUserDetail(
      id: $id
      username: $username
      title: $title
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      defaultAddress: $defaultAddress
      picture: $picture
      address: $address
      postalCode: $postalCode
      language: $language
      dateOfBirth: $dateOfBirth
      status: $status
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const createAnItem = /* GraphQL */ `
  mutation CreateAnItem($serviceID: ID!, $name: String!) {
    createAnItem(serviceID: $serviceID, name: $name) {
      id
      serviceID
      name
      codeName
      description
      image
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      service {
        id
        serviceID
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        photoGallaryImages {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          codeName
          noOfPieces
          description
          unit
          price
          priceRange
          image
          tags
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createAProduct = /* GraphQL */ `
  mutation CreateAProduct(
    $shopID: ID!
    $serviceID: ID!
    $itemID: ID
    $name: String
    $codeName: String
    $noOfPieces: Int
    $description: String
    $unit: Units
    $price: Float
    $priceRange: String
    $image: String
  ) {
    createAProduct(
      shopID: $shopID
      serviceID: $serviceID
      itemID: $itemID
      name: $name
      codeName: $codeName
      noOfPieces: $noOfPieces
      description: $description
      unit: $unit
      price: $price
      priceRange: $priceRange
      image: $image
    ) {
      id
      shopID
      serviceID
      itemID
      category
      name
      codeName
      noOfPieces
      description
      unit
      price
      priceRange
      image
      tags
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      service {
        id
        serviceID
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        photoGallaryImages {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      item {
        id
        serviceID
        name
        codeName
        description
        image
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          serviceID
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      coupons {
        items {
          id
          shopID
          productID
          title
          description
          startDateTime
          endDateTime
          maxUsesPerUser
          discountType
          discountAmount
          discountUnit
          conditionAmount
          conditionUnit
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateAnUserLoggedInStatus = /* GraphQL */ `
  mutation UpdateAnUserLoggedInStatus($status: UserLoggedInStatusStatus!) {
    updateAnUserLoggedInStatus(status: $status) {
      statusCode
      body
      __typename
    }
  }
`;
export const markNotificationsAsRead = /* GraphQL */ `
  mutation MarkNotificationsAsRead($notificationIDs: [String!]!) {
    markNotificationsAsRead(notificationIDs: $notificationIDs) {
      statusCode
      body
      __typename
    }
  }
`;
export const generateOTP = /* GraphQL */ `
  mutation GenerateOTP {
    generateOTP {
      statusCode
      body
      __typename
    }
  }
`;
export const createService = /* GraphQL */ `
  mutation CreateService(
    $input: CreateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    createService(input: $input, condition: $condition) {
      id
      serviceID
      name
      description
      orderIndex
      type
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      items {
        items {
          id
          serviceID
          name
          codeName
          description
          image
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          codeName
          noOfPieces
          description
          unit
          price
          priceRange
          image
          tags
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      photoGallaryImages {
        items {
          id
          serviceID
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService(
    $input: UpdateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    updateService(input: $input, condition: $condition) {
      id
      serviceID
      name
      description
      orderIndex
      type
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      items {
        items {
          id
          serviceID
          name
          codeName
          description
          image
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          codeName
          noOfPieces
          description
          unit
          price
          priceRange
          image
          tags
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      photoGallaryImages {
        items {
          id
          serviceID
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService(
    $input: DeleteServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    deleteService(input: $input, condition: $condition) {
      id
      serviceID
      name
      description
      orderIndex
      type
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      items {
        items {
          id
          serviceID
          name
          codeName
          description
          image
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          codeName
          noOfPieces
          description
          unit
          price
          priceRange
          image
          tags
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      photoGallaryImages {
        items {
          id
          serviceID
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createItem = /* GraphQL */ `
  mutation CreateItem(
    $input: CreateItemInput!
    $condition: ModelItemConditionInput
  ) {
    createItem(input: $input, condition: $condition) {
      id
      serviceID
      name
      codeName
      description
      image
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      service {
        id
        serviceID
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        photoGallaryImages {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          codeName
          noOfPieces
          description
          unit
          price
          priceRange
          image
          tags
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateItem = /* GraphQL */ `
  mutation UpdateItem(
    $input: UpdateItemInput!
    $condition: ModelItemConditionInput
  ) {
    updateItem(input: $input, condition: $condition) {
      id
      serviceID
      name
      codeName
      description
      image
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      service {
        id
        serviceID
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        photoGallaryImages {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          codeName
          noOfPieces
          description
          unit
          price
          priceRange
          image
          tags
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem(
    $input: DeleteItemInput!
    $condition: ModelItemConditionInput
  ) {
    deleteItem(input: $input, condition: $condition) {
      id
      serviceID
      name
      codeName
      description
      image
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      service {
        id
        serviceID
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        photoGallaryImages {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          codeName
          noOfPieces
          description
          unit
          price
          priceRange
          image
          tags
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateShop = /* GraphQL */ `
  mutation UpdateShop(
    $input: UpdateShopInput!
    $condition: ModelShopConditionInput
  ) {
    updateShop(input: $input, condition: $condition) {
      id
      name
      googlePlaceId
      contactName
      address
      postcode
      town
      country
      email
      phoneNumber
      location {
        lat
        lng
        __typename
      }
      workHrs {
        closeDay
        openDay
        closeTime
        openTime
        __typename
      }
      collectionHrs {
        closeDay
        openDay
        closeTime
        openTime
        __typename
      }
      deliveryHrs {
        closeDay
        openDay
        closeTime
        openTime
        __typename
      }
      image
      minPriceForFree
      distanceRangeForFree
      distanceRange
      standardDeliveryFee
      deliveryLocations
      AsapDeliveryFee
      language
      range
      shopID
      about
      documents {
        name
        documentType
        documentURL
        description
        MIMETypes
        status
        __typename
      }
      photos {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
        serviceID
        __typename
      }
      primaryPhoto {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
        serviceID
        __typename
      }
      videos {
        name
        videoURL
        autoPlay
        thumbURL
        description
        MIMETypes
        status
        __typename
      }
      variant
      status
      isLive
      consentLetterCount
      consentLetterLastDate
      enableAsapDelivery
      collectionInstructions
      deliveryInstructions
      websiteLink
      paymentMethods
      ocrTemplateName
      totalRatingID
      createdBy
      updatedBy
      createdAt
      auctions {
        items {
          id
          shopID
          auctionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          codeName
          noOfPieces
          description
          unit
          price
          priceRange
          image
          tags
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      users {
        items {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      reviews {
        items {
          id
          shopID
          userID
          orderID
          replyID
          title
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      coupons {
        items {
          id
          shopID
          productID
          title
          description
          startDateTime
          endDateTime
          maxUsesPerUser
          discountType
          discountAmount
          discountUnit
          conditionAmount
          conditionUnit
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      rating {
        id
        rating
        ratingsCount
        ratingsSum
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteShop = /* GraphQL */ `
  mutation DeleteShop(
    $input: DeleteShopInput!
    $condition: ModelShopConditionInput
  ) {
    deleteShop(input: $input, condition: $condition) {
      id
      name
      googlePlaceId
      contactName
      address
      postcode
      town
      country
      email
      phoneNumber
      location {
        lat
        lng
        __typename
      }
      workHrs {
        closeDay
        openDay
        closeTime
        openTime
        __typename
      }
      collectionHrs {
        closeDay
        openDay
        closeTime
        openTime
        __typename
      }
      deliveryHrs {
        closeDay
        openDay
        closeTime
        openTime
        __typename
      }
      image
      minPriceForFree
      distanceRangeForFree
      distanceRange
      standardDeliveryFee
      deliveryLocations
      AsapDeliveryFee
      language
      range
      shopID
      about
      documents {
        name
        documentType
        documentURL
        description
        MIMETypes
        status
        __typename
      }
      photos {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
        serviceID
        __typename
      }
      primaryPhoto {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
        serviceID
        __typename
      }
      videos {
        name
        videoURL
        autoPlay
        thumbURL
        description
        MIMETypes
        status
        __typename
      }
      variant
      status
      isLive
      consentLetterCount
      consentLetterLastDate
      enableAsapDelivery
      collectionInstructions
      deliveryInstructions
      websiteLink
      paymentMethods
      ocrTemplateName
      totalRatingID
      createdBy
      updatedBy
      createdAt
      auctions {
        items {
          id
          shopID
          auctionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          codeName
          noOfPieces
          description
          unit
          price
          priceRange
          image
          tags
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      users {
        items {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      reviews {
        items {
          id
          shopID
          userID
          orderID
          replyID
          title
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      coupons {
        items {
          id
          shopID
          productID
          title
          description
          startDateTime
          endDateTime
          maxUsesPerUser
          discountType
          discountAmount
          discountUnit
          conditionAmount
          conditionUnit
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      rating {
        id
        rating
        ratingsCount
        ratingsSum
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      shopID
      serviceID
      itemID
      category
      name
      codeName
      noOfPieces
      description
      unit
      price
      priceRange
      image
      tags
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      service {
        id
        serviceID
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        photoGallaryImages {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      item {
        id
        serviceID
        name
        codeName
        description
        image
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          serviceID
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      coupons {
        items {
          id
          shopID
          productID
          title
          description
          startDateTime
          endDateTime
          maxUsesPerUser
          discountType
          discountAmount
          discountUnit
          conditionAmount
          conditionUnit
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      shopID
      serviceID
      itemID
      category
      name
      codeName
      noOfPieces
      description
      unit
      price
      priceRange
      image
      tags
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      service {
        id
        serviceID
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        photoGallaryImages {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      item {
        id
        serviceID
        name
        codeName
        description
        image
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          serviceID
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      coupons {
        items {
          id
          shopID
          productID
          title
          description
          startDateTime
          endDateTime
          maxUsesPerUser
          discountType
          discountAmount
          discountUnit
          conditionAmount
          conditionUnit
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      shopID
      serviceID
      itemID
      category
      name
      codeName
      noOfPieces
      description
      unit
      price
      priceRange
      image
      tags
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      service {
        id
        serviceID
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        photoGallaryImages {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      item {
        id
        serviceID
        name
        codeName
        description
        image
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          serviceID
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      coupons {
        items {
          id
          shopID
          productID
          title
          description
          startDateTime
          endDateTime
          maxUsesPerUser
          discountType
          discountAmount
          discountUnit
          conditionAmount
          conditionUnit
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      groups
      picture
      username
      userId
      firstName
      lastName
      title
      defaultAddress
      email
      address
      postalCode
      phoneNumber
      createdAt
      shopID
      stripeID
      status
      language
      dateOfBirth
      documents {
        name
        documentType
        documentURL
        description
        MIMETypes
        status
        __typename
      }
      updatedAt
      favouriteShops {
        items {
          id
          shopID
          userID
          isFavourite
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createOrderForwardRequest = /* GraphQL */ `
  mutation CreateOrderForwardRequest(
    $input: CreateOrderForwardRequestInput!
    $condition: ModelOrderForwardRequestConditionInput
  ) {
    createOrderForwardRequest(input: $input, condition: $condition) {
      id
      orderID
      forwardReason
      requestShopID
      acceptedShopID
      createdAt
      createdBy
      status
      forwardShops {
        items {
          id
          shopID
          forwardRequestID
          status
          forwaredBy
          createdAt
          createdBy
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      order {
        id
        shopID
        orderID
        bidID
        orderList {
          productID
          serviceID
          category
          name
          codeName
          noOfPieces
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          totalPrice
          priceRange
          quantity
          __typename
        }
        userID
        status
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        paymentMethod
        orderCancelOrDeclineReason
        total
        variableTotal
        discount
        tax
        noOfPieces
        paidAmount
        onHoldPaymentIntentID
        onHoldPaymentAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
          distanceRangeForFree
          distanceRange
          __typename
        }
        itemizedAt
        payments {
          nextToken
          startedAt
          __typename
        }
        bid {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        forwardRequests {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        customerReview {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      requestShop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      acceptedShop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateOrderForwardRequest = /* GraphQL */ `
  mutation UpdateOrderForwardRequest(
    $input: UpdateOrderForwardRequestInput!
    $condition: ModelOrderForwardRequestConditionInput
  ) {
    updateOrderForwardRequest(input: $input, condition: $condition) {
      id
      orderID
      forwardReason
      requestShopID
      acceptedShopID
      createdAt
      createdBy
      status
      forwardShops {
        items {
          id
          shopID
          forwardRequestID
          status
          forwaredBy
          createdAt
          createdBy
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      order {
        id
        shopID
        orderID
        bidID
        orderList {
          productID
          serviceID
          category
          name
          codeName
          noOfPieces
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          totalPrice
          priceRange
          quantity
          __typename
        }
        userID
        status
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        paymentMethod
        orderCancelOrDeclineReason
        total
        variableTotal
        discount
        tax
        noOfPieces
        paidAmount
        onHoldPaymentIntentID
        onHoldPaymentAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
          distanceRangeForFree
          distanceRange
          __typename
        }
        itemizedAt
        payments {
          nextToken
          startedAt
          __typename
        }
        bid {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        forwardRequests {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        customerReview {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      requestShop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      acceptedShop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteOrderForwardRequest = /* GraphQL */ `
  mutation DeleteOrderForwardRequest(
    $input: DeleteOrderForwardRequestInput!
    $condition: ModelOrderForwardRequestConditionInput
  ) {
    deleteOrderForwardRequest(input: $input, condition: $condition) {
      id
      orderID
      forwardReason
      requestShopID
      acceptedShopID
      createdAt
      createdBy
      status
      forwardShops {
        items {
          id
          shopID
          forwardRequestID
          status
          forwaredBy
          createdAt
          createdBy
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      order {
        id
        shopID
        orderID
        bidID
        orderList {
          productID
          serviceID
          category
          name
          codeName
          noOfPieces
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          totalPrice
          priceRange
          quantity
          __typename
        }
        userID
        status
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        paymentMethod
        orderCancelOrDeclineReason
        total
        variableTotal
        discount
        tax
        noOfPieces
        paidAmount
        onHoldPaymentIntentID
        onHoldPaymentAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
          distanceRangeForFree
          distanceRange
          __typename
        }
        itemizedAt
        payments {
          nextToken
          startedAt
          __typename
        }
        bid {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        forwardRequests {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        customerReview {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      requestShop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      acceptedShop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createOrderForwardShop = /* GraphQL */ `
  mutation CreateOrderForwardShop(
    $input: CreateOrderForwardShopInput!
    $condition: ModelOrderForwardShopConditionInput
  ) {
    createOrderForwardShop(input: $input, condition: $condition) {
      id
      shopID
      forwardRequestID
      status
      forwaredBy
      createdAt
      createdBy
      forwardRequest {
        id
        orderID
        forwardReason
        requestShopID
        acceptedShopID
        createdAt
        createdBy
        status
        forwardShops {
          nextToken
          startedAt
          __typename
        }
        order {
          id
          shopID
          orderID
          bidID
          userID
          status
          createdAt
          updatedAt
          updatedBy
          paymentStatus
          paymentMethod
          orderCancelOrDeclineReason
          total
          variableTotal
          discount
          tax
          noOfPieces
          paidAmount
          onHoldPaymentIntentID
          onHoldPaymentAmount
          coupons
          collectionAndDeliveryFee
          itemizedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        requestShop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        acceptedShop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateOrderForwardShop = /* GraphQL */ `
  mutation UpdateOrderForwardShop(
    $input: UpdateOrderForwardShopInput!
    $condition: ModelOrderForwardShopConditionInput
  ) {
    updateOrderForwardShop(input: $input, condition: $condition) {
      id
      shopID
      forwardRequestID
      status
      forwaredBy
      createdAt
      createdBy
      forwardRequest {
        id
        orderID
        forwardReason
        requestShopID
        acceptedShopID
        createdAt
        createdBy
        status
        forwardShops {
          nextToken
          startedAt
          __typename
        }
        order {
          id
          shopID
          orderID
          bidID
          userID
          status
          createdAt
          updatedAt
          updatedBy
          paymentStatus
          paymentMethod
          orderCancelOrDeclineReason
          total
          variableTotal
          discount
          tax
          noOfPieces
          paidAmount
          onHoldPaymentIntentID
          onHoldPaymentAmount
          coupons
          collectionAndDeliveryFee
          itemizedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        requestShop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        acceptedShop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteOrderForwardShop = /* GraphQL */ `
  mutation DeleteOrderForwardShop(
    $input: DeleteOrderForwardShopInput!
    $condition: ModelOrderForwardShopConditionInput
  ) {
    deleteOrderForwardShop(input: $input, condition: $condition) {
      id
      shopID
      forwardRequestID
      status
      forwaredBy
      createdAt
      createdBy
      forwardRequest {
        id
        orderID
        forwardReason
        requestShopID
        acceptedShopID
        createdAt
        createdBy
        status
        forwardShops {
          nextToken
          startedAt
          __typename
        }
        order {
          id
          shopID
          orderID
          bidID
          userID
          status
          createdAt
          updatedAt
          updatedBy
          paymentStatus
          paymentMethod
          orderCancelOrDeclineReason
          total
          variableTotal
          discount
          tax
          noOfPieces
          paidAmount
          onHoldPaymentIntentID
          onHoldPaymentAmount
          coupons
          collectionAndDeliveryFee
          itemizedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        requestShop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        acceptedShop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createOrderHistory = /* GraphQL */ `
  mutation CreateOrderHistory(
    $input: CreateOrderHistoryInput!
    $condition: ModelOrderHistoryConditionInput
  ) {
    createOrderHistory(input: $input, condition: $condition) {
      id
      orderID
      order {
        id
        shopID
        orderID
        bidID
        orderList {
          productID
          serviceID
          category
          name
          codeName
          noOfPieces
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          totalPrice
          priceRange
          quantity
          __typename
        }
        userID
        status
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        paymentMethod
        orderCancelOrDeclineReason
        total
        variableTotal
        discount
        tax
        noOfPieces
        paidAmount
        onHoldPaymentIntentID
        onHoldPaymentAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
          distanceRangeForFree
          distanceRange
          __typename
        }
        itemizedAt
        payments {
          nextToken
          startedAt
          __typename
        }
        bid {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        forwardRequests {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        customerReview {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      userID
      status
      changeLog
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAddress = /* GraphQL */ `
  mutation CreateAddress(
    $input: CreateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    createAddress(input: $input, condition: $condition) {
      id
      userID
      postcode
      buildingName
      buildingNumber
      line1
      line2
      town
      county
      district
      country
      latitude
      longitude
      phoneNumber
      contactName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateAddress = /* GraphQL */ `
  mutation UpdateAddress(
    $input: UpdateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    updateAddress(input: $input, condition: $condition) {
      id
      userID
      postcode
      buildingName
      buildingNumber
      line1
      line2
      town
      county
      district
      country
      latitude
      longitude
      phoneNumber
      contactName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteAddress = /* GraphQL */ `
  mutation DeleteAddress(
    $input: DeleteAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    deleteAddress(input: $input, condition: $condition) {
      id
      userID
      postcode
      buildingName
      buildingNumber
      line1
      line2
      town
      county
      district
      country
      latitude
      longitude
      phoneNumber
      contactName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const createCounter = /* GraphQL */ `
  mutation CreateCounter(
    $input: CreateCountersInput!
    $condition: ModelCountersConditionInput
  ) {
    createCounter(input: $input, condition: $condition) {
      id
      counterName
      currentValue
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCounter = /* GraphQL */ `
  mutation UpdateCounter(
    $input: UpdateCountersInput!
    $condition: ModelCountersConditionInput
  ) {
    updateCounter(input: $input, condition: $condition) {
      id
      counterName
      currentValue
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      orderID
      sender
      shopID
      message
      messageIntent
      photo {
        name
        photoURL
        thumbURL
        MIMETypes
        __typename
      }
      status
      notificationID
      readAt
      createdAt
      order {
        id
        shopID
        orderID
        bidID
        orderList {
          productID
          serviceID
          category
          name
          codeName
          noOfPieces
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          totalPrice
          priceRange
          quantity
          __typename
        }
        userID
        status
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        paymentMethod
        orderCancelOrDeclineReason
        total
        variableTotal
        discount
        tax
        noOfPieces
        paidAmount
        onHoldPaymentIntentID
        onHoldPaymentAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
          distanceRangeForFree
          distanceRange
          __typename
        }
        itemizedAt
        payments {
          nextToken
          startedAt
          __typename
        }
        bid {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        forwardRequests {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        customerReview {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      notification {
        id
        userID
        shopID
        orderID
        messageID
        isRead
        createdAt
        updatedAt
        message {
          id
          orderID
          sender
          shopID
          message
          messageIntent
          status
          notificationID
          readAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      senderDetails {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      orderID
      sender
      shopID
      message
      messageIntent
      photo {
        name
        photoURL
        thumbURL
        MIMETypes
        __typename
      }
      status
      notificationID
      readAt
      createdAt
      order {
        id
        shopID
        orderID
        bidID
        orderList {
          productID
          serviceID
          category
          name
          codeName
          noOfPieces
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          totalPrice
          priceRange
          quantity
          __typename
        }
        userID
        status
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        paymentMethod
        orderCancelOrDeclineReason
        total
        variableTotal
        discount
        tax
        noOfPieces
        paidAmount
        onHoldPaymentIntentID
        onHoldPaymentAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
          distanceRangeForFree
          distanceRange
          __typename
        }
        itemizedAt
        payments {
          nextToken
          startedAt
          __typename
        }
        bid {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        forwardRequests {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        customerReview {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      notification {
        id
        userID
        shopID
        orderID
        messageID
        isRead
        createdAt
        updatedAt
        message {
          id
          orderID
          sender
          shopID
          message
          messageIntent
          status
          notificationID
          readAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      senderDetails {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const createCustomerReview = /* GraphQL */ `
  mutation CreateCustomerReview(
    $input: CreateCustomerReviewInput!
    $condition: ModelCustomerReviewConditionInput
  ) {
    createCustomerReview(input: $input, condition: $condition) {
      id
      shopID
      userID
      orderID
      replyID
      title
      ratings {
        title
        rating
        __typename
      }
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      reply {
        id
        reviewID
        description
        createdAt
        createdBy
        updatedAt
        _version
        _deleted
        _lastChangedAt
        review {
          id
          shopID
          userID
          orderID
          replyID
          title
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateReviewReply = /* GraphQL */ `
  mutation UpdateReviewReply(
    $input: UpdateReviewReplyInput!
    $condition: ModelReviewReplyConditionInput
  ) {
    updateReviewReply(input: $input, condition: $condition) {
      id
      reviewID
      description
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
      review {
        id
        shopID
        userID
        orderID
        replyID
        title
        ratings {
          title
          rating
          __typename
        }
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        reply {
          id
          reviewID
          description
          createdAt
          createdBy
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      orderID
      shopID
      userID
      stripePaymentIntentID
      amount
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAd = /* GraphQL */ `
  mutation CreateAd($input: CreateAdInput!, $condition: ModelAdConditionInput) {
    createAd(input: $input, condition: $condition) {
      id
      shopID
      startDate
      endDate
      scheduledDays
      isClosed
      template
      content
      amount
      page
      priority
      orderedDate
      type
      redirectLink
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateAd = /* GraphQL */ `
  mutation UpdateAd($input: UpdateAdInput!, $condition: ModelAdConditionInput) {
    updateAd(input: $input, condition: $condition) {
      id
      shopID
      startDate
      endDate
      scheduledDays
      isClosed
      template
      content
      amount
      page
      priority
      orderedDate
      type
      redirectLink
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteAd = /* GraphQL */ `
  mutation DeleteAd($input: DeleteAdInput!, $condition: ModelAdConditionInput) {
    deleteAd(input: $input, condition: $condition) {
      id
      shopID
      startDate
      endDate
      scheduledDays
      isClosed
      template
      content
      amount
      page
      priority
      orderedDate
      type
      redirectLink
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createGallaryPhoto = /* GraphQL */ `
  mutation CreateGallaryPhoto(
    $input: CreateGallaryPhotoInput!
    $condition: ModelGallaryPhotoConditionInput
  ) {
    createGallaryPhoto(input: $input, condition: $condition) {
      id
      serviceID
      picture {
        name
        photoURL
        thumbURL
        MIMETypes
        __typename
      }
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
  }
`;
export const updateGallaryPhoto = /* GraphQL */ `
  mutation UpdateGallaryPhoto(
    $input: UpdateGallaryPhotoInput!
    $condition: ModelGallaryPhotoConditionInput
  ) {
    updateGallaryPhoto(input: $input, condition: $condition) {
      id
      serviceID
      picture {
        name
        photoURL
        thumbURL
        MIMETypes
        __typename
      }
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGallaryPhoto = /* GraphQL */ `
  mutation DeleteGallaryPhoto(
    $input: DeleteGallaryPhotoInput!
    $condition: ModelGallaryPhotoConditionInput
  ) {
    deleteGallaryPhoto(input: $input, condition: $condition) {
      id
      serviceID
      picture {
        name
        photoURL
        thumbURL
        MIMETypes
        __typename
      }
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
  }
`;
export const createSearchBanner = /* GraphQL */ `
  mutation CreateSearchBanner(
    $input: CreateSearchBannerInput!
    $condition: ModelSearchBannerConditionInput
  ) {
    createSearchBanner(input: $input, condition: $condition) {
      id
      photoURL
      mobilePhotoURL
      image
      mobileImage
      MIMETypes
      title
      color
      enabled
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
  }
`;
export const updateSearchBanner = /* GraphQL */ `
  mutation UpdateSearchBanner(
    $input: UpdateSearchBannerInput!
    $condition: ModelSearchBannerConditionInput
  ) {
    updateSearchBanner(input: $input, condition: $condition) {
      id
      photoURL
      mobilePhotoURL
      image
      mobileImage
      MIMETypes
      title
      color
      enabled
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSearchBanner = /* GraphQL */ `
  mutation DeleteSearchBanner(
    $input: DeleteSearchBannerInput!
    $condition: ModelSearchBannerConditionInput
  ) {
    deleteSearchBanner(input: $input, condition: $condition) {
      id
      photoURL
      mobilePhotoURL
      image
      mobileImage
      MIMETypes
      title
      color
      enabled
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
  }
`;
export const createCoupon = /* GraphQL */ `
  mutation CreateCoupon(
    $input: CreateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    createCoupon(input: $input, condition: $condition) {
      id
      shopID
      productID
      title
      description
      startDateTime
      endDateTime
      maxUsesPerUser
      discountType
      discountAmount
      discountUnit
      conditionAmount
      conditionUnit
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      product {
        id
        shopID
        serviceID
        itemID
        category
        name
        codeName
        noOfPieces
        description
        unit
        price
        priceRange
        image
        tags
        editors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          serviceID
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        item {
          id
          serviceID
          name
          codeName
          description
          image
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateCoupon = /* GraphQL */ `
  mutation UpdateCoupon(
    $input: UpdateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    updateCoupon(input: $input, condition: $condition) {
      id
      shopID
      productID
      title
      description
      startDateTime
      endDateTime
      maxUsesPerUser
      discountType
      discountAmount
      discountUnit
      conditionAmount
      conditionUnit
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      product {
        id
        shopID
        serviceID
        itemID
        category
        name
        codeName
        noOfPieces
        description
        unit
        price
        priceRange
        image
        tags
        editors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          serviceID
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        item {
          id
          serviceID
          name
          codeName
          description
          image
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteCoupon = /* GraphQL */ `
  mutation DeleteCoupon(
    $input: DeleteCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    deleteCoupon(input: $input, condition: $condition) {
      id
      shopID
      productID
      title
      description
      startDateTime
      endDateTime
      maxUsesPerUser
      discountType
      discountAmount
      discountUnit
      conditionAmount
      conditionUnit
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      product {
        id
        shopID
        serviceID
        itemID
        category
        name
        codeName
        noOfPieces
        description
        unit
        price
        priceRange
        image
        tags
        editors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          serviceID
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        item {
          id
          serviceID
          name
          codeName
          description
          image
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createAuction = /* GraphQL */ `
  mutation CreateAuction(
    $input: CreateAuctionInput!
    $condition: ModelAuctionConditionInput
  ) {
    createAuction(input: $input, condition: $condition) {
      id
      auctionID
      userID
      createdAt
      startDateTime
      endDateTime
      collection {
        date
        time
        type
        address
        driver {
          userID
          firstName
          lastName
          title
          email
          phoneNumber
          __typename
        }
        instructions
        __typename
      }
      delivery {
        date
        time
        type
        address
        driver {
          userID
          firstName
          lastName
          title
          email
          phoneNumber
          __typename
        }
        instructions
        __typename
      }
      postcode
      isClosed
      total
      items {
        items {
          id
          auctionID
          serviceID
          name
          description
          quantity
          image
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bids {
        items {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      shops {
        items {
          id
          shopID
          auctionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateAuction = /* GraphQL */ `
  mutation UpdateAuction(
    $input: UpdateAuctionInput!
    $condition: ModelAuctionConditionInput
  ) {
    updateAuction(input: $input, condition: $condition) {
      id
      auctionID
      userID
      createdAt
      startDateTime
      endDateTime
      collection {
        date
        time
        type
        address
        driver {
          userID
          firstName
          lastName
          title
          email
          phoneNumber
          __typename
        }
        instructions
        __typename
      }
      delivery {
        date
        time
        type
        address
        driver {
          userID
          firstName
          lastName
          title
          email
          phoneNumber
          __typename
        }
        instructions
        __typename
      }
      postcode
      isClosed
      total
      items {
        items {
          id
          auctionID
          serviceID
          name
          description
          quantity
          image
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bids {
        items {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      shops {
        items {
          id
          shopID
          auctionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteAuction = /* GraphQL */ `
  mutation DeleteAuction(
    $input: DeleteAuctionInput!
    $condition: ModelAuctionConditionInput
  ) {
    deleteAuction(input: $input, condition: $condition) {
      id
      auctionID
      userID
      createdAt
      startDateTime
      endDateTime
      collection {
        date
        time
        type
        address
        driver {
          userID
          firstName
          lastName
          title
          email
          phoneNumber
          __typename
        }
        instructions
        __typename
      }
      delivery {
        date
        time
        type
        address
        driver {
          userID
          firstName
          lastName
          title
          email
          phoneNumber
          __typename
        }
        instructions
        __typename
      }
      postcode
      isClosed
      total
      items {
        items {
          id
          auctionID
          serviceID
          name
          description
          quantity
          image
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bids {
        items {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      shops {
        items {
          id
          shopID
          auctionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const createAuctionItem = /* GraphQL */ `
  mutation CreateAuctionItem(
    $input: CreateAuctionItemInput!
    $condition: ModelAuctionItemConditionInput
  ) {
    createAuctionItem(input: $input, condition: $condition) {
      id
      auctionID
      serviceID
      name
      description
      quantity
      image
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      service {
        id
        serviceID
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        photoGallaryImages {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateAuctionItem = /* GraphQL */ `
  mutation UpdateAuctionItem(
    $input: UpdateAuctionItemInput!
    $condition: ModelAuctionItemConditionInput
  ) {
    updateAuctionItem(input: $input, condition: $condition) {
      id
      auctionID
      serviceID
      name
      description
      quantity
      image
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      service {
        id
        serviceID
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        photoGallaryImages {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteAuctionItem = /* GraphQL */ `
  mutation DeleteAuctionItem(
    $input: DeleteAuctionItemInput!
    $condition: ModelAuctionItemConditionInput
  ) {
    deleteAuctionItem(input: $input, condition: $condition) {
      id
      auctionID
      serviceID
      name
      description
      quantity
      image
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      service {
        id
        serviceID
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        photoGallaryImages {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createBid = /* GraphQL */ `
  mutation CreateBid(
    $input: CreateBidInput!
    $condition: ModelBidConditionInput
  ) {
    createBid(input: $input, condition: $condition) {
      id
      auctionID
      shopID
      createdBy
      createdAt
      totalPrice
      shippingCharges
      items {
        items {
          id
          bidID
          auctionItemID
          price
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          shopID
          orderID
          bidID
          userID
          status
          createdAt
          updatedAt
          updatedBy
          paymentStatus
          paymentMethod
          orderCancelOrDeclineReason
          total
          variableTotal
          discount
          tax
          noOfPieces
          paidAmount
          onHoldPaymentIntentID
          onHoldPaymentAmount
          coupons
          collectionAndDeliveryFee
          itemizedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      auction {
        id
        auctionID
        userID
        createdAt
        startDateTime
        endDateTime
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        postcode
        isClosed
        total
        items {
          nextToken
          startedAt
          __typename
        }
        bids {
          nextToken
          startedAt
          __typename
        }
        shops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        user {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateBid = /* GraphQL */ `
  mutation UpdateBid(
    $input: UpdateBidInput!
    $condition: ModelBidConditionInput
  ) {
    updateBid(input: $input, condition: $condition) {
      id
      auctionID
      shopID
      createdBy
      createdAt
      totalPrice
      shippingCharges
      items {
        items {
          id
          bidID
          auctionItemID
          price
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          shopID
          orderID
          bidID
          userID
          status
          createdAt
          updatedAt
          updatedBy
          paymentStatus
          paymentMethod
          orderCancelOrDeclineReason
          total
          variableTotal
          discount
          tax
          noOfPieces
          paidAmount
          onHoldPaymentIntentID
          onHoldPaymentAmount
          coupons
          collectionAndDeliveryFee
          itemizedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      auction {
        id
        auctionID
        userID
        createdAt
        startDateTime
        endDateTime
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        postcode
        isClosed
        total
        items {
          nextToken
          startedAt
          __typename
        }
        bids {
          nextToken
          startedAt
          __typename
        }
        shops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        user {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteBid = /* GraphQL */ `
  mutation DeleteBid(
    $input: DeleteBidInput!
    $condition: ModelBidConditionInput
  ) {
    deleteBid(input: $input, condition: $condition) {
      id
      auctionID
      shopID
      createdBy
      createdAt
      totalPrice
      shippingCharges
      items {
        items {
          id
          bidID
          auctionItemID
          price
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          shopID
          orderID
          bidID
          userID
          status
          createdAt
          updatedAt
          updatedBy
          paymentStatus
          paymentMethod
          orderCancelOrDeclineReason
          total
          variableTotal
          discount
          tax
          noOfPieces
          paidAmount
          onHoldPaymentIntentID
          onHoldPaymentAmount
          coupons
          collectionAndDeliveryFee
          itemizedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      auction {
        id
        auctionID
        userID
        createdAt
        startDateTime
        endDateTime
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        postcode
        isClosed
        total
        items {
          nextToken
          startedAt
          __typename
        }
        bids {
          nextToken
          startedAt
          __typename
        }
        shops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        user {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createBidItem = /* GraphQL */ `
  mutation CreateBidItem(
    $input: CreateBidItemInput!
    $condition: ModelBidItemConditionInput
  ) {
    createBidItem(input: $input, condition: $condition) {
      id
      bidID
      auctionItemID
      price
      bid {
        id
        auctionID
        shopID
        createdBy
        createdAt
        totalPrice
        shippingCharges
        items {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        auction {
          id
          auctionID
          userID
          createdAt
          startDateTime
          endDateTime
          postcode
          isClosed
          total
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBidItem = /* GraphQL */ `
  mutation UpdateBidItem(
    $input: UpdateBidItemInput!
    $condition: ModelBidItemConditionInput
  ) {
    updateBidItem(input: $input, condition: $condition) {
      id
      bidID
      auctionItemID
      price
      bid {
        id
        auctionID
        shopID
        createdBy
        createdAt
        totalPrice
        shippingCharges
        items {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        auction {
          id
          auctionID
          userID
          createdAt
          startDateTime
          endDateTime
          postcode
          isClosed
          total
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBidItem = /* GraphQL */ `
  mutation DeleteBidItem(
    $input: DeleteBidItemInput!
    $condition: ModelBidItemConditionInput
  ) {
    deleteBidItem(input: $input, condition: $condition) {
      id
      bidID
      auctionItemID
      price
      bid {
        id
        auctionID
        shopID
        createdBy
        createdAt
        totalPrice
        shippingCharges
        items {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        auction {
          id
          auctionID
          userID
          createdAt
          startDateTime
          endDateTime
          postcode
          isClosed
          total
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAuctionShop = /* GraphQL */ `
  mutation CreateAuctionShop(
    $input: CreateAuctionShopInput!
    $condition: ModelAuctionShopConditionInput
  ) {
    createAuctionShop(input: $input, condition: $condition) {
      id
      shopID
      auctionID
      auction {
        id
        auctionID
        userID
        createdAt
        startDateTime
        endDateTime
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        postcode
        isClosed
        total
        items {
          nextToken
          startedAt
          __typename
        }
        bids {
          nextToken
          startedAt
          __typename
        }
        shops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        user {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteAuctionShop = /* GraphQL */ `
  mutation DeleteAuctionShop(
    $input: DeleteAuctionShopInput!
    $condition: ModelAuctionShopConditionInput
  ) {
    deleteAuctionShop(input: $input, condition: $condition) {
      id
      shopID
      auctionID
      auction {
        id
        auctionID
        userID
        createdAt
        startDateTime
        endDateTime
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        postcode
        isClosed
        total
        items {
          nextToken
          startedAt
          __typename
        }
        bids {
          nextToken
          startedAt
          __typename
        }
        shops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        user {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createFavouriteShop = /* GraphQL */ `
  mutation CreateFavouriteShop(
    $input: CreateFavouriteShopInput!
    $condition: ModelFavouriteShopConditionInput
  ) {
    createFavouriteShop(input: $input, condition: $condition) {
      id
      shopID
      userID
      isFavourite
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateFavouriteShop = /* GraphQL */ `
  mutation UpdateFavouriteShop(
    $input: UpdateFavouriteShopInput!
    $condition: ModelFavouriteShopConditionInput
  ) {
    updateFavouriteShop(input: $input, condition: $condition) {
      id
      shopID
      userID
      isFavourite
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const addShop = /* GraphQL */ `
  mutation AddShop($input: ShopInput!) {
    addShop(input: $input) {
      id
      name
      googlePlaceId
      contactName
      address
      postcode
      town
      country
      email
      phoneNumber
      location {
        lat
        lng
        __typename
      }
      workHrs {
        closeDay
        openDay
        closeTime
        openTime
        __typename
      }
      collectionHrs {
        closeDay
        openDay
        closeTime
        openTime
        __typename
      }
      deliveryHrs {
        closeDay
        openDay
        closeTime
        openTime
        __typename
      }
      image
      minPriceForFree
      distanceRangeForFree
      distanceRange
      standardDeliveryFee
      deliveryLocations
      AsapDeliveryFee
      language
      range
      shopID
      about
      documents {
        name
        documentType
        documentURL
        description
        MIMETypes
        status
        __typename
      }
      photos {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
        serviceID
        __typename
      }
      primaryPhoto {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
        serviceID
        __typename
      }
      videos {
        name
        videoURL
        autoPlay
        thumbURL
        description
        MIMETypes
        status
        __typename
      }
      variant
      status
      isLive
      consentLetterCount
      consentLetterLastDate
      enableAsapDelivery
      collectionInstructions
      deliveryInstructions
      websiteLink
      paymentMethods
      ocrTemplateName
      totalRatingID
      createdBy
      updatedBy
      createdAt
      auctions {
        items {
          id
          shopID
          auctionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          codeName
          noOfPieces
          description
          unit
          price
          priceRange
          image
          tags
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      users {
        items {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      reviews {
        items {
          id
          shopID
          userID
          orderID
          replyID
          title
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      coupons {
        items {
          id
          shopID
          productID
          title
          description
          startDateTime
          endDateTime
          maxUsesPerUser
          discountType
          discountAmount
          discountUnit
          conditionAmount
          conditionUnit
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      rating {
        id
        rating
        ratingsCount
        ratingsSum
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createShopSiteRedirectRecord = /* GraphQL */ `
  mutation CreateShopSiteRedirectRecord($shopID: String!) {
    createShopSiteRedirectRecord(shopID: $shopID)
  }
`;
export const createMessageNotification = /* GraphQL */ `
  mutation CreateMessageNotification(
    $input: CreateMessageNotificationInput!
    $condition: ModelMessageNotificationConditionInput
  ) {
    createMessageNotification(input: $input, condition: $condition) {
      id
      userID
      shopID
      orderID
      messageID
      isRead
      createdAt
      updatedAt
      message {
        id
        orderID
        sender
        shopID
        message
        messageIntent
        photo {
          name
          photoURL
          thumbURL
          MIMETypes
          __typename
        }
        status
        notificationID
        readAt
        createdAt
        order {
          id
          shopID
          orderID
          bidID
          userID
          status
          createdAt
          updatedAt
          updatedBy
          paymentStatus
          paymentMethod
          orderCancelOrDeclineReason
          total
          variableTotal
          discount
          tax
          noOfPieces
          paidAmount
          onHoldPaymentIntentID
          onHoldPaymentAmount
          coupons
          collectionAndDeliveryFee
          itemizedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        notification {
          id
          userID
          shopID
          orderID
          messageID
          isRead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        senderDetails {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      userID
      shopID
      message
      isRead
      type {
        variant
        modelID
        model
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRedirect = /* GraphQL */ `
  mutation CreateRedirect(
    $input: CreateRedirectInput!
    $condition: ModelRedirectConditionInput
  ) {
    createRedirect(input: $input, condition: $condition) {
      id
      shopID
      userID
      event
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
  }
`;
