import React from 'react';
import {
  List,
  ListItem,
  Collapse,
  Typography,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';
import FilterItems from './FilterItems';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '14px',
    fontWeight: '700',
    color: 'black'
  },
  subFiltersList: {
    padding: 0,
    paddingLeft: '0.25rem'
  }
}));

const Filter = ({
  data = { name: '', items: [], id: '', filterName: '', stackItems: false },
  open,
  setOpen,
  shopFilters,
  setShopFilters
}) => {
  const classes = useStyles();

  const updateShopFilters = (id) => (e) => {
    const currentItem = data.items.find((_item) => _item.id === id);
    let removeIds = [id];

    // for removing filter items and subfilter items from services filter
    if (
      currentItem &&
      !e.target.checked &&
      data.filterName === 'services' &&
      currentItem.subFilters?.length > 0
    ) {
      const getIDsCallback = (_item) => {
        removeIds.push(_item.id);
        _item.subFilters?.length > 0 &&
          _item.subFilters.forEach(getIDsCallback);
      };
      currentItem.subFilters.forEach(getIDsCallback);
    }

    // for updating filters
    e.target.checked
      ? setShopFilters({
          ...shopFilters,
          [data.filterName]: data.stackItems
            ? [...shopFilters[data.filterName], id]
            : [id]
        })
      : setShopFilters({
          ...shopFilters,
          [data.filterName]: shopFilters[data.filterName].filter(
            (item) => !removeIds.includes(item)
          )
        });
  };

  const handleCollapseClick = () =>
    setOpen({ ...open, [data.id]: !open[data.id] });

  return (
    <>
      <ListItem
        style={{ width: '100%' }}
        disableGutters
        button
        onClick={handleCollapseClick}>
        <ListItemText
          primary={
            <Typography className={classes.title}>{data.name}</Typography>
          }
        />
        <ListItemSecondaryAction
          onClick={handleCollapseClick}
          style={{ top: '58%', right: 0 }}>
          {open[data.id] ? <ExpandMore /> : <ChevronRightIcon />}
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open[data.id]}>
        <FilterItems
          filterData={data}
          shopFilters={shopFilters}
          updateShopFilters={updateShopFilters}
        />

        {data.subFilters?.length > 0 ? (
          <List className={classes.subFiltersList}>
            {data.subFilters.map((_filter, index) => (
              <Filter
                key={`sub-filters-for-${data.id}-${index}`}
                data={_filter}
                open={open}
                setOpen={setOpen}
                shopFilters={shopFilters}
                setShopFilters={setShopFilters}
              />
            ))}
          </List>
        ) : null}
      </Collapse>
    </>
  );
};

export default Filter;
