import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button
} from '@material-ui/core';

const ShopDeleteDialog = ({
  open,
  handleClose,
  handleSuccess,
  noOfShops = 0
}) => (
  <Dialog onClose={handleClose} open={open}>
    <DialogTitle>Delete Shop</DialogTitle>
    <DialogContent>
      <DialogContentText>{`Are you sure you want to delete ${
        noOfShops > 0 ? 'these shops' : 'this shop'
      }?`}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color="secondary" onClick={handleClose} variant="outlined">
        No
      </Button>
      <Button color="primary" onClick={handleSuccess} variant="outlined">
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);

export default ShopDeleteDialog;
