import React from 'react';
import {
  Grid,
  Table,
  TableBody,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  showPrice,
  capitalizeStr,
  inWords,
  unitFormatter,
  getFormattedPriceRange
} from 'common/utilFunctions';
import OrderDetailInvoice from './OrderDetailInvoice';

const useStyles = makeStyles({
  root: {
    borderTop: '1px solid #c2c2c2',
    padding: '1rem 0'
  },
  orderItems: {
    borderBottom: '1px solid #c2c2c2',
    padding: '0.3rem 1rem'
  },
  spacingTopAndBottom: {
    padding: '0.2rem 0'
  },
  paymentPaidTextStatus: {
    padding: '0.5rem',
    fontWeight: 'bold'
  }
});

const MobileOrderDetailDialog = ({
  order,
  partiallyPaidRecord,
  prevTotal,
  sm = false
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      {order.orderList.length > 0
        ? order.orderList.map((item) => {
            return (
              <Grid
                key={item.productID}
                container
                item
                alignItems="center"
                className={classes.orderItems}>
                <Grid item xs={4} container direction="column">
                  <Grid item>
                    <Typography
                      variant="h6"
                      className={classes.spacingTopAndBottom}>
                      {item.name}
                    </Typography>
                  </Grid>
                  {!order.bidID ? (
                    <Grid item>
                      <Typography
                        variant="body1"
                        className={classes.spacingTopAndBottom}>
                        {`${
                          item.price
                            ? showPrice(item.price)
                            : getFormattedPriceRange(item.priceRange)
                        } ${unitFormatter(item.unit)}`.toLowerCase()}
                      </Typography>
                    </Grid>
                  ) : null}
                  {!!(item.discount > 0 || item.overallDiscount > 0) ? (
                    <Grid item>
                      <Typography
                        variant="body1"
                        className={classes.spacingTopAndBottom}>
                        Discount:{' '}
                        {item.discount
                          ? `${showPrice(item.discount || 0)} ${unitFormatter(
                              item.unit
                            )}`.toLowerCase()
                          : item.overallDiscount
                          ? `${showPrice(item.overallDiscount)}`
                          : 0}
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    align="right"
                    variant="h6"
                    className={classes.spacingTopAndBottom}>
                    QTY: {item.quantity}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    align="right"
                    variant="h6"
                    className={classes.spacingTopAndBottom}>
                    {item.totalPrice
                      ? showPrice(item.totalPrice)
                      : item.price
                      ? showPrice(
                          item.price * item.quantity -
                            (item.overallDiscount || 0)
                        )
                      : getFormattedPriceRange(item.priceRange, item.quantity)}
                  </Typography>
                </Grid>
              </Grid>
            );
          })
        : null}
      <Table>
        <TableBody>
          <OrderDetailInvoice
            order={order}
            partiallyPaidRecord={partiallyPaidRecord}
            prevTotal={prevTotal}
            sm={sm}
          />
        </TableBody>
      </Table>
      {order.paymentStatus === 'paid' ? (
        <Grid container item justify="center">
          <Typography align="center" className={classes.paymentPaidTextStatus}>
            A {unitFormatter(order.paymentMethod)} Payment of{' '}
            {capitalizeStr(inWords(order?.total))} is Paid.
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default MobileOrderDetailDialog;
