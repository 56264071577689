import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import {
  showPrice,
  getOrderTotal,
  getCollectionAndDeliveryFeeText,
  getCollectionAndDeliveryFee
} from 'common/utilFunctions';

const useStyles = makeStyles((theme) => ({
  orderDetailsRoot: {
    width: '100%',
    backgroundColor: '#fff',
    padding: '0 2rem 0',
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem 0'
    }
  },
  orderDetailsHeaderContainer: {
    padding: '1rem 1rem 0',
    gap: '0.3rem'
  },
  orderDetailsItemListContainer: {
    gap: '0.5rem',
    borderTop: '1px solid #77777780',
    paddingTop: '1rem',
    margin: '1rem 1rem 0'
  },
  boldFont: {
    fontWeight: 600
  },
  orderItemContainer: {
    padding: '0.5rem 1rem',
    boxShadow:
      'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px',
    gap: '0.5rem',
    borderRadius: '8px'
  },
  orderItem: {
    fontSize: 14
  },
  subTotal: {
    fontWeight: 600,
    fontSize: 14
  },
  fontSizeSmall: {
    fontSize: 14
  },
  totalPrice: {
    fontWeight: 600,
    fontSize: 18,
    color: '#194376'
  },
  marginBottom: {
    marginBottom: '1rem'
  },
  marginBottomSmall: {
    marginBottom: '0.5rem'
  },
  divider: {
    borderTop: '1px solid #77777780',
    paddingTop: '1rem',
    marginBottom: '1rem'
  }
}));

const OrderDetails = ({ order }) => {
  const classes = useStyles();
  const paymentDue =
    Math.round(((order?.total || 0) - (order?.paidAmount || 0)) * 100) / 100;

  return (
    <Grid container className={classes.orderDetailsRoot}>
      <Grid container className={classes.orderDetailsHeaderContainer}>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            className={classes.fontSizeSmall}
            style={{ fontWeight: 600 }}>
            Order - #{order?.orderID}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.boldFont}>
            Payment Due: {showPrice(paymentDue)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.orderDetailsItemListContainer}>
        {order?.orderList.map((item, index) => (
          <Grid
            container
            item
            justify="space-between"
            alignItems="center"
            key={index}
            className={classes.orderItemContainer}>
            <Grid item>
              <Typography
                className={classes.orderItem}
                style={{ fontWeight: 600, marginBottom: '2px' }}>
                {item.name}
              </Typography>
              <Typography variant="body2" className={classes.orderItem}>
                Qty: {item.quantity}
              </Typography>
            </Grid>

            <Grid item>
              <Typography className={classes.orderItem}>
                {showPrice(
                  item.price * item.quantity - (item.overallDiscount || 0)
                )}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Grid
        container
        style={{
          padding: '1rem'
        }}>
        <Grid container justify="space-between" className={classes.divider}>
          <Grid item>
            <Typography className={classes.subTotal}>Sub Total</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.subTotal}>
              {order && showPrice(getOrderTotal(order) || 0)}
            </Typography>
          </Grid>
        </Grid>

        {order?.coupons?.length > 0 ? (
          <Grid container className={classes.marginBottom}>
            <Typography
              variant="body1"
              style={{ fontSize: 15, marginBottom: '5px' }}>
              Coupons
            </Typography>
            {order?.coupons.map((_item, index) => {
              const orderCoupon = JSON.parse(_item);
              return (
                <Grid
                  container
                  justify="space-between"
                  key={`order-coupons-${orderCoupon.id || ''}-items-${index}`}>
                  <Typography variant="body2" style={{ fontSize: 13 }}>
                    {orderCoupon.title || ''}
                  </Typography>
                  <Typography variant="body2" style={{ fontSize: 13 }}>
                    - {showPrice(orderCoupon.discountAmountApplied || 0)}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        ) : null}

        {process.env.REACT_APP_VAT === 'enabled' ? (
          <Grid
            container
            justify="space-between"
            className={classes.marginBottomSmall}>
            <Grid item>
              <Typography className={classes.fontSizeSmall}>
                VAT (20%)
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.fontSizeSmall}>
                {showPrice(order?.tax || 0)}
              </Typography>
            </Grid>
          </Grid>
        ) : null}

        <Grid
          container
          justify="space-between"
          className={classes.marginBottom}>
          <Typography variant="h6" className={classes.fontSizeSmall}>
            {getCollectionAndDeliveryFeeText(order)}
          </Typography>
          <Typography variant="h6" className={classes.fontSizeSmall}>
            {getCollectionAndDeliveryFee(order)}
          </Typography>
        </Grid>

        <Grid container justify="space-between" className={classes.divider}>
          <Grid item>
            <Typography className={classes.totalPrice}>Total</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.totalPrice}>
              {showPrice(order?.total || 0)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderDetails;
