import React from 'react';
import { Divider, Grid, Typography, makeStyles } from '@material-ui/core';
import { StyledRating } from 'components/molecules';
import { RATING_CATEGORIES } from 'common/constants';
import LinearProgressWithLabel from './LinearProgressWithLabel';
import { STAR_VALUE_TO_PERCENTAGE } from 'common/constants';

const useStyles = makeStyles((theme) => ({
  customerReviewsBox: {
    borderBottom: '1px solid #C4C4C4',
    paddingBottom: '35px',
    marginBottom: '35px'
  },
  starCount: {
    fontWeight: 600,
    color: '#edd242'
  },
  totalRating: {
    marginTop: '2.625rem',
    paddingRight: '1rem',
    marginBottom: '2.25rem'
  }
}));

const ShopReviewsMatrix = ({ totalRating, reviews, shopReviews }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.customerReviewsBox}>
      <Typography variant="h3" id="anchor-to-shop-reviews-footer">
        Ratings & Reviews
      </Typography>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.totalRating}>
        <Typography variant="h4" className={classes.starCount}>
          {totalRating}
        </Typography>
        <StyledRating
          name="read-only-customer-rating"
          value={totalRating}
          readOnly
        />
        <Divider orientation="vertical" flexItem />
        <Typography
          style={{
            fontWeight: '450'
          }}>
          {reviews.length} Reviews
        </Typography>
      </Grid>
      {shopReviews.length > 0
        ? RATING_CATEGORIES.map((item) => ({
            label: item,
            value:
              parseFloat(
                (
                  shopReviews.reduce(
                    (total, review) =>
                      total +
                        review.ratings?.find((rating) => rating?.title === item)
                          ?.rating || 0,
                    0
                  ) / (shopReviews.length || 1)
                ).toFixed(1)
              ) * STAR_VALUE_TO_PERCENTAGE
          })).map((item, index) => (
            <Grid
              container
              direction="column"
              style={{ marginBottom: '20px' }}
              key={`shop-detail-cutomer-reviews-categories-items-${index}`}>
              <Typography>{item.label}</Typography>
              <LinearProgressWithLabel value={item.value} />
            </Grid>
          ))
        : null}
    </Grid>
  );
};

export default ShopReviewsMatrix;
