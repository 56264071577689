import React, { useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  getFormattedName,
  getFormattedPhoneNumber
} from 'common/utilFunctions';
import { CustomSearchBar } from 'components/organisms';
import { useShops } from 'context/shop/shopsContext';
import { PDFDownloadView } from 'components/organisms';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  root: {},
  filterField: {
    padding: '0.5rem'
  },
  tableContainer: {
    minWidth: '1000px'
  }
}));

const Marketing = ({
  salesUsers = [],
  dateRange = { from: '', to: '' },
  setDateRange
}) => {
  const classes = useStyles();
  const [currentUser, setCurrentUser] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const [showShops, setShowShops] = useState(false);
  const [showPDFs, setShowPDFs] = useState(false);
  const [selectedShops, setSelectedShops] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { enqueueSnackbar } = useSnackbar();
  const { shops, isMoreShopsAvailable, dispatch } = useShops();

  const getDateRange = () => {
    const toDate = new Date(dateRange.to);
    toDate.setDate(toDate.getDate() + 1);
    return {
      startDate: dateRange.from,
      endDate: toDate.toISOString().split('T').at(0)
    };
  };

  const loadShops = (newQuery = false) => {
    const payload = {
      status: 'pending',
      salesUser: currentUser,
      searchString: searchBy,
      newQuery
    };
    if (dateRange.from && dateRange.to)
      payload['dateRangeData'] = getDateRange();

    dispatch({ type: 'getShops', payload });
    setShowShops(true);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handlePageChange = (_event, page) => {
    isMoreShopsAvailable && shops.length <= page * rowsPerPage && loadShops();
    setPage(page);
  };

  const updateDate = (e) =>
    setDateRange({ ...dateRange, [e.target.name]: e.target.value });

  const handleSearch = () => {
    if (
      (dateRange.from && !dateRange.to) ||
      (!dateRange.from && dateRange.to) ||
      (dateRange.from && dateRange.to && dateRange.from > dateRange.to)
    ) {
      enqueueSnackbar('enter valid date range', {
        variant: 'error',
        persist: true
      });
      return;
    }
    setPage(0);
    loadShops(true);
  };

  const onSearchChange = (event) => {
    setSearchBy(event.target.value);
    if (event.key === 'Enter') handleSearch();
  };

  const getSalesPersonForShop = (userID) =>
    salesUsers.find((item) => item.id === userID);

  const onShopCheck = (shopID) => (e) =>
    e.target.checked
      ? setSelectedShops([...selectedShops, shopID])
      : setSelectedShops(selectedShops.filter((item) => item !== shopID));

  const onAllShopCheck = (e) =>
    e.target.checked
      ? setSelectedShops(
          shops
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((shop) => shop.id)
        )
      : setSelectedShops([]);

  const handleGenerateClick = async () => {
    dispatch({
      type: 'updateShopsConsentLetterCount',
      payload: {
        shops: shops.filter((shop) => selectedShops.includes(shop.id))
      }
    });
    setShowPDFs(true);
  };

  return (
    <Grid container className={classes.root}>
      {showPDFs ? (
        <PDFDownloadView
          title="Consent Letters"
          shops={shops.filter((shop) => selectedShops.includes(shop.id))}
          handleCancel={() => setShowPDFs(false)}
        />
      ) : (
        <>
          <Grid item xs={12} container justify="space-between">
            <Typography variant="h3">Explore leads</Typography>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={selectedShops.length === 0}
              onClick={handleGenerateClick}>
              generate consent letters
            </Button>
          </Grid>
          <Grid
            container
            item
            xs={12}
            alignItems="flex-end"
            style={{ marginTop: '1.25rem', marginBottom: '0.5rem' }}>
            <Typography variant="subtitle2">
              Search & Filter Pending Shops
            </Typography>
          </Grid>
          <Grid container item xs={12} justify="center" alignItems="center">
            <Grid item xs={12} md={2} sm={6} className={classes.filterField}>
              <TextField
                type="date"
                variant="outlined"
                label="From"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={dateRange.from}
                inputProps={{
                  max: new Date().toISOString().split('T').at(0)
                }}
                name="from"
                fullWidth
                onChange={updateDate}
              />
            </Grid>
            <Grid item xs={12} md={2} sm={6} className={classes.filterField}>
              <TextField
                type="date"
                variant="outlined"
                label="To"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={dateRange.to}
                inputProps={{
                  max: new Date().toISOString().split('T').at(0)
                }}
                name="to"
                fullWidth
                onChange={updateDate}
              />
            </Grid>
            <Grid item xs={12} md={3} sm={6} className={classes.filterField}>
              <CustomSearchBar
                value={searchBy}
                onChange={onSearchChange}
                onKeyDown={onSearchChange}
                placeholder="id / name / postcode"
                onClear={() => setSearchBy('')}
              />
            </Grid>
            <Grid item xs={12} md={3} sm={6} className={classes.filterField}>
              <TextField
                select
                variant="outlined"
                value={currentUser}
                onChange={(e) => setCurrentUser(e.target.value)}
                size="small"
                label="select facility manager"
                fullWidth>
                <MenuItem value={''}>
                  <em>None</em>
                </MenuItem>
                {salesUsers.map((item, index) => (
                  <MenuItem
                    key={`sales-user-dropdown-items-${index}`}
                    value={item?.id || ''}>
                    {getFormattedName(item, false)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={2} className={classes.filterField}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
                fullWidth
                startIcon={<SearchIcon />}>
                search
              </Button>
            </Grid>
          </Grid>
          {showShops && (
            <Grid item xs={12} style={{ marginTop: '1.5rem' }}>
              <Card>
                <CardHeader
                  title='"Pending" Shops'
                  subheader={
                    shops.length > 0
                      ? `${shops.length} shops found`
                      : 'No results found'
                  }
                />
                {shops.length > 0 && (
                  <CardContent>
                    <PerfectScrollbar>
                      <Grid container className={classes.tableContainer}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Checkbox
                                  checked={
                                    shops.length > 0 &&
                                    shops.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    ).length === selectedShops.length &&
                                    shops
                                      .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                      )
                                      .every((item) =>
                                        selectedShops.includes(item.id)
                                      )
                                  }
                                  onChange={onAllShopCheck}
                                />
                              </TableCell>
                              <TableCell>Shop Id</TableCell>
                              <TableCell>Shop Name</TableCell>
                              <TableCell>Address</TableCell>
                              <TableCell>Shop Contact</TableCell>
                              <TableCell>Created Date</TableCell>
                              <TableCell>Facility Manager</TableCell>
                              <TableCell>Consent Letter Count</TableCell>
                              <TableCell>Last Generated Date</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {shops
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((Shop) => (
                                <TableRow
                                  hover
                                  key={`marketing-leads-shops-items-${Shop.id}`}>
                                  <TableCell>
                                    <Checkbox
                                      checked={selectedShops.some(
                                        (item) => item === Shop.id
                                      )}
                                      onChange={onShopCheck(Shop.id)}
                                    />
                                  </TableCell>
                                  <TableCell>{Shop.shopID}</TableCell>
                                  <TableCell>
                                    <Typography variant="body1">
                                      {Shop.name}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    {Shop?.address}, {Shop?.town},{' '}
                                    {Shop?.postcode}
                                  </TableCell>
                                  <TableCell>
                                    {Shop.contactName}, <br /> {Shop.email},{' '}
                                    <br />
                                    {getFormattedPhoneNumber(Shop.phoneNumber)}
                                  </TableCell>
                                  <TableCell>
                                    {Shop.createdAt
                                      ? moment(Shop.createdAt).format(
                                          'DD/MM/YYYY'
                                        )
                                      : 'N/A'}
                                  </TableCell>
                                  <TableCell>
                                    {Shop.createdBy &&
                                    getSalesPersonForShop(Shop.createdBy)
                                      ? getFormattedName(
                                          getSalesPersonForShop(Shop.createdBy)
                                        )
                                      : 'N/A'}
                                  </TableCell>
                                  <TableCell>
                                    {Shop?.consentLetterCount || 0}
                                  </TableCell>
                                  <TableCell>
                                    {Shop.consentLetterLastDate
                                      ? moment(
                                          Shop.consentLetterLastDate
                                        ).format('DD/MM/YYYY')
                                      : 'N/A'}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </Grid>
                    </PerfectScrollbar>
                    <TablePagination
                      component="div"
                      count={isMoreShopsAvailable ? -1 : shops.length}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[5, 10, 25]}
                    />
                  </CardContent>
                )}
              </Card>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default Marketing;
