import React from 'react';
import { Image } from 'components/organisms';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    height: 'calc(100vw * 1.125)',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vw * 0.375)'
    },
    [theme.breakpoints.up('md')]: {
      height: 'calc(90vw * 0.375)'
    }
  },
  image: {
    objectFit: 'fill',
    [theme.breakpoints.up('sm')]: {
      objectFit: 'contain'
    }
  }
}));
const Template5 = ({
  content = {
    image: ''
  },
  className = ''
}) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles();

  return (
    <Grid container item justify="center" xs={12} className={classes.root}>
      {sm ? (
        <Image
          docKey={content?.image || ''}
          loadFromStorage={content?.image?.split('/').at(0) === 'images'}
          height="100%"
          width="100%"
          alt="Advertisement image"
          className={clsx(className, classes.image)}
        />
      ) : (
        <Image
          docKey={content?.mobileImage || ''}
          loadFromStorage={content?.mobileImage?.split('/').at(0) === 'images'}
          height="100%"
          width="100%"
          alt="Advertisement image"
          className={clsx(className, classes.image)}
        />
      )}
    </Grid>
  );
};

export default Template5;
