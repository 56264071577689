import React, { useState, useEffect } from 'react';
import ShopFilters from './ShopFilters';
import ShopsCarousel from './ShopsCarousel';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  IconButton,
  Drawer,
  useMediaQuery,
  Button,
  Link,
  Typography
} from '@material-ui/core';
import { googleFormLinks } from 'common/constants';
import { useFavouriteShops } from 'context/favouriteShops/favouriteShopsContext';
import { useServices } from 'views/Services/serviceContext';
import { useAmplifyAuth } from 'context';
import { ShopListShopCardSkeleton } from 'components/skeletons';
import { CustomSearchBar } from 'components/organisms';
import filterIcon from '../assets/filterIcon.svg';
import FilterListIcon from '@material-ui/icons/FilterList';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    margin: '0 auto',
    padding: '1.5rem',
    [theme.breakpoints.up('md')]: {
      padding: '3rem 0',
      maxWidth: '90%'
    },
    overflowX: 'hidden'
  },
  title: {
    fontWeight: 'Bold',
    margin: theme.spacing(4, 0)
  },
  divider: {
    color: theme.palette.divider
  },
  content: {
    height: 'fit-content',
    padding: 0,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '3.5rem'
    }
  },
  clearAll: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  searchBox: {
    borderRadius: '10px'
  },
  toggleDrawerBtn: {
    display: 'none', // FIXME REMOVE LATER
    zIndex: 999, // FIXME REMOVE LATER
    position: 'fixed',
    top: '50%',
    right: '5%',
    backgroundColor: theme.palette.yellow.main
  },
  saveBtn: {
    color: 'black',
    jbackgroundColor: theme.palette.yellow.main
  },
  filtersContainer: {
    marginBottom: '1rem'
  },
  filterBtn: {
    backgroundColor: '#1f6893',
    color: '#fff',
    letterSpacing: 1,
    fontSize: 18,
    borderRadius: 10
  },
  filterIcon: {
    fontSize: '2rem'
  },
  iconButtonContainer: {
    padding: '0 0.5rem'
  }
}));

const ShopsViews = (props) => {
  const {
    shops = [],
    shopFilters,
    setShopFilters,
    isShopsLoading = false,
    resetFilters = () => {},
    showResetFiltersBtn = false
  } = props;
  const [servicesWithShops, setServicesWithShops] = useState([]);
  const [filteredServicesWithShops, setFilteredServicesWithShops] = useState(
    []
  );
  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchName, setSearchName] = useState('');
  const { services = [] } = useServices();
  let {
    state: { user }
  } = useAmplifyAuth();
  const { favouriteShops, dispatch } = useFavouriteShops();
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const smDown = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();

  useEffect(() => {
    let mounted = true;
    const data = services.map((_service) => ({
      ..._service,
      shops: shops.filter(
        (shop) =>
          [
            ...shop.products?.items?.filter(
              (item) => item.serviceID === _service.id
            )
          ].length !== 0
      )
    }));
    mounted &&
      setServicesWithShops([{ name: 'All', shops, id: 'all' }, ...data]);

    return () => {
      mounted = false;
    };
  }, [services, shops]);

  useEffect(() => {
    let mounted = true;

    // getting services with shops filtered
    const newServicesWithShops = servicesWithShops.map((_service) => ({
      ..._service,
      shops: !!searchName
        ? _service.shops.filter((_shop) =>
            _shop.name.toLowerCase().includes(searchName.toLowerCase())
          )
        : _service.shops
    }));
    const showServices = newServicesWithShops.some(
      (_service) => _service.shops.length > 0
    );

    mounted &&
      setFilteredServicesWithShops(
        shopFilters.services.length > 0
          ? newServicesWithShops.filter((_service) =>
              shopFilters.services.includes(_service.id)
            )
          : !!(!!searchName && showServices)
          ? newServicesWithShops
          : newServicesWithShops.filter((_item) => _item.shops.length > 0)
      );

    return () => {
      mounted = false;
    };
  }, [servicesWithShops, shopFilters.services, searchName]);

  useEffect(() => {
    if (user && user.id) {
      dispatch({
        type: 'getFavouriteShops',
        payload: {
          userId: user.id,
          limit: 5000
        }
      });
    }
  }, [user]);

  const closeDrawer = () => setOpenDrawer(false);

  return (
    <>
      <Grid container justify="space-between" className={classes.root}>
        {!lg ? (
          <IconButton
            onClick={() => setOpenDrawer(true)}
            className={classes.toggleDrawerBtn}>
            <img src={filterIcon} alt="toggle-filter-button" />
          </IconButton>
        ) : (
          <Grid item xs={2}>
            <ShopFilters
              shopFilters={shopFilters}
              setShopFilters={setShopFilters}
              resetFilters={resetFilters}
              showResetFiltersBtn={showResetFiltersBtn}
            />
          </Grid>
        )}
        <Grid
          item
          container
          alignItems="flex-start"
          justify="flex-start"
          xs={12}
          lg={10}
          className={classes.content}>
          <Grid
            container
            item
            spacing={1}
            className={classes.filtersContainer}
            justify="flex-end"
            alignItems="center">
            <Grid item xs={9} md={10} lg={4}>
              <CustomSearchBar
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
                placeholder="Shop Name"
                label=""
                size="medium"
                onClear={() => setSearchName('')}
                InputProps={{
                  classes: { root: classes.searchBox }
                }}
              />
            </Grid>
            {!lg ? (
              <Grid item xs={3} md={2}>
                <Button
                  size="large"
                  fullWidth
                  variant="contained"
                  className={classes.filterBtn}
                  onClick={() => setOpenDrawer(true)}
                  endIcon={
                    !smDown ? (
                      <FilterListIcon className={classes.filterIcon} />
                    ) : null
                  }>
                  {smDown ? (
                    <FilterListIcon className={classes.filterIcon} />
                  ) : (
                    'Filter'
                  )}
                </Button>
              </Grid>
            ) : null}
          </Grid>
          {isShopsLoading ? (
            <ShopListShopCardSkeleton />
          ) : filteredServicesWithShops.length > 0 ? (
            filteredServicesWithShops.map((service, index) => (
              <Grid
                container
                alignItems="flex-start"
                key={`shops-carousels-shops-search-results-items-${index}`}>
                <Grid container item xs={12}>
                  <ShopsCarousel
                    service={service}
                    favouriteShops={favouriteShops}
                    className={classes.shops}
                    onSearch={!!searchName}
                  />
                </Grid>
              </Grid>
            ))
          ) : !!searchName ? (
            <Typography align="center" variant="h3">
              Shop not found with the searched name. Please refine your search
              to find your shop.
            </Typography>
          ) : (
            <Grid item container justify="center" alignItems="center" xs={12}>
              <Link
                href={googleFormLinks.requestToOnboardShopFromCustomer}
                variant="h3"
                target="_blank"
                rel="noreferrer"
                align="center">
                Click here to register your interest so that we can be there
                shortly...
              </Link>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Drawer anchor="right" open={openDrawer} onClose={closeDrawer}>
        <div style={{ width: '100vw' }} role="presentation">
          <Grid
            container
            justify="flex-end"
            className={classes.iconButtonContainer}>
            <IconButton color="primary" onClick={closeDrawer}>
              <CancelIcon fontSize="large" />
            </IconButton>
          </Grid>
          <ShopFilters
            shopFilters={shopFilters}
            setShopFilters={setShopFilters}
          />
        </div>
      </Drawer>
    </>
  );
};

export default ShopsViews;
