import React from 'react';
import { useParams } from 'react-router-dom';
import FilterUser from './FilterUser';
import CustomerDetails from './CustomerDetails';

const Customers = () => {
  let { userId } = useParams();

  return userId ? <CustomerDetails /> : <FilterUser />;
};

export default Customers;
