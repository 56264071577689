import { Grid, Typography } from '@material-ui/core';
import React from 'react';

const PhotoLibTopBar = () => {
  return (
    <Grid container alignItems="flex-end" justify="space-between" spacing={3}>
      <Grid item>
        <Typography variant="overline" gutterBottom={true}>
          Photo Library
        </Typography>
        <Typography variant="h3">Services Photo Library</Typography>
      </Grid>
    </Grid>
  );
};

export default PhotoLibTopBar;
