import { API, graphqlOperation } from 'aws-amplify';
import {
  getMessageNotification as getMessageNotificationQuery,
  getMessageNotificationByShop,
  getMessageNotificationByUser
} from './messageQueries';
import { markNotificationsAsRead as markAllNotificationsAsRead } from 'graphql/mutations';

export const getMessageNotification = (id) =>
  new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(getMessageNotificationQuery, { id }))
      .then((data) => resolve(data.data.getMessageNotification))
      .catch((err) => reject(err));
  });

export const loadMessageNotificationsByUser = (payload = {}) =>
  new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(getMessageNotificationByUser, payload))
      .then((data) => resolve(data.data.getMessageNotificationByUser))
      .catch((err) => reject(err));
  });

export const loadMessageNotificationsByShop = (payload = {}) =>
  new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(getMessageNotificationByShop, payload))
      .then((data) => resolve(data.data.getMessageNotificationByShop))
      .catch((err) => reject(err));
  });

export const markNotificationsAsRead = (payload = {}) =>
  new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(markAllNotificationsAsRead, payload))
      .then((data) => resolve(data.data.markNotificationsAsRead))
      .catch((err) => reject(err));
  });
