import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core';
import Image from './Image';
import { makeStyles } from '@material-ui/core/styles';
import { useAmplifyAuth } from 'context';
import { useLoader } from 'layouts/loaderContext';
import { StyledTabs, StyledTab, TabPanel } from 'components/molecules';

const useStyles = makeStyles((theme) => ({
  upload: {
    margin: '1rem 0'
  },
  preview: {
    maxWidth: 100,
    maxHeight: 100,
    border: '1px solid #bbb',
    display: 'block',
    marginBottom: 8
  },
  galleryImgs: {
    maxWidth: 100,
    minWidth: 100,
    maxHeight: 100,
    minHeight: 100,
    border: '1px solid #bbb',
    display: 'inline'
  },
  image: {
    maxHeight: 100,
    maxWidth: 100,
    backgroundColor: '#3f51b5',
    padding: '0.5rem',
    borderRadius: '4px'
  },
  imageBox: {
    margin: '2rem 0 1rem 0'
  },
  selectButton: {
    margin: '1rem',
    '&:focus': {
      backgroundColor: '#3f51b5'
    }
  },
  tabPanel: {
    textAlign: 'center'
  },
  bottomSpacing: {
    marginBottom: '1rem'
  }
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const PhotoGalleryDialog = ({
  open = () => {},
  onClose = () => {},
  previewGalleryImg = [],
  serviceName = 'service',
  imgData = '',
  selectImageHandler = () => {},
  handleFileChange = () => {},
  setImgData = () => {},
  onConfirmImage = () => {}
}) => {
  const classes = useStyles();
  const { loading } = useLoader();
  const [value, setValue] = useState(0);
  const {
    state: { isAdmin }
  } = useAmplifyAuth();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setImgData('');
  };

  return (
    <Dialog fullWidth={true} maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>
        <Grid container justify="space-between">
          <h4>Select an image for {serviceName}</h4>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid container item xs={12} justify="center">
          <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="gallery-tabs">
            <StyledTab label="Choose photo from gallery" {...a11yProps(0)} />
            {!isAdmin && (
              <StyledTab label="Upload your own photo" {...a11yProps(1)} />
            )}
          </StyledTabs>
        </Grid>

        <div className={classes.upload}>
          <TabPanel value={value} index={0}>
            {loading ? null : previewGalleryImg?.length < 1 ? (
              <Typography
                align="center"
                variant="h5"
                style={{ paddingBottom: '1rem' }}>
                No photos for this service!
              </Typography>
            ) : (
              <>
                {previewGalleryImg?.map((el, i) => (
                  <Button
                    onClick={() => selectImageHandler(el)}
                    key={i}
                    className={classes.selectButton}>
                    <Grid>
                      <Image
                        docKey={el.picture.photoURL}
                        className={classes.galleryImgs}
                        alt="product image"
                      />
                      <Typography variant="body1">
                        {el.picture.name || ''}
                      </Typography>
                    </Grid>
                  </Button>
                ))}
              </>
            )}
          </TabPanel>
          {!isAdmin && (
            <Grid container item justify="center">
              <TabPanel value={value} index={1} className={classes.tabPanel}>
                <Typography className={classes.bottomSpacing}>
                  Preferred image ratio <strong>3 x 2</strong>
                </Typography>
                <input
                  type="file"
                  id="image-file"
                  name="image-file"
                  accept="image/*"
                  onChange={handleFileChange}
                  hidden
                />
                <label htmlFor="image-file">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    size="small">
                    Upload
                  </Button>
                </label>
                {imgData && (
                  <Box className={classes.imageBox}>
                    <img
                      src={imgData}
                      className={classes.image}
                      alt="product image"
                    />
                  </Box>
                )}
              </TabPanel>
            </Grid>
          )}
        </div>
      </DialogContent>

      <DialogActions style={{ justifyContent: 'center' }}>
        <Button color="inherit" onClick={onClose} align="center">
          Cancel
        </Button>
        <Button color="inherit" onClick={onConfirmImage} align="center">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PhotoGalleryDialog;
