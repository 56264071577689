import React, { useCallback, useEffect, useRef, useState } from 'react';
import { I18n, Auth } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { useHistory } from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import LogoName from '../../components/organisms/LogoName';
import { Grid, makeStyles } from '@material-ui/core';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useAmplifyAuth } from 'context';

I18n.putVocabulariesForLanguage('en', {
  'Sign In': 'Login', // Tab header
  'Sign in': 'LOGIN' // Button label
});

const useStyles = makeStyles(() => ({
  root: {
    margin: '0 auto',
    padding: '2rem 0 3rem',
    minHeight: '450px'
  }
}));

const Login = () => {
  const history = useHistory();
  const classes = useStyles();
  const captchaRef = useRef();
  const [triggerEffect, setTriggerEffect] = useState(false);
  const redirectUrl = history.location.search.split('redirect=')[1] || '/home';
  const { executeRecaptcha } = useGoogleReCaptcha();
  let {
    state: { user }
  } = useAmplifyAuth();

  useEffect(() => {
    let timerId;

    if (user && user.username) {
      timerId = setTimeout(() => history.replace(redirectUrl), 500);
    }

    return () => clearTimeout(timerId);
  }, [user]);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('recaptcha not yet available');
      return '';
    }
    const token = await executeRecaptcha();
    return token;
  }, [executeRecaptcha, triggerEffect]);

  useEffect(() => {
    localStorage.setItem('redirectUrl', redirectUrl);
    handleReCaptchaVerify().then((token) => {
      captchaRef.current = token;
    });
  }, [handleReCaptchaVerify]);

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
      className={classes.root}>
      <Grid item style={{ marginBottom: '40px' }}>
        <LogoName />
      </Grid>
      <Authenticator
        loginMechanisms={['phone_number']}
        signUpAttributes={['custom:firstName', 'custom:lastName']}
        formFields={{
          signIn: {
            username: {
              pattern: '^[0-9]{10}$',
              title: 'Phone Number should be 10 digits.',
              placeholder: 'Phone Number (eg: 7712345678)',
              dialCode: '+44',
              isRequired: true
            }
          },
          signUp: {
            phone_number: {
              order: 1,
              placeholder: 'Phone Number (eg: 7712345678)',
              labelHidden: false,
              label: 'Phone Number',
              pattern: '^[0-9]{10}$',
              title: 'Phone Number should be 10 digits.',
              isRequired: true,
              dialCode: '+44'
            },
            'custom:firstName': {
              order: 2,
              placeholder: 'First Name',
              labelHidden: false,
              label: 'First Name',
              isRequired: true
            },
            'custom:lastName': {
              order: 3,
              placeholder: 'Last Name',
              labelHidden: false,
              label: 'Last Name',
              isRequired: true
            },
            email: {
              order: 4,
              labelHidden: false,
              label: 'Email',
              placeholder: 'Please enter your email',
              type: 'email',
              isRequired: true
            },
            password: {
              order: 5,
              labelHidden: false,
              label: 'Password'
            },
            confirm_password: {
              order: 6,
              labelHidden: false,
              label: 'Confirm Password'
            }
          }
        }}
        services={{
          handleSignUp: async (formData) =>
            Auth.signUp({
              ...formData,
              attributes: {
                ...formData.attributes,
                email: formData.attributes.email.toLowerCase().trim()
              },
              username: formData.username.toLowerCase().trim(),
              validationData: { recaptchaToken: captchaRef.current }
            }).finally(() => setTriggerEffect((prev) => !prev)),
          handleSignIn: async (formData) =>
            Auth.signIn({
              ...formData,
              username: formData.username.toLowerCase().trim()
            }).then((user) => {
              setTriggerEffect((prev) => !prev);
              history.replace(redirectUrl);
              return user;
            }),
          handleConfirmSignUp: async ({ username, code }) =>
            Auth.confirmSignUp(username, code).then(() =>
              history.replace('/login')
            ),
          handleForgotPassword: async (username) =>
            Auth.forgotPassword(username.toLowerCase().trim()),
          handleForgotPasswordSubmit: async ({ username, code, password }) =>
            Auth.forgotPasswordSubmit(username, code, password).then(() =>
              history.replace(redirectUrl)
            )
        }}
      />
    </Grid>
  );
};

export default Login;
