import React, { useState, useEffect } from 'react';
import { graphqlOperation, API } from 'aws-amplify';
import { getUser } from '../../../../../../graphql/queries';
import {
  Grid,
  IconButton,
  Card,
  CardContent,
  Avatar,
  Typography,
  Tab,
  Tabs,
  Button,
  Chip
} from '@material-ui/core';
import { StaffActivity, StaffProfile } from '..';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { makeStyles } from '@material-ui/core/styles';
import { unitFormatter, getFormattedName } from 'common/utilFunctions';
import { Image } from 'components/organisms';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    height: 140,
    width: 140
  },
  pending: { backgroundColor: theme.palette.warning.main },
  enabled: { backgroundColor: theme.palette.success.main },
  disabled: { backgroundColor: theme.palette.error.main },
  deceased: { backgroundColor: theme.palette.error.main }
}));

const StaffDetails = ({
  selectedStaff,
  setSelectedStaff,
  handleAddBtnClick = () => {},
  handleClose = () => {},
  showAddBtn = false
}) => {
  const [showStaffInfo, setShowStaffInfo] = useState('profile');
  const classes = useStyles();

  const loadStaffDetails = async () => {
    const data = await API.graphql(
      graphqlOperation(getUser, { id: selectedStaff.id })
    );
    setSelectedStaff(data.data.getUser);
  };

  useEffect(() => {
    if (selectedStaff?.id) loadStaffDetails();
  }, []);

  return (
    <Grid container direction="row" item xs={12}>
      <Grid container item justify="space-between" xs={12}>
        <IconButton onClick={() => handleClose()}>
          <ArrowBackIcon />
        </IconButton>
        {showAddBtn ? (
          <Button
            style={{ height: 'fit-content' }}
            color="primary"
            variant="contained"
            size="small"
            onClick={handleAddBtnClick}
            endIcon={<GroupAddIcon />}>
            Add New Staff
          </Button>
        ) : null}
      </Grid>
      <Grid container item xs={12}>
        <Card style={{ minWidth: '100%', boxShadow: 'none' }}>
          <CardContent>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center">
              <Grid
                container
                item
                xs={12}
                md={2}
                style={{ marginRight: '2rem' }}>
                {selectedStaff?.picture?.split('/').at(0) === 'images' ? (
                  <Image
                    alt="staff-image-header"
                    className={classes.avatar}
                    docKey={selectedStaff.picture}
                  />
                ) : (
                  <Avatar className={classes.avatar} />
                )}
              </Grid>
              <Grid
                container
                item
                justify="center"
                direction="column"
                xs={12}
                md={6}>
                <Typography gutterBottom variant="h5">
                  {getFormattedName(selectedStaff)}
                </Typography>
                <Typography gutterBottom variant="h6">
                  {unitFormatter(selectedStaff?.groups?.at(0) || '')}
                </Typography>
                <Typography color="textSecondary" variant="subtitle2">
                  {selectedStaff?.email || ''}
                </Typography>
                <Grid container item style={{ marginTop: '1rem' }}>
                  <Chip
                    size="small"
                    label={selectedStaff?.status || ''}
                    className={classes[selectedStaff?.status || 'pending']}
                    style={{ color: 'white' }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid container item xs={12}>
        <Tabs
          value={showStaffInfo}
          onChange={(e, newVal) => setShowStaffInfo(newVal)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="staff-profile-tabs">
          <Tab label="profile" value={'profile'} />

          {/* following activities tab is needed in future */}
          {/* <Tab label="activities" value={'activities'} /> */}
        </Tabs>
      </Grid>
      <Grid container item xs={12}>
        {showStaffInfo === 'profile' ? (
          <StaffProfile
            selectedStaff={selectedStaff}
            setSelectedStaff={setSelectedStaff}
          />
        ) : (
          <StaffActivity selectedStaff={selectedStaff} />
        )}
      </Grid>
    </Grid>
  );
};

export default StaffDetails;
