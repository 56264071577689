import React, { useContext, useCallback, useReducer } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { useLoader } from 'layouts/loaderContext';
import { getCustomers } from 'graphql/queries';

const CustomerContext = React.createContext({});

const getFormatedCustomer = (customersList) => {
  const customerListData = [];

  const ids = customersList.map((customer) =>
    customer.Attributes.filter((el) => el.Name === 'sub')
  );
  const firstNames = customersList.map((customer) =>
    customer.Attributes.filter((el) => el.Name === 'custom:firstName')
  );
  const lastNames = customersList.map((customer) =>
    customer.Attributes.filter((el) => el.Name === 'custom:lastName')
  );
  const phoneNumbers = customersList.map((customer) =>
    customer.Attributes.filter((el) => el.Name === 'phone_number')
  );
  const emails = customersList.map((customer) =>
    customer.Attributes.filter((el) => el.Name === 'email')
  );
  const pictures = customersList.map((customer) =>
    customer.Attributes.filter((el) => el.Name === 'picture')
  );

  for (let i = 0; i < ids.length; i++) {
    customerListData[i] = {
      id: ids[i][0].Value,
      name: firstNames[i][0]?.Value + ' ' + lastNames[i][0]?.Value,
      phoneNumber: phoneNumbers[i][0]?.Value,
      email: emails[i][0]?.Value,
      picture: pictures[i][0]?.Value,
      userCreateDate: customersList[i].UserCreateDate,
      orderDate: '',
      location: '',
      distance: ''
    };
  }
  return customerListData.sort(
    (a, b) =>
      new Date(b.userCreateDate).getTime() -
      new Date(a.userCreateDate).getTime()
  );
};

const customerReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'updateData': {
      return payload || [];
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

const CustomerContextProvider = (props) => {
  const [customers, dispatch] = useReducer(customerReducer, []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { setLoading } = useLoader();

  const asyncDispatch = useCallback(
    async (action) => {
      switch (action.type) {
        case 'getCustomers': {
          if (customers.length > 0) return;
          setLoading(true);
          const snackBar = enqueueSnackbar('Loading customers...', {
            variant: 'info',
            persist: true
          });
          try {
            const res = await API.graphql(graphqlOperation(getCustomers));
            const formatedCustomers = getFormatedCustomer(
              res.data.getCustomers
            );
            dispatch({ type: 'updateData', payload: formatedCustomers });
          } catch (error) {
            console.log('Error ', error);
          } finally {
            setLoading(false);
            closeSnackbar(snackBar);
          }
          break;
        }
        case 'sortCustomers': {
          const { sort } = action?.payload || {};

          let sortedCustomers = [];
          if (sort === 'oldest') {
            sortedCustomers = [
              ...customers.sort(
                (a, b) =>
                  new Date(a.userCreateDate).getTime() -
                  new Date(b.userCreateDate).getTime()
              )
            ];
          } else {
            sortedCustomers = [
              ...customers.sort(
                (a, b) =>
                  new Date(b.userCreateDate).getTime() -
                  new Date(a.userCreateDate).getTime()
              )
            ];
          }
          dispatch({ type: 'updateData', payload: sortedCustomers });
          break;
        }
        default:
          dispatch(action);
      }
    },
    [customers]
  );

  const contextValue = {
    customers,
    dispatch: asyncDispatch
  };

  return (
    <CustomerContext.Provider value={contextValue}>
      {props.children}
    </CustomerContext.Provider>
  );
};

const useCustomers = () => {
  const context = useContext(CustomerContext);
  if (!(context && Object.keys(context).length)) {
    throw new Error('useContext must be used within a CustomerContext');
  }
  return context;
};

export { useCustomers, CustomerContextProvider };
