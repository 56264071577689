import React from 'react';
import { Image } from 'components/organisms';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import { Button, DialogActions } from '@material-ui/core';
import { getStorageUrl } from 'common/utilFunctions';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh'
  },
  image: {
    maxWidth: '100%',
    height: 'auto'
  }
}));

const ViewDialog = (props) => {
  const {
    open,
    handleCancel,
    docKey,
    accessLevel = 'public',
    mimeType = ''
  } = props;
  const classes = useStyles();

  const downloadImageHandler = async () => {
    const filename = docKey.split('/').at(-1);
    const imageURL = await getStorageUrl(docKey);
    fetch(imageURL)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error downloading image:', error);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="draggable-dialog-title"
      className={classes.dialogPaper}>
      <DialogContent>
        <Image
          docKey={docKey}
          alt="Shop photo"
          className={classes.image}
          accessLevel={accessLevel}
          mimeType={mimeType}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Close
        </Button>
        <Button onClick={downloadImageHandler} color="primary" autoFocus>
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewDialog;
