import React from 'react';
import {
  Button,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import '../styles.css';
import CollectAndDeliver from './CollectAndDeliver';
import { Address, WorkingHoursMenu } from 'components/organisms';
import { ShopCurrentWorkingStatus, StyledRadio } from 'components/molecules';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useAmplifyAuth } from 'context';
import { useShops } from 'context/shop/shopsContext';
import MUIRichTextEditor from 'mui-rte';
import { StyledFormControlLabel } from 'components/molecules';

const useStyles = makeStyles((theme) => ({
  root: {},
  blueBtn: {
    color: theme.palette.blue.main,
    padding: '0',
    marginTop: '0.5rem',
    [theme.breakpoints.up('sm')]: {
      marginTop: 0
    }
  },
  blueContainedBtn: {
    backgroundColor: theme.palette.blue.main,
    color: 'white'
  },
  greyText: {
    color: theme.palette.grey.secondary,
    fontSize: '16px',
    marginRight: '3px'
  },
  note: {
    color: theme.palette.warning.dark,
    padding: '0.5rem 0'
  },
  radioContainer: {
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      flexDirection: 'column',
      gap: '1rem'
    }
  },
  deliveryNote: {
    backgroundColor: '#edd242',
    padding: '0.2rem 0.5rem',
    fontWeight: 600,
    borderRadius: 4,
    color: '#444',
    textAlign: 'center'
  }
}));

const RenderAddress = ({
  type = '',
  value = '',
  setValue = () => {},
  typeChangeHandler = () => {},
  collectionAddress = '',
  deliveryAddress = '',
  changeAddress = () => {},
  isASAP = false,
  collectionHrs = [],
  setCollectionSlot = () => {},
  setCollectionTime = null,
  deliveryHrs = [],
  collectionTime = null,
  collectionSlot = null,
  setDeliverySlot = null,
  setDeliveryTime = () => {},
  setIsDefaultAddressChange = () => {},
  setWorkingHoursMenuAnchor = () => {},
  workingHoursMenuAnchor = null,
  instructions = {},
  options = [],
  isEditing = false,
  isPickUpOrder = false,
  isFreeDeliveryAvl = false,
  collectionInstructions = '',
  deliveryInstructions = '',
  setCollectionInstructions = () => {},
  setDeliveryInstructions = () => {}
}) => {
  const classes = useStyles();
  const {
    state: { user }
  } = useAmplifyAuth();
  const { selectedShop } = useShops();

  return (
    <Grid container className={classes.root}>
      <Grid
        item
        xs={12}
        container
        justify="space-between"
        alignItems="center"
        style={{ padding: '0.625rem 0 1rem' }}>
        <Grid item xs={12}>
          <RadioGroup
            row
            aria-label="payment-options-radio"
            name="payment-options-radio"
            value={value}
            onChange={(e, newVal) => typeChangeHandler(e, newVal, setValue)}
            className={classes.radioContainer}>
            {options.map((item) => (
              <StyledFormControlLabel
                key={`order-review-address-${value}-options-items=${item}`}
                value={item}
                label={item}
                disabled={isEditing && isPickUpOrder && item === 'Pickup'}
                checked={item === value}
              />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>
      {value === '' || value === 'asap' ? (
        <></>
      ) : value === 'Collection' || value === 'Delivery' ? (
        <>
          <Grid item xs={12}>
            {isASAP ? (
              <Typography variant="h5" className={classes.note}>
                Note: {type} Date & time will be provided by the shop.
              </Typography>
            ) : null}
            {type === 'Collection' && !isASAP && (
              <CollectAndDeliver
                type={'Collection'}
                allowedHrs={collectionHrs}
                collectionDate={collectionTime}
                collectionSlot={collectionSlot}
                setSlot={setCollectionSlot}
                setTime={setCollectionTime}
              />
            )}
            {type === 'Delivery' && !isASAP && (
              <CollectAndDeliver
                type={'Delivery'}
                allowedHrs={deliveryHrs}
                collectionDate={collectionTime}
                collectionSlot={collectionSlot}
                setSlot={setDeliverySlot}
                setTime={setDeliveryTime}
              />
            )}
            {((type === 'Collection' && collectionAddress) ||
              (type === 'Delivery' && deliveryAddress)) && (
              <>
                {type === 'Collection' ? (
                  <Address data={collectionAddress} mode={0} />
                ) : (
                  <Address data={deliveryAddress} mode={0} />
                )}
                <Grid container justify="space-between">
                  <Grid item>
                    {deliveryAddress &&
                      user?.defaultAddress !== deliveryAddress.id &&
                      type === 'Delivery' && (
                        <FormControlLabel
                          classes={{ root: classes.fomrControlLabelRoot }}
                          label={`Make this my default address`}
                          control={<Checkbox color="primary" />}
                          onChange={(event, val) => {
                            setIsDefaultAddressChange({
                              isChange: val,
                              option: 'delivery'
                            });
                          }}
                        />
                      )}

                    {collectionAddress &&
                      user?.defaultAddress !== collectionAddress.id &&
                      type === 'Collection' && (
                        <FormControlLabel
                          classes={{ root: classes.fomrControlLabelRoot }}
                          label={`Make this my default address`}
                          control={<Checkbox color="primary" />}
                          onChange={(event, val) => {
                            setIsDefaultAddressChange({
                              isChange: val,
                              option: 'collection'
                            });
                          }}
                        />
                      )}
                  </Grid>
                  <Grid item>
                    {((type === 'Collection' &&
                      value === 'Collection' &&
                      collectionAddress) ||
                      (type === 'Delivery' &&
                        value === 'Delivery' &&
                        deliveryAddress)) && (
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => changeAddress(type)}
                        className={classes.blueContainedBtn}>
                        Change Address
                      </Button>
                    )}
                  </Grid>
                </Grid>

                <Grid container style={{ gap: '0.5rem' }}>
                  <Grid item xs={12}>
                    <Typography variant="h6">{`${type} Instructions (If any)`}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={
                        type === 'Collection'
                          ? collectionInstructions
                          : deliveryInstructions
                      }
                      name="instructions"
                      onChange={(e) =>
                        type === 'Collection'
                          ? setCollectionInstructions(e.target.value)
                          : setDeliveryInstructions(e.target.value)
                      }
                      placeholder={`e.g, ${
                        type === 'Collection'
                          ? 'Collect the laundry bag from my doorstep.'
                          : "If I'm not available, please coordinate with my neighbor, for the delivery."
                      }`}
                      size="small"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>

                {isEditing && !isFreeDeliveryAvl ? (
                  <Grid
                    container
                    justify="center"
                    style={{ margin: '1rem 0 0' }}>
                    <Grid item>
                      <Typography
                        variant="body1"
                        className={classes.deliveryNote}>
                        Please note that delivery charges will be applicable.
                      </Typography>
                    </Grid>
                  </Grid>
                ) : null}
              </>
            )}
          </Grid>
          <Grid item xs={12} style={{ marginTop: '10px' }}>
            {((type === 'Collection' && !collectionAddress) ||
              (type === 'Delivery' && !deliveryAddress)) && (
              <Button
                onClick={() => changeAddress(type)}
                className={classes.blueContainedBtn}>
                ADD NEW ADDRESS
              </Button>
            )}
          </Grid>
        </>
      ) : (
        <Grid container style={{ marginTop: '10px' }}>
          {selectedShop && (
            <Grid item xs={12} md={6}>
              <Grid container alignItems="flex-start">
                <LocationOnOutlinedIcon className={classes.greyText} />
                <Typography variant="subtitle1" className={classes.greyText}>
                  Store Location
                </Typography>
              </Grid>
              <Typography variant="subtitle1" style={{ marginTop: '5px' }}>
                &nbsp;
                {`${selectedShop?.address}, ${selectedShop?.town}, ${selectedShop?.postcode}`}
              </Typography>
            </Grid>
          )}
          {selectedShop && (
            <Grid item xs={12} md={6}>
              <Grid container alignItems="flex-start">
                <AccessTimeOutlinedIcon className={classes.greyText} />
                <Typography variant="subtitle1" className={classes.greyText}>
                  Opening Hours
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                container
                alignItems="center"
                style={{ height: 'fit-content', marginTop: '5px' }}>
                <Button
                  variant="text"
                  onClick={(e) => setWorkingHoursMenuAnchor(e.currentTarget)}
                  style={{ padding: 0 }}
                  endIcon={<KeyboardArrowDownIcon fontSize="small" />}>
                  <ShopCurrentWorkingStatus workHrs={selectedShop?.workHrs} />
                </Button>
              </Grid>
              <WorkingHoursMenu
                anchorEl={workingHoursMenuAnchor}
                handleClose={() => setWorkingHoursMenuAnchor(null)}
                workingHours={
                  selectedShop && selectedShop.workHrs
                    ? selectedShop.workHrs
                    : []
                }
              />
            </Grid>
          )}

          {!isEditing && selectedShop ? (
            <Grid item xs={12} style={{ marginTop: '20px' }}>
              <Grid container alignItems="flex-start">
                <InfoOutlinedIcon className={classes.greyText} />
                <Typography variant="subtitle1" className={classes.greyText}>
                  {type === 'Collection' ? 'Drop ' : 'Pickup '} Instructions
                </Typography>
              </Grid>
              {type === 'Collection' ? (
                !!instructions.collection &&
                JSON.parse(instructions.collection)?.blocks?.some(
                  (item) => !!item?.text
                ) ? (
                  <MUIRichTextEditor
                    readOnly
                    toolbar={false}
                    defaultValue={instructions.collection}
                  />
                ) : (
                  'Not available'
                )
              ) : !!instructions.delivery &&
                JSON.parse(instructions.delivery)?.blocks?.some(
                  (item) => !!item?.text
                ) ? (
                <MUIRichTextEditor
                  readOnly
                  toolbar={false}
                  defaultValue={instructions.delivery}
                />
              ) : (
                'Not available'
              )}
            </Grid>
          ) : null}
        </Grid>
      )}
    </Grid>
  );
};

export default RenderAddress;
