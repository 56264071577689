import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
//import { createService } from "../../../graphql/mutations";
//import { graphqlOperation, API } from 'aws-amplify';

const useStyles = makeStyles(() => ({
  addBtn: {
    minHeight: '1rem',
    minWidth: '1rem'
  }
}));

const ServicesToolbar = (props) => {
  const classes = useStyles();
  const { addService } = props;
  const [services, setServices] = useState({
    servicesName: '',
    description: ''
  });

  /*const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      input: {
        'name': services.servicesName,
        'description': services.description
      }
    };
    const result = await API.graphql(
      graphqlOperation(createService, data)
    );

  };*/

  const handleChange = (event, newValue) => {
    const { name, value } = event.target;
    if (newValue) {
      setServices({
        ...services,
        shopId: newValue.id
      });
    } else {
      setServices({
        ...services,
        [name]: value
      });
    }
  };

  return (
    <div>
      <Grid
        container
        alignItems="flex-start"
        justify="space-between"
        spacing={3}
        direction="column">
        <Grid item>
          <Typography variant="overline" gutterBottom={true}>
            Services
          </Typography>
          <Typography variant="h3">List of Services & Items</Typography>
        </Grid>
        <Grid item>
          <Button
            title="Add a new domestic/commercial service"
            variant="contained"
            size="medium"
            onClick={addService}
            className={classes.addBtn}>
            <Typography variant="h1">
              <AddIcon fontSize="large" />
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid container></Grid>
    </div>
  );
};

ServicesToolbar.propTypes = {
  className: PropTypes.string
};

export default ServicesToolbar;
