const auctionQuery = `
{
  id
  auctionID
  userID
  createdAt
  startDateTime
  endDateTime
  total
  collection {
    date
    time
    type
    address
    instructions
    driver {
      userID
    }
  }
  delivery {
    date
    time
    type
    address
    instructions
    driver {
      userID
    }
  }
  postcode
  isClosed
  items {
    items {
      id
      auctionID
      serviceID
      name
      description
      quantity
      image
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      service {
        id
        serviceID
        name
      }
    }
    nextToken
    startedAt
  }
  bids {
    items {
      id
      auctionID
      shopID
      createdBy
      createdAt
      totalPrice
      shippingCharges
      items {
        items {
          id
          bidID
          auctionItemID
          price
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      orders {
        items {
          id
          orderID
          status
        }
        nextToken
        startedAt
      }
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
        }
        language
        shopID
        about
        status
        isLive
        websiteLink
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        AsapDeliveryFee
      }
    }
    nextToken
    startedAt
  }
  _version
  _deleted
  _lastChangedAt
  updatedAt
  user {
    id
    groups
    picture
    username
    userId
    firstName
    lastName
    title
    defaultAddress
    email
    address
    postalCode
    phoneNumber
    createdAt
    shopID
    status
    language
    dateOfBirth
    updatedAt
    _version
    _deleted
    _lastChangedAt
  }
}
`;

export const getAuctionByUserID = /* GraphQL */ `
  query GetAuctionByUserID(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAuctionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAuctionByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${auctionQuery}
      nextToken
      startedAt
    }
  }
`;
export const listAuctions = /* GraphQL */ `
  query ListAuctions(
    $filter: ModelAuctionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuctions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items ${auctionQuery}
      nextToken
      startedAt
    }
  }
`;
export const getAuctionByShop = /* GraphQL */ `
  query GetAuctionByShop(
    $shopID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAuctionShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAuctionByShop(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        auctionID
        auction ${auctionQuery}
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
