import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';

const useStyles = makeStyles(theme => ({
  box: {
    padding: theme.spacing(1)
  }
}));

export default function PopoverComponent(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <ShareOutlinedIcon
        fontSize="small"
        style={{ color: '#c4c4c4' }}
        onClick={handleClick}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        style={{ marginTop: '0.5rem' }}>
        <Grid container className={classes.box}>
          {props.children}
        </Grid>
      </Popover>
    </>
  );
}
