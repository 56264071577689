export const favouriteShopItem = `{
    id
    shopID
    userID
    isFavourite
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    shop {
        id
        name
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        shopID
        status
        isLive
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        __typename
    }
    user {
        id
        username
        firstName
        lastName
        title
        email
        phoneNumber
        createdAt
        status
        dateOfBirth
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
    }
    __typename
}`;

export const getFavouriteShopByUser = /* GraphQL */ `
  query GetFavouriteShopByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFavouriteShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFavouriteShopByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${favouriteShopItem}
      nextToken
      startedAt
      __typename
    }
  }
`;
