import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#F2F2F2',
    padding: '1rem',
    gap: '1rem',
    borderRadius: '0.25rem'
  },
  reply: {
    fontSize: 15,
    lineHeight: '1.2rem'
  }
}));

const ShopAdminReply = ({
  shopResponse,
  selectedShop,
  setIsEditReply,
  isShopAdminView = false
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid container item justify="space-between">
        <Typography variant="h6" style={{ fontWeight: 600, fontSize: 15 }}>
          {selectedShop?.name}
        </Typography>
        <Typography style={{ fontWeight: 600, color: '#444' }}>
          {moment(shopResponse.reply?.updatedAt).format('MMMM Do YYYY')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.reply}>
          {shopResponse.reply?.description || ''}
        </Typography>
      </Grid>
      {isShopAdminView ? (
        <Grid container justify="flex-end">
          <Button
            size="small"
            color="primary"
            onClick={() => setIsEditReply(true)}>
            Edit your response
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ShopAdminReply;
