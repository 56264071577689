import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Grid, Tabs, Tab, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ProductsToolbar, ProductsTable } from './components';
import { CustomSearchBar, AddProductModal } from 'components/organisms';
import SearchShop from 'components/SearchShop';
import { getCustomShopProductName } from 'common/utilFunctions';
import { useServices } from '../Services/serviceContext';
import { useProducts } from 'context/products/productsContext';
import { useShops } from 'context/shop/shopsContext';
import { useAmplifyAuth } from 'context';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  autoComplete: {
    margin: theme.spacing(1),
    minWidth: 500
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  paper: {
    padding: 10
  },
  footer: {
    flexGrow: 1,
    padding: 10
  },
  tabs: {
    border: '1px solid #DDDDDD',
    borderBottom: 'none',
    marginRight: 5
  },
  wrapper: {
    display: 'table'
  },
  checkbox: {
    paddingLeft: 0
  }
}));

const initialNewProduct = {
  service: '',
  name: '',
  description: ''
};

const ProductList = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [disabled, setDisabled] = useState(true);
  const { selectedShop } = useShops();
  const { services, dispatch: dispatchService } = useServices();
  const { products, selectedShopID, dispatch } = useProducts();
  const [initialProducts, setInitialProducts] = useState({});
  const [showAddProductDialog, setShowAddProductDialog] = useState(false);
  const [newProduct, setNewProduct] = useState(initialNewProduct);
  const [items, setItems] = useState([]);
  const [searchBy, setSearchBy] = useState('');
  let {
    state: { user, isAdmin, isSales }
  } = useAmplifyAuth();

  useEffect(() => {
    let mounted = true;
    if (user && user.shopId) {
      dispatch({
        type: 'getProductsByShopId',
        payload: { id: user.shopId }
      }).then((data) => mounted && setInitialProducts(data));
    }

    return () => {
      mounted = false;
    };
  }, [user]);

  useEffect(() => {
    dispatchService({ type: 'getService' });
  }, [dispatchService]);

  useEffect(() => {
    if (products) {
      updateItems();
    }
  }, [value, services, Object.keys(products).length]);

  // for ordering the products and storing in array *items*
  const updateItems = () => {
    // items under the selected service
    const itemsUnordered = services[value]?.items?.items || [];
    // shop specific products
    const newProductItems = Object.entries(products)
      .filter(
        ([key, val]) =>
          key.includes('shopProduct') && val.serviceID === services[value]?.id
      )
      .map(([key, val]) => ({
        name: val.name || '',
        description: val.description || '',
        image: val.image || '',
        id: key,
        serviceID: services[value]?.id
      }));

    // items under the selected service from previous version of *items* array
    const oldItemIds = items
      .map((item) => item.id)
      .filter((id) => !id.includes('shopProduct'));
    // shop specific products from previous version of *items* array
    const oldShopProductItemIds = items
      .map((item) => item.id)
      .filter((id) => id.includes('shopProduct'));

    // checking if service items haven't changed and shop products haven't changed since last time
    if (
      !(
        oldItemIds.length === itemsUnordered.length &&
        itemsUnordered.every((item) => oldItemIds.includes(item.id)) &&
        oldShopProductItemIds.length === newProductItems.length
      )
    ) {
      // if items or shop products changed then update items
      setItems([
        ...newProductItems,
        ...itemsUnordered.filter((item) => !!products[item.id]),
        ...itemsUnordered.filter((item) => !products[item.id])
      ]);
    }
  };

  const handleChange = (_, newValue) => setValue(newValue);

  const handleSelectChange = (_, newValue) => {
    if (newValue?.id) {
      dispatch({
        type: 'getProductsByShopId',
        payload: newValue
      }).then((data) => {
        setInitialProducts(data);
      });
    }
  };

  const addProduct = () => {
    const { service, name, ...rest } = newProduct;
    const data = {
      ...rest,
      name: name.replace(/\s+/g, ' ').trim(),
      serviceID: newProduct.service?.id || '',
      shopID: user.shopId
    };
    dispatch({
      type: 'addProduct',
      payload: data
    });
    setShowAddProductDialog(false);
    setDisabled(false);
    setNewProduct(initialNewProduct);
  };

  const selectAllItems = (event, t) => {
    setDisabled(false);
    const checkValue = event.target.checked;
    let newProducts = Object.assign({}, products);

    // service items
    const items = services[value]?.items?.items || [];
    items.forEach((item) => {
      const product = products[item.id];
      if (product) {
        newProducts[product.itemID] = {
          ...product,
          enabled: checkValue,
          modify: true
        };
      } else {
        const { name, description, image, id, ...rowValue } = item;
        newProducts[item.id] = {
          price: 0,
          ...rowValue,
          itemID: id,
          enabled: checkValue,
          modify: true
        };
      }
    });

    // shop specific products
    const shopProducts = Object.values(newProducts).filter(
      (item) => !item.itemID && item.serviceID === (services[value]?.id || '')
    );
    shopProducts.forEach((item) => {
      if (item.name)
        newProducts[getCustomShopProductName(item.name, item.serviceID)] = {
          ...item,
          enabled: checkValue,
          modify: true
        };
    });

    dispatch({
      type: 'updateData',
      payload: newProducts
    });
  };

  const handleAddBtnClick = () => {
    setNewProduct({ ...newProduct, service: services[value] });
    setShowAddProductDialog(true);
  };

  const handleNewProductChange = (e) =>
    setNewProduct({ ...newProduct, [e.target.name]: e.target.value });

  return (
    <>
      <div className={classes.root}>
        <ProductsToolbar
          title={`${
            !(isAdmin || isSales) ? `${selectedShop?.name || ''} - ` : ''
          }Add/Edit Products and Pricing`}
        />
        <div className={classes.content}>
          {!(isAdmin || isSales) ? (
            <Grid
              container
              justify="space-between"
              alignItems="center"
              style={{ marginBottom: '1rem', gap: '1rem' }}>
              <Grid item md={4} xs={12}>
                <CustomSearchBar
                  value={searchBy}
                  onChange={(e) => setSearchBy(e.target.value.toLowerCase())}
                  placeholder="Search by product name"
                  onClear={() => setSearchBy('')}
                />
              </Grid>
              <Grid item md={4} lg={2} xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleAddBtnClick}>
                  Add New Item
                </Button>
              </Grid>
            </Grid>
          ) : null}

          <Paper className={classes.paper}>
            <Grid
              container
              item
              xs={12}
              justify="flex-end"
              style={{ marginBottom: '1rem' }}>
              <>
                {isAdmin || isSales ? (
                  <SearchShop
                    onChange={handleSelectChange}
                    value={selectedShopID}
                    shouldFindShop={true}
                  />
                ) : null}
              </>
            </Grid>

            {!!selectedShopID && (
              <Grid container>
                <Grid container>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto">
                    {services.map(
                      (
                        { id, name, items: { items = [] } = { items: [] } },
                        key
                      ) => {
                        const shopProducts = Object.values(products).filter(
                          (item) => !item.itemID && item.serviceID === id
                        );
                        const newCheckAllItem =
                          items.some((item) => products[item.id]?.enabled) ||
                          shopProducts?.some((prod) => {
                            return prod.name
                              ? products[
                                  getCustomShopProductName(
                                    prod.name,
                                    prod.serviceID
                                  )
                                ]?.enabled
                              : false;
                          });
                        const showTab = [...items, ...shopProducts].length > 0;

                        return showTab ? (
                          <Tab
                            key={id}
                            value={key}
                            classes={{
                              root: classes.tabs,
                              wrapper: classes.wrapper
                            }}
                            label={
                              <>
                                <Checkbox
                                  checked={newCheckAllItem}
                                  disabled={value !== key}
                                  inputProps={{
                                    'aria-label': 'select all items'
                                  }}
                                  onClick={selectAllItems}
                                  className={classes.checkbox}
                                />
                                <span>{name}</span>
                              </>
                            }
                          />
                        ) : null;
                      }
                    )}
                  </Tabs>
                </Grid>
                <ProductsTable
                  services={services}
                  dispatch={dispatch}
                  products={products}
                  disabled={disabled}
                  setDisabled={setDisabled}
                  initialProducts={initialProducts}
                  updateInitialProducts={() => setInitialProducts(products)}
                  updateTab={(val) => handleChange(val, val)}
                  items={items}
                  searchBy={searchBy}
                />
              </Grid>
            )}
          </Paper>
        </div>
      </div>
      <AddProductModal
        open={showAddProductDialog}
        services={services}
        products={Object.values(products)}
        values={newProduct}
        addProduct={addProduct}
        handleClose={() => setShowAddProductDialog(false)}
        handleChange={handleNewProductChange}
      />
    </>
  );
};

export default ProductList;
