import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAmplifyAuth } from 'context';
import clsx from 'clsx';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0.5rem 0'
  },
  messageBody: {
    textAlign: 'center',
    padding: '0.3rem 0.5rem',
    color: '#444',
    borderRadius: '4px',
    fontWeight: 600,
    marginBottom: '0.5rem'
  },
  orderCancelled: {
    backgroundColor: '#FFCCCC'
  },
  orderCancelIntent: {
    backgroundColor: '#FFEBCC'
  },
  orderDeliveryIssue: {
    backgroundColor: '#FFEBCC'
  },
  orderDelivered: {
    backgroundColor: '#CCFFCC'
  }
}));

const ActionMessage = ({ message }) => {
  const classes = useStyles();
  const {
    state: { user }
  } = useAmplifyAuth();
  const { messageIntent } = message;

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          className={clsx(classes.messageBody, classes[messageIntent])}>
          {message.text}
        </Typography>
        <Grid
          container
          item
          justify={user?.id === message.sender ? 'flex-end' : 'flex-start'}>
          <Typography variant="body2">
            {moment(message.createdAt).format('LLL') || ''}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ActionMessage;
