import React, { useState } from 'react';
import {
  Grid,
  IconButton,
  Container,
  Divider,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Telophone from '../../assets/png/Telephone.png';
import { makeStyles, useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import LogoName from './LogoName';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'none',
    maxWidth: '100%',
    margin: '0 auto',
    padding: '0.8rem 0',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      maxWidth: '90%'
    }
  },
  headerRoot: {
    display: 'flex',
    maxWidth: '90%',
    margin: '0 auto',
    padding: '0.8rem 0'
  },
  fitContentDiv: {
    width: 'fit-content'
  },
  navBtn: {
    letterSpacing: '0.095em',
    color: theme.palette.primary.secondary,
    fontSize: '13px',
    fontWeight: '600'
  },
  menuItems: {
    paddingLeft: '25px',
    borderLeft: '2px solid rgba(196, 196, 196, 0.7)',
    borderRadius: 0,
    width: 'fit-content'
  }
}));

const TELEPHONE_NUMBER = '07771038880';
const MENU_ITEMS = [
  // {
  //   url: '/about-us',
  //   title: 'About Us'
  // }
];

export default function Navbar({ hideInSmallScreen = true }) {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <>
      <Grid
        container
        alignItems="center"
        direction="row"
        justify={sm ? 'space-between' : 'center'}
        className={hideInSmallScreen ? classes.root : classes.headerRoot}
        component={Container}>
        <Grid item className={classes.fitContentDiv}>
          <LogoName />
        </Grid>
        {md || !hideInSmallScreen ? (
          <Grid
            container
            item
            justify="flex-end"
            alignItems="center"
            className={classes.fitContentDiv}>
            {MENU_ITEMS.map((item, index) => (
              <Link
                key={`home-navbar-menu-item-${index}`}
                className={classes.navBtn}
                to={item.url}>
                {item.title}
              </Link>
            ))}

            <Grid
              container
              item
              direction="row"
              justify="flex-end"
              className={classes.menuItems}>
              <a href={`tel:${TELEPHONE_NUMBER}`}>
                <IconButton style={{ padding: 0, margin: '0 0.1rem' }}>
                  <img src={Telophone} alt="Telophone" />
                </IconButton>
              </a>
              <Grid
                container
                item
                direction="column"
                justify="center"
                alignItems="flex-end"
                style={{ width: 'fit-content' }}>
                <Typography
                  variant="body2"
                  color="primary"
                  style={{
                    fontSize: '12px',
                    letterSpacing: '0.1rem',
                    fontWeight: 900
                  }}>
                  {TELEPHONE_NUMBER}
                </Typography>
                <Typography variant="body2" color="secondary">
                  Call For Support
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        {/* <Grid item>
          <IconButton color="primary" onClick={() => setShowDrawer(true)}>
            <MenuOpenIcon />
          </IconButton>
        </Grid> */}
      </Grid>

      <Drawer
        anchor="right"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}>
        <div
          style={{ width: '80vw' }}
          role="presentation"
          onClick={() => setShowDrawer(false)}
          onKeyDown={() => setShowDrawer(false)}>
          <Grid
            container
            direction="column"
            style={{ paddingLeft: '0.5rem', paddingTop: '1rem' }}>
            <Grid container item justify="space-between">
              <Grid item xs={6}>
                <LogoName />
              </Grid>
              <IconButton color="primary" onClick={() => setShowDrawer(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <List style={{ margin: '3rem 0' }}>
              <Divider />
              {MENU_ITEMS.map((item, index) => (
                <React.Fragment key={`home-navbar-menu-items-drawer-${index}`}>
                  <ListItem button>
                    <ListItemText primary={item.title} />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>

            <Grid item container style={{ paddingLeft: '0.5rem' }}>
              <Typography>
                <strong>Connect</strong>
                <br />
                000 000 0000
                <br />
                abc@gmail.com
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Drawer>
    </>
  );
}
