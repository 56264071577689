import React, { useState, useEffect } from 'react';
import {
  showPrice,
  unitFormatter,
  getFormattedPriceRange
} from 'common/utilFunctions';
import {
  Typography,
  Box,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Checkbox,
  TextField,
  FormControlLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StickyTableCell } from 'components/molecules';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  autoComplete: {
    margin: theme.spacing(1),
    minWidth: 500
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  paper: {
    padding: 10
  },
  footer: {
    flexGrow: 1,
    padding: 10
  },
  amount: {
    width: 60,
    '& input': {
      padding: '10.5px 8px'
    }
  },
  nameColumn: {
    width: '9rem',
    left: 0
  },
  column: {
    maxWidth: 100
  },
  largeColumn: {
    minWidth: 100
  },
  imgHolder: {
    width: 82,
    height: 82,
    textAlign: 'center',
    border: '1px solid #bbb',
    marginRight: 10,
    position: 'relative'
  },
  image: {
    maxHeight: 80,
    maxWidth: 80,
    transform: 'translateY(-50%)',
    marginTop: '50%'
  },
  imageColumn: {
    width: 50
  },
  iconButton: {
    padding: 10
  },
  imgEditButton: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    background: 'rgba(255,255,255,.75)',
    borderRadius: 0,
    padding: 0
  },
  alignMiddle: {
    verticalAlign: 'middle'
  },
  tableRow: {
    '& .editBtn': {
      visibility: 'hidden'
    },
    '&:hover .editBtn': {
      visibility: 'visible'
    }
  },
  noImage: {
    paddingTop: '5px',
    '& p': {
      fontSize: '10px',
      lineHeight: '12px'
    }
  }
}));

const ItemizeRow = ({ row, order, setOrder }) => {
  const classes = useStyles();
  const [curOrder, setCurOrder] = useState({});
  const [quantityNum, setQuantityNum] = useState(0);
  const [open, setOpen] = useState(false);
  const numArr = Array.from(Array(100).keys());

  useEffect(() => {
    const newOrderItem = order.orderList.find(
      (o) => o.productID === row.productID
    );
    setCurOrder(newOrderItem || {});
    setQuantityNum(newOrderItem ? newOrderItem.quantity : 0);
  }, [row, order]);

  const handleQuantityChange = (event) => {
    setQuantityNum(event.target.value);
    setOrder({
      ...order,
      orderList: order.orderList.map((listItem) =>
        listItem.productID === row.productID
          ? { ...listItem, quantity: event.target.value }
          : listItem
      )
    });
  };

  const handlePriceUpdate = (e) => {
    const re = /^[0-9]+\.?[0-9]{0,2}$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setOrder({
        ...order,
        orderList: order.orderList.map((listItem) =>
          listItem.productID === row.productID
            ? { ...listItem, price: e.target.value }
            : listItem
        )
      });
    }
  };

  const checkHandler = (e) =>
    e.target.checked
      ? setOrder({
          ...order,
          orderList: [
            ...order.orderList,
            {
              codeName: row.codeName,
              productID: row.productID,
              serviceID: row.serviceID,
              name: row.name || row.product.name || row.item?.name || '',
              description:
                row.description ||
                row.product.description ||
                row.item?.description ||
                'N/A',
              discount: 0,
              overallDiscount: 0,
              price: row.product.price,
              priceRange: row.product.priceRange || '',
              quantity: quantityNum || 1,
              unit: row.product.unit
            }
          ]
        })
      : setOrder({
          ...order,
          orderList: order.orderList.filter(
            (item) => item.productID !== row.productID
          )
        });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <TableRow key={row.name} className={classes.tableRow}>
      <StickyTableCell className={classes.nameColumn}>
        <FormControlLabel
          control={
            <Checkbox
              checked={curOrder.productID !== undefined}
              onChange={checkHandler}
            />
          }
          label={
            curOrder.name ||
            row.name ||
            row.product.name ||
            row.item?.name ||
            ''
          }
        />
      </StickyTableCell>
      <TableCell className={classes.column} align="center">
        <Typography>
          {curOrder.description ||
            row.description ||
            row.product.description ||
            row.item?.description ||
            'N/A'}
        </Typography>
      </TableCell>

      <TableCell align="center">
        <Box
          className={classes.amount}
          style={{
            border: '1px solid #ced4da',
            display: 'flex',
            margin: 'auto',
            paddingLeft: '10px'
          }}>
          <Select
            disableUnderline
            id="name"
            labelId="controlled-open-select-label"
            onChange={handleQuantityChange}
            onClose={handleClose}
            onOpen={handleOpen}
            disabled={curOrder.productID === undefined}
            open={open}
            value={quantityNum || 0}>
            {numArr.map((numb, i) => {
              return (
                <MenuItem key={`numb-${i}`} value={numb}>
                  {numb}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      </TableCell>

      <TableCell className={classes.column} align="center">
        <Typography>{curOrder?.unit || row.product.unit || ''}</Typography>
      </TableCell>

      <TableCell
        className={
          !!curOrder?.priceRange ? classes.largeColumn : classes.column
        }
        align="center">
        {!!curOrder?.priceRange ? (
          <TextField
            variant="outlined"
            size="small"
            style={{ maxWidth: '9rem' }}
            value={curOrder.price || ''}
            placeholder={getFormattedPriceRange(curOrder.priceRange)}
            onChange={handlePriceUpdate}
          />
        ) : (
          <Typography>
            {!row.product?.price
              ? getFormattedPriceRange(
                  curOrder?.priceRange || row.product.priceRange || '0-0'
                )
              : showPrice(curOrder?.price || row.product.price || 0)}
          </Typography>
        )}
      </TableCell>
      <TableCell className={classes.column} align="center">
        <Typography>
          {curOrder.discount
            ? `${showPrice(curOrder.discount || 0)} ${unitFormatter(
                curOrder.unit
              )}`.toLowerCase()
            : curOrder.overallDiscount
            ? `${showPrice(curOrder.overallDiscount)}`
            : 0}
        </Typography>
      </TableCell>
      <TableCell className={classes.column} align="center">
        <Typography>
          {showPrice(
            (quantityNum || 0) * (curOrder?.price || row.product.price || 0) -
              (curOrder.overallDiscount || 0)
          )}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default ItemizeRow;
