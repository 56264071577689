import React from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Divider,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LaunchIcon from '@material-ui/icons/Launch';
import { useAmplifyAuth } from 'context';
import { Link } from 'react-router-dom';
import { Timer } from 'components/molecules';
import { showPrice } from 'common/utilFunctions';
import { routes } from 'common/constants';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const VIEW_DETAILS_BY_STATUS = [
  {
    subHeaderDate: 'startDateTime',
    subHeaderTitle: 'Starting',
    timerTitle: 'Starts ',
    timerTime: 'startDateTime'
  },
  {
    subHeaderDate: 'startDateTime',
    subHeaderTitle: 'Started',
    timerTitle: 'Ends ',
    timerTime: 'endDateTime'
  },
  {
    subHeaderDate: 'endDateTime',
    subHeaderTitle: 'Ended',
    timerTitle: '',
    timerTime: ''
  },
  {
    subHeaderDate: 'endDateTime',
    subHeaderTitle: 'Ended',
    timerTitle: '',
    timerTime: ''
  }
];

const useStyles = makeStyles((theme) => ({
  bidValueRow: {
    padding: '1.5rem 0 0'
  },
  rootHeader: {
    padding: '16px 16px 5px'
  },
  noBidsText: {
    color: theme.palette.grey[400]
  },
  orderPlacedTextBox: {
    backgroundColor: '#EDD242',
    padding: '0.25rem 0.5rem',
    borderRadius: '0.25rem'
  },
  ordersLink: {
    fontSize: '11px',
    color: 'blue',
    textDecoration: 'underline',
    fontWeight: 600
  },
  unplacedAuction: {
    backgroundColor: '#ff3d3d',
    padding: '0.5rem',
    marginLeft: '1rem',
    color: '#fff',
    borderRadius: 4,
    fontSize: 14
  },
  selectAuctionText: {
    width: '100%',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 600,
    color: '#444',
    marginBottom: '1rem',
    textTransform: 'uppercase'
  },
  colorTextSecondary: {
    fontWeight: 600,
    color: '#194376 ',
    fontSize: '13px',
    lineHeight: '1.2rem'
  },
  noPadding: {
    padding: 0
  },
  lowestBidStyle: {
    padding: '0.5rem',
    backgroundColor: '#edd242',
    borderRadius: '0.25rem'
  },
  auctionTimeTypeStyle: {
    textTransform: 'uppercase',
    fontWeight: 600,
    color: '#194376'
  },
  BoldLightText: {
    fontWeight: 600,
    color: '#444'
  }
}));

const getCardSubHeader = (title = '', date) =>
  `${title} at ${new Date(date).toLocaleDateString()}, ${new Date(
    date
  ).toLocaleTimeString()}`;

const AuctionCard = ({
  item,
  className = '',
  actions = [],
  status = 1 // auction status : { 0:Pending, 1:Ongoing, 2:Expired, 3:Completed }
}) => {
  const {
    state: { isLaundryAdmin, isLaundryStaff }
  } = useAmplifyAuth();
  const classes = useStyles();
  const view_details = VIEW_DETAILS_BY_STATUS[status];

  const completedAuctionOrder = item.bids.items
    .map((item) => item.orders.items[0])
    .find(Boolean);

  const redirectRoute = ['declined', 'delivered', 'cancelled'].includes(
    completedAuctionOrder?.status
  )
    ? `${routes.customer.orderHistory}?orderID=${completedAuctionOrder?.orderID}`
    : `${routes.customer.order}?orderID=${completedAuctionOrder?.orderID}`;

  return (
    <Card className={className}>
      <CardHeader
        action={
          !!(isLaundryAdmin || isLaundryStaff) ? (
            <></>
          ) : item.bids.items.some((_bid) => _bid.orders.items.length > 0) ? (
            <Grid
              container
              alignItems="center"
              direction="column"
              className={classes.orderPlacedTextBox}>
              <Typography variant="h6" className={classes.BoldLightText}>
                Order Placed
              </Typography>
              <Link to={redirectRoute} className={classes.ordersLink}>
                Go to orders <LaunchIcon style={{ fontSize: '0.5rem' }} />
              </Link>
            </Grid>
          ) : (
            <></>
          )
        }
        title={
          <div className={classes.BoldLightText}>
            <span>Auction #{item.auctionID}</span>
            {!!item.collection?.date &&
              status === 2 &&
              new Date(item.collection.date) < new Date() && (
                <span className={classes.unplacedAuction}>
                  Collection Date Expired
                </span>
              )}
          </div>
        }
        subheader={getCardSubHeader(
          view_details.subHeaderTitle,
          item[view_details.subHeaderDate]
        )}
        classes={{
          root: classes.rootHeader,
          subheader: classes.colorTextSecondary
        }}
      />
      <CardContent>
        {[0, 1].includes(status) ? (
          <Grid container alignItems="center" style={{ marginTop: '10px' }}>
            <Grid item xs={12} md={2} className={classes.auctionTimeTypeStyle}>
              <Grid container>
                {view_details.timerTitle}
                in <ArrowForwardIcon />
              </Grid>
            </Grid>
            <Grid item xs={12} md={10}>
              <Timer endTime={new Date(item[view_details.timerTime])} />
            </Grid>
          </Grid>
        ) : null}
        {[1, 2].includes(status) ? (
          <Grid
            container
            justify="center"
            className={
              [0, 1].includes(status) ? classes.bidValueRow : classes.noPadding
            }>
            {item.bids.items.length > 0 ? (
              <>
                {!(isLaundryAdmin || isLaundryStaff) && (
                  <>
                    <Typography
                      variant="h6"
                      className={classes.selectAuctionText}>
                      Place your bids
                    </Typography>
                    <br />
                  </>
                )}
                <Typography
                  variant="subtitle1"
                  className={classes.lowestBidStyle}>
                  Lowest bid price until now:{' '}
                  <strong>
                    {showPrice(
                      item.bids.items.reduce(
                        (p, c) => (p < c.totalPrice ? p : c.totalPrice),
                        item.bids.items[0].totalPrice
                      )
                    )}
                  </strong>
                </Typography>
              </>
            ) : (
              <Typography variant="subtitle1" className={classes.noBidsText}>
                No bids placed yet
              </Typography>
            )}
          </Grid>
        ) : null}
      </CardContent>

      <Divider />

      <CardActions>
        <Grid container justify="flex-end" style={{ gap: '0.5rem' }}>
          {actions.map(({ title, ...actionProps }, index) => (
            <Button
              size="small"
              {...actionProps}
              key={`auction-card-actions-items-${index}`}>
              {title}
            </Button>
          ))}
        </Grid>
      </CardActions>
    </Card>
  );
};

export default AuctionCard;
