import React, { createContext, useState, useContext } from "react";


const LoaderContext = createContext({});

const LoaderProvider = props => {

  const [ loading, setLoading] = useState(false)
  const value = {
    loading, setLoading
  };
  return <LoaderContext.Provider value={value}>{props.children}</LoaderContext.Provider>;

}

function useLoader() {
  const context = useContext(LoaderContext)
  if (context === undefined) {
    throw new Error('useLoader must be used within a ServicesProvider')
  }
  return context
}

export { LoaderProvider, useLoader }