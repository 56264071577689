import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DesktopAccessDisabledIcon from '@material-ui/icons/DesktopAccessDisabled';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));

const ShopsCharts = (props) => {
  const {
    className,
    pendingShops,
    activeShops,
    onHoldShops,
    otherShops,
    ...rest
  } = props;

  const classes = useStyles();
  const theme = useTheme();

  const getPercentageOf = (targetShop) => {
    const result = (targetShop / totalShops) * 100;
    return result.toFixed(1);
  };
  const totalShops = pendingShops + activeShops + onHoldShops + otherShops;
  const pendingPercentage = getPercentageOf(pendingShops);
  const activePercentage = getPercentageOf(activeShops);
  const onHoldPercentage = getPercentageOf(onHoldShops);
  const otherShopsPercentage = getPercentageOf(otherShops);

  const data = {
    datasets: [
      {
        data: [
          pendingPercentage,
          activePercentage,
          onHoldPercentage,
          otherShopsPercentage
        ],
        backgroundColor: ['#fb8c00', '#198754', '#3f51b5', '#E50000'],
        borderWidth: 8,
        borderColor: '#fff',
        hoverBorderColor: '#fff'
      }
    ],
    labels: ['Pending', 'Active', 'Offline', 'Other']
  };

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

  const devices = [
    {
      title: 'Pending',
      value: pendingPercentage,
      icon: <HourglassEmptyIcon />,
      color: '#fb8c00'
    },
    {
      title: 'Active',
      value: activePercentage,
      icon: <CheckCircleOutlineIcon />,
      color: '#198754'
    },
    {
      title: 'Offline shops',
      value: onHoldPercentage,
      icon: <DesktopAccessDisabledIcon />,
      color: '#3f51b5'
    },
    {
      title: 'Other shops',
      value: otherShopsPercentage,
      icon: <ErrorOutlineIcon />,
      color: '#E50000'
    }
  ];

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader style={{ textAlign: 'center' }} title="Shops Data" />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Doughnut data={data} options={options} />
        </div>
        <div className={classes.stats}>
          {devices.map((device) => (
            <div className={classes.device} key={device.title}>
              <span className={classes.deviceIcon}>{device.icon}</span>
              <Typography variant="body1">{device.title}</Typography>
              <Typography style={{ color: device.color }} variant="h4">
                {device.value}%
              </Typography>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

ShopsCharts.propTypes = {
  className: PropTypes.string
};

export default ShopsCharts;
