import { API, graphqlOperation } from 'aws-amplify';
import { getNotificationByShop } from 'graphql/queries';
import { markNotificationsAsRead as markAllNotificationsAsRead } from 'graphql/mutations';

export const getNotifications = (payload = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await API.graphql(
        graphqlOperation(getNotificationByShop, { shopID: payload.shopID })
      );
      const data = await res.data.getNotificationByShop.items.filter(
        (item) => !item.isRead
      );
      resolve(data);
    } catch (error) {
      console.log('something went wrong', error);
      reject(error);
    }
  });
};

export const markNotificationsAsRead = (payload = {}) =>
  new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(markAllNotificationsAsRead, payload))
      .then((data) => resolve(data.data.markNotificationsAsRead))
      .catch((err) => reject(err));
  });
