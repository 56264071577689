import React, {
  useState,
  createContext,
  useCallback,
  useContext,
  useReducer
} from 'react';
import { useSnackbar } from 'notistack';
import { useLoader } from 'layouts/loaderContext';
import { auctionsReducer } from './auctionsReducer';
import * as auctionAPIs from './auctionsAPIs';

const AuctionsContext = createContext({});

const AuctionsProvider = (props) => {
  const [auctions, dispatch] = useReducer(auctionsReducer, []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [auctionShop, setAuctionShop] = useState(null);
  const { setLoading } = useLoader();

  const asyncDispatch = useCallback(
    async (action) => {
      switch (action.type) {
        case 'getAuctions': {
          setLoading(true);
          const snackBar = enqueueSnackbar('Loading Auctions...', {
            variant: 'info',
            persist: true,
            preventDuplicate: true
          });
          try {
            const resp = await auctionAPIs.loadAuctions({ limit: 1000 });
            const data = resp.items
              .filter((item) => !item._deleted)
              .sort((a, b) => b._lastChangedAt - a._lastChangedAt);
            dispatch({
              type: 'updateData',
              payload: data
            });
          } catch (error) {
            console.error('Something went wrong... ', error);
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2000
            });
          } finally {
            setLoading(false);
            closeSnackbar(snackBar);
          }
          break;
        }
        case 'getAuctionsByUser': {
          const { userID } = action.payload;
          setLoading(true);
          const snackBar = enqueueSnackbar('Loading Auctions...', {
            variant: 'info',
            persist: true,
            preventDuplicate: true
          });
          try {
            if (!userID) {
              throw new Error('invalid user');
            }
            const resp = await auctionAPIs.loadAuctionsByUser({
              limit: 5000,
              userID
            });
            const data = resp.items
              .filter((item) => !item._deleted)
              .sort((a, b) => b._lastChangedAt - a._lastChangedAt);
            dispatch({
              type: 'updateData',
              payload: data
            });
          } catch (error) {
            console.error('Something went wrong... ', error);
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2000
            });
          } finally {
            setLoading(false);
            closeSnackbar(snackBar);
          }
          break;
        }
        case 'getAuctionsByShop': {
          let snackBar;
          const { shopID } = action.payload;
          setLoading(true);
          try {
            if (!shopID) throw new Error('invalid shop');
            if (auctionShop === shopID) return;
            setAuctionShop(shopID);
            snackBar = enqueueSnackbar('Loading Auctions...', {
              variant: 'info',
              persist: true,
              preventDuplicate: true
            });
            const resp = await auctionAPIs.loadAuctionsByShop({
              limit: 8000,
              shopID
            });
            const data = resp.items
              .map((_item) => _item.auction)
              .filter((item) => !item._deleted)
              .sort((a, b) => b._lastChangedAt - a._lastChangedAt);

            dispatch({
              type: 'updateData',
              payload: data
            });
          } catch (error) {
            console.error('Something went wrong... ', error);
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2000
            });
          } finally {
            setLoading(false);
            closeSnackbar(snackBar);
          }
          break;
        }
        case 'createAuction': {
          setLoading(true);
          const { auction, successCallback = () => {} } = action.payload;
          const { products, ...payload } = auction;

          // getting shops nearby
          let shops = [];
          const snackBar0 = enqueueSnackbar(
            'Getting shops in 5 mile radius to inform about the auction...',
            {
              variant: 'info',
              persist: true,
              preventDuplicate: true
            }
          );
          try {
            shops = await auctionAPIs.loadShops({
              postalCode: payload.postcode,
              range: '5'
            });
          } catch (error) {
            console.error('Something went wrong... ', error);
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2000
            });
            return;
          } finally {
            closeSnackbar(snackBar0);
          }
          if (shops.length === 0) {
            enqueueSnackbar(
              "No shops found ;(... Auction couldn't be created...",
              {
                variant: 'error',
                autoHideDuration: 2000,
                persist: true
              }
            );
            return;
          }

          // creating auction
          const snackBar = enqueueSnackbar('Saving Auction...', {
            variant: 'info',
            persist: true,
            preventDuplicate: true
          });
          try {
            const auctionData = await auctionAPIs.createAuction(payload);
            const itemsPromises = await Promise.allSettled(
              products.map(async (item) => {
                const { service, ...input } = item;
                return await auctionAPIs.createAuctionItem({
                  ...input,
                  auctionID: auctionData.id
                });
              })
            );
            const itemsData = itemsPromises
              .filter((promise) => promise.status === 'fulfilled')
              .map((promise) => promise.value);
            dispatch({
              type: 'addData',
              payload: [{ ...auctionData, items: { items: itemsData } }]
            });
            enqueueSnackbar('Auction saved successfully', {
              variant: 'success',
              autoHideDuration: 2500
            });
            closeSnackbar(snackBar);

            // informing shops
            const snackBar1 = enqueueSnackbar('Informing Shops...', {
              variant: 'info',
              persist: true,
              preventDuplicate: true
            });
            await Promise.allSettled(
              shops.map(
                async (_shop) =>
                  await auctionAPIs.createAuctionShop({
                    shopID: _shop.id,
                    auctionID: auctionData.id
                  })
              )
            );
            closeSnackbar(snackBar1);

            // finishing
            successCallback();
          } catch (error) {
            console.error('Something went wrong... ', error);
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2000
            });
          } finally {
            setLoading(false);
          }
          break;
        }
        case 'createBid': {
          setLoading(true);
          const snackBar = enqueueSnackbar('Saving Bid...', {
            variant: 'info',
            persist: true,
            preventDuplicate: true
          });
          try {
            const { bid, successCallback = () => {} } = action.payload;
            const { items, ...payload } = bid;
            const bidData = await auctionAPIs.createBid(payload);
            const itemsPromises = await Promise.allSettled(
              items.map(async (item) => {
                const { service, ...input } = item;
                return await auctionAPIs.createBidItem({
                  ...input,
                  bidID: bidData.id
                });
              })
            );
            const itemsData = itemsPromises
              .filter((promise) => promise.status === 'fulfilled')
              .map((promise) => promise.value);
            dispatch({
              type: 'updateData',
              payload: auctions.map((_auction) =>
                _auction.id === bidData.auctionID
                  ? {
                      ..._auction,
                      bids: {
                        ..._auction.bids,
                        items: [
                          ..._auction.bids.items,
                          { ...bidData, items: { items: itemsData } }
                        ]
                      }
                    }
                  : _auction
              )
            });
            enqueueSnackbar('Bid saved successfully', {
              variant: 'success',
              autoHideDuration: 2500
            });
            successCallback();
          } catch (error) {
            console.error('Something went wrong... ', error);
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2000
            });
          } finally {
            setLoading(false);
            closeSnackbar(snackBar);
          }
          break;
        }
        case 'updateAuction': {
          setLoading(true);
          const snackBar = enqueueSnackbar('Updating Auction...', {
            variant: 'info',
            persist: true,
            preventDuplicate: true
          });
          try {
            const {
              auction,
              successCallback = () => {},
              successMessage = 'Auction updated successfully'
            } = action.payload;
            const auctionData = await auctionAPIs.updateAuction(auction);
            dispatch({
              type: 'updateData',
              payload: auctions.map((item) =>
                item.id === auction.id
                  ? { ...item, ...auction, _version: auctionData._version }
                  : item
              )
            });
            enqueueSnackbar(successMessage, {
              variant: 'success',
              autoHideDuration: 2500
            });
            successCallback();
          } catch (error) {
            console.error('Something went wrong... ', error);
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2000
            });
          } finally {
            setLoading(false);
            closeSnackbar(snackBar);
          }
          break;
        }
        case 'deleteAuction': {
          setLoading(true);
          const { auctionID = '' } = action.payload;
          const auction = auctions.find((item) => item.id === auctionID);
          if (!auction) return;
          try {
            await auctionAPIs.deleteAuction({
              id: auction.id,
              _version: auction._version
            });
            await Promise.allSettled(
              auction.items.items.map(async (item) => {
                const { id, _version } = item;
                return await auctionAPIs.deleteAuctionItem({ id, _version });
              })
            );
            const updatedAuctions = auctions.filter(
              (item) => item.id !== auctionID
            );
            dispatch({
              type: 'updateData',
              payload: updatedAuctions
            });
            enqueueSnackbar('Auction Deleted', {
              variant: 'success',
              autoHideDuration: 2500
            });
          } catch (error) {
            console.error('Something went wrong... ', error);
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2000
            });
          } finally {
            setLoading(false);
          }
          break;
        }
        default:
          dispatch(action);
      }
    },
    [auctions]
  );

  const value = {
    auctions,
    dispatch: asyncDispatch
  };

  return (
    <AuctionsContext.Provider value={value}>
      {props.children}
    </AuctionsContext.Provider>
  );
};

const useAuctions = () => {
  const context = useContext(AuctionsContext);
  if (!(context && Object.keys(context).length)) {
    throw new Error('useAuctions must be used within a AuctionsContext');
  }
  return context;
};

export { useAuctions, AuctionsProvider };
