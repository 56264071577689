import React from 'react';
import PropTypes from 'prop-types';
import SubSidebarNav from './components/SubSidebarNav';
import { useAmplifyAuth } from 'context';
import { Provider } from '../../context/shopDetail/shopContext';

const ShopDetails = () => {
  let {
    state: { user }
  } = useAmplifyAuth();

  return (
    <Provider>
      {user && (
        <SubSidebarNav pageType="shop" shopId={user.shopId || user.shopID} />
      )}
    </Provider>
  );
};

ShopDetails.propTypes = {
  className: PropTypes.string
};

export default ShopDetails;
