import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { Storage } from 'aws-amplify';
import { Image } from 'components/organisms';
import ShopPhotosAndVideos from './ShopPhotosAndVideos';
import { useServices } from 'views/Services/serviceContext';
import { useShops } from 'context/shop/shopsContext';

const useStyles = makeStyles(() => ({
  root: {
    padding: '1rem 0'
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
    backgroundColor: ' #ffffff',
    height: 150
  },
  image: {
    height: '10rem',
    width: '14rem',
    boxShadow:
      'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
    margin: '1rem'
  }
}));

function UploadedShopPhotos(props) {
  const { selectedShop, setSelectedShop } = props;
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);
  const { dispatch } = useShops();
  const { services, dispatch: dispatchService } = useServices();
  const classes = useStyles();

  useEffect(() => {
    setPhotos(selectedShop?.photos || []);
    setVideos(selectedShop?.videos || []);
  }, [selectedShop]);

  useEffect(() => {
    services?.length <= 0 && dispatchService({ type: 'getService' });
  }, [dispatchService]);

  const handleDefaultPhotoUpdate = (el, category = 'primaryPhoto') => {
    const input = {
      id: selectedShop.id,
      _version: selectedShop._version
    };
    input[category] = el;

    dispatch({
      type: 'updateShopInDB',
      payload: {
        input,
        successMessage: 'Default photo updated',
        successCallback: (data) => setSelectedShop(data)
      }
    });
  };

  const handleUpdate = (url = '', status, mimeType = 'images') => {
    let input = {
      id: selectedShop.id,
      _version: selectedShop._version
    };
    input[mimeType === 'images' ? 'photos' : 'videos'] =
      selectedShop[mimeType === 'images' ? 'photos' : 'videos']
        ?.filter((item) => item)
        .map((item) => ({
          ...item,
          status:
            item[mimeType === 'images' ? 'photoURL' : 'videoURL'] === url
              ? status
              : item.status
        })) || [];

    dispatch({
      type: 'updateShopInDB',
      payload: {
        input,
        hideSnackbar: true,
        successCallback: (data) => setSelectedShop(data)
      }
    });
  };

  const handleDelete = (url, type = 'image') => {
    let input = {
      id: selectedShop.id,
      _version: selectedShop._version
    };
    if (type.includes('image')) {
      Storage.remove(
        selectedShop.photos?.find((photo) => photo?.photoURL === url)?.photoURL,
        { level: 'public' }
      );
      input.photos =
        selectedShop.photos?.filter((item) => item?.photoURL !== url) || [];
      if (selectedShop?.primaryPhoto?.photoURL === url) {
        input.primaryPhoto = null;
      }
    } else {
      Storage.remove(
        selectedShop.videos?.find((video) => video?.videoURL === url)?.videoURL,
        { level: 'public' }
      );
      input.videos =
        selectedShop.videos?.filter((item) => item?.videoURL !== url) || [];
    }

    dispatch({
      type: 'updateShopInDB',
      payload: {
        input,
        hideSnackbar: true,
        successCallback: (data) => setSelectedShop(data)
      }
    });
  };

  return (
    <Box className={classes.root}>
      {selectedShop?.primaryPhoto?.photoURL ? (
        <Grid
          item
          container
          direction="column"
          alignItems="flex-start"
          justify="space-between"
          xs={12}
          sm={6}
          md={4}
          style={{ marginBottom: '2rem' }}>
          <Typography variant="h5">Default Photo</Typography>
          <Image
            docKey={selectedShop?.primaryPhoto?.photoURL}
            alt={selectedShop?.primaryPhoto?.name || 'shop default photo'}
            mimeType={selectedShop?.primaryPhoto?.MIMETypes}
            className={classes.image}
          />
        </Grid>
      ) : null}
      <ShopPhotosAndVideos
        heading="Shop Photos"
        data={photos}
        primaryPhoto={selectedShop?.primaryPhoto}
        handleDelete={handleDelete}
        handleUpdate={handleUpdate}
        handleDefaultPhotoUpdate={handleDefaultPhotoUpdate}
      />

      <ShopPhotosAndVideos
        heading="Shop Videos"
        data={videos}
        handleDelete={handleDelete}
        handleUpdate={handleUpdate}
      />
    </Box>
  );
}

export default UploadedShopPhotos;
