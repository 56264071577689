import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const Context = createContext({});

export const Provider = props => {
  // Initial values are obtained from the props
  const {
    shops: initialShops,
    selectedShop: initialSelectedShop,
    children
  } = props;

  // Use State to keep the values
  const [availableShops, setAvailableShops] = useState(initialShops);
  const [selectedShop, setSelectedShop] = useState(initialSelectedShop);

  // Make the context object:
  const shopContext = {
    availableShops,
    setAvailableShops,
    selectedShop,
    setSelectedShop
  };

  // pass the value in provider and return
  return <Context.Provider value={shopContext}>{children}</Context.Provider>;

}

export const { Consumer } = Context;

Provider.propTypes = {
  shops: PropTypes.array,
  selectedShop: PropTypes.object
};

Provider.defaultProps = {
  shops: [],
  selectedUser: {}
};