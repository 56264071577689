import React from 'react';
import { OrderDetailContent } from 'components/organisms';
import { Grid, Modal, Backdrop, Fade, Container } from '@material-ui/core';

const OrderDetailsDialog = ({
  dialogType,
  handleModalClose = () => {},
  order,
  updateOrder,
  showOrderDetails = false,
  isOrdersModal = true,
  showClosedBtn = false
}) => {
  const isOpen = showOrderDetails || dialogType === 'details';

  return order !== undefined ? (
    <Modal
      aria-describedby="message-modal-description"
      aria-labelledby="message-modal-title"
      disableEscapeKeyDown
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition
      onClose={handleModalClose}
      open={isOpen}>
      <Container maxWidth="md" style={{ border: 'none', outline: 'none' }}>
        <Fade in={isOpen}>
          <Grid container spacing={3}>
            <Grid container item justify="flex-end" alignItems="center"></Grid>
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{
                backgroundColor: 'white'
              }}>
              {/* order details */}
              <OrderDetailContent
                order={order}
                updateOrder={updateOrder}
                isOrdersModal={isOrdersModal}
                handleClose={handleModalClose}
                showClosedBtn={showClosedBtn}
              />
            </Grid>
          </Grid>
        </Fade>
      </Container>
    </Modal>
  ) : null;
};

export default OrderDetailsDialog;
