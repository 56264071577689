import React from 'react';
import { Box, Grid } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  userMessageBox: {
    padding: '0.5rem 1rem',
    marginBottom: '5px',
    color: '#000',
    backgroundColor: '#EAFCFE',
    borderRadius: '15px 15px 0 15px'
  }
}));

const UserMessage = props => {
  const { userMessage } = props;
  const classes = useStyles();

  return (
    <Box>
      {userMessage?.map((texts, i) => {
        return (
          <Grid
            container
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-end"
            className={classes.userMessageBox}
            key={i}>
            {texts.text}
          </Grid>
        );
      })}
    </Box>
  );
};

export default UserMessage;
