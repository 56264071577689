import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  name: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    verticalAlign: 'top',
    overflow: 'hidden'
  }
}));

const TemplateFooter = ({ shopName = '' }) => {
  const classes = useStyles();

  return !!shopName ? (
    <Grid container alignItems="flex-end" style={{ height: '15px' }}>
      <Typography variant="body1" title={shopName} className={classes.name}>
        <span style={{ fontWeight: '550' }}>Ad</span>
        {shopName ? <>&nbsp;&#183; {shopName}</> : ''}
      </Typography>
    </Grid>
  ) : null;
};

export default TemplateFooter;
