import { routes } from 'common/constants';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import GavelOutlinedIcon from '@material-ui/icons/GavelOutlined';
import TimerOffOutlinedIcon from '@material-ui/icons/TimerOffOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';

export const TABS = [
  {
    name: 'Scheduled Auctions',
    icon: <AccessAlarmsIcon fontSize="small" />,
    route: routes.customer.auctionPending,
    filter: (_auction) =>
      !_auction.isClosed && new Date(_auction.startDateTime) > new Date()
  },
  {
    name: 'Ongoing Auctions',
    icon: <GavelOutlinedIcon fontSize="small" />,
    route: routes.customer.auction,
    filter: (_auction) =>
      !_auction.isClosed &&
      new Date(_auction.startDateTime) < new Date() &&
      new Date(_auction.endDateTime) > new Date()
  },
  {
    name: 'Expired Auctions',
    icon: <TimerOffOutlinedIcon fontSize="small" />,
    route: routes.customer.auctionExpired,
    filter: (_auction) =>
      (_auction.isClosed || new Date(_auction.endDateTime) < new Date()) &&
      _auction.bids.items.every((_bid) => _bid.orders.items.length === 0)
  },
  {
    name: 'Completed Auctions',
    icon: <AttachMoneyOutlinedIcon fontSize="small" />,
    route: routes.customer.auctionCompleted,
    filter: (_auction) =>
      (_auction.isClosed || new Date(_auction.endDateTime) < new Date()) &&
      _auction.bids.items.some((_bid) => _bid.orders.items.length > 0)
  }
];

export const ITEMS_PER_PAGE = 5;
