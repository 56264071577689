import React, { useEffect, useState } from 'react';
import {
  Grid,
  ListItem,
  ListItemText,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useServices } from 'views/Services/serviceContext';
import { Link } from 'react-router-dom';
import { getStringAsSlug } from 'common/utilFunctions';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  listHeading: {
    fontSize: 16,
    fontWeight: 800,
    color: '#fff',
    marginTop: '1.5rem'
  },
  subListHeading: {
    fontSize: 14,
    fontWeight: 800,
    color: '#edd242',
    margin: '1rem 0 0.5rem'
  },
  listItem: {
    padding: 0
  },
  listTextTopService: {
    textTransform: 'capitalize',
    fontSize: 14,
    color: '#C4C4C4'
  }
});

const FooterServices = () => {
  const classes = useStyles();
  const [postalCode, setPostalCode] = useState(null);
  const { services = [], dispatch } = useServices();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (services?.length === 0) {
      dispatch({ type: 'getService', payload: { showLoader: false } });
    }
  }, []);

  useEffect(() => {
    const postalAddress = localStorage.getItem('recentPostCode');
    if (postalAddress) {
      setPostalCode(postalAddress);
    }
  }, []);

  const domesticServices = services?.filter(
    (service) => service.type === 'domestic'
  );
  const commercialServices = services?.filter(
    (service) => service.type === 'commercial'
  );

  const serviceLinkHandler = () => {
    if (!postalCode) {
      enqueueSnackbar(
        'Please enter your postal code to find the shop with the services you require',
        {
          variant: 'info',
          persist: true,
          preventDuplicate: true
        }
      );
    }
    window.scrollTo(0, 0);
  };

  return (
    <Grid container item>
      <Typography variant="h6" className={classes.listHeading}>
        Our Services
      </Typography>

      <Grid container item justify="space-between">
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.subListHeading}>
            Domestic Services
          </Typography>

          <Grid container item xs={12}>
            {domesticServices?.map((service, i) => {
              const redirectLink = postalCode
                ? `/shop/postcode/${postalCode}/service/${getStringAsSlug(
                    service.name
                  )}`
                : '/home';

              return (
                <Grid item md={3} sm={4} xs={6} key={i}>
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      primary={
                        <Typography variant="h6">
                          <Link
                            to={redirectLink}
                            onClick={serviceLinkHandler}
                            className={classes.listTextTopService}>
                            {service.name}
                          </Link>
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.subListHeading}>
            Commercial Services
          </Typography>
          <Grid container item xs={12}>
            {commercialServices.map((service, i) => {
              const redirectLink = postalCode
                ? `/shop/postcode/${postalCode}/service/${getStringAsSlug(
                    service.name
                  )}`
                : '/home';

              return (
                <Grid item md={3} sm={4} xs={6} key={i}>
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      primary={
                        <Typography variant="h6">
                          <Link
                            to={redirectLink}
                            onClick={serviceLinkHandler}
                            className={classes.listTextTopService}>
                            {service.name}
                          </Link>
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FooterServices;
