import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useServices } from 'views/Services/serviceContext';
import { List, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import parse from 'autosuggest-highlight/parse';
// import locationIcon from '../assets/SR_locationIcon.png';
import throttle from 'lodash/throttle';
import Filter from './Filters';

const INITIAL_FILTERS = [
  {
    id: 'shops',
    name: 'Shops',
    filterName: 'shops', // name in filters state
    show: true,
    items: [{ id: 'open', name: 'Open Now' }]
  },
  {
    id: 'distances',
    name: 'Distances',
    show: false,
    items: [
      { id: 2, name: '2 miles' },
      { id: 4, name: '4 miles' },
      { id: 5, name: '5 miles' },
      { id: 6, name: '6 miles' },
      { id: 8, name: '8 miles' },
      { id: 10, name: '10 miles' }
    ]
  }
];

const autocompleteService = { current: null };

function loadScript(src, position, id) {
  if (!position) {
    return;
  }
  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'fit-content',
    [theme.breakpoints.down('md')]: {
      padding: '0 22px'
    }
  },
  button: {
    backgroundColor: '#A2D1D733',
    color: theme.palette.primary.main,
    lineHeight: 'none',
    minWidth: 'unset',
    fontSize: '10px',
    fontWeight: '700',
    borderRadius: '15px',
    boxShadow: 'none'
  },
  loremText: {
    fontSize: '14px',
    fontWeight: '500'
  },
  changeBtn: {
    color: theme.palette.blue.main
  },
  postCodeFieldRoot: {
    marginTop: '10px !important',
    paddingBottom: '16px',
    '&:before': {
      borderBottom: '1px solid rgb(196 196 196/0.5)'
    }
  },
  distanceCheckBoxRoot: {
    marginLeft: '-0.5rem'
  }
}));

// callback to format service with subservices
const formatServiceWithSubServices = (services) => (_service) => ({
  ..._service,
  subFilters: services
    .filter((_item) => _item.serviceID === _service.id)
    .map(formatServiceWithSubServices(services))
});

const ShopFilters = (props) => {
  const {
    shopFilters,
    setShopFilters = () => {},
    resetFilters = () => {},
    showResetFiltersBtn = false
  } = props;
  const classes = useStyles();
  let { postCode } = useParams();
  let history = useHistory();
  const { services = [] } = useServices();
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(postCode);
  const [inputValue, setInputValue] = useState(postCode);
  const loaded = React.useRef(false);
  const [disablePostcodeEdit, setDisablePostcodeEdit] = useState(true);
  const [openFilters, setOpenFilters] = useState({
    services: true,
    domestic: true,
    commercial: true,
    shops: true
  });
  const [allFilters, setAllFilters] = useState(INITIAL_FILTERS);

  useEffect(() => {
    const domesticServices = services
      .filter((el) => (!el.type || el.type === 'domestic') && !el.serviceID)
      .map(formatServiceWithSubServices(services));
    const commercialServices = services
      .filter((el) => el.type === 'commercial' && !el.serviceID)
      .map(formatServiceWithSubServices(services));

    setAllFilters([
      ...INITIAL_FILTERS,
      {
        id: 'services',
        name: 'Services',
        filterName: 'services',
        show: true,
        subFilters: [
          {
            id: 'domestic',
            name: 'Domestic',
            filterName: 'services',
            show: true,
            items: domesticServices,
            stackItems: true
          },
          {
            id: 'commercial',
            name: 'Commercial',
            filterName: 'services',
            show: true,
            items: commercialServices,
            stackItems: true
          }
        ]
      }
    ]);
  }, [services]);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }
    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  const navigateTo = (value) => history.push(`/shop/postcode/${value}`);

  // use for go button, merge functionalities
  const onClickHandler = () => {
    setDisablePostcodeEdit(!disablePostcodeEdit);
    if (value && !disablePostcodeEdit) {
      localStorage.setItem('recentPostCode', value);
      navigateTo(value);
    }
  };

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current) {
      if (window.google?.maps?.places) {
        autocompleteService.current = new window.google.maps.places.AutocompleteService();
      } else {
        return undefined;
      }
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        componentRestrictions: { country: 'GB' }
      },
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const enterSearchHandler = (e) => {
    if (e.key === 'Enter' && value) {
      navigateTo(value);
    }
  };

  return (
    <Grid container className={classes.root}>
      {/* temporarily hiding the option to update postal code */}
      {/* <Grid
        container
        item
        justify="flex-start"
        style={{ width: '100%', marginBottom: 10 }}>
        <Autocomplete
          autoComplete
          autoSelect
          disabled={disablePostcodeEdit}
          disableClearable
          filterOptions={(x) => x}
          filterSelectedOptions
          getOptionLabel={(option) =>
            typeof option === 'string' ? option : option.description
          }
          includeInputInList
          onChange={(event, newValue) => {
            setOptions(newValue ? [newValue, ...options] : options);
            if (newValue?.terms?.length > 0) {
              const len = newValue.terms.length;
              const val = newValue.terms[len - 2]?.value;
              setValue(val);
            }
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={options}
          renderInput={(params) => (
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={2}>
                <img
                  src={locationIcon}
                  alt="location icon"
                  height="26px"
                  width="28px"
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  {...params}
                  fullWidth
                  onChange={enterSearchHandler}
                  onKeyDown={enterSearchHandler}
                />
              </Grid>

              <Grid item xs={2}>
                <Button onClick={onClickHandler} className={classes.changeBtn}>
                  {disablePostcodeEdit ? 'Change' : 'Go'}
                </Button>
              </Grid>
            </Grid>
          )}
          renderOption={(option) => {
            const matches =
              option.structured_formatting.main_text_matched_substrings;
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match) => [
                match.offset,
                match.offset + match.length
              ])
            );

            return (
              <Grid alignItems="center" container>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400
                      }}>
                      {part.text}
                    </span>
                  ))}
                  <Typography variant="body2">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
          value={value}
        />
      </Grid> */}
      {showResetFiltersBtn ? (
        <Grid container item xs={12} md={12}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            fullWidth
            onClick={resetFilters}>
            Reset Filter
          </Button>
        </Grid>
      ) : null}
      <List style={{ width: '100%' }}>
        {allFilters
          .filter((item) => item.show)
          .map((serviceData) => (
            <Filter
              key={serviceData.id}
              data={serviceData}
              open={openFilters}
              setOpen={setOpenFilters}
              shopFilters={shopFilters}
              setShopFilters={setShopFilters}
            />
          ))}
      </List>
    </Grid>
  );
};

export default ShopFilters;
