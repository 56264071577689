import React, { useEffect, useState } from 'react';
import { useAmplifyAuth } from 'context';

const CartContext = React.createContext({});

function CartProvider(props) {
  const [cart, setCart] = useState({ items: [] });
  const {
    state: { user }
  } = useAmplifyAuth();

  useEffect(() => {
    const _cart = localStorage.getItem('cart');
    const parseCartData = JSON.parse(_cart);
    const { item, username } = parseCartData || {};

    if (user?.email) {
      if (!!username && user.email !== username) {
        setCart({ items: [] });
        localStorage.removeItem('cart');
      } else {
        localStorage.setItem(
          'cart',
          JSON.stringify({ username: user.email, item })
        );
        if (item?.items?.length > 0) setCart(item);
      }
    } else if (item?.items?.length > 0) setCart(item);
  }, [user]);

  return (
    <CartContext.Provider value={{ cart, setCart }}>
      {props.children}
    </CartContext.Provider>
  );
}

export { CartContext, CartProvider };
