import queries from './productQueries';

const createAProduct = /* GraphQL */ `
  mutation CreateAProduct(
    $shopID: ID!
    $serviceID: ID!
    $itemID: ID
    $name: String
    $codeName: String
    $noOfPieces: Int
    $description: String
    $unit: Units
    $price: Float
    $priceRange: String
    $image: String
  ) {
    createAProduct(
      shopID: $shopID
      serviceID: $serviceID
      itemID: $itemID
      name: $name
      codeName: $codeName
      noOfPieces: $noOfPieces
      description: $description
      unit: $unit
      price: $price
      priceRange: $priceRange
      image: $image
    ) ${queries.productItem}
  }
`;
const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) ${queries.productItem}
  }
`;
const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) ${queries.productItem}
  }
`;

export default { createAProduct, updateProduct, deleteProduct };
