import React, { useState, useEffect, useContext } from 'react';
import {
  showPrice,
  capitalizeStr,
  unitFormatter,
  calculatePriceWithDiscount,
  getFormattedPriceRange
} from 'common/utilFunctions';
import { OrderUpDownButtons, Image } from 'components/organisms';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CartContext } from 'context/cart/cartContext';
import { useCoupons } from 'context/coupons/couponsContext';
import {
  Accordion,
  AccordionSummary,
  Card,
  Chip,
  Grid,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  selectedItemBtns: {
    backgroundColor: theme.palette.blue.main,
    color: 'white',
    borderColor: 'white !important',
    '&:hover': {
      backgroundColor: theme.palette.blue.secondary
    }
  },
  serviceHeading: {
    fontSize: '22px',
    fontWeight: 600,
    color: '#444'
  },
  itemCard: {
    padding: '14px',
    width: '100%',
    borderRadius: '7px',
    boxShadow: '0px 0px 3px 0px #00000033',
    border: 'none',
    marginRight: '1rem'
  },
  greyText: {
    color: '#777'
  },
  discountedPrice: {
    color: '#F82A2A'
  },
  oldPrice: {
    textDecoration: 'line-through',
    fontSize: '0.75rem'
  },
  offerChip: {
    marginLeft: '1rem',
    height: '1rem',
    padding: '0.5rem 0.8rem',
    backgroundColor: '#f82a2ad4',
    borderColor: '#FE3F3F',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    color: 'white',
    borderRadius: '5px'
  },
  itemName: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '95%',
    overflow: 'hidden'
  },
  childService: {},
  accordionRounded: {
    border: 0,
    boxShadow: 'none'
  },
  accordionSummary: {
    padding: 0
  }
}));

const Products = ({
  service,
  dense = false,
  className = '',
  serviceIndex = 0,
  searchBy = ''
}) => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { cart } = useContext(CartContext);
  const classes = useStyles();
  const { coupons = [] } = useCoupons();
  const [expanded, setExpanded] = useState(0);
  const filteredProducts = service.products
    .filter((item) => !item._deleted && (item.name || item.item?.name))
    .filter((item) => {
      const name = item.name || item.item?.name;
      return name.toLowerCase().includes(searchBy.trim().toLowerCase());
    });

  useEffect(() => {
    if (mdUp || !!searchBy) {
      setExpanded(serviceIndex);
    } else {
      setExpanded(0);
    }
  }, [mdUp, searchBy]);

  const getItemQuantity = (item) =>
    cart.items.some((cartItem) => cartItem.id === item.id)
      ? cart.items
          .filter((cartItem) => cartItem.id === item.id)
          .map((item) => item.quantity)[0]
      : 0;

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Grid container style={{ height: 'fit-content' }} className={className}>
      {filteredProducts.length > 0 ? (
        <Accordion
          style={{ width: '100%' }}
          expanded={expanded === serviceIndex}
          onChange={handleChange(serviceIndex)}
          classes={{ rounded: classes.accordionRounded }}>
          <Grid item xs={12}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              classes={{ root: classes.accordionSummary }}>
              <Typography variant="h4" className={classes.serviceHeading}>
                {`${capitalizeStr(service.name)} (${filteredProducts?.length})`}
              </Typography>
            </AccordionSummary>
          </Grid>
          <Grid
            container
            item
            xs={12}
            style={{ gap: '1.5rem 0', margin: '12px 0 18px 0' }}>
            {filteredProducts.map((item, index) => {
              const currentCoupon = !!item.price
                ? coupons.find(
                    (coupon) =>
                      coupon.discountType === 'direct' &&
                      coupon.productID === item.id
                  )
                : null;
              let discountedPrice;
              if (currentCoupon)
                discountedPrice = calculatePriceWithDiscount(
                  item.price,
                  currentCoupon.discountAmount,
                  currentCoupon.discountUnit
                );
              return (
                <Grid
                  container
                  justify="center"
                  item
                  xs={12}
                  md={dense ? 4 : 6}
                  key={`shop-detail-shop-items-item-${index}`}>
                  <Card className={classes.itemCard}>
                    <Grid
                      container
                      justify="space-between"
                      style={{ height: '100%' }}>
                      <Grid
                        item
                        xs={8}
                        sm={9}
                        container
                        justify="space-between">
                        <Grid
                          container
                          alignItems="center"
                          style={{ marginBottom: '5px' }}
                          title={item?.name || item.item?.name || ''}>
                          <Typography variant="h5" className={classes.itemName}>
                            {item?.name || item.item?.name || ''}{' '}
                            {currentCoupon ? (
                              <Chip
                                variant="outlined"
                                size="small"
                                className={classes.offerChip}
                                label={`${
                                  currentCoupon.discountUnit === 'percentage'
                                    ? `${currentCoupon.discountAmount}%`
                                    : showPrice(currentCoupon.discountAmount)
                                } off`}
                              />
                            ) : null}
                          </Typography>
                        </Grid>
                        <Grid container justify="space-between">
                          <Typography
                            variant="caption"
                            className={classes.greyText}>
                            {item?.description || item.item?.description || ''}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          style={{ gap: '0.325rem', marginTop: '5px' }}>
                          <Typography
                            variant="h5"
                            className={currentCoupon ? classes.oldPrice : ''}>
                            {item.price
                              ? showPrice(item.price)
                              : getFormattedPriceRange(item.priceRange)}
                          </Typography>
                          {currentCoupon ? (
                            <Typography
                              variant="h5"
                              className={classes.discountedPrice}>
                              {showPrice(discountedPrice)}
                            </Typography>
                          ) : null}
                          <Typography
                            variant="caption"
                            style={{
                              textTransform: 'lowercase'
                            }}
                            className={classes.greyText}>
                            {unitFormatter(item.unit, '/')}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={4}
                        sm={3}
                        alignItems="center"
                        justify="flex-end">
                        {!!(item?.image || item?.item?.image) && (
                          <Grid
                            item
                            xs={12}
                            container
                            justify="flex-end"
                            style={{ marginBottom: '8px' }}>
                            <Image
                              docKey={item?.image || item?.item?.image}
                              height={50}
                              width={72}
                              alt={
                                item?.name || item.item?.name || 'product photo'
                              }
                            />
                          </Grid>
                        )}
                        {!dense ? (
                          <Grid
                            container
                            justify="flex-end"
                            alignItems="flex-start"
                            item
                            xs={12}>
                            <OrderUpDownButtons
                              item={{
                                ...item,
                                price: discountedPrice || item.price,
                                discount: item.price - discountedPrice || 0,
                                discountCouponID: currentCoupon?.id || '',
                                discountCoupon: currentCoupon || ''
                              }}
                              className={
                                getItemQuantity(item) > 0
                                  ? classes.selectedItemBtns
                                  : ''
                              }
                            />
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
          {service.subServices?.map((item, index) => (
            <Products
              key={`shop-details-items-to-shop-from-items-${service.id}-${index}`}
              service={item}
              dense={dense}
              className={classes.childService}
            />
          ))}
        </Accordion>
      ) : null}
    </Grid>
  );
};

export default Products;
