import React from 'react';
import { Box, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 40
  },
  cartSelect: {
    height: '1.75rem'
  }
}));

const QuantitySelect = function (props) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const numArr = Array.from(Array(100).keys());

  const handleChange = (event) => {
    props.item.quantity = event.target.value;
    props.updateCart(props.item, event.target.value);
  };

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  return (
    <Box className={classes.formControl}>
      <Select
        className={classes.cartSelect}
        variant="outlined"
        id="name"
        labelId="controlled-open-select-label"
        onChange={handleChange}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        value={props.item.quantity}>
        {numArr.map((numb, i) => {
          return (
            <MenuItem key={`quantity_${i}`} value={numb}>
              {numb}
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};

export default QuantitySelect;
