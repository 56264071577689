import Towel_Cleaning from './assets/service-icons/Towel.png';
import Workwear_Laundry from './assets/service-icons/WorkwearLaundry.png';
import Repairs__Alteration from './assets/service-icons/RepairsandAlteration.png';
import Home_and_Bedding from './assets/service-icons/HomeandBedding.png';
import Wash__Iron from './assets/service-icons/WashandIron.png';
import Medical_Laundry from './assets/service-icons/MedicalLaundry.png';
import Shoe_Repairs from './assets/service-icons/ShoeRepair.png';
import Locksmith from './assets/service-icons/Locksmith.png';
import Gym_Laundry from './assets/service-icons/GymLaundry.png';
import Restaurants_Laundry from './assets/service-icons/RestaurantsLaundry.png';
import Laundry from './assets/service-icons/Laundry.png';
import Leather__Suede from './assets/service-icons/LeatherandSuede.png';
import Dry_Cleaning from './assets/service-icons/DryCleaning.png';
import Self_service from './assets/service-icons/SelfService.png';
import Spa_Laundry from './assets/service-icons/SpaLaundry.png';
import Hotel_Laundry from './assets/service-icons/HotelLaundry.png';
import Cafe_Laundry from './assets/service-icons/CafeLaundry.png';
import Ironing from './assets/service-icons/Ironing.png';
import Airbnb_Cleaning from './assets/service-icons/AIRbnbCleaning.png';
import Keys_and_FOB_Copying from './assets/service-icons/keyChain.png';
import CatDryCleaning from './assets/service-icons/CatDryCleaning.png';

export const AD_PER_SLIDE = {
  md: {
    count: 3,
    indexArr: [0, 1, 2]
  },
  sm: {
    count: 2,
    indexArr: [0, 1]
  },
  xs: {
    count: 1,
    indexArr: [0]
  }
};

export const tinySliderSettings = {
  lazyload: true,
  mouseDrag: true,
  autoplayButtonOutput: false,
  autoplayHoverPause: true,
  autoplay: true,
  speed: 600,
  autoplayTimeout: 6000,
  controls: false,
  center: true,
  nav: false,
  rewind: true
};

export const initialFilters = {
  services: [],
  distance: [10],
  shops: []
};

export const SERVICE_ICONS = {
  Towel_Cleaning,
  Workwear_Laundry,
  Repairs__Alteration,
  Home_and_Bedding,
  Wash__Iron,
  Medical_Laundry,
  Shoe_Repairs,
  Locksmith,
  Gym_Laundry,
  Restaurants_Laundry,
  Laundry,
  Leather__Suede,
  Self_service,
  Spa_Laundry,
  Hotel_Laundry,
  Cafe_Laundry,
  Ironing,
  Airbnb_Cleaning,
  Dry_Cleaning,
  Keys_and_FOB_Copying,
  CatDryCleaning
};
