export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      groups
      picture
      username
      userId
      firstName
      lastName
      title
      defaultAddress
      email
      address
      postalCode
      phoneNumber
      createdAt
      shopID
      stripeID
      status
      language
      dateOfBirth
      documents {
        name
        documentType
        documentURL
        description
        MIMETypes
        status
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
