import { API, graphqlOperation } from 'aws-amplify';
import { getRandomBanner, listSearchBanners } from 'graphql/queries';

const loadBanners = (payload) =>
  new Promise((resolve, reject) =>
    API.graphql(graphqlOperation(listSearchBanners, payload))
      .then((data) => resolve(data.data.listSearchBanners))
      .catch((err) => reject(err))
  );

const loadRandomBanner = (payload) =>
  new Promise((resolve, reject) =>
    API.graphql(graphqlOperation(getRandomBanner, payload))
      .then((data) => resolve(data.data.getRandomBanner))
      .catch((err) => reject(err))
  );

export { loadBanners, loadRandomBanner };
