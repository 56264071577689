import React, { useMemo } from 'react';
import {
  Grid,
  List,
  ListItemText,
  ListItem,
  Typography,
  Button,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { routes } from 'common/constants';
import ChatMessageBody from 'components/organisms/ChatMessageBody';
import { ShopImage } from 'components/organisms';

const useStyles = makeStyles((theme) => ({
  container: {
    border: '2px solid #0000003b',
    backgroundColor: theme.palette.white,
    marginLeft: 0,
    marginTop: '1rem',
    borderRadius: '0.25rem',
    [theme.breakpoints.up('md')]: {
      marginTop: 0
    }
  },
  greenDot: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    color: '#15CF74',
    marginRight: '4px'
  },
  chatBoxHeader: {
    padding: '0.5rem',
    borderRadius: '0.25rem 0.25rem 0 0',
    borderBottom: '2px solid #0000003b',
    boxShadow: '0 2px 8px -2px #0000003b',
    zIndex: 1
  },
  avatar: {
    height: 40,
    width: 40,
    marginRight: '1rem',
    borderRadius: '50%'
  },
  listItemText: {
    padding: '0.5rem'
  },
  shopName: {
    fontWeight: '700'
  }
}));

const ORDER_STATUSES_IN_ORDER_HISTORY = ['delivered', 'declined', 'cancelled'];

const ChatMessageBox = (props) => {
  const { orderActive, setOpenDrawer } = props;
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const orderDetailsURL = useMemo(
    () =>
      !orderActive?.id
        ? ''
        : `${routes.customer.order}${
            ORDER_STATUSES_IN_ORDER_HISTORY.includes(orderActive.status)
              ? '/history'
              : ''
          }?orderID=${orderActive.orderID}`,
    [orderActive]
  );

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.chatBoxHeader}>
        {sm ? (
          <Grid container item justify="space-between" alignItems="center">
            <Grid item>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => setOpenDrawer(true)}>
                All Orders
              </Button>
            </Grid>

            <Grid item>
              <Link to={orderDetailsURL}>
                <Button variant="contained" size="small" color="primary">
                  View Order Details
                </Button>
              </Link>
            </Grid>
          </Grid>
        ) : null}

        <List>
          <ListItem className={classes.listItemText}>
            <ShopImage
              shop={orderActive.shop}
              width="100%"
              className={classes.avatar}
            />
            <ListItemText
              primary={
                <Typography variant="h5" className={classes.shopName}>
                  #{orderActive.orderID} ({orderActive.shop?.name})
                </Typography>
              }
              // secondary={
              //   <Typography variant="body1">
              //     <FiberManualRecordIcon className={classes.greenDot} />
              //     Online
              //   </Typography>
              // }
            />
          </ListItem>
        </List>

        {md ? (
          <Grid item>
            <Link to={orderDetailsURL}>
              <Button variant="contained" size="small" color="primary">
                View Order Details
              </Button>
            </Link>
          </Grid>
        ) : null}
      </Grid>

      {/* message body */}
      <ChatMessageBody orderActive={orderActive} />
    </Grid>
  );
};

export default ChatMessageBox;
