import * as routes from './largeConstants/routes.json';
import { counties } from './largeConstants/counties.json';
import { countries } from './largeConstants/countries.json';
import { languages } from './largeConstants/languages.json';

const num = 'zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen'.split(
  ' '
);

const tens = 'twenty thirty forty fifty sixty seventy eighty ninety'.split(' ');

const maxInputDateForDOB = `${new Date().getFullYear() - 18}-${
  new Date().getMonth() < 10
    ? `0${new Date().getMonth()}`
    : new Date().getMonth()
}-${
  new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()
}`;

const maxInputDateForDatePicker = new Date('9999-12-31').toISOString();

const webLinkRegex = /^((http(s?)?):\/\/)?([w|W]{3}\.)?[a-zA-Z0-9\-\.]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?(\/[a-zA-Z0-9\/\-]{2,})?$/;

const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const regexPhoneNumber = /\s*(([+](\s?\d)([-\s]?\d)|0)?(\s?\d)([-\s]?\d){9}|[(](\s?\d)([-\s]?\d)+\s*[)]([-\s]?\d)+)\s*/i;

const regexPostcode = /([A-Za-z]{1,2}[0-9]{1,2}[A-Za-z]?[ ]?)([0-9]{1}[A-Za-z]{2})$/i;

const paymentMethods = [
  { label: 'Debit / Credit Card', value: 'card' },
  { label: 'Cash', value: 'cash' }
];

const googleFormLinks = {
  joinUs: 'https://forms.gle/WhZFWPCEPcH81CR29',
  businessWithUs: 'https://forms.gle/NzXrAnNmF7W9NaBZ6',
  requestToOnboardShopFromCustomer:
    'https://docs.google.com/forms/d/e/1FAIpQLSd_YBGZjgeALZzfEWRrRdpZ414Z3hGy1PcHy3gW-QSKSrxjeQ/viewform?usp=sf_link'
};

const launderItBlogLink = 'http://space.launder-it.co.uk/';

const shopFormFields = [
  {
    helper: 'Please specify the business name',
    label: 'Business Name',
    property: 'name'
  },
  {
    helper: 'Please specify the business contact name',
    label: 'Business Contact Name',
    property: 'contactName'
  },
  {
    helper: 'Please specify the business phone number. e.g. 07712345678',
    label: 'Business Contact Number',
    property: 'phoneNumber'
  },
  {
    helper: 'Please specify the business address',
    label: 'Address',
    property: 'address'
  },
  {
    helper: '',
    label: 'Email',
    property: 'email'
  },
  {
    label: 'Postal Code',
    property: 'postcode'
  },
  {
    label: 'Country',
    property: 'country',
    isSelect: true
  },
  {
    label: 'County',
    property: 'town',
    isSelect: true
  }
];

const days = 'Sunday Monday Tuesday Wednesday Thursday Friday Saturday'
  .split(' ')
  .map((item, index) => ({ id: index, name: item }));

const socialSiteLinks = {
  facebook: 'https://www.facebook.com/profile.php?id=100089255919530',
  instagram: 'https://www.instagram.com/launder_itcouk/',
  youtube: 'https://www.youtube.com/channel/UCLuHBuCTp6BQUfC95ae9gcQ'
};

const PAYMENT_CANCELLED_ORDER_STATUSES = ['cancelled', 'declined'];

const RATING_CATEGORIES = ['Washing Quality', 'Service', 'Delivery', 'Price'];

const STAR_VALUE_TO_PERCENTAGE = 20;

const B64_SEPARATOR = '$#@#$';

const RADIUS_OF_EARTH = 3956; // Radius of earth in kilometers: 6371, in miles: 3956

const AUCTION_OPEN_ALL_DAY_HRS = [
  { closeDay: null, openDay: 0, closeTime: null, openTime: null },
  { closeDay: null, openDay: 1, closeTime: null, openTime: null },
  { closeDay: null, openDay: 2, closeTime: null, openTime: null },
  { closeDay: null, openDay: 3, closeTime: null, openTime: null },
  { closeDay: null, openDay: 4, closeTime: null, openTime: null },
  { closeDay: null, openDay: 5, closeTime: null, openTime: null },
  { closeDay: null, openDay: 6, closeTime: null, openTime: null }
];

export {
  routes,
  maxInputDateForDOB,
  counties,
  countries,
  languages,
  paymentMethods,
  num,
  tens,
  webLinkRegex,
  googleFormLinks,
  launderItBlogLink,
  regexEmail,
  regexPhoneNumber,
  regexPostcode,
  shopFormFields,
  days,
  socialSiteLinks,
  maxInputDateForDatePicker,
  PAYMENT_CANCELLED_ORDER_STATUSES,
  RATING_CATEGORIES,
  STAR_VALUE_TO_PERCENTAGE,
  B64_SEPARATOR,
  RADIUS_OF_EARTH,
  AUCTION_OPEN_ALL_DAY_HRS
};
