import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  Grid,
  Typography,
  Paper
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: { minWidth: '100%' },
  saveButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.text.primary
  },
  cancelButton: {
    color: theme.palette.text.secondary
  }
}));

const Notifications = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const [enableActions, setEnableActions] = useState(false);

  const handleSubmit = e => e.preventDefault();

  const handleChange = () => setEnableActions(true);

  const onCancel = () => setEnableActions(false);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form onSubmit={handleSubmit}>
        <CardHeader
          title="Notification Settings"
          subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit,Lorem ipsum dolor sit amet, consectetur adipiscing elit,"
        />
        <CardContent>
          <Typography variant="subtitle1">
            Choose when you receive push notifications
          </Typography>
          <List>
            <ListItem component={Paper}>
              <ListItemText primary="Activity on customers order" />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  color="primary"
                  onChange={handleChange}
                  // onChange={handleToggle('bluetooth')}
                  // checked={checked.indexOf('bluetooth') !== -1}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem component={Paper}>
              <ListItemText primary="Message notification from customers" />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  color="primary"
                  onChange={handleChange}
                  // onChange={handleToggle('bluetooth')}
                  // checked={checked.indexOf('bluetooth') !== -1}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </CardContent>
        <CardHeader
          title="E-mail Notification Settings"
          subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit,Lorem ipsum dolor sit amet, consectetur adipiscing elit,"
        />
        <CardContent>
          <Typography variant="subtitle1">
            Choose when you receive email notifications
          </Typography>

          <List>
            <ListItem component={Paper}>
              <ListItemText primary="Receive notifications for customers orders" />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  color="primary"
                  onChange={handleChange}
                  // onChange={handleToggle('bluetooth')}
                  // checked={checked.indexOf('bluetooth') !== -1}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem component={Paper}>
              <ListItemText primary="Notify me when customers send direct messages" />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  color="primary"
                  onChange={handleChange}
                  // onChange={handleToggle('bluetooth')}
                  // checked={checked.indexOf('bluetooth') !== -1}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </CardContent>
        <CardActions>
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-end"
            justify="flex-end"
            alignItems="center"
            xs={12}>
            <Button
              className={classes.cancelButton}
              variant="outlined"
              disabled={!enableActions}
              onClick={onCancel}>
              Cancel
            </Button>
            <Button
              className={classes.saveButton}
              variant="contained"
              disabled={!enableActions}
              type="submit">
              Save
            </Button>
          </Grid>
        </CardActions>
      </form>
    </Card>
  );
};

Notifications.propTypes = {
  className: PropTypes.string
};

export default Notifications;

{
  /* <Grid container spacing={6} wrap="wrap">
  <Grid className={classes.item} item md={4} sm={6} xs={12}>
    <Typography gutterBottom variant="h6">
      Notifications
    </Typography>
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          defaultChecked //
        />
      }
      label="Email"
    />
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          defaultChecked //
        />
      }
      label="Push Notifications"
    />
    <FormControlLabel
      control={<Checkbox color="primary" />}
      label="Text Messages"
    />
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          defaultChecked //
        />
      }
      label="Phone calls"
    />
  </Grid>
  <Grid className={classes.item} item md={4} sm={6} xs={12}>
    <Typography gutterBottom variant="h6">
      Messages
    </Typography>
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          defaultChecked //
        />
      }
      label="Email"
    />
    <FormControlLabel
      control={<Checkbox color="primary" />}
      label="Push Notifications"
    />
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          defaultChecked //
        />
      }
      label="Phone calls"
    />
  </Grid>
</Grid>; */
}
