import React from 'react';
import { Address } from 'components/organisms';
import { makeStyles } from '@material-ui/core/styles';
import {
  Backdrop,
  Fade,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalScroll: {
    overflowY: 'scroll',
    maxHeight: '80vh',
    paddingRight: '1rem'
  },
  modalBox: {
    padding: '1rem',
    maxWidth: '95%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '50%'
    }
  }
}));

const AddressModal = ({
  open = false,
  handleClose = () => {},
  updateAddressList = () => {},
  showAddNewAddressForm = false,
  hideAddNewAddressForm = () => {},
  selectedAddress = null
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      className={classes.modal}
      closeAfterTransition
      aria-labelledby="addresses-modal"
      aria-describedby="addresses-modal-to-check-details-or-update">
      <Fade in={open}>
        <Paper className={classes.modalBox}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{ margin: '10px 0' }}>
            <Typography variant="h4">
              {showAddNewAddressForm ? 'Add Address' : 'Address Details'}{' '}
            </Typography>
            <IconButton onClick={handleClose} style={{ padding: 0 }}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <PerfectScrollbar className={classes.modalScroll}>
            {showAddNewAddressForm ? (
              <Address
                mode={3}
                onCancel={hideAddNewAddressForm}
                updateAddressList={updateAddressList}
              />
            ) : (
              <Address
                mode={1}
                data={selectedAddress}
                updateAddressList={updateAddressList}
              />
            )}
          </PerfectScrollbar>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default AddressModal;
