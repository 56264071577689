import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
} from '@material-ui/core';
const ProductsToolbar = props => {
  const {title} = props

  return (
    <div>
      <Grid container alignItems="flex-end" justify="space-between" spacing={3}>
        <Grid item>
          <Typography
            variant="overline"
            gutterBottom={true}
          >
            Products & Pricing
        </Typography>
          <Typography
            variant="h3"
          >
            {title}
        </Typography>
        </Grid>

      </Grid>
    </div>

  );
};

ProductsToolbar.propTypes = {
  className: PropTypes.string
};

export default ProductsToolbar;
