export const orderItem = `
{
    id
    shopID
    orderID
    bidID
    customerReview {
        items {
          id
          shopID
          userID
          orderID
          title
          ratings {
           title
           rating
          }
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
    }
    orderList {
      productID
      serviceID
      category
      name
      codeName
      description
      discount
      discountCoupon
      discountCouponID
      discountDescription
      overallDiscount
      unit
      price
      totalPrice
      priceRange
      quantity
    }
    forwardRequests {
        items {
          id
          orderID
          forwardReason
          requestShopID
          acceptedShopID
          acceptedShop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          AsapDeliveryFee
          language
          range
          shopID
          about
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
          requestShop {
              id
              name
              address
              postcode
          }
          forwardShops {
            items {
              id
              shopID
              shop {
                id
                name
              }
              forwardRequestID
              status
              forwaredBy
              createdAt
              createdBy
              _version
              _deleted
              _lastChangedAt
              updatedAt
            }
            nextToken
            startedAt
          }
          createdAt
          createdBy
          status
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
      nextToken
      startedAt
    }
    userID
    status
    collection {
      date
      time
      type
      address
      instructions
      driver {
        userID
        firstName,
        title,
        lastName,
        email,
        phoneNumber
      }
    }
    delivery {
      date
      time
      type
      address
      instructions
      driver {
        userID
        firstName,
        title,
        lastName,
        email,
        phoneNumber
      }
    }
    createdAt
    updatedAt
    updatedBy
    paymentStatus
    paymentMethod
    orderCancelOrDeclineReason
    total
    tax
    discount
    variableTotal
    paidAmount
    onHoldPaymentIntentID
    onHoldPaymentAmount
    coupons
    collectionAndDeliveryFee
    currentShopFees {
      standardDeliveryFee
      AsapDeliveryFee
      minPriceForFree
      distanceRangeForFree
      distanceRange
    }
    payments {
      nextToken
      startedAt
    }
    _version
    _deleted
    _lastChangedAt
    shop {
      id
      name
      googlePlaceId
      contactName
      address
      postcode
      town
      country
      email
      phoneNumber
      minPriceForFree
      distanceRangeForFree
      distanceRange
      standardDeliveryFee
      AsapDeliveryFee
      language
      range
      shopID
      status
      isLive
      enableAsapDelivery
      collectionInstructions
      deliveryInstructions
      location {
        lat
        lng
      }
      primaryPhoto {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
        serviceID
      }
      workHrs {
        closeDay
        openDay
        closeTime
        openTime
      }      
      deliveryHrs {
        closeDay
        openDay
        closeTime
        openTime
      }
      createdBy
      updatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
    userDetail {
      id
      groups
      picture
      username
      userId
      firstName
      lastName
      title
      defaultAddress
      email
      address
      postalCode
      phoneNumber
      createdAt
      shopID
      status
      language
      dateOfBirth
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
}
`;

export const getOrderByShopIdDateOIDStatus = /* GraphQL */ `
  query GetOrderByShopIdDateOIDStatus(
    $shopId: String!
    $orderId: String
    $status: [OrderStatus]
    $createdDateRange: DateRange
    $nextToken: String
  ) {
    getOrderByShopIdDateOIDStatus(
      shopId: $shopId
      orderId: $orderId
      status: $status
      createdDateRange: $createdDateRange
      nextToken: $nextToken
    ) {
      items ${orderItem}
      nextToken
    }
  }
`;

export const getOrderByUserId = /* GraphQL */ `
  query GetOrderByUserId(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderByUserId(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${orderItem}
      nextToken
      startedAt
    }
  }
`;

export const getOrderForwardRequestByShop = /* GraphQL */ `
  query GetOrderByUserId(
    $requestShopID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderForwardRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderForwardRequestByShop(
      requestShopID: $requestShopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderID
        forwardReason
        requestShopID
        acceptedShopID
        createdAt
        createdBy
        status
        forwardShops {
          nextToken
          startedAt
        }
        order ${orderItem}
        _version
        _deleted
        _lastChangedAt
        updatedAt
        requestShop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          AsapDeliveryFee
          language
          range
          shopID
          about
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        acceptedShop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          AsapDeliveryFee
          language
          range
          shopID
          about
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
      }
    }
  }
`;

export const getOrderForwardShopByShop = /* GraphQL */ `
  query GetOrderForwardShopByShop(
    $shopID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderForwardShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderForwardShopByShop(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        forwardRequestID
        status
        forwaredBy
        createdAt
        createdBy
        forwardRequest {
          id
          orderID
          forwardReason
          requestShopID
          acceptedShopID
          createdAt
          createdBy
          status
          forwardShops {
            items {
              id
              shopID
              forwardRequestID
              status
              forwaredBy
              createdAt
              createdBy
              _version
              _deleted
              _lastChangedAt
              updatedAt
            }
            nextToken
            startedAt
          }
          order ${orderItem}
          _version
          _deleted
          _lastChangedAt
          updatedAt
          requestShop {
            id
            name
            googlePlaceId
            contactName
            address
            postcode
            town
            country
            email
            phoneNumber
            location {
              lat
              lng
            }
            workHrs {
              closeDay
              openDay
              closeTime
              openTime
            }
            collectionHrs {
              closeDay
              openDay
              closeTime
              openTime
            }
            deliveryHrs {
              closeDay
              openDay
              closeTime
              openTime
            }
            image
            minPriceForFree
            distanceRangeForFree
            distanceRange
            standardDeliveryFee
            AsapDeliveryFee
            language
            range
            shopID
            about
            documents {
              name
              documentType
              documentURL
              description
              MIMETypes
              status
            }
            photos {
              name
              photoURL
              thumbURL
              description
              MIMETypes
              status
              serviceID
            }
            primaryPhoto {
              name
              photoURL
              thumbURL
              description
              MIMETypes
              status
              serviceID
            }
            videos {
              name
              videoURL
              autoPlay
              thumbURL
              description
              MIMETypes
              status
            }
            status
            isLive
            consentLetterCount
            consentLetterLastDate
            enableAsapDelivery
            collectionInstructions
            deliveryInstructions
            websiteLink
            createdBy
            updatedBy
            createdAt
            auctions {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
            updatedAt
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            reviews {
              nextToken
              startedAt
            }
            coupons {
              nextToken
              startedAt
            }
          }
          acceptedShop {
            id
            name
            googlePlaceId
            contactName
            address
            postcode
            town
            country
            email
            phoneNumber
            location {
              lat
              lng
            }
            workHrs {
              closeDay
              openDay
              closeTime
              openTime
            }
            collectionHrs {
              closeDay
              openDay
              closeTime
              openTime
            }
            deliveryHrs {
              closeDay
              openDay
              closeTime
              openTime
            }
            image
            minPriceForFree
            distanceRangeForFree
            distanceRange
            standardDeliveryFee
            AsapDeliveryFee
            language
            range
            shopID
            about
            documents {
              name
              documentType
              documentURL
              description
              MIMETypes
              status
            }
            photos {
              name
              photoURL
              thumbURL
              description
              MIMETypes
              status
              serviceID
            }
            primaryPhoto {
              name
              photoURL
              thumbURL
              description
              MIMETypes
              status
              serviceID
            }
            videos {
              name
              videoURL
              autoPlay
              thumbURL
              description
              MIMETypes
              status
            }
            status
            isLive
            consentLetterCount
            consentLetterLastDate
            enableAsapDelivery
            collectionInstructions
            deliveryInstructions
            websiteLink
            createdBy
            updatedBy
            createdAt
            auctions {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
            updatedAt
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            reviews {
              nextToken
              startedAt
            }
            coupons {
              nextToken
              startedAt
            }
          }
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          AsapDeliveryFee
          language
          range
          shopID
          about
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) ${orderItem}
  }
`;
