import React, { useCallback, useContext } from 'react';
import { CartContext } from 'context/cart/cartContext';
import { useAmplifyAuth } from 'context';
import OrderUpDownButtons from './OrderUpDownButtons';
import {
  Grid,
  Card,
  Typography,
  IconButton,
  CardContent,
  Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import {
  showPrice,
  unitFormatter,
  getOrderTotal,
  checkCouponCondition,
  getFormattedPriceRange,
  calculateVariablePrice,
  addRangeToPrice
} from 'common/utilFunctions';
import { useOrders } from 'context/order/ordersContext';
import { API, graphqlOperation } from 'aws-amplify';
import { updateUserDetail } from 'graphql/mutations';
import { validateCartCoupons } from 'common/validators';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CartCoupons from './CartCoupons';
// STRIPE
import {
  useStripe,
  useElements,
  CardNumberElement
} from '@stripe/react-stripe-js';
import { omit } from 'lodash';
import { useLoader } from 'layouts/loaderContext';
import { useShops } from 'context/shop/shopsContext';
import InfoIcon from '@material-ui/icons/Info';
import { shouldApplyCollectionOrDeliveryFee } from 'common/utilFunctions';
import CreateAuctionButton from './CreateAuctionButton';

const useStyles = makeStyles((theme) => ({
  orderSubContainer: {
    height: 'fit-content',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '25px'
    }
  },
  orderSumCard: {
    borderRadius: '8px',
    width: '100%',
    border: `1px solid ${theme.palette.grey.main}`,
    boxShadow: 'none'
  },
  orderSumCardContent: {
    padding: '20px'
  },
  totalPrice: {
    marginRight: '30px'
  },
  checkoutBtn: {
    backgroundColor: theme.palette.blue.main,
    color: 'white',
    textTransform: 'uppercase'
  },
  auctionBtn: {
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  addItemsBtn: {
    cursor: 'pointer',
    color: '#1f6893',
    textDecoration: 'underline'
  },
  closeBtn: {
    color: theme.palette.grey.main
  },
  totalText: {
    fontWeight: 850,
    color: '#444444'
  },
  greyText: {
    color: '#545454'
  },
  discountedPrice: {
    color: '#F82A2A'
  },
  originalCrossedPrice: {
    textDecoration: 'line-through',
    fontSize: '0.5rem'
  },
  itemTotalPrice: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  itemTotalPriceContainer: {
    paddingRight: '0.625rem',
    maxWidth: 'calc(100% - 24px)'
  },
  noteIcon: {
    color: '#edd242'
  },
  note: {
    marginTop: '1rem',
    backgroundColor: '#e4f1fa',
    borderRadius: '0.25rem',
    padding: '0.5rem 0.25rem'
  },
  noteText: {
    fontWeight: 600,
    color: '#444'
  },
  orText: {
    width: '100%',
    textAlign: 'center',
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    lineHeight: '0.1em',
    margin: '0.5rem 0'
  },
  orTextSpan: {
    background: '#fff',
    padding: '0 0.625rem',
    fontWeight: '600'
  }
}));

const CartCard = ({
  isDefaultAddressChange,
  toggleCardNameError = () => {},
  handleAddAuctionModalOpen = () => {},
  order: {
    typeOfOrder = '',
    collectionMethod = '',
    deliveryMethod = '',
    collectionAddress = '',
    deliveryAddress = '',
    setStatus = () => {},
    collectionTime = '',
    collectionSlot = '',
    deliverySlot = '',
    deliveryTime = '',
    standardDeliveryFee = '',
    minPriceForFree = '',
    asapDeliveryFee = '',
    paymentMethod = '',
    cardDetails = {},
    addressDistance = {},
    collectionInstructions = '',
    deliveryInstructions = ''
  } = {
    typeOfOrder: '',
    collectionMethod: '',
    deliveryMethod: '',
    collectionAddress: '',
    deliveryAddress: '',
    setStatus: () => {},
    collectionTime: '',
    collectionSlot: '',
    deliverySlot: '',
    deliveryTime: '',
    standardDeliveryFee: '',
    minPriceForFree: '',
    asapDeliveryFee: '',
    paymentMethod: '',
    cardDetails: {},
    addressDistance: {},
    collectionInstructions: '',
    deliveryInstructions: ''
  }
}) => {
  const { action } = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const { loading, setLoading } = useLoader();
  const { cart, setCart } = useContext(CartContext);
  const { dispatch: ordersDispatch } = useOrders();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = useStyles();
  const subTotal = !cart.auctionID
    ? getOrderTotal({ orderList: cart?.items || [] })
    : cart.total || 0;
  const tax =
    process.env.REACT_APP_VAT === 'enabled'
      ? (subTotal - (cart.discount || 0)) * 0.2 || 0
      : 0;
  let total = subTotal + tax - (cart.discount || 0);
  let collectionAndDeliveryFee = 0;
  const {
    state: { user },
    _refreshUser
  } = useAmplifyAuth();
  const { selectedShop } = useShops();

  const MIN_AUTHORIZED_AMOUNT = 20;

  const DISABLE_PLACE_ORDER_BTN =
    (collectionMethod === 'Collection'
      ? !collectionAddress ||
        (!(typeOfOrder === 'ASAP') && (!collectionTime || !collectionSlot)) ||
        (selectedShop?.distanceRange &&
          addressDistance.collection > selectedShop?.distanceRange)
      : !collectionMethod) ||
    (deliveryMethod === 'Delivery'
      ? !deliveryAddress ||
        (!(typeOfOrder === 'ASAP') && (!deliverySlot || !deliveryTime)) ||
        (selectedShop?.distanceRange &&
          addressDistance.delivery > selectedShop?.distanceRange)
      : !deliveryMethod) ||
    (action === 'place-auction' ? false : !paymentMethod) ||
    cart.items.length === 0 ||
    !stripe ||
    loading;

  if (typeOfOrder === 'ASAP') {
    total = total + asapDeliveryFee;
    collectionAndDeliveryFee = collectionAndDeliveryFee + asapDeliveryFee;
  }
  if (
    collectionMethod === 'Collection' &&
    shouldApplyCollectionOrDeliveryFee(
      subTotal,
      minPriceForFree,
      addressDistance?.collection,
      selectedShop?.distanceRangeForFree
    )
  ) {
    total = total + standardDeliveryFee;
    collectionAndDeliveryFee = collectionAndDeliveryFee + standardDeliveryFee;
  }
  if (
    deliveryMethod === 'Delivery' &&
    shouldApplyCollectionOrDeliveryFee(
      subTotal,
      minPriceForFree,
      addressDistance?.delivery,
      selectedShop?.distanceRangeForFree
    )
  ) {
    total = total + standardDeliveryFee;
    collectionAndDeliveryFee = collectionAndDeliveryFee + standardDeliveryFee;
  }

  const handleAddMoreItems = () => {
    const previousPath = history.location.pathname.split('/');
    const lastEl = previousPath.pop();
    lastEl === 'checkout'
      ? history.replace(previousPath.join('/'))
      : history.replace('/home');
  };

  const removeItemFromCart = (item) => {
    const newCart = validateCartCoupons({
      ...cart,
      items: cart.items.filter((cartItem) => item.id !== cartItem.id)
    });
    newCart.variablePrice = calculateVariablePrice(
      newCart.items.filter((_item) => !_item.price)
    );

    localStorage.setItem(
      'cart',
      JSON.stringify({ username: user.email, item: newCart })
    );
    setCart(newCart);
  };

  const defaultAddressHandler = async (id) => {
    const snackBar = enqueueSnackbar('Updating default address', {
      variant: 'info',
      persist: true,
      preventDuplicate: true
    });
    try {
      await API.graphql(
        graphqlOperation(updateUserDetail, { defaultAddress: id })
      );
      enqueueSnackbar('Address updated', {
        variant: 'success',
        autoHideDuration: 2000
      });
      _refreshUser();
    } catch (error) {
      console.error('Something went wrong', error);
    } finally {
      closeSnackbar(snackBar);
    }
  };

  // for applying coupon
  const handleApplyCouponClick = (coupon) => () => {
    const newCart = { ...cart };
    const couponProduct = cart.items.find(
      (item) => item.id === coupon.productID
    );
    const conditionMet = checkCouponCondition(
      coupon.conditionUnit,
      coupon.conditionAmount,
      cart.items,
      coupon.productID
    );

    if (!conditionMet) {
      enqueueSnackbar('Not Applicable', {
        variant: 'error',
        autoHideDuration: 2000
      });
      return;
    }

    if (couponProduct) {
      couponProduct.discountCouponID = coupon.id;
      couponProduct.discountCoupon = coupon;
      if (coupon.discountUnit === 'percentage')
        couponProduct.overallDiscount = parseFloat(
          (couponProduct.quantity *
            couponProduct.price *
            coupon.discountAmount) /
            100
        );
      else if (coupon.discountUnit === 'amount')
        couponProduct.overallDiscount = parseFloat(coupon.discountAmount);
      else if (coupon.discountUnit === 'noOfItems')
        couponProduct.overallDiscount = parseFloat(
          coupon.discountAmount * couponProduct.price
        );

      // updating cart items
      newCart.items = cart.items.map((item) =>
        item.id === couponProduct.id ? couponProduct : item
      );
    } else {
      let newDiscount = 0;
      if (coupon.discountUnit === 'percentage')
        newDiscount = parseFloat((subTotal * coupon.discountAmount) / 100);
      else if (coupon.discountUnit === 'amount')
        newDiscount = parseFloat(coupon.discountAmount);

      // updating cart discounts
      newCart.discount = (cart.discount || 0) + newDiscount;
      newCart.coupons = [
        ...(cart.coupons || []),
        JSON.stringify({
          ...omit(coupon, [
            'updatedAt',
            '_deleted',
            '_lastChangedAt',
            '_version'
          ]),
          discountAmountApplied: newDiscount
        })
      ];
    }

    localStorage.setItem(
      'cart',
      JSON.stringify({
        username: user.email,
        item: newCart
      })
    );
    setCart(newCart);
  };

  // for cancelling a applied coupon
  const handleClearCouponClick = (coupon) => () => {
    const couponProduct = cart.items.find(
      (item) => item.id === coupon.productID
    );
    const newCart = { ...cart };
    if (couponProduct) {
      newCart.items = cart.items.map((item) =>
        item.id === couponProduct.id
          ? {
              ...couponProduct,
              price: parseFloat(couponProduct.price + couponProduct.discount),
              discountCouponID: '',
              discountCoupon: '',
              discount: 0,
              overallDiscount: 0
            }
          : item
      );
    } else {
      const currCoupon =
        JSON.parse(
          cart.coupons.find((item) => JSON.parse(item)?.id === coupon.id)
        ) || '{}';

      newCart.discount =
        (cart.discount || 0) - (currCoupon?.discountAmountApplied || 0);

      newCart.coupons = cart.coupons?.filter(
        (item) => JSON.parse(item)?.id !== coupon.id
      );
    }

    localStorage.setItem(
      'cart',
      JSON.stringify({ username: user.email, item: newCart })
    );
    setCart(newCart);
  };

  const getAuthorisedAmount = useCallback(
    (total) => {
      let paymentTotal = total;
      if (!!cart.variablePrice) {
        const [a, b] = addRangeToPrice(total, cart.variablePrice)
          .split('-')
          .map((_item) => parseFloat(_item));
        if (a < b) paymentTotal = b;
        else paymentTotal = a;
      }
      paymentTotal = Math.round(paymentTotal * 100) / 100;
      return paymentTotal > 20 ? paymentTotal : 20;
    },
    [cart]
  );

  const onOrderPlace = async () => {
    if (!user?.id) {
      setStatus('WRONG_USER');
      return;
    }
    if (cart.items.length <= 0) {
      enqueueSnackbar('Empty cart', {
        variant: 'error',
        preventDuplicate: true,
        autoHideDuration: 3000
      });
      return;
    }
    if (paymentMethod === 'card') {
      if (!stripe || !elements) return;

      if (!cardDetails.selectedCardID && !cardDetails.cardName.value) {
        toggleCardNameError();
        enqueueSnackbar('Please enter your full name', {
          variant: 'error',
          preventDuplicate: true,
          autoHideDuration: 2500
        });
        return;
      }
    }

    let shopID;
    const orderList = cart.items.map((product) => {
      const { item, shopID: _shopID } = product;
      if (_shopID) {
        shopID = _shopID;
      }
      const _product = {
        category: product.category || item?.category,
        name: product.name || item?.name,
        codeName: product.codeName || item?.codeName,
        description: product.description || item?.description,
        discount: product.discount || 0,
        discountCoupon: product.discountCoupon
          ? JSON.stringify({
              ...omit(product.discountCoupon, [
                'updatedAt',
                '_deleted',
                '_lastChangedAt',
                '_version'
              ])
            })
          : '',
        discountCouponID: product.discountCouponID || '',
        discountDescription:
          product.discountDescription || item?.discountDescription,
        overallDiscount: Math.round((product.overallDiscount || 0) * 100) / 100,
        unit: product.unit || item?.unit,
        price: product.price || item?.price || 0,
        priceRange: product.priceRange || '',
        productID: product.id,
        serviceID: product.serviceID,
        quantity: product.quantity
      };
      return _product;
    });

    let collection = {
      type: typeOfOrder === 'ASAP' ? 'asap' : 'time',
      instructions: collectionInstructions
    };
    let delivery = {
      type: typeOfOrder === 'ASAP' ? 'asap' : 'time',
      instructions: deliveryInstructions
    };

    if (collectionMethod !== 'Drop') {
      const _collAddress = omit(collectionAddress, [
        '_deleted',
        '_lastChangedAt',
        '_version',
        'user',
        'userID',
        'updatedAt',
        'createdAt'
      ]);
      const filteredCollAddress = Object.fromEntries(
        Object.entries(_collAddress).filter(([, v]) => !!v)
      );
      collection.address = filteredCollAddress;

      if (typeOfOrder === 'Schedule') {
        collection.date = collectionTime.format('YYYY-MM-DDThh:mm:ss.sssZ');
        collection.time = collectionSlot;
      }
    }

    if (deliveryMethod !== 'Pickup') {
      const _delAddress = omit(deliveryAddress, [
        '_deleted',
        '_lastChangedAt',
        '_version',
        'user',
        'userID',
        'updatedAt',
        'createdAt'
      ]);
      const filteredDelAddress = Object.fromEntries(
        Object.entries(_delAddress).filter(([, v]) => !!v)
      );
      delivery.address = filteredDelAddress;

      if (typeOfOrder === 'Schedule') {
        delivery.date = deliveryTime.format('YYYY-MM-DDThh:mm:ss.sssZ');
        delivery.time = deliverySlot;
      }
    }

    if (isDefaultAddressChange.isChange) {
      if (isDefaultAddressChange.option === 'collection') {
        defaultAddressHandler(collectionAddress.id);
      } else if (isDefaultAddressChange.option === 'delivery') {
        defaultAddressHandler(deliveryAddress.id);
      }
    }

    // new order
    const payload = {
      shopID,
      orderList: JSON.stringify(orderList),
      collection: JSON.stringify(collection),
      delivery: JSON.stringify(delivery),
      total: Math.round(total * 100) / 100,
      paymentMethod,
      tax: Math.round(tax * 100) / 100,
      variableTotal: cart.variablePrice || '',
      collectionAndDeliveryFee,
      coupons: cart.coupons || null,
      discount: Math.round((cart.discount || 0) * 100) / 100,
      currentShopFees: JSON.stringify({
        standardDeliveryFee,
        AsapDeliveryFee: asapDeliveryFee,
        minPriceForFree,
        distanceRangeForFree: selectedShop?.distanceRangeForFree ?? 0,
        distanceRange: selectedShop?.distanceRange ?? 0
      })
    };

    // new order payload
    const orderPayload = {
      payload,
      successCallback: () => {
        localStorage.removeItem('cart');
        setStatus('SUCCESS');
        setCart({ items: [] });
      },
      failureCallback: () => setStatus('FAILURE')
    };

    // attempt card payment and place order
    if (paymentMethod !== 'card') {
      ordersDispatch({
        type: 'placeOrder',
        payload: orderPayload
      });
    } else {
      let snackBar;
      try {
        setLoading(true);
        // calculating payment total
        const paymentTotal = getAuthorisedAmount(total);

        snackBar = enqueueSnackbar(
          `Authorising payment of ${showPrice(paymentTotal)}, please wait...`,
          { variant: 'info', persist: true, preventDuplicate: true }
        );

        // getting payment intent
        const clientSecretResp = await API.post(
          'laundryapi',
          '/payment-intents',
          {
            body: {
              amount: Math.round(paymentTotal * 100),
              manual_capture: true,
              customerID: cardDetails.customerID
            }
          }
        );
        const confirmPaymentParams = {};
        if (cardDetails.shouldSaveCard) {
          confirmPaymentParams['setup_future_usage'] = 'off_session';
        }
        if (!!cardDetails.selectedCardID) {
          confirmPaymentParams['payment_method'] = cardDetails.selectedCardID;
        } else {
          confirmPaymentParams['payment_method'] = {
            card: elements.getElement(CardNumberElement),
            billing_details: { name: cardDetails.cardName.value }
          };
        }
        const result = await stripe.confirmCardPayment(
          clientSecretResp.client_secret,
          confirmPaymentParams
        );
        closeSnackbar(snackBar);
        if (result.error) {
          // Show error to your customer (for example, insufficient funds)
          enqueueSnackbar(result.error.message, {
            variant: 'error',
            persist: true,
            preventDuplicate: true
          });
          if (selectedShop?.paymentMethods?.includes('cash')) {
            enqueueSnackbar(
              'Feel free to select another card or explore alternative payment choices.',
              {
                variant: 'info',
                persist: true,
                preventDuplicate: true
              }
            );
          }
          console.error(result.error.message);
          setLoading(false);
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'requires_capture') {
            ordersDispatch({
              type: 'placeOrder',
              payload: {
                ...orderPayload,
                payload: {
                  ...payload,
                  onHoldPaymentIntentID: result.paymentIntent.id,
                  onHoldPaymentAmount: paymentTotal
                }
              }
            });
          }
        }
      } catch (error) {
        setLoading(false);
        console.log('Something went wrong', error);
        enqueueSnackbar('Something went wrong', {
          variant: 'error',
          autoHideDuration: 2500
        });
        closeSnackbar(snackBar);
      }
    }
  };

  const getColAndDelFeeText = () => {
    const isCollectionFeeRequired =
      collectionMethod === 'Collection' &&
      shouldApplyCollectionOrDeliveryFee(
        subTotal,
        minPriceForFree,
        addressDistance?.collection,
        selectedShop?.distanceRangeForFree
      );

    const isDeliveryFeeRequired =
      deliveryMethod === 'Delivery' &&
      shouldApplyCollectionOrDeliveryFee(
        subTotal,
        minPriceForFree,
        addressDistance?.delivery,
        selectedShop?.distanceRangeForFree
      );

    if (isCollectionFeeRequired && isDeliveryFeeRequired) {
      return 'Collection and Delivery Fee';
    } else if (isCollectionFeeRequired && !isDeliveryFeeRequired) {
      return 'Collection Fee';
    } else {
      return 'Delivery Fee';
    }
  };

  return (
    <Grid container justify="flex-end" className={classes.orderSubContainer}>
      <Card className={classes.orderSumCard}>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ padding: '13px', backgroundColor: '#454545' }}>
          <Typography
            variant="h5"
            align="center"
            style={{ color: 'white', fontSize: '18px', fontWeight: '700' }}>
            Order Summary
          </Typography>
        </Grid>
        <CardContent className={classes.orderSumCardContent}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{
              paddingBottom: '15px',
              borderBottom: '1px solid #D2D8DD'
            }}>
            {cart.items.length > 0 ? (
              cart.items?.map((item, index) => (
                <Grid
                  key={`cart-items-ready-for-checkout-${index}`}
                  container
                  justify="space-between"
                  alignItems="center"
                  style={{ marginBottom: '10px' }}>
                  <Grid
                    item
                    xs={4}
                    md={3}
                    lg={6}
                    style={{ paddingRight: '5px' }}>
                    <Typography variant="h6">
                      {item.name || item.item?.name}
                    </Typography>
                    <Typography variant="body1" className={classes.greyText}>
                      <span
                        className={
                          item.discount ? classes.originalCrossedPrice : ''
                        }>
                        {item.price
                          ? `${showPrice(
                              item.discount
                                ? item.price + item.discount
                                : item.price
                            )}`
                          : getFormattedPriceRange(item.priceRange)}
                      </span>
                      <span className={classes.discountedPrice}>
                        {item.discount ? ` ${showPrice(item.price)}` : ''}
                      </span>
                      {` ${unitFormatter(item.unit, '/').toLowerCase()}`}
                      <span className={classes.discountedPrice}>
                        {item.overallDiscount
                          ? ` (-${showPrice(item.overallDiscount)})`
                          : ''}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={8}
                    sm={4}
                    md={9}
                    lg={6}
                    alignItems="center"
                    justify="space-between">
                    <Grid container item xs={6} justify="center">
                      <OrderUpDownButtons
                        item={item}
                        disabled={!!cart.auctionID}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      container
                      justify="flex-end"
                      alignItems="center">
                      <Grid item className={classes.itemTotalPriceContainer}>
                        <Typography
                          variant="h6"
                          className={classes.itemTotalPrice}
                          align="right"
                          title={
                            item.totalPrice
                              ? showPrice(item.totalPrice)
                              : item.price
                              ? showPrice(
                                  item.price * item.quantity -
                                    (item.overallDiscount || 0)
                                )
                              : getFormattedPriceRange(
                                  item.priceRange,
                                  item.quantity
                                )
                          }>
                          {item.totalPrice
                            ? showPrice(item.totalPrice)
                            : item.price
                            ? showPrice(
                                item.price * item.quantity -
                                  (item.overallDiscount || 0)
                              )
                            : getFormattedPriceRange(
                                item.priceRange,
                                item.quantity
                              )}
                        </Typography>
                      </Grid>
                      <IconButton
                        disabled={!!cart.auctionID}
                        onClick={() => removeItemFromCart(item)}
                        style={{ padding: '0' }}>
                        <CloseIcon
                          fontSize="small"
                          className={classes.closeBtn}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Grid
                container
                item
                xs={12}
                justify="center"
                style={{ marginBottom: '10px' }}>
                <Typography variant="h4">Cart is Empty</Typography>
              </Grid>
            )}
            {subTotal > 0 && (
              <Grid
                item
                xs={12}
                container
                justify="space-between"
                style={{ marginTop: '1.5rem' }}>
                <Typography variant="h6">Sub Total</Typography>
                <Typography variant="h6" className={classes.totalPrice}>
                  {!!cart.variablePrice
                    ? getFormattedPriceRange(
                        addRangeToPrice(subTotal, cart.variablePrice)
                      )
                    : showPrice(subTotal)}
                </Typography>
              </Grid>
            )}

            {/* coupons */}
            {action !== 'place-auction' ? (
              <CartCoupons
                handleApplyCouponClick={handleApplyCouponClick}
                handleClearCouponClick={handleClearCouponClick}
                showAppliedOnly={action === 'checkout'}
              />
            ) : null}

            {/* discount coupons */}
            {cart.coupons?.length > 0 ? (
              <Grid
                item
                xs={12}
                container
                style={{
                  marginTop: '0.5rem',
                  gap: '0.25rem',
                  paddingRight: '1.75rem'
                }}>
                <Grid container>
                  <Typography variant="h6">Discounts</Typography>
                </Grid>
                {cart.coupons.map((_item, index) => {
                  const orderCoupon = JSON.parse(_item);
                  return (
                    <Grid
                      container
                      justify="space-between"
                      key={`cart-coupons-${
                        orderCoupon.id || ''
                      }-items-${index}`}>
                      <Typography variant="body1">
                        {orderCoupon.title || ''}
                      </Typography>
                      <Typography variant="body1">
                        - {showPrice(orderCoupon.discountAmountApplied || 0)}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            ) : null}

            {/* tax */}
            {process.env.REACT_APP_VAT === 'enabled' && subTotal > 0 ? (
              <Grid
                item
                xs={12}
                container
                justify="space-between"
                style={{ marginTop: '1.5rem' }}>
                <Typography variant="h6">VAT (20%)</Typography>
                <Typography variant="h6" className={classes.totalPrice}>
                  {showPrice(tax)}
                </Typography>
              </Grid>
            ) : null}

            {typeOfOrder === 'ASAP' && (
              <Grid
                item
                xs={12}
                container
                justify="space-between"
                style={{ margin: '8px 0px 4px 0px' }}>
                <Typography variant="h6">ASAP fee</Typography>
                <Typography variant="h6" className={classes.totalPrice}>
                  {showPrice(asapDeliveryFee)}
                </Typography>
              </Grid>
            )}

            {!!collectionAndDeliveryFee &&
              (deliveryMethod === 'Delivery' ||
                collectionMethod === 'Collection') && (
                <Grid
                  item
                  xs={12}
                  container
                  justify="space-between"
                  style={{ margin: '0.25rem 0 0.75rem' }}>
                  <Typography variant="h6">{getColAndDelFeeText()}</Typography>
                  <Typography variant="h6" className={classes.totalPrice}>
                    {showPrice(collectionAndDeliveryFee)}
                  </Typography>
                </Grid>
              )}

            {/* cart total */}
            <Grid
              item
              xs={12}
              container
              justify="space-between"
              style={{ padding: '1.25rem 1.75rem 0 0' }}>
              <Typography variant="h5" className={classes.totalText}>
                Total
              </Typography>
              <Typography variant="h5" className={classes.totalText}>
                {!!cart.variablePrice
                  ? getFormattedPriceRange(
                      addRangeToPrice(total, cart.variablePrice)
                    )
                  : showPrice(total)}
              </Typography>
            </Grid>

            {paymentMethod === 'card' && cart.items.length > 0 && (
              <>
                <Grid
                  item
                  xs={12}
                  container
                  justify="space-between"
                  style={{ padding: '1.25rem 1.75rem 0 0' }}>
                  <Typography variant="h6">Will be authorising</Typography>
                  <Typography
                    variant="body1"
                    style={{ textAlign: 'center', fontSize: 14 }}>
                    {showPrice(getAuthorisedAmount(total))}
                  </Typography>
                </Grid>

                <Grid
                  container
                  justify="space-between"
                  className={classes.note}>
                  <Grid item xs={1}>
                    <InfoIcon fontSize="small" className={classes.noteIcon} />
                  </Grid>
                  {total <= MIN_AUTHORIZED_AMOUNT ? (
                    <Grid item xs={11}>
                      <Typography variant="body1" className={classes.noteText}>
                        {`To complete your order, we'll authorize a minimum value of ${showPrice(
                          MIN_AUTHORIZED_AMOUNT
                        )} on your card. Any
                  excess funds will be refunded.`}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item xs={11}>
                      <Typography variant="body1" className={classes.noteText}>
                        {`If there are any changes to the order total, we'll let you know. Any excess funds will be refunded.`}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </>
            )}

            {!cart.auctionID && cart.items.length > 0 ? (
              <Grid container alignItems="center" className={classes.note}>
                <Grid container item xs={1} alignItems="center">
                  <InfoIcon fontSize="small" className={classes.noteIcon} />
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="body1" className={classes.noteText}>
                    All prices are subject to change without prior notice.
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
          </Grid>

          {/* actions */}
          <Grid container style={{ paddingTop: '20px' }}>
            {!action ? (
              <Grid
                container
                alignItems="center"
                direction="column"
                style={{ gap: '0.2rem' }}>
                <RouterLink
                  to={(location) =>
                    cart.items.length === 0
                      ? '#'
                      : `${location.pathname}/checkout`
                  }
                  style={{ width: '100%' }}>
                  <Button
                    fullWidth
                    size="large"
                    disabled={cart.items.length === 0}
                    variant="contained"
                    className={classes.checkoutBtn}>
                    CHECKOUT
                  </Button>
                </RouterLink>
                {process.env.REACT_APP_AUCTIONS === 'enabled' ? (
                  <Grid style={{ width: '100%' }}>
                    <Typography variant="body1" className={classes.orText}>
                      <span className={classes.orTextSpan}>or</span>
                    </Typography>
                    <CreateAuctionButton
                      isDisabled={cart.items.length === 0}
                      createFromShopDetails={true}
                    />
                  </Grid>
                ) : null}
              </Grid>
            ) : action === 'place-auction' &&
              process.env.REACT_APP_AUCTIONS === 'enabled' ? (
              <Button
                disabled={DISABLE_PLACE_ORDER_BTN || typeOfOrder === 'ASAP'}
                onClick={handleAddAuctionModalOpen}
                variant="contained"
                fullWidth
                className={classes.auctionBtn}>
                PLACE AUCTION
              </Button>
            ) : action === 'checkout' ? (
              <Grid
                container
                alignItems="center"
                direction="column"
                style={{ gap: '0.2rem' }}>
                <Button
                  fullWidth
                  disabled={DISABLE_PLACE_ORDER_BTN}
                  onClick={onOrderPlace}
                  variant="contained"
                  className={classes.checkoutBtn}>
                  PLACE ORDER
                </Button>
                <Button
                  className={classes.addItemsBtn}
                  onClick={() => handleAddMoreItems()}>
                  Add More Items
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CartCard;
