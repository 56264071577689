import React from 'react';
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory, useParams } from 'react-router-dom';

const CouponHeader = () => {
  let { actionType = '' } = useParams();
  const history = useHistory();
  const handleHideForm = () => history.goBack();
  const handleShowForm = () => history.push('/service-provider/coupons/add');

  return (
    <Grid container justify="space-between">
      {actionType === 'add' || actionType === 'edit' ? (
        <Grid
          container
          item
          alignItems="center"
          xs={12}
          style={{
            gap: '0.5rem'
          }}>
          <IconButton size="small" onClick={handleHideForm}>
            <ArrowBackIcon fontSize="small" />
          </IconButton>
          <Typography variant="h4">Add / Edit Coupons</Typography>
        </Grid>
      ) : (
        <>
          <Typography variant="h3">Coupons</Typography>
          <Button variant="contained" color="primary" onClick={handleShowForm}>
            Add Coupon
          </Button>
        </>
      )}
    </Grid>
  );
};

export default CouponHeader;
