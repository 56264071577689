import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  errorText: {
    color: theme.palette.error.main
  },
  blueText: {
    color: theme.palette.blue.main
  },
  shopWorkingStatusText: {
    [theme.breakpoints.up('md')]: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  }
}));

const convertToTime = (time) => {
  if (time < 1000) {
    time = '0' + time;
  } else {
    time = time.toString();
  }
  const hrs = time.toString().match(/\d{2}/);
  const amOrPm = hrs < 12 ? ' AM' : ' PM'; // Set AM/PM
  const hrsIn12 = hrs % 12 || 12; // Adjust hours
  return time.replace(/(\d{2})(\d{2})/, hrsIn12 + ':$2' + amOrPm);
};

const ShopCurrentWorkingStatus = ({ workHrs = [] }) => {
  const classes = useStyles();
  const curTime = new Date().getHours() * 100 + new Date().getMinutes(); // current time in working hours time format
  const curDay = new Date().getDay(); // weekday number for today
  const curWorkHrs = workHrs?.filter((d) => d.openDay === curDay);

  // current hours
  const curHrs = curWorkHrs.find(
    (day) =>
      day.openTime < curTime && (!day.closeTime || day.closeTime > curTime)
  );

  // is shop open right now
  const isOpen = !!curHrs;

  // next opening or closing time for today
  let nextTime;

  if (isOpen)
    nextTime = curHrs.closeTime ? convertToTime(curHrs.closeTime) : '';
  else {
    const nextOpenTimes = curWorkHrs
      .map((item) => item.openTime)
      .filter((item) => item > curTime);
    if (nextOpenTimes.length > 0)
      nextTime = convertToTime(Math.min(...nextOpenTimes));
  }

  // calculation for next opening day
  let nextDayDateTime;

  if (!nextTime) {
    let nextDayHrs = [];
    let i = curDay + 1;
    i = i < 7 ? i : 0;
    while (true) {
      nextDayHrs = workHrs?.filter((d) => d.openDay === i);
      if (nextDayHrs.length > 0) break;
      if (i === curDay) break;
      i = i === 6 ? 0 : ++i;
    }

    const nextDayStartHrs = nextDayHrs.reduce(
      (previousValue, currentValue) =>
        currentValue.openTime > previousValue.openTime
          ? previousValue
          : currentValue,
      nextDayHrs.at(0)
    );
    const nextDayStartTime = nextDayStartHrs?.closeTime
      ? nextDayStartHrs?.openTime
      : null;

    const differenceInDay = nextDayStartHrs?.openDay - curDay;

    nextDayDateTime =
      nextDayStartHrs?.openDay === curDay
        ? 'in a week'
        : moment()
            .add(
              differenceInDay >= 0 ? differenceInDay : 7 + differenceInDay,
              'days'
            )
            .set({
              hour: Math.floor(nextDayStartTime / 100),
              minute: nextDayStartTime % 100
            })
            .calendar();
  }

  return (
    <Typography variant="subtitle1" className={classes.shopWorkingStatusText}>
      {isOpen ? (
        <span className={classes.blueText}>Open</span>
      ) : (
        <span className={classes.errorText}>Closed</span>
      )}
      {nextTime ? (
        <>
          <span> - {!isOpen ? 'Open' : 'Close'}</span>s {nextTime}
        </>
      ) : !isOpen && nextDayDateTime ? (
        ` - Opens ${nextDayDateTime}`
      ) : (
        ''
      )}
    </Typography>
  );
};

export default ShopCurrentWorkingStatus;
