import React, { useState, useEffect } from 'react';
import PhotoLibTopBar from './components/PhotoLibTopBar';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import SearchServices from './components/SearchServices';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import CancelIcon from '@material-ui/icons/Cancel';
import AddPhoto from './components/AddPhoto';
import { useLoader } from 'layouts/loaderContext';
import { graphqlOperation, API } from 'aws-amplify';
import { getGallaryPhotoByServiceID } from 'graphql/queries';
import { Image } from 'components/organisms';
import ViewDialog from 'views/LaundryShop/components/ViewDialog';
import { DocumentDeleteDialog } from 'components/organisms';
import { deleteGallaryPhoto } from 'graphql/mutations';
import Pagination from '@material-ui/lab/Pagination';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  paper: {
    padding: 10
  },
  imgHolder: {
    padding: '1rem'
  },
  image: {
    width: '9rem',
    marginRight: '1rem'
  },
  imgName: {
    marginTop: '0.5rem'
  }
}));

const PhotoLibrary = () => {
  const classes = useStyles();
  const { setLoading } = useLoader();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isAddPhoto, setIsAddPhoto] = useState(false);
  const [selectedService, setSelectedService] = useState('');
  const [selectedServiceId, setSelectedServiceId] = useState('');
  const [galleryPhotos, setGalleryPhotos] = useState([]);
  const [showViewDialog, setShowViewDialog] = useState(false);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [viewImg, setViewImg] = useState(null);
  const [page, setPage] = useState(1);

  const ITEMS_PER_PAGE = 12;
  const noOfPages = Math.ceil(galleryPhotos?.length / ITEMS_PER_PAGE);

  useEffect(() => {
    fetchItemPhotos();
  }, [selectedServiceId]);

  const paginationHandler = (event, value) => {
    setPage(value);
  };

  const fetchItemPhotos = () => {
    setPage(1);
    if (selectedServiceId) {
      try {
        setLoading(true);
        API.graphql(
          graphqlOperation(getGallaryPhotoByServiceID, {
            serviceID: selectedServiceId
          })
        ).then(({ data }) => {
          const itemImgs = data.getGallaryPhotoByServiceID.items
            .filter((item) => !item._deleted)
            .map((img) => img);
          setGalleryPhotos(itemImgs);
          setLoading(false);
        });
      } catch (error) {
        console.log('error', error);
        setLoading(false);
      }
    }
  };

  const viewPhotoHandler = (img) => {
    setShowViewDialog(true);
    setViewImg(img.picture.photoURL);
  };
  const handleDeleteClick = (el) => {
    setViewImg(el);
    setShowDeleteConfirmDialog(true);
  };
  const handleModalClose = () => {
    setViewImg(null);
    setShowViewDialog(false);
    setShowDeleteConfirmDialog(false);
  };

  const handleModalConfirm = (id = '', _version = 1) => {
    const input = { id, _version };
    if (input.id) {
      setLoading(true);
      API.graphql(graphqlOperation(deleteGallaryPhoto, { input }))
        .then((data) => {
          enqueueSnackbar('Photo deleted successfully', {
            variant: 'success',
            autoHideDuration: 2000
          });
          setGalleryPhotos(galleryPhotos.filter((photo) => photo?.id !== id));
        })
        .catch((e) => {
          console.log('error', e);
          enqueueSnackbar('Something went wrong..!', {
            variant: 'error',
            autoHideDuration: 2000
          });
        })
        .finally(() => {
          setLoading(false);
          handleModalClose();
        });
    }
  };

  return (
    <Box className={classes.root}>
      <PhotoLibTopBar />
      <Box style={{ marginTop: '1rem' }}>
        <Paper className={classes.paper}>
          <Grid
            container
            item
            xs={12}
            justify="flex-start"
            style={{ margin: '0.5rem 0' }}>
            <SearchServices
              setSelectedService={setSelectedService}
              setSelectedServiceId={setSelectedServiceId}
            />

            {!isAddPhoto ? (
              <Button
                onClick={() => setIsAddPhoto((prevState) => !prevState)}
                disabled={selectedService ? false : true}
                color="primary">
                <AddPhotoAlternateIcon fontSize="large" />
              </Button>
            ) : (
              <Button
                onClick={() => setIsAddPhoto((prevState) => !prevState)}
                color="primary">
                <CancelIcon fontSize="large" />
              </Button>
            )}

            {isAddPhoto && (
              <AddPhoto
                selectedServiceId={selectedServiceId}
                setIsAddPhoto={setIsAddPhoto}
                setGalleryPhotos={setGalleryPhotos}
              />
            )}

            <Grid container>
              {galleryPhotos &&
                galleryPhotos
                  .slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)
                  .map((img, i) => (
                    <Grid
                      item
                      container
                      direction="column"
                      alignItems="center"
                      justify="space-between"
                      className={classes.imgHolder}
                      xs={12}
                      sm={6}
                      md={3}
                      key={i}>
                      <Image
                        docKey={img.picture.photoURL}
                        alt="Product image"
                        className={classes.image}
                      />
                      <Typography variant="body1" className={classes.imgName}>
                        {img.picture.name || ''}
                      </Typography>
                      <Grid
                        container
                        justify="center"
                        alignItems="center"
                        style={{ paddingTop: '0.5rem' }}>
                        <Button
                          size="small"
                          color="secondary"
                          onClick={() => viewPhotoHandler(img)}>
                          View
                        </Button>
                        <Button
                          size="small"
                          style={{ color: '#ff0000ad' }}
                          onClick={() => handleDeleteClick(img)}>
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
            </Grid>
          </Grid>
        </Paper>
        {galleryPhotos?.length > 0 && (
          <Grid
            container
            item
            xs={12}
            justify="center"
            style={{ marginTop: '1rem' }}>
            <Pagination
              count={noOfPages}
              page={page}
              onChange={paginationHandler}
              defaultPage={1}
              color="secondary"
              shape="rounded"
            />
          </Grid>
        )}
      </Box>

      <ViewDialog
        open={showViewDialog}
        handleCancel={handleModalClose}
        docKey={viewImg || ''}
      />
      <DocumentDeleteDialog
        open={showDeleteConfirmDialog}
        confirmText="delete this file"
        handleCancel={handleModalClose}
        handleConfirm={() => handleModalConfirm(viewImg.id, viewImg._version)}
      />
    </Box>
  );
};

export default PhotoLibrary;
