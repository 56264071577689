export const tabs = [
  {
    value: 'home',
    label: 'Home Page Ads'
  },
  {
    value: 'list',
    label: 'Shops List Page Ads'
  }
];

export const initialAdFilters = {
  shop: null,
  dateRange: { start: '', end: '' },
  type: 'all',
  showCurrentAds: true
};
