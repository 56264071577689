import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useAmplifyAuth } from 'context';
import { useAuctions } from 'context/auctions/auctionsContext';
import { Profile, SidebarNav } from './components';
import LogoName from 'components/organisms/LogoName';
import { routes } from 'common/constants';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Drawer, Grid } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import SettingsIcon from '@material-ui/icons/Settings';
import LocalLaundryServiceIcon from '@material-ui/icons/LocalLaundryService';
import StoreIcon from '@material-ui/icons/Store';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import GavelOutlinedIcon from '@material-ui/icons/GavelOutlined';
import ChatIcon from '@material-ui/icons/Chat';
import CropFreeIcon from '@material-ui/icons/CropFree';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    height: '100%',
    padding: theme.spacing(2, 0),
    overflowX: 'hidden'
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  profileContainer: {
    padding: '1rem 0 0',
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2
  },
  navContainer: {
    marginTop: '8rem',
    height: 'calc(100% - 12rem)',
    [theme.breakpoints.up('lg')]: {
      height: 'calc(100% - 13rem)'
    }
  },
  nav: {
    padding: theme.spacing(2)
  },
  logoContainer: {
    zIndex: 2,
    position: 'absolute',
    bottom: 0,
    left: 15,
    padding: '1rem 0.5rem'
  }
}));
const LINKS = [
  {
    title: 'Dashboard',
    href: routes.admin.dashboard,
    icon: <DashboardIcon />,
    userGroup: ['admin']
  },
  {
    title: 'Shops',
    href: routes.admin.shops,
    icon: <StoreIcon />,
    userGroup: ['admin']
  },
  {
    title: 'Shops',
    href: routes.sales.shops,
    icon: <StoreIcon />,
    userGroup: ['sales']
  },
  {
    title: 'Shop',
    href: routes.serviceProvider.shopDetail,
    icon: <StoreIcon />,
    userGroup: ['laundryAdmin']
  },
  {
    title: 'Services',
    href: routes.admin.services,
    icon: <LocalLaundryServiceIcon />,
    userGroup: ['admin']
  },
  {
    title: 'Photo Library',
    href: routes.admin.photoLibrary,
    icon: <PhotoLibraryIcon />,
    userGroup: ['admin']
  },
  {
    title: 'Products & Pricing',
    href: routes.admin.products,
    icon: <ShoppingBasketIcon />,
    userGroup: ['admin']
  },
  {
    title: 'Products & Pricing',
    href: routes.sales.products,
    icon: <ShoppingBasketIcon />,
    userGroup: ['sales']
  },
  {
    title: 'Products & Pricing',
    href: routes.serviceProvider.productsPricing,
    icon: <ShoppingBasketIcon />,
    userGroup: ['laundryAdmin']
  },
  {
    title: 'Coupons',
    href: routes.serviceProvider.coupons,
    icon: <LocalOfferIcon />,
    userGroup: ['laundryAdmin']
  },
  {
    title: 'Orders',
    href: routes.serviceProvider.orders,
    icon: <ShoppingBasketIcon />,
    userGroup: ['laundryAdmin', 'laundryStaff']
  },
  {
    title: 'Customer Reviews',
    href: routes.serviceProvider.reviews,
    icon: <ChatIcon />,
    userGroup: ['laundryAdmin', 'laundryStaff']
  },
  {
    title: 'Auctions',
    href: routes.serviceProvider.auctionsOngoing,
    icon: <GavelOutlinedIcon />,
    userGroup: ['laundryAdmin', 'laundryStaff'],
    showAuctionsBadge: true
  },
  {
    title: 'Ad manager',
    href: routes.admin.ads,
    icon: <TextFieldsIcon />,
    userGroup: ['admin']
  },
  {
    title: 'Sales & Marketing',
    href: routes.admin.salesMarketing,
    icon: <AttachMoneyIcon />,
    userGroup: ['admin']
  },
  {
    title: 'Shop Users',
    href: routes.admin.users,
    icon: <PeopleIcon />,
    userGroup: ['admin']
  },
  {
    title: 'Shops Performance',
    href: routes.admin.shopsPerformance,
    icon: <TrendingUpIcon />,
    userGroup: ['admin']
  },
  {
    title: 'Customers',
    href: routes.admin.customers,
    icon: <PeopleIcon />,
    userGroup: ['admin']
  },
  {
    title: 'Redirect Records',
    href: routes.admin.insights,
    icon: <AssessmentIcon />,
    userGroup: ['admin']
  },
  {
    title: 'Search Banners',
    href: routes.admin.searchBanner,
    icon: <ViewCarouselIcon />,
    userGroup: ['admin']
  },
  {
    title: 'Manage Staff',
    href: routes.serviceProvider.manageStaff,
    icon: <SupervisedUserCircleOutlinedIcon />,
    userGroup: ['laundryAdmin']
  },
  {
    title: 'Settings',
    href: routes.admin.settings,
    icon: <SettingsIcon />,
    userGroup: ['admin']
  },
  {
    title: 'Settings',
    href: routes.sales.settings,
    icon: <SettingsIcon />,
    userGroup: ['sales']
  },
  {
    title: 'Settings',
    href: routes.serviceProvider.settingsProfile,
    icon: <SettingsIcon />,
    userGroup: ['laundryAdmin', 'laundryStaff']
  },
  {
    title: 'QR Code',
    href: routes.serviceProvider.qrCode,
    icon: <CropFreeIcon />,
    userGroup: ['laundryAdmin', 'laundryStaff']
  }
];

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;
  const { auctions = [] } = useAuctions();
  const {
    state: { user }
  } = useAmplifyAuth();
  const classes = useStyles();
  const pages = useMemo(
    () =>
      user?.groups
        ? LINKS.filter(
            ({ title, userGroup }) =>
              userGroup.includes(user.groups[0]) &&
              (process.env.REACT_APP_AUCTIONS === 'enabled'
                ? true
                : title !== 'Auctions')
          )
        : [],
    [user]
  );

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <Grid
        container
        justify="space-between"
        direction="column"
        {...rest}
        className={clsx(classes.root, className)}>
        <Grid
          item
          container
          alignItems="center"
          direction="column"
          className={classes.profileContainer}>
          <Profile />
          <Divider className={classes.divider} />
        </Grid>
        <Grid item className={classes.navContainer}>
          <PerfectScrollbar>
            <SidebarNav
              className={classes.nav}
              pages={pages.map((item) =>
                item.showAuctionsBadge
                  ? {
                      ...item,
                      badgeContent:
                        auctions.filter(
                          (item) =>
                            !item?.isClosed &&
                            new Date(item.startDateTime) < new Date() &&
                            new Date(item.endDateTime) > new Date()
                        )?.length || 0
                    }
                  : item
              )}
            />
          </PerfectScrollbar>
        </Grid>
        <Grid item className={classes.logoContainer}>
          <LogoName size="large" />
        </Grid>
      </Grid>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
