import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Typography,
  Grid,
  Tabs,
  Tab,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useAmplifyAuth } from 'context';
import { useOrders } from 'context/order/ordersContext';
import { useLoader } from 'layouts/loaderContext';
import { API, graphqlOperation } from 'aws-amplify';
import { createMessage } from 'graphql/mutations';
import { routes } from 'common/constants';
import { Header, OrderCard, OrderPaymentDialog } from './components';
import { NavigationBreadcrumbs, CustomSearchBar } from 'components/organisms';
import { MyOrderSkeleton } from 'components/skeletons';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Pagination from '@material-ui/lab/Pagination';
import OrderCancelDialog from 'components/organisms/OrderCancelDialog';
import { DocumentDeleteDialog } from 'components/organisms';
import ReactGA from 'react-ga4';
import ReplayIcon from '@material-ui/icons/Replay';
import OrderRatingDialog from './components/OrderRatingDialog';
import EditDeliveryOptionDialog from './components/EditDeliveryOptionDialog';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    borderTop: '1px solid #0000001A'
  },
  root: {
    overflow: 'hidden',
    marginBottom: '100px',
    margin: '0 auto',
    maxWidth: '90%'
  },
  headerContainer: {
    marginBottom: '0.5rem',
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0
    }
  },
  tabSelected: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white !important'
  },
  tabRoot: {
    color: theme.palette.grey.secondary,
    textTransform: 'none',
    maxWidth: '100%'
  },
  tabsRoot: {
    borderRadius: '8px',
    border: '1px solid',
    borderColor: theme.palette.grey.secondary
  },
  ordersHeader: {
    width: '100%',
    textAlign: 'left',
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    lineHeight: '0.1em',
    padding: '0 0 0.8rem'
  },
  ordersHeaderText: {
    fontWeight: '600'
  },
  pageContentBox: {
    paddingTop: '1.5rem',
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
      paddingLeft: '2.125rem'
    }
  }
}));

const a11yProps = (index) => ({
  id: `vertical-tab-${index}`,
  'aria-controls': `vertical-tabpanel-${index}`
});

const tabs = [
  {
    id: 0,
    name: 'Current Orders',
    icon: <EditOutlinedIcon fontSize="small" />,
    route: routes.customer.order
  },
  {
    id: 1,
    name: 'Order History',
    icon: <NotificationsNoneOutlinedIcon fontSize="small" />,
    route: routes.customer.orderHistory
  }
];

const itemsPerPage = 5;

const Account = () => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { orders: allOrders, dispatch } = useOrders();
  const { loading } = useLoader();
  const [pastOrders, setPastOrders] = useState([]);
  const [currOrders, setCurrOrders] = useState([]);
  const [searchBy, setSearchBy] = useState('');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [currentTab, setCurrentTab] = useState(
    history.location.pathname.split('/').at(-1) === 'history' ? 1 : 0
  );
  const [showOrderPaymentDialog, setShowOrderPaymentDialog] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState();
  const [currSelectedOrder, setCurrSelectedOrder] = useState(null); // TODO two selected order state
  const [forwardOrderActionModal, setForwardOrderActionModal] = useState(false);
  const orders = currentTab === 0 ? currOrders : pastOrders;
  const filteredOrders = orders.filter((_item) =>
    _item?.orderID?.toLowerCase().includes(searchBy.trim().toLowerCase())
  );
  const [page, setPage] = useState(1);
  const [showOrderRatingDialog, setShowOrderRatingDialog] = useState(false);
  const [orderDelivered, setOrderDelivered] = useState(false);
  const [showEditDeliveryDialog, setShowEditDeliveryDialog] = useState(false);
  const [reportDeliveryIssue, setReportDeliveryIssue] = useState({
    openDialog: false,
    reportedForOrders: []
  });
  const {
    state: { user }
  } = useAmplifyAuth();
  const noOfPages = Math.ceil(filteredOrders.length / itemsPerPage);

  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({
      hitType: 'pageview',
      page: `${history.location.pathname}${history.location.search}`,
      title: 'my orders page'
    });

    !!history.location.search &&
      setSearchBy(history.location.search.split('=').at(1));
  }, []);

  const handleTabSwitch = (e, newVal) => {
    handleSearchClear();
    setCurrentTab(newVal);
    setPage(1);
    history.replace(
      `${tabs.find((item) => item.id === newVal)?.route}${
        history.location.search
      }`
    );
  };

  const paginationHandler = (event, value) => {
    setPage(value);
  };

  const handlePaymentClick = (order) => () => {
    setSelectedOrder(order);
    setShowOrderPaymentDialog(true);
  };

  const closeOrderPaymentDialog = () => {
    setShowOrderPaymentDialog(false);
  };

  const loadOrders = (toLoad) => {
    if (user?.id || user?.sub) {
      dispatch({
        type: 'getOrdersByUserId',
        payload: {
          userId: user.id || user.sub,
          reloadOrders: toLoad
        }
      });
    }
  };

  useEffect(() => {
    loadOrders(false);
  }, [user]);

  useEffect(() => {
    const _pastOrders = allOrders.filter(
      (o) =>
        o.status === 'delivered' ||
        o.status === 'declined' ||
        o.status === 'cancelled'
    );
    const _currOrders = allOrders.filter(
      (o) =>
        !(
          o.status === 'delivered' ||
          o.status === 'declined' ||
          o.status === 'cancelled'
        )
    );
    setSelectedOrder((prev) =>
      prev?.id ? allOrders.find((item) => item.id === prev.id) : null
    );
    setCurrOrders(_currOrders);
    setPastOrders(_pastOrders);
    setShowConfirmDialog(false);
  }, [allOrders]);

  const handleCancelClick = (order) => () => {
    setSelectedOrder(order);
    setShowConfirmDialog(true);
  };

  const forwardOrderAcceptHandler = (order) => {
    dispatch({
      type: 'updateOrder',
      payload: {
        order: {
          ...order,
          status: 'newOrder'
        }
      }
    });
    setForwardOrderActionModal(false);
  };

  const handleCancelConfirm = (orderCancelOrDeclineReason = '') => {
    dispatch({
      type: 'updateOrder',
      payload: {
        order: {
          ...selectedOrder,
          status: 'cancelled',
          orderCancelOrDeclineReason
        },
        updateMessage: 'Cancelling order...'
      }
    });
  };

  const sendReportMessage = (message = '', messageIntent = '') => {
    if (!message || !selectedOrder?.id) {
      enqueueSnackbar("Something went wrong... Couldn't submit report...", {
        variant: 'error',
        preventDuplicate: true,
        autoHideDuration: 3000
      });
      return;
    }
    API.graphql(
      graphqlOperation(createMessage, {
        input: {
          orderID: selectedOrder.id,
          sender: selectedOrder.userID,
          message,
          messageIntent,
          status: 'sent'
        }
      })
    )
      .then(() => {
        setReportDeliveryIssue((prev) => ({
          ...prev,
          reportedForOrders: [...prev.reportedForOrders, selectedOrder.id]
        }));
        enqueueSnackbar('Your request has been sent to our team for review.', {
          variant: 'success',
          preventDuplicate: true,
          autoHideDuration: 3000
        });
      })
      .catch(() =>
        console.log('something went wrong while submitting report...')
      );
  };

  const updateSearchToURL = (searchBy = '') =>
    !!searchBy
      ? history.replace(`${history.location.pathname}?orderID=${searchBy}`)
      : history.replace(history.location.pathname);

  const handleSearchChange = (e) => {
    setSearchBy(e.target.value);
    setPage(1);
    updateSearchToURL(e.target.value);
  };

  const handleSearchClear = () => {
    setSearchBy('');
    updateSearchToURL();
  };
  const refreshOrders = () => {
    loadOrders(true);
    setSearchBy('');
    updateSearchToURL();
  };

  const handleForwardRequestActionClick = (order) => () => {
    setCurrSelectedOrder(order);
    setForwardOrderActionModal(true);
  };

  const handleOrderDelivered = (order) => () => {
    setOrderDelivered(true);
    setSelectedOrder(order);
  };

  const handleOrderDeliveryIssue = (order) => () => {
    setReportDeliveryIssue((prev) => ({ ...prev, openDialog: true }));
    setSelectedOrder(order);
  };

  const orderDeliveredHandler = () => {
    setOrderDelivered(false);
    setShowOrderRatingDialog(true);
  };

  const showEditDeliveryHandler = (order) => () => {
    setSelectedOrder(order);
    setShowEditDeliveryDialog(true);
  };

  const handleFeedbackButtonClick = (order) => () => {
    setShowOrderRatingDialog(true);
    setSelectedOrder(order);
  };

  return user ? (
    <Grid className={classes.rootContainer}>
      <Helmet>
        <meta
          name="description"
          content="Want to keep track of your orders and packages with ease? Look no further than our website! We offer a simple and convenient way to monitor all of your current and past orders with our My Orders feature.
          With our user-friendly platform, you can easily access and track your orders, including your current orders and order history. Plus, our order tracking system allows you to monitor the progress of your shipments every step of the way.
          If you're waiting on an Amazon package, our platform makes it easy to track your Amazon order number, so you'll always know when your package is expected to arrive.
          So why wait? Sign up with us today to start tracking your packages and orders with ease. With our advanced tracking features and user-friendly interface, you'll never miss a delivery again!"
        />
        <meta
          name="keywords"
          content="launder-it, launder it, My orders, Current orders, Track my package, Order tracking, Track amazon package, Track order number,"
        />
      </Helmet>

      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <NavigationBreadcrumbs currentLink="Orders" />
        </Grid>
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          className={classes.headerContainer}>
          <Grid item xs={12} sm={6} lg={8}>
            <Header content="My Orders" />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <CustomSearchBar
              value={searchBy}
              onChange={handleSearchChange}
              onClear={handleSearchClear}
              placeholder="search orders"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} md={3} lg={2}>
            <Tabs
              classes={{ root: classes.tabsRoot }}
              orientation="vertical"
              value={currentTab}
              onChange={handleTabSwitch}
              aria-label="my-orders-vertical-tabs">
              {tabs.map((tab) => (
                <Tab
                  value={tab.id}
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected
                  }}
                  label={
                    <Grid container justify="space-between" alignItems="center">
                      <Grid
                        item
                        container
                        alignItems="center"
                        justify="space-between"
                        style={{ width: 'fit-content' }}>
                        {tab.icon}
                        &nbsp; &nbsp;
                        {tab.name}
                      </Grid>
                      <Grid
                        item
                        container
                        alignItems="center"
                        style={{ width: 'fit-content' }}>
                        <ChevronRightIcon fontSize="small" />
                      </Grid>
                    </Grid>
                  }
                  {...a11yProps(tab.id)}
                  key={`my-orders-vertical-tabs-items-${tab.id}`}
                />
              ))}
            </Tabs>
          </Grid>
          <Grid item xs={12} md={9} lg={10} className={classes.pageContentBox}>
            <Grid item xs={12} className={classes.ordersHeader}>
              <Typography variant="h5" className={classes.ordersHeaderText}>
                <span>
                  {currentTab === 0 ? 'Current Orders' : 'Order History'}
                </span>
                <Tooltip title="Refresh" placement="right">
                  <IconButton
                    onClick={refreshOrders}
                    style={{ padding: '0 0.5rem' }}>
                    <ReplayIcon style={{ color: '#194376' }} />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '18px' }}>
              {!loading ? (
                filteredOrders.length > 0 ? (
                  filteredOrders
                    .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                    .map((order, index) => (
                      <OrderCard
                        order={order}
                        index={index}
                        hideReportBtn={reportDeliveryIssue.reportedForOrders.includes(
                          order.id
                        )}
                        handleCancelClick={handleCancelClick(order)}
                        handlePaymentClick={handlePaymentClick(order)}
                        handleForwardRequestActionClick={handleForwardRequestActionClick(
                          order
                        )}
                        showCancelButton={currentTab === 0}
                        handleOrderDelivered={handleOrderDelivered(order)}
                        showEditDeliveryHandler={showEditDeliveryHandler(order)}
                        handleFeedbackButtonClick={handleFeedbackButtonClick(
                          order
                        )}
                        handleOrderDeliveryIssue={handleOrderDeliveryIssue(
                          order
                        )}
                        key={`my-orders-order-item-${index}`}
                        selectedOrder={selectedOrder}
                      />
                    ))
                ) : (
                  <Grid container justify="center">
                    No orders available.
                  </Grid>
                )
              ) : (
                <Grid container item>
                  {[...Array(itemsPerPage).keys()].map((item) => {
                    return <MyOrderSkeleton key={item} />;
                  })}
                </Grid>
              )}

              <Grid
                container
                item
                xs={12}
                justify="center"
                style={{ marginTop: '2rem' }}>
                <Pagination
                  count={noOfPages}
                  page={page}
                  onChange={paginationHandler}
                  defaultPage={1}
                  color="secondary"
                  shape="rounded"
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <OrderPaymentDialog
        open={showOrderPaymentDialog}
        handleClose={closeOrderPaymentDialog}
        setShowEditDeliveryDialog={setShowEditDeliveryDialog}
        order={selectedOrder}
      />
      <OrderCancelDialog
        open={showConfirmDialog || reportDeliveryIssue.openDialog}
        handleClose={
          reportDeliveryIssue.openDialog
            ? () =>
                setReportDeliveryIssue((prev) => ({
                  ...prev,
                  openDialog: false
                }))
            : () => setShowConfirmDialog(false)
        }
        order={selectedOrder}
        handleCancelConfirm={handleCancelConfirm}
        handleReportSubmit={sendReportMessage}
        isDeliveryIssue={reportDeliveryIssue.openDialog}
      />
      <DocumentDeleteDialog
        open={forwardOrderActionModal}
        handleCancel={() => setForwardOrderActionModal(false)}
        handleConfirm={() => forwardOrderAcceptHandler(currSelectedOrder)}
        title="This order is forwarded"
        confirmText="accept this forward"
        note="Note: Please verify all your order details before taking any action."
      />
      <DocumentDeleteDialog
        open={orderDelivered}
        handleCancel={() => setOrderDelivered(false)}
        handleConfirm={orderDeliveredHandler}
        title="Confirm Order Delivery"
        confirmText="mark this order as delivered"
      />
      <OrderRatingDialog
        open={showOrderRatingDialog}
        handleModalClose={() => setShowOrderRatingDialog(false)}
        order={selectedOrder}
      />
      <EditDeliveryOptionDialog
        order={selectedOrder}
        open={showEditDeliveryDialog}
        handleModalClose={() => setShowEditDeliveryDialog(false)}
      />
    </Grid>
  ) : (
    <Grid container justify="center">
      <Header content="Please login to review your orders" />
    </Grid>
  );
};

export default Account;
