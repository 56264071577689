import palette from '../palette';

export default {
  root: {
    textTransform: 'none',
    fontWeight: '600'
  },
  text: {
    '&:hover': {
      color: palette.blue.secondary
    },
    '&:active': {
      color: palette.blue.secondary
    }
  },
  contained: {
    boxShadow:
      '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: palette.primary.secondary
    }
  }
};
