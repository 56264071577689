import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  IconButton,
  useMediaQuery,
  Select,
  MenuItem
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { JoinLinks } from 'components/organisms';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSearchBanner } from 'context/searchBanner/searchBannerContext';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import throttle from 'lodash/throttle';
import ReactGA from 'react-ga4';
import parse from 'autosuggest-highlight/parse';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { useShops } from 'context/shop/shopsContext';

function loadScript(src, position, id) {
  if (!position) return;
  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    alignItems: 'center',
    textAlign: 'center',
    color: 'black',
    padding: '0 1.5rem',
    [theme.breakpoints.up('sm')]: {
      padding: '0 5rem'
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 10rem'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0 15.5rem'
    }
  },
  rootSize: {
    height: 'auto',
    width: '100%',
    aspectRatio: '4/5',
    [theme.breakpoints.up('sm')]: {
      aspectRatio: '8/3'
    }
  },
  searchBarContainer: {
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset'
  },
  searchButton: {
    height: '51.25px',
    width: '49px',
    padding: '0',
    backgroundColor: theme.palette.yellow?.main || '#EDD242',
    color: 'white',
    borderRadius: '0 0.25rem 0.25rem 0',
    '&:hover': {
      backgroundColor: '#FFDF42'
    },
    [theme.breakpoints.up('md')]: {
      width: '55px'
    }
  },
  offer: {
    backgroundColor: '#1f6893'
  },
  heading: {
    marginBottom: '0.5rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '65px',
      fontWeight: 'bold',
      lineHeight: '69px'
    }
  },
  textInputWrapper: {
    backgroundColor: '#fff',
    borderColor: 'transparent'
  },
  autoCompleteRoot: {
    width: 'calc(100% - 134px)',
    '& fieldset': {
      border: 'none !important'
    },
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 185px)'
    }
  },
  autoCompletePaper: {
    margin: '0',
    opacity: '0.98'
  },
  searchShopSelect: {
    padding: '0 1rem',
    width: '85px',
    backgroundColor: '#fff',
    borderRadius: '0.25rem 0 0 0.25rem',
    borderRight: '1px solid #ccc',
    fontWeight: 600,
    color: '#444',
    [theme.breakpoints.up('md')]: {
      width: '130px'
    }
  },
  searchShopMenu: {
    fontWeight: 600,
    color: '#444'
  },
  selectStyle: {
    paddingRight: '0px !important',
    [theme.breakpoints.up('md')]: {
      paddingRight: '24px !important'
    }
  }
}));

export default function Search() {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  let history = useHistory();
  const classes = useStyles();
  const navigateTo = (value) => history.push(`/shop/postcode/${value}`);
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const { banner, dispatch } = useSearchBanner();
  const [searchType, setSearchType] = useState('postal_code');
  const [selectedShopName, setSelectedShopName] = useState(null);
  const { shops = [] } = useShops();
  const loaded = React.useRef(false);
  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }
    loaded.current = true;
  }

  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({
      hitType: 'pageview',
      page: `${history.location.pathname}${history.location.search}`,
      title: 'home page'
    });

    // getting random banner
    dispatch({ type: 'getBanner' });

    // setting react searched postcode to the search bar as default value
    const oldValue = localStorage.getItem('recentPostCode');
    !!oldValue && setValue(oldValue);
  }, []);

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  const onClickHandler = () => {
    if (searchType === 'shop_name') {
      if (selectedShopName) {
        const shopName = selectedShopName.name.split(' ').join('-');
        history.push(`/shop/${selectedShopName.shopID}/${shopName}`);
      }
    } else {
      if (value) {
        localStorage.setItem('recentPostCode', value);
        navigateTo(value);
      }
    }
  };

  const enterSearchHandler = (e) => {
    if (searchType === 'shop_name') {
      if (e.key === 'Enter' && selectedShopName) {
        const shopName = selectedShopName.name.split(' ').join('-');
        history.push(`/shop/${selectedShopName.shopID}/${shopName}`);
      }
    } else {
      if (e.key === 'Enter' && value) {
        localStorage.setItem('recentPostCode', value);
        navigateTo(value);
      }
    }
  };

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current)
      if (window.google?.maps?.places)
        autocompleteService.current = new window.google.maps.places.AutocompleteService();
      else return undefined;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        types: ['postal_code'],
        componentRestrictions: { country: 'GB' }
      },
      (results) => {
        let newOptions = [];
        if (value) newOptions.push(value);
        if (results) newOptions = [...newOptions, ...results];
        active && setOptions(newOptions);
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handleSearchType = (event) => setSearchType(event.target.value);

  return banner?.image ? (
    <>
      <Grid
        container
        className={clsx(classes.root, classes.rootSize)}
        style={{
          backgroundImage: sm
            ? `url(${banner.image})`
            : `url(${banner.mobileImage})`
        }}
        justify="center">
        <Grid container justify="center" item xs={12} sm={8}>
          <Grid item xs={12}>
            <Typography
              variant="h1"
              className={classes.heading}
              align="left"
              style={{ color: banner.color || '#fff' }}>
              {banner.title || ''}
            </Typography>
          </Grid>
          <Grid container item xs={12} className={classes.searchBarContainer}>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              disableUnderline
              defaultValue={'postal_code'}
              value={searchType}
              onChange={handleSearchType}
              className={classes.searchShopSelect}
              classes={{ select: classes.selectStyle }}>
              <MenuItem
                value={'postal_code'}
                className={classes.searchShopMenu}>
                Postal Code
              </MenuItem>
              <MenuItem value={'shop_name'} className={classes.searchShopMenu}>
                Shop Name
              </MenuItem>
            </Select>
            <Autocomplete
              id="search-select-autocomplete"
              autoComplete
              disableClearable
              noOptionsText={
                searchType === 'postal_code'
                  ? 'Oops! It seems like the postal code you entered is incorrect.'
                  : 'Sorry, no shops found matching that name. Please try another shop.'
              }
              {...(searchType === 'postal_code' && {
                autoSelect: true,
                forcePopupIcon: true,
                filterOptions: (x) => x,
                filterSelectedOptions: true,
                includeInputInList: true,
                getOptionSelected: (option, value) =>
                  value === option || option?.terms?.at(-2)?.value === value,
                onInputChange: (event, newInputValue) =>
                  setInputValue(newInputValue),
                onFocus: () => {
                  setValue(null);
                  setOptions([]);
                  setInputValue('');
                }
              })}
              autoHighlight
              getOptionLabel={(option) => {
                if (searchType === 'shop_name') {
                  return `${option.name}, ${option.postcode}`;
                } else {
                  return typeof option === 'string'
                    ? option
                    : option.description;
                }
              }}
              onChange={(event, newValue) => {
                if (searchType === 'shop_name') {
                  setSelectedShopName(newValue);
                } else {
                  !!newValue && setOptions((prev) => [newValue, ...prev]);
                  if (newValue?.terms?.length > 0) {
                    setValue(newValue.terms.at(-2)?.value || '');
                  }
                }
              }}
              options={searchType === 'shop_name' ? shops : options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  margin="none"
                  placeholder={
                    searchType === 'shop_name'
                      ? 'Search by Shop Name'
                      : 'Search by Postal Code'
                  }
                  className={classes.textInputWrapper}
                  variant="outlined"
                  onChange={enterSearchHandler}
                  onKeyDown={enterSearchHandler}
                />
              )}
              renderOption={(option) => {
                if (searchType === 'shop_name') {
                  return (
                    <Grid alignItems="center" container>
                      <Grid item>
                        <LocationOnIcon className={classes.icon} />
                      </Grid>
                      <Grid item xs>
                        <span style={{ fontWeight: 600 }}>
                          {option.name},&nbsp;
                          {option.postcode}
                        </span>
                        <Typography color="secondary" variant="body2">
                          {option.address}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                } else {
                  const matches =
                    option.structured_formatting.main_text_matched_substrings;
                  const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [
                      match.offset,
                      match.offset + match.length
                    ])
                  );

                  return (
                    <Grid alignItems="center" container>
                      <Grid item>
                        <LocationOnIcon className={classes.icon} />
                      </Grid>
                      <Grid item xs>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400
                            }}>
                            {part.text}
                          </span>
                        ))}

                        <Typography color="secondary" variant="body2">
                          {option.structured_formatting.secondary_text}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                }
              }}
              value={searchType === 'shop_name' ? selectedShopName : value}
              classes={{
                root: classes.autoCompleteRoot,
                paper: classes.autoCompletePaper
              }}
            />
            <IconButton
              className={classes.searchButton}
              onClick={onClickHandler}
              variant="contained">
              <ArrowForwardIcon />
            </IconButton>
          </Grid>
          {md ? (
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="left"
                className={classes.bannerText}>
                <br />
                {searchType === 'postal_code'
                  ? 'Eg. AB10 1 - ZE3 9'
                  : 'Eg. Five star dry cleaners'}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {md ? null : (
        <Grid container justify="center" className={classes.offer}>
          <JoinLinks />
        </Grid>
      )}
    </>
  ) : (
    <Skeleton variant="rect" animation="wave" className={classes.rootSize} />
  );
}
