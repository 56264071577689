import {
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Tooltip,
  Typography,
  InputAdornment
} from '@material-ui/core';
import React, { useState } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(() => ({
  themeYellowColor: {
    color: '#edd242'
  },
  leftRightMargin: {
    margin: '0 1rem'
  },
  checkboxStyle: {
    marginRight: 0,
    marginLeft: 0,
    padding: 0,
    verticalAlign: 'middle'
  },
  leftRightMargin2: {
    marginRight: '1rem',
    marginLeft: '0.5rem'
  },
  tooltip: {
    fontSize: '14px'
  }
}));

const ShopCharges = ({
  selectedShop,
  setSelectedShop,
  handleChange,
  handleNumberChange
}) => {
  const classes = useStyles();

  return (
    <CardContent>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12} container alignItems="center">
          <Tooltip
            title="You now have the flexibility to customize the collection and delivery range based on your preferences. If you wish to cover the entire UK, simply set this range to 0"
            placement="top"
            arrow
            classes={{ tooltip: classes.tooltip }}>
            <InfoIcon fontSize="small" className={classes.themeYellowColor} />
          </Tooltip>
          <Typography
            component="span"
            variant="body1"
            className={classes.leftRightMargin}>
            Standard collection or delivery fee
          </Typography>
          <TextField
            fullWidth
            margin="dense"
            placeholder="Enter fee"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">£</InputAdornment>
              )
            }}
            name="standardDeliveryFee"
            onChange={handleNumberChange}
            required
            value={selectedShop?.standardDeliveryFee || ''}
            variant="outlined"
            size="small"
          />
        </Grid>

        <Grid item md={6} xs={12} container alignItems="center">
          <Tooltip
            title="You now have the flexibility to customize the collection and delivery range based on your preferences. If you wish to cover the entire UK, simply set this range to 0"
            placement="top"
            arrow
            classes={{ tooltip: classes.tooltip }}>
            <InfoIcon fontSize="small" className={classes.themeYellowColor} />
          </Tooltip>
          <Typography
            component="span"
            variant="body1"
            className={classes.leftRightMargin}>
            Collection and delivery distance range upto
          </Typography>
          <TextField
            fullWidth
            size="small"
            // error={
            //   isCollectionAndDeliveryHoursAvailable &&
            //   !selectedShop.distanceRange
            // }
            margin="dense"
            name="distanceRange"
            onChange={handleNumberChange}
            required
            value={selectedShop?.distanceRange || ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">miles</InputAdornment>
              )
            }}
            variant="outlined"
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item md={6} xs={12} container alignItems="center">
          <Tooltip
            title="You now have the flexibility to customize the collection and delivery range based on your preferences. If you wish to cover the entire UK, simply set this range to 0"
            placement="top"
            arrow
            classes={{ tooltip: classes.tooltip }}>
            <InfoIcon fontSize="small" className={classes.themeYellowColor} />
          </Tooltip>
          <Typography
            component="span"
            className={classes.leftRightMargin2}
            variant="body1">
            Free collection and delivery for order amount starting from
          </Typography>
          <TextField
            margin="dense"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">£</InputAdornment>
              )
            }}
            name="minPriceForFree"
            onChange={handleNumberChange}
            fullWidth
            required
            value={selectedShop?.minPriceForFree || ''}
            variant="outlined"
            size="small"
          />
        </Grid>

        <Grid item md={6} xs={12} container alignItems="center">
          <Tooltip
            title="You have the freedom to define the range within which collection and delivery services are offered without incurring any additional costs"
            placement="top"
            arrow
            classes={{ tooltip: classes.tooltip }}>
            <InfoIcon fontSize="small" className={classes.themeYellowColor} />
          </Tooltip>
          <Typography
            component="span"
            variant="body1"
            className={classes.leftRightMargin}>
            Free Collection and delivery distance range upto
          </Typography>
          <TextField
            fullWidth
            size="small"
            margin="dense"
            name="distanceRangeForFree"
            onChange={handleNumberChange}
            required
            value={selectedShop?.distanceRangeForFree || ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">miles</InputAdornment>
              )
            }}
            variant="outlined"
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item md={6} xs={12} container alignItems="center">
          <FormControlLabel
            control={
              <Switch
                checked={selectedShop?.enableAsapDelivery || false}
                onChange={handleChange}
                name="enableAsapDelivery"
                color="primary"
              />
            }
            style={{ margin: 0 }}
            label="Enable/Disable ASAP option for same-day collection and delivery."
            labelPlacement="start"
          />
        </Grid>
        {selectedShop.enableAsapDelivery && (
          <Grid item md={6} xs={12} container alignItems="center">
            <Tooltip
              title="You now have the flexibility to customize the collection and delivery range based on your preferences. If you wish to cover the entire UK, simply set this range to 0"
              placement="top"
              arrow
              classes={{ tooltip: classes.tooltip }}>
              <InfoIcon fontSize="small" className={classes.themeYellowColor} />
            </Tooltip>
            <Typography
              component="span"
              variant="body1"
              className={classes.leftRightMargin}>
              ASAP fee
            </Typography>
            <TextField
              margin="dense"
              placeholder="Enter fee"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">£</InputAdornment>
                )
              }}
              name="AsapDeliveryFee"
              onChange={handleNumberChange}
              required
              value={selectedShop?.AsapDeliveryFee || ''}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
        )}
      </Grid>
    </CardContent>
  );
};

export default ShopCharges;
