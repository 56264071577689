import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { routes } from 'common/constants';
import ShopDocuments from './ShopDocuments';
import UploadedShopDocuments from './UploadedShopDocuments';
import AddIcon from '@material-ui/icons/Add';
import { ArrowBack } from '@material-ui/icons';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Container,
  Fab,
  Divider,
  IconButton
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: 'none'
  },
  contentContainer: {
    padding: '1rem'
  },
  header: {
    padding: '1rem'
  }
}));

function DocumentsUpload(props) {
  const { selectedShop, setSelectedShop, setDisabled } = props;
  const [showUploadDropzone, setShowUploadDropzone] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const showUploader = () => {
    setShowUploadDropzone(true);
    history.replace(routes.serviceProvider.shopDocumentsAdd);
  };

  const hideUploader = () => {
    setShowUploadDropzone(false);
    history.replace(routes.serviceProvider.shopDocuments);
  };

  return (
    <Card className={classes.root}>
      <CardContent style={{ padding: '0' }}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className={classes.header}>
          <Grid container item xs={10} alignItems="center">
            {showUploadDropzone && (
              <IconButton onClick={hideUploader}>
                <ArrowBack fontSize="small" />
              </IconButton>
            )}
            <CardHeader
              title="Shop Documents & License"
              subheader="Shop Related Documnets"
              style={{ padding: '0' }}
            />
          </Grid>
          {showUploadDropzone ? null : (
            <Grid container item xs={2} alignItems="center" justify="flex-end">
              <Fab color="secondary" size="small" onClick={showUploader}>
                <AddIcon />
              </Fab>
            </Grid>
          )}
        </Grid>
        <Divider />

        <Container className={classes.contentContainer}>
          {showUploadDropzone ? (
            <>
              <ShopDocuments
                hideUploader={hideUploader}
                setSelectedShop={setSelectedShop}
                setDisabled={setDisabled}
              />
            </>
          ) : (
            <>
              <UploadedShopDocuments
                selectedShop={selectedShop}
                setSelectedShop={setSelectedShop}
              />
            </>
          )}
        </Container>
      </CardContent>
    </Card>
  );
}

export default DocumentsUpload;
