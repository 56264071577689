import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Tabs, Tab } from '@material-ui/core';
import { NavigationBreadcrumbs } from 'components/organisms';
import {
  AddressModal,
  Details,
  MyAddresses,
  SavedCardDetails
} from './components';
import { useAmplifyAuth } from 'context';
import { Password } from '../Settings/components';
import { listAddresss } from 'graphql/customQueries';
import { API, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { updateUserDetail } from 'graphql/mutations';
import { isExternalUser } from 'common/utilFunctions';
import ReactGA from 'react-ga4';
import { tabs } from './constants';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    borderTop: '1px solid #0000001A'
  },
  root: {
    overflow: 'hidden',
    marginBottom: '100px',
    margin: '0 auto',
    maxWidth: '90%'
  },
  tabSelected: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white !important'
  },
  tabRoot: {
    color: theme.palette.grey.secondary,
    textTransform: 'none',
    maxWidth: '100%'
  },
  tabsRoot: {
    borderRadius: '8px',
    border: '1px solid',
    borderColor: theme.palette.grey.secondary
  },
  ordersHeader: {
    width: '100%',
    textAlign: 'left',
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    lineHeight: '0.1em',
    margin: '10px 0'
  },
  ordersHeaderText: {
    background: '#fff',
    paddingRight: '10px',
    fontWeight: '600'
  },
  pageContentBox: {
    paddingTop: '1.5rem',
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
      paddingLeft: '2.125rem'
    }
  }
}));

const a11yProps = (index) => ({
  id: `vertical-tab-${index}`,
  'aria-controls': `vertical-tabpanel-${index}`
});

const TAB_LAST_INDEX = tabs.length - 1;

const Profile = () => {
  const classes = useStyles();
  const [showAddNewAddressForm, setShowAddNewAddressForm] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [currentTab, setCurrentTab] = useState(0);
  const [isGoogleUser, setIsGoogleUser] = useState(false);
  const [showAddressDetails, setShowAddressDetails] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  let {
    state: { user },
    _refreshUser
  } = useAmplifyAuth();

  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({
      hitType: 'pageview',
      page: '/customer/profile',
      title: 'my profile page'
    });
  }, []);

  useEffect(() => {
    if (user?.username) {
      isExternalUser()
        .then((res) => setIsGoogleUser(res))
        .catch((error) => console.log('error', error));
      API.graphql(
        graphqlOperation(listAddresss, {
          filter: { userID: { eq: user.username } },
          limit: 1000
        })
      )
        .then(({ data }) => {
          const _addressList = data.listAddresss.items;
          if (_addressList.length > 0) {
            setAddressList(_addressList.filter((a) => !a._deleted));
          }
        })
        .catch((error) => console.log("couldn't fetch addresses", error));
    }
  }, [user]);

  const addAddressHandler = () => {
    setShowAddNewAddressForm(true);
    setShowAddressDetails(true);
  };

  const handleShowAddressClick = (address) => () => {
    setShowAddNewAddressForm(false);
    setSelectedAddress(address);
    setShowAddressDetails(true);
  };

  const updateAddressList = (data, operation) => {
    if (operation === 'add') {
      setAddressList([...addressList, data]);
    } else if (operation === 'edit') {
      setAddressList(
        addressList.map((item) => (item.id === data.id ? data : item))
      );
    } else {
      setAddressList(addressList.filter((a) => a.id !== data.id));
    }
    setShowAddressDetails(false);
  };

  const handleSetDefaultAddress = (id) => async () => {
    const snackBar = enqueueSnackbar('Updating default address...', {
      variant: 'info',
      persist: true
    });
    try {
      const resp = await API.graphql(
        graphqlOperation(updateUserDetail, { defaultAddress: id })
      );
      enqueueSnackbar('Address updated', {
        variant: 'success',
        autoHideDuration: 2000
      });
      _refreshUser();
    } catch (error) {
      console.error('Something went wrong', error);
    } finally {
      closeSnackbar(snackBar);
    }
  };

  return (
    <Grid className={classes.rootContainer}>
      <Helmet>
        <meta
          name="description"
          content="Our website offers a variety of payment options to suit your needs, including bank transfers. With our secure payment portal, you can rest easy knowing that your payment is safe and secure.
          With our user-friendly platform, managing your payments is a breeze. Simply log in to your profile to access all of your payment information in one convenient location. Plus, you can customize your experience by opting in to web push notifications, so you never miss an important payment deadline.
          If you're always on the go, you'll love our notifications on iPhone feature. With this handy tool, you'll receive timely notifications right on your phone, so you can stay up to date with all of your payment activity."
        />
        <meta
          name="keywords"
          content="launder-it, launder it, Payment method, My profile, web push notifications, Notifications on iphone, Notifications, Payment, Bank Transfer,"
        />
      </Helmet>

      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <NavigationBreadcrumbs currentLink="My Profile" />
        </Grid>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          style={{ minHeight: '64px' }}>
          <Typography variant="h3">My Profile</Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} md={3} lg={2}>
            <Tabs
              classes={{
                root: classes.tabsRoot
              }}
              orientation="vertical"
              value={currentTab}
              onChange={(e, newVal) => setCurrentTab(newVal)}
              aria-label="my-orders-vertical-tabs">
              {tabs
                .filter(
                  (item, index) => !(isGoogleUser && index === TAB_LAST_INDEX)
                )
                .map((tab, index) => (
                  <Tab
                    value={index}
                    disabled={index === TAB_LAST_INDEX && isGoogleUser}
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.tabSelected
                    }}
                    label={
                      <Grid
                        container
                        justify="space-between"
                        alignItems="center">
                        <Grid
                          item
                          container
                          alignItems="center"
                          justify="space-between"
                          style={{ width: 'fit-content' }}>
                          {tab.icon}
                          &nbsp; &nbsp;
                          {tab.name}
                        </Grid>
                        <Grid
                          item
                          container
                          alignItems="center"
                          style={{ width: 'fit-content' }}>
                          <ChevronRightIcon fontSize="small" />
                        </Grid>
                      </Grid>
                    }
                    {...a11yProps(index)}
                    key={`my-orders-vertical-tabs-items-${index}`}
                  />
                ))}
            </Tabs>
          </Grid>
          <Grid item xs={12} md={9} lg={10} className={classes.pageContentBox}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.ordersHeader}>
                <span className={classes.ordersHeaderText}>
                  {tabs[currentTab].name}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {currentTab === 0 ? (
                <Details />
              ) : currentTab === 1 ? (
                <SavedCardDetails />
              ) : currentTab === 2 ? (
                <MyAddresses
                  addressList={addressList}
                  addAddressHandler={addAddressHandler}
                  handleShowAddressClick={handleShowAddressClick}
                  handleSetDefaultAddress={handleSetDefaultAddress}
                />
              ) : currentTab === 3 ? (
                <Password style={{ marginTop: '20px', width: '100%' }} />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AddressModal
        open={showAddressDetails}
        handleClose={() => setShowAddressDetails(false)}
        updateAddressList={updateAddressList}
        showAddNewAddressForm={showAddNewAddressForm}
        hideAddNewAddressForm={() => setShowAddNewAddressForm(false)}
        selectedAddress={selectedAddress}
      />
    </Grid>
  );
};

export default Profile;
