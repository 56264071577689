import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme)=>({
    root:{
        display:"flex",
        alignItems:"center",
        height:200, // TODO: make dynamic to support all height banners
        backgroundColor:theme.palette.captionBox,
    }
}))

const Banner = (props) => {
    const classes=useStyles();
    return (
        <Box className={classes.root} style={props.style}>
            {props.children}
        </Box>
    )
}

export default Banner;