import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { UsersToolbar, UsersTable } from './components';
import { StaffDetails } from 'views/ServiceSettings/components/Staff/components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const Users = () => {
  const [searchBy, setSearchBy] = useState('');
  const [selectedShop, setSelectedShop] = useState(null);
  const [selectedUser, setSelectedUser] = useState({});
  const classes = useStyles();

  const handleSearchChange = (e) => setSearchBy(e.target.value.toLowerCase());

  const handleClear = () => setSearchBy('');

  const handleShopChange = (e, newVal) => setSelectedShop(newVal);

  const handleUserNameClick = (id) => () => setSelectedUser({ id });

  return (
    <div className={classes.root}>
      {!selectedUser?.id ? (
        <>
          <UsersToolbar
            value={searchBy}
            handleChange={handleSearchChange}
            handleClear={handleClear}
            handleShopChange={handleShopChange}
            selectedShop={selectedShop}
          />
          <div className={classes.content}>
            <UsersTable
              searchBy={searchBy}
              selectedShopId={selectedShop?.id || ''}
              handleUserNameClick={handleUserNameClick}
            />
          </div>
        </>
      ) : (
        <StaffDetails
          selectedStaff={selectedUser}
          setSelectedStaff={setSelectedUser}
          handleClose={() => setSelectedUser({})}
        />
      )}
    </div>
  );
};

export default Users;
