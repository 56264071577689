import { API, graphqlOperation } from 'aws-amplify';
import { listShops, getShopByShopID } from './shopsQueries';
import { updateShop } from './shopsMutations';
import { getProductsByShop } from 'context/products/productAPIs';
import { getCustomerReviewByShop } from './shopsQueries';

export const loadShops = (limit = 100, nextToken = '') =>
  new Promise((resolve, reject) => {
    const params = { limit };
    if (!!nextToken) params.nextToken = nextToken;
    API.graphql(graphqlOperation(listShops, params))
      .then((data) =>
        resolve({
          ...data.data.listShops,
          items: data.data.listShops.items.filter(
            (item) => item._deleted !== true && item.status !== 'rejected'
          )
        })
      )
      .catch((err) => reject(err));
  });

export const loadShopWithProductByID = (shopID) =>
  new Promise(async (resolve, reject) => {
    try {
      let shop;
      const shopDataResp = await API.graphql(
        graphqlOperation(getShopByShopID, { shopID })
      );

      if (shopDataResp.data.getShopByShopID.items.length > 0)
        shop = shopDataResp.data.getShopByShopID.items[0];
      if (!shop) resolve('NO_DATA');

      if (!!shop.products.nextToken) {
        const allProducts = await getProductsByShop(shop.id);
        shop = { ...shop, products: { items: allProducts } };
      }

      if (!!shop.reviews.nextToken) {
        const allReviewsResp = await API.graphql(
          graphqlOperation(getCustomerReviewByShop, {
            shopID: shop.id,
            limit: 8000
          })
        );
        const allReviews = allReviewsResp.data.getCustomerReviewByShop;
        shop = { ...shop, reviews: allReviews };
      }

      resolve(shop);
    } catch (error) {
      console.log('something went wrong', error);
      reject(error);
    }
  });

export const updateShopData = (input) =>
  new Promise((resolve, reject) =>
    API.graphql(graphqlOperation(updateShop, { input }))
      .then((data) => resolve(data.data.updateShop))
      .catch((err) => reject(err))
  );
