import React, { useEffect, useState } from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useShops } from 'context/shop/shopsContext';
import { useLoader } from 'layouts/loaderContext';
import { useRef } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '12.5rem',
    cursor: 'pointer'
  },
  modalContainer: {
    width: '250px',
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgb(0 0 0 / 30%)',
    padding: '1rem',
    lineHeight: '1.2rem',
    zIndex: 999
  },
  locationIcon: { color: '#ea4335', fontSize: '32px' }
}));

const DashboardMarker = ({ text, postalCode, area, range }) => {
  const [showModal, setShowModal] = useState(false);
  const [postalCodeState, setPostalCodeState] = useState(null);
  const [locationRange, setLocationRange] = useState(0);
  const classes = useStyles();
  const { shops = [], dispatch } = useShops();
  const { loading } = useLoader();
  const modalRef = useRef(null);

  useEffect(() => {
    if (postalCodeState && locationRange) {
      dispatch({
        type: 'getShopsByPostCode',
        payload: {
          postalCode: postalCodeState,
          range: locationRange,
          showSnackbar: true
        }
      });
    }
  }, [postalCodeState]);

  useEffect(() => {
    function handleOutsideClick(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModal(false);
        setPostalCodeState(null);
        setLocationRange(0);
      }
    }
    document.body.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.body.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const pendingShops = shops?.filter(
    (shop) => shop.status === 'pending' || !shop.status
  ).length;
  const activeShops = shops?.filter(
    (shop) => shop.status === 'active' && shop.isLive
  ).length;
  const offlineShops = shops?.filter(
    (shop) => shop.status === 'active' && !shop.isLive
  ).length;
  const otherShops = shops?.filter(
    (shop) =>
      shop.status !== 'pending' &&
      shop.status !== 'active' &&
      shop.status !== null
  ).length;

  const totalShops = shops?.length;

  return (
    <Grid container direction="column" className={classes.root}>
      <LocationOnIcon
        onClick={() => {
          setShowModal(!showModal);
          setPostalCodeState(postalCode);
          setLocationRange(range);
        }}
        className={classes.locationIcon}
      />
      {showModal && (
        <div ref={modalRef} className={classes.modalContainer}>
          <Grid>
            <Typography variant="h6" style={{ marginBottom: '0.5rem' }}>
              {`Estimated shops data (${area})`}
              <Divider />
            </Typography>
            {!loading ? (
              totalShops > 0 ? (
                <>
                  <Typography>
                    Total shops: &nbsp;<b>{totalShops}</b>
                  </Typography>
                  <Typography>
                    Active shops: &nbsp;<b>{activeShops}</b>
                  </Typography>
                  <Typography>
                    Pending shops: &nbsp;<b>{pendingShops}</b>
                  </Typography>
                  <Typography>
                    Offline shops: &nbsp;<b>{offlineShops}</b>
                  </Typography>
                  <Typography>
                    Other shops: &nbsp;<b>{otherShops}</b>
                  </Typography>
                </>
              ) : (
                <Typography>No shops available in this area</Typography>
              )
            ) : null}
          </Grid>
        </div>
      )}
    </Grid>
  );
};

export default DashboardMarker;
