import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAmplifyAuth } from 'context';

const WrapWithLayout = ({ Comp, Layout, props }) => {
  let {
    state: { user },
    _updateUserLastSeen
  } = useAmplifyAuth();
  let history = useHistory();

  useEffect(() => {
    if (user?.id || user?.sub) _updateUserLastSeen();
  }, [history.location.pathname, user]);

  return Layout ? (
    <Layout>
      <Comp {...props} />
    </Layout>
  ) : (
    <Comp {...props} />
  );
};

export default WrapWithLayout;
