import React from 'react';
import ActionButtons from './ActionButtons';
import { countries, counties } from 'common/constants';
import {
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  TextField,
  MenuItem
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useAmplifyAuth } from 'context';

function ShopDetails(props) {
  let { shopId } = useParams();
  const {
    validation,
    className,
    google,
    value,
    index,
    shopId: _shopId,
    pageType,
    selectedShop,
    formErrors,
    onCancel,
    handleSubmit,
    handleChange,
    handlePostCodeOnBlur,
    disabled,
    fields,
    disablePostcode = true,
    isSelfOnboarding = false,
    ...rest
  } = props;
  if (_shopId) {
    shopId = _shopId;
  }
  const {
    state: { isAdmin = false, isSales = false }
  } = useAmplifyAuth();

  // helper for handling pasting of phone numbers
  const handlePhoneNumberPaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    handleChange({
      target: { name: 'phoneNumber', value: pastedData.replace(/\D+/g, '') }
    });
  };

  return (
    <>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={12}>
          <CardHeader
            title="Shop Details"
            subheader={
              <span style={{ color: '#444' }}>
                Shop Name and Contact Details
              </span>
            }
          />
        </Grid>
      </Grid>

      <CardContent>
        <Grid container spacing={3}>
          {fields
            .filter(({ property }) =>
              selectedShop?.variant === 'online'
                ? !['address', 'postcode'].includes(property)
                : true
            )
            .map(({ helper, label, property }, i) => (
              <Grid
                item
                key={`shop-details-form-field-${i}`}
                md={property === 'name' ? 12 : property === 'address' ? 12 : 6}
                xs={12}>
                <TextField
                  select={
                    property === 'town'
                      ? true
                      : property === 'country'
                      ? true
                      : false
                  }
                  error={
                    !(selectedShop && selectedShop[property]) ||
                    !!formErrors[property]
                  }
                  fullWidth
                  onBlur={property === 'postcode' ? handlePostCodeOnBlur : null}
                  // onPaste={
                  //   property === 'phoneNumber' ? handlePhoneNumberPaste : null
                  // }
                  helperText={helper || formErrors[property]}
                  label={label}
                  margin="dense"
                  name={property}
                  onChange={handleChange}
                  inputProps={
                    property === 'phoneNumber' ? { maxLength: 13 } : {}
                  }
                  required
                  value={
                    selectedShop && selectedShop[property]
                      ? selectedShop[property]
                      : ''
                  }
                  disabled={
                    isSelfOnboarding
                      ? false
                      : isAdmin
                      ? false
                      : property === 'name'
                      ? !(isAdmin || isSales)
                      : property === 'address'
                      ? selectedShop?.variant === 'online'
                      : property === 'postcode'
                      ? !!(
                          disablePostcode || selectedShop?.variant === 'online'
                        )
                      : false
                  }
                  variant="outlined">
                  {property === 'town'
                    ? counties.map(({ value }, index) => (
                        <MenuItem
                          key={`counties-list-items-${index}`}
                          value={value}>
                          {value}
                        </MenuItem>
                      ))
                    : property === 'country'
                    ? countries.map((item, index) => (
                        <MenuItem
                          key={`countries-list-item-${index}`}
                          value={`${item.value}`}>
                          {item.value}
                        </MenuItem>
                      ))
                    : null}
                </TextField>
              </Grid>
            ))}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Shop Website Link"
              margin="dense"
              error={!!formErrors['websiteLink']}
              helperText={formErrors['websiteLink'] || ''}
              name="websiteLink"
              onChange={handleChange}
              value={
                selectedShop && selectedShop['websiteLink']
                  ? selectedShop['websiteLink']
                  : ''
              }
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
      {!isAdmin && !isSales && !isSelfOnboarding ? (
        <CardActions>
          <ActionButtons
            disabled={disabled}
            handleSubmit={handleSubmit}
            handleCancel={onCancel}
          />
        </CardActions>
      ) : null}
    </>
  );
}

export default ShopDetails;
