import React from 'react';
import { ListItem, ListItemIcon, ListItemText, Paper } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import {
  Card,
  CardContent,
  Typography,
  Container,
  CardActions
} from '@material-ui/core';
import Map from '../../Shops/components/ShopDetails/map';
import ActionButtons from './ActionButtons';

const AddressOnGoogle = (props) => {
  // let { actionType, shopId } = useParams();
  const {
    validation,
    className,
    google,
    value,
    index,
    shopId: _shopId,
    pageType,
    formErrors,
    onCancel,
    handleSubmit,
    handleChange,
    disabled,
    handleNumberChange,
    enablePriceText,
    setEnablePriceText,
    selectedShop,
    setDisabled,
    setSelectedShop,
    ...rest
  } = props;

  const updateActions = () => setDisabled(false);

  return (
    <Paper style={{ padding: '0.5rem' }}>
      <Container style={{ padding: '0.5rem' }}>
        <Typography variant="h5">Find my shop on Google Maps</Typography>
        <Typography>Shop Location on Google Maps</Typography>
      </Container>
      <Container style={{ padding: '0.5rem', height: '40vh' }}>
        <Map
          state={selectedShop}
          update={setSelectedShop}
          updateActions={updateActions}
        />
        <Typography variant="body2">
          click on a place to update shop location
        </Typography>
      </Container>
      <Card
        style={{
          width: '100%',
          boxShadow: '0px 2px 4px 0px #00000040'
        }}>
        <CardContent>
          <ListItem>
            <Typography variant="h5">{selectedShop.name}</Typography>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <RoomIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={`${selectedShop.address}`}
              secondary={`(${selectedShop?.location?.lat || ''}, ${
                selectedShop?.location?.lng || ''
              })`}
            />
          </ListItem>
        </CardContent>
        <CardActions>
          <ActionButtons
            disabled={disabled}
            handleSubmit={handleSubmit}
            handleCancel={onCancel}
          />
        </CardActions>
      </Card>
    </Paper>
  );
};

export default AddressOnGoogle;
