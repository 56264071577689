import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  Grid,
  TextField,
  MenuItem,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  reportContainer: {
    padding: '1rem 1rem 1.5rem',
    rowGap: '1rem',
    borderBottom: '1px solid #ccc'
  },
  reportHeading: {
    fontSize: 18,
    fontWeight: 600,
    color: '#143a69',
    textAlign: 'center',
    textWrap: 'balance',
    lineHeight: '1.5rem'
  },
  reportDesc: {
    fontSize: 13,
    textAlign: 'center',
    textWrap: 'balance',
    lineHeight: '1.2rem',
    fontWeight: 600,
    color: '#444'
  },
  reportBtn: {
    fontSize: 14,
    color: '#fff',
    fontWeight: 500,
    letterSpacing: '0.1rem',
    backgroundColor: '#ff7c2b',
    '&:hover': {
      backgroundColor: '#ff9f5e'
    }
  },
  dialogTitle: {
    padding: '16px 24px 0'
  },
  dialogContent: {
    padding: '0 24px 8px'
  }
}));

const INITIAL_REPORT_ORDER = {
  isReport: false,
  reportReason: ''
};

const OrderCancelDialog = ({
  open,
  handleClose,
  handleCancelConfirm = (reason = '') => {},
  handleReportSubmit = (message = '', messageIntent = '') => {},
  isDeliveryIssue = false
}) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [otherValue, setOtherValue] = useState('');
  const [reportOrder, setReportOrder] = useState(INITIAL_REPORT_ORDER);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const otherDeclineReasonHandler = (event) => {
    setOtherValue(event.target.value);
  };

  const reportOrderHandler = (event) => {
    setReportOrder({
      ...reportOrder,
      reportReason: event.target.value
    });
  };

  const onCloseHandler = () => {
    setValue('');
    setOtherValue('');
    handleClose();
    setReportOrder(INITIAL_REPORT_ORDER);
  };

  const handleSubmit = () => {
    if (reportOrder.isReport) {
      handleReportSubmit(
        `Cancellation Notice: ${reportOrder.reportReason}`,
        'orderCancelIntent'
      );
      setReportOrder(INITIAL_REPORT_ORDER);
    } else if (isDeliveryIssue) {
      handleReportSubmit(
        `Delivery Inquiry: "${reportOrder.reportReason}"`,
        'orderDeliveryIssue'
      );
      setReportOrder(INITIAL_REPORT_ORDER);
    } else {
      const reason = value === 'other' ? otherValue : value;
      handleCancelConfirm(reason);
      setValue('');
      setOtherValue('');
    }
    handleClose();
  };

  const dialogActionText =
    reportOrder.isReport || isDeliveryIssue
      ? 'Submit Your Concern, Our Team is Ready to Assist You'
      : 'Are you sure you still want to cancel this order?';

  const disabledSubmit =
    isDeliveryIssue || reportOrder.isReport
      ? !reportOrder.reportReason
      : value === '' || (value === 'other' && otherValue === '');

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={onCloseHandler}
      aria-labelledby="form-dialog-title">
      {!isDeliveryIssue ? (
        <Grid container justify="center" className={classes.reportContainer}>
          <Typography variant="h6" className={classes.reportHeading}>
            Encountering an issue? Don't cancel — let us know!
          </Typography>
          <Typography className={classes.reportDesc}>
            Our dedicated support team is here to turn challenges into solutions
            and ensure your satisfaction with every order.
          </Typography>
          {!reportOrder.isReport ? (
            <Button
              variant="outlined"
              fullWidth
              className={classes.reportBtn}
              onClick={() =>
                setReportOrder({ ...reportOrder, isReport: true })
              }>
              Report an issue
            </Button>
          ) : null}
        </Grid>
      ) : null}

      <DialogTitle
        id="form-dialog-title"
        classes={{ root: classes.dialogTitle }}>
        {dialogActionText}
      </DialogTitle>
      <DialogContent
        style={{ height: 'fit-content', overflow: 'hidden' }}
        classes={{ root: classes.dialogContent }}>
        {reportOrder.isReport || isDeliveryIssue ? (
          <TextField
            style={{ margin: '1rem 0' }}
            fullWidth
            id="other-reasons-text"
            label="Write your issue"
            multiline
            rows={3}
            variant="outlined"
            onChange={reportOrderHandler}
          />
        ) : (
          <Grid container>
            <Grid
              item
              xs={12}
              style={{ marginBottom: '0.5rem', marginTop: '1.5rem' }}>
              <TextField
                select
                variant="outlined"
                size="small"
                fullWidth
                value={value}
                required
                error={value === ''}
                onChange={handleChange}
                label="Select Reason">
                <MenuItem value={'Product is not required anymore.'}>
                  Service is not required anymore.
                </MenuItem>
                <MenuItem
                  value={'Cheaper alternative available for lesser price.'}>
                  Cheaper alternative available for lesser price.
                </MenuItem>
                <MenuItem
                  value={
                    'Bad review from friends/relatives after ordering the product.'
                  }>
                  Bad review from friends/relatives after ordering the product.
                </MenuItem>
                <MenuItem value={'other'}>Other reasons</MenuItem>
              </TextField>
            </Grid>

            {value === 'other' && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="other-reasons-text"
                  label="Other Reasons"
                  multiline
                  rows={3}
                  variant="outlined"
                  onChange={otherDeclineReasonHandler}
                />
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseHandler} variant="contained" autoFocus>
          Close
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={disabledSubmit}
          variant="contained"
          color="primary"
          autoFocus>
          {reportOrder.isReport || isDeliveryIssue ? 'Submit' : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderCancelDialog;
