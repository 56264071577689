export const TAB_ORDER_TABLE_STATUS = {
  1: ['newOrder', 'pending'],
  2: ['accepted'],
  3: ['itemized'],
  4: ['inProgress'],
  5: ['readyForDelivery'],
  6: ['outForDelivery', 'delivered']
};

export const TAB_ORDER_TABLE_HEADER = {
  1: 'New Orders',
  2: 'Orders awaiting Item & Pricing',
  3: 'Orders awaiting Payment or to be sent for Processing',
  4: 'Orders Processing',
  5: 'Orders awaiting Delivery',
  6: 'Completed Orders'
};

export const TABS = [
  {
    name: 'new',
    label: 'New Orders',
    orderStatuses: ['newOrder', 'pending'],
    stages: [1],
    route: '/service-provider/orders/new'
  },
  {
    name: 'item-pricing',
    label: 'Item & Pricing',
    orderStatuses: ['accepted'],
    stages: [2],
    route: '/service-provider/orders/item-pricing'
  },
  {
    name: 'processing',
    label: 'Processing',
    orderStatuses: ['itemized', 'inProgress'],
    stages: [3, 4],
    route: '/service-provider/orders/processing'
  },
  {
    name: 'delivery',
    label: 'Delivery',
    orderStatuses: ['readyForDelivery', 'outForDelivery', 'delivered'],
    stages: [5, 6],
    route: '/service-provider/orders/delivery'
  }
];

export const DIALOG_NAME_FOR_URL = {
  details: 'details',
  accepted: 'accept',
  declined: 'decline',
  itemized: 'review-item-&-pricing',
  readyForDelivery: 'review-delivery-details',
  showMessages: 'messages',
  delivered: 'confirmation'
};
