import { customMessageNotification } from './messageQueries';

export const onCreateMessageNotification = /* GraphQL */ `
  subscription OnCreateMessageNotification(
    $userID: String
    $shopID: String
    $orderID: String
  ) {
    onCreateMessageNotification(
      userID: $userID
      shopID: $shopID
      orderID: $orderID
    ) ${customMessageNotification}
  }
`;
