import React from 'react';
import Radio from '@material-ui/core/Radio';
import checkedIcon from 'assets/svg/checked.svg';
import uncheckedIcon from 'assets/svg/unchecked.svg';

const StyledRadio = (props) => (
  <Radio
    icon={<img src={uncheckedIcon} alt="unchecked" />}
    checkedIcon={<img src={checkedIcon} alt="checked" />}
    {...props}
  />
);

export default StyledRadio;
