import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import SendIcon from '@material-ui/icons/Send';
import {
  CircularProgress,
  Grid,
  IconButton,
  TextField
} from '@material-ui/core';
import { useLoader } from 'layouts/loaderContext';
import { graphqlOperation, API, Storage } from 'aws-amplify';
import { getFileUploadKey } from 'common/utilFunctions';
import { useAmplifyAuth } from 'context';
import { createMessage } from 'context/messages/messageMutations';

const useStyles = makeStyles({
  sendMessageInput: {
    padding: '0.3rem'
  },
  circularProgress: {
    margin: '0.5rem 0 0 1rem'
  },
  InputActions: {
    margin: '1rem auto 0'
  }
});

function ChatImageSendDialog(props) {
  const classes = useStyles();
  const { loading, setLoading } = useLoader();
  const [messageTerm, setMessageTerm] = useState({});
  const {
    onClose,
    open,
    previewImage,
    fileImage,
    orderActive,
    addNewMessage
  } = props;
  const {
    state: { user }
  } = useAmplifyAuth();

  const newUserMessageHandler = (e) => {
    setMessageTerm({
      id: Date.now(),
      text: e.target.value
    });
  };
  const onSendHandler = async (e) => {
    if (e.key !== 'Enter' && e.type !== 'click') return;
    if (!previewImage) {
      return;
    }
    setLoading(true);
    if (previewImage) {
      try {
        const { type: mimeType } = fileImage;
        const key = getFileUploadKey(fileImage, 'images/messages');
        const thumbURL = key.split('/');
        thumbURL.splice(2, 0, 'thumbnails');

        await Storage.put(key, fileImage, {
          contentType: mimeType,
          level: 'public'
        });

        const data = await API.graphql(
          graphqlOperation(createMessage, {
            input: {
              sender: user.id,
              orderID: orderActive.id,
              photo: {
                photoURL: key,
                thumbURL: thumbURL.join('/'),
                MIMETypes: mimeType
              },
              message: messageTerm.text || '',
              status: 'sent'
            }
          })
        );
        addNewMessage(data.data.createMessage);
      } catch (error) {
        console.error(error);
      } finally {
        onClose();
      }
    }
    setLoading(false);
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}>
      <Grid
        container
        item
        direction="column"
        justify="center"
        alignItems="center"
        style={{
          padding: '1rem'
        }}>
        <Grid item xs={12}>
          <img src={previewImage} height="200px" />
        </Grid>

        <Grid
          container
          item
          justify="space-between"
          className={classes.InputActions}>
          <Grid item xs={11}>
            <TextField
              fullWidth
              placeholder="Write a caption..."
              className={classes.sendMessageInput}
              onChange={newUserMessageHandler}
              onKeyDown={onSendHandler}
              value={messageTerm.text || ''}
            />
          </Grid>

          <Grid item xs={1}>
            {loading ? (
              <CircularProgress
                className={classes.circularProgress}
                size="2rem"
              />
            ) : (
              <IconButton onClick={onSendHandler}>
                <SendIcon className={classes.sendMessageIcon} />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default ChatImageSendDialog;
