import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Button,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { useNotifications } from 'context/notifications/notificationsContext';

const useStyles = makeStyles((theme) => ({
  menu: {
    marginTop: '2rem',
    maxWidth: '16rem',
    minWidth: '15.5rem',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '18.75rem',
      minWidth: '18rem'
    }
  },
  markAllBtn: {
    padding: '0 1rem'
  },
  notificationMessage: {
    fontSize: 13,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11
    }
  },
  notificationContent: {
    '& > p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '& #orderID': {
      color: theme.palette.primary.main,
      border: `0.5px solid ${theme.palette.primary.light}`,
      padding: '0.125rem 0.25rem',
      borderRadius: '0.125rem'
    }
  }
}));
const NotificationsMenu = ({ notificationsMenuEl, handleClose }) => {
  const classes = useStyles();
  const { notifications, dispatch } = useNotifications();

  const notificationReadHandler = (id) => {
    if (id) {
      dispatch({
        type: 'markNotificationsAsRead',
        payload: { notificationId: id }
      });
    } else {
      dispatch({
        type: 'markNotificationsAsRead',
        payload: {}
      });
    }
    handleClose();
  };

  return (
    <Menu
      id="notifications-menu"
      classes={{ paper: classes.menu }}
      anchorEl={notificationsMenuEl}
      open={!!notificationsMenuEl}
      onClose={handleClose}>
      <Grid className={classes.markAllBtn}>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => notificationReadHandler()}>
          Mark all as read
        </Button>
      </Grid>
      {notifications.length > 0
        ? notifications.map((notification, index) => {
            return (
              <MenuItem
                key={`notification-${index}`}
                component={RouterLink}
                to="/service-provider/auctions/ongoing"
                onClick={() => notificationReadHandler(notification.id)}>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography className={classes.notificationMessage}>
                      {notification.message}
                    </Typography>
                  }
                  secondary={
                    <Grid className={classes.notificationContent}>
                      <Typography variant="caption">
                        {moment(notification.createdAt).fromNow()} &nbsp; &nbsp;
                      </Typography>
                    </Grid>
                  }
                />
              </MenuItem>
            );
          })
        : null}
    </Menu>
  );
};

export default NotificationsMenu;
