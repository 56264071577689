import React, { useState, useCallback, useEffect } from 'react';
import ActionButtons from './ActionButtons';
import { UploadedFilesListItem } from 'components/organisms';
import { makeStyles } from '@material-ui/core/styles';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import { useServices } from 'views/Services/serviceContext';
import { Storage } from 'aws-amplify';
import {
  Grid,
  Button,
  Typography,
  Paper,
  TextField,
  MenuItem
} from '@material-ui/core';
import { useShops } from 'context/shop/shopsContext';
import { getFileUploadKey } from 'common/utilFunctions';
import { useLoader } from 'layouts/loaderContext';
import { validateImageAspectRatio } from 'common/validators';

const useStyles = makeStyles(() => ({
  root: {},
  dragDropBox: {
    minHeight: '15rem',
    width: '100%',
    border: '2px dashed #C4C4C4',
    borderRadius: '0.4rem'
  }
}));

function ShopPhotos({
  hideUploader,
  selectedShop,
  setSelectedShop,
  setDisabled = () => {}
}) {
  const classes = useStyles();
  const { dispatch } = useShops();
  const { setLoading } = useLoader();
  const { services } = useServices();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setDisabled(!selectedFiles.length);
  }, [selectedFiles]);

  const handleFileValidation = (file) => (res) => {
    if (res) {
      setSelectedFiles((prevFiles) => [
        ...prevFiles,
        {
          file,
          name: '',
          description: '',
          serviceID: '',
          showServiceDropdown: file.type.includes('image')
        }
      ]);
    } else {
      enqueueSnackbar(`The uploaded image is not matching the desired ratio.`, {
        variant: 'error',
        autoHideDuration: 2000,
        persist: true
      });
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles?.forEach((file) => {
        validateImageAspectRatio(file, 16 / 9, handleFileValidation(file));
      });
    },
    [selectedFiles]
  );

  const onDropRejected = useCallback(
    (rejectedFiles) =>
      rejectedFiles.length > 1 &&
      enqueueSnackbar('select only one file at a time', {
        variant: 'error',
        autoHideDuration: 1500,
        persist: true
      }),
    []
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    onDropRejected,
    noClick: true,
    noKeyboard: true,
    accept: ['image/*', 'video/*']
  });

  const onFileClose = (id) => {
    const result = selectedFiles.filter((item, index) => index !== id);
    setSelectedFiles(result);
  };

  const onCancel = () => setSelectedFiles([]);

  const handleChange = (id) => (e) =>
    setSelectedFiles(
      selectedFiles.map((file, index) =>
        index === id ? { ...file, [e.target.name]: e.target.value } : file
      )
    );

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFiles.length || selectedFiles.some((item) => !item.name)) {
      return enqueueSnackbar('All fields are mandatory', {
        variant: 'warning',
        preventDuplicate: true,
        persist: true
      });
    }
    setLoading(true);
    const photoUrls = [];
    const videoUrls = [];

    const uploadedFiles = selectedFiles.map(
      async ({ file, name, serviceID, description }) => {
        const { type: mimeType } = file;
        let key = '';
        if (mimeType.includes('image')) {
          key = getFileUploadKey(file, 'images');
          const thumbURL = key.split('/');
          thumbURL.splice(1, 0, 'thumbnails');

          return Storage.put(key, file, {
            contentType: mimeType,
            level: 'public'
          })
            .then(() => ({
              name,
              serviceID,
              photoURL: key,
              MIMETypes: mimeType,
              thumbURL: thumbURL.join('/')
            }))
            .catch((error) => {
              console.log('error', error);
              return null;
            });
        } else {
          key = getFileUploadKey(file, 'videos');
          return Storage.put(key, file, {
            contentType: mimeType,
            level: 'public'
          })
            .then(() => ({
              name,
              description,
              videoURL: key,
              autoPlay: true,
              MIMETypes: mimeType
            }))
            .catch((error) => {
              console.log('error', error);
              return null;
            });
        }
      }
    );

    try {
      const results = await Promise.allSettled(uploadedFiles);
      results.forEach((item) => {
        if (item.status === 'fulfilled') {
          if (item.value.MIMETypes.includes('image')) {
            photoUrls.push(item.value);
          } else {
            videoUrls.push(item.value);
          }
        }
      });
      let input = {
        id: selectedShop.id,
        _version: selectedShop._version,
        photos: [...(selectedShop?.photos || []), ...photoUrls],
        videos: [...(selectedShop?.videos || []), ...videoUrls]
      };
      dispatch({
        type: 'updateShopInDB',
        payload: {
          input,
          successMessage: 'Photos & Videos updated successfully',
          successCallback: (data) => {
            setDisabled(true);
            hideUploader();
            setSelectedShop(data);
          }
        }
      });
    } catch (e) {
      console.log(e);
      enqueueSnackbar('Failed', {
        variant: 'warning',
        preventDuplicate: true,
        persist: true
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper style={{ boxShadow: 'none' }}>
      <Grid container justify="space-evenly" style={{ marginBottom: '2rem' }}>
        <Grid container item xs={12} justify="center" alignItems="center">
          <Grid container item justify="center" {...getRootProps()} xs={12}>
            <input {...getInputProps()} />
            <Grid
              container
              item
              direction="column"
              justify="center"
              alignItems="center"
              className={classes.dragDropBox}
              xs={12}>
              {isDragActive ? (
                <Typography variant="h6">Drop the files here...</Typography>
              ) : (
                <>
                  <CloudUploadOutlinedIcon fontSize="large" />
                  <Typography variant="h6" style={{ marginTop: '1rem' }}>
                    Drag & Drop Files
                  </Typography>
                  <br />
                  <br />
                  <Button color="primary" onClick={open}>
                    Choose Files
                  </Button>
                  <Typography align="center">
                    Preferred image/video ratio
                    <strong> 16 x 9</strong>
                  </Typography>
                </>
              )}
            </Grid>
            <Grid container item justify="flex-start">
              <Typography
                variant="body1"
                style={{ color: ' #C4C4C4', padding: '0.5rem' }}>
                Accepted File Types: JPG, PNG and MP4
              </Typography>
            </Grid>
          </Grid>
          {selectedFiles && selectedFiles.length > 0 && (
            <Grid
              container
              item
              justify="center"
              xs={12}
              style={{ gap: '1rem' }}>
              {selectedFiles.map((item, index) => (
                <Grid container key={`shop-photos-${index}`}>
                  <UploadedFilesListItem
                    fileName={item.file?.name || ''}
                    onClose={() => onFileClose(index)}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        value={item.name || ''}
                        onChange={handleChange(index)}
                        fullWidth
                        label="File name"
                        margin="dense"
                        name="name"
                        variant="outlined"
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      {item.showServiceDropdown ? (
                        <TextField
                          select
                          name="serviceID"
                          variant="outlined"
                          margin="dense"
                          label="Select Service"
                          value={item.serviceID || ''}
                          onChange={handleChange(index)}
                          helperText="select service for which you want to show this banner"
                          InputLabelProps={{ shrink: true }}
                          fullWidth>
                          {services.map((service, index) => (
                            <MenuItem
                              value={service?.id || ''}
                              key={`coupons-form-services-items-${index}`}>
                              {service?.name || ''}
                            </MenuItem>
                          ))}
                        </TextField>
                      ) : (
                        <TextField
                          value={item.description || ''}
                          onChange={handleChange(index)}
                          fullWidth
                          label="Description"
                          margin="dense"
                          name="description"
                          variant="outlined"
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
      <ActionButtons
        disabled={!selectedFiles.length}
        handleSubmit={onSubmit}
        handleCancel={onCancel}
      />
    </Paper>
  );
}

export default ShopPhotos;
