import React, { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useShops } from 'context/shop/shopsContext';
import { StyledRating } from 'components/molecules';
import { Image } from 'components/organisms';
import ShopReviewDescription from 'views/ShopDetail/components/ShopReviewDescription';
import defaultUserImage from 'assets/png/default-image-user.png';
import ShopAdminReply from './ShopAdminReply';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  userImg: {
    height: 40,
    width: 40,
    borderRadius: '50%',
    marginRight: '0.625rem',
    [theme.breakpoints.up('md')]: {
      marginRight: 0,
      height: 60,
      width: 60
    }
  }
}));

const ShopSingleReview = ({ ratings, item, isShopAdminView = false }) => {
  const classes = useStyles();
  const md = useMediaQuery(useTheme().breakpoints.up('md'));
  const { selectedShop, dispatch } = useShops();
  const [shopReply, setShopReply] = useState('');
  const [isEditReply, setIsEditReply] = useState(false);

  useEffect(() => {
    if (item.replyID && isEditReply)
      setShopReply(item.reply?.description || '');
  }, [isEditReply]);

  const replyUpdateHandler = (e) => {
    setShopReply(e.target.value);
  };

  const shopReplySubmitHandler = () => {
    if (selectedShop?.id) {
      const replyObj = {
        shopID: selectedShop.id,
        reviewID: item.id,
        description: shopReply.trim()
      };

      if (item.replyID) {
        replyObj._version = item.reply._version;
        replyObj.id = item.reply.id;
        delete replyObj.shopID;

        dispatch({
          type: 'updateShopReviewReply',
          payload: {
            data: { input: { ...replyObj } },
            selectedShop: selectedShop
          }
        });
      } else {
        dispatch({
          type: 'addShopReviewReply',
          payload: { data: replyObj, selectedShop: selectedShop }
        });
      }
      setIsEditReply(false);
    }
  };

  return (
    <Grid
      container
      justify="space-between"
      style={{
        padding: '1.7rem 0 1.25rem',
        borderBottom: '1px solid #C4C4C4'
      }}>
      {md ? (
        <Grid item xs={1}>
          <Image
            className={classes.userImg}
            onError={(e) => (e.target.src = defaultUserImage)}
            docKey={item.user?.picture}
            alt="User profile photo"
          />
        </Grid>
      ) : null}
      <Grid
        item
        xs={11}
        container
        direction="column"
        style={{ paddingLeft: md ? '1.25rem' : 0 }}>
        <Grid container justify="space-between" alignItems="center">
          <Grid container item xs={12} md={6} alignItems="center">
            {!md ? (
              <Image
                className={classes.userImg}
                docKey={item.user?.picture}
                onError={(e) => (e.target.src = defaultUserImage)}
                alt="User profile photo"
              />
            ) : null}
            <Typography variant="h4">
              {item.user?.username
                ? item.user.firstName
                  ? `${item.user.firstName} ${item.user.lastName}`
                  : item.user.username
                : '' || ''}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            container
            justify={md ? 'flex-end' : 'flex-start'}>
            <Typography variant="subtitle2">
              Reviewed on {moment(item.createdAt).format('MMMM Do YYYY')}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item alignItems="center">
          <StyledRating
            name="read-only-customer-rating"
            value={ratings}
            readOnly
            style={{ margin: '0.5rem 0 0.75rem' }}
          />
          <Divider
            orientation="vertical"
            flexItem
            style={{ margin: '0.5rem' }}
          />
          <Typography variant="body1">{ratings} star</Typography>
        </Grid>

        <Typography variant="h4">{item.title || ''}</Typography>

        <ShopReviewDescription description={item.description || ''} />

        {!!(item.replyID && !!item.reply?.description && !isEditReply) ? (
          <ShopAdminReply
            shopResponse={item}
            selectedShop={selectedShop}
            setIsEditReply={setIsEditReply}
            isShopAdminView={isShopAdminView}
          />
        ) : isShopAdminView ? (
          isEditReply ? (
            <Grid container>
              <TextField
                variant="outlined"
                placeholder="Write your response"
                label="Shop Response"
                name="response"
                value={shopReply}
                onChange={replyUpdateHandler}
                multiline
                inputProps={{ maxLength: 1500 }}
                rows={4}
                fullWidth
                style={{ margin: '0.625rem 0' }}
              />
              <Grid container justify="flex-end" style={{ gap: '2rem' }}>
                <Button
                  variant="contained"
                  size="small"
                  style={{ backgroundColor: 'orange' }}
                  onClick={() => setIsEditReply(false)}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={shopReplySubmitHandler}
                  disabled={isEditReply ? false : !shopReply}>
                  Send
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container justify="flex-end">
              <Button
                color="primary"
                size="small"
                onClick={() => setIsEditReply(true)}>
                Reply
              </Button>
            </Grid>
          )
        ) : null}
      </Grid>
    </Grid>
  );
};

export default ShopSingleReview;
