import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Container,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ActionButtons from 'views/LaundryShop/components/ActionButtons';
import { useOrders } from 'context/order/ordersContext';
import { showPrice } from 'common/utilFunctions';
import { getPaymentByOrderId } from 'graphql/queries';
import { useLoader } from 'layouts/loaderContext';
import { API, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { createPayment } from 'graphql/mutations';
import { useAmplifyAuth } from 'context';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: 'white',
    padding: '1rem',
    borderRadius: '4px'
  },
  titleText: {
    color: 'black'
  },
  totalText: {
    color: theme.palette.primary.main
  }
}));

const PaymentUpdateDialog = ({ order, open, onClose }) => {
  const [status, setStatus] = useState('');
  const [value, setValue] = useState('');
  const { dispatch } = useOrders();
  const classes = useStyles();
  const { setLoading } = useLoader();
  const [balance, setBalance] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { user }
  } = useAmplifyAuth();

  useEffect(() => {
    setStatus('');
    if (order?.id) {
      setLoading(true);
      API.graphql(
        graphqlOperation(getPaymentByOrderId, {
          orderID: order.id
        })
      )
        .then((data) => {
          const records = data.data.getPaymentByOrderId.items.filter(
            (item) => !!item
          );
          setBalance(
            (order?.total || 0) -
              records.map((item) => item.amount || 0).reduce((p, c) => p + c, 0)
          );
        })
        .catch((e) => console.log('error', e))
        .finally(() => setLoading(false));
    }
  }, [order]);

  const handleSubmit = async () => {
    try {
      if (!status) {
        onClose();
      } else if (status === 'partiallyPaid' && !value) {
        enqueueSnackbar('Enter a valid amount', {
          variant: 'error',
          persist: true
        });
      } else if (value > balance) {
        enqueueSnackbar('Amount cannot be more than remaining balance', {
          variant: 'error',
          persist: true
        });
      } else {
        const input = {
          orderID: order.id,
          shopID: order.shopID,
          userID: order.userID,
          createdBy: user.id
        };
        let newPaymentStatus;
        if (
          status === 'paid' ||
          (status === 'partiallyPaid' && parseFloat(value) === balance)
        ) {
          input.amount = balance;
          newPaymentStatus = 'paid';
        } else {
          input.amount = value;
          newPaymentStatus = 'partiallyPaid';
        }
        setLoading(true);

        await API.graphql(graphqlOperation(createPayment, { input }));
        setValue('');
        const amountPaid =
          newPaymentStatus === 'paid'
            ? order?.total
            : order?.total - balance + parseFloat(value);

        dispatch({
          type: 'updateOrder',
          payload: {
            order: {
              ...order,
              paymentStatus: newPaymentStatus,
              paidAmount: amountPaid
            }
          }
        });
        onClose();
      }
    } catch (error) {
      console.log('error', error);
      enqueueSnackbar('Something went wrong..!', {
        variant: 'error',
        autoHideDuration: 2000
      });
    }
  };

  const valueChangeHandler = (e) => {
    const replaceRegex = /[^0-9.]/g;
    e.target.value = e.target.value.replace(replaceRegex, '');
    setValue(e.target.value);
  };

  return order && order.id ? (
    <Grid container justify="center" alignItems="center">
      <Modal
        aria-describedby="message-modal-description"
        aria-labelledby="message-modal-title"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        closeAfterTransition
        onClose={onClose}
        open={open}>
        <Container maxWidth="sm">
          <Fade in={open}>
            <Grid container justify="center" alignItems="center">
              <Grid container style={{ height: '15rem' }}></Grid>
              <Grid container direction="column" className={classes.content}>
                <Typography
                  variant="h5"
                  className={classes.titleText}
                  style={{ marginBottom: '1rem' }}>
                  Update Payment Status
                </Typography>
                <Typography variant="h6" className={classes.totalText}>
                  Total Price: {showPrice(order?.total || 0)}
                  <br />
                  Balance: {showPrice(balance)}
                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    style={{ marginTop: '1rem' }}
                    aria-label="position"
                    name="position"
                    onChange={(e) => setStatus(e.target.value)}
                    value={status}>
                    <FormControlLabel
                      value="paid"
                      control={<Radio color="primary" />}
                      label="Paid"
                    />
                    <FormControlLabel
                      value="partiallyPaid"
                      control={<Radio color="primary" />}
                      label="Partially Paid"
                    />
                  </RadioGroup>
                </FormControl>
                {status === 'partiallyPaid' && (
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Enter Amount"
                    style={{ margin: '0.5rem 0' }}
                    name="amount"
                    value={value}
                    onChange={(e) => valueChangeHandler(e)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          out of {showPrice(balance)}
                        </InputAdornment>
                      )
                    }}
                  />
                )}
                <ActionButtons
                  handleCancel={onClose}
                  handleSubmit={handleSubmit}
                />
              </Grid>
            </Grid>
          </Fade>
        </Container>
      </Modal>
    </Grid>
  ) : null;
};

export default PaymentUpdateDialog;
