import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { makeStyles } from '@material-ui/core/styles';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import StarIcon from '@material-ui/icons/Star';

const useStyles = makeStyles(() => ({
  root: {
    color: '#EDD242'
  },
  sizeSmall: {
    fontSize: '.6rem'
  },
  ratingCount: {
    marginLeft: '2px',
    verticalAlign: 'middle',
    marginTop: 4
  }
}));

const StyledRating = ({ showRatingCount, ...props }) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" style={{ width: 'fit-content' }}>
      <Rating
        precision={props.readOnly ? 0.1 : 0.5}
        emptyIcon={
          <StarBorderOutlinedIcon
            classes={{ root: classes.root }}
            fontSize={props.size}
          />
        }
        icon={
          <StarIcon classes={{ root: classes.root }} fontSize={props.size} />
        }
        {...props}
        classes={{
          sizeSmall: classes.sizeSmall
        }}
      />
      {showRatingCount ? (
        <Typography className={classes.ratingCount}>
          {props.value || 0}
        </Typography>
      ) : null}
    </Grid>
  );
};

StyledRating.propTypes = {
  name: PropTypes.string,
  showRatingCount: PropTypes.bool,
  value: PropTypes.number,
  max: PropTypes.number,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func
};
StyledRating.defaultProps = {
  showRatingCount: false,
  readOnly: false,
  onChange: () => {},
  name: ''
};

export default StyledRating;
