import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  root: {}
}));

const ChatMessageMenu = ({
  anchor = { x: null, y: null },
  handleClose = () => {},
  handleDelete = () => {}
}) => {
  const classes = useStyles();

  return (
    <Menu
      id="chat-message-menu"
      keepMounted
      open={anchor.x !== null && anchor.y !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        anchor.x !== null && anchor.y !== null
          ? { top: anchor.y, left: anchor.x }
          : undefined
      }>
      <MenuItem onClick={handleDelete}>
        <DeleteIcon size="small" />
        Delete
      </MenuItem>
    </Menu>
  );
};

export default ChatMessageMenu;
