import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link, Grid } from '@material-ui/core';
import FooterLinks from './components/FooterLinks';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.primary.main
  },
  fontColor: {
    color: '#fff',
    fontWeight: 600
  },
  trademark: {
    padding: '0.2rem 1rem',
    backgroundColor: theme.palette.primary.dark
  },
  linksContainer: {
    padding: '0.2rem 1rem'
  }
}));

const Footer = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <footer {...rest} className={clsx(classes.root, className)}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.linksContainer}>
        <FooterLinks />
      </Grid>

      <Typography
        variant="body1"
        className={clsx(classes.fontColor, classes.trademark)}>
        &copy;{' '}
        <Link
          component="a"
          href="/"
          target="_blank"
          className={classes.fontColor}>
          Launder-it.co.uk
        </Link>
        . 2020
      </Typography>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
