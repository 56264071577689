import { API, graphqlOperation } from 'aws-amplify';
import { getItemByService, listServices } from './serviceQueries';
import mutations from './serviceMutations';
import { uploadImage, compressImage } from 'common/utilFunctions';

// callback to format service and it's items
const formatService = (item) => {
  const items =
    item.items.items &&
    item.items.items.filter((item) => item._deleted !== true);
  return { ...item, items: { items } };
};

// callback to sort services
const sortServices = (a, b) => (a.orderIndex || 0) - (b.orderIndex || 0);

/* Service  APIs */
const loadService = async () => {
  try {
    const servicesResp = await API.graphql(graphqlOperation(listServices));
    const servicesPromises = servicesResp.data.listServices.items
      .filter((item) => item._deleted !== true)
      .map(async (service) => {
        if (!!service.items.nextToken) {
          const itemsResp = await API.graphql(
            graphqlOperation(getItemByService, {
              serviceID: service.id,
              limit: 8000
            })
          );
          const allItems = itemsResp.data.getItemByService.items.filter(
            (item) => !item._deleted
          );
          return { ...service, items: { items: allItems } };
        } else return service;
      });
    const servicesPromisesResp = await Promise.allSettled(servicesPromises);
    const allServices = servicesPromisesResp
      .filter((item) => item.status === 'fulfilled')
      .map((item) => item.value);
    const filteredServices = [
      ...allServices
        .filter((_service) => !_service.type || _service.type === 'domestic')
        .map(formatService)
        .sort(sortServices),
      ...allServices
        .filter((_service) => _service.type === 'commercial')
        .map(formatService)
        .sort(sortServices)
    ];
    return filteredServices;
  } catch (error) {
    console.error('something went wrong in getting all services...');
    throw new Error(error);
  }
};

const addNewService = (service) =>
  new Promise((resolve) => {
    const payload = {
      input: service
    };
    API.graphql(
      graphqlOperation(mutations.createService, payload)
    ).then((data) => resolve(data));
  });

const updateNewService = (service) =>
  new Promise((resolve) => {
    const payload = {
      input: service
    };
    API.graphql(
      graphqlOperation(mutations.updateService, payload)
    ).then((data) => resolve(data));
  });

/* Item  APIs */
const addNewItem = (payload) =>
  new Promise((resolve) => {
    API.graphql(
      graphqlOperation(mutations.createAnItem, payload)
    ).then((data) => resolve(data));
  });

const performDelete = ({ type, payload }) =>
  new Promise((resolve) => {
    const { id, _version, moduleType } = payload;
    API.graphql(
      graphqlOperation(mutations[type + moduleType], {
        input: { id, _version }
      })
    ).then((data) => resolve(data));
  });

const performUpdate = async ({ type, payload }, file) => {
  const { _deleted, moduleType, ...inputData } = payload;

  let input = inputData;
  if (file && moduleType == 'Item') {
    const compressedFile = await compressImage(file);
    const imageUrl = await uploadImage(compressedFile);
    if (imageUrl) {
      input.image = imageUrl;
    }
  }

  const data = await API.graphql(
    graphqlOperation(mutations[type + moduleType], { input })
  );
  return data;
};

export {
  loadService,
  addNewService,
  addNewItem,
  performDelete,
  performUpdate,
  updateNewService
};
