import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import {
  CircularProgress,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { useAmplifyAuth } from 'context';
import { useLoader } from 'layouts/loaderContext';
import { PaymentCardDetails } from 'components/organisms';
import { DocumentDeleteDialog } from 'components/organisms';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '20px 0'
  },
  title: {
    fontWeight: 600,
    margin: '0.5rem 0 1rem',
    color: theme.palette.primary.main
  },
  cardItem: {
    padding: '0.5rem'
  },
  deleteIcon: {
    color: theme.palette.error.dark
  }
}));

const SavedCardDetails = () => {
  const {
    state: { user }
  } = useAmplifyAuth();
  const classes = useStyles();
  const { loading, setLoading } = useLoader();
  const [savedCards, setSavedCards] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currCard, setCurrCard] = useState(null);

  useEffect(() => {
    let mounted = true;
    if (!user?.stripeID) return;

    const getMounted = () => mounted;

    setLoading(true);
    API.get('laundryapi', '/payment-methods', {
      queryStringParameters: {
        customerID: user.stripeID
      }
    })
      .then((resp) => getMounted() && setSavedCards(resp.data))
      .catch((error) => console.error('error ', error))
      .finally(() => setLoading(false));

    return () => {
      mounted = false;
    };
  }, [user]);

  const handleDelete = (paymentMethodID = '') => {
    API.put('laundryapi', '/payment-methods', {
      body: { paymentMethodID }
    })
      .then(() =>
        setSavedCards(
          savedCards.filter((_item) => _item.id !== paymentMethodID)
        )
      )
      .catch((error) => console.error('error ', error));
  };

  const handleOpenDeleteModal = (card) => {
    setOpenDeleteModal(true);
    setCurrCard(card);
  };

  const handleCancleDeleteModal = () => {
    setOpenDeleteModal(false);
    setCurrCard(null);
  };

  const handleConfirmDelete = () => {
    handleDelete(currCard.id);
    setOpenDeleteModal(false);
    setCurrCard(null);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" className={classes.title}>
          Saved cards
        </Typography>
      </Grid>

      <Grid container justify="center">
        {loading ? (
          <CircularProgress />
        ) : savedCards.length > 0 ? (
          savedCards.map((_item, index) => (
            <Grid container justify="center" key={`saved-cards-items-${index}`}>
              <Grid
                item
                xs={12}
                lg={8}
                container
                justify="space-between"
                alignItems="center"
                className={classes.cardItem}>
                <Grid item xs={10}>
                  <PaymentCardDetails
                    brand={_item.card.brand}
                    last4={_item.card.last4}
                    exp_month={_item.card.exp_month}
                    exp_year={_item.card.exp_year}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => handleOpenDeleteModal(_item)}
                    title="Delete">
                    <DeleteIcon className={classes.deleteIcon} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ))
        ) : (
          <Typography variant="subtitle2">No cards saved yet.</Typography>
        )}
      </Grid>

      <DocumentDeleteDialog
        open={openDeleteModal}
        handleCancel={handleCancleDeleteModal}
        handleConfirm={handleConfirmDelete}
        confirmText="Delete this Card"
      />
    </Grid>
  );
};

export default SavedCardDetails;
