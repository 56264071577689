import React from 'react';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from '@stripe/react-stripe-js';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import './card_details.css';

const useStyles = makeStyles((theme) => ({
  root: {
    gap: '1rem 0',
    paddingTop: '1rem'
  },
  title: {
    marginBottom: '0.5rem',
    fontWeight: 600,
    color: '#444'
  },
  item: {
    position: 'relative',
    padding: '0.5rem'
  },
  label: {
    fontSize: '0.775rem',
    transform: 'translate(14px, -6px)',
    position: 'absolute',
    top: 0,
    left: 0,
    transformOrigin: 'top left',
    zIndex: '1',
    fontWeight: 600,
    color: '#444'
  }
}));

const CardDetails = () => {
  const classes = useStyles();
  const THEME = useTheme();
  const ELEMENT_OPTIONS = {
    style: {
      base: {
        color: THEME.palette.text.primary,
        fontFamily: THEME.typography.fontFamily,
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: THEME.palette.text.hint
        }
      },
      invalid: {
        color: THEME.palette.error.main,
        iconColor: THEME.palette.error.main
      }
    }
  };

  return (
    <Grid container className={classes.root}>
      {/* Card Number */}
      <Grid item xs={12} className={classes.item}>
        <label htmlFor="cardNumber" className={classes.label}>
          Card Number
        </label>
        <CardNumberElement id="cardNumber" options={ELEMENT_OPTIONS} />
      </Grid>

      {/* Card Expiration */}
      <Grid item xs={12} sm={6} className={classes.item}>
        <label htmlFor="expiry" className={classes.label}>
          Card Expiration
        </label>
        <CardExpiryElement id="expiry" options={ELEMENT_OPTIONS} />
      </Grid>

      {/* Card CVC */}
      <Grid item xs={12} sm={6} className={classes.item}>
        <label htmlFor="cvc" className={classes.label}>
          CVC
        </label>
        <CardCvcElement id="cvc" options={ELEMENT_OPTIONS} />
      </Grid>
    </Grid>
  );
};

export default CardDetails;
