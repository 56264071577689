import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Chip,
  Grid,
  useMediaQuery
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginBottom: 10
  },
  card: {
    borderRadius: 8,
    border: '1px solid #979797',
    borderLeft: '8px solid #194376',
    position: 'relative'
  },
  cardContent: {
    marginTop: '1.2rem',
    marginBottom: '0.5rem'
  },
  orderTypeBadge: {
    position: 'absolute',
    backgroundColor: '#194376',
    color: '#194376',
    margin: '0.5rem 0 0 0',
    padding: '0 0.5rem',
    borderRadius: '0 1rem 1rem 0'
  }
});

const MyOrderSkeleton = () => {
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));

  const ORDER_TEXT_WIDTH = md ? 6 : 12;
  const ORDER_BUTTON_WIDTH = md ? 6 : 12;

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Grid container justify="flex-start">
          <Chip
            size="small"
            label="Standard"
            className={classes.orderTypeBadge}
          />
        </Grid>
        <CardContent className={classes.cardContent}>
          <Grid container justify="space-between">
            <Grid container item xs={ORDER_TEXT_WIDTH} spacing={1}>
              {[...Array(12).keys()].map((item) => {
                return (
                  <Grid item xs={6} key={item}>
                    <Skeleton animation="wave" height={19} width="100%" />
                  </Grid>
                );
              })}
            </Grid>

            <Grid
              container
              item
              xs={ORDER_BUTTON_WIDTH}
              justify={md ? 'flex-end' : 'center'}
              alignItems="center"
              style={{ gap: 1 }}>
              <Grid item xs={6}>
                <Skeleton animation="wave" height={50} width="100%" />
              </Grid>
              <Grid item xs={6}>
                <Skeleton animation="wave" height={50} width="100%" />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default MyOrderSkeleton;
