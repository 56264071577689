export const messageNotificationsReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'updateMessageNotifications': {
      return payload || [];
    }
    case 'addMessageNotifications': {
      return [...payload, ...state];
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};
