import React from 'react';
import { FormControlLabel, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 0,
    marginRight: 0
  },
  radio: {
    padding: 0,
    paddingTop: '0.625rem'
  },
  icon: {
    fontSize: 15,
    color: '#7391BC',
    padding: 0
  },
  checkedIcon: {
    fontSize: 15,
    color: '#007AFF',
    padding: 0
  }
}));

// Radio for Navigation in a Carousel
const CarouselNavRadio = (props) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.root}
      control={
        <Radio
          disableRipple
          size="medium"
          className={classes.radio}
          icon={<FiberManualRecordIcon className={classes.icon} />}
          checkedIcon={
            <FiberManualRecordIcon className={classes.checkedIcon} />
          }
        />
      }
      {...props}
    />
  );
};

export default CarouselNavRadio;
