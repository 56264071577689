import React from 'react';
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import { maxInputDateForDatePicker } from 'common/constants';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  filterCouponType: {
    [theme.breakpoints.down('md')]: {
      marginTop: '1rem'
    }
  }
}));

const CouponFilterTools = ({
  filters = {},
  handleFilterUpdate = () => {},
  handleResetFilters = () => {},
  showResetButton = false
}) => {
  const classes = useStyles();
  const handleDateRangeUpdate = (e) => {
    handleFilterUpdate({
      target: {
        name: e.target.name,
        value: e.target.value
      }
    });
  };

  return (
    <Grid
      container
      item
      justify="space-between"
      alignItems="center"
      style={{ margin: '2rem 1rem 0' }}>
      <Grid
        container
        item
        xs={12}
        md={5}
        justify="flex-end"
        alignItems="center">
        <Grid item xs={5}>
          <TextField
            label="Start Date"
            type="date"
            name="startDate"
            size="small"
            variant="outlined"
            value={filters.startDate}
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              max: maxInputDateForDatePicker.slice(0, 10)
            }}
            onChange={handleDateRangeUpdate}
            fullWidth
          />
        </Grid>
        <Grid item xs={2} container justify="center" alignItems="center">
          <Typography>to</Typography>
        </Grid>
        <Grid item xs={5}>
          <TextField
            label="End Date"
            type="date"
            name="endDate"
            size="small"
            variant="outlined"
            value={filters.endDate}
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              max: maxInputDateForDatePicker.slice(0, 10)
            }}
            onChange={handleDateRangeUpdate}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid item xs={12} md={2} className={classes.filterCouponType}>
        <TextField
          fullWidth
          size="small"
          name="showCurrentCoupons"
          select
          onChange={handleFilterUpdate}
          value={filters.showCurrentCoupons}
          variant="outlined">
          <MenuItem value={true}>Current Coupons</MenuItem>
          <MenuItem value={false}>Expired Coupons</MenuItem>
        </TextField>
      </Grid>

      <Grid container item xs={12} md={2} justify="flex-end">
        {showResetButton ? (
          <Button
            color="secondary"
            variant="contained"
            size="small"
            onClick={handleResetFilters}
            fullWidth>
            reset filters
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default CouponFilterTools;
