export const CUSTOMER_BENEFITS = [
  {
    id: 1,
    content: 'Convenient locations near you'
  },
  {
    id: 2,
    content: 'Doorstep laundry services'
  },
  {
    id: 3,
    content: 'Quick turnaround time'
  },
  {
    id: 4,
    content: 'Competitive prices'
  },
  {
    id: 5,
    content: 'Seamless experience from start to finish'
  },
  {
    id: 6,
    content: 'Tracking system to stay updated on order status'
  },
  {
    id: 7,
    content: 'Control of your schedule'
  },
  {
    id: 8,
    content: 'Top-notch quality cleaning'
  },
  {
    id: 9,
    content: 'Say goodbye to the hassle of carrying heavy laundry bags'
  },

  {
    id: 10,
    content:
      'Enjoy the ease of booking your orders online from anywhere, anytime'
  },

  {
    id: 11,
    content: 'Experience professional cleaning and impeccable results'
  },
  {
    id: 12,
    content: 'Direct communication with shops through messaging feature'
  }
];

export const SHOP_OWNER_BENEFITS = [
  {
    id: 1,
    content:
      'Promote Your Shop Details: Launder-It provides a dedicated space to showcase your shop details. Display your address, contact information, and photos to attract more customers and enhance your online presence'
  },
  {
    id: 2,
    content:
      'Streamline Operations: Simplify your daily tasks and improve efficiency with our state-of-the-art laundry management software'
  },
  {
    id: 3,
    content:
      'Effective Order Management: Managing orders has never been easier! Launder it provides you with efficient order management tools, enabling you to streamline your workflow, track orders, and ensure timely deliveries'
  },
  {
    id: 4,
    content:
      'Messaging Customers: Communication is key, and with Launder it, you can easily connect with your customers through our integrated messaging system. Keep them informed, address their queries, and provide exceptional customer service'
  },
  {
    id: 5,
    content:
      'Order Tracking System: Say goodbye to confusion and uncertainty! Our advanced order tracking system allows you to stay updated on the progress of your customers orders, ensuring smooth operations and customer satisfaction'
  },
  {
    id: 6,
    content:
      'Online Payment Integration: Seamlessly integrate online payment options into your business. Enable secure and convenient transactions, making it easier for customers to complete their orders'
  },
  {
    id: 7,
    content:
      "Promote Your Coupons Effectively: Don't let your amazing coupons go unnoticed! our comprehensive coupon options designed to help you highlight your amazing offers and attract customers to book their orders"
  },
  {
    id: 8,
    content:
      "Staff Management Database: Simplify employee scheduling and optimize your team's productivity with our staff management database"
  },
  {
    id: 9,
    content:
      'Expand Your Network: Connect with other industry professionals, exchange ideas, and learn from the best in the business. Our community forum and networking events provide valuable opportunities for growth and collaboration'
  },
  {
    id: 10,
    content:
      'Increase Your Visibility: Stand out from the competition and attract more customers with our powerful marketing solutions. Let us help you create compelling ads and promotions that reach your target audience'
  },
  {
    id: 11,
    content:
      'Stay Informed: Stay up to date with the latest trends, industry news, and expert tips through our informative blog and newsletters. We believe knowledge is power, and we want to empower you to succeed!'
  },
  {
    id: 12,
    content:
      "Seamless Integration: Easily integrate your existing online presence with Launder-It. Connect your social media profiles and website to maximize your brand's exposure and enhance customer engagement"
  }
];
