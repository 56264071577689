import { Badge } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const StyledBadge = withStyles(() => ({
  badge: {
    backgroundColor: 'white',
    color: 'black',
    fontSize: '0.625rem',
    height: '0.75rem',
    minWidth: '0.625rem',
    padding: '0 0.25rem'
  }
}))(Badge);

export default StyledBadge;
