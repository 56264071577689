import React from 'react';
import { Grid, Link, Typography, useMediaQuery } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import LogoName from '../../components/organisms/LogoName';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  googleFormLinks,
  socialSiteLinks,
  launderItBlogLink
} from 'common/constants';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import WebIcon from '@material-ui/icons/Web';
import HomeSubFooter from './HomeSubFooter';
import FooterServices from './FooterServices';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    backgroundColor: '#194376'
  },
  container: {
    width: '100%',
    padding: '1.5rem 0 1rem 0',
    [theme.breakpoints.up('md')]: {
      width: '90%'
    }
  },
  subFooterContainer: {
    margin: '1.5rem auto 2rem',
    maxWidth: '90%',
    borderTop: '1px solid #c4c4c4',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse'
    }
  },
  socialMediaIcon: {
    padding: 0,
    margin: '0 0.2rem'
  },
  collapse: {
    paddingLeft: '2rem'
  },
  collapseListItem: {
    width: '100%',
    borderBottom: '1px solid rgb(196 196 196/0.1)'
  },
  newsItemBody: {
    color: '#7391BC',
    fontSize: '13px',
    fontWeight: 'bold',
    letterSpacing: '0.015em'
  },
  linkFont: {
    letterSpacing: '0.01em',
    fontWeight: 500,
    cursor: 'pointer',
    padding: '0 0.5rem',
    color: theme.palette.yellow.main || '#EDD242',
    borderRight: '2px solid rgba(196, 196, 196, 0.7)',
    marginRight: '0.5rem'
  },
  iconContainer: {
    width: 30,
    height: 30,
    padding: '1rem',
    backgroundColor: '#fff'
  },
  iconSize: {
    height: 25,
    width: 25
  },
  icon: {
    fontSize: '1.3rem'
  },

  followUscontainer: {
    width: '85%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '5.5rem'
    }
  },
  followUsText: {
    letterSpacing: '0.05em',
    color: '#fff',
    fontSize: '15px',
    marginBottom: '0.5rem'
  }
}));

export default function Footer({ showServiceDetails = true }) {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.container}>
        <Grid
          item
          xs={12}
          md={5}
          container
          alignItems="flex-end"
          justify={md ? 'flex-start' : 'center'}
          style={{ marginBottom: md ? 0 : '1rem', gap: '0.5rem' }}>
          <LogoName light size="large" />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          container
          alignItems="center"
          justify={md ? 'flex-end' : 'center'}
          style={{ marginTop: md ? 0 : '1rem' }}>
          {/* <Link
            href={googleFormLinks.joinUs}
            target="_blank"
            rel="noreferrer"
            className={classes.linkFont}>
            Join Us
          </Link> */}
          <Link
            title="Facebook"
            href={socialSiteLinks.facebook}
            target="_blank"
            rel="noreferrer"
            className={classes.socialMediaIcon}>
            <Avatar
              className={classes.iconContainer}
              style={{ backgroundColor: '#4267B2' }}>
              <FacebookIcon className={classes.icon} />
            </Avatar>
          </Link>
          {/* <Link
            // href="#"
            // target="_blank"
            rel="noreferrer"
            className={classes.socialMediaIcon}>
            <img src={TwiterIcon} alt="TwiterIcon" />
          </Link> */}
          <Link
            title="Instagram"
            href={socialSiteLinks.instagram}
            target="_blank"
            rel="noreferrer"
            className={classes.socialMediaIcon}>
            <Avatar
              className={classes.iconContainer}
              style={{ backgroundColor: '#E1306C' }}>
              <InstagramIcon className={classes.icon} />
            </Avatar>
          </Link>
          <Link
            title="YouTube"
            href={socialSiteLinks.youtube}
            target="_blank"
            rel="noreferrer"
            className={classes.socialMediaIcon}>
            <Avatar
              className={classes.iconContainer}
              style={{ backgroundColor: '#ff0000' }}>
              <YouTubeIcon className={classes.icon} />
            </Avatar>
          </Link>
          {/* <Link
            title="Blogs"
            href={launderItBlogLink}
            target="_blank"
            rel="noreferrer"
            className={classes.socialMediaIcon}>
            <Avatar
              className={classes.iconContainer}
              style={{ backgroundColor: '#f95c1e' }}>
              <WebIcon className={classes.icon} />
            </Avatar>
          </Link> */}
        </Grid>
      </Grid>
      {/* <Grid
        container
        justify={md ? 'flex-end' : 'center'}
        alignItems="center"
        className={classes.followUscontainer}>
        <Typography variant="h5" className={classes.followUsText}>
          Follow Us
        </Typography>
      </Grid> */}

      {showServiceDetails ? (
        <Grid
          container
          item
          className={classes.subFooterContainer}
          xs={12}
          justify="space-between">
          <Grid item md={3}>
            <HomeSubFooter />
          </Grid>
          <Grid item md={9}>
            <FooterServices />
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
}
