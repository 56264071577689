import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import PaymentOptions from 'views/Review/components/PaymentOptions';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  contentText: {
    color: '#444 !important',
    fontSize: '0.8rem !important',
    fontWeight: '600 !important',
    marginTop: '1.5rem !important'
  },
  contentTitle: {
    color: '#143a69',
    fontWeight: '600',
    borderBottom: '1px solid #ccc'
  },
  note: {
    color: '#777'
  }
}));

const AuctionsPaymentDialog = (props) => {
  const {
    open = false,
    handleCancel = () => {},
    handleConfirm = () => {},
    paymentMethod = '',
    cardDetails = {},
    setPaymentMethod = () => {},
    setCardDetails = () => {}
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={handleCancel}
      aria-labelledby="delete-confirm-dialog-title">
      <DialogTitle
        disableTypography
        classes={{ root: classes.contentTitle }}
        id="delete-confirm-dialog-title">
        Choose Payment & Place Order
      </DialogTitle>
      <DialogContent>
        <PaymentOptions
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          cardDetails={cardDetails}
          setCardDetails={setCardDetails}
        />
        <DialogContentText className={classes.contentText}>
          Are you sure you want to select this bid and place an order?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleConfirm} color="primary">
          Yes
        </Button>
        <Button onClick={handleCancel} color="primary">
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuctionsPaymentDialog;
