import React from 'react';
import { Helmet } from 'react-helmet';
import IntroOne from './components/IntroOne';
import Search from './components/Search';
import Banner from './components/Banner';
import SubFooter from './components/SubFooter';
import Cookies from './components/Cookies';
import { CategoryList } from 'views/ShopList/components';
import './styles.css';

export default function App() {
  return (
    <div style={{ overflowX: 'hidden' }}>
      <Helmet>
        <meta
          name="description"
          content="From Laundry , Dry cleaning to Shoe repair, Launder-it is your all-in-one destination for all your laundry needs. Our online booking system is simple and efficient. You can book your laundry, dry cleaning, or shoe repair orders with just a few clicks, saving you time and effort. Looking for high-quality and convenient laundry and tailoring services near you? Look no further than our top-rated dry cleaner and laundrette! With expert tailors and seamstresses on staff, we offer a wide range of services, from basic laundry and dry cleaning to alterations and shoe repair. Our convenient locations make it easy to find a laundry or laundrette near you, so you can get your clothes cleaned, repaired, and tailored without any hassle. Whether you need a quick fix or a complete overhaul, our skilled team is here to help. So why wait? Visit us today and experience the best dry cleaning, laundry, and tailoring services near you!"
        />
        <meta
          name="keywords"
          content="launder-it, launder it, dry cleaner near me, launderette near me, tailor, shoe repair, laundry near me, alterations near me, seamstress, cobbler near me, Laundry, london laundry services, 24hrs laundry services, Pick up and Drop laundry services, online booking, Dry cleaning, cheapest laundry services, nearby laundry shops, Laundry service near me, Laundry Services , Launderit Online , Clothes Washing , Dry Cleaning , Laundry Pickup Laundry Delivery , Washing Machines Laundry App , Clothes cleaning service, Laundry booking platform, Clothes washing"
        />
      </Helmet>

      <Search />
      <CategoryList />
      <IntroOne />
      <Banner />
      <SubFooter />
      <Cookies />
    </div>
  );
}
