import React, { useContext, useEffect, useState } from 'react';
import { graphqlOperation, API, Storage } from 'aws-amplify';
import ShopDocumentsIdAddress from 'views/LaundryShop/components/ShopDocumentsIdAddress';
import { updateUser } from 'graphql/mutations';
import { Grid, Typography, Divider, Button } from '@material-ui/core';
import { useLoader } from 'layouts/loaderContext';
import { useSnackbar } from 'notistack';
import { getFileUploadKey } from 'common/utilFunctions';
import StaffDocumentationDropzone from '../AddNewStaff/StaffDocumentationDropzone';
import { StaffIdContext } from 'context/StaffIdContext';
import StaffDetailsForm from '../AddNewStaff/StaffDetailsForm';
import { useAmplifyAuth } from 'context';

const StaffProfile = ({ selectedStaff, setSelectedStaff }) => {
  const { setLoading } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [documents, setDocuments] = useState([]);
  const {
    state: { isLaundryAdmin = false }
  } = useAmplifyAuth();
  const [showAddDocument, setShowAddDocument] = useState(false);
  const { setStaffId } = useContext(StaffIdContext);

  useEffect(
    () =>
      setDocuments(
        selectedStaff?.documents?.filter((doc) =>
          isLaundryAdmin ? doc.status !== 'rejected' : true
        ) || []
      ),
    [selectedStaff]
  );

  const handleAddDocument = () => {
    setStaffId(selectedStaff.id);
    setShowAddDocument(true);
  };

  const handleDelete = async (url = '') => {
    Storage.remove(
      selectedStaff.documents?.find((doc) => doc?.documentURL === url)
        ?.documentURL,
      { level: 'protected' }
    );
    const input = {
      id: selectedStaff.id,
      _version: selectedStaff._version,
      documents:
        selectedStaff.documents?.filter((item) => item?.documentURL !== url) ||
        []
    };
    setLoading(true);
    try {
      const data = await API.graphql(graphqlOperation(updateUser, { input }));
      setSelectedStaff(data.data.updateUser);
      enqueueSnackbar('Document Deleted', {
        variant: 'success',
        preventDuplicate: true,
        autoHideDuration: 1500
      });
    } catch (error) {
      console.log('error', error);
      enqueueSnackbar('Something went wrong..!', {
        variant: 'error',
        preventDuplicate: true,
        autoHideDuration: 1500
      });
    } finally {
      setLoading(false);
    }
  };

  const handleReUpload = async (url = '', file) => {
    setLoading(true);
    const oldDoc = documents.find((item) => item?.documentURL === url);
    Storage.remove(oldDoc?.documentURL, { level: 'protected' });
    const { type: mimeType } = file;
    const key = getFileUploadKey(file, 'documents');
    try {
      const resp = await Storage.put(key, file, {
        contentType: mimeType,
        level: 'protected'
      });
      const input = {
        id: selectedStaff.id,
        _version: selectedStaff._version,
        documents: [
          ...selectedStaff.documents?.filter(
            (item) => item?.documentURL !== url
          ),
          {
            ...oldDoc,
            documentURL: key,
            MIMETypes: mimeType
          }
        ]
      };
      const data = await API.graphql(graphqlOperation(updateUser, { input }));
      setSelectedStaff(data.data.updateUser);
      enqueueSnackbar('Document updated', {
        variant: 'success',
        preventDuplicate: true,
        autoHideDuration: 1500
      });
    } catch (error) {
      console.log('error', error);
      enqueueSnackbar('Something went wrong..!', {
        variant: 'error',
        preventDuplicate: true,
        autoHideDuration: 1500
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (url = '', status = 'pending') => {
    setLoading(true);
    const oldDoc = documents.find((item) => item?.documentURL === url);
    try {
      const input = {
        id: selectedStaff.id,
        _version: selectedStaff._version,
        documents: [
          ...selectedStaff.documents?.filter(
            (item) => item?.documentURL !== url
          ),
          { ...oldDoc, status }
        ]
      };
      const data = await API.graphql(graphqlOperation(updateUser, { input }));
      setSelectedStaff(data.data.updateUser);
      enqueueSnackbar('Document updated', {
        variant: 'success',
        preventDuplicate: true,
        autoHideDuration: 1500
      });
    } catch (error) {
      console.log('error', error);
      enqueueSnackbar('Something went wrong..!', {
        variant: 'error',
        preventDuplicate: true,
        autoHideDuration: 1500
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container style={{ margin: '1rem 0' }}>
      <Grid container item xs={12}>
        <Typography variant="h6">Staff Details</Typography>
        <Divider />
        <Grid container item xs={12} style={{ marginTop: '0.5rem' }}>
          <StaffDetailsForm
            edit={true}
            currentStaff={selectedStaff}
            setCurrentStaff={setSelectedStaff}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid container justify="space-between">
          <Typography variant="h6" style={{ marginBottom: '1rem' }}>
            Staff Documents
          </Typography>
          {isLaundryAdmin ? (
            showAddDocument ? (
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => setShowAddDocument(false)}>
                back
              </Button>
            ) : (
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={handleAddDocument}>
                Add Documents
              </Button>
            )
          ) : null}
        </Grid>
        {showAddDocument ? (
          <StaffDocumentationDropzone
            onClose={() => setShowAddDocument(false)}
            setSelectedStaff={setSelectedStaff}
          />
        ) : (
          <>
            <ShopDocumentsIdAddress
              data={documents.filter((el) => el.documentType === 'Id Proof')}
              heading="ID Proof"
              handleReUpload={handleReUpload}
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
            />
            <ShopDocumentsIdAddress
              data={documents.filter(
                (el) => el.documentType === 'Address Proof'
              )}
              heading="Address Proof"
              handleReUpload={handleReUpload}
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default StaffProfile;
