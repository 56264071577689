import React from 'react';
import { Box, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { STAR_VALUE_TO_PERCENTAGE } from 'common/constants';

const useStyles = makeStyles((theme) => ({
  progressColor: {
    backgroundColor: '#EDD242 !important'
  }
}));

const LinearProgressWithLabel = (props) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" style={{ marginTop: '8px' }}>
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          style={{
            height: '10px',
            borderRadius: '1.5rem',
            backgroundColor: '#E5E5E5'
          }}
          classes={{ barColorPrimary: classes.progressColor }}
          {...props}
        />
      </Box>
      <Box minWidth={40}>
        <Typography variant="body1">{`${(
          props.value / STAR_VALUE_TO_PERCENTAGE
        ).toFixed(1)} star`}</Typography>
      </Box>
    </Box>
  );
};

export default LinearProgressWithLabel;
