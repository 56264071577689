import React, { useState, useEffect } from 'react';
import {
  TextField,
  Typography,
  Grid,
  IconButton,
  AppBar,
  Tabs,
  Tab
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import OrderDetailsDialog from './components/dialogs/OrderDetailsDialog';
import OrdersTable from './components/OrdersTable';
import { TabPanel } from 'components/molecules';
import { CustomSearchBar } from 'components/organisms';
import { routes } from 'common/constants';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useAmplifyAuth } from 'context';
import { useOrders } from 'context/order/ordersContext';
import MessageDialog from 'views/Messages/MessageDialog';
import { DIALOG_NAME_FOR_URL } from './constants';

const ORDER_STATUSES_BY_TAB = [
  ['cancelled', 'declined', 'delivered'],
  ['declined'],
  ['delivered']
];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: '12px'
  },
  title: {
    margin: '25px 0 10px'
  },
  tabsBarRoot: {
    backgroundColor: 'transparent',
    color: '#263238',
    marginTop: '2rem',
    paddingBottom: '1rem',
    boxShadow: 'none'
  },
  tabsBarContent: {
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  }
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const OrderHistory = () => {
  const classes = useStyles();
  const history = useHistory();
  const { orders, dispatch } = useOrders();
  const { orderID, actionType } = useParams();
  const [dialogType, setDialogType] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [value, setValue] = React.useState(0);
  const [searchBy, setSearchBy] = React.useState('');
  const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });
  const {
    state: { user }
  } = useAmplifyAuth();

  const loadOrders = (useSearchString = true, newQuery = true) => {
    if (user?.shopId || user?.shopID) {
      const payload = {
        shopId: user.shopId || user.shopID,
        status: ORDER_STATUSES_BY_TAB[value],
        orderId: useSearchString ? searchBy : '',
        newQuery
      };
      if (!!dateRange.startDate && !!dateRange.endDate)
        payload['createdDateRange'] = dateRange;

      dispatch({ type: 'getOrders', payload });
    }
  };

  useEffect(() => {
    loadOrders();
  }, [user, value]);

  useEffect(() => {
    if (
      (!!dateRange.startDate && !!dateRange.endDate) ||
      (!dateRange.startDate && !dateRange.endDate)
    )
      loadOrders();
  }, [dateRange]);

  useEffect(() => {
    if (
      orders.length > 0 &&
      ORDER_STATUSES_BY_TAB[0].includes(orders[0].status)
    ) {
      if (orderID) {
        const dialogType = Object.keys(DIALOG_NAME_FOR_URL).find(
          (item) => DIALOG_NAME_FOR_URL[item] === actionType
        );
        if (!dialogType) {
          history.replace(
            `${routes.serviceProvider.orderHistory}/${orderID}/details`
          );
          return;
        }
        const currentOrder = orders.find(
          (order) => order.orderID.toLowerCase() === orderID.toLowerCase()
        );
        if (currentOrder?.id) {
          setSelectedOrder(currentOrder);
          setDialogType(dialogType);
        } else history.replace(routes.serviceProvider.orderHistory);
      } else setDialogType(null);
    }
  }, [orders, orderID, actionType]);

  const onSearchChange = (event) => {
    if (event.key === 'Enter') loadOrders();
    else setSearchBy(event.target.value.toLowerCase());
  };

  const onSearchClear = () => {
    setSearchBy('');
    loadOrders(false);
  };

  const handleChange = (_, newValue) => setValue(newValue);

  const updateOrder = (order, sts) =>
    history.push(
      `${routes.serviceProvider.orderHistory}/${order.orderID}/${
        DIALOG_NAME_FOR_URL[sts] || ''
      }`
    );

  const handleModalClose = () => {
    if (history.length > 2 && dialogType !== 'showMessages') history.goBack();
    else history.replace(routes.serviceProvider.orderHistory);
  };

  const handleDateChange = (e) =>
    setDateRange({ ...dateRange, [e.target.name]: e.target.value });

  const ordersTableCommonProps = {
    updateOrder,
    loadOrders
  };

  return (
    <div className={classes.root}>
      <Grid container justify="space-between" style={{ rowGap: '0.5rem' }}>
        <Grid
          container
          item
          xs={12}
          sm={6}
          md={3}
          justify="flex-start"
          alignItems="center">
          <Link to={routes.serviceProvider.orders}>
            <IconButton variant="outlined" size="small">
              <ArrowBackIcon />
            </IconButton>
          </Link>
          <Typography style={{ marginLeft: '1.5rem' }} variant="h5">
            Order History
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <CustomSearchBar
            value={searchBy}
            onChange={onSearchChange}
            onKeyDown={onSearchChange}
            placeholder="Order No / Name"
            onClear={onSearchClear}
            onSearch={() => loadOrders()}
          />
        </Grid>
      </Grid>
      <AppBar position="static" className={classes.tabsBarRoot}>
        <Grid
          container
          justify="space-between"
          className={classes.tabsBarContent}>
          <Grid container item xs={12} md={8} justify="flex-start">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example">
              <Tab label="All" {...a11yProps(0)} />
              <Tab label="Declined " {...a11yProps(1)} />
              <Tab label="Delivered" {...a11yProps(2)} />
            </Tabs>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            justify="flex-end"
            alignItems="center">
            <Grid item xs={5}>
              <TextField
                label="Start Date"
                type="date"
                size="small"
                variant="outlined"
                name="startDate"
                InputLabelProps={{
                  shrink: true
                }}
                value={dateRange.startDate}
                onChange={handleDateChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={2} container justify="center" alignItems="center">
              <Typography>to</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                label="End Date"
                size="small"
                variant="outlined"
                name="endDate"
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={dateRange.endDate}
                onChange={handleDateChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
      <TabPanel value={value} index={0}>
        <OrdersTable
          stage={7}
          {...ordersTableCommonProps}
          headerTitle="All Orders"
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OrdersTable
          stage={8}
          {...ordersTableCommonProps}
          headerTitle="All Declined Orders"
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <OrdersTable
          stage={9}
          {...ordersTableCommonProps}
          headerTitle="All Delivered Orders"
        />
      </TabPanel>
      <MessageDialog
        open={dialogType === 'showMessages'}
        handleModalClose={handleModalClose}
        order={selectedOrder}
      />
      <OrderDetailsDialog
        dialogType={dialogType}
        handleModalClose={handleModalClose}
        order={selectedOrder}
      />
    </div>
  );
};

export default OrderHistory;
