import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const HomeShopCardSkeleton = () => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));

  const NUMBER_OF_SHOP_CARD = md ? 4 : 1;
  const CARD_HEIGHT = md ? 183 : 211;
  const CARD_WIDTH = md ? 3 : 12;

  return (
    <Grid container item xs={12} spacing={2}>
      {[...Array(NUMBER_OF_SHOP_CARD).keys()].map((item) => {
        return (
          <Grid container item key={item} xs={CARD_WIDTH}>
            <Grid item xs={12} style={{ marginBottom: '1rem' }}>
              <Skeleton
                animation="wave"
                variant="rect"
                width="100%"
                height={CARD_HEIGHT}
                style={{ borderRadius: 12 }}
              />
            </Grid>

            <Grid item xs={12}>
              <Skeleton animation="wave" variant="text" width={'25%'} />
            </Grid>

            <Grid container item xs={12} spacing={2}>
              <Grid item xs={6}>
                <Skeleton animation="wave" variant="text" height={40} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton animation="wave" variant="text" height={40} />
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default HomeShopCardSkeleton;
