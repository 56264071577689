import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Amplify, { Auth } from 'aws-amplify';
import aws_exports from './aws-exports';

const isLocalhost = !!(window.location.hostname === 'localhost');
const [productionRedirect, localRedirect] = [
  process.env.REACT_APP_GOOGLE_SIGN_IN_REDIRECT_URL,
  'http://localhost:3000'
];

Amplify.configure({
  ...aws_exports,
  API: {
    graphql_endpoint: aws_exports.aws_appsync_graphqlEndpoint,
    graphql_headers: async () => {
      try {
        let token = await Auth.currentSession();
        token = token.idToken.jwtToken;
        return {
          Authorization: token
        };
      } catch (e) {
        return {
          'x-api-key': aws_exports.aws_appsync_apiKey
        };
      }
    }
  },
  oauth: {
    ...aws_exports.oauth,
    redirectSignIn: isLocalhost ? localRedirect : productionRedirect,
    redirectSignOut: isLocalhost ? localRedirect : productionRedirect
  }
});

ReactDOM.render(<App />, document.getElementById('root'));
