import React, { useEffect, useState } from 'react';
import { Grid, MenuItem, makeStyles, TextField } from '@material-ui/core';
import '../styles.css';
import DateFnsUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  fieldsContainer: {
    gap: '0.5rem 0'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  fieldPaddingLeft: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '10px'
    }
  },
  fieldPaddingRight: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '10px'
    }
  }
}));

export const data = {
  collection: {
    item1: 'Driver collection',
    item2: 'Customer drops '
  },
  delivery: {
    item1: 'Driver collection',
    item2: 'Customer collects '
  }
};

export default function CollectAndDeliver({
  allowedHrs,
  setTime,
  setSlot,
  collectionDate = 0,
  collectionSlot = 0,
  type = 'Collection'
}) {
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState(null);
  const [slotOption, setSlotOption] = useState([]);
  const [slotTime, setSlotTime] = React.useState('');

  const disablePrevDates = (date) => {
    if (type === 'Collection') return false;
    const prevDate = collectionDate ? collectionDate : new Date();
    const diff = Math.floor((date - prevDate) / (1000 * 60 * 60 * 24));
    if (diff === -1) {
      const day = date.day();
      const slots = allowedHrs.filter((d) => d.openDay === day);
      if (slots.some((t) => t.openTime > date.format('HHmm'))) {
        return false;
      }
    }
    return diff < -1;
  };

  useEffect(() => {
    const newCollection = collectionDate
      ? Date.parse(collectionDate)
      : new Date();
    if (newCollection > Date.parse(selectedDate)) {
      handleDateChange(null);
      setTime(null);
      setSlotOption([]);
      setSlotTime('');
      setSlot('');
    }
  }, [collectionDate, collectionSlot]);

  const convertTime = (time) => {
    if (time < 1000) {
      time = '0' + time;
    } else {
      time = time.toString();
    }
    const hrs = time.toString().match(/\d{2}/);
    const amOrPm = hrs < 12 ? 'AM' : 'PM'; // Set AM/PM
    const hrsIn12 = hrs % 12 || 12; // Adjust hours
    return time.replace(/(\d{2})(\d{2})/, hrsIn12 + ':$2' + amOrPm);
  };

  // disables day if it's not available in allowed hours or
  // if it is in allowed hours but current time has passed closeTime
  // then also checks for disablePrevDates
  const shouldDisableDate = (date) => {
    return (
      !allowedHrs?.some(({ closeTime, openDay }) =>
        openDay === date.day() && date.isSame(new Date().toDateString())
          ? closeTime
            ? `${new Date().getHours() + 1}${new Date().getMinutes()}` <
              closeTime
            : true
          : openDay === date.day()
      ) || disablePrevDates(date)
    );
  };

  const onChangeDate = (date) => {
    if (type === 'Delivery' && collectionDate?.isAfter(date)) return;
    handleDateChange(date);
    const day = date.day();
    const dateTemp = moment(date).format('MM-DD-YYYY');
    const dateNow = moment(new Date()).format('MM-DD-YYYY');
    const formatCollectionDate = moment(collectionDate).format('MM-DD-YYYY');
    const slots = allowedHrs.filter((d) => d.openDay === day);
    const timeNow = moment(new Date()).format('Hmm');
    const startTime =
      collectionSlot &&
      moment(collectionSlot.split('-')[0], ['h:mmA']).format('Hmm');

    const isOpenAllDay = slots.some((slot) => !slot.closeTime);
    const _slotOptions = isOpenAllDay
      ? []
      : slots
          .filter((d) => (dateTemp === dateNow ? d.closeTime > timeNow : true))
          .filter((d) =>
            collectionSlot && dateTemp === formatCollectionDate
              ? d.closeTime > startTime
              : true
          )
          .map(
            (d) => convertTime(d.openTime) + ' - ' + convertTime(d.closeTime)
          );

    setSlotOption(_slotOptions);
    setTime(date);
    isOpenAllDay && setSlotTime('Open 24 hours');
    isOpenAllDay && setSlot('Open 24 hours');
  };

  const handleChangeTimeSlot = (event) => {
    setSlotTime(event.target.value);
    setSlot(event.target.value);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container className={classes.fieldsContainer}>
        <Grid item xs={12} md={6} className={classes.fieldPaddingRight}>
          <DatePicker
            autoOk
            className={classes.container}
            disablePast
            fullWidth
            format="DD/MM/YYYY"
            inputVariant="outlined"
            label="Date"
            onChange={onChangeDate}
            shouldDisableDate={shouldDisableDate}
            value={selectedDate}
            error={!selectedDate}
            variant="inline"
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.fieldPaddingLeft}>
          <TextField
            disabled={!(slotOption && slotOption.length)}
            id="time slot"
            fullWidth
            variant="outlined"
            select={slotOption && slotOption.length > 0}
            label="Time"
            onChange={handleChangeTimeSlot}
            value={slotTime}
            error={!slotTime}>
            {slotOption &&
              slotOption.length &&
              slotOption?.map((v, index) => (
                <MenuItem key={`${v}-${index}`} value={v}>
                  {v}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
