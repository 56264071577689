import React, { useState, useEffect } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AddReviewDialog from './AddReviewDialog';
import { useAmplifyAuth } from 'context';
import { useSnackbar } from 'notistack';
import { createCustomerReview } from 'graphql/customMutations';
import { graphqlOperation, API } from 'aws-amplify';
import { getShopTotalRating } from 'common/utilFunctions';
import clsx from 'clsx';
import WriteReview from './WriteReview';
import { RATING_CATEGORIES } from 'common/constants';
import ShopReviewsMatrix from 'components/ShopReview/ShopReviewsMatrix';
import ShopReviewList from 'components/ShopReview/ShopReviewList';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '50px 0',
    backgroundColor: 'white',
    margin: '0 auto',
    maxWidth: '90%',
    [theme.breakpoints.up('md')]: {
      padding: '60px 0'
    }
  },
  progressColor: {
    backgroundColor: '#EDD242'
  },
  customerReviewsBox: {
    borderBottom: '1px solid #C4C4C4',
    paddingBottom: '35px',
    marginBottom: '35px'
  },
  userImg: {
    height: 40,
    width: 40,
    borderRadius: '50%',
    marginRight: '0.625rem',
    [theme.breakpoints.up('md')]: {
      marginRight: 0,
      height: 60,
      width: 60
    }
  },
  starCount: {
    fontWeight: 600,
    color: '#edd242'
  }
}));

const ShopReviews = ({
  reviews = [],
  shopID = '',
  addNewReview = () => {},
  className = ''
}) => {
  const classes = useStyles();
  const md = useMediaQuery(useTheme().breakpoints.up('md'));
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { user }
  } = useAmplifyAuth();
  const [showAddReviewDialog, setShowAddReviewDialog] = useState(false);
  const [totalRating, setTotalRating] = useState(null);
  const [newReview, setNewReview] = useState({});
  const shopReviews = reviews
    .filter((review) => !review.orderID)
    .sort((a, b) => b._lastChangedAt - a._lastChangedAt);

  useEffect(() => {
    if (reviews.length > 0) setTotalRating(getShopTotalRating(reviews));
  }, [reviews]);

  const handleChange = (e, newVal) => {
    const reviewDesc = ['description', 'title'];
    if (reviewDesc.includes(e.target.name)) {
      setNewReview({ ...newReview, [e.target.name]: e.target.value });
    } else {
      setNewReview({ ...newReview, [e.target.name]: newVal });
    }
  };

  const handleCancel = () => {
    setShowAddReviewDialog(false);
    setNewReview({});
  };

  const handleSubmit = async () => {
    const reviewsData = {
      title: newReview.title,
      description: newReview.description,
      ratings: Object.keys(newReview)
        .map((item) => {
          if (item !== 'title' && item !== 'description') {
            return {
              title: item,
              rating: newReview[item]
            };
          }
          return null;
        })
        .filter((item) => item)
    };

    try {
      const resp = await API.graphql(
        graphqlOperation(createCustomerReview, {
          input: { ...reviewsData, userID: user.id, shopID }
        })
      );
      enqueueSnackbar('Review submitted', {
        variant: 'success',
        preventDuplicate: true,
        autoHideDuration: 1000
      });
      addNewReview(resp.data.createCustomerReview);
      handleCancel();
    } catch (error) {
      console.log('error', error);
      enqueueSnackbar('Something went wrong...', {
        variant: 'error',
        autoHideDuration: 1000,
        preventDuplicate: true
      });
    }
  };

  return (
    <>
      <Grid
        container
        justify="space-between"
        className={clsx(classes.root, className)}
        id="anchor-to-shop-reviews-footer">
        <Grid item xs={12} md={3}>
          {reviews.length > 0 && (
            <ShopReviewsMatrix
              totalRating={totalRating}
              reviews={reviews}
              shopReviews={shopReviews}
            />
          )}
          {md ? (
            <WriteReview
              reviews={reviews}
              setShowAddReviewDialog={setShowAddReviewDialog}
            />
          ) : null}
        </Grid>
        {reviews.length > 0 && (
          <ShopReviewList
            reviews={reviews}
            shopReviews={shopReviews}
            heading={'Top Reviews'}
          />
        )}
        {!md ? (
          <WriteReview
            reviews={reviews}
            setShowAddReviewDialog={setShowAddReviewDialog}
          />
        ) : null}
      </Grid>
      <AddReviewDialog
        review={newReview}
        handleChange={handleChange}
        open={showAddReviewDialog}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        ratingCategories={RATING_CATEGORIES}
      />
    </>
  );
};

export default ShopReviews;
