import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  Grid,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  Paper
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { layout1, layout2, layout3, layout4 } from '../../assets';

const useStyles = makeStyles(theme => ({
  root: { minWidth: '100%' },
  saveButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.text.primary
  },
  cancelButton: {
    color: theme.palette.text.secondary
  },
  layoutBox: {
    padding: '5vh',
    marginBottom: '0.5rem',
    backgroundColor: '#E7F5FE',
    height: '20vh',
    width: '20vh'
  }
}));

const Appearence = () => {
  const classes = useStyles();
  const [enableActions, setEnableActions] = useState(false);

  const handleSubmit = e => e.preventDefault();

  const handleChange = () => setEnableActions(true);

  const onCancel = () => setEnableActions(false);

  return (
    <Card className={classes.root}>
      <form onSubmit={handleSubmit}>
        <CardHeader
          subheader="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos libero veniam aut, odio mollitia sapiente dolores voluptatibus minus porro? Maxime"
          title="Theme Setting"
        />
        <CardContent>
          <Typography variant="subtitle1">
            Customize on how customers can view your shop
          </Typography>
          <RadioGroup
            row
            aria-label="position"
            name="position"
            onChange={handleChange}
            defaultValue={`layout1`}>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              justify="space-around">
              {[layout1, layout2, layout3, layout4].map((item, index) => (
                <FormControlLabel
                  key={`layout${index + 1}`}
                  value={`layout${index + 1}`}
                  control={
                    <Radio
                      color="primary"
                      checkedIcon={<CheckCircleOutlineIcon />}
                    />
                  }
                  label={
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      justify="center"
                      alignItems="center">
                      <Box m={1} className={classes.layoutBox}>
                        <img
                          src={item}
                          alt={`layout${index + 1}`}
                          height="100%"
                          width="100%"
                        />
                      </Box>
                      <Typography variant="subtitle2">
                        {`Layout ${index + 1}`}
                      </Typography>
                    </Grid>
                  }
                  labelPlacement="top"
                />
              ))}
            </Grid>
          </RadioGroup>
        </CardContent>
        <Divider />
        <CardHeader
          subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit,Lorem ipsum dolor sit amet, consectetur adipiscing elit"
          title="Layout Settings"
        />
        <CardContent>
          <Typography variant="subtitle1">Lorem ipsum</Typography>
          <List>
            <ListItem component={Paper}>
              <ListItemText primary="Receive notifications for customers orders" />
              <ListItemSecondaryAction>
                <Switch edge="end" color="primary" onChange={handleChange} />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem component={Paper}>
              <ListItemText primary="Notify me when customers send direct messages" />
              <ListItemSecondaryAction>
                <Switch edge="end" color="primary" onChange={handleChange} />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem component={Paper}>
              <ListItemText primary="Receive notifications for customers orders" />
              <ListItemSecondaryAction>
                <Switch edge="end" color="primary" onChange={handleChange} />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem component={Paper}>
              <ListItemText primary="Notify me when customers send direct messages" />
              <ListItemSecondaryAction>
                <Switch edge="end" color="primary" onChange={handleChange} />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </CardContent>
        <CardActions>
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-end"
            justify="flex-end"
            alignItems="center"
            xs={12}>
            <Button
              className={classes.cancelButton}
              variant="outlined"
              disabled={!enableActions}
              onClick={onCancel}>
              Cancel
            </Button>
            <Button
              className={classes.saveButton}
              variant="contained"
              disabled={!enableActions}
              type="submit">
              Save
            </Button>
          </Grid>
        </CardActions>
      </form>
    </Card>
  );
};

export default Appearence;
