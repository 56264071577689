import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import { useShops } from '../../../../context/shop/shopsContext';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TableSortLabel,
  Grid,
  FormControlLabel,
  Switch,
  TextField,
  MenuItem,
  InputAdornment,
  IconButton,
  Button
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { getInitials } from 'helpers';
import { useHistory } from 'react-router';
import { CustomSearchBar } from 'components/organisms';
import { useAmplifyAuth } from 'context';
import { useLoader } from 'layouts/loaderContext';
import CloseIcon from '@material-ui/icons/Close';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.main
  },
  actions: {
    justifyContent: 'flex-end'
  },
  searchInput: {
    margin: theme.spacing(2),
    marginLeft: 0,
    maxWidth: 500
  },
  successText: {
    color: theme.palette.success.main
  },
  errorText: {
    color: theme.palette.error.main
  },
  width: {
    width: '20%',
    marginRight: 10
  },
  marginBottom: {
    marginBottom: 25
  },
  linkStyle: {
    textDecoration: 'none',
    color: '#000'
  },
  lowRating: {
    backgroundColor: '#FFCDD2',
    '&:hover': {
      backgroundColor: '#EF9A9A !important'
    }
  }
}));

const ShopsTable = (props) => {
  const history = useHistory();
  const { className, setConsentLetterShop = () => {}, ...rest } = props;
  const {
    shops,
    isMoreShopsAvailable,
    pageNumber,
    getShopPayload,
    dispatch
  } = useShops();
  const [searchBy, setSearchBy] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [showDeletedShops, setShowDeletedShops] = useState(false);
  const { loading } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [isShowNewShops, setIsShowNewShop] = useState(
    getShopPayload.status === 'new' ? true : false
  );
  let {
    state: { user, isSales = false, isAdmin = false }
  } = useAmplifyAuth();

  useEffect(() => {
    setPage(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    if (getShopPayload.searchString) {
      setSearchBy(getShopPayload.searchString);
    }
    if (
      getShopPayload.status &&
      getShopPayload.status !== 'rejected' &&
      getShopPayload.status !== 'new'
    ) {
      setFilterValue(getShopPayload.status);
    }
    if (getShopPayload.status === 'rejected') {
      setShowDeletedShops(true);
    }
  }, []);

  useEffect(() => {
    loadShops(true);
  }, [isSales, showDeletedShops, filterValue]);

  useEffect(() => {
    const isLowRatedShopAvl = shops?.find((shop) => shop.rating?.rating <= 2);
    if (isLowRatedShopAvl) {
      enqueueSnackbar('Consistent poor ratings among some listed shops', {
        variant: 'warning',
        preventDuplicate: true,
        autoHideDuration: 3000
      });
    }
  }, [shops]);

  const loadShops = (newQuery = false, clearString, clearStatus) => {
    const payload = { newQuery, limit: 100 };
    if (searchBy || getShopPayload.searchString) {
      payload.searchString = searchBy || getShopPayload.searchString;
    }
    if (clearString) {
      payload.searchString = '';
    }
    if (isSales) {
      payload.salesUser = user?.sub || user?.id;
    }
    if (filterValue || getShopPayload.status) {
      payload.status = filterValue || getShopPayload.status;
    }
    if (showDeletedShops) {
      payload.status = 'rejected';
    }
    if (clearStatus) {
      payload.status = null;
    }

    dispatch({
      type: 'getShops',
      payload
    });
  };

  const handlePageChange = (_event, page) => {
    isMoreShopsAvailable && shops.length <= page * rowsPerPage && loadShops();
    const payload = { pageNumber: page };
    setPage(page);
    dispatch({
      type: 'updatePageNumber',
      payload
    });
  };

  const onSearchChange = (event) => {
    setSearchBy(event.target.value);
    if (event.key === 'Enter') onSearchSubmit();
  };

  const onSearchSubmit = () => loadShops(true, !!searchBy ? false : true);

  const onSearchClear = () => {
    setSearchBy('');
    loadShops(true, true);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    dispatch({
      type: 'sortShop',
      payload: { property, direction: newOrder }
    });
  };

  const statusFilterHandler = (event) => {
    setFilterValue(event.target.value);
    setShowDeletedShops(false);
  };

  const clearStatusFilter = () => {
    setFilterValue('');
    loadShops(true, false, true);
  };

  const handleShowDeletedSwitch = (e) => {
    setShowDeletedShops(e.target.checked);
    setFilterValue('');

    if (!e.target.checked) {
      loadShops(true, false, true);
    }
  };

  const handleDownloadConsentLetterClick = (id) => (e) => {
    e.stopPropagation();
    dispatch({
      type: 'updateShopsConsentLetterCount',
      payload: {
        shops: shops.filter((shop) => shop.id === id)
      }
    });
    setConsentLetterShop(id);
  };

  const handleShowNewShops = () => {
    setIsShowNewShop(true);
    const payload = { newQuery: true, limit: 100, status: 'new' };
    dispatch({
      type: 'getShops',
      payload
    });
  };

  const handleHideNewShop = () => {
    setIsShowNewShop(false);
    loadShops(true, false, true);
  };

  return (
    <>
      <Grid container justify="space-between" className={classes.marginBottom}>
        <Grid container item xs={12} sm={8} style={{ gap: '1rem' }}>
          <Grid item xs={12} sm={4}>
            <CustomSearchBar
              value={searchBy}
              onChange={onSearchChange}
              onKeyDown={onSearchChange}
              placeholder="id / name / postcode"
              onClear={onSearchClear}
              onSearch={onSearchSubmit}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Status"
              margin="none"
              size="small"
              name={'status'}
              select
              onChange={statusFilterHandler}
              value={filterValue}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {filterValue ? (
                      <IconButton
                        size="small"
                        edge="end"
                        style={{ marginRight: '0.5rem' }}
                        onClick={clearStatusFilter}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    ) : (
                      <></>
                    )}
                  </InputAdornment>
                )
              }}>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="deactivate">Deactivate</MenuItem>
              <MenuItem value="suspended">Suspended</MenuItem>
              <MenuItem value="onhold">On Hold</MenuItem>
              <MenuItem value="new">Requested</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={3}>
            {!isShowNewShops ? (
              <Button variant="contained" onClick={handleShowNewShops}>
                Requested Shops
              </Button>
            ) : (
              <Button variant="contained" onClick={handleHideNewShop}>
                Close
              </Button>
            )}
          </Grid>
        </Grid>

        {isAdmin ? (
          <Grid item xs={12} sm={3} container justify="flex-end">
            <FormControlLabel
              control={
                <Switch
                  checked={showDeletedShops}
                  onChange={handleShowDeletedSwitch}
                  name="shop-delete-show"
                  color="primary"
                />
              }
              label="Show Deleted"
              labelPlacement="start"
            />
          </Grid>
        ) : null}
      </Grid>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'shopID'}
                        direction={orderBy === 'shopID' ? order : 'asc'}
                        onClick={(e) => {
                          handleRequestSort(e, 'shopID');
                        }}>
                        Shop ID
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'name'}
                        direction={orderBy === 'name' ? order : 'asc'}
                        onClick={(e) => {
                          handleRequestSort(e, 'name');
                        }}>
                        Shop Name
                      </TableSortLabel>
                    </TableCell>
                    {filterValue === 'active' ? (
                      <TableCell>Live Status</TableCell>
                    ) : null}
                    <TableCell>Email</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell align="center">
                      <TableSortLabel
                        active={orderBy === 'rating'}
                        direction={orderBy === 'rating' ? order : 'asc'}
                        onClick={(e) => {
                          handleRequestSort(e, 'rating');
                        }}>
                        Rating
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">Consent Letter</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!loading &&
                    shops
                      .filter((shop) =>
                        getShopPayload.status
                          ? shop.status === getShopPayload.status
                          : true
                      )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((Shop) => {
                        const ratingBelowLimit = Shop.rating?.rating <= 2;
                        return (
                          <TableRow
                            className={
                              ratingBelowLimit ? classes.lowRating : ''
                            }
                            hover
                            key={Shop.id}
                            onClick={() => {
                              history.push('/admin/shops/edit/' + Shop.id);
                            }}>
                            <TableCell>{Shop.shopID}</TableCell>
                            <TableCell>
                              <div className={classes.nameContainer}>
                                <Avatar className={classes.avatar}>
                                  {getInitials(Shop.name)}
                                </Avatar>
                                <Typography variant="body1">
                                  {Shop.name}
                                </Typography>
                              </div>
                            </TableCell>
                            {filterValue === 'active' ? (
                              <TableCell
                                className={
                                  Shop?.isLive
                                    ? classes.successText
                                    : classes.errorText
                                }>
                                {Shop?.isLive ? 'Online' : 'Offline'}
                              </TableCell>
                            ) : null}
                            <TableCell onClick={(e) => e.stopPropagation()}>
                              <a
                                href={`mailTo:${Shop.email}`}
                                className={classes.linkStyle}>
                                {Shop.email}
                              </a>
                            </TableCell>
                            <TableCell>
                              {Shop.address}, {Shop.town}, {Shop.postcode}
                            </TableCell>
                            <TableCell onClick={(e) => e.stopPropagation()}>
                              <a
                                href={`tel:${Shop.phoneNumber}`}
                                className={classes.linkStyle}>
                                {Shop.phoneNumber}
                              </a>
                            </TableCell>
                            <TableCell align="center">
                              {Shop.rating ? (
                                <Rating
                                  value={Shop.rating.rating}
                                  precision={0.5}
                                  readOnly
                                />
                              ) : (
                                'N/A'
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={handleDownloadConsentLetterClick(
                                  Shop.id
                                )}
                                title="Download consent letter">
                                <GetAppRoundedIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
              {!loading && shops.length < 1 && (
                <Typography
                  align="center"
                  variant="h6"
                  style={{ paddingTop: '1rem' }}>
                  No shop found
                </Typography>
              )}
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={isMoreShopsAvailable ? -1 : shops.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
    </>
  );
};

ShopsTable.propTypes = {
  className: PropTypes.string
};

export default ShopsTable;
