import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  IconButton,
  Link,
  Chip,
  useMediaQuery,
  Button
} from '@material-ui/core';
import { useAmplifyAuth } from 'context';
import {
  capitalizeStr,
  showPrice,
  getRedirectUrl,
  getFormattedPhoneNumber
} from 'common/utilFunctions';
import {
  WorkingHoursMenu,
  NavigationBreadcrumbs,
  ShopTotalRatingCard
} from 'components/organisms';
import { ShopCurrentWorkingStatus } from 'components/molecules';
import {
  ShopReviews,
  Map,
  ItemDisplayer,
  AboutShop,
  ShopVideos,
  ImageSlider,
  CollectionDeliveryMilesCard
} from './components';
import { useParams } from 'react-router-dom';
import { useRedirects } from 'context/redirect/redirectsContext';
import { useShops } from 'context/shop/shopsContext';
import { useHistory } from 'react-router';
import { useServices } from 'views/Services/serviceContext';
import { useCoupons } from 'context/coupons/couponsContext';
import { useFavouriteShops } from 'context/favouriteShops/favouriteShopsContext';
import { useSnackbar } from 'notistack';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PopoverComponent from 'components/organisms/PopoverComponent';
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon
} from 'react-share';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import ReactGA from 'react-ga4';
import { debounce } from 'lodash';
import { Image } from 'components/organisms';
import noImagePic from 'assets/png/no-image-available.png';

const useStyles = makeStyles((theme) => {
  return {
    rootContainer: {
      borderTop: '1px solid #0000001A'
    },
    root: {
      flexGrow: 1,
      overflow: 'hidden',
      maxWidth: '90%',
      margin: '0 auto'
    },
    blackText: {
      color: 'black'
    },
    greyText: {
      color: theme.palette.grey.secondary
    },
    greyIcon: {
      color: theme.palette.grey.secondary,
      fontSize: '12px'
    },
    '@keyframes bounce': {
      '0%, 100%': {
        transform: 'translateY(0)'
      },
      '50%': {
        transform: 'translateY(-10px)'
      }
    },
    favouriteButton: {
      color: theme.palette.favourite.main,
      animation: '$bounce 0.4s ease-in-out'
    },
    reviewPromoBox: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: '10px'
      }
    },
    shopOnHoldAlertBox: {
      marginTop: '0.5rem',
      [theme.breakpoints.up('md')]: {
        marginTop: '0.2rem'
      }
    },
    shopOnHoldAlertText: {
      color: '#b00',
      fontWeight: 'bold'
    },
    languageChip: {
      color: 'white',
      backgroundColor: theme.palette.blue.main,
      borderColor: theme.palette.blue.main
    },
    shopWebsiteLinkButton: {
      cursor: 'pointer',
      fontWeight: 600
    },
    shopWebsiteLinkButtonIcon: {
      position: 'relative',
      fontSize: '12px',
      top: '2px',
      marginRight: '2px'
    },
    phoneNumLink: {
      color: '#444',
      fontWeight: 600
    },
    shopUnAvailable: {
      fontSize: 12,
      fontWeight: 600,
      backgroundColor: theme.palette.error.main,
      width: 'fit-content',
      padding: '0.1rem 0.5rem',
      margin: '0.2rem 0',
      borderRadius: 4,
      color: '#fff'
    },
    offerCards: {
      position: 'relative',
      marginBottom: '2rem',
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
        display: 'flex',
        paddingRight: '1.25rem'
      }
    },
    fullWidthImage: {
      height: '100%',
      width: '100%',
      objectFit: 'contain',
      aspectRatio: '16 / 9'
    }
  };
});

const debouncedFavouriteShopDispatcher = debounce((payload, dispatch) => {
  if (!payload.id) dispatch({ type: 'createFavouriteShops', payload });
  else dispatch({ type: 'updateFavouriteShops', payload });
}, 2000);

const ShopDetail = () => {
  let { shopID } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const [workingHoursMenuAnchor, setWorkingHoursMenuAnchor] = useState(null);
  const { dispatch: dispatchRedirects } = useRedirects();
  const { dispatch: dispatchService } = useServices();
  const { selectedShop, dispatch } = useShops();
  const {
    favouriteShops,
    dispatch: favouriteShopsDispatch
  } = useFavouriteShops();
  const [checked, setChecked] = useState({ isFavourite: false });
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const shareURL = window.location.href;
  let {
    state: { user }
  } = useAmplifyAuth();
  const { dispatch: dispatchCoupons } = useCoupons();

  useEffect(() => {
    if (selectedShop?.id)
      dispatchCoupons({
        type: 'getCouponsByShop',
        payload: { shopID: selectedShop?.id, currentOnly: true }
      });
  }, [selectedShop]);

  useEffect(() => {
    const checkedShop = favouriteShops.find(
      (shop) => shop.shopID === selectedShop?.id
    );
    checkedShop && setChecked(checkedShop);
  }, [selectedShop, favouriteShops]);

  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({
      hitType: 'pageview',
      page: `${history.location.pathname}${history.location.search}`,
      title: 'shop detail page'
    });

    // get services
    dispatchService({ type: 'getService' });
  }, []);

  const {
    name = '',
    address = '',
    town = '',
    postcode = '',
    variant = 'regular',
    phoneNumber = '',
    workHrs = [],
    reviews = { items: [] },
    primaryPhoto = {},
    photos = [],
    videos = [],
    distanceRange = 0,
    distanceRangeForFree = 0,
    minPriceForFree = 0,
    language = [],
    websiteLink = ''
  } = selectedShop || {};

  let filteredPhotos = photos?.filter(
    (photo) =>
      photo &&
      photo?.status === 'approved' &&
      photo?.MIMETypes.includes('image')
  );

  if (primaryPhoto?.photoURL) {
    let primaryPhotoURL = primaryPhoto?.photoURL;

    filteredPhotos = [
      primaryPhoto,
      ...filteredPhotos.filter(
        (photo) => !primaryPhotoURL.includes(photo.photoURL)
      )
    ];
  }

  const filteredVideos = videos?.filter(
    (video) =>
      video &&
      video?.status === 'approved' &&
      video?.MIMETypes.includes('video')
  );

  const getShopDetails = () => {
    dispatch({
      type: 'getShopByID',
      payload: {
        shopID
      }
    }).then((data) => {
      if (data === 'NO_DATA') {
        history.push('/home');
        return null;
      }
    });
  };

  useEffect(() => {
    getShopDetails();
  }, []);

  useEffect(() => {
    if (user && user.id) {
      favouriteShopsDispatch({
        type: 'getFavouriteShops',
        payload: {
          userId: user.id,
          limit: 5000
        }
      });
    }
  }, [user]);

  const updateCustomerReviews = (newReview) => {
    dispatch({
      type: 'updateShop',
      payload: {
        ...selectedShop,
        reviews: {
          items: [...selectedShop.reviews.items, newReview]
        }
      }
    });
  };

  const copyLinkHandler = () => {
    navigator.clipboard.writeText(shareURL);
    enqueueSnackbar('Shop copied to clipboard', {
      variant: 'success',
      autoHideDuration: 1000,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      }
    });
  };

  const handleShopSiteRedirect = () => {
    dispatchRedirects({
      type: 'createRedirect',
      payload: { shopID: selectedShop.id }
    });
  };

  const favouriteShopHandler = (e) => {
    e.stopPropagation();
    const newVal = {
      shopID: selectedShop.id,
      userID: user.id,
      isFavourite: e.target.checked
    };
    if (checked.id) {
      newVal.id = checked.id;
      newVal._version = checked._version;
    }
    setChecked((prev) => ({ ...prev, ...newVal }));
    debouncedFavouriteShopDispatcher(newVal, favouriteShopsDispatch);
  };

  return selectedShop ? (
    <Grid className={classes.rootContainer}>
      <Helmet>
        <meta
          name="description"
          content="Our team of experts provides a wide range of services, including steam ironing and stain removal, to keep your clothes looking their best. We also offer the best iron on the market, so your clothes come out wrinkle-free and looking sharp. 
          If you're in need of self-service laundry, our coin laundry services are designed to be convenient and easy, so you can get in and out quickly. Our locations are strategically located throughout London, making it easy to find a laundry or shoe repair near you. 
          At our company, we pride ourselves on offering high-quality services and top-notch customer service. So why wait? Visit us today to experience the best in laundry and shoe repair services in London, United Kingdom!"
        />
        <meta
          name="keywords"
          content="launder-it, launder it, Shoe repair, Laundry, Laundry near me, Shoe repair near me, Steam iron, Coin laundry near me, Stain remover, Best iron, Self service laundry near me"
        />
      </Helmet>

      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.root}>
        <Grid item xs={12}>
          <NavigationBreadcrumbs showShopList currentLink={selectedShop.name} />
        </Grid>
        <Grid container>
          {filteredPhotos?.length > 1 ? (
            <ImageSlider photos={filteredPhotos} />
          ) : filteredPhotos?.length === 1 ? (
            <Grid
              container
              item
              md={6}
              xs={12}
              xl={4}
              className={classes.offerCards}>
              <Grid item xs={12} style={{ backgroundColor: 'aliceblue' }}>
                <Image
                  docKey={filteredPhotos[0].photoURL}
                  alt={`shop photo: ${filteredPhotos[0].name || ''}`}
                  loadFromStorage={
                    filteredPhotos[0].photoURL?.split('/').at(0) === 'images'
                  }
                  className={classes.fullWidthImage}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              item
              md={6}
              xs={12}
              xl={4}
              className={classes.offerCards}>
              <img
                src={noImagePic}
                alt="No photos for this shop"
                className={classes.fullWidthImage}
              />
            </Grid>
          )}
          <Grid
            container
            item
            md={6}
            xl={8}
            xs={12}
            justify="center"
            style={{ gap: '10px' }}>
            <Grid
              container
              item
              xs={12}
              style={{
                padding: '10px 20px'
              }}
              component={Paper}>
              <Grid container justify="space-between">
                <Grid container item xs={10} style={{ gap: '0.25rem' }}>
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h3"
                        className={classes.blackText}
                        style={{ fontWeight: 800 }}>
                        {capitalizeStr(name)}
                      </Typography>

                      {selectedShop?.status !== 'active' &&
                      selectedShop?.status !== 'onhold' ? (
                        <Typography
                          variant="h6"
                          className={classes.shopUnAvailable}>
                          Temporarily Unavailable
                        </Typography>
                      ) : null}
                    </Grid>
                    {variant === 'online' ? null : (
                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          className={classes.blackText}>
                          <LocationOnOutlinedIcon
                            className={classes.blackText}
                            style={{ fontSize: '12px' }}
                          />
                          &nbsp; {`${address}, ${town}, ${postcode}`}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justify="flex-end"
                  alignItems="flex-start"
                  item
                  xs={2}>
                  {user && user.username && (
                    <FormControlLabel
                      onClick={favouriteShopHandler}
                      control={
                        <Checkbox
                          checked={checked.isFavourite}
                          icon={
                            <FavoriteBorder
                              fontSize="small"
                              className={`${classes.favouriteButton} ${
                                checked ? 'checked' : ''
                              }`}
                            />
                          }
                          checkedIcon={
                            <Favorite
                              fontSize="small"
                              className={`${classes.favouriteButton} ${
                                checked ? 'checked' : ''
                              }`}
                            />
                          }
                          name="shop-favourite-check"
                        />
                      }
                      style={{ marginRight: 0 }}
                    />
                  )}
                  <IconButton
                    style={{
                      padding: 0,
                      height: 38,
                      width: 38
                    }}>
                    <PopoverComponent>
                      <Grid container style={{ gap: '0.5rem' }}>
                        <IconButton
                          size="small"
                          style={{
                            color: 'blue',
                            height: '30px',
                            backgroundColor: '#46373736'
                          }}
                          onClick={copyLinkHandler}>
                          <FileCopyRoundedIcon />
                        </IconButton>
                        <FacebookShareButton url={shareURL}>
                          <FacebookIcon size={30} round={true} />
                        </FacebookShareButton>
                        <WhatsappShareButton url={shareURL}>
                          <WhatsappIcon size={30} round={true} />
                        </WhatsappShareButton>
                        <TwitterShareButton url={shareURL}>
                          <TwitterIcon size={30} round={true} />
                        </TwitterShareButton>
                        <EmailShareButton url={shareURL}>
                          <EmailIcon size={30} round={true} />
                        </EmailShareButton>
                      </Grid>
                    </PopoverComponent>
                  </IconButton>
                </Grid>
              </Grid>
              {language?.length > 0 ? (
                <Grid
                  container
                  item
                  xs={12}
                  alignItems="center"
                  style={{ gap: '0.5rem', margin: '0.5rem 0 1rem' }}>
                  {language.map((lang, index) => (
                    <Chip
                      variant="outlined"
                      size="small"
                      label={lang}
                      key={`customer-side-shop-detail-language-items-${index}`}
                      className={classes.languageChip}
                    />
                  ))}
                </Grid>
              ) : null}
              {!!websiteLink ? (
                <Grid container item xs={12} alignItems="center">
                  <Link
                    className={classes.shopWebsiteLinkButton}
                    variant="body2"
                    href={getRedirectUrl(websiteLink)}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleShopSiteRedirect}>
                    <OpenInNewIcon
                      className={classes.shopWebsiteLinkButtonIcon}
                    />
                    Go to shop website
                  </Link>
                </Grid>
              ) : null}
              <Grid
                container
                alignItems="flex-end"
                justify="space-between"
                style={{ padding: '0.25rem 0 0.625rem' }}>
                <Grid
                  container
                  item
                  justify="center"
                  alignItems="flex-start"
                  xs={12}
                  md={6}
                  style={{ height: 'fit-content' }}>
                  <Grid item xs={12} container alignItems="center">
                    <AccessTimeIcon className={classes.greyIcon} />
                    <Typography variant="body2" className={classes.greyText}>
                      &nbsp; Opening Hours
                    </Typography>
                  </Grid>
                  <Grid item xs={12} container alignItems="center">
                    <Button
                      variant="text"
                      onClick={(e) =>
                        setWorkingHoursMenuAnchor(e.currentTarget)
                      }
                      style={{ padding: 0 }}
                      endIcon={<KeyboardArrowDownIcon fontSize="small" />}>
                      <ShopCurrentWorkingStatus workHrs={workHrs} />
                    </Button>
                  </Grid>
                  <WorkingHoursMenu
                    anchorEl={workingHoursMenuAnchor}
                    handleClose={() => setWorkingHoursMenuAnchor(null)}
                    workingHours={workHrs}
                  />
                </Grid>
                <Grid
                  container
                  item
                  md={6}
                  xs={12}
                  justify="center"
                  alignItems="center"
                  style={{ paddingLeft: md ? '0.5rem' : 0 }}>
                  <Grid item xs={12} container alignItems="center">
                    <PhoneOutlinedIcon className={classes.greyIcon} />
                    <Typography variant="body2" className={classes.greyText}>
                      &nbsp; Phone Number
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    justify="space-between"
                    alignItems="center"
                    style={{ paddingLeft: md ? '0.2rem' : 0 }}>
                    <Typography variant="subtitle2">
                      <a
                        href={`tel:${getFormattedPhoneNumber(phoneNumber)}`}
                        className={classes.phoneNumLink}>
                        {getFormattedPhoneNumber(phoneNumber)}
                      </a>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              justify="space-between"
              alignItems="flex-start"
              item
              xs={12}>
              {variant === 'online' ? null : (
                <Grid item xs={12} md={6} component={Paper}>
                  <Map state={selectedShop} />
                </Grid>
              )}
              <Grid
                container
                item
                xs={12}
                md={variant === 'online' ? 12 : 6}
                className={variant === 'online' ? '' : classes.reviewPromoBox}
                direction="column"
                justify="center"
                alignItems="center">
                <ShopTotalRatingCard
                  reviewItems={reviews.items}
                  showAnchor={true}
                />
                {(!md || lg) && distanceRange > 0 && (
                  <CollectionDeliveryMilesCard distanceRange={distanceRange} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          className={classes.shopOnHoldAlertBox}>
          {md && !lg && distanceRange > 0 && (
            <CollectionDeliveryMilesCard distanceRange={distanceRange} />
          )}
          {selectedShop?.status === 'onhold' ? (
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.shopOnHoldAlertText}>
                Call the shop and place an order.
              </Typography>
            </Grid>
          ) : null}
          {!!(minPriceForFree || distanceRangeForFree) ? (
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.shopOnHoldAlertText}>
                Free collection & delivery
                {minPriceForFree > 0 ? (
                  <> for order amount {showPrice(minPriceForFree)} & above</>
                ) : null}
                {distanceRangeForFree ? (
                  <> within {distanceRangeForFree} miles</>
                ) : null}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <ItemDisplayer currentShop={selectedShop} />
      <AboutShop currentShop={selectedShop} />
      {filteredVideos?.length > 0 && <ShopVideos videos={filteredVideos} />}
      <ShopReviews
        reviews={reviews.items}
        shopID={selectedShop.id}
        addNewReview={updateCustomerReviews}
      />
    </Grid>
  ) : (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.root}>
      <CircularProgress style={{ margin: '2rem' }} />
    </Grid>
  );
};

export default ShopDetail;
