import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useAmplifyAuth } from '../../../../context';
import { graphqlOperation, API } from 'aws-amplify';
import { updateUserDetail } from '../../../../graphql/mutations';

const useStyles = makeStyles(() => ({
  root: {}
}));

const AccountDetails = (props) => {
  const { className, ...rest } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    state: { user, isAdmin = false },
    _refreshUser,
    _updateUser
  } = useAmplifyAuth();
  const classes = useStyles();
  const [disabled, setDisabled] = useState(true);
  const [values, setValues] = useState(user);

  useEffect(() => {
    user && setValues(user);
  }, [user]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setDisabled(false);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    let sBar;
    try {
      sBar = enqueueSnackbar('Updating Account detail...', {
        variant: 'info',
        preventDuplicate: true
      });
      if (isAdmin) {
        await _updateUser({
          'custom:firstName': values.firstName,
          'custom:lastName': values.lastName
        });
      } else {
        await API.graphql(
          graphqlOperation(updateUserDetail, {
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber || ''
          })
        );
      }
      enqueueSnackbar('Saved', {
        variant: 'success',
        autoHideDuration: 3000
      });
    } catch (e) {
      console.error('Error', e);
    } finally {
      closeSnackbar(sBar);
      setDisabled(true);
      _refreshUser();
    }
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate onSubmit={onSubmit}>
        <CardHeader subheader="The information can be edited" title="Profile" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Please specify the first name"
                label="First name"
                margin="dense"
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName || ''}
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last name"
                margin="dense"
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName || ''}
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                margin="dense"
                name="email"
                disabled
                value={values.email || ''}
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={disabled}>
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
