import React from 'react';
import { Box, Grid } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  shopMessageBox: {
    padding: '0.5rem 1rem',
    marginBottom: '5px',
    color: '#000',
    backgroundColor: '#eee',
    borderRadius: '15px 15px 15px 0px'
  }
}));

const ShopMessage = props => {
  const { shopMessage } = props;
  const classes = useStyles();

  return (
    <Box>
      {shopMessage?.map((texts, i) => {
        return (
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            className={classes.shopMessageBox}
            key={i}>
            {texts.text}
          </Grid>
        );
      })}
    </Box>
  );
};

export default ShopMessage;
