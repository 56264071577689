import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import EntryFormTabPanel from './components/ShopDetails/EntryFormTabPanel';
import { useAmplifyAuth } from 'context';
import { Provider } from '../../context/shopDetail/shopContext';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  }
}));

const ShopDetails = () => {
  let {
    state: { user }
  } = useAmplifyAuth();

  const classes = useStyles();
  return (
    <Provider>
      <div className={classes.root}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            {user && (
              <EntryFormTabPanel
                pageType="shops"
                shopId={user.shopId || user.shopID}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </Provider>
  );
};

ShopDetails.propTypes = {
  className: PropTypes.string
};

export default ShopDetails;
