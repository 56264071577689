import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAmplifyAuth } from 'context';
import { useAuctions } from 'context/auctions/auctionsContext';
import { AuctionToolbar, BidPlaceModal, MyBidsModal } from './components';
import { AuctionCard } from 'components/organisms';
import { TABS } from './constants';
import { Pagination } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: { padding: '1rem' },
  cardContainer: {
    padding: '0.5rem'
  },
  listContainer: {
    marginTop: '1rem'
  }
}));

const Auctions = () => {
  const {
    state: { user }
  } = useAmplifyAuth();
  const classes = useStyles();
  const history = useHistory();
  const { auctions = [], dispatch } = useAuctions();
  const [currentTab, setCurrentTab] = useState(1);
  const [showBidModal, setShowBidModal] = useState(false);
  const [showMyBidsModal, setShowMyBidsModal] = useState(false);
  const [selectedAuctionID, setSelectedAuctionID] = useState(null);
  const [page, setPage] = useState(1);

  const ITEMS_PER_PAGE = 9;

  const filteredAuctions = useMemo(() => {
    if (auctions.length === 0) return [];
    const _noop = () => {};
    const filterCallback = TABS.at(currentTab)?.filter || _noop;
    return auctions.filter(filterCallback);
  }, [auctions, currentTab]);

  useEffect(() => {
    const currentTabValue = TABS.findIndex(
      (tab) => tab.route === history.location.pathname
    );
    setCurrentTab(currentTabValue >= 0 ? currentTabValue : 1);
  }, []);

  useEffect(() => {
    if (auctions.length === 0 && (user?.shopID || user?.shopId))
      dispatch({
        type: 'getAuctionsByShop',
        payload: { shopID: user.shopID || user.shopId }
      });
  }, [user]);

  const handleTabChange = (_, newValue) => {
    setCurrentTab(newValue);
    history.replace(TABS.at(newValue).route);
  };

  const handleViewDetailsButtonClick = (auctionID) => () => {
    setSelectedAuctionID(auctionID);
    setShowBidModal(true);
  };

  const handleShowCurrentShopBids = (auctionID) => () => {
    setSelectedAuctionID(auctionID);
    setShowMyBidsModal(true);
  };

  const handleBidPlaceModalClose = () => {
    setShowBidModal(false);
    setSelectedAuctionID(null);
  };

  const handleMyBidsModalClose = () => {
    setShowMyBidsModal(false);
    setSelectedAuctionID(null);
  };

  const getCardActions = (id = '') => [
    {
      title: 'show my bids',
      onClick: handleShowCurrentShopBids(id),
      color: 'secondary',
      variant: 'outlined'
    },
    {
      title: 'view details',
      onClick: handleViewDetailsButtonClick(id),
      color: 'primary',
      variant: 'contained'
    }
  ];

  const noOfPages =
    filteredAuctions && Math.ceil(filteredAuctions.length / ITEMS_PER_PAGE);

  const paginationHandler = (_, value) => {
    setPage(value);
  };

  return (
    <>
      <Grid container className={classes.root}>
        <AuctionToolbar
          currentTabValue={currentTab}
          handleTabChange={handleTabChange}
        />
        <Grid container className={classes.listContainer}>
          {filteredAuctions.length > 0 ? (
            filteredAuctions
              .slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)
              .map((item, index) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  className={classes.cardContainer}
                  key={`service-provider-auctions-items-${index}`}>
                  <AuctionCard
                    item={item}
                    actions={getCardActions(item.id)}
                    status={currentTab}
                  />
                </Grid>
              ))
          ) : (
            <Typography variant="subtitle2">
              No Auctions available yet.
            </Typography>
          )}

          {filteredAuctions.length > ITEMS_PER_PAGE ? (
            <Grid
              container
              item
              xs={12}
              justify="center"
              style={{ marginTop: '1rem' }}>
              <Pagination
                count={noOfPages}
                page={page}
                onChange={paginationHandler}
                defaultPage={1}
                color="secondary"
                shape="rounded"
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <BidPlaceModal
        open={showBidModal}
        handleClose={handleBidPlaceModalClose}
        auctionID={selectedAuctionID}
      />
      <MyBidsModal
        open={showMyBidsModal}
        handleClose={handleMyBidsModalClose}
        auctionID={selectedAuctionID}
      />
    </>
  );
};

export default Auctions;
