import React from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  Paper,
  Typography
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { Link } from 'react-router-dom';

const StaffActivity = ({ selectedStaff }) => {
  return (
    <div style={{ minWidth: '100%' }}>
      <Typography
        variant="h5"
        style={{ marginTop: '2rem', marginBottom: '1rem' }}>
        Orders taken
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="staff-activities-table">
          <TableHead>
            <TableRow>
              <TableCell align="center">ORDER NO.</TableCell>
              <TableCell align="center">DATE ORDERED</TableCell>
              <TableCell align="center">RATING</TableCell>
              <TableCell align="center">ORDER DELIVERED</TableCell>
              <TableCell align="center">EARNED</TableCell>
              <TableCell>STATUS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            style={{
              boxShadow:
                '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)'
            }}>
            {[...Array(6)].map((item, index) => (
              <TableRow
                key={`staff-member-activities-order-${index}`}
                style={{
                  backgroundColor: index % 2 !== 0 ? '#F6F6F6' : 'white'
                }}>
                <TableCell align="center">
                  <Link to="#">OR-00001</Link>
                </TableCell>
                <TableCell align="center">11/04/2021</TableCell>
                <TableCell align="center">
                  <Rating
                    name="staff-member-activities-order-rating"
                    defaultValue={3}
                    max={3}
                  />
                </TableCell>
                <TableCell align="center">22/04/2021</TableCell>
                <TableCell align="center">£ 000.00</TableCell>
                <TableCell style={{ color: '#3F51B5' }}>
                  Order delivered
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default StaffActivity;
