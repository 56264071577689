import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  TableCell,
  TableRow,
  Checkbox,
  IconButton,
  TextareaAutosize,
  Grid,
  Typography,
  ListItemText
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Image } from 'components/organisms';
import { StickyTableCell } from 'components/molecules';
import { graphqlOperation, API } from 'aws-amplify';
import { getGallaryPhotoByServiceID } from 'graphql/queries';
import { useLoader } from 'layouts/loaderContext';
import { useSnackbar } from 'notistack';
import PhotoGalleryDialog from 'components/organisms/PhotoGalleryDialog';
import { validateImageAspectRatio } from 'common/validators';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  autoComplete: {
    margin: theme.spacing(1),
    minWidth: 500
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  paper: {
    padding: 10
  },
  footer: {
    flexGrow: 1,
    padding: 10
  },
  amount: {
    width: 60,
    '& input': {
      padding: '10.5px 8px'
    }
  },
  column: {
    maxWidth: 100
  },
  checkboxColumn: {
    left: 0
  },
  nameColumn: {
    left: 44
  },
  priceColumn: {
    minWidth: '10rem'
  },
  columnWithError: {
    backgroundColor: '#ffbb55'
  },
  imgHolder: {
    width: 82,
    height: 55,
    textAlign: 'center',
    border: '1px solid #bbb',
    marginRight: 10,
    position: 'relative'
  },
  image: {
    maxHeight: 54,
    maxWidth: 80,
    transform: 'translateY(-50%)',
    marginTop: '33%'
  },
  imageColumn: {
    width: 50
  },
  iconButton: {
    padding: 10
  },
  imgEditButton: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    background: 'rgba(255,255,255,.75)',
    borderRadius: 0,
    padding: 0
  },
  imgRemoveButton: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    background: 'rgba(255,255,255,.75)',
    borderRadius: 0,
    padding: 0
  },
  alignMiddle: {
    verticalAlign: 'middle'
  },
  tableRow: {
    '& .editBtn': {
      visibility: 'hidden'
    },
    '&:hover .editBtn': {
      visibility: 'visible'
    }
  }
}));

const ProductsRow = ({
  row,
  product = { id: '' },
  products,
  dispatch,
  setDisabled,
  priceHelper
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [editName, setEditName] = useState('');
  const [editDescription, setEditDescription] = useState('');
  const [imgData, setImgData] = useState(''); // TODO : check for optimize
  const { setLoading } = useLoader();
  const [showDialog, setShowDialog] = useState(false);
  const [previewGalleryImg, setPreviewGalleryImg] = useState([]);
  const [serviceName, setServiceName] = useState('');
  const [priceType, setPriceType] = useState('normal');

  useEffect(() => {
    setPriceType(product?.priceRange ? 'range' : 'normal');
  }, [product.id]);

  const updateProducts = (obj) => {
    let newProducts = Object.assign({}, products);
    if (!!product.serviceID) {
      const updatedProduct = {
        ...product,
        enabled: true,
        modify: true,
        ...obj
      };
      const isValid =
        updatedProduct.price &&
        updatedProduct.price !== 0 &&
        !!updatedProduct.unit;
      newProducts[product.itemID || row.id] = { ...updatedProduct, isValid };
    } else {
      const { id, ...rowValue } = row;
      newProducts[row.id] = {
        price: 0,
        ...rowValue,
        itemID: id,
        isValid: false,
        unit: '',
        enabled: true,
        modify: true,
        ...obj
      };
    }
    dispatch({
      type: 'updateData',
      payload: newProducts
    });
    setDisabled(false);
  };

  const handleFileChange = (event) => {
    const {
      target: { value, files }
    } = event;
    const fileForUpload = files[0];
    validateImageAspectRatio(fileForUpload, 3 / 2, (isValid) => {
      if (isValid) {
        updateProducts({ file: fileForUpload });
        readFile(fileForUpload || value);
      } else {
        enqueueSnackbar(
          `The uploaded image is not matching the desired ratio.`,
          {
            variant: 'error',
            persist: true
          }
        );
      }
    });
  };

  const handleFileRemove = (event) => {
    updateProducts({ file: null });
    updateProducts({ image: null });
    setImgData('');
  };

  const readFile = (_file) => {
    if (_file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImgData(reader.result);
      };
      reader.readAsDataURL(_file);
    }
  };

  const saveToProduct = (propName) => {
    const obj = {};
    const value = propName === 'name' ? editName : editDescription;
    if (value.trim()) {
      obj[propName] = value;
    } else if (propName !== 'name') {
      obj[propName] = '';
    }
    updateProducts(obj);

    if (propName === 'name') {
      setEditName('');
    } else {
      setEditDescription('');
    }
  };

  const changeHandler = (event) => {
    updateProducts({ enabled: !(product && product.enabled) });
  };

  const updateUnits = (event) => {
    const {
      target: { value }
    } = event;
    updateProducts({ unit: value });
  };

  const updatePrice = (e) => {
    const re = /^[0-9]+\.?[0-9]{0,2}$/;
    if (e.target.value === '' || re.test(e.target.value))
      updateProducts({ price: e.target.value, priceRange: '' });
  };

  const updatePieces = (e) => {
    const re = /^[0-9]+$/;
    if (e.target.value === '' || re.test(e.target.value))
      updateProducts({ noOfPieces: e.target.value || null });
  };

  const updatePriceRange = (index) => (e) => {
    const re = /^[0-9]+\.?[0-9]{0,2}$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      const priceArr = product.priceRange
        ? product.priceRange.split('-')
        : ['', ''];
      priceArr[index] = e.target.value;
      updateProducts({ priceRange: priceArr.join('-'), price: 0 });
    }
  };

  const selectItemHandler = (product) => {
    setShowDialog(true);
    setServiceName(product?.name);
    fetchItemPhotos(product?.serviceID);
  };

  const selectImageHandler = (el = '') => {
    !el ? setImgData(null) : setImgData(el.picture.photoURL);
  };

  const onCancelSelectingImg = () => {
    setImgData('');
    setShowDialog(false);
  };

  const onConfirmImage = () => {
    updateProducts({ image: imgData });
    setShowDialog(false);
  };

  const fetchItemPhotos = (serviceId) => {
    if (serviceId) {
      try {
        setLoading(true);
        API.graphql(
          graphqlOperation(getGallaryPhotoByServiceID, {
            serviceID: serviceId
          })
        ).then(({ data }) => {
          const itemImgs = data.getGallaryPhotoByServiceID.items
            .filter((item) => !item._deleted)
            .map((img) => img);
          setPreviewGalleryImg(itemImgs);
          setLoading(false);
        });
      } catch (error) {
        console.log('error', error);
        setLoading(false);
      }
    }
  };

  return (
    <TableRow className={classes.tableRow} key={row.name}>
      <StickyTableCell padding="checkbox" className={classes.checkboxColumn}>
        <Checkbox
          checked={(product && product.enabled) || false}
          onChange={changeHandler}
        />
      </StickyTableCell>
      <StickyTableCell
        component="th"
        scope="row"
        className={classes.nameColumn}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={6} md={7}>
            {editName ? (
              <TextField
                margin="none"
                onChange={(e) => setEditName(e.target.value.trimStart() || ' ')}
                size="small"
                value={editName}
                variant="outlined"
              />
            ) : (
              <ListItemText
                primary={product?.name || row.name}
                secondary={
                  !!(product?.codeName || row.codeName)
                    ? `code: ${product?.codeName || row.codeName}`
                    : ''
                }
              />
            )}
          </Grid>

          <Grid item xs={6} md={5} container>
            {editName ? (
              <>
                <IconButton
                  className={classes.iconButton}
                  onClick={() => saveToProduct('name')}>
                  <CheckBoxIcon fontSize="small" />
                </IconButton>
                <IconButton
                  className={classes.iconButton}
                  onClick={() => setEditName('')}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            ) : (
              <IconButton
                className={`${classes.iconButton} editBtn`}
                disabled={!product?.enabled || !!product?.itemID}
                onClick={() =>
                  setEditName((product && product.name) || row.name)
                }>
                <EditIcon fontSize="small" />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </StickyTableCell>
      <TableCell className={classes.imageColumn}>
        <div className={classes.imgHolder}>
          {imgData ? (
            <Image
              docKey={imgData}
              loadFromStorage={imgData.includes('images/')}
              alt="Item"
              className={classes.image}
            />
          ) : product?.image || row.image ? (
            <Image
              docKey={product?.image || row.image}
              alt={product?.name || row.name}
              className={classes.image}
            />
          ) : null}

          {(product && product?.image) || imgData ? (
            <IconButton
              className={`${classes.imgRemoveButton} editBtn`}
              component="span"
              onClick={handleFileRemove}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          ) : null}

          <IconButton
            onClick={() => selectItemHandler(row)}
            className={`${classes.imgEditButton} editBtn`}
            disabled={!product?.enabled}
            component="span">
            <EditIcon fontSize="small" />
          </IconButton>
        </div>
      </TableCell>
      <TableCell>
        {editDescription ? (
          <TextareaAutosize
            className={classes.alignMiddle}
            onChange={(e) => {
              setEditDescription(e.target.value.trimStart() || ' ');
            }}
            rowsMin={3}
            value={editDescription}
          />
        ) : (
          (product && product.description) || row.description || 'N/A'
        )}
        {editDescription ? (
          <>
            <IconButton
              className={classes.iconButton}
              onClick={() => saveToProduct('description')}>
              <CheckBoxIcon fontSize="small" />
            </IconButton>
            <IconButton
              className={classes.iconButton}
              onClick={() => setEditDescription('')}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        ) : (
          <IconButton
            className={`${classes.iconButton}  editBtn`}
            disabled={!product?.enabled}
            onClick={() =>
              setEditDescription(
                (product && product.description) || row.description || ' '
              )
            }>
            <EditIcon fontSize="small" />
          </IconButton>
        )}
      </TableCell>
      <TableCell align="center">
        <TextField
          className={classes.amount}
          margin="none"
          size="small"
          variant="outlined"
          value={product?.noOfPieces || ''}
          onChange={updatePieces}
          disabled={!product?.enabled}
        />
      </TableCell>
      <TableCell align="center" className={classes.column}>
        <TextField
          select
          size="small"
          onChange={(e) => setPriceType(e.target.value)}
          value={priceType}
          variant="outlined"
          disabled={!product?.enabled}
          SelectProps={{
            native: true
          }}
          required>
          <option value="normal">normal</option>
          <option value="range">range</option>
        </TextField>
      </TableCell>
      <TableCell
        align="center"
        className={clsx(
          classes.priceColumn,
          !!(
            product?.enabled &&
            priceHelper &&
            !product?.price &&
            !product?.priceRange?.split('-')?.at(0)
          ) && classes.columnWithError
        )}>
        {priceType === 'normal' ? (
          <TextField
            className={classes.amount}
            margin="none"
            onChange={updatePrice}
            size="small"
            helperText={product?.enabled && !product?.price && priceHelper}
            error={!!(product?.enabled && priceHelper && !product?.price)}
            disabled={!product?.enabled}
            value={product?.price || row.price || ''}
            variant="outlined"
            required
          />
        ) : (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ gap: '0.25rem' }}>
            <TextField
              className={classes.amount}
              margin="none"
              onChange={updatePriceRange(0)}
              size="small"
              helperText={
                product?.enabled &&
                !product?.priceRange?.split('-')?.at(0) &&
                priceHelper
              }
              error={
                !!(
                  product?.enabled &&
                  priceHelper &&
                  !product?.priceRange?.split('-')?.at(0)
                )
              }
              disabled={!product?.enabled}
              value={product?.priceRange?.split('-')[0] || ''}
              variant="outlined"
              required
            />
            <Typography variant="body1">to</Typography>
            <TextField
              className={classes.amount}
              margin="none"
              onChange={updatePriceRange(1)}
              size="small"
              disabled={!product?.enabled}
              value={product?.priceRange?.split('-')[1] || ''}
              error={
                !!(
                  product?.enabled &&
                  priceHelper &&
                  !product?.priceRange?.split('-')?.at(0)
                )
              }
              variant="outlined"
              required
            />
          </Grid>
        )}
      </TableCell>
      <TableCell
        align="center"
        className={clsx(
          classes.column,
          !!(product?.enabled && priceHelper && !product?.unit) &&
            classes.columnWithError
        )}>
        <TextField
          select
          size="small"
          onChange={updateUnits}
          value={product?.unit || ''}
          error={!!(product?.enabled && priceHelper && !product?.unit)}
          disabled={!product?.enabled}
          variant="outlined"
          SelectProps={{
            native: true
          }}
          required>
          <option value=""></option>
          <option value="perPair">per/pair</option>
          <option value="perKg">per/kg</option>
          <option value="perItem">per/item</option>
          <option value="perLoad">per/load</option>
          <option value="poa">POA</option>
        </TextField>
      </TableCell>

      {/* TODO : need to remove this outside of row */}
      <PhotoGalleryDialog
        open={showDialog}
        onClose={onCancelSelectingImg}
        previewGalleryImg={previewGalleryImg}
        serviceName={serviceName}
        selectImageHandler={selectImageHandler}
        handleFileChange={handleFileChange}
        imgData={imgData}
        setImgData={setImgData}
        onConfirmImage={onConfirmImage}
      />
    </TableRow>
  );
};

export default ProductsRow;
