import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  IconButton,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ImageIcon from '@material-ui/icons/Image';
import SendIcon from '@material-ui/icons/Send';
import { createMessage } from 'context/messages/messageMutations';
import { API, graphqlOperation } from 'aws-amplify';
import { useAmplifyAuth } from 'context';
import { useSnackbar } from 'notistack';
import { getMessageByOrderID } from 'graphql/queries';
import { getMessage } from 'context/messages/messageQueries';
import { onCreateMessageNotification } from 'context/messages/messageSubscriptions';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import ChatImageSendDialog from './ChatImageSendDialog';
import Image from './Image';
import { deleteMessage } from 'graphql/mutations';
import { getStorageUrl } from 'common/utilFunctions';
import DocumentDeleteDialog from './DocumentDeleteDialog';
import defaultUserImage from 'assets/png/default-image-user.png';
import ChatMessageMenu from './ChatMessageMenu';
import clsx from 'clsx';
import ViewDialog from 'views/LaundryShop/components/ViewDialog';
import ActionMessage from './ActionMessage';
import { debounce } from 'lodash';
import { useLoader } from 'layouts/loaderContext';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  container: {
    border: '2px solid #0000003b',
    backgroundColor: theme.palette.white,
    marginLeft: 0,
    marginTop: '1rem',
    borderRadius: '0.25rem',
    [theme.breakpoints.up('md')]: {
      marginTop: 0
    }
  },
  greenDot: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    color: '#15CF74',
    marginRight: '4px'
  },
  chatBoxHeader: {
    padding: '0.5rem',
    borderRadius: '0.25rem 0.25rem 0 0',
    borderBottom: '2px solid #0000003b',
    boxShadow: '0 2px 8px -2px #0000003b',
    zIndex: 1
  },
  avatar: {
    height: 40,
    width: 40,
    marginRight: '1rem'
  },
  listItemText: {
    padding: '0.5rem'
  },
  shopName: {
    fontWeight: '700'
  },
  messageBody: {
    backgroundColor: theme.palette.white
  },
  senderIcon: {
    marginRight: '0.6rem',
    height: 30,
    width: 30,
    borderRadius: '50%'
  },
  inputBox: {
    height: '5rem',
    padding: '0.5rem 0',
    backgroundColor: '#46c6ce40'
  },
  messageBox: {
    height: '30rem',
    padding: '1rem',
    gap: '1rem'
  },
  messageContentLeft: {
    paddingLeft: '1.5rem'
  },
  messageContentRight: {
    paddingRight: '1.5rem'
  },
  messageImage: {
    padding: '0.5rem 0',
    objectFit: 'contain',
    height: '135px',
    [theme.breakpoints.up('lg')]: {
      height: '180px'
    }
  },
  messageText: {
    width: 'fit-content',
    padding: '0.5rem 1rem',
    color: '#000',
    marginBottom: '0.25rem'
  },
  msgReceived: {
    backgroundColor: '#eee',
    borderRadius: '15px 15px 15px 0px'
  },
  msgSent: {
    backgroundColor: '#EAFCFE',
    borderRadius: '15px 15px 0 15px'
  },
  scrollContainer: {
    width: '100%',
    maxHeight: '30rem'
  },
  sendMessageInput: {
    border: 'none',
    outline: 'none',
    fontSize: '16px'
  },
  sendMessageIcon: {
    color: '#fff',
    backgroundColor: '#194376',
    height: '1.5rem',
    width: '1.5rem',
    borderRadius: '50%',
    padding: '0.25rem',
    [theme.breakpoints.up('sm')]: {
      height: '2rem',
      width: '2rem',
      padding: '0.375rem'
    }
  },
  iconColor: {
    color: '#808AA0'
  }
}));

const debouncedGetMessage = debounce(
  (orderActive, setUserMessage, setLoading) => {
    API.graphql(
      graphqlOperation(getMessageByOrderID, {
        orderID: orderActive.id
      })
    ).then(({ data }) => {
      const msgs = data?.getMessageByOrderID?.items
        .map((msg) => ({
          ...msg,
          text: msg.message
        }))
        .filter((message) => !message._deleted)
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      setUserMessage(msgs);
      setLoading(false);
    });
  },
  1500
);

const ChatMessageBody = (props) => {
  const { orderActive, showSenderName = false, className = '' } = props;
  const { loading, setLoading } = useLoader();
  const classes = useStyles();
  const [messageDeleteAnchor, setMessageDeleteAnchor] = useState({
    x: null,
    y: null
  });
  const [messageTerm, setMessageTerm] = useState({});
  const {
    state: { user, isLaundryAdmin, isLaundryStaff }
  } = useAmplifyAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [userMessage, setUserMessage] = useState([{}]);
  const [scrollEl, setScrollEl] = useState();
  const [selectedMsgId, setSelectedMsgId] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [fileImage, setFileImage] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [deleteMsgOpen, setDeleteMsgOpen] = useState(false);
  const [photoMessage, setPhotoMessage] = useState(null);
  const [viewPhotoMessageDialog, setViewPhotoMessageDialog] = useState(false);

  useEffect(() => {
    if (scrollEl) {
      scrollEl.scrollTop = Number.MAX_SAFE_INTEGER;
    }
  }, [scrollEl, userMessage]);

  const newUserMessageHandler = (e) => {
    setMessageTerm({
      id: Date.now(),
      text: e.target.value
    });
  };

  const addNewMessage = (newMessage) =>
    setUserMessage((userMessage) => [
      ...userMessage,
      { ...newMessage, text: newMessage.message }
    ]);

  const sendHandler = async (e, photo) => {
    if (e.key !== 'Enter' && e.type !== 'click') return;
    if (
      Object.keys(messageTerm).length === 0 ||
      messageTerm?.text.trim().length == 0
    )
      return;

    try {
      const data = await API.graphql(
        graphqlOperation(createMessage, {
          input: {
            sender: user.id,
            orderID: orderActive.id,
            photo: photo,
            message: messageTerm.text,
            status: 'sent'
          }
        })
      );
      addNewMessage(data.data.createMessage);
      setMessageTerm({ id: '', text: '' });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    debouncedGetMessage(orderActive, setUserMessage, setLoading);

    // Subscribe to creation of message
    const subscriptionParams = {
      orderID: orderActive.id
    };
    if (!!(isLaundryAdmin || isLaundryStaff))
      subscriptionParams.shopID = user.shopId;
    else subscriptionParams.userID = user.id;

    const subscription = API.graphql(
      graphqlOperation(onCreateMessageNotification, subscriptionParams)
    ).subscribe({
      next: ({ _, value }) => {
        const newMessageID =
          value?.data?.onCreateMessageNotification?.messageID;

        API.graphql(
          graphqlOperation(getMessage, {
            id: newMessageID
          })
        )
          .then(({ data }) => {
            const newMessage = data.getMessage;
            setUserMessage((userMessage) => [
              ...userMessage,
              { ...newMessage, text: newMessage.message }
            ]);
          })
          .catch(() => console.error('something went wrong...'));
      },
      error: (error) => {
        enqueueSnackbar('Please refresh the page to load new messages', {
          variant: 'error',
          persist: true
        });
        console.warn(error);
      }
    });

    return () => subscription.unsubscribe();
  }, [orderActive]);

  const handleContextMenuOpen = (msg) => (event) => {
    if (user?.id === msg.sender) {
      event.preventDefault();
      setSelectedMsgId(msg.id);
      setMessageDeleteAnchor({ x: event.clientX - 2, y: event.clientY - 4 });
    }
  };

  const handleContextMenuClose = () => {
    setMessageDeleteAnchor({ x: null, y: null });
    setSelectedMsgId(null);
  };

  const handleFileChange = (event) => {
    const {
      target: { value, files }
    } = event;
    const fileForUpload = files[0];
    readFile(fileForUpload || value);
  };

  const readFile = (_file) => {
    if (_file) {
      handleClickOpen();
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
        setFileImage(_file);
      };
      reader.readAsDataURL(_file);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setPreviewImage(null);
    setFileImage(null);
    setOpen(false);
  };

  const deleteMessageHandler = () => {
    setDeleteMsgOpen(true);
  };
  const handleDeleteMsgModalClose = () => {
    setDeleteMsgOpen(false);
  };

  const onConfirmMsgDeleteHandler = async () => {
    const msgToDelete = userMessage.find(
      (message) => message.id === selectedMsgId
    );
    try {
      await API.graphql(
        graphqlOperation(deleteMessage, {
          input: {
            id: msgToDelete.id,
            _version: msgToDelete._version
          }
        })
      );
      setUserMessage((prev) =>
        prev.filter((item) => item.id !== msgToDelete.id)
      );
    } catch (e) {
      console.log(e);
    } finally {
      handleDeleteMsgModalClose();
    }
  };

  const photoMessageHandler = (msgPhoto) => () => {
    setPhotoMessage(msgPhoto);
    setViewPhotoMessageDialog(true);
  };

  const closePhotoMessageHandler = () => {
    setPhotoMessage(null);
    setViewPhotoMessageDialog(false);
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justify="space-between"
        className={clsx(classes.messageBody, className)}>
        <PerfectScrollbar
          className={classes.scrollContainer}
          containerRef={(ref) => setScrollEl(ref)}>
          <Grid container className={classes.messageBox}>
            {!loading ? (
              userMessage.map((msg, i) => {
                return msg.messageIntent ? (
                  <ActionMessage
                    key={`message-box-action-item-${i}`}
                    message={msg}
                  />
                ) : (
                  <Grid
                    container
                    justify={
                      user?.id === msg.sender ? 'flex-end' : 'flex-start'
                    }
                    key={`message-box-message-item-${i}`}>
                    <Grid item xs={12} lg={8}>
                      <Grid
                        container
                        direction="column"
                        alignItems={
                          user?.id === msg.sender ? 'flex-end' : 'flex-start'
                        }
                        className={
                          user?.id === msg.sender
                            ? classes.messageContentRight
                            : classes.messageContentLeft
                        }>
                        {msg.photo?.thumbURL ? (
                          <Image
                            onContextMenu={handleContextMenuOpen(msg)}
                            docKey={msg.photo.thumbURL}
                            onError={async (e) => {
                              e.target.src = await getStorageUrl(
                                msg.photo?.photoURL
                              );
                            }}
                            mimeType={msg.photo?.MIMETypes}
                            className={classes.messageImage}
                            onClick={photoMessageHandler(msg.photo)}
                          />
                        ) : null}
                        {msg.text ? (
                          <Typography
                            onContextMenu={handleContextMenuOpen(msg)}
                            variant="h6"
                            className={clsx(
                              classes.messageText,
                              user?.id === msg.sender
                                ? classes.msgSent
                                : classes.msgReceived
                            )}>
                            {msg.text}
                          </Typography>
                        ) : null}
                      </Grid>
                      <Grid
                        container
                        alignItems={
                          user?.id === msg.sender ? 'flex-end' : 'flex-start'
                        }
                        direction="column">
                        <Image
                          title={
                            showSenderName
                              ? `${msg.senderDetails?.firstName} ${msg.senderDetails?.lastName}`
                              : ''
                          }
                          docKey={msg.senderDetails?.picture}
                          onError={(e) => (e.target.src = defaultUserImage)}
                          mimeType={msg.photo?.MIMETypes}
                          className={classes.senderIcon}
                        />
                        <Typography variant="body2">
                          {moment(msg.createdAt).format('LLL') || ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Grid container justify="center" alignItems="center">
                <CircularProgress />
              </Grid>
            )}
          </Grid>
        </PerfectScrollbar>

        <Grid container alignItems="center" className={classes.inputBox}>
          <Grid
            item
            xs={2}
            sm={1}
            container
            alignItems="center"
            justify="center">
            <input
              type="file"
              id="message-image"
              accept="image/*"
              onChange={handleFileChange}
              hidden
            />
            <label htmlFor="message-image">
              <IconButton component="span" style={{ padding: '0.25rem' }}>
                <ImageIcon className={classes.iconColor} />
              </IconButton>
            </label>
          </Grid>
          <Grid item xs={8} sm={10}>
            <TextField
              fullWidth
              placeholder="Type a Message..."
              className={classes.sendMessageInput}
              onChange={newUserMessageHandler}
              onKeyDown={sendHandler}
              value={messageTerm.text || ''}
            />
          </Grid>
          <Grid
            item
            xs={2}
            sm={1}
            container
            alignItems="center"
            justify="center">
            <Button onClick={sendHandler}>
              <SendIcon className={classes.sendMessageIcon} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <ViewDialog
        open={viewPhotoMessageDialog}
        handleCancel={closePhotoMessageHandler}
        docKey={photoMessage?.photoURL}
        mimeType={photoMessage?.mimeType}
      />
      <ChatImageSendDialog
        open={open}
        onClose={handleClose}
        previewImage={previewImage}
        fileImage={fileImage}
        addNewMessage={addNewMessage}
        orderActive={orderActive}
      />
      <DocumentDeleteDialog
        open={deleteMsgOpen}
        handleCancel={handleDeleteMsgModalClose}
        handleConfirm={onConfirmMsgDeleteHandler}
        confirmText="delete this message for everyone"
      />
      <ChatMessageMenu
        anchor={messageDeleteAnchor}
        handleClose={handleContextMenuClose}
        handleDelete={deleteMessageHandler}
      />
    </>
  );
};

export default ChatMessageBody;
