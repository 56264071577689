import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import GavelOutlinedIcon from '@material-ui/icons/GavelOutlined';
import { AuctionFormModal } from 'views/MyAuctions/components';
import { useAmplifyAuth } from 'context';
import { useHistory } from 'react-router-dom';
import { routes } from 'common/constants';

const useStyles = makeStyles((theme) => ({
  root: {},
  auctionButton: {
    color: '#fff',
    borderColor: '#fff',
    textTransform: 'capitalize',
    fontSize: 12,
    fontWeight: 600,
    padding: '0.125rem 0.5rem',
    [theme.breakpoints.down('md')]: {
      padding: '5px 6px',
      margin: '0'
    }
  },
  shopDetailAuctionButton: {
    color: '#fff',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    width: '100%',
    textTransform: 'uppercase'
  }
}));

const CreateAuctionButton = ({
  isDisabled = false,
  createFromShopDetails = false
}) => {
  const classes = useStyles();
  const [openAuctionDialog, setOpenAuctionDialog] = useState(false);
  let {
    state: { user }
  } = useAmplifyAuth();
  const history = useHistory();

  useEffect(() => {
    if (history.location.search.includes('show-create-auction=true')) {
      setOpenAuctionDialog(true);
    }
  }, []);

  const handleClickOpen = () => {
    if (user && Object.keys(user).length > 0) {
      setOpenAuctionDialog(true);
    } else {
      history.push(
        `${routes.customer.login}?redirect=${history.location.pathname}${history.location.search}?show-create-auction=true`
      );
    }
  };

  const handleClose = () => {
    setOpenAuctionDialog(false);
    history.push(`${history.location.pathname}`);
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        size={createFromShopDetails ? 'large' : 'small'}
        endIcon={<GavelOutlinedIcon />}
        disabled={isDisabled}
        variant={createFromShopDetails ? 'contained' : 'outlined'}
        className={
          createFromShopDetails
            ? classes.shopDetailAuctionButton
            : classes.auctionButton
        }>
        Create Auction
      </Button>

      <AuctionFormModal
        open={openAuctionDialog}
        handleClose={handleClose}
        createdFromShop={false}
      />
    </div>
  );
};

export default CreateAuctionButton;
