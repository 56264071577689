import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import RenderAddress from 'views/Review/components/RenderAddress';
import { useAmplifyAuth } from 'context';
import { API, graphqlOperation } from 'aws-amplify';
import { listAddresss, getAddress } from 'graphql/customQueries';
import AddressModal from 'views/Review/components/AddressModal';
import { getOrderTotal } from 'common/utilFunctions';
import { useOrders } from 'context/order/ordersContext';
import { updateUserDetail } from 'graphql/mutations';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import {
  getDistanceBetweenPostcodesInMiles,
  shouldApplyCollectionOrDeliveryFee
} from 'common/utilFunctions';

export default function EditDeliveryOptionDialog({
  order,
  open = false,
  handleModalClose
}) {
  const {
    state: { user },
    _refreshUser
  } = useAmplifyAuth();
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [deliveryMethod, setDeliveryMethod] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [deliveryHrs, setDeliveryHrs] = useState([]);
  const [deliverySlot, setDeliverySlot] = useState(null);
  const [deliveryTime, setDeliveryTime] = useState(null);
  const [isDefaultAddressChange, setIsDefaultAddressChange] = useState({
    isChange: false,
    option: ''
  });
  const [addressList, setAddressList] = useState({
    loading: false,
    items: []
  });
  const [collectionTime, setCollectionTime] = useState(null);
  const [collectionSlot, setCollectionSlot] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [orderedShop, setOrderedShop] = useState(null);
  const [workingHoursMenuAnchor, setWorkingHoursMenuAnchor] = useState(null);
  const [show, setShow] = useState(true);
  const [edit, setEdit] = useState(false);
  const { dispatch } = useOrders();
  const [addressDistance, setAddressDistance] = useState({
    collection: 0,
    delivery: 0
  });
  const isPickUpOrder = order?.delivery.address ? false : true;
  const isFreeDeliveryAvl =
    order?.total >= order?.currentShopFees?.minPriceForFree;
  const validDeliveryMethod =
    (deliveryMethod === 'Delivery' &&
      (orderedShop?.distanceRange
        ? addressDistance.delivery < orderedShop?.distanceRange
        : true) &&
      !!deliveryTime) ||
    deliveryMethod === 'Pickup' ||
    order?.delivery?.type === 'asap';

  const changeAddress = (type = 'Delivery') => {
    setShowAddressModal(true);
    setAddressList({ loading: true, items: [] });
    if (user?.username) {
      API.graphql(
        graphqlOperation(listAddresss, {
          filter: { userID: { eq: user.username } },
          limit: 1000
        })
      ).then(({ data }) => {
        const _addressList = data.listAddresss.items;
        let items = [];
        if (_addressList.length > 0) {
          items = _addressList.filter((a) => !a._deleted);
        }
        setAddressList({ loading: false, items });
      });
    }
  };

  const typeChangeHandler = (e, newVal, setValue = () => {}) => {
    setValue(newVal);
    if (newVal === 'Pickup') {
      setDeliverySlot(null);
      setDeliveryTime(null);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (open) {
      if (order?.delivery?.address) {
        const orderAddress = JSON.parse(order?.delivery?.address);
        mounted && setDeliveryAddress(orderAddress);
      } else if (user?.defaultAddress) {
        API.graphql(
          graphqlOperation(getAddress, { id: user.defaultAddress })
        ).then(({ data }) => {
          const _address = data?.getAddress || [];
          mounted && setDeliveryAddress(_address);
        });
      }

      if (order?.collection?.date) {
        setCollectionTime(moment(order.collection.date));
      }
      if (order?.shop) {
        setOrderedShop(order.shop);
        setDeliveryHrs(order.shop.deliveryHrs);
      }
    }

    return () => {
      mounted = false;
    };
  }, [user, order, open]);

  useEffect(() => {
    const collectionAddress = JSON.parse(order?.collection?.address || null);

    if (orderedShop?.location && collectionAddress?.postcode) {
      getDistanceBetweenPostcodesInMiles(
        orderedShop.location,
        collectionAddress.postcode
      ).then((distance) => {
        setAddressDistance((prev) => ({
          ...prev,
          collection: distance
        }));
      });
    }

    if (
      orderedShop?.distanceRange &&
      orderedShop?.location &&
      deliveryAddress?.postcode &&
      deliveryMethod === 'Delivery'
    ) {
      getDistanceBetweenPostcodesInMiles(
        orderedShop.location,
        deliveryAddress.postcode
      ).then((distance) => {
        setAddressDistance((prev) => ({
          ...prev,
          delivery: distance
        }));

        if (distance > orderedShop.distanceRange) {
          enqueueSnackbar(
            'Your location is beyond our service area. Please get in touch with the shop for more details.',
            {
              variant: 'error',
              persist: true,
              preventDuplicate: true
            }
          );
        }
      });
    }
    if (deliveryMethod === 'Pickup') {
      setAddressDistance((prev) => ({
        ...prev,
        delivery: 0
      }));
    }
  }, [deliveryAddress, deliveryMethod]);

  const handleClose = () => {
    handleModalClose();
    setDeliveryMethod('');
    setDeliverySlot(null);
    setDeliveryTime(null);
    setAddressDistance({ collection: 0, delivery: 0 });
  };

  const handleCloseAddressModal = () => setShowAddressModal(false);

  const handleEdit = () => {
    setEdit((prevState) => !prevState);
    setShow(false);
  };

  const defaultAddressHandler = async (id) => {
    const snackBar = enqueueSnackbar('Updating default address', {
      variant: 'info',
      persist: true,
      preventDuplicate: true
    });
    try {
      await API.graphql(
        graphqlOperation(updateUserDetail, { defaultAddress: id })
      );
      _refreshUser();
    } catch (error) {
      console.error('Something went wrong', error);
    } finally {
      closeSnackbar(snackBar);
    }
  };

  const handleSubmit = () => {
    const delivery = {};
    delivery.type = order.delivery?.type;
    delivery.date = deliveryTime?.format('YYYY-MM-DDThh:mm:ss.sssZ') || null;
    delivery.time = deliverySlot || null;
    delivery.address =
      deliveryMethod === 'Delivery' ? JSON.stringify(deliveryAddress) : null;

    const { standardDeliveryFee, minPriceForFree } = order.currentShopFees;
    const subTotal = getOrderTotal({ orderList: order.orderList || [] });
    let total = subTotal;
    let collectionAndDeliveryFee =
      order?.collection?.type === 'asap'
        ? order?.currentShopFees?.AsapDeliveryFee
        : 0;
    const isCollectionFeeRequired = shouldApplyCollectionOrDeliveryFee(
      subTotal,
      minPriceForFree,
      addressDistance.collection,
      orderedShop?.distanceRangeForFree
    );
    const isDeliveryFeeRequired = shouldApplyCollectionOrDeliveryFee(
      subTotal,
      minPriceForFree,
      addressDistance.delivery,
      orderedShop?.distanceRangeForFree
    );

    if (isCollectionFeeRequired) {
      collectionAndDeliveryFee = collectionAndDeliveryFee + standardDeliveryFee;
    }
    if (deliveryMethod === 'Delivery' && isDeliveryFeeRequired) {
      collectionAndDeliveryFee += standardDeliveryFee;
    }
    total += collectionAndDeliveryFee;

    const updatedOrder = {
      ...order,
      delivery,
      collectionAndDeliveryFee:
        Math.round(collectionAndDeliveryFee * 100) / 100,
      total: Math.round(total * 100) / 100
    };
    dispatch({
      type: 'updateOrder',
      payload: { order: updatedOrder, successCallback: handleClose }
    });
    if (isDefaultAddressChange.isChange) {
      defaultAddressHandler(deliveryAddress.id);
    }
  };

  const isASAPOrder = order?.delivery?.type === 'asap';

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-edit-delivery"
        aria-describedby="alert-dialog-edit-delivery">
        <DialogTitle id="alert-dialog-slide-title">
          Edit Delivery Options
        </DialogTitle>
        <DialogContent>
          <RenderAddress
            type="Delivery"
            value={deliveryMethod}
            setValue={setDeliveryMethod}
            typeOfOrder="Schedule"
            typeChangeHandler={typeChangeHandler}
            deliveryAddress={deliveryAddress}
            changeAddress={changeAddress}
            isASAP={isASAPOrder}
            deliveryHrs={deliveryHrs}
            setDeliverySlot={setDeliverySlot}
            setDeliveryTime={setDeliveryTime}
            setCollectionSlot={setCollectionSlot}
            setCollectionTime={setCollectionTime}
            collectionTime={collectionTime}
            collectionSlot={collectionSlot}
            user={user}
            setIsDefaultAddressChange={setIsDefaultAddressChange}
            selectedShop={orderedShop}
            setWorkingHoursMenuAnchor={setWorkingHoursMenuAnchor}
            workingHoursMenuAnchor={workingHoursMenuAnchor}
            instructions={{
              collection: null,
              delivery: null
            }}
            options={['Delivery', 'Pickup']}
            isEditing={true}
            isPickUpOrder={isPickUpOrder}
            isFreeDeliveryAvl={isFreeDeliveryAvl}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={!validDeliveryMethod}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <AddressModal
        open={showAddressModal}
        handleClose={handleCloseAddressModal}
        currAddressType="Delivery"
        deliveryAddress={deliveryAddress}
        addressList={addressList}
        handleEdit={handleEdit}
        show={show}
        edit={edit}
        setDeliveryAddress={setDeliveryAddress}
        setOpen={setShowAddressModal}
      />
    </>
  );
}
