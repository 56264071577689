import React, { useContext } from 'react';
import { useAmplifyAuth } from 'context';
import { CartContext } from 'context/cart/cartContext';
import { validateCartCoupons } from 'common/validators';
import { calculateVariablePrice } from 'common/utilFunctions';
import { QuantityUpDownButtons } from 'components/molecules';

const OrderUpDownButtons = ({
  className = '',
  item = {},
  disabled = false
}) => {
  const { cart, setCart } = useContext(CartContext);
  const {
    state: { user }
  } = useAmplifyAuth();

  const getItemQuantity = () =>
    cart.items.find((cartItem) => cartItem.id === item.id)?.quantity || 0;

  const updateCart = (q) => () => {
    let newCart = {
      ...cart,
      items: cart.items.some((cartItem) => cartItem.id === item.id)
        ? cart.items
            .map((cartItem) =>
              cartItem.id === item.id
                ? {
                    ...cartItem,
                    quantity: cartItem.quantity + parseInt(q)
                  }
                : cartItem
            )
            .filter((item) => item.quantity > 0)
        : [...cart.items, { ...item, quantity: 1 }]
    };
    newCart.variablePrice = calculateVariablePrice(
      newCart.items.filter((_item) => !_item.price)
    );
    newCart = validateCartCoupons(newCart);

    localStorage.setItem(
      'cart',
      JSON.stringify({ username: user?.email || '', item: newCart })
    );
    setCart(newCart);
  };

  return (
    <QuantityUpDownButtons
      disabled={disabled}
      getItemQuantity={getItemQuantity}
      increaseQuantity={updateCart(1)}
      decreaseQuantity={updateCart(-1)}
      className={className}
    />
  );
};

export default OrderUpDownButtons;
