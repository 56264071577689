import React, { useState, useEffect } from 'react';
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const SubSideNavBar = ({
  children,
  currentItem = { title: '', description: '' }
}) => {
  const [showContent, setShowContent] = useState(false);
  const md = useMediaQuery(useTheme().breakpoints.up('md'));

  useEffect(() => {
    setShowContent(false);
  }, [currentItem]);

  const handleClick = () => setShowContent(!showContent);

  return md ? (
    children
  ) : (
    <List
      style={{
        width: '100%'
      }}>
      <ListItem
        button
        onClick={handleClick}
        style={{
          backgroundColor: '#fff'
        }}>
        {currentItem.icon && <ListItemIcon>{currentItem.icon}</ListItemIcon>}
        <ListItemText
          primary={currentItem.title}
          secondary={currentItem.description}
        />
        {showContent ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={showContent} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </List>
  );
};

export default SubSideNavBar;
