import React from 'react';
import { Avatar, Grid, Typography, makeStyles } from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import MailIcon from '@material-ui/icons/Mail';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { googleFormLinks } from 'common/constants';

const useStyles = makeStyles({
  rootContainer: {
    marginBottom: '2rem'
  },
  textContentOne: {
    textAlign: 'justify',
    fontSize: '18px',
    lineHeight: '1.8rem',
    marginBottom: '1rem'
  },
  textContentTwo: {
    textAlign: 'justify',
    fontSize: '18px',
    lineHeight: '1.8rem'
  },
  linksContainer: {
    marginTop: '2rem',
    gap: '2rem'
  },
  linksCard: {
    borderRadius: '8px',
    backgroundColor: '#edd242'
  },
  linkText: {
    fontSize: '16px',
    lineHeight: '1.2rem',
    fontWeight: 600,
    marginBottom: '0.5rem'
  },
  linkIcon: {
    backgroundColor: '#1f6893'
  },
  link: {
    fontSize: '14px'
  },
  textContentThree: {
    fontSize: '20px',
    textAlign: 'center',
    margin: '2rem 0 1rem',
    lineHeight: '1.5rem',
    color: '#1f6893'
  }
});

const AboutUsConclusion = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      justify="center"
      alignItems="center"
      xs={12}
      className={classes.rootContainer}>
      <Grid container item>
        <Typography className={classes.textContentOne}>
          We understand that convenience is key, both for customers and shops.
          That's why Launder-it also provides a collection and delivery service,
          allowing shops to offer door-to-door laundry solutions to their
          customers. By utilizing our platform, shops can enhance their services
          and deliver a seamless and convenient experience to their valued
          customers. Our aim is to empower shops with the tools they need to
          excel in customer satisfaction while embracing the utmost convenience
          for all parties involved.
        </Typography>
        <Typography className={classes.textContentTwo}>
          At Launder-it, we are committed to connecting customers with reliable
          laundry shops, simplifying the laundry process, and creating a win-win
          situation for all. Join us today and experience the future of laundry
          services!
        </Typography>

        <Typography className={classes.textContentThree}>
          Join us at launder-it.co.uk and embark on a journey that will propel
          your company to new heights. Together, we can reshape the dry-cleaning
          industry, revolutionize customer experiences, and build a stronger,
          more vibrant community.
        </Typography>
      </Grid>

      <Grid
        container
        item
        justify="space-between"
        className={classes.linksContainer}>
        <Grid
          container
          item
          md={2}
          xs={12}
          spacing={2}
          className={classes.linksCard}>
          <Grid container item xs={4} justify="center">
            <a href="tel:07771038880">
              <Avatar className={classes.linkIcon}>
                <CallIcon />
              </Avatar>
            </a>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.linkText}>Call Us</Typography>
            <Typography className={classes.link}>07771038880</Typography>
          </Grid>
        </Grid>

        <Grid
          container
          item
          md={2}
          xs={12}
          spacing={2}
          className={classes.linksCard}>
          <Grid container item xs={4} justify="center">
            <a href={`mailTo:info@launder-it.co.uk`}>
              <Avatar className={classes.linkIcon}>
                <MailIcon />
              </Avatar>
            </a>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.linkText}>Email Us</Typography>
            <Typography className={classes.link}>
              info@launder-it.co.uk
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          item
          alignItems="center"
          md={2}
          xs={12}
          spacing={2}
          className={classes.linksCard}>
          <Grid container item xs={4} justify="center">
            <a href={googleFormLinks.joinUs} target="_blank" rel="noreferrer">
              <Avatar className={classes.linkIcon}>
                <PersonAddIcon />
              </Avatar>
            </a>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.linkText}>Join Us</Typography>
          </Grid>
        </Grid>

        <Grid
          container
          item
          md={2}
          xs={12}
          alignItems="center"
          spacing={2}
          className={classes.linksCard}>
          <Grid container item xs={4} justify="center">
            <a href="/business-with-us" target="_blank" rel="noreferrer">
              <Avatar className={classes.linkIcon}>
                <BusinessCenterIcon />
              </Avatar>
            </a>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.linkText}>
              Business With Us
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AboutUsConclusion;
