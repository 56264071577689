import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
    backgroundColor: ' #C4C4C4',
    height: 150
  }
}));

function StaffUploadedDocumentation() {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid container justify="center" alignItems="center">
              Staff Id
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid container justify="center" alignItems="center">
              Staff Proof
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default StaffUploadedDocumentation;
