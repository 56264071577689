import { makeStyles } from '@material-ui/core/styles';

export const useAdTemplateStyles = makeStyles((theme) => ({
  shopListAdsRootStyles: {
    padding: '2px',
    borderRadius: '4px',
    height: '100%',
    width: '100%',
    '&:hover': {
      backgroundColor: '#eee'
    }
  },
  shopListAdsImage: {
    height: 'calc(100% - 15px)'
  }
}));
