import { API, graphqlOperation } from 'aws-amplify';
import {
  createAuctionItem as createAnAuctionItem,
  updateAuction as updateAnAuction,
  createBid as createABid,
  createBidItem as createABidItem,
  deleteAuction as deleteAnAuction,
  deleteAuctionItem as deleteAnAuctionItem,
  createAuctionShop as createAnAuctionShop
} from 'graphql/mutations';
import {
  listAuctions,
  getAuctionByUserID,
  getAuctionByShop
} from './auctionsQueries';
import { createAnAuction } from './auctionsMutations';
import { getShopByPostalCode } from 'context/shop/shopsQueries';

/* Auction  APIs */
export const loadAuctions = (payload = {}) =>
  new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(listAuctions, payload))
      .then((data) => resolve(data.data.listAuctions))
      .catch((err) => reject(err));
  });

export const loadAuctionsByUser = (payload = {}) =>
  new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(getAuctionByUserID, payload))
      .then((data) => resolve(data.data.getAuctionByUserID))
      .catch((err) => reject(err));
  });

export const loadAuctionsByShop = (payload = {}) =>
  new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(getAuctionByShop, payload))
      .then((data) => resolve(data.data.getAuctionByShop))
      .catch((err) => reject(err));
  });

export const createAuction = (input) =>
  new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(createAnAuction, { ...input }))
      .then((data) => resolve(data.data.createAnAuction))
      .catch((err) => reject(err));
  });

export const createAuctionItem = (input) =>
  new Promise((resolve, reject) => {
    API.graphql(
      graphqlOperation(createAnAuctionItem, {
        input
      })
    )
      .then((data) => resolve(data.data.createAuctionItem))
      .catch((err) => reject(err));
  });

export const createBid = (input) =>
  new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(createABid, { input }))
      .then((data) => resolve(data.data.createBid))
      .catch((err) => reject(err));
  });

export const createBidItem = (input) =>
  new Promise((resolve, reject) => {
    API.graphql(
      graphqlOperation(createABidItem, {
        input
      })
    )
      .then((data) => resolve(data.data.createBidItem))
      .catch((err) => reject(err));
  });

export const updateAuction = (input) =>
  new Promise((resolve, reject) => {
    API.graphql(
      graphqlOperation(updateAnAuction, {
        input
      })
    )
      .then((data) => resolve(data.data.updateAuction))
      .catch((err) => reject(err));
  });

export const deleteAuction = (input) =>
  new Promise((resolve, reject) => {
    API.graphql(
      graphqlOperation(deleteAnAuction, {
        input
      })
    )
      .then((data) => resolve(data.data.deleteAuction))
      .catch((err) => reject(err));
  });

export const deleteAuctionItem = (input) =>
  new Promise((resolve, reject) => {
    API.graphql(
      graphqlOperation(deleteAnAuctionItem, {
        input
      })
    )
      .then((data) => resolve(data.data.deleteAuctionItem))
      .catch((err) => reject(err));
  });

export const loadShops = (payload = {}) =>
  new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(getShopByPostalCode, payload))
      .then((data) => resolve(data.data.getShopByPostalCode))
      .catch((err) => reject(err));
  });

export const createAuctionShop = (input) =>
  new Promise((resolve, reject) => {
    API.graphql(
      graphqlOperation(createAnAuctionShop, {
        input
      })
    )
      .then((data) => resolve(data.data.createAuctionShop))
      .catch((err) => reject(err));
  });
