import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import TinySlider from 'tiny-slider-react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, IconButton, useMediaQuery } from '@material-ui/core';
import { useServices } from 'views/Services/serviceContext';
import { useAds } from 'context/ads/adsContext';
import { useShops } from 'context/shop/shopsContext';
import { routes } from 'common/constants';
import { initialFilters, tinySliderSettings, AD_PER_SLIDE } from './constants';
import { getStringAsSlug } from 'common/utilFunctions';
import { useLoader } from 'layouts/loaderContext';
import { NavigationBreadcrumbs } from 'components/organisms';
import { ShopListAdsSkeleton } from 'components/skeletons';
import { AdTemplates } from 'components';
import { ShopsViews } from './components';
import ReactGA from 'react-ga4';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import 'tiny-slider/dist/tiny-slider.css';
import './assets/carousel-styles.css';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {},
  box: {
    height: 200,
    backgroundColor: theme.palette.captionBox
  },
  typography: {
    color: theme.palette.white
  },
  caption: {
    padding: theme.spacing(5, 20)
  },
  subCaption: {
    padding: theme.spacing(5, 20)
  },
  selectGroup: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    width: '133px',
    height: '45px',
    margin: theme.spacing(1),
    borderRadius: '5em',
    backgroundColor: theme.palette.select,
    color: theme.palette.black
  },
  offerShopsContainer: {
    backgroundColor: theme.palette.primary.main,
    padding: '20px 5px',
    [theme.breakpoints.up('sm')]: {
      padding: '25px 10px'
    }
  },
  scrollBtn: {
    maxHeight: '26px',
    maxWidth: '26px',
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.white,
      color: theme.palette.primary.main
    }
  },
  navigationBreadcrumbWrapper: {
    maxWidth: '100%',
    margin: '0 auto',
    padding: '0 1.5rem',
    [theme.breakpoints.up('md')]: {
      padding: 0,
      maxWidth: '90%'
    }
  },
  // Shop List ads card styles
  shopListAdCard: {
    maxHeight: '18rem',
    minHeight: '18rem',
    maxWidth: '18rem',
    [theme.breakpoints.up('sm')]: {
      maxHeight: '15rem',
      minHeight: '15rem',
      maxWidth: '15rem'
    },
    [theme.breakpoints.up('lg')]: {
      maxHeight: '20rem',
      minHeight: '20rem',
      maxWidth: '24rem',
      minWidth: '24rem'
    }
  }
}));

const curTime = new Date().getHours() * 100 + new Date().getMinutes(); // current time in working hours time format

const ShopList = ({ className }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { loading } = useLoader();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const currentViewWidth = md ? 'md' : sm ? 'sm' : 'xs';
  const sliderRef = useRef(null);
  let { postCode, service = '' } = useParams();
  const history = useHistory();
  const [shopFilters, setShopFilters] = useState(initialFilters);
  const [showResetFiltersBtn, setShowResetFiltersBtn] = useState(false);
  const [isShopsLoading, setIsShopsLoading] = useState(false);
  const { services = [], dispatch: dispatchService } = useServices();
  const { shopListAds, dispatch: dispatchAds } = useAds();
  const { shops = [], dispatch } = useShops();

  let filteredShops = shops?.filter((shop) => !!shop) || [];
  if (shopFilters.shops.length > 0)
    filteredShops = filteredShops.filter(
      (shop) =>
        shop.status === 'onhold' ||
        (shop.isLive &&
          shop?.workHrs.some(
            (day) =>
              day.openDay === new Date().getDay() &&
              day.openTime < curTime &&
              (!day.closeTime || day.closeTime > curTime)
          ))
    );

  const filteredAds = shopListAds?.filter((item) =>
    item.type === 'shop'
      ? filteredShops.some((shop) => shop?.id === item?.shopID)
      : true
  );

  const serviceOnClick = (serviceID) =>
    setShopFilters({
      ...shopFilters,
      services: serviceID === 'all' ? [] : [serviceID]
    });

  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({
      hitType: 'pageview',
      page: `${history.location.pathname}${history.location.search}`,
      title: 'shop search results page'
    });

    // getting services
    if (services?.length === 0) dispatchService({ type: 'getService' });
  }, []);

  useEffect(() => {
    let mounted = true;
    dispatch({
      type: 'getShopsByPostCode',
      payload: {
        postalCode: postCode,
        // range: shopFilters.distance?.at(0) || 2,
        showSnackbar: true,
        setExtraLoading: (data) => mounted && setIsShopsLoading(data)
      }
    });
    return () => {
      mounted = false;
    };
  }, [shopFilters.distance, postCode]);

  useEffect(() => {
    if (shopListAds.length === 0) {
      dispatchAds({ type: 'getCurrentOpenAds', payload: { adsType: 'list' } });
    }
  }, []);

  useEffect(() => {
    let mounted = true;
    if (shopFilters.services.length > 0 || shopFilters.shops.length > 0)
      mounted && setShowResetFiltersBtn(true);
    else mounted && setShowResetFiltersBtn(false);
    return () => {
      mounted = false;
    };
  }, [shopFilters]);

  const removeServiceFromURL = () =>
    history.replace(routes.customer.shops.replace(':postCode', postCode));

  const resetFilters = () => {
    if (!!service) removeServiceFromURL();
    setShopFilters(initialFilters);
    setShowResetFiltersBtn(false);
  };

  const onGoTo = (dir) => sliderRef.current.slider.goTo(dir);

  const _setShopFilters = (data) => {
    if (!!service) removeServiceFromURL();
    return setShopFilters(data);
  };

  useEffect(() => {
    let mounted = true;
    if (!!service) {
      const serviceId =
        services.find((_service) => getStringAsSlug(_service.name) === service)
          ?.id || '';
      !!serviceId &&
        mounted &&
        setShopFilters({
          ...shopFilters,
          services: [serviceId]
        });
    }

    return () => {
      mounted = false;
    };
  }, [services, service]);

  return (
    <div className={clsx(classes.root, className)}>
      <Helmet>
        <meta
          name="description"
          content="When you choose us for your dry cleaning needs, you'll enjoy additional perks like cashback and exclusive deals. Our top cashback offers and coupons ensure that you're getting the most value for your money, while our discount options make it easy to save even more.
          Don't settle for subpar dry cleaning services that will leave your clothes looking lackluster. Choose us for the best quality, prices, and deals around. Contact us today to learn more about our services and to schedule your first appointment!"
        />
        <meta
          name="keywords"
          content="launder-it, launder it, Dry cleaning, Cheap prices, Cashback, Deals, Top cashback, Coupon, Discount"
        />
      </Helmet>

      <Grid container style={{ borderTop: '1px solid #0000001a' }}>
        <Grid item xs={12} className={classes.navigationBreadcrumbWrapper}>
          <NavigationBreadcrumbs
            currentLink="Shop List"
            style={{
              margin: '10px 0 0',
              padding: '0 0 10px 0'
            }}
          />
        </Grid>
      </Grid>
      {!(loading || isShopsLoading) ? (
        <>
          {filteredAds.length > 0 && (
            <Grid container className={classes.offerShopsContainer}>
              <Grid
                container
                item
                justify="flex-start"
                alignItems="center"
                style={{ width: 40 }}>
                <IconButton
                  onClick={() => onGoTo('prev')}
                  className={classes.scrollBtn}>
                  <ArrowBackIosIcon
                    fontSize="small"
                    style={{ fontSize: '10px' }}
                  />
                </IconButton>
              </Grid>
              <Grid
                container
                item
                justify="center"
                alignItems="center"
                style={{
                  maxWidth: 'calc(100% - 80px)',
                  overflow: 'hidden',
                  textAlign: 'center'
                }}>
                <TinySlider settings={tinySliderSettings} ref={sliderRef}>
                  {[...Array(filteredAds.length).keys()]
                    .filter(
                      (item) =>
                        item % AD_PER_SLIDE[currentViewWidth].count === 0
                    )
                    .map((index) => (
                      <div
                        style={{
                          opacity: 1
                        }}
                        key={`shops-list-ads-slides-${index}`}
                        className="tns-lazy-img">
                        <Grid container justify="space-around">
                          {AD_PER_SLIDE[currentViewWidth].indexArr
                            .map((item) => {
                              const newIndex = index + item;
                              return newIndex >= filteredAds.length
                                ? filteredAds[newIndex - filteredAds.length]
                                : filteredAds[newIndex];
                            })
                            .filter((item) => item)
                            .map((ad, adIndex) => (
                              <AdTemplates
                                key={`shops-list-ads-items-${index + adIndex}`}
                                ad={ad}
                                showAsButton={true}
                                cardStyles={classes.shopListAdCard}
                              />
                            ))}
                        </Grid>
                      </div>
                    ))}
                </TinySlider>
              </Grid>
              <Grid
                container
                item
                justify="flex-end"
                alignItems="center"
                style={{ width: 40 }}>
                <IconButton
                  onClick={() => onGoTo('next')}
                  className={classes.scrollBtn}>
                  <ArrowForwardIosIcon style={{ fontSize: '10px' }} />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <Grid container style={{ marginTop: 8 }}>
          <ShopListAdsSkeleton />
        </Grid>
      )}

      <ShopsViews
        shops={filteredShops}
        shopFilters={shopFilters}
        setShopFilters={_setShopFilters}
        isShopsLoading={loading || isShopsLoading}
        resetFilters={resetFilters}
        showResetFiltersBtn={showResetFiltersBtn}
      />
    </div>
  );
};

export default ShopList;
