import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  Switch,
  Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CollectionAndDeliveryHours from 'views/LaundryShop/components/CollectionAndDeliveryHours';
import { makeStyles } from '@material-ui/core/styles';
import WorkingHours from 'views/Shops/components/ShopDetails/WorkingHours';
import ShopDetails from 'views/LaundryShop/components/ShopDetails';
import { shopFormFields } from 'common/constants';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { SelectPaymentAndLanguage } from './components';
import { API, graphqlOperation } from 'aws-amplify';
import { getLatLngByPostCode } from 'graphql/queries';
import Map from 'views/Shops/components/ShopDetails/map';
import { useSnackbar } from 'notistack';
import { useLoader } from 'layouts/loaderContext';
import { addShop } from 'context/shop/shopsMutations';
import FormSubmitted from './components/FormSubmitted';
import { customSearchShops } from 'graphql/queries';

const INITIAL_SHOP_STATE = {
  country: '',
  name: '',
  contactName: '',
  phoneNumber: '',
  address: '',
  email: '',
  postcode: '',
  location: {
    lat: null,
    lng: null
  },
  town: '',
  websiteLink: '',
  variant: 'regular',
  workHrs: [],
  collectionHrs: [],
  deliveryHrs: [],
  standardDeliveryFee: 0,
  distanceRange: '',
  minPriceForFree: '',
  distanceRangeForFree: '',
  enableAsapDelivery: false,
  AsapDeliveryFee: '',
  paymentMethods: [],
  language: [],
  status: 'new'
};

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '90%',
    margin: '1rem auto',
    padding: '1rem'
  },
  headingText: {
    textAlign: 'center',
    width: '100%',
    marginBottom: '2rem',
    color: '#143a69',
    fontWeight: 600,
    borderBottom: '1px solid #ccc',
    paddingBottom: '1rem'
  },
  noteText: {
    padding: '16px',
    fontWeight: 600,
    textAlign: 'center',
    color: '#444',
    fontSize: '16px'
  },
  bottomMargin: {
    marginBottom: '1rem'
  },
  formControlLabel: {
    fontSize: 16
  },
  darkColor: {
    color: '#444'
  },
  colAndDelNote: {
    padding: '0 16px 5px',
    color: '#1f6893'
  }
}));

const getStepContent = (
  stepIndex,
  setActiveStep,
  classes,
  selectedShop,
  setSelectedShop,
  enqueueSnackbar,
  closeSnackbar,
  setLoading,
  setFormSubmitted,
  validateFormData
) => {
  const onlineShopHandler = () =>
    setSelectedShop((prev) => ({
      ...prev,
      variant: prev.variant === 'online' ? 'regular' : 'online'
    }));

  const handleChange = (event, newValue) => {
    let value;
    const currentAttribute = event.target.name,
      updatedValues = {};
    // if (currentAttribute === 'phoneNumber')
    //   value = event.target.value.replace(/[^0-9]/g, '');
    if (currentAttribute === 'country' || currentAttribute === 'town')
      value = event.target.value;
    else if (currentAttribute === 'variant') {
      value = newValue ? 'online' : 'regular';
    } else value = newValue || event.target.value;

    setSelectedShop({
      ...selectedShop,
      [currentAttribute]: value,
      ...updatedValues
    });
  };

  const handlePostCodeOnBlur = () => {
    // need to update the lat and lng in selectedShop by using the new postcode
    API.graphql(
      graphqlOperation(getLatLngByPostCode, {
        postalCode: selectedShop.postcode
      })
    )
      .then((data) => {
        setSelectedShop({
          ...selectedShop,
          location: {
            lat: data.data.getLatLngByPostCode.lat,
            lng: data.data.getLatLngByPostCode.lng
          }
        });
      })
      .catch((e) => console.error('postcode data not found', e));
  };

  const handleNumberChange = (e) => {
    const re = /^[0-9]+\.?[0-9]{0,2}$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setSelectedShop({
        ...selectedShop,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleSubmit = async () => {
    if (!validateFormData()) {
      enqueueSnackbar('Please ensure all required details are entered.', {
        variant: 'warning',
        persist: false
      });
      return;
    }
    let sBar = enqueueSnackbar(
      'Initiating the shop self Onboard Request Process...',
      {
        variant: 'info',
        persist: true
      }
    );

    setLoading(true);
    try {
      const payload = {
        ...selectedShop,
        location: {
          lat: selectedShop.variant ==='online' ? 0 : selectedShop.location.lat,
          lng: selectedShop.variant ==='online' ? 0 : selectedShop.location.lng,
        },
        standardDeliveryFee: selectedShop.standardDeliveryFee
          ? parseFloat(selectedShop.standardDeliveryFee)
          : 0,
        distanceRange: selectedShop.distanceRange
          ? parseFloat(selectedShop.distanceRange)
          : null,
        distanceRangeForFree: selectedShop.distanceRangeForFree
          ? parseFloat(selectedShop.distanceRangeForFree)
          : null,
        minPriceForFree: selectedShop.minPriceForFree
          ? parseFloat(selectedShop.minPriceForFree)
          : 0,
        AsapDeliveryFee: selectedShop.AsapDeliveryFee
          ? selectedShop.AsapDeliveryFee
          : null
      };
      await API.graphql(graphqlOperation(addShop, { input: payload }));
      setSelectedShop({});
      setFormSubmitted(true);
      enqueueSnackbar('Your request has been successfully generated.', {
        variant: 'success',
        preventDuplicate: true,
        autoHideDuration: 1000
      });
    } catch (error) {
      console.log(error);
    } finally {
      closeSnackbar(sBar);
      setLoading(false);
    }
  };

  const handleReset = () => {
    setSelectedShop(INITIAL_SHOP_STATE);
    setActiveStep(0);
  };

  switch (stepIndex) {
    case 0:
      return (
        <>
          <Grid container item xs={12} alignItems="center">
            <FormControlLabel
              classes={{ label: classes.formControlLabel }}
              label="Is this an online shop? (An online shop is one that lacks a physical location.)"
              labelPlacement="start"
              control={
                <Switch
                  checked={selectedShop.variant === 'online' ? true : false}
                  onChange={onlineShopHandler}
                  color="primary"
                  name="variant"
                  inputProps={{ 'aria-label': 'shop-variant-switch' }}
                />
              }
            />
          </Grid>
          <Grid className={classes.bottomMargin}>
            <ShopDetails
              fields={shopFormFields}
              formErrors={{}}
              selectedShop={selectedShop}
              handleChange={handleChange}
              handlePostCodeOnBlur={handlePostCodeOnBlur}
              handleSubmit={() => {}}
              disablePostcode={false}
              isSelfOnboarding={true}
            />
          </Grid>
        </>
      );
    case 1:
      return (
        <Card className={classes.bottomMargin}>
          <CardHeader
            subheader={
              <span className={classes.darkColor}>
                Pin for your business location.
              </span>
            }
            title="Mark my shop in Google Map"
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <Map state={selectedShop} update={setSelectedShop} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      );
    case 2:
      return (
        <>
          <WorkingHours
            sorryMsg="Closed"
            subheader="The information about the shop working days."
            title="Shop Open Hours"
            type="workHrs"
            hours={selectedShop ? selectedShop['workHrs'] : []}
            selectedShop={selectedShop}
            setSelectedShop={setSelectedShop}
          />
          <Typography variant="h5" className={classes.colAndDelNote}>
            Note: If collection and delivery services are offered, please
            provide the corresponding timings for your shop
          </Typography>
          <CollectionAndDeliveryHours
            selectedShop={selectedShop}
            setSelectedShop={setSelectedShop}
            handleChange={() => {}}
            handleSubmit={() => {}}
            enablePriceText={false}
            setEnablePriceText={() => {}}
            handleNumberChange={handleNumberChange}
            isSelfOnboarding={true}
          />
        </>
      );
    case 3:
      return (
        <>
          <SelectPaymentAndLanguage
            selectedShop={selectedShop}
            setSelectedShop={setSelectedShop}
            handleChange={handleChange}
            handleNumberChange={handleNumberChange}
          />
          <Grid container justify="center">
            <CardActions>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleReset}>
                Reset
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}>
                Submit
              </Button>
            </CardActions>
          </Grid>
        </>
      );

    default:
      return 'Unknown stepIndex';
  }
};

function getSteps() {
  return [
    'Shop information',
    'Geographic location',
    'Business timings',
    'Pricing and details'
  ];
}

const SelfOnboardingShopForm = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { setLoading } = useLoader();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedShop, setSelectedShop] = useState(INITIAL_SHOP_STATE);
  const steps = getSteps();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  const handleNext = async () => {
    if (!validateFormData()) {
      const warningMessage =
        activeStep === 0
          ? 'Please ensure all shop details are entered.'
          : activeStep === 1
          ? 'Please select your shop location.'
          : activeStep === 2
          ? 'Please provide your shop operating hours.'
          : 'Please ensure all required details are entered.';

      enqueueSnackbar(warningMessage, {
        variant: 'warning',
        persist: false
      });
      return;
    }
    if (activeStep === 0) {
      try {
        setLoading(true);
        enqueueSnackbar('Verifying the presence of an existing shop...', {
          variant: 'info',
          preventDuplicate: true,
          autoHideDuration: 2000
        });
        const resp = await API.graphql(
          graphqlOperation(customSearchShops, {
            searchString: selectedShop.name
          })
        );
        const shopsWithSameName = resp.data.customSearchShops.items || [];
        const isShopAlreadyExist = shopsWithSameName.some((shop) => {
          return (
            shop?.name?.split(' ').join('').toLowerCase() ===
              selectedShop.name?.split(' ').join('').toLowerCase() &&
            // checking shop postcode
            (selectedShop.variant === 'regular'
              ? shop?.postcode?.split(' ').join('').toLowerCase() ===
                  selectedShop.postcode?.split(' ').join('').toLowerCase() &&
                shop?.address?.trim().toLowerCase() ===
                  selectedShop.address?.trim().toLowerCase()
              : true) &&
            // // checking shop phone number last 10 digits
            // shop?.phoneNumber?.split(' ').join('').slice(-10) ===
            //   selectedShop.phoneNumber?.split(' ').join('').slice(-10) &&
            // checking shop email
            shop?.email?.trim().toLowerCase() ===
              selectedShop.email?.trim().toLowerCase()
          );
        });

        if (isShopAlreadyExist) {
          enqueueSnackbar('Shop already exists...', {
            variant: 'error',
            preventDuplicate: true,
            persist: true
          });
          return;
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }

    if (activeStep === 0 && selectedShop.variant === 'online') {
      setActiveStep(2);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

  };

  const handleBack = () => {
    if (activeStep === 2 && selectedShop.variant === 'online') {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const validateFormData = () => {
    switch (activeStep) {
      case 0: {
        if (
          !selectedShop.country ||
          !selectedShop.name.trim() ||
          !selectedShop.contactName.trim() ||
          !selectedShop.phoneNumber.trim() ||
          !selectedShop.email.trim() ||
          (selectedShop.variant === 'regular' &&
            (!selectedShop.address || !selectedShop.postcode)) ||
          !selectedShop.town
        ) {
          return false;
        }
        return true;
      }
      case 1: {
        if (!selectedShop.location.lat || !selectedShop.location.lng) {
          return false;
        }
        return true;
      }
      case 2: {
        if (selectedShop.workHrs.length < 1) {
          return false;
        }
        return true;
      }
      case 3: {
        if (
          ((selectedShop.collectionHrs.length > 1 ||
            selectedShop.deliveryHrs.length > 1) &&
            (!selectedShop.standardDeliveryFee ||
              !selectedShop.distanceRange)) ||
          (selectedShop.enableAsapDelivery
            ? selectedShop.AsapDeliveryFee === ''
            : !!selectedShop.AsapDeliveryFee) ||
          selectedShop.paymentMethods?.length < 1 ||
          selectedShop.language?.length < 1
        ) {
          return false;
        }
        return true;
      }
      default: {
        break;
      }
    }
  };

  return (
    <Card className={classes.root}>
      <Typography variant="h2" className={classes.headingText}>
        Shop Self-Onboarding Form
      </Typography>
      {!formSubmitted ? (
        <>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            className={classes.bottomMargin}>
            {steps.filter(item=> selectedShop.variant==='online'? item !==  'Geographic location':true).map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Grid>
            <Grid className={classes.instructions}>
              {getStepContent(
                activeStep,
                setActiveStep,
                classes,
                selectedShop,
                setSelectedShop,
                enqueueSnackbar,
                closeSnackbar,
                setLoading,
                setFormSubmitted,
                validateFormData
              )}
            </Grid>

            <Typography variant="h6" className={classes.noteText}>
              Note: Please ensure that all details are completed accurately
              before submission.
            </Typography>

            <Grid container justify="center">
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
                size="small">
                Back
              </Button>
              {activeStep !== steps.length - 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  size="small">
                  Next
                </Button>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <FormSubmitted />
      )}
    </Card>
  );
};

export default SelfOnboardingShopForm;
