import React from 'react';
import { Link } from 'react-router-dom';
import { Link as MuiLink } from '@material-ui/core';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Template1, Template2, Template5, Template6 } from './components';
import { getRedirectUrl } from 'common/utilFunctions';
import userFlowTheme from 'theme/HomeTheme';

const useStyles = makeStyles((theme) => ({
  templateButton: {
    borderRadius: '4px',
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none'
    }
  }
}));

const templates = {
  tem1: Template1,
  tem2: Template2,
  tem5: Template5,
  tem6: Template6
  // need to configure properly later
  // tem3: Template3,
  // tem4: Template4,
};

const AdTemplates = ({
  ad = {
    shop: {},
    template: 'tem6',
    page: 'home',
    redirectLink: '',
    content: ''
  },
  showAsButton = false,
  ...rest
}) => {
  const classes = useStyles();
  let Component = templates[ad.template];
  const linkAllyProps = !!ad.redirectLink
    ? {
        href: getRedirectUrl(ad.redirectLink),
        target: '_blank',
        rel: 'noopener noreferrer'
      }
    : !!ad.shop?.shopID
    ? {
        to: `/shop/${
          ad.shop?.shopID || ''
        }/${ad.shop?.name?.toLowerCase()?.split(' ')?.join('-')}`
      }
    : {
        href: '#'
      };
  const adProps = {
    content: JSON.parse(ad.content || ''),
    shopName: ad.shop?.name || '',
    ...rest
  };

  return Component ? (
    <ThemeProvider theme={userFlowTheme}>
      {showAsButton ? (
        linkAllyProps.to ? (
          <Link className={classes.templateButton} {...linkAllyProps}>
            <Component {...adProps} />
          </Link>
        ) : (
          <MuiLink className={classes.templateButton} {...linkAllyProps}>
            <Component {...adProps} />
          </MuiLink>
        )
      ) : (
        <Component {...adProps} />
      )}
    </ThemeProvider>
  ) : null;
};

export default AdTemplates;
