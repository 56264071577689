import React, { useEffect } from 'react';
import {
  Grid,
  List,
  ListItemText,
  Divider,
  ListItem,
  Box,
  Typography,
  Badge
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMessages } from 'context/messages/messagesContext';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ShopImage } from 'components/organisms';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100% - 3.5rem)',
    border: '2px solid #0000003b',
    borderRadius: '0.25rem',
    [theme.breakpoints.up('md')]: {
      height: '38rem'
    }
  },
  mainContainer: {
    backgroundColor: theme.palette.white,
    borderRadius: '0.25rem'
  },
  list: {
    borderRadius: '0.25rem',
    padding: '0.5rem'
  },
  listItemRoot: {
    borderRadius: 0,
    '&.Mui-selected': {
      backgroundColor: theme.palette.blue.main,
      '&:hover': {
        backgroundColor: theme.palette.blue.main
      }
    }
  },
  listTextPrimary: {
    fontWeight: '700'
  },
  listTextSecondary: {
    fontSize: '1rem'
  },
  avatar: {
    height: 30,
    width: 30,
    marginRight: '0.5rem',
    borderRadius: '50%'
  },
  badge: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginRight: '0.5rem'
  },
  scrollContainer: {
    maxHeight: 'calc(100vh - 4.5rem)',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxHeight: '38rem'
    }
  }
}));

const ChatContactBox = (props) => {
  const classes = useStyles();
  const { orderActive, setOrderActive, setOpenDrawer, orders } = props;
  const { messageNotifications = [], dispatch } = useMessages();

  useEffect(() => {
    if (orderActive?.id)
      dispatch({
        type: 'markMessagesAsReadByOrder',
        payload: { orderID: orderActive?.id }
      });
  }, [orderActive]);

  const handleListItemClick = (order) => () => {
    setOrderActive(order);
    setOpenDrawer(false);
  };

  return (
    <Grid container item xs={12} className={classes.root}>
      <PerfectScrollbar className={classes.scrollContainer}>
        <Grid item xs={12} md={12} className={classes.mainContainer}>
          <Box sx={{ width: '100%' }}>
            <List>
              {orders.map((order, i) => {
                const notificationCount = messageNotifications.filter(
                  (_item) => _item?.orderID === order.id
                ).length;

                return (
                  <ListItem
                    button
                    key={`user-chat-orders-items-${i}`}
                    selected={orderActive?.id === order.id}
                    onClick={handleListItemClick(order)}
                    classes={{ root: classes.listItemRoot }}
                    className={classes.list}>
                    <ShopImage
                      shop={order.shop}
                      width="100%"
                      className={classes.avatar}
                    />
                    <ListItemText
                      primary={
                        <Typography
                          variant="h6"
                          className={classes.listTextPrimary}>
                          #{order.orderID} ({order.shop?.name})
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body1">
                          {/* last msg will be displayed */}
                        </Typography>
                      }
                    />
                    <Badge
                      badgeContent={notificationCount}
                      color="primary"
                      className={classes.badge}
                    />
                  </ListItem>
                );
              })}
            </List>
            <Divider />
          </Box>
        </Grid>
      </PerfectScrollbar>
    </Grid>
  );
};

export default ChatContactBox;
