import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
  useState
} from 'react';
import { useLoader } from 'layouts/loaderContext';
import { useMessages } from 'context/messages/messagesContext';
import { API, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import {
  getOrderByUserId,
  getOrder,
  getOrderByShopIdDateOIDStatus
} from './orderQueries';
import { createAnOrder, confirmOrderCardPayment } from 'graphql/mutations';
import * as orderApis from './ordersApis';
import { showPrice } from 'common/utilFunctions';
import omit from 'lodash/omit';

const OrdersContext = createContext({});
const FIELDS_TO_BE_DELETED_BEFORE_UPDATE = [
  'createdAt',
  'currentShopFees',
  'customerReview',
  'forwardRequests',
  'payments',
  'shop',
  'shopID',
  'orderID',
  'bidID',
  'userID',
  'updatedAt',
  'updatedBy',
  'userDetail',
  '_deleted',
  '_lastChangedAt',
  'onHoldPaymentIntentID',
  'onHoldPaymentAmount',
  'lastNotificationAt',
  'bid'
];

function ordersReducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case 'updateData': {
      return payload || [];
    }
    case 'addData': {
      return [...state, ...payload];
    }
    case 'addAData': {
      return [...state, payload];
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

function forwardRequestsReducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case 'updateData': {
      return payload || [];
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

const OrdersProvider = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [orders, dispatch] = useReducer(ordersReducer, []);
  const [forwardRequests, forwardRequestsDispatch] = useReducer(
    forwardRequestsReducer,
    []
  );
  const [nextPageToken, setNextPageToken] = useState('');
  const [selectedUserID, setSelectedUserID] = useState(null);
  const { messageNotifications = [] } = useMessages();
  const { setLoading } = useLoader();

  const asyncDispatch = useCallback(
    async (action) => {
      switch (action.type) {
        case 'getOrders': {
          setLoading(true);
          const {
            newQuery = false,
            shopId,
            status = [],
            orderId = '',
            createdDateRange = ''
          } = action?.payload || {};

          let snackBar;

          try {
            snackBar = enqueueSnackbar('Orders are loading...', {
              variant: 'info',
              persist: true,
              preventDuplicate: true
            });
            const input = {
              shopId,
              status,
              orderId
            };
            if (createdDateRange) input['createdDateRange'] = createdDateRange;
            if (!newQuery) input['nextToken'] = nextPageToken;

            const data = await API.graphql(
              graphqlOperation(getOrderByShopIdDateOIDStatus, input)
            );
            const orders = data.data.getOrderByShopIdDateOIDStatus.items
              .filter(
                (item) => item._deleted !== true && item.status !== 'dorment'
              )
              .sort(
                (a, b) => b.orderID.split('-')[1] - a.orderID.split('-')[1]
              );
            setNextPageToken(data.data.getOrderByShopIdDateOIDStatus.nextToken);

            if (!newQuery) {
              dispatch({
                type: 'addData',
                payload: orders
              });
            } else {
              dispatch({
                type: 'updateData',
                payload: orders
              });
            }
          } catch (error) {
            console.error(error);
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              preventDuplicate: true,
              autoHideDuration: 2500
            });
          } finally {
            setLoading(false);
            closeSnackbar(snackBar);
          }
          break;
        }
        case 'getOrdersByUserId': {
          setLoading(true);
          let snackBar;
          const { reloadOrders, userId, sortBy } = action.payload;
          try {
            // get orders
            let ordersResp = orders;
            // check if orders for current user are already available
            if (reloadOrders || selectedUserID !== userId) {
              snackBar = enqueueSnackbar('Orders are loading...', {
                variant: 'info',
                persist: true,
                preventDuplicate: true
              });
              const data = await API.graphql(
                graphqlOperation(getOrderByUserId, {
                  userID: userId,
                  limit: 8000
                })
              );
              ordersResp = data.data.getOrderByUserId.items.filter(
                (item) => item._deleted !== true
              );
              setSelectedUserID(userId);
            }

            // sort orders
            let sortedOrders;
            if (sortBy === 'lastNotificationTime') {
              sortedOrders = ordersResp
                .map((_order) => {
                  const notificationTimes = messageNotifications
                    .filter((_item) => _item?.orderID === _order.id)
                    .map((_item) => _item.createdAt);
                  return {
                    ..._order,
                    lastNotificationAt: notificationTimes.reduce(
                      (p, c) => (p > c ? p : c),
                      null
                    )
                  };
                })
                .sort(
                  (a, b) => b.orderID.split('-')[1] - a.orderID.split('-')[1]
                )
                .sort(
                  (a, b) =>
                    new Date(b.lastNotificationAt) -
                    new Date(a.lastNotificationAt)
                );
            } else
              sortedOrders = ordersResp.sort(
                (a, b) =>
                  new Date(b.updatedAt).getTime() -
                  new Date(a.updatedAt).getTime()
              );

            dispatch({
              type: 'updateData',
              payload: sortedOrders
            });
            return sortedOrders;
          } catch (error) {
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2500,
              preventDuplicate: true
            });
            console.error('something went wrong...', error);
          } finally {
            setLoading(false);
            closeSnackbar(snackBar);
          }
          break;
        }
        case 'updateOrder': {
          setLoading(true);
          let snackBar;
          try {
            const {
              order,
              sts = '',
              updateMessage = '',
              successCallback = () => {}
            } = action.payload;
            snackBar = enqueueSnackbar(updateMessage || 'updating order...', {
              variant: 'info',
              persist: true
            });

            const updatedOrder = await orderApis.updateOrder({
              ...omit(order, FIELDS_TO_BE_DELETED_BEFORE_UPDATE),
              status: sts || order.status
            });
            const updatedOrders = orders
              .map((item) => (order.id === item.id ? updatedOrder : item))
              .filter(({ _deleted }) => !_deleted);

            dispatch({
              type: 'updateData',
              payload: updatedOrders
            });
            successCallback();
          } catch (error) {
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2500,
              preventDuplicate: true
            });
            console.error('something went wrong...', error);
          } finally {
            setLoading(false);
            closeSnackbar(snackBar);
          }
          break;
        }
        case 'updateOrderInContext': {
          const { order: updatedOrder } = action.payload;
          const updatedOrders = orders
            .map((order) =>
              order.id === updatedOrder.id ? updatedOrder : order
            )
            .filter(({ _deleted }) => !_deleted);
          dispatch({
            type: 'updateData',
            payload: updatedOrders
          });
          break;
        }
        case 'sortOrders': {
          let sortedOrders;
          if (
            action.payload.property === 'date' ||
            action.payload.property === 'parent-date'
          ) {
            sortedOrders = [
              ...orders.sort((a, b) =>
                action.payload.direction === 'desc'
                  ? new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime()
                  : new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime()
              )
            ];
          } else if (action.payload.property === 'deliveryDate') {
            sortedOrders = [
              ...orders.sort((a, b) =>
                action.payload.direction === 'desc'
                  ? new Date(a.delivery.date).getTime() -
                    new Date(b.delivery.date).getTime()
                  : new Date(b.delivery.date).getTime() -
                    new Date(a.delivery.date).getTime()
              )
            ];
          } else {
            sortedOrders = [
              ...orders.sort((a, b) =>
                action.payload.direction === 'desc'
                  ? a.orderID.split('-')[1] - b.orderID.split('-')[1]
                  : b.orderID.split('-')[1] - a.orderID.split('-')[1]
              )
            ];
          }
          dispatch({
            type: 'updateData',
            payload: sortedOrders
          });
          break;
        }
        case 'placeOrder': {
          setLoading(true);
          const {
            payload,
            successCallback = () => {},
            failureCallback = () => {}
          } = action.payload;
          const snackBar = enqueueSnackbar('Placing order...', {
            variant: 'info',
            persist: true,
            preventDuplicate: true
          });

          try {
            const resp = await API.graphql(
              graphqlOperation(createAnOrder, payload)
            );
            const newOrderResp = await API.graphql(
              graphqlOperation(getOrder, { id: resp.data.createAnOrder.id })
            );
            dispatch({
              type: 'updateData',
              payload: [newOrderResp.data.getOrder, ...orders]
            });
            successCallback(resp.data.createAnOrder);
          } catch (error) {
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2500,
              preventDuplicate: true
            });
            console.error('something went wrong...', error);
            failureCallback(error);
          } finally {
            setLoading(false);
            closeSnackbar(snackBar);
          }
          break;
        }
        case 'createOrderForwardRequest': {
          setLoading(true);
          let snackBar;
          try {
            const { forwardRequest, order } = action.payload;
            snackBar = enqueueSnackbar('Order forwarding', {
              variant: 'info',
              persist: true
            });
            const input = {
              id: order.id,
              status: order.status,
              _version: order._version
            };
            await orderApis.createOrderForwardRequest(forwardRequest);
            const updateOrderResp = await orderApis.updateOrder(input);
            const updatedOrders = orders
              .map((order) =>
                updateOrderResp.id === order.id ? updateOrderResp : order
              )
              .filter(({ _deleted }) => !_deleted);
            dispatch({
              type: 'updateData',
              payload: updatedOrders
            });
          } catch (e) {
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2000,
              preventDuplicate: true
            });
          } finally {
            setLoading(false);
            closeSnackbar(snackBar);
          }
          break;
        }
        case 'updateOrderAndConfirmCardPayment': {
          setLoading(true);
          let snackBar;
          try {
            const {
              order,
              orderID = '',
              customerID = '',
              skipUpdate = false,
              updateMessage = '',
              successCallback = () => {},
              failureCallback = () => {}
            } = action.payload;
            snackBar = enqueueSnackbar(
              updateMessage || 'updating order and confirming payment...',
              {
                variant: 'info',
                persist: true
              }
            );
            let updatedOrder = orders.find((_order) => _order.id === orderID);

            if (!skipUpdate) {
              // update order
              updatedOrder = await orderApis.updateOrder({
                ...omit(order, FIELDS_TO_BE_DELETED_BEFORE_UPDATE)
              });
            }

            // get previous payment intent details
            const { paymentIntent } = await API.get(
              'laundryapi',
              '/payment-intents',
              {
                queryStringParameters: {
                  paymentIntentID: updatedOrder.onHoldPaymentIntentID
                }
              }
            );

            // capture payment if paymentIntent status is correct
            if (paymentIntent.status === 'requires_capture') {
              // capture pre authorised payment
              await API.post('laundryapi', '/capture-payment-intents', {
                body: { orderID, customerID }
              });

              updatedOrder = {
                ...updatedOrder,
                paymentStatus: 'paid',
                paidAmount: updatedOrder.total,
                status:
                  updatedOrder.status === 'itemized'
                    ? 'inProgress'
                    : updatedOrder.status
              };
              enqueueSnackbar('Order updated', {
                variant: 'success',
                autoHideDuration: 2500,
                preventDuplicate: true
              });
            } else {
              enqueueSnackbar(
                `Your previous authorised payment of ${showPrice(
                  updatedOrder.onHoldPaymentAmount
                )} is refunded.`,
                {
                  variant: 'warning',
                  persist: true
                }
              );
            }

            // update current orders
            const updatedOrders = orders
              .map((_order) => (orderID === _order.id ? updatedOrder : _order))
              .filter(({ _deleted }) => !_deleted);
            dispatch({
              type: 'updateData',
              payload: updatedOrders
            });

            if (paymentIntent.status === 'requires_capture') successCallback();
            else failureCallback(true);
          } catch (error) {
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2500,
              preventDuplicate: true
            });
            console.error('something went wrong...', error);
          } finally {
            setLoading(false);
            closeSnackbar(snackBar);
          }
          break;
        }
        case 'confirmOrderCardPayment': {
          setLoading(true);
          let snackBar;
          const {
            orderID,
            newPaymentIntentID = '',
            successCallback = () => {}
          } = action.payload;
          if (!orderID) return;

          try {
            snackBar = enqueueSnackbar('confirming order payment...', {
              variant: 'info',
              persist: true
            });

            // update order
            await API.graphql(
              graphqlOperation(confirmOrderCardPayment, {
                orderID,
                paymentIntentID: newPaymentIntentID
              })
            );

            // update current orders
            const updatedOrders = orders
              .map((_order) =>
                orderID === _order.id
                  ? {
                      ..._order,
                      paymentStatus: 'paid',
                      paidAmount: _order.total,
                      status:
                        _order.status === 'itemized'
                          ? 'inProgress'
                          : _order.status
                    }
                  : _order
              )
              .filter(({ _deleted }) => !_deleted);
            enqueueSnackbar('Order updated', {
              variant: 'success',
              autoHideDuration: 2500,
              preventDuplicate: true
            });
            dispatch({
              type: 'updateData',
              payload: updatedOrders
            });
            successCallback();
          } catch (error) {
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2500,
              preventDuplicate: true
            });
            console.error('something went wrong...', error);
          } finally {
            setLoading(false);
            closeSnackbar(snackBar);
          }
          break;
        }
        // TODO Forwarded & Missed
        case 'getOrderForwardShopByShopId': {
          setLoading(true);
          let snackBar;
          try {
            const { shopId, showCurrentRequests = false } = action.payload;
            let requests;
            if (forwardRequests.length < 1 || !showCurrentRequests) {
              snackBar = enqueueSnackbar('Loading forward requests', {
                variant: 'info',
                persist: true
              });
              const forwardOrderRequestsResp = await orderApis.getOrderForwardShopByShopId(
                shopId
              );

              requests = forwardOrderRequestsResp.items
                .filter((item) => {
                  const isOngoingReq =
                    item.forwardRequest?.status === 'ongoing' &&
                    item.status === 'pending';
                  const isMissedReq =
                    item.forwardRequest?.status === 'accepted' &&
                    item.status !== 'accepted';

                  return item._deleted !== true && showCurrentRequests
                    ? isOngoingReq
                    : isMissedReq;
                })
                .map((item) => item.forwardRequest?.order)
                .sort((a, b) => b._lastChangedAt - a._lastChangedAt);

              forwardRequestsDispatch({
                type: 'updateData',
                payload: requests
              });
            } else {
              forwardRequestsDispatch({
                type: 'updateData',
                payload: forwardRequests
              });
            }
          } catch (e) {
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2000,
              preventDuplicate: true
            });
          } finally {
            setLoading(false);
            closeSnackbar(snackBar);
          }
          break;
        }
        case 'getOrderForwardRequestByShopId': {
          setLoading(true);
          let snackBar;
          try {
            const { shopId } = action.payload;
            snackBar = enqueueSnackbar('Loading forward requests', {
              variant: 'info',
              persist: true
            });
            const forwardOrderRequestsResp = await orderApis.getOrderForwardRequestByShopId(
              shopId
            );
            const orders = forwardOrderRequestsResp.items
              .filter(
                (item) => item._deleted !== true && item.status !== 'rejected'
              )
              .map((item) => item.order)
              .sort((a, b) => b._lastChangedAt - a._lastChangedAt);
            dispatch({
              type: 'updateData',
              payload: orders
            });
          } catch (e) {
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2000,
              preventDuplicate: true
            });
          } finally {
            setLoading(false);
            closeSnackbar(snackBar);
          }
          break;
        }
        case 'updateOrderForwardRequest': {
          setLoading(true);
          try {
            const { order, sts, shopId } = action.payload;
            const forwardRequest = order.forwardRequests.items[0];
            const orderForwardShop = forwardRequest.forwardShops.items.find(
              (shop) => shop.shopID === shopId
            );

            await orderApis.acceptOrderForwardRequestById(orderForwardShop.id);

            if (sts === 'pending') {
              const updateOrderInput = {
                id: order.id,
                status: sts,
                shopID: shopId,
                _version: order._version
              };
              const updateOrderResp = await orderApis.updateOrder(
                updateOrderInput
              );
              const updatedOrders = forwardRequests.filter(
                (order) => updateOrderResp.id !== order.id && !order._deleted
              );
              forwardRequestsDispatch({
                type: 'updateData',
                payload: updatedOrders
              });
            }
          } catch (e) {
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2000,
              preventDuplicate: true
            });
          } finally {
            setLoading(false);
          }
          break;
        }
        case 'rejectOrderForwardShop': {
          setLoading(true);
          try {
            const { order, sts, shopId } = action.payload;
            const forwardShop = order.forwardRequests.items[0].forwardShops.items.find(
              (shop) => shop?.shopID === shopId
            );
            const forwardInput = {
              id: forwardShop.id,
              status: sts,
              _version: forwardShop._version
            };
            await orderApis.updateOrderForwardShop({
              input: forwardInput
            });

            const rejectedForwardReq = forwardRequests.filter((item) => {
              return order.id !== item.id && !item._deleted;
            });

            forwardRequestsDispatch({
              type: 'updateData',
              payload: rejectedForwardReq
            });
          } catch (e) {
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2000,
              preventDuplicate: true
            });
          } finally {
            setLoading(false);
          }
          break;
        }
        default:
          dispatch(action);
      }
      return;
    },
    [orders, nextPageToken, messageNotifications]
  );
  const value = {
    orders,
    isMoreOrdersAvailable: !!nextPageToken,
    forwardRequests,
    dispatch: asyncDispatch
  };

  return (
    <OrdersContext.Provider value={value}>
      {props.children}
    </OrdersContext.Provider>
  );
};

function useOrders() {
  const context = useContext(OrdersContext);
  if (context === undefined || !Object.keys(context).length) {
    throw new Error('useOrders must be used within a OrdersContext');
  }
  return context;
}

export { OrdersProvider, useOrders };
