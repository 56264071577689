import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
  useState
} from 'react';
import shopsReducer from './shopsReducer';
import {
  loadShops,
  loadShopWithProductByID,
  updateShopData
} from './shopsAPIs';
import { useLoader } from '../../layouts/loaderContext';
import { API, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import {
  shopItemQuery,
  getShop,
  getShopByPostalCode,
  searchShops
} from './shopsQueries';
import { createReviewReply, updateReviewReply } from './shopsMutations';
import { getCustomerReviewByShop } from './shopsQueries';

const ShopsContext = createContext({});

const ShopsProvider = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [shops, dispatch] = useReducer(shopsReducer, []);
  const [selectedShop, setSelectedShop] = useState(null);
  const [nextPageToken, setNextPageToken] = useState('');
  const [currentPostCode, setCurrentPostCode] = useState('');
  const [currentRange, setCurrentRange] = useState('');
  const [getShopPayload, setGetShopPayload] = useState({
    newQuery: false,
    limit: 100,
    salesUser: null,
    searchString: null,
    status: null,
    dateRangeData: null
  });
  const [pageNumber, setPageNumber] = useState(0);
  const { setLoading } = useLoader();

  // TODO remove API calls from context
  const asyncDispatch = useCallback(
    async (action) => {
      switch (action.type) {
        case 'getShops': {
          if (
            Object.keys(action.payload).length ===
              Object.keys(getShopPayload).length &&
            Object.keys(action.payload).every(
              (el) => getShopPayload[el] === action?.payload[el]
            )
          )
            return;

          setGetShopPayload({ ...action.payload, newQuery: true });
          setLoading(true);
          let snackBar;

          const {
            newQuery = false, // send this as true to ignore next page token
            limit = 100,
            salesUser = '',
            searchString = '',
            status = '',
            dateRangeData = '',
            hideLoader = false
          } = action?.payload || {};

          if (!hideLoader) {
            snackBar = enqueueSnackbar('Shops are loading...', {
              variant: 'info',
              persist: true,
              preventDuplicate: true
            });
          }

          let data;
          let nextToken = '';

          const callSearchShops =
            salesUser || searchString || status || dateRangeData;

          if (callSearchShops) {
            const input = {
              searchString,
              createdBy: salesUser
            };
            if (status) input['status'] = status;
            if (dateRangeData) input['createdDateRange'] = dateRangeData;
            if (!newQuery) input['nextToken'] = nextPageToken;

            try {
              const resp = await API.graphql(
                graphqlOperation(searchShops, input)
              );
              data = resp.data.searchShops.items;
              nextToken = resp.data.searchShops.nextToken;
            } catch (error) {
              console.error('error', error);
              if (!hideLoader)
                enqueueSnackbar('Something went wrong!!!', {
                  variant: 'error',
                  autoHideDuration: 2000
                });
            }
          } else {
            const resp = await loadShops(limit, newQuery ? '' : nextPageToken);
            data = resp.items;
            nextToken = resp.nextToken;
          }

          if (!newQuery) {
            dispatch({
              type: 'addShops',
              payload: data
            });
          } else {
            setPageNumber(0);
            dispatch({
              type: 'updateData',
              payload: data
            });
          }
          setNextPageToken(nextToken);
          setLoading(false);
          closeSnackbar(snackBar);
        }
        case 'getShopByID': {
          setLoading(true);

          const { shopID } = action?.payload || {};
          let data;
          if (shopID) {
            data = shops.find((item) => item.shopID === shopID);
            if (!data || data.products.nextToken) {
              const snackBar = enqueueSnackbar('Loading products...', {
                variant: 'info',
                persist: true
              });
              data = await loadShopWithProductByID(shopID);
              closeSnackbar(snackBar);
            }
          }
          let updatedSelectedShop = data;
          if (data?.reviews.nextToken) {
            const allReviewsResp = await API.graphql(
              graphqlOperation(getCustomerReviewByShop, {
                shopID: data.id,
                limit: 8000
              })
            );
            const allReviews = allReviewsResp.data.getCustomerReviewByShop;
            updatedSelectedShop = {
              ...data,
              reviews: allReviews
            };
          }
          setSelectedShop(updatedSelectedShop);
          setLoading(false);
          return data;
        }
        case 'updateShop': {
          const { payload } = action;
          setSelectedShop(payload);
          const updatedShops = shops
            .map((shop) => (shop.id === payload.id ? payload : shop))
            .filter(({ _deleted }) => !_deleted);
          dispatch({
            type: 'updateData',
            payload: updatedShops
          });
          break;
        }
        case 'updateShopInDB': {
          let sBar;
          const {
            input,
            hideSnackbar = false,
            successMessage = '',
            successCallback = () => {}
          } = action.payload;
          setLoading(true);

          if (!hideSnackbar) {
            sBar = enqueueSnackbar('Updating shop data...', {
              variant: 'info',
              preventDuplicate: true,
              persist: true
            });
          }

          try {
            const result = await updateShopData(input);
            const updatedShops = shops
              .map((shop) => (shop.id === input.id ? result : shop))
              .filter(({ _deleted }) => !_deleted);
            dispatch({
              type: 'updateData',
              payload: updatedShops
            });
            if (!hideSnackbar) {
              enqueueSnackbar(successMessage || 'Updated...', {
                variant: 'success',
                preventDuplicate: true,
                autoHideDuration: 2500
              });
            }
            setSelectedShop(result);
            successCallback(result);
          } catch (error) {
            console.error('something went wrong', error);
            if (!hideSnackbar) {
              enqueueSnackbar('Something went wrong..!', {
                variant: 'error',
                preventDuplicate: true,
                autoHideDuration: 2500
              });
            }
          } finally {
            closeSnackbar(sBar);
            setLoading(false);
          }
          break;
        }
        case 'getSelectedShop': {
          const { payload } = action;
          if (!payload.shopId) return;
          if (selectedShop && selectedShop.id === payload.shopId)
            return selectedShop;
          const sBar = enqueueSnackbar('Loading Shop data...', {
            variant: 'info',
            preventDuplicate: true
          });
          setLoading(true);
          const data = await API.graphql(
            graphqlOperation(getShop, { id: payload.shopId })
          );
          if (data.data.getShop) {
            const res = data.data.getShop;
            if (res._deleted) {
              enqueueSnackbar(`Shop(${payload.shopId}) is deleted`, {
                variant: 'error',
                preventDuplicate: true
              });
            } else {
              let updatedSelectedShop = res;
              if (res.reviews.nextToken) {
                const allReviewsResp = await API.graphql(
                  graphqlOperation(getCustomerReviewByShop, {
                    shopID: res.id,
                    limit: 8000
                  })
                );
                const allReviews = allReviewsResp.data.getCustomerReviewByShop;
                updatedSelectedShop = {
                  ...res,
                  reviews: allReviews
                };
              }
              setSelectedShop(updatedSelectedShop);
              closeSnackbar(sBar);
              setLoading(false);
              return updatedSelectedShop;
            }
          } else {
            enqueueSnackbar(`couldn't find Shop : ${payload.shopId}`, {
              variant: 'error',
              preventDuplicate: true,
              persist: true
            });
          }
          closeSnackbar(sBar);
          setLoading(false);
        }
        case 'getShopsByPostCode': {
          const {
            postalCode = '',
            range,
            useLoader = true,
            showSnackbar = false,
            setExtraLoading = () => {}
          } = action?.payload || {};
          let snackBar;
          if (currentPostCode !== postalCode || currentRange !== range) {
            try {
              setCurrentPostCode(postalCode);
              let input = { postalCode };
              if (range) {
                setCurrentRange(range);
                input.range = range;
              }
              useLoader && setLoading(true);
              setExtraLoading(true);
              if (showSnackbar) {
                snackBar = enqueueSnackbar('Shops are loading...', {
                  variant: 'info',
                  persist: true,
                  preventDuplicate: true
                });
              }

              const resp = await API.graphql(
                graphqlOperation(getShopByPostalCode, input)
              );
              const data = resp.data.getShopByPostalCode;
              dispatch({
                type: 'updateData',
                payload: data
              });
            } catch (error) {
              console.log('error', error);
            } finally {
              useLoader && setLoading(false);
              closeSnackbar(snackBar);
              setExtraLoading(false);
            }
          }
          break;
        }
        case 'getRandomShops':
          const { limit = 10 } = action?.payload || {};
          setLoading(true);
          try {
            const resp = await API.graphql(
              graphqlOperation(
                `query ListShops {
                  listShops(limit: ${limit}) {
                    items {
                      ${shopItemQuery}
                    }
                  }
                }`
              )
            );
            const data = resp.data.listShops.items;
            dispatch({
              type: 'updateData',
              payload: data
            });
          } catch (error) {
            console.log('error', error);
          } finally {
            setLoading(false);
          }
          break;
        // for updating shops in DB
        case 'updateShopsConsentLetterCount':
          const { payload } = action;
          if (!payload.shops?.length) return;
          setLoading(true);
          let updatedShops = await Promise.allSettled(
            payload.shops.map((shop) =>
              updateShopData({
                _version: shop._version,
                id: shop.id,
                consentLetterCount: (shop.consentLetterCount || 0) + 1,
                consentLetterLastDate: new Date()
              })
            )
          );
          updatedShops = updatedShops
            .map((item) => (item.status === 'fulfilled' ? item.value : ''))
            .filter((item) => !!item);
          const data = shops.map((shop) => {
            const updatedData = updatedShops.find(
              (item) => item.id === shop.id
            );
            return updatedData ? updatedData : shop;
          });

          dispatch({
            type: 'updateData',
            payload: data
          });
          setLoading(false);
          break;

        case 'updatePageNumber':
          const { pageNumber } = action.payload;
          setPageNumber(pageNumber);
          break;

        case 'addShopReviewReply': {
          const { data, selectedShop } = action.payload;
          setLoading(true);
          try {
            const result = await API.graphql(
              graphqlOperation(createReviewReply, data)
            );
            const reply = result.data.createReviewReply;
            const reviews = selectedShop.reviews.items.map((item) => {
              if (item.id === reply.reviewID) {
                item.reply = reply;
                item.replyID = reply.id;
              }
              return item;
            });
            const updatedSelectedShop = {
              ...selectedShop,
              reviews: { items: reviews }
            };
            setSelectedShop(updatedSelectedShop);
            enqueueSnackbar('Your reply has been sent.', {
              variant: 'success',
              preventDuplicate: true,
              autoHideDuration: 2000
            });
          } catch (e) {
            enqueueSnackbar('Something went wrong.', {
              variant: 'error',
              preventDuplicate: true,
              autoHideDuration: 1000
            });
            console.log(e);
          }
          setLoading(false);
          break;
        }

        case 'updateShopReviewReply': {
          const { data, selectedShop } = action.payload;
          setLoading(true);
          try {
            const result = await API.graphql(
              graphqlOperation(updateReviewReply, data)
            );
            const editedReply = result.data.updateReviewReply;
            const reviews = selectedShop.reviews.items.map((item) =>
              item.reply?.id === editedReply.id
                ? { ...item, reply: editedReply }
                : item
            );
            const updatedSelectedShop = {
              ...selectedShop,
              reviews: { items: reviews }
            };
            setSelectedShop(updatedSelectedShop);
            enqueueSnackbar('Your reply has been updated.', {
              variant: 'success',
              preventDuplicate: true,
              autoHideDuration: 2000
            });
          } catch (e) {
            enqueueSnackbar('Something went wrong.', {
              variant: 'error',
              preventDuplicate: true,
              autoHideDuration: 1000
            });
            console.log(e);
          }
          setLoading(false);
          break;
        }

        default:
          dispatch(action);
      }
    },
    [shops, nextPageToken]
  );

  const value = {
    shops,
    isMoreShopsAvailable: !!nextPageToken,
    dispatch: asyncDispatch,
    selectedShop,
    setSelectedShop,
    pageNumber,
    getShopPayload
  };

  return (
    <ShopsContext.Provider value={value}>
      {props.children}
    </ShopsContext.Provider>
  );
};

function useShops() {
  const context = useContext(ShopsContext);
  if (context === undefined || !Object.keys(context).length) {
    throw new Error('useShops must be used within a ShopsContext');
  }
  return context;
}

export { ShopsProvider, useShops };
