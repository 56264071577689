import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLoader } from 'layouts/loaderContext';
import OtpInput from 'react-otp-input';
import { useAmplifyAuth } from 'context';
import { INITIAL_BANK_DETAILS } from '../constants';

const useStyles = makeStyles((theme) => ({
  root: { minWidth: '100%', minHeight: '98%' },
  saveButton: {
    marginLeft: theme.spacing(2)
  },
  cancelButton: {
    color: theme.palette.text.secondary
  },
  otpInputStyles: {
    width: '2rem !important',
    aspectRatio: 1,
    border: '2px solid #ccc',
    borderRadius: '0.25rem',
    [theme.breakpoints.up('md')]: {
      width: '3rem !important'
    }
  },
  otpOTPcontainer: {
    justifyContent: 'center'
  }
}));

const BankAccountForm = ({
  accountDetails = {},
  handleSubmit = (input = {}, callback = () => {}) => {},
  handleCancel = () => {},
  handleGenerateOTPClick = async () => {}
}) => {
  const classes = useStyles();
  const { loading } = useLoader();
  const {
    state: { isAdmin }
  } = useAmplifyAuth();
  const [accountDetailsInput, setAccountDetailsInput] = useState(
    INITIAL_BANK_DETAILS
  );
  const [showOTPModal, setShowOTPModal] = useState(false);

  useEffect(() => {
    setAccountDetailsInput(accountDetails);
  }, [accountDetails]);

  useEffect(() => {
    if (showOTPModal) handleGenerateOTPClick();
  }, [showOTPModal]);

  const accountDetailsChangeHandler = (e) =>
    setAccountDetailsInput((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));

  const openOTPDialog = () => setShowOTPModal(true);

  const handleDialogClose = () => setShowOTPModal(false);

  const handleOTPSubmit = () =>
    handleSubmit(accountDetailsInput, handlerCallback);

  const handlerCallback = () => {
    setAccountDetailsInput(INITIAL_BANK_DETAILS);
    handleDialogClose();
  };

  const handleCancelBtnClick = () => {
    handlerCallback();
    handleCancel();
  };

  const handleSubmitBtnClick = () => {
    if (isAdmin) handleSubmit(accountDetailsInput, handlerCallback);
    else openOTPDialog();
  };

  return (
    <>
      <Card className={classes.root}>
        <CardHeader title="Save Your Bank Account Details" />
        <CardContent>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ gap: '2rem' }}>
            <Grid item md={8} xs={12}>
              <TextField
                fullWidth
                label="Account Holder Name"
                name="accountHolderName"
                variant="outlined"
                value={accountDetailsInput.accountHolderName}
                onChange={accountDetailsChangeHandler}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={8} xs={12}>
              <TextField
                fullWidth
                label="Bank Name"
                name="name"
                variant="outlined"
                value={accountDetailsInput.name}
                onChange={accountDetailsChangeHandler}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid container item md={8} xs={12} style={{ gap: '0.5rem' }}>
              <Grid item xs={12}>
                <Typography variant="body1">Sort Code</Typography>
              </Grid>
              <OtpInput
                numInputs={6}
                value={accountDetailsInput.accountSortCode}
                onChange={(input) =>
                  accountDetailsChangeHandler({
                    target: { value: input, name: 'accountSortCode' }
                  })
                }
                placeholder="------"
                renderSeparator={<span>&nbsp;&nbsp;</span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={classes.otpInputStyles}
                inputType="tel"
              />
            </Grid>
            <Grid container item md={8} xs={12} style={{ gap: '0.5rem' }}>
              <Grid item xs={12}>
                <Typography variant="body1">Account Number</Typography>
              </Grid>
              <OtpInput
                numInputs={8}
                value={accountDetailsInput.accountNumber}
                onChange={(input) =>
                  accountDetailsChangeHandler({
                    target: { value: input, name: 'accountNumber' }
                  })
                }
                placeholder="--------"
                renderSeparator={<span>&nbsp;&nbsp;</span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={classes.otpInputStyles}
                inputType="tel"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{ marginTop: '1rem' }}>
          <Grid
            container
            item
            justify="center"
            alignItems="center"
            xs={12}
            style={{ gap: '1rem' }}>
            <Button
              className={classes.cancelButton}
              variant="outlined"
              onClick={handleCancelBtnClick}>
              Cancel
            </Button>
            <Button
              className={classes.saveButton}
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={handleSubmitBtnClick}>
              Save
            </Button>
          </Grid>
        </CardActions>
      </Card>

      {/* dialog for otp input */}
      <Dialog
        open={showOTPModal}
        onClose={handleDialogClose}
        aria-labelledby="otp-form-dialog-title">
        <DialogTitle id="otp-form-dialog-title">
          Verify Your Identity
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            An OTP has been sent to the registered email. Please enter the OTP
            in the field below to verify your identity.
          </DialogContentText>
          <OtpInput
            numInputs={8}
            value={accountDetailsInput.otp}
            onChange={(input) =>
              accountDetailsChangeHandler({
                target: { value: input, name: 'otp' }
              })
            }
            placeholder="--------"
            renderSeparator={<span>&nbsp;&nbsp;</span>}
            renderInput={(props, index) => (
              <input autoFocus={index === 0} {...props} />
            )}
            inputStyle={classes.otpInputStyles}
            containerStyle={classes.otpOTPcontainer}
            inputType="tel"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOTPSubmit} color="primary" disabled={loading}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BankAccountForm;
