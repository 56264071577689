/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "laundryapi",
            "endpoint": "https://nci4qbu69c.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://kbow7hm4bvcghnqmswxx3ryvaq.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-rcu6lanbkbd3fj3upureihqn7a",
    "aws_cognito_identity_pool_id": "eu-west-2:385a3912-171e-41f8-9e16-8a9bbd82ce57",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_BaLnEUUCl",
    "aws_user_pools_web_client_id": "14t2rbu0ccsgq0ceg3eirt5qss",
    "oauth": {
        "domain": "googlesignin-prod.auth.eu-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.do-gooder.co.uk,http://localhost:3000",
        "redirectSignOut": "https://www.do-gooder.co.uk,http://localhost:3000",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "laundry-app-20210328000328-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "eu-west-2",
    "aws_content_delivery_url": "https://d1micqts1ebe04.cloudfront.net",
    "aws_user_files_s3_bucket": "laundryappb3ead5e3a8b8480e8c40eabfcd65827a194328-prod",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
