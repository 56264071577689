export const INITIAL_AUCTION = {
  startDate: null,
  startTime: null,
  endDate: null,
  endTime: null,
  products: [],
  collection: '',
  delivery: '',
  postcode: ''
};

export const INITIAL_PRODUCT = {
  service: '',
  name: '',
  description: ''
};

export const DATE_FIELDS = ['year', 'month', 'date'];

export const REQUIRED_FIELDS = [
  'startDate',
  'startTime',
  'endDate',
  'endTime',
  'postcode'
];

export const OMMITED_ADDRESS_FIELDS = [
  '_deleted',
  '_lastChangedAt',
  '_version',
  'user',
  'userID',
  'updatedAt',
  'createdAt'
];
