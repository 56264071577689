import React from 'react';
import { Typography, Link, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { googleFormLinks, launderItBlogLink } from 'common/constants';
import { Link as ReactRouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  linksBox: {
    backgroundColor: 'rgb(59 171 189/0.3)',
    padding: '0.5rem 0.75rem',
    margin: '0'
  },
  linkFont: {
    letterSpacing: '0.01em',
    fontWeight: 600,
    cursor: 'pointer',
    padding: '0 0.5rem',
    color: theme.palette.yellow?.main || '#EDD242'
  }
}));

const JoinLinks = () => {
  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.linksBox}>
      <Grid item>
        <Typography variant="body2">
          <ReactRouterLink
            className={classes.linkFont}
            to="/business-with-us"
            target="_blank"
            rel="noreferrer">
            Business With Us
          </ReactRouterLink>
        </Typography>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid item>
        <Typography variant="body2">
          <Link
            className={classes.linkFont}
            href={launderItBlogLink}
            target="_blank"
            rel="noreferrer">
            Blogs
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default JoinLinks;
