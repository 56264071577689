import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ShopsToolbar, ShopsTable } from '../Shops/components';
import { ShopDetails } from '../Shops/components';
import { Provider } from '../../context/shopDetail/shopContext';
import { useParams } from 'react-router-dom';
import { useShops } from 'context/shop/shopsContext';
import { PDFDownloadView } from 'components/organisms';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const LaundryShop = () => {
  let { actionType } = useParams();
  const isListPage = !actionType;
  const [consentLetterShop, setConsentLetterShop] = useState(null);
  const { shops } = useShops();
  const classes = useStyles();

  return (
    <Provider>
      <div className={classes.root}>
        {consentLetterShop ? (
          <PDFDownloadView
            title="Consent Letter"
            shops={shops.filter((shop) => shop.id === consentLetterShop)}
            handleCancel={() => setConsentLetterShop(null)}
          />
        ) : (
          <>
            <ShopsToolbar />
            <div className={classes.content}>
              {isListPage && (
                <ShopsTable setConsentLetterShop={setConsentLetterShop} />
              )}
              {!isListPage && <ShopDetails />}
            </div>
          </>
        )}
      </div>
    </Provider>
  );
};

export default LaundryShop;
