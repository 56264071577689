import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import StaffUploadedDocumentation from './StaffUploadedDocumentation';
import StaffDocumentationDropzone from './StaffDocumentationDropzone';
import { Card, CardContent, Grid, Container, Fab } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '2rem',
    paddingTop: '1rem',
    paddingBottom: '0.5rem',
    boxShadow: 'none'
  },
  contentContainer: {
    padding: 0,
    marginTop: '1rem'
  }
}));

function StaffDocumentationForm({ onClose }) {
  const [showUploadDropzone, setShowUploadDropzone] = useState(true);
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-between" alignItems="flex-start">
          <Grid container item xs={10} direction="column"></Grid>
          {showUploadDropzone ? null : (
            <Grid container item xs={2} justify="flex-end" alignItems="center">
              <Fab
                color="secondary"
                size="small"
                onClick={() => setShowUploadDropzone(true)}>
                <AddIcon />
              </Fab>
            </Grid>
          )}
        </Grid>
        <Container className={classes.contentContainer}>
          {showUploadDropzone ? (
            <>
              <StaffDocumentationDropzone onClose={onClose} />
            </>
          ) : (
            <>
              <StaffUploadedDocumentation />
            </>
          )}
        </Container>
      </CardContent>
    </Card>
  );
}

export default StaffDocumentationForm;
