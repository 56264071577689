import React, { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import loadingGif from 'assets/gif/Loading_icon.gif';

const Image = ({
  docKey = '',
  className = '',
  accessLevel = 'public',
  mimeType = '',
  loadFromStorage = true,
  ...rest
}) => {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (!loadFromStorage) {
      setUrl(docKey);
    } else {
      setLoading(true);
      Storage.get(docKey, {
        level: accessLevel
      })
        .then((data) => (isMounted ? setUrl(data) : null))
        .catch((error) => console.log('error', error))
        .finally(() => (isMounted ? setLoading(false) : null));
    }
    return () => (isMounted = false);
  }, [docKey]);

  return mimeType === 'application/pdf' ? (
    <a href={`${url}#toolbar=0`} target="_blank">
      click here to view document
    </a>
  ) : mimeType.includes('video') ? (
    !loading ? (
      <video className={className} controls {...rest} autoPlay muted>
        <source src={url} type={mimeType} />
      </video>
    ) : (
      <img src={loadingGif} className={className} {...rest} />
    )
  ) : (
    <img src={loading ? loadingGif : url} className={className} {...rest} />
  );
};

export default Image;
