import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  Typography,
  CardContent,
  Box,
  IconButton
} from '@material-ui/core';
import TinySlider from 'tiny-slider-react';
import { getShopTotalRating, showPrice } from '../../../common/utilFunctions';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DistanceIcon from '../assets/Distance.png';
import { useLoader } from 'layouts/loaderContext';
import { useShops } from 'context/shop/shopsContext';
import { ShopImage } from 'components/organisms';
import { StyledRating } from 'components/molecules';
import { HomeShopCardSkeleton } from 'components/skeletons';
import 'tiny-slider/dist/tiny-slider.css';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '90%',
    margin: '5.625rem auto',
    [theme.breakpoints.down('md')]: {
      margin: '3.5rem auto'
    }
  },
  card: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '12px 12px 0 0'
  },
  cardName: {
    fontSize: '15px',
    fontWeight: '600',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    verticalAlign: 'top',
    overflow: 'hidden'
  },
  cardContent: {
    padding: 0,
    paddingBottom: '0.25rem !important',
    height: '100%'
  },
  shopImageContainer: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '0.75rem'
  },
  cardContentMain: {
    padding: '10px 0',
    borderBottom: '3px solid #a3a3a3'
  },
  header: {
    fontSize: '1.5rem',
    fontWeight: '800'
  },
  rating: {
    fontSize: '9px',
    color: theme.palette.secondary.main
  },
  startingPrice: {
    borderRadius: '4px',
    backgroundColor: '#ffc700a8',
    color: theme.palette.primary.secondary,
    padding: '4px 6px',
    fontSize: '10px',
    color: '#194376',
    fontWeight: 600
  },
  image: {
    height: `auto`,
    aspectRatio: '16/9',
    objectFit: 'contain',
    maxWidth: '100%',
    borderRadius: '0.75rem',
    overflow: 'hidden',
    backgroundColor: '#ccc'
  },
  imageCover: {
    position: 'absolute',
    borderRadius: 5,
    top: -9,
    right: -151,
    width: 317,
    height: 198,
    transform: 'rotateY(0deg) rotate(45deg)',
    opacity: 0.3,
    backgroundImage: 'linear-gradient(gray, white)'
  },
  postCode: {
    borderRadius: 2,
    color: 'black',
    gap: '0.25rem'
  },
  shopsList: {
    marginTop: '1.5rem !important'
  },
  distanceRange: {
    color: 'black',
    textAlign: 'left',
    fontSize: '0.7rem',
    backgroundColor: '#f8f8f8'
  },
  shopGrayWrapper: {
    filter: 'grayscale(100%)'
  },
  onHoldWrapper: {
    left: 0,
    position: 'absolute',
    top: 0,
    height: '100px',
    width: '100px',
    overflow: 'hidden',
    zIndex: '1000'
  },
  onHoldRibbon: {
    left: '-55px',
    position: 'absolute',
    top: '37px',
    height: '16px',
    width: '200px',
    transform: 'rotate(-45deg)',
    textAlign: 'center',
    backgroundColor: theme.palette.warning.main,
    color: '#000',
    fontWeight: 600,
    fontSize: '11px'
  },
  scrollBtnsContainer: {
    marginTop: '0.5rem',
    display: 'flex',
    gap: '1rem'
  },
  scrollBtn: {
    padding: '0.25rem'
  },
  scrollIcon: {
    fontSize: '1rem'
  },
  shopRedirect: {
    fontSize: 11,
    fontWeight: 600,
    color: '#000',
    backgroundColor: '#edd242',
    padding: '0.3rem',
    borderRadius: '0 0 4px 4px'
  }
}));
const SLIDER_SETTINGS = {
  items: 1,
  speed: 400,
  lazyload: true,
  mouseDrag: true,
  slideBy: 'page',
  autoplay: false,
  controls: false,
  center: false,
  nav: false,
  gutter: 10,
  rewind: true,
  responsive: {
    990: {
      items: 4
    }
  }
};

export default function IntroOne() {
  const sliderRef = useRef(null);
  const { shops = [], dispatch } = useShops();
  const classes = useStyles();
  const { loading } = useLoader();
  const [filteredShops, setFilteredShops] = useState([]);

  useEffect(() => {
    dispatch({
      type: 'getShops',
      payload: { newQuery: true, limit: 1000, hideLoader: true }
    });
  }, []);

  useEffect(() => {
    setFilteredShops(
      shops
        ?.filter(
          (item) =>
            !!item &&
            (item.status === 'onhold' ||
              (item.status === 'active' && item.isLive))
        )
        .sort(
          (a, b) =>
            getShopTotalRating(b?.reviews?.items) -
            getShopTotalRating(a?.reviews?.items)
        ) || []
    );
  }, [shops]);

  const handleScrollBtnClick = (dir) => () =>
    sliderRef.current?.slider.goTo(dir);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography
          variant="h3"
          color="primary"
          align="center"
          className={classes.header}>
          Popular Shops
        </Typography>
      </Grid>
      {!!(loading || filteredShops.length <= 0) ? (
        <Grid container justify="center" className={classes.shopsList}>
          <HomeShopCardSkeleton />
        </Grid>
      ) : (
        <>
          <Grid container justify="center" className={classes.shopsList}>
            <TinySlider settings={SLIDER_SETTINGS} ref={sliderRef}>
              {filteredShops.map((item, index) => {
                const isClosedToday = !item?.workHrs?.some(
                  (item) => item.openDay === new Date().getDay()
                );
                const redirectLink = `/shop/${
                  item.shopID
                }/${item.name.toLowerCase().split(' ').join('-')}`;

                return (
                  <Grid
                    container
                    key={`popular-shop-introone-items-${index}`}
                    justify="center"
                    style={{ height: 'auto' }}
                    className={classes.card}>
                    {isClosedToday ? (
                      <Box className={classes.onHoldWrapper} id="ribbon">
                        <Box className={classes.onHoldRibbon}>
                          CLOSED FOR TODAY
                        </Box>
                      </Box>
                    ) : null}
                    <Link
                      to={redirectLink}
                      // add the following to make the card grey
                      // className={isClosedToday ? classes.shopGrayWrapper : ''}
                    >
                      <CardContent className={classes.cardContent}>
                        <Box className={classes.shopImageContainer}>
                          <ShopImage
                            shop={item}
                            width="100%"
                            className={classes.image}
                          />
                          {item?.photos?.some(
                            (photo) =>
                              photo?.status === 'approved' &&
                              photo?.MIMETypes.includes('image')
                          ) &&
                            !loading && <Box className={classes.imageCover} />}
                        </Box>
                        <Grid
                          container
                          justify="space-between"
                          // className={classes.cardContentMain}
                        >
                          <Grid
                            container
                            alignItems="flex-end"
                            className={classes.postCode}>
                            <img src={DistanceIcon} alt="Distance icon" />

                            <Typography
                              className={classes.distanceRange}
                              align="center">
                              {item.range
                                ? item.range > 152
                                  ? (item.range / 1609).toFixed(2) + ' mi'
                                  : Math.ceil(item.range * 3.281) + ' ft'
                                : item.postcode}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            justify="space-between"
                            style={{ marginBottom: '0.625rem' }}>
                            <Grid
                              container
                              item
                              xs={6}
                              md={8}
                              lg={6}
                              justify="flex-start"
                              alignItems="center"
                              style={{ paddingTop: 4, minHeight: 45 }}>
                              <Typography
                                variant="h5"
                                color="secondary"
                                title={item.name || ''}
                                className={classes.cardName}>
                                {item.name || ''}
                                {item.reviews?.items?.length > 0 && (
                                  <Grid
                                    container
                                    item
                                    className={classes.rating}>
                                    <StyledRating
                                      name="size-small"
                                      value={parseFloat(
                                        getShopTotalRating(item.reviews.items)
                                      )}
                                      size={'small'}
                                      readOnly
                                      showRatingCount
                                    />
                                  </Grid>
                                )}
                              </Typography>
                            </Grid>

                            {item.products?.items?.length > 0 ? (
                              <Grid
                                container
                                item
                                xs={4}
                                alignItems="center"
                                justify="flex-end">
                                <Typography
                                  variant="body2"
                                  className={classes.startingPrice}>
                                  {`Starting at ${showPrice(
                                    Math.min(
                                      ...item.products.items
                                        .filter(
                                          (product) =>
                                            product?.price && !product?._deleted
                                        )
                                        .map((product) => product?.price)
                                    )
                                  )}`}
                                </Typography>
                              </Grid>
                            ) : null}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Link>
                    {item.websiteLink?.trim() !== '' ? (
                      <a
                        id="RouterNavLink"
                        href={item.websiteLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}>
                        <Typography
                          variant="body1"
                          className={classes.shopRedirect}>
                          {item.websiteLink}
                        </Typography>
                      </a>
                    ) : null}
                  </Grid>
                );
              })}
            </TinySlider>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.scrollBtnsContainer}>
            <IconButton
              className={classes.scrollBtn}
              onClick={handleScrollBtnClick('prev')}>
              <ArrowBackIosIcon className={classes.scrollIcon} />
            </IconButton>
            <IconButton
              className={classes.scrollBtn}
              onClick={handleScrollBtnClick('next')}>
              <ArrowForwardIosIcon className={classes.scrollIcon} />
            </IconButton>
          </Grid>
        </>
      )}
    </Grid>
  );
}
