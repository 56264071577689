import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography, Tabs, Tab, useMediaQuery } from '@material-ui/core';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { capitalizeStr } from 'common/utilFunctions';
import { CartCard, CustomSearchBar } from 'components/organisms';
import Products from './Products';
import { CartContext } from 'context/cart/cartContext';
import { useServices } from 'views/Services/serviceContext';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    backgroundColor: '#F9FAFB'
  },
  root: {
    padding: '1.5rem 0',
    maxWidth: '90%',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      padding: '40px 0 4rem'
    }
  },
  catListRoot: {
    boxShadow: '2px 4px 3px 0px #0000001A',
    width: '100%'
  },
  catListContainer: {
    marginTop: '25px',
    padding: '10px 0 18px',
    clipPath: 'inset(0px 0px -15px 0px);',
    margin: '0 auto',
    maxWidth: '90%',
    [theme.breakpoints.up('md')]: {
      padding: '10px 0 18px'
    }
  },
  tabIndicator: {
    display: 'none'
  },
  tabsRoot: { gap: '10px' },
  itemsContainer: {
    height: 'fit-content',
    marginBottom: '1rem'
  },
  searchBox: {
    borderRadius: '10px'
  },
  notFoundTextContainer: {
    width: '100%',
    margin: '2rem 0'
  },
  notFoundText: {
    textAlign: 'center'
  }
}));

const StyledTab = withStyles((theme) => ({
  root: {
    borderRadius: '22px',
    backgroundColor: '#F2F2F2',
    textTransform: 'capitalize',
    fontSize: '12px',
    maxHeight: '30px',
    padding: '8px 15px',
    fontWeight: '800'
  },
  selected: {
    backgroundColor: theme.palette.blue.main,
    color: 'white'
  }
}))((props) => <Tab {...props} />);

const getServicesWithProducts = (
  services = [],
  products = [],
  filterCallback = () => false
) => {
  const filteredServices = services.filter(filterCallback);
  return filteredServices.length > 0
    ? filteredServices
        .map((service) => ({
          ...service,
          products: products?.filter((prod) => prod.serviceID === service?.id),
          subServices: getServicesWithProducts(
            services,
            products,
            (_service) => _service.serviceID === service.id
          )
        }))
        .filter(
          (_service) =>
            _service?.products?.length > 0 || _service?.subServices?.length > 0
        )
    : [];
};

export default function ItemDisplayer(props) {
  const { currentShop } = props;
  const { cart, setCart } = useContext(CartContext);
  const { services = [] } = useServices();
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const enableCart = currentShop.isLive;
  const [servicesWithProducts, setServicesWithProducts] = useState([]);
  const [currentTab, setCurrentTab] = useState('all');
  const [searchBy, setSearchBy] = useState('');
  const filteredServicesWithProducts = servicesWithProducts.filter(
    (item) => currentTab === 'all' || item.id === currentTab
  );
  const { products = {} } = currentShop;

  useEffect(() => {
    let mounted = true;
    const data = getServicesWithProducts(
      services,
      products?.items?.filter((_product) => !_product?._deleted) || [],
      (_service) => !_service.serviceID
    );
    mounted && setServicesWithProducts(data);

    return () => {
      mounted = false;
    };
  }, [services, currentShop]);

  useEffect(() => {
    if (cart.items?.length > 0) {
      if (cart.items[0].shopID !== currentShop.id) {
        localStorage.removeItem('cart');
        setCart({ items: [] });
      }
    }
  }, [currentShop]);

  const handleSearchChange = (e) => setSearchBy(e.target.value);

  const handleSearchClear = () => setSearchBy('');

  const getSearchedProductQty = () =>
    products?.items
      ?.filter((_product) => !_product?._deleted)
      .filter((item) => {
        const name = item?.name || item?.item?.name || '';
        return (
          !!name && name.toLowerCase().includes(searchBy.trim().toLowerCase())
        );
      }).length || 0;

  return (
    <Grid container>
      <Grid className={classes.catListRoot}>
        <Grid
          container
          alignItems="center"
          className={classes.catListContainer}>
          {servicesWithProducts.length > 0 ? (
            <Grid item xs={12} style={{ marginBottom: md ? 0 : '10px' }}>
              <Tabs
                classes={{
                  flexContainer: classes.tabsRoot,
                  indicator: classes.tabIndicator
                }}
                variant="scrollable"
                value={currentTab}
                onChange={(e, newVal) => setCurrentTab(newVal)}
                scrollButtons="auto">
                <StyledTab
                  label="All"
                  value="all"
                  key={`user-flow-shop-detail-tabs-item-all`}
                />
                {servicesWithProducts.map((item, index) => (
                  <StyledTab
                    label={capitalizeStr(item.name)}
                    value={item.id}
                    key={`user-flow-shop-detail-tabs-item-${index}`}
                  />
                ))}
              </Tabs>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid container className={classes.root}>
        {servicesWithProducts.length > 0 ? (
          <>
            {/* category items */}
            <Grid
              container
              item
              xs={12}
              lg={enableCart ? 8 : 12}
              className={classes.itemsContainer}>
              <Grid
                item
                xs={12}
                lg={enableCart ? 6 : 12}
                style={{ marginBottom: '1rem' }}>
                <CustomSearchBar
                  value={searchBy}
                  onChange={handleSearchChange}
                  onClear={handleSearchClear}
                  placeholder="Search products"
                  size="medium"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: { root: classes.searchBox }
                  }}
                />
              </Grid>
              {getSearchedProductQty() === 0 ? (
                <Grid className={classes.notFoundTextContainer}>
                  <Typography variant="h4" className={classes.notFoundText}>
                    No Products Found
                  </Typography>
                </Grid>
              ) : null}
              {filteredServicesWithProducts?.map((item, index) => (
                <Products
                  key={`shop-details-items-to-shop-from-items-${index}`}
                  service={item}
                  dense={!enableCart}
                  serviceIndex={index}
                  searchBy={searchBy}
                />
              ))}
            </Grid>

            {/* order checkout */}
            {enableCart && (
              <Grid item xs={12} lg={4}>
                <CartCard />
              </Grid>
            )}
          </>
        ) : (
          <Grid container justify="center">
            <Typography variant="h3">
              Products and pricing coming soon. Stay tuned...
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
