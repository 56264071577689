import React, { useEffect } from 'react';
import TinySlider from 'tiny-slider-react';
import { AdTemplates } from 'components';
import { makeStyles } from '@material-ui/core/styles';
import 'tiny-slider/dist/tiny-slider.css';
import clsx from 'clsx';
import { useAds } from 'context/ads/adsContext';

const tinySliderSettings = {
  lazyload: true,
  mouseDrag: true,
  autoplayButtonOutput: false,
  autoplayHoverPause: true,
  autoplay: true,
  speed: 500,
  autoplayTimeout: 5000,
  controls: false,
  center: true,
  nav: false,
  autoHeight: true,
  rewind: true
};

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    margin: '0 auto',
    padding: 0,
    [theme.breakpoints.up('md')]: {
      maxWidth: '90%'
    }
  },
  adSlide: {
    position: 'relative',
    textAlign: 'center',
    opacity: 1
  },
  homeBanner: {
    borderRadius: 0,
    [theme.breakpoints.up('md')]: {
      borderRadius: 12
    }
  }
}));

const Banner = () => {
  const classes = useStyles();
  const { homeAds, dispatch } = useAds();

  useEffect(() => {
    if (homeAds.length === 0) {
      dispatch({ type: 'getCurrentOpenAds', payload: { adsType: 'home' } });
    }
  }, []);

  return (
    <div className={classes.root}>
      <TinySlider settings={tinySliderSettings}>
        {homeAds.map((ad, index) => (
          <div
            key={`home-ads-items-${index}`}
            className={clsx(classes.adSlide, 'tns-lazy-img')}>
            <AdTemplates
              ad={ad}
              showAsButton={true}
              className={classes.homeBanner}
            />
          </div>
        ))}
      </TinySlider>
    </div>
  );
};

export default Banner;
