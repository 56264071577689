import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import {
  Checkbox,
  CircularProgress,
  Typography,
  Divider,
  FormControlLabel,
  Grid,
  RadioGroup,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StripeCardPaymentForm } from 'components';
import { PaymentCardDetails } from 'components/organisms';
import StyledRadio from 'components/molecules/StyledRadio';
import { useAmplifyAuth } from 'context';
import { useLoader } from 'layouts/loaderContext';

const useStyles = makeStyles((theme) => ({
  cardDetails: {
    margin: '0.5rem 0',
    padding: '0.8rem 0.5rem',
    border: '1px solid #C4C4C4',
    boxShadow:
      'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
    borderRadius: '0.25rem'
  },
  formControlLabelRoot: {
    margin: '0.5rem 0'
  },
  dividerRoot: {
    backgroundColor: '#ccc'
  },
  cardDetailsForm: {
    padding: '0.5rem 0'
  },
  cardsHeading: {
    marginBottom: '0.5rem',
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 16
  },
  formControlLabel: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 600,
    padding: '0.5rem'
  },
  radioButton: {
    display: 'none'
  }
}));

const StripeCardPaymentOptions = ({
  className = '',
  cardDetails = {},
  setCardDetails = () => {}
}) => {
  const {
    state: { user }
  } = useAmplifyAuth();
  const classes = useStyles();
  const [stripeCustomerID, setStripeCustomerID] = useState(null);
  const [savedMethods, setSavedMethods] = useState([]);
  const { loading, setLoading } = useLoader();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    let mounted = true;
    if (!user) return;
    const getMounted = () => mounted;
    setLoading(true);

    if (!!user.stripeID) setStripeCustomerID(user.stripeID);
    else if (user.id)
      API.post('laundryapi', '/register-stripe-customers', {
        body: {
          customerID: user.id
        }
      })
        .then((resp) => getMounted() && setStripeCustomerID(resp.stripeID))
        .catch((error) => console.error('error ', error))
        .finally(() => setLoading(false));

    return () => {
      mounted = false;
    };
  }, [user]);

  useEffect(() => {
    let mounted = true;
    if (!stripeCustomerID) return;
    const getMounted = () => mounted;
    setLoading(true);

    setCardDetails((_prev) => ({ ..._prev, customerID: stripeCustomerID }));
    API.get('laundryapi', '/payment-methods', {
      queryStringParameters: {
        customerID: stripeCustomerID
      }
    })
      .then((resp) => getMounted() && setSavedMethods(resp.data))
      .catch((error) => console.error('error ', error))
      .finally(() => setLoading(false));

    return () => {
      mounted = false;
    };
  }, [stripeCustomerID]);

  const handleNameChange = (e) =>
    setCardDetails((_prev) => ({
      ..._prev,
      cardName: { value: e.target.value, error: false }
    }));

  return (
    <Grid container direction="column" className={className}>
      {savedMethods.length > 0 ? (
        <Typography variant="h6" className={classes.cardsHeading}>
          Saved Cards
        </Typography>
      ) : null}

      {loading ? (
        <Grid container justify="center">
          <CircularProgress color="secondary" />
        </Grid>
      ) : null}
      <RadioGroup
        aria-label="payment-cards-radio"
        name="payment-cards-radio"
        value={cardDetails.selectedCardID}
        onChange={(_, newVal) =>
          setCardDetails((_prev) => ({ ..._prev, selectedCardID: newVal }))
        }>
        <Grid container style={{ gap: '1rem', marginBottom: '1rem' }}>
          {savedMethods.map((item, index) => {
            return (
              <Grid item xs={sm ? 12 : 5} key={`saved-cards-items-${index}`}>
                <FormControlLabel
                  value={item.id}
                  classes={{ root: classes.formControlLabelRoot }}
                  control={<StyledRadio />}
                  label={
                    <PaymentCardDetails
                      brand={item.card.brand}
                      last4={item.card.last4}
                      exp_month={item.card.exp_month}
                      exp_year={item.card.exp_year}
                    />
                  }
                />
              </Grid>
            );
          })}
        </Grid>
        <Grid item className={classes.cardDetails}>
          <FormControlLabel
            value=""
            classes={{ root: classes.formControlLabelRoot }}
            control={
              <StyledRadio
                color="primary"
                className={savedMethods.length > 0 ? '' : classes.radioButton}
              />
            }
            label={
              <Typography className={classes.formControlLabel}>
                Enter your card details
              </Typography>
            }
          />
          {cardDetails.selectedCardID === '' ? (
            <>
              <Divider classes={{ root: classes.dividerRoot }} />
              <StripeCardPaymentForm
                name={cardDetails.cardName}
                handleNameChange={handleNameChange}
                className={classes.cardDetailsForm}
              />
              <FormControlLabel
                classes={{ root: classes.formControlLabelRoot }}
                control={
                  <Checkbox
                    checked={cardDetails.shouldSaveCard}
                    onChange={(e) =>
                      setCardDetails((_prev) => ({
                        ..._prev,
                        shouldSaveCard: e.target.checked
                      }))
                    }
                    color="primary"
                  />
                }
                label="Save Card Details for future transactions"
              />
            </>
          ) : null}
        </Grid>
      </RadioGroup>
    </Grid>
  );
};

export default StripeCardPaymentOptions;
