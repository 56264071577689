import React, { useEffect } from 'react';
import {
  Switch,
  Redirect,
  Route,
  BrowserRouter,
  useLocation
} from 'react-router-dom';
import {
  Main as MainLayout,
  NonLoggedIn,
  MinimalWithRecaptcha,
  HomeThemeBasic
} from './layouts';
import { routes } from 'common/constants';
import {
  Dashboard,
  Products,
  Customers,
  Users,
  AddUser,
  Shops,
  LaundryShop,
  Services,
  AdsList,
  DummyOrder,
  Orders,
  ForwardOrders,
  ServiceSettings,
  Settings,
  Invoices,
  Drivers,
  Home,
  ShopList,
  ShopDetail,
  Login,
  NotFound,
  Review,
  Profile,
  Account,
  Chat,
  SalesAndMarketing,
  PhotoLibrary,
  Insights,
  SearchBanner,
  OrderHistory,
  Coupons,
  AdminLogin,
  VerifyEmail,
  MyAuctions,
  AboutUs,
  Auctions,
  OrderPayment,
  ShopQRCode
  // SalesDashboard
} from './views';
import WrapWithLayout from 'components/organisms/WrapWithLayout';
import ProtectedRoute from 'components/organisms/ProtectedRoute';
import WrapWithProtectedLayout from 'components/organisms/WrapWithProtectedLayout';
import OrderDetails from 'views/Customers/OrderDetails';
import { Staff } from 'views/ServiceSettings/components';
import ShopsPerformance from 'views/ShopsPerformance';
import ShopAdminReviews from 'views/ShopAdminReviews';
import SelfOnboardingShopForm from 'components/organisms/SelfOnboardingShopForm';

const ProppedRoute = ({ render: C, props: childProps, ...rest }) => (
  <Route {...rest} render={(rProps) => <C {...rProps} {...childProps} />} />
);

// Component to scroll page to top everytime a new path is pushed to history
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

// password protected routes for shop admin, admin
const protectedRoutes = [
  { path: routes.admin.dashboard, component: Dashboard },
  { path: routes.admin.products, component: Products },
  { path: routes.sales.products, component: Products },
  { path: routes.admin.insights, component: Insights },
  { path: routes.admin.searchBanner, component: SearchBanner },
  { path: [routes.admin.settings, routes.sales.settings], component: Settings },
  {
    path: [routes.admin.addUser, routes.admin.addNewUser],
    component: AddUser
  },
  {
    path: [routes.admin.users, routes.admin.usersUser],
    component: Users
  },
  { path: routes.admin.ads, component: AdsList },
  { path: routes.admin.salesMarketing, component: SalesAndMarketing },
  { path: routes.admin.createOrder, component: DummyOrder },
  {
    path: [
      routes.admin.shops,
      routes.admin.shopsAction,
      routes.admin.shopsActionWithID
    ],
    component: Shops
  },
  {
    path: [
      routes.sales.shops,
      routes.sales.shopsAction,
      routes.sales.shopsActionWithID
    ],
    component: Shops
  },
  { path: routes.admin.services, component: Services },
  { path: routes.admin.photoLibrary, component: PhotoLibrary },
  {
    path: [
      routes.serviceProvider.shop,
      routes.serviceProvider.shopDetail,
      routes.serviceProvider.shopWorkingHours,
      routes.serviceProvider.shopCollectionDeliveryHours,
      routes.serviceProvider.shopInstructions,
      routes.serviceProvider.shopDocuments,
      routes.serviceProvider.shopDocumentsAdd,
      routes.serviceProvider.shopPhotos,
      routes.serviceProvider.shopPhotosAdd,
      routes.serviceProvider.shopMap,
      routes.serviceProvider.shopAbout
    ],
    component: LaundryShop
  },
  { path: routes.serviceProvider.productsPricing, component: Products },
  {
    path: [
      routes.serviceProvider.manageStaff,
      routes.serviceProvider.manageStaffAdd
    ],
    component: Staff
  },
  {
    path: [
      routes.serviceProvider.settings,
      routes.serviceProvider.settingsProfile,
      routes.serviceProvider.settingsAccount,
      routes.serviceProvider.settingsPassword
    ],
    component: ServiceSettings
  },
  { path: routes.serviceProvider.drivers, component: Drivers },
  { path: routes.serviceProvider.invoices, component: Invoices },
  { path: routes.admin.shopsPerformance, component: ShopsPerformance },
  { path: routes.admin.customers, component: Customers },
  { path: routes.admin.customerDetail, component: Customers },
  {
    path: routes.admin.customerOrderDetail,
    component: OrderDetails
  },
  {
    path: [
      routes.serviceProvider.orderHistory,
      routes.serviceProvider.orderHistoryMessageDialog
    ],
    component: OrderHistory
  },
  {
    path: [
      routes.serviceProvider.orders,
      routes.serviceProvider.ordersTab,
      routes.serviceProvider.ordersItem,
      routes.serviceProvider.ordersItemWithAction
    ],
    component: Orders
  },
  {
    path: routes.serviceProvider.reviews,
    component: ShopAdminReviews
  },
  {
    path: routes.serviceProvider.forwardRequests,
    component: ForwardOrders
  },
  {
    path: [
      routes.serviceProvider.coupons,
      routes.serviceProvider.couponsAction
    ],
    component: Coupons
  },
  {
    path: [
      routes.serviceProvider.auctionsPending,
      routes.serviceProvider.auctions,
      routes.serviceProvider.auctionsOngoing,
      routes.serviceProvider.auctionsExpired,
      routes.serviceProvider.auctionCompleted
    ],
    component: Auctions
  },
  {
    path: [routes.serviceProvider.qrCode],
    component: ShopQRCode
  }
];

// publicly accessible routes
const unProtectedRoutes = [
  { path: '/not-found', component: NotFound, layout: NonLoggedIn },
  {
    path: routes.customer.login,
    component: Login,
    layout: MinimalWithRecaptcha
  },
  { path: routes.customer.home, component: Home, layout: NonLoggedIn },
  {
    path: [routes.customer.shops, routes.customer.shopsByService],
    component: ShopList,
    layout: NonLoggedIn
  },
  {
    path: routes.customer.shopDetail,
    component: ShopDetail,
    layout: NonLoggedIn
  },
  {
    path: routes.customer.aboutUs,
    component: AboutUs,
    layout: NonLoggedIn
  },
  {
    path: routes.customer.businessWithUs,
    component: SelfOnboardingShopForm,
    layout: NonLoggedIn
  }
];

// password protected routes for customer
const protectedUserRoutes = [
  { path: routes.customer.profile, component: Profile, layout: NonLoggedIn },
  {
    path: [routes.customer.checkout, routes.customer.auctionCheckout],
    component: Review,
    layout: NonLoggedIn
  },
  {
    path: [routes.customer.order, routes.customer.orderHistory],
    component: Account,
    layout: NonLoggedIn
  },
  {
    path: routes.customer.orderPayment,
    component: OrderPayment,
    layout: HomeThemeBasic
  },
  {
    path: [
      routes.customer.auction,
      routes.customer.auctionPending,
      routes.customer.auctionExpired,
      routes.customer.auctionCompleted
    ],
    component: MyAuctions,
    layout: NonLoggedIn
  },
  { path: routes.customer.chat, component: Chat, layout: NonLoggedIn }
];

const Routes = ({ childProps }) => (
  <BrowserRouter>
    <ScrollToTop />

    <Switch>
      <Redirect exact from="/" to={routes.customer.home} />
      <ProppedRoute path="/auth" props={childProps} render={AdminLogin} />
      <ProppedRoute
        path="/verify-email"
        props={childProps}
        render={VerifyEmail}
      />
      {protectedRoutes.map((prop, key) => (
        <ProtectedRoute
          exact
          key={key}
          layout={prop.layout || MainLayout}
          path={prop.path}
          props={childProps}
          render={() => <prop.component />}
        />
      ))}
      {protectedUserRoutes.map((prop, key) => (
        <Route
          exact
          key={key}
          path={prop.path}
          props={childProps}
          render={(props) => (
            <WrapWithProtectedLayout
              component={prop.component}
              layout={prop.layout}
              rest={props}
            />
          )}
        />
      ))}
      {unProtectedRoutes.map((prop, key) => (
        <Route
          exact
          key={key}
          path={prop.path}
          props={childProps}
          render={(props) => (
            <WrapWithLayout
              Comp={prop.component}
              Layout={prop.layout}
              props={props}
            />
          )}
        />
      ))}
      <Redirect to="/not-found" />
    </Switch>
  </BrowserRouter>
);
export default Routes;
