import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid
} from '@material-ui/core';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import MUIRichTextEditor from 'mui-rte';

const useStyles = makeStyles(() => ({
  rteEditor: { padding: '0.25rem 0.875rem' },
  rtePlaceHolder: {
    position: 'inherit',
    padding: '0.25rem 0.875rem',
    color: '#ccc'
  },
  rteContainer: {
    minHeight: '5rem',
    borderRadius: '0.25rem',
    padding: '0 1rem'
  }
}));

const DropPickupInstructions = (props) => {
  const classes = useStyles();
  const collection = useRef();
  const delivery = useRef();
  const [showRtes, setShowRtes] = useState(false);
  const { selectedShop, onCancel, handleSubmit: _handleSubmit } = props;

  useEffect(() => {
    collection.current = !!selectedShop?.collectionInstructions
      ? EditorState.createWithContent(
          convertFromRaw(JSON.parse(selectedShop.collectionInstructions))
        )
      : EditorState.createEmpty();
    delivery.current = !!selectedShop?.deliveryInstructions
      ? EditorState.createWithContent(
          convertFromRaw(JSON.parse(selectedShop.deliveryInstructions))
        )
      : EditorState.createEmpty();
    setShowRtes(true);
  }, [selectedShop]);

  const handleCollectionUpdate = (newEditor) => {
    collection.current = newEditor;
  };

  const handleDeliveryUpdate = (newEditor) => {
    delivery.current = newEditor;
  };

  const handleSubmit = (e) =>
    _handleSubmit(e, {
      collectionInstructions: JSON.stringify(
        convertToRaw(collection.current.getCurrentContent())
      ),
      deliveryInstructions: JSON.stringify(
        convertToRaw(delivery.current.getCurrentContent())
      )
    });

  return showRtes ? (
    <>
      <CardHeader
        title="Drop Instructions"
        subheader="Shop Drop Instructions"
      />

      <Divider />

      <CardContent className={classes.rteContainer}>
        {collection.current ? (
          <MUIRichTextEditor
            label="drop instructions"
            controls={[
              'title',
              'bold',
              'italic',
              'underline',
              'strikethrough',
              'highlight',
              'undo',
              'redo',
              'numberList',
              'bulletList',
              'quote',
              'code',
              'clear'
            ]}
            defaultValue={JSON.stringify(
              convertToRaw(
                collection.current.getCurrentContent() ||
                  collection.current.currentContent
              )
            )}
            onChange={handleCollectionUpdate}
            classes={{
              editor: classes.rteEditor,
              placeHolder: classes.rtePlaceHolder
            }}
          />
        ) : null}
      </CardContent>

      <Divider />

      <CardHeader
        title="Pickup Instructions"
        subheader="Shop Pickup Instructions"
      />
      <Divider />
      <CardContent className={classes.rteContainer}>
        {delivery.current ? (
          <MUIRichTextEditor
            label="pickup instructions"
            controls={[
              'title',
              'bold',
              'italic',
              'underline',
              'strikethrough',
              'highlight',
              'undo',
              'redo',
              'numberList',
              'bulletList',
              'quote',
              'code',
              'clear'
            ]}
            defaultValue={JSON.stringify(
              convertToRaw(
                delivery.current.getCurrentContent() ||
                  delivery.current.currentContent
              )
            )}
            onChange={handleDeliveryUpdate}
            classes={{
              editor: classes.rteEditor,
              placeHolder: classes.rtePlaceHolder
            }}
          />
        ) : null}
      </CardContent>

      <Divider />

      <CardActions>
        <Grid container justify="flex-end" alignItems="center">
          <Button
            color="primary"
            size="small"
            onClick={handleSubmit}
            variant="contained">
            Save
          </Button>
        </Grid>
      </CardActions>
    </>
  ) : null;
};

export default DropPickupInstructions;
