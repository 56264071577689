import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { useTheme } from '@material-ui/core/styles';
import { useAmplifyAuth } from 'context';
import { getOrderTotalRating } from 'common/utilFunctions';
import ShopSingleReview from './ShopSingleReview';

const ShopReviewList = ({
  reviews = [],
  shopReviews = [],
  heading = 'Customer Reviews'
}) => {
  const [page, setPage] = useState(1);
  const {
    state: { isLaundryAdmin, isLaundryStaff }
  } = useAmplifyAuth();
  const history = useHistory();
  const md = useMediaQuery(useTheme().breakpoints.up('md'));
  const isShopAdminView =
    history.location.pathname.includes('/service-provider') &&
    (isLaundryAdmin || isLaundryStaff);
  const ITEMS_PER_PAGE = 3;
  const noOfPages = reviews && Math.ceil(reviews.length / ITEMS_PER_PAGE);
  const orderReviews = reviews
    .filter((review) => review.orderID)
    .sort((a, b) => b._lastChangedAt - a._lastChangedAt);
  const filteredReview = [...shopReviews, ...orderReviews];

  const paginationHandler = (_, value) => {
    setPage(value);
  };

  return (
    <Grid item xs={12} md={isShopAdminView ? 12 : 8}>
      <Typography
        variant="h3"
        style={{ marginBottom: md ? '1.1rem' : '0.5rem' }}>
        {heading}
      </Typography>
      <Grid container direction="column">
        {filteredReview
          .slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)
          .map((item, index) => {
            const ratings = getOrderTotalRating(item.ratings);
            return (
              <ShopSingleReview
                ratings={ratings}
                item={item}
                isShopAdminView={isShopAdminView}
                key={`shop-reviews-details-items-${index}`}
              />
            );
          })}
        {reviews.length === 0 ? (
          <Typography variant="h6">No reviews received yet</Typography>
        ) : null}
      </Grid>
      {reviews.length > ITEMS_PER_PAGE ? (
        <Grid
          container
          item
          xs={12}
          justify="center"
          style={{ marginTop: '1rem' }}>
          <Pagination
            count={noOfPages}
            page={page}
            onChange={paginationHandler}
            defaultPage={1}
            color="secondary"
            shape="rounded"
            size="small"
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ShopReviewList;
