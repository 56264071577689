import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import DistanceIcon from '../assets/Distance.png';
import { ShopImage } from 'components/organisms';
import {
  capitalizeStr,
  getShopTotalRating as getTotalRating
} from 'common/utilFunctions';
import { useAmplifyAuth } from 'context';
import { useFavouriteShops } from 'context/favouriteShops/favouriteShopsContext';
import CatRewards from 'assets/svg/rewardsSvg.svg';
import { debounce } from 'lodash';
import clsx from 'clsx';
import StarsIcon from '@material-ui/icons/Stars';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const useStyles = makeStyles((theme) => ({
  '@keyframes bounce': {
    '0%, 100%': {
      transform: 'translateY(0)'
    },
    '50%': {
      transform: 'translateY(-10px)'
    }
  },
  favouriteButton: {
    color: theme.palette.favourite.main,
    animation: '$bounce 0.4s ease-in-out',
    height: '16px',
    width: '16px'
  },
  rating: {
    fontSize: '13px',
    maxWidth: 'fit-content',
    gap: '5px',
    fontWeight: 600
  },
  ratingCount: {
    fontSize: '10px',
    fontWeight: 600,
    color: '#15893e',
    borderRadius: '50%',
    height: '23px',
    width: '23px'
  },
  cardFooter: {
    height: '2.375rem',
    padding: '10px 0'
  },
  picture: {
    width: 'calc(100vw * 0.9)',
    [theme.breakpoints.up('sm')]: {
      height: '122px',
      width: '218px'
    }
  },
  shopName: {
    color: '#444',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '90%',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      padding: '0 0.5rem'
    }
  },
  onHoldContainer: { position: 'relative' },
  freeCollAndDelBox: {
    backgroundColor: '#99ccee44',
    marginTop: '0.3rem',
    padding: '0.3rem',
    borderRadius: '4px'
  },
  closedText: {
    position: 'absolute',
    top: '35%',
    left: '25%',
    fontSize: '24px',
    fontWeight: '600',
    color: '#fff',
    textShadow: '2px 1px #000',
    [theme.breakpoints.down('sm')]: {
      left: '38%'
    }
  },
  shippingOffer: {
    fontSize: 11,
    fontWeight: 600,
    color: '#444'
  },
  range: {
    margin: '0 1rem 0 0.2rem',
    fontWeight: 600
  }
}));
const CURRENT_TIME = new Date().toISOString();

const debouncedFavouriteShopDispatcher = debounce((payload, dispatch) => {
  if (!payload.id) dispatch({ type: 'createFavouriteShops', payload });
  else dispatch({ type: 'updateFavouriteShops', payload });
}, 2000);

const ShopCardContent = ({ item, serviceID = '', disableShop = false }) => {
  const classes = useStyles();
  const { favouriteShops, dispatch } = useFavouriteShops();
  const [checked, setChecked] = useState({ isFavourite: false });
  let {
    state: { user }
  } = useAmplifyAuth();
  const SHOW_OFFERS_TOOLTIP =
    item.coupons?.items?.filter(
      (item) =>
        !item._deleted &&
        item.startDateTime < CURRENT_TIME &&
        CURRENT_TIME < item.endDateTime
    ).length > 0;

  useEffect(() => {
    const checkedShop = favouriteShops.find((shop) => shop.shopID === item.id);
    checkedShop && setChecked(checkedShop);
  }, [favouriteShops]);

  const favouriteShopHandler = (e) => {
    e.stopPropagation();
    const newVal = {
      shopID: item.id,
      userID: user.id,
      isFavourite: e.target.checked
    };
    if (checked.id) {
      newVal.id = checked.id;
      newVal._version = checked._version;
    }
    setChecked((prev) => ({ ...prev, ...newVal }));
    debouncedFavouriteShopDispatcher(newVal, dispatch);
  };

  return (
    <>
      <Box className={classes.onHoldContainer}>
        <ShopImage
          shop={item}
          serviceID={serviceID}
          width="100%"
          className={classes.picture}
        />
        {disableShop ? (
          <Grid container className={clsx(classes.picture, classes.closedText)}>
            CLOSED
          </Grid>
        ) : null}
      </Box>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        item
        style={{
          opacity: disableShop ? 0.5 : 1
        }}>
        <Grid container item alignItems="center" xs={10}>
          <Tooltip title={capitalizeStr(item.name)} placement="bottom">
            <Typography variant="subtitle2" className={classes.shopName}>
              {capitalizeStr(item.name)}
            </Typography>
          </Tooltip>
        </Grid>
        {user && user.username && (
          <Grid container item justify="flex-end" alignItems="center" xs={2}>
            <FormControlLabel
              onClick={favouriteShopHandler}
              control={
                <Checkbox
                  checked={checked.isFavourite}
                  icon={
                    <FavoriteBorder
                      fontSize="small"
                      className={`${classes.favouriteButton} ${
                        checked ? 'checked' : ''
                      }`}
                    />
                  }
                  checkedIcon={
                    <Favorite
                      fontSize="small"
                      className={`${classes.favouriteButton} ${
                        checked ? 'checked' : ''
                      }`}
                    />
                  }
                  name="shop-favourite-check"
                />
              }
              style={{ marginRight: 0 }}
            />
          </Grid>
        )}
      </Grid>
      <Grid
        container
        justify="flex-start"
        className={classes.cardFooter}
        alignItems="center"
        style={{
          opacity: disableShop ? 0.3 : 1
        }}>
        {(!!item.range || item.range === 0) && (
          <>
            <LocationOnIcon style={{ color: '#1f6893' }} />
            <Typography
              variant="body2"
              align="center"
              color="secondary"
              className={classes.range}>
              {item.range > 152
                ? (item.range / 1609).toFixed(2) + ' mi'
                : Math.ceil(item.range * 3.281) + ' ft'}
            </Typography>
          </>
        )}
        {/* ---- show rating ---- */}
        {item.reviews?.items?.length > 0 && (
          <Grid
            container
            item
            justify="space-evenly"
            alignItems="center"
            className={classes.rating}>
            <Grid
              item
              container
              alignItems="center"
              justify="center"
              className={classes.ratingCount}>
              <StarsIcon />
            </Grid>
            {getTotalRating(item.reviews.items) || 0}
          </Grid>
        )}
        {/* offers text */}
        {SHOW_OFFERS_TOOLTIP && (
          <Tooltip title="Offers available for this Shop" placement="bottom">
            <Grid
              container
              item
              alignItems="center"
              className={classes.rating}
              style={{ marginLeft: '0.8rem' }}>
              <img src={CatRewards} height="23" width={23} alt="offers image" />
              Offers
            </Grid>
          </Tooltip>
        )}
      </Grid>
      {!!(item.minPriceForFree || item.distanceRangeForFree) ? (
        <Grid
          container
          alignItems="center"
          className={classes.freeCollAndDelBox}>
          <Typography variant="body1" className={classes.shippingOffer}>
            * Free Collection &amp; Delivery Available
          </Typography>
        </Grid>
      ) : null}
    </>
  );
};

export default ShopCardContent;
