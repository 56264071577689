import { createContext, useCallback, useContext, useReducer } from 'react';
import { getNotifications, markNotificationsAsRead } from './notificationsApi';
import notificationsReducer from './notificationsReducer';
import { useLoader } from 'layouts/loaderContext';

const NotificationsContext = createContext({});

const NotificationsProvider = ({ children }) => {
  const [notifications, dispatch] = useReducer(notificationsReducer, []);
  const { setLoading } = useLoader();

  const asyncDispatch = useCallback(
    async (action) => {
      switch (action.type) {
        case 'getNotifications': {
          const notifications = await getNotifications(action.payload);
          dispatch({
            type: 'updateData',
            payload: notifications
          });
          return notifications;
        }
        case 'addNewNotification': {
          const newNotification = action.payload;
          dispatch({
            type: 'addNewNotification',
            payload: newNotification
          });
          break;
        }
        case 'markNotificationsAsRead': {
          const { notificationId } = action.payload;
          try {
            setLoading(true);
            if (!notificationId) {
              const allNotificationIds = notifications.map((item) => item.id);
              await markNotificationsAsRead({
                notificationIDs: allNotificationIds
              });
              dispatch({
                type: 'updateData',
                payload: []
              });
            } else {
              await markNotificationsAsRead({
                notificationIDs: [notificationId]
              });
              dispatch({
                type: 'updateData',
                payload: notifications.filter(
                  (_item) => _item.id !== notificationId
                )
              });
            }
          } catch (error) {
            console.error(
              'Something went wrong while marking notifications as read... ',
              error
            );
          } finally {
            setLoading(false);
          }
          break;
        }

        default:
          dispatch(action);
      }
    },
    [notifications]
  );

  const value = {
    notifications,
    dispatch: asyncDispatch
  };

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};

const useNotifications = () => {
  const context = useContext(NotificationsContext);
  if (context === undefined) {
    throw new Error(
      'useNotifications must be used within a NotificationsContext'
    );
  }
  return context;
};

export { useNotifications, NotificationsProvider };
