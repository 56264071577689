import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  Modal,
  Backdrop,
  Fade,
  Container,
  IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { useMessages } from 'context/messages/messagesContext';
import { ChatMessageBody } from 'components/organisms';
import { Image } from 'components/organisms';
import { getFormattedPhoneNumber } from 'common/utilFunctions';
import defaultUserImage from 'assets/png/default-image-user.png';

const useStyles = makeStyles((theme) => ({
  chatHeaderContainer: {
    backgroundColor: '#4094EA',
    padding: '1.5rem 0',
    borderRadius: '0.25rem 0.25rem 0 0'
  },
  chatHeader: {
    padding: '0 1rem'
  },
  customerImage: {
    height: 40,
    width: 40,
    borderRadius: '50%'
  },
  closeBtn: {
    padding: '0.25rem',
    margin: '0 1rem -2rem 0',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: 'grey'
    }
  },
  chatBody: {
    borderRadius: '0 0 0.25rem 0.25rem'
  }
}));

const MessageDialog = ({ open, handleModalClose, order = {} }) => {
  const classes = useStyles();
  const { dispatch } = useMessages();

  useEffect(() => {
    if (open && order?.id)
      dispatch({
        type: 'markMessagesAsReadByOrder',
        payload: { orderID: order?.id }
      });
  }, [order, open]);

  return (
    <Modal
      aria-describedby="message-modal-description"
      aria-labelledby="message-modal-title"
      disableEscapeKeyDown
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition
      onClose={handleModalClose}
      open={open}>
      <Container
        maxWidth="sm"
        style={{
          padding: '1rem'
        }}>
        <Fade in={open}>
          <Grid container justify="center" alignItems="center">
            {/* header */}
            <Grid container className={classes.chatHeaderContainer}>
              <Grid container justify="flex-end" alignItems="center">
                <IconButton
                  color="primary"
                  onClick={handleModalClose}
                  className={classes.closeBtn}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={10}
                alignItems="center"
                className={classes.chatHeader}>
                <Grid container item xs={2}>
                  <Image
                    docKey={order.userDetail?.picture}
                    onError={(e) => (e.target.src = defaultUserImage)}
                    className={classes.customerImage}
                  />
                </Grid>
                <Grid container direction="column" item xs={10}>
                  <Typography style={{ color: 'yellow' }} variant="h5">
                    #{order.orderID || ''}
                  </Typography>

                  <Typography variant="h6" style={{ color: 'white' }}>
                    {order.userDetail?.firstName} {order.userDetail?.lastName}
                  </Typography>

                  <Typography style={{ color: 'white' }} variant="body2">
                    {order.userDetail?.email}
                    <br />
                    {getFormattedPhoneNumber(order.userDetail?.phoneNumber)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* content */}
            <Grid
              container
              style={{
                minHeight: '50vh'
              }}>
              <ChatMessageBody
                orderActive={order}
                showSenderName
                className={classes.chatBody}
              />
            </Grid>
          </Grid>
        </Fade>
      </Container>
    </Modal>
  );
};

export default MessageDialog;
