import React from 'react';
import GenerateConsentLetterPDF from './GenerateConsentLetterPDF';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { PDFViewer } from '@react-pdf/renderer';
import { ArrowBackIos } from '@material-ui/icons';

const PDFDownloadView = ({
  title = '',
  shops = [],
  handleCancel = () => {}
}) => {
  return (
    <Grid container>
      <Grid container item xs={12} alignItems="center">
        <IconButton onClick={handleCancel}>
          <ArrowBackIos />
        </IconButton>
        <Typography variant="h3">{title}</Typography>
      </Grid>
      <Grid container item xs={12} style={{ minHeight: '65vh' }}>
        <PDFViewer width="100%" showToolbar>
          <GenerateConsentLetterPDF shops={shops} />
        </PDFViewer>
      </Grid>
    </Grid>
  );
};

export default PDFDownloadView;
