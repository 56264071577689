import React, { useState, useEffect } from 'react';
import { Grid, Typography, Paper, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';
import { getShopTotalRating as getTotalRating } from 'common/utilFunctions';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0.625rem',
    gap: '0 1rem',
    [theme.breakpoints.up('md')]: { gap: '0 0.25rem' },
    [theme.breakpoints.up('lg')]: {
      gap: '0 1rem'
    }
  },
  currentRatingBox: {
    backgroundColor: '#EDD242',
    height: '4.375rem',
    minWidth: '5.5rem',
    borderRadius: '0.25rem',
    [theme.breakpoints.up('md')]: { minWidth: '4rem' },
    [theme.breakpoints.up('lg')]: { minWidth: '5.5rem' }
  },
  blueText: {
    color: theme.palette.blue?.main || theme.palette.secondary.main
  }
}));

const getTotalReviewText = (rating) =>
  rating >= 4.5
    ? 'Excellent'
    : rating >= 4
    ? 'Very Good'
    : rating >= 3.5
    ? 'Good'
    : rating >= 2.5
    ? 'Moderate'
    : rating >= 1.5
    ? 'Bad'
    : 'Very Bad';

const ShopTotalRatingCard = ({
  reviewItems = [],
  showAnchor = false,
  className = ''
}) => {
  const classes = useStyles();
  const [totalRating, setTotalRating] = useState(null);

  useEffect(() => {
    if (reviewItems.length > 0) setTotalRating(getTotalRating(reviewItems));
  }, [reviewItems]);

  return (
    <Grid
      container
      alignItems="center"
      component={Paper}
      className={clsx(classes.root, className)}>
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        style={{ width: 'fit-content' }}
        className={classes.currentRatingBox}>
        {reviewItems.length > 0 ? (
          <>
            <Typography
              variant="h3"
              style={{ fontWeight: '900', marginBottom: '3px' }}>
              {totalRating || 0}
            </Typography>
            <Typography variant="h6">
              Out of 5
              <StarIcon
                style={{
                  fontSize: '14px',
                  paddingTop: '3px'
                }}
              />
            </Typography>
          </>
        ) : (
          <Typography variant="h6">
            No ratings
            <br />
            yet
          </Typography>
        )}
      </Grid>
      <Grid container direction="column" style={{ width: 'fit-content' }}>
        {reviewItems.length > 0 ? (
          <>
            <Typography variant="h6" style={{ fontWeight: '900' }}>
              {getTotalReviewText(totalRating)}
            </Typography>
            <Typography variant="body2">
              Based on {reviewItems.length} Reviews
            </Typography>
            {showAnchor ? (
              <Link
                href="#anchor-to-shop-reviews-footer"
                className={classes.blueText}>
                Read Reviews
              </Link>
            ) : null}
          </>
        ) : (
          <Typography variant="h6" style={{ fontWeight: '900' }}>
            No reviews yet
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default ShopTotalRatingCard;
