import React, { useCallback, useContext, useReducer } from 'react';
import { useLoader } from 'layouts/loaderContext';
import { API, graphqlOperation } from 'aws-amplify';
import { listAds } from 'graphql/queries';
import { deleteAd, updateAd, createAd } from 'graphql/mutations';
import { useSnackbar } from 'notistack';
import { getCurrentOpenAds } from 'graphql/customQueries';
import { adsReducer, homeAdsReducer, listAdsReducer } from './adsReducer';

const AdsContext = React.createContext({});

const AdsProvider = ({ children }) => {
  const { setLoading } = useLoader();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [ads, dispatch] = useReducer(adsReducer, []);
  const [homeAds, dispatchHomeAds] = useReducer(homeAdsReducer, []);
  const [shopListAds, dispatchListAds] = useReducer(listAdsReducer, []);

  const asyncDispatch = useCallback(
    async (action) => {
      switch (action.type) {
        case 'getAds': {
          setLoading(true);
          const snackBar = enqueueSnackbar('Loading Ads...', {
            variant: 'info',
            persist: true
          });
          try {
            const resp = await API.graphql(
              graphqlOperation(listAds, { limit: 5000 })
            );
            const data = resp.data.listAds.items.filter(
              (item) => !item._deleted
            );
            dispatch({
              type: 'updateData',
              payload: data
            });
          } catch (e) {
            console.error(e);
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2000
            });
          } finally {
            setLoading(false);
            closeSnackbar(snackBar);
          }
          break;
        }
        case 'getCurrentOpenAds': {
          const { adsType } = action.payload;
          try {
            const resp = await API.graphql(
              graphqlOperation(getCurrentOpenAds, { page: adsType })
            );
            const data = resp.data.getCurrentOpenAds?.filter(
              (item) => !item._deleted
            );

            if (adsType === 'home') {
              dispatchHomeAds({ type: 'updateData', payload: data });
            } else if (adsType === 'list') {
              dispatchListAds({ type: 'updateData', payload: data });
            }
          } catch (e) {
            console.error(e);
          }
          break;
        }
        case 'createAd': {
          const { input } = action.payload;
          try {
            setLoading(true);
            const resp = await API.graphql(
              graphqlOperation(createAd, { input })
            );
            const data = resp.data.createAd;
            dispatch({
              type: 'addData',
              payload: [data]
            });
            enqueueSnackbar('Ad created successfully', {
              variant: 'success',
              autoHideDuration: 2000
            });
          } catch (e) {
            console.log('error', e);
            enqueueSnackbar('Something went wrong..!', {
              variant: 'error',
              autoHideDuration: 2000
            });
          } finally {
            setLoading(false);
          }
          break;
        }
        case 'updateAd': {
          const { input } = action.payload;
          try {
            setLoading(true);
            const resp = await API.graphql(
              graphqlOperation(updateAd, { input })
            );
            const data = [
              ...ads.filter((ad) => ad?.id !== input.id),
              resp.data.updateAd
            ];
            dispatch({ type: 'updateData', payload: data });
            if (input.isClosed) {
              enqueueSnackbar('Ad closed successfully', {
                variant: 'success',
                autoHideDuration: 2000
              });
            } else {
              enqueueSnackbar('Ad updated successfully', {
                variant: 'success',
                autoHideDuration: 2000
              });
            }
          } catch (e) {
            console.log(e);
            enqueueSnackbar('Something went wrong..!', {
              variant: 'error',
              autoHideDuration: 2000
            });
          } finally {
            setLoading(false);
          }
          break;
        }
        case 'deleteAd': {
          const { id, _version } = action.payload;
          try {
            setLoading(true);
            const input = { id, _version };
            await API.graphql(graphqlOperation(deleteAd, { input }));
            const data = ads.filter((ad) => ad?.id !== id);
            enqueueSnackbar('Ad deleted successfully', {
              variant: 'success',
              autoHideDuration: 2000
            });
            dispatch({ type: 'updateData', payload: data });
          } catch (e) {
            console.log(e);
            enqueueSnackbar('Something went wrong..!', {
              variant: 'error',
              autoHideDuration: 2000
            });
          } finally {
            setLoading(false);
          }
          break;
        }
        default: {
          dispatch(action);
        }
      }
    },
    [ads]
  );

  const value = {
    ads,
    homeAds,
    shopListAds,
    dispatch: asyncDispatch
  };

  return <AdsContext.Provider value={value}>{children}</AdsContext.Provider>;
};

const useAds = () => {
  const context = useContext(AdsContext);
  if (!(context && Object.keys(context).length)) {
    throw new Error('useAds must be used within a AdsContext');
  }
  return context;
};

export { AdsProvider, useAds };
