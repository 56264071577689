import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles((theme) => ({
  root: {
    zIndex: '2',
    position: 'sticky',
    backgroundColor: theme.palette.common.white
  }
}))((props) => <TableCell {...props} />);

export default StyledTableCell;
