import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ItemizeProduct from '../itemize/ItemizeProduct';
import { getFormattedPriceRange } from 'common/utilFunctions';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    margin: '1rem'
  },
  dialogPaperFullWidth: {
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 16px)'
    }
  },
  dialogContent: {
    [theme.breakpoints.down('xs')]: {
      padding: '0.25rem'
    }
  }
}));

export function ItemisedDialog({
  onSuccess = () => {},
  order,
  dialogType,
  handleModalClose = () => {}
}) {
  const classes = useStyles();
  const [currentOrder, setCurrentOrder] = useState({});
  const [prevTotal, setPrevTotal] = useState(0);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClose = () => {
    setCurrentOrder(order);
    handleModalClose();
  };

  const prevTotalChangeHandler = (val) => {
    setPrevTotal(val);
  };

  useEffect(() => {
    setCurrentOrder(order);
    prevTotalChangeHandler(order?.total || 0);
  }, [order]);

  const handleSubmit = () => {
    // check if some item doesn't have price
    const itemWithoutPrice = currentOrder.orderList.find(
      (_item) => !!_item.priceRange && !_item.price
    );
    if (itemWithoutPrice) {
      enqueueSnackbar(`Enter valid price for \"${itemWithoutPrice.name}\"`, {
        variant: 'error',
        preventDuplicate: true,
        persist: true
      });
      return;
    }

    // check if some item doesn't have price in range
    const itemWithoutPriceInRange = currentOrder.orderList.find((_item) => {
      if (!!(_item.priceRange && _item.price)) {
        const priceRangeArr = _item.priceRange
          .split('-')
          .filter((val) => !!val)
          .map((val) => parseFloat(val));
        if (
          priceRangeArr[0] <= _item.price &&
          (priceRangeArr[1] ? _item.price <= priceRangeArr[1] : true)
        )
          return false;
        return true;
      }
      return false;
    });
    if (itemWithoutPriceInRange) {
      enqueueSnackbar(
        `Price for \"${
          itemWithoutPriceInRange.name
        }\" should be within range - ${getFormattedPriceRange(
          itemWithoutPriceInRange.priceRange
        )}`,
        {
          variant: 'error',
          preventDuplicate: true,
          persist: true
        }
      );
      return;
    }

    // update order
    const payloadOrder = {
      ...currentOrder,
      orderList: currentOrder.orderList.map((_item) => ({
        ..._item,
        price: parseFloat(_item.price),
        priceRange: ''
      })),
      variableTotal: ''
    };
    if (order?.paymentStatus === 'paid' && currentOrder?.total > prevTotal)
      payloadOrder.paymentStatus = 'partiallyPaid';
    onSuccess({ order: payloadOrder, sts: 'itemized' });
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialogPaper,
        paperFullWidth: classes.dialogPaperFullWidth
      }}
      fullWidth={true}
      open={dialogType === 'itemized'}
      maxWidth="lg"
      onClose={handleClose}>
      <DialogContent className={classes.dialogContent}>
        <ItemizeProduct order={currentOrder} setOrder={setCurrentOrder} />
      </DialogContent>
      <DialogActions style={{ alignSelf: 'center' }}>
        <Button autoFocus onClick={handleClose} color="primary" align="center">
          Close
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          align="center">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ItemisedDialog.propTypes = {
  declineOrder: PropTypes.func,
  handleClose: PropTypes.func
};
