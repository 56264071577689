import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { IconButton, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  iconButton: {
    borderRadius: '4em',
    margin: theme.spacing(1),
    backgroundColor: theme.palette.select,
    padding: '5px 10px 5px 15px'
  },
  iconButtonSelected: {
    color: '#EEEEEE',
    backgroundColor: '#000000'
  },
  text: {
    color: 'inherit'
  }
}));

export default function Select(props) {
  const classes = useStyles();
  return (
    <IconButton
      value={props.value}
      className={
        props.isSelected
          ? `${classes.iconButton} ${classes.iconButtonSelected}`
          : classes.iconButton
      }
      onClick={event => props.onClick(event)}>
      <Typography className={classes.text}>{props.children}</Typography>
      <ArrowDropDownIcon />
    </IconButton>
  );
}
