import React from 'react';
import { Grid, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useShops } from 'context/shop/shopsContext';
import { StyledRating } from 'components/molecules';
import { RATING_CATEGORIES, STAR_VALUE_TO_PERCENTAGE } from 'common/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '2rem',
    borderBottom: '1px solid #ccc',
    paddingBottom: '1rem'
  },
  heading: {
    marginBottom: '2.5rem',
    [theme.breakpoints.up('md')]: {
      marginBottom: '1.5rem'
    }
  },
  progressBarRoot: {
    height: '0.625rem !important',
    borderRadius: '1.5rem'
  },
  progressBarColor: {
    backgroundColor: '#EDD242 !important'
  },
  progressBarBgColor: {
    backgroundColor: '#E5E5E5 !important'
  }
}));

const ShopAdminReviewMatrix = () => {
  const classes = useStyles();
  const { selectedShop } = useShops();
  const reviews = selectedShop?.reviews.items || [];
  const shopReviews = reviews
    .filter((review) => !review?.orderID)
    .sort((a, b) => b?._lastChangedAt - a?._lastChangedAt);
  const totalRating = +selectedShop?.rating?.rating.toFixed(1) || 0;
  const noOfRatings = selectedShop?.rating?.ratingsCount || 0;

  return (
    <Grid container justify="space-between" className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h3" className={classes.heading}>
          Overall Rating
        </Typography>
      </Grid>
      <Grid
        direction="column"
        container
        item
        md={2}
        xs={12}
        justify="center"
        alignItems="center">
        <Typography
          variant="h2"
          style={{ fontSize: 50, marginBottom: '0.5rem' }}>
          {totalRating}
        </Typography>
        <StyledRating
          name="read-only-customer-rating"
          value={totalRating}
          readOnly
          style={{ marginBottom: '0.5rem' }}
        />
        <Typography variant="body1">{noOfRatings} Reviews</Typography>
      </Grid>

      <Grid item md={10} xs={12}>
        {RATING_CATEGORIES.map((item) => ({
          label: item,
          value:
            Math.round(
              (shopReviews.reduce(
                (total, review) =>
                  total +
                    review.ratings?.find((rating) => rating?.title === item)
                      ?.rating || 0,
                0
              ) /
                (shopReviews.length || 1)) *
                10
            ) / 10
        })).map((item, index) => (
          <Grid
            container
            alignItems="center"
            key={`shop-detail-cutomer-reviews-categories-items-${index}`}
            style={{ gap: '1rem', marginBottom: '0.5rem' }}>
            <Grid item md={2} xs={12}>
              <Typography variant="h6">{item.label}</Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <LinearProgress
                variant="determinate"
                value={item.value * STAR_VALUE_TO_PERCENTAGE}
                classes={{
                  root: classes.progressBarRoot,
                  barColorPrimary: classes.progressBarColor,
                  colorPrimary: classes.progressBarBgColor
                }}
              />
            </Grid>
            <Grid item md={1} xs={12} style={{ textAlign: 'right' }}>
              <Typography variant="body1">
                {item.value > 0 ? `${item.value} star` : 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ShopAdminReviewMatrix;
