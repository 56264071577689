import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Grid,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMessages } from 'context/messages/messagesContext';
import { getFormattedName } from 'common/utilFunctions';
import { Image } from 'components/organisms';
import { TABS } from 'views/Orders/constants';
import { routes } from 'common/constants';
import defaultUserImage from 'assets/png/default-image-user.png';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  customerImage: {
    height: 40,
    width: 40,
    borderRadius: '50%'
  },
  menu: {
    marginTop: '2rem',
    maxWidth: '16rem',
    minWidth: '15.5rem',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '18.75rem',
      minWidth: '18rem'
    }
  },
  notificationMessageContent: {
    '& > p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '& #orderID': {
      color: theme.palette.primary.main,
      border: `0.5px solid ${theme.palette.primary.light}`,
      padding: '0.125rem 0.25rem',
      borderRadius: '0.125rem'
    }
  }
}));

const MessageNotificationsMenu = ({ anchorEl, handleClose = () => {} }) => {
  const classes = useStyles();
  const { messageNotifications } = useMessages();

  return (
    <Menu
      anchorEl={anchorEl}
      onClick={handleClose}
      onClose={handleClose}
      open={!!anchorEl}
      classes={{ paper: classes.menu }}>
      {messageNotifications.length > 0
        ? messageNotifications.map((item, index) => (
            <MenuItem
              component={RouterLink}
              to={`${
                TABS.find((tab) =>
                  tab.orderStatuses.includes(item.message?.order.status)
                )?.route || routes.serviceProvider.orderHistory
              }/${item.message?.order.orderID}/messages`}
              key={`shop-admin-topbar-message-items-${index}`}>
              <ListItemAvatar>
                <Image
                  docKey={item.message?.senderDetails.picture}
                  onError={(e) => (e.target.src = defaultUserImage)}
                  className={classes.customerImage}
                />
              </ListItemAvatar>
              <ListItemText
                disableTypography
                primary={
                  <Typography>
                    {getFormattedName(item.message?.senderDetails, false)}
                  </Typography>
                }
                secondary={
                  <Grid className={classes.notificationMessageContent}>
                    <Typography variant="body2">
                      {item.message?.message || 'one new message'}
                    </Typography>
                    <Typography variant="caption">
                      {moment(item.createdAt).fromNow()} &nbsp; &nbsp;
                      <span id="orderID">#{item.message?.order.orderID}</span>
                    </Typography>
                  </Grid>
                }
              />
            </MenuItem>
          ))
        : null}
    </Menu>
  );
};

export default MessageNotificationsMenu;
