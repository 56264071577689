import React, { useRef, useState, useEffect } from 'react';
import {
  Grid,
  IconButton,
  RadioGroup,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { Image } from 'components/organisms';
import { CarouselNavRadio } from 'components/molecules';
import TinySlider from 'tiny-slider-react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import 'tiny-slider/dist/tiny-slider.css';

const useStyles = makeStyles((theme) => {
  return {
    offerCards: {
      position: 'relative',
      marginBottom: '2rem',
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
        display: 'flex',
        paddingRight: '1.25rem'
      }
    },
    scrollBtn: {
      position: 'absolute',
      top: '45%',
      zIndex: 15,
      backgroundColor: '#72737499'
    },
    fullWidthImage: {
      width: '100%',
      objectFit: 'contain',
      aspectRatio: '16 / 9'
    }
  };
});
const SLIDER_SETTINGS = {
  items: 1,
  speed: 400,
  lazyload: false,
  mouseDrag: true,
  slideBy: 1,
  autoplay: false,
  controls: false,
  center: false,
  nav: false,
  rewind: true
};

const ImageSlider = ({ photos }) => {
  const classes = useStyles();
  const sliderRef = useRef(null);
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const [imageSlideIndex, setImageSlideIndex] = useState(0);

  useEffect(() => {
    const handleSliderTransitionEnd = (sliderInfo) =>
      setImageSlideIndex(sliderInfo.index);

    if (sliderRef.current)
      sliderRef.current.slider.events.on(
        'transitionEnd',
        handleSliderTransitionEnd
      );

    return () => {
      sliderRef.current?.slider.events.off(
        'transitionEnd',
        handleSliderTransitionEnd
      );
    };
  }, [photos]);

  const handleScrollBtnClick = (nextPage) => () => {
    sliderRef.current?.slider.goTo(nextPage);
  };

  const handleScrollRadioChange = (e) => {
    const newIndex = parseInt(e.target.value);
    setImageSlideIndex(newIndex);
    sliderRef.current?.slider.goTo(newIndex);
  };

  return (
    <Grid container item md={6} xs={12} xl={4} className={classes.offerCards}>
      {md && (
        <IconButton
          size="small"
          className={classes.scrollBtn}
          style={{ left: '4%' }}
          onClick={handleScrollBtnClick('prev')}>
          <ArrowBackIosIcon fontSize="small" style={{ color: 'white' }} />
        </IconButton>
      )}

      <Grid container className="hide-scrollbar" alignItems="center">
        <TinySlider settings={SLIDER_SETTINGS} ref={sliderRef}>
          {photos.map((item, index) => (
            <Grid
              key={`popular-shop-introone-items-${index}`}
              style={{ backgroundColor: 'aliceblue' }}>
              <Image
                docKey={item.photoURL}
                alt={`shop photo: ${item.name || ''}`}
                loadFromStorage={item.photoURL?.split('/').at(0) === 'images'}
                className={classes.fullWidthImage}
              />
            </Grid>
          ))}
        </TinySlider>
      </Grid>

      {md && (
        <IconButton
          size="small"
          className={classes.scrollBtn}
          style={{ left: '89%' }}
          onClick={handleScrollBtnClick('next')}>
          <ArrowForwardIosIcon fontSize="small" style={{ color: 'white' }} />
        </IconButton>
      )}
      {!md ? (
        <Grid container direction="column" justify="center" alignItems="center">
          <RadioGroup
            row
            value={imageSlideIndex}
            onChange={handleScrollRadioChange}
            style={{ gap: '0 0.25rem' }}>
            {photos.map((_, i) => (
              <CarouselNavRadio
                value={i}
                key={`subfooter-radio-group-item-${i}`}
              />
            ))}
          </RadioGroup>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ImageSlider;
