import { StepConnector } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const OrderStatusConnector = withStyles({
  alternativeLabel: {
    top: 0,
    left: 'calc(-50% - 12px)',
    right: 'calc(50% - 12px)'
  },
  active: {
    '& $line': {
      backgroundColor: '#194378'
    }
  },
  completed: {
    '& $line': {
      backgroundColor: '#194378'
    }
  },
  line: {
    height: 25,
    border: 0,
    backgroundColor: '#F0F1F3',
    borderRadius: '15px'
  }
})(StepConnector);

export default OrderStatusConnector;
