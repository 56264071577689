import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  MenuItem,
  Grid,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Paper,
  Modal,
  Box
} from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useLoader } from 'layouts/loaderContext';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { API, graphqlOperation } from 'aws-amplify';
import { getUserByShop } from 'graphql/queries';
import OrderTimeEditBar from '../OrderTimeEditBar';
import {
  getFormattedName,
  getOrderTotal,
  getDistanceBetweenPostcodesInMiles,
  shouldApplyCollectionOrDeliveryFee
} from 'common/utilFunctions';
import { Address } from 'components/organisms';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import { useShops } from 'context/shop/shopsContext';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    padding: '16px 24px 0'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  modalPaper: {
    padding: '0.5rem 1rem'
  },
  modalScroll: {
    overflowY: 'scroll',
    maxHeight: '90vh',
    paddingRight: '15px',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '80vh'
    }
  },
  timeEditBars: {
    margin: '0 0 1.8rem',
    gap: '1rem'
  },
  textField: {
    padding: '0.25rem'
  },
  dialogContent: {
    height: 'fit-content',
    [theme.breakpoints.up('md')]: {
      overflow: 'hidden'
    },
    paddingRight: '15px'
  },
  detailsText: {
    lineHeight: '1.5rem'
  },
  addressFieldContainer: {
    padding: '0.5rem'
  },
  paddingSpacing: {
    padding: '0 12px'
  },
  marginSpacing: {
    margin: '0 12px'
  },
  addressHeadeing: { gap: '0.5rem', padding: '0 12px 8px' },
  customerDetails: {
    gap: '0.5rem',
    padding: '5px 12px'
  },
  customerDeliveryAddress: {
    padding: '0 12px',
    gap: '0.5rem'
  },
  instructionsHeading: {
    fontWeight: 600,
    fontSize: 16
  }
}));

export function ConfirmOrderDialog({
  onSuccess = () => {},
  dialogType, // dialogType is the next status for this order;
  handleModalClose: handleCurrentModalClose = () => {},
  order
}) {
  const isOpen = dialogType === 'accepted' || dialogType === 'readyForDelivery';
  const classes = useStyles();
  const { setLoading } = useLoader();
  const { selectedShop } = useShops();
  const [state, setState] = useState({
    addressType: 'house',
    driver: ''
  });
  const [pickUpTime, setPickUpTime] = useState({ start: null, end: null });
  const [deliveryTime, setDeliveryTime] = useState({ start: null, end: null });
  const [pickUpDate, setPickUpDate] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isError, setIsError] = useState(''); // dateError, pickupError, deliveryError
  const selectedDriver = drivers.find((_item) => _item.id === state.driver);
  const [addressDistance, setAddressDistance] = useState({
    collection: 0,
    delivery: 0
  });
  const [isOutOfServiceRange, setIsOutOfServiceRange] = useState(false);
  const [isCustomerAddressUpdated, setIsCustomerAddressUpdated] = useState(
    false
  );

  useEffect(() => {
    if (isOpen) {
      const [colStart, colEnd] = order?.collection?.time?.includes('-')
        ? order?.collection?.time?.split(' - ')
        : ['', ''];

      const [delStart, delEnd] = order?.delivery?.time?.includes('-')
        ? order?.delivery?.time?.split(' - ')
        : ['', ''];

      setPickUpDate(order?.collection?.date || '');
      setPickUpTime({ start: colStart, end: colEnd });
      setDeliveryDate(order?.delivery?.date || '');
      setDeliveryTime({ start: delStart, end: delEnd });

      resetCustomerInitialAddress();
      // getDrivers();
    }
    return () => setState((_prev) => ({ ..._prev, driver: '' }));
  }, [order, isOpen]);

  useEffect(() => {
    const getAddressDistance = async (type, address) => {
      if (selectedShop && address?.postcode) {
        const distance = await getDistanceBetweenPostcodesInMiles(
          selectedShop.location,
          address.postcode
        );

        setAddressDistance((prev) => ({
          ...prev,
          [type]: distance
        }));
      }
    };

    const customerAddress =
      dialogType === 'accepted'
        ? JSON.parse(order?.delivery?.address || null)
        : JSON.parse(order?.collection?.address || null);

    if (dialogType === 'accepted') {
      getAddressDistance('collection', currentAddress);
      getAddressDistance('delivery', customerAddress);
    } else {
      getAddressDistance('collection', customerAddress);
      getAddressDistance('delivery', currentAddress);
    }
  }, [currentAddress, order, isOpen]);

  useEffect(() => {
    if (
      selectedShop?.distanceRange &&
      selectedShop?.location &&
      currentAddress?.postcode
    ) {
      getDistanceBetweenPostcodesInMiles(
        selectedShop.location,
        currentAddress.postcode
      ).then((distance) => {
        if (distance > selectedShop.distanceRange) {
          enqueueSnackbar(
            'Sorry! No collection or delivery for this address, outside our service range',
            {
              variant: 'error',
              persist: true,
              preventDuplicate: true
            }
          );
          setIsOutOfServiceRange(true);
        } else setIsOutOfServiceRange(false);
      });
    }
  }, [currentAddress]);

  // need to get drivers list
  // const getDrivers = () => {
  //   if (!order?.shopID) return;
  //   setLoading(true);
  //   API.graphql(
  //     graphqlOperation(getUserByShop, {
  //       shopID: order.shopID
  //     })
  //   )
  //     .then(({ data }) =>
  //       setDrivers(
  //         data.getUserByShop.items.filter(
  //           (user) =>
  //             !user._deleted &&
  //             user.status === 'enabled' &&
  //             user.groups.includes('laundryDriver')
  //         )
  //       )
  //     )
  //     .catch((error) => console.log('error in getting drivers', error))
  //     .finally(() => setLoading(false));
  // };

  const handleClose = () => {
    resetCustomerInitialAddress();
    handleCurrentModalClose();
    setIsError('');
    setState({
      addressType: 'house',
      driver: ''
    });
    setAddressDistance({
      collection: 0,
      delivery: 0
    });
    setIsOutOfServiceRange(false);
  };

  const resetCustomerInitialAddress = () => {
    if (dialogType === 'accepted') {
      const collectionAddress = JSON.parse(order?.collection?.address || null);
      setCurrentAddress(collectionAddress);
    } else {
      const deliveryAddress = JSON.parse(order?.delivery?.address || null);
      setCurrentAddress(deliveryAddress);
    }
    setIsCustomerAddressUpdated(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value
    });
  };

  const isValidColAndDelDate = () => {
    if (deliveryDate && new Date(pickUpDate) > new Date(deliveryDate)) {
      enqueueSnackbar(
        'Invalid date slot: Collection date should not exceed delivery date',
        {
          variant: 'error',
          autoHideDuration: 1000,
          persist: true
        }
      );
      setIsError('date');
      return false;
    }
    setIsError('');
    return true;
  };

  const validTimeSlots = (startTime, endTime, slotType) => {
    if ((startTime && !endTime) || (!startTime && endTime)) {
      setIsError(slotType);
      return false;
    }
    return true;
  };

  const checkTimeSlotAvailability = (startTime, endTime, slotType) => {
    if (!startTime || !endTime) {
      if (
        (slotType === 'Pick Up' && dialogType === 'accepted') ||
        (slotType === 'Delivery' && dialogType === 'readyForDelivery') ||
        (startTime && !endTime) ||
        (!startTime && endTime)
      ) {
        enqueueSnackbar('Choose a time slot', {
          variant: 'warning',
          autoHideDuration: 1000,
          preventDuplicate: true
        });
        setIsError(slotType);
      }
      return false;
    }
    setIsError('');
    return true;
  };

  const checkTimeSlotValidatity = (startTime, endTime, slotType) => {
    if (!checkTimeSlotAvailability(startTime, endTime, slotType)) {
      return;
    }

    const startTimeInteger = parseInt(
      moment(startTime, 'hh:mma').format('HHmm'),
      10
    );
    const endTimeInteger = parseInt(
      moment(endTime, 'hh:mma').format('HHmm'),
      10
    );

    if (startTimeInteger > endTimeInteger) {
      enqueueSnackbar('Time Slot Invalid - Start time exceeding end time', {
        variant: 'error',
        autoHideDuration: 1000,
        persist: true
      });
      setIsError(slotType);
      return false;
    } else {
      setIsError('');
      return true;
    }
  };

  const timeSlotUpdate = (startTime, endTime) => {
    if (
      !startTime ||
      !endTime ||
      startTime === 'Invalid date' ||
      endTime === 'Invalid date'
    ) {
      return '';
    }
    return `${startTime} - ${endTime}`;
  };

  const checkTimeSlotBetweenPickUpAndDelivery = (
    pickUpStart,
    deliveryStart
  ) => {
    const pickUpStartTime = moment(pickUpStart, 'h:mma');
    const deliveryStartTime = moment(deliveryStart, 'h:mma');

    const oneHour = moment.duration(1, 'hour');

    if (deliveryStartTime.isAfter(pickUpStartTime.clone().add(oneHour))) {
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    if (!isValidColAndDelDate()) {
      return;
    }

    if (dialogType === 'accepted') {
      const isDeliveryInValid =
        deliveryTime.start === 'Invalid date' ||
        deliveryTime.end === 'Invalid date';

      const isDateAndTimeInvalid =
        !pickUpDate ||
        pickUpDate === 'Invalid date' ||
        pickUpTime.start === 'Open 24 hours' ||
        pickUpTime.end === 'Open 24 hours' ||
        pickUpTime.start === 'Invalid date' ||
        pickUpTime.end === 'Invalid date' ||
        isDeliveryInValid;

      if (isDateAndTimeInvalid) {
        enqueueSnackbar(
          `Please provide valid ${
            isDeliveryInValid ? 'delivery' : 'collection'
          } date and time`,
          {
            variant: 'warning',
            preventDuplicate: true,
            persist: true
          }
        );
        return;
      }

      if (
        !checkTimeSlotValidatity(pickUpTime.start, pickUpTime.end, 'Pick Up')
      ) {
        return;
      }

      if (
        checkTimeSlotAvailability(
          deliveryTime.start,
          deliveryTime.end,
          'Delivery'
        ) &&
        !checkTimeSlotValidatity(
          deliveryTime.start,
          deliveryTime.end,
          'Delivery'
        )
      ) {
        return;
      }

      if (!validTimeSlots(deliveryTime.start, deliveryTime.end, 'Delivery'))
        return;
    }

    if (dialogType === 'readyForDelivery') {
      const isDateAndTimeInvalid =
        !deliveryDate ||
        deliveryDate === 'Invalid date' ||
        deliveryTime.start === 'Open 24 hours' ||
        deliveryTime.start === 'Invalid date' ||
        deliveryTime.end === 'Open 24 hours' ||
        deliveryTime.end === 'Invalid date';

      if (isDateAndTimeInvalid) {
        enqueueSnackbar(`Please provide valid delivery date and time`, {
          variant: 'warning',
          preventDuplicate: true,
          persist: true
        });
        return;
      }

      if (
        !checkTimeSlotValidatity(
          deliveryTime.start,
          deliveryTime.end,
          'Delivery'
        )
      ) {
        return;
      }
    }

    if (
      pickUpTime.start &&
      !checkTimeSlotValidatity(pickUpTime.start, pickUpTime.end, 'Pick Up')
    ) {
      return;
    }

    if (
      pickUpDate &&
      new Date(pickUpDate).getTime() === new Date(deliveryDate).getTime() &&
      !checkTimeSlotBetweenPickUpAndDelivery(
        pickUpTime.start,
        deliveryTime.start
      )
    ) {
      enqueueSnackbar(
        'Maintain a 1-hour gap between pickup and delivery start times.',
        {
          variant: 'warning',
          autoHideDuration: 3000
        }
      );
      setIsError('oneHourGap');
      return;
    }

    const subTotal = getOrderTotal(order);
    let total = subTotal;
    const minPriceForFree = order?.currentShopFees?.minPriceForFree || 0;
    let collectionAndDeliveryFee =
      order?.collection?.type === 'asap'
        ? order?.currentShopFees?.AsapDeliveryFee
        : 0;

    if (!!order.collection.address) {
      if (
        shouldApplyCollectionOrDeliveryFee(
          subTotal,
          minPriceForFree,
          addressDistance?.collection,
          selectedShop?.distanceRangeForFree
        )
      ) {
        collectionAndDeliveryFee += order?.currentShopFees?.standardDeliveryFee;
      }
    }
    if (!!order.delivery.address) {
      if (
        shouldApplyCollectionOrDeliveryFee(
          subTotal,
          minPriceForFree,
          addressDistance?.delivery,
          selectedShop?.distanceRangeForFree
        )
      ) {
        collectionAndDeliveryFee += order?.currentShopFees?.standardDeliveryFee;
      }
    }

    total += collectionAndDeliveryFee;
    total -= order.discount;

    const updatedOrder = {
      ...order,
      total,
      collectionAndDeliveryFee,
      collection: {
        ...order?.collection,
        time: timeSlotUpdate(pickUpTime.start, pickUpTime.end),
        date: pickUpDate ? new Date(pickUpDate) : ''
      },
      delivery: {
        ...order?.delivery,
        time: timeSlotUpdate(deliveryTime.start, deliveryTime.end),
        date: new Date(deliveryDate)
      }
    };

    const driverData = {
      userID: selectedDriver?.id,
      firstName: selectedDriver?.firstName,
      lastName: selectedDriver?.lastName,
      title: selectedDriver?.title,
      email: selectedDriver?.email,
      phoneNumber: selectedDriver?.phoneNumber
    };
    if (dialogType === 'accepted') {
      updatedOrder.collection.address = currentAddress
        ? JSON.stringify(currentAddress)
        : null;
      updatedOrder.collection.driver = driverData;
    } else {
      updatedOrder.delivery.address = currentAddress
        ? JSON.stringify(currentAddress)
        : null;
      updatedOrder.delivery.driver = driverData;
    }
    onSuccess({ order: updatedOrder, sts: dialogType });
  };

  const handleAddAddress = (data) => {
    setCurrentAddress({
      ...data
    });
    setOpenEditModal(false);
    setIsCustomerAddressUpdated(true);
  };

  const handleModalOpen = () => setOpenEditModal(true);
  const handleModalClose = () => setOpenEditModal(false);

  const isPickUpOrDropOffOrder =
    dialogType === 'accepted'
      ? !order?.collection?.address
      : dialogType === 'readyForDelivery'
      ? !order?.delivery?.address
      : false;

  const instructions =
    dialogType === 'accepted'
      ? order?.collection?.instructions
      : order?.delivery?.instructions;

  return order && order.id ? (
    <>
      <Dialog
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        open={isOpen}>
        <DialogTitle
          id="form-dialog-title"
          classes={{ root: classes.dialogTitle }}>
          <Grid container justify="flex-start">
            <Typography variant="h5" style={{ fontSize: 18 }}>
              Order{' '}
              {dialogType === 'accepted'
                ? 'Collection'
                : dialogType === 'readyForDelivery'
                ? 'Delivery'
                : null}
              &nbsp; &nbsp;
            </Typography>
            <Typography color="primary">#{order && order.orderID}</Typography>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container justify="center" alignItems="center">
            <Grid container className={classes.timeEditBars}>
              <OrderTimeEditBar
                heading="Pick Up"
                dialogType={dialogType}
                initialDate={order?.collection?.date}
                date={pickUpDate}
                time={pickUpTime}
                setDate={setPickUpDate}
                setTime={setPickUpTime}
                isError={isError}
                isScheduleOrder={!!order?.collection?.address}
              />
              <OrderTimeEditBar
                heading="Delivery"
                dialogType={dialogType}
                initialDate={order?.delivery?.date}
                date={deliveryDate}
                time={deliveryTime}
                setDate={setDeliveryDate}
                setTime={setDeliveryTime}
                isError={isError}
                isScheduleOrder={!!order?.delivery?.address}
              />
            </Grid>

            <Grid container item xs={12} justify="space-between">
              {/* order address */}
              <Grid container item md={6} xs={12}>
                <Grid
                  container
                  item
                  alignItems="center"
                  className={classes.addressHeadeing}>
                  <Typography variant="h5">
                    {dialogType === 'accepted' ? 'Collection ' : 'Delivery '}
                    Address
                  </Typography>
                  <IconButton
                    color="primary"
                    onClick={handleModalOpen}
                    disabled={
                      isPickUpOrDropOffOrder ||
                      (dialogType === 'readyForDelivery' &&
                        order?.paymentStatus === 'paid')
                    }
                    size="small">
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={resetCustomerInitialAddress}
                    disabled={!isCustomerAddressUpdated}>
                    Reset
                  </Button>
                </Grid>
                {!order?.collection?.address ? (
                  <Grid
                    item
                    xs={12}
                    className={classes.customerDeliveryAddress}>
                    <Typography variant="body1">
                      <span style={{ fontWeight: 600 }}>Drop</span>: Customer.
                      <br />
                    </Typography>
                  </Grid>
                ) : null}
                {!order?.delivery?.address ? (
                  <Grid
                    item
                    xs={12}
                    className={classes.customerDeliveryAddress}>
                    <Typography variant="body1">
                      <span style={{ fontWeight: 600 }}>Pickup</span>: Customer.
                      <br />
                    </Typography>
                  </Grid>
                ) : null}
                {!isPickUpOrDropOffOrder ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      className={classes.addressFieldContainer}>
                      <TextField
                        className="nameField"
                        id="filled-basic"
                        disabled
                        value={currentAddress?.postcode || ''}
                        size="small"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="end">
                              <LocationOnIcon fontSize="small" />
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={classes.addressFieldContainer}>
                      <TextField
                        className="nameField"
                        id="filled-basic"
                        disabled
                        value={`${currentAddress?.line1 || ''} ${
                          currentAddress?.line2 || ''
                        } ${currentAddress?.district || ''}`}
                        variant="outlined"
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={6} className={classes.addressFieldContainer}>
                      <TextField
                        className="nameField"
                        id="filled-basic"
                        value="Business Name"
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} className={classes.addressFieldContainer}>
                      <TextField
                        select
                        variant="outlined"
                        size="small"
                        disabled
                        fullWidth
                        value={state.addressType}
                        onChange={handleChange}
                        label="House"
                        defaultValue="house">
                        <MenuItem value="house">House</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </TextField>
                    </Grid>
                  </>
                ) : null}
              </Grid>

              <Grid
                container
                item
                md={6}
                xs={12}
                direction="column"
                style={{ gap: '1rem' }}>
                {/* order driver */}
                {/* <Typography variant="h5" className={classes.paddingSpacing}>
                  {dialogType === 'accepted' ? 'Collection ' : 'Delivery '}
                  Driver
                </Typography>
                <TextField
                  select
                  label="Choose a driver"
                  variant="outlined"
                  size="small"
                  name="driver"
                  value={state.driver}
                  disabled={isPickUpOrDropOffOrder}
                  onChange={handleChange}
                  className={classes.marginSpacing}>
                  <MenuItem value="">
                    <i>None</i>
                  </MenuItem>
                  {drivers.map((driver, index) => (
                    <MenuItem
                      value={driver.id}
                      key={`collection-or-delivery-drivers-itmes-${index}`}>
                      {getFormattedName(driver)}
                    </MenuItem>
                  ))}
                </TextField> */}
                {state.driver ? (
                  <Box className={classes.paddingSpacing}>
                    <Typography variant="h5">Driver Details</Typography>
                    <Typography variant="body1" className={classes.detailsText}>
                      Name: {getFormattedName(selectedDriver)} <br />
                      Email: {selectedDriver.email}
                      <br />
                      Phone number: {selectedDriver.phoneNumber}
                    </Typography>
                  </Box>
                ) : null}

                {/* customer details */}
                <Grid
                  container
                  direction="column"
                  className={classes.customerDetails}>
                  <Typography variant="h5">Customer Details</Typography>
                  <Typography variant="body1" className={classes.detailsText}>
                    Name: {getFormattedName(order?.userDetail)} <br />
                    Email address: {order?.userDetail?.email} <br />
                    Phone no: {order?.userDetail?.phoneNumber}
                  </Typography>
                  {instructions && (
                    <Typography style={{ fontSize: 15 }}>
                      <span className={classes.instructionsHeading}>
                        Instructions:{' '}
                      </span>
                      {instructions}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
          <Button
            color="primary"
            onClick={handleSubmit}
            variant="contained"
            disabled={isOutOfServiceRange}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        open={openEditModal}
        onClose={handleModalClose}
        aria-labelledby="edit-address-modal-title"
        aria-describedby="edit-address-modal-description">
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ marginTop: '3rem' }}>
          <Paper className={classes.modalPaper}>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              style={{ margin: '10px 0' }}>
              <Typography variant="h4">Edit Address</Typography>
              <IconButton onClick={handleModalClose} style={{ padding: 0 }}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <PerfectScrollbar className={classes.modalScroll}>
              <Address
                mode={5}
                onCancel={handleModalClose}
                updateAddressList={handleAddAddress}
                data={currentAddress}
              />
            </PerfectScrollbar>
          </Paper>
        </Grid>
      </Modal>
    </>
  ) : null;
}

ConfirmOrderDialog.propTypes = {
  declineOrder: PropTypes.func,
  handleClose: PropTypes.func
};
