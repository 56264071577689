import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import ReviewComp from './components/ReviewComp';
import { NavigationBreadcrumbs } from 'components/organisms';
import { routes } from 'common/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: '1px solid #0000001A'
  },
  checkoutContent: {
    flexGrow: 1,
    overflow: 'hidden',
    maxWidth: '90%',
    margin: '0 auto 5rem'
  }
}));

const Review = () => {
  const classes = useStyles();
  const history = useHistory();
  const { shopName = '' } = useParams();

  const getNavigationConfig = () =>
    !!shopName
      ? {
          showShopList: true,
          links: [
            {
              label: shopName.split('-').join(' '),
              link: history.location.pathname.split('/').slice(0, -1).join('/')
            }
          ]
        }
      : {
          showShopList: false,
          links: [
            {
              label: 'Auction History',
              link: routes.customer.auctionExpired
            }
          ]
        };

  return (
    <Grid container justify="center" className={classes.root}>
      <Helmet>
        <meta
          name="description"
          content="At our laundromat, we understand the importance of a seamless checkout process. That's why we've designed our checkout page to be user-friendly and easy to navigate, making your payment experience quick and hassle-free.
          With our secure payment system, you can rest easy knowing that your personal information and payment details are protected. Plus, our checkout page is optimized for all devices, ensuring a smooth experience whether you're using a desktop computer or mobile device.
          We offer a range of payment options to suit your needs, including credit card and debit card payments. And, if you're a regular customer, you can save time by creating an account and storing your payment details for future transactions.
          So why wait? Check out with ease and convenience at our laundromat today. With our user-friendly and secure checkout page, your payment experience will be a breeze."
        />
        <meta
          name="keywords"
          content="launder-it, launder it, checkout, user-friendly, secure payment, personal information, security, payment details"
        />
      </Helmet>
      <Grid container className={classes.checkoutContent}>
        <Grid item xs={12}>
          <NavigationBreadcrumbs
            currentLink="Order Review"
            {...getNavigationConfig()}
          />
        </Grid>
        <ReviewComp />
      </Grid>
    </Grid>
  );
};

export default Review;
