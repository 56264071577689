import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { StaffList, StaffDetails, AddNewStaff } from './components';
import { StaffIdContext } from 'context/StaffIdContext';

const useStyles = makeStyles((theme) => ({
  root: { minWidth: '100%' }
}));

const Staff = () => {
  const [staffId, setStaffId] = useState('');
  const [selectedStaff, setSelectedStaff] = useState({});
  const [showAddForm, setShowAddForm] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const classes = useStyles();

  return (
    <StaffIdContext.Provider value={{ staffId, setStaffId }}>
      <Card className={classes.root}>
        <CardContent>
          <Grid container direction="column" my={5} py={5}>
            {showAddForm ? (
              <AddNewStaff
                setShowAddForm={setShowAddForm}
                setShowDetails={setShowDetails}
              />
            ) : showDetails ? (
              <StaffDetails
                selectedStaff={selectedStaff}
                setSelectedStaff={setSelectedStaff}
                handleAddBtnClick={() => setShowAddForm(true)}
                handleClose={() => setShowDetails(false)}
                showAddBtn
              />
            ) : (
              <>
                <Typography variant="h4" component="strong">
                  Staff
                </Typography>
                <StaffList
                  selectedStaff={selectedStaff}
                  setSelectedStaff={setSelectedStaff}
                  setShowAddForm={setShowAddForm}
                  setShowDetails={setShowDetails}
                />
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    </StaffIdContext.Provider>
  );
};

export default Staff;
