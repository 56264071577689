import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Button,
  ButtonGroup,
  MenuItem,
  TextField,
  Typography,
  Backdrop,
  Fade,
  Modal,
  Grid,
  Chip,
  useMediaQuery,
  CircularProgress
} from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import {
  updateAddress,
  deleteAddress,
  updateUserDetail
} from 'graphql/mutations';
import { createAddress } from 'graphql/customMutations';
import { listAddresses } from 'graphql/queries';
import { validatePhoneNumber } from 'common/validators';
import { regexPostcode } from 'common/constants';
import { useAmplifyAuth } from 'context';
import { useSnackbar } from 'notistack';
import { omit } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 500,
    gap: '1.25rem 0',
    margin: '0.625rem 0',
    '& .MuiTextField-root': {
      margin: theme.spacing(1)
    },
    '& .MuiTypography-root': {
      margin: theme.spacing(1)
    },
    '& .MuiButtonBase-root ': {
      margin: theme.spacing(1),
      padding: '12px 21px'
    },
    '&:hover': {
      borderColor: '#000'
    },
    '&.mode_0': {
      border: 'none',
      maxWidth: '100%'
    }
  },
  edit: {
    backgroundColor: theme.palette.blue?.main || theme.palette.primary.main
  },
  select: {
    width: '80%',
    margin: 8
  },
  disableText: {
    pointerEvents: 'none',
    '& .MuiInputBase-input': {
      background: 'white',
      color: 'black'
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: 'white',
    padding: 10
  },
  errorText: {
    color: theme.palette.error.main
  },
  textFieldLeftPadding: {
    paddingLeft: '0.625rem'
  },
  textFieldRightPadding: {
    paddingRight: '0.625rem'
  }
}));
const NO_ADDRESSES_FOUND_MESSAGE =
  '**No addresses were found, please try updating the house no or postal code and try again.';

const Address = (props) => {
  const classes = useStyles();
  const { updateAddressList = () => {} } = props;
  const [mode, setMode] = React.useState(props.mode); //, 0-> Read Only, 1-> view, 2-> edit, 3-> new, 5-> edit customer order address
  const [formValue, setFormValue] = React.useState({});
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = React.useState([]);
  const [postcode, setPostcode] = React.useState('');
  const [curSelectValue, setCurSelectValue] = React.useState(0);
  const [formErrors, setFormErrors] = React.useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openDefault, setOpenDefault] = React.useState(false);
  const [openDefaultDelete, setOpenDefaultDelete] = React.useState(false);
  const [showSelectAddress, setShowSelectAddress] = React.useState(false);
  const shouldPassAddress = !(mode === 3 || mode === 4) && props.data;
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  let {
    state: { user },
    _refreshUser
  } = useAmplifyAuth();

  useEffect(() => {
    let mounted = true;
    mounted && setFormValue(shouldPassAddress || {});

    return () => {
      mounted = false;
    };
  }, [shouldPassAddress]);

  useEffect(() => {
    let mounted = true;
    mounted && setMode(props.mode);

    return () => {
      mounted = false;
    };
  }, [props.mode]);

  const editHandler = () => {
    setMode(2);
  };

  const handleClose = () => {
    setOpenDefault(false);
    setOpenDelete(false);
  };

  const onOkDelete = () => {
    setOpenDelete(false);
    const snackBar = enqueueSnackbar('Deleting address', {
      variant: 'info',
      persist: true
    });
    API.graphql(
      graphqlOperation(deleteAddress, {
        input: { id: formValue.id, _version: formValue._version }
      })
    ).then(() => {
      updateAddressList(formValue, 'remove');
      closeSnackbar(snackBar);
    });
  };

  const onOkDefaultDelete = () => setOpenDefaultDelete(false);

  const onOkDefaultAddress = async (id) => {
    setOpenDefault(false);
    const snackBar = enqueueSnackbar('Updating default address...', {
      variant: 'info',
      persist: true
    });
    try {
      const resp = await API.graphql(
        graphqlOperation(updateUserDetail, { defaultAddress: id })
      );
      enqueueSnackbar('Address updated', {
        variant: 'success',
        autoHideDuration: 2000
      });
      _refreshUser();
    } catch (error) {
      console.error('Something went wrong', error);
    } finally {
      closeSnackbar(snackBar);
    }
  };

  const deleteHandler = () => {
    if (formValue.id === user.defaultAddress) {
      setOpenDefaultDelete(true);
    } else {
      setOpenDelete(true);
    }
  };

  const cancelHandler = () => {
    if (shouldPassAddress) {
      setFormValue(shouldPassAddress);
      setMode(1);
    }
    if (props.onCancel) {
      props.onCancel();
    }
  };
  const setAsDefault = () => {
    setOpenDefault(true);
  };

  const onSave = () => {
    if (mode === 4) {
      updateAddressList(formValue);
    } else if (mode === 3) {
      const snackBar = enqueueSnackbar('Saving address ...', {
        variant: 'info',
        persist: true
      });
      API.graphql(
        graphqlOperation(createAddress, {
          input: { ...formValue, userID: user.username }
        })
      ).then(({ data }) => {
        if (data.createAddress.id && updateAddressList) {
          updateAddressList(data.createAddress, 'add');
        }
        if (!user.defaultAddress) {
          setAsDefault(data.createAddress.id);
          onOkDefaultAddress(data.createAddress.id);
        }
        if (props.setData) {
          props.setData(data.createAddress);
        }
        setFormValue({ ...formValue, ...data.createAddress });
        closeSnackbar(snackBar);
        props.onCancel();
      });
    } else if (mode === 2) {
      const { _deleted, _lastChangedAt, ...rest } = formValue;
      if (!_deleted && _lastChangedAt) {
        const snackBar = enqueueSnackbar('Saving address ...', {
          variant: 'info',
          persist: true
        });
        API.graphql(
          graphqlOperation(updateAddress, {
            input: { ...omit(rest, ['user', 'createdAt', 'updatedAt']) }
          })
        ).then(({ data }) => {
          if (!user.defaultAddress) {
            setAsDefault(data.updateAddress.id);
          }
          setMode(1);
          updateAddressList(data.updateAddress, 'edit');
          closeSnackbar(snackBar);
        });
      }
    } else if (mode === 5) {
      enqueueSnackbar('Customer Address updated', {
        variant: 'success',
        preventDuplicate: true,
        autoHideDuration: 1000
      });
      updateAddressList(formValue);
    }
  };

  const searchHandler = () => {
    if (formValue.postcode) {
      setShowSelectAddress(true);
      setCurSelectValue(0);
      setLoading(true);
      API.graphql(
        graphqlOperation(listAddresses, {
          params: {
            house: formValue.buildingNumber || '',
            postcode: formValue.postcode.replace(/ /g, '')
          }
        })
      )
        .then(({ data }) => {
          const _address = data?.listAddresses?.addresses || [];
          if (_address.length > 0) {
            setAddress(_address.map((v) => JSON.parse(v)));
            setFormErrors({ phoneNumber: formErrors.phoneNumber });
          } else {
            setFormErrors({
              ...formErrors,
              address: NO_ADDRESSES_FOUND_MESSAGE
            });
          }
          setPostcode(data?.listAddresses?.postcode || '');
        })
        .catch((err) => {
          setPostcode('');
          setAddress([]);
          setFormErrors({
            ...formErrors,
            address: NO_ADDRESSES_FOUND_MESSAGE,
            buildingNumber: 'This may not be a valid house number!',
            postcode: 'This may not be a valid postal code!'
          });
        })
        .finally(() => setLoading(false));
    }
  };

  const setFormValueHandler = (name) => (e) => {
    if (name === 'phoneNumber')
      e.target.value = e.target.value.replace(/[^0-9,+,()]/g, '');
    const newFormValues = { ...formValue, [name]: e.target.value };
    if (['phoneNumber', 'postcode'].includes(name)) validate(newFormValues);
    setFormValue(newFormValues);
  };

  const validate = (formValue) => {
    const errors = {};

    errors.phoneNumber = validatePhoneNumber(formValue.phoneNumber);
    if (!formValue.postcode) {
      errors.postcode = 'Postal code is required!';
    } else if (!regexPostcode.test(formValue.postcode)) {
      errors.postcode = '*This is not a valid postal code!';
    }

    setFormErrors(errors);
  };

  const selectHandler = (e) => {
    const _curSelectValue = e.target.value - 1;
    setCurSelectValue(e.target.value);
    const {
      building_name: buildingName,
      building_number: buildingNumber,
      line_1: line1,
      line_2: line2,
      county,
      country,
      district,
      town_or_city: town
    } = address[_curSelectValue];
    setFormValue({
      ...formValue,
      buildingName,
      buildingNumber,
      line1,
      line2,
      county,
      country,
      district,
      town,
      postcode
    });
    setShowSelectAddress(false);
  };

  const getInputProps = (type) => {
    let inputProps = {};
    if (type === 'phoneNumber') {
      inputProps.maxLength = 13;
    }
    return inputProps;
  };

  const getTextField = (shouldHide = true) => (arr, index) =>
    shouldHide && (
      <Grid
        item
        xs={12}
        md={6}
        key={`order-review-address-fields-items-${arr[1]}`}
        className={
          md
            ? index % 2 === 0
              ? classes.textFieldRightPadding
              : classes.textFieldLeftPadding
            : ''
        }>
        <TextField
          className={mode === 1 ? classes.disableText : ''}
          fullWidth
          helperText={formErrors[arr[1]]}
          error={!!formErrors[arr[1]]}
          inputProps={getInputProps(arr[1])}
          InputLabelProps={{ shrink: true }}
          label={arr[0]}
          onChange={mode < 2 ? () => {} : setFormValueHandler(arr[1])}
          style={{ caretColor: mode < 2 ? 'transparent' : 'auto' }}
          required={arr[1] === 'phoneNumber'}
          value={(formValue && formValue[arr[1]]) || ''}
          variant="outlined"
        />
      </Grid>
    );

  return (
    <Grid container className={`${classes.root} mode_${mode}`}>
      {mode === 1 && user.defaultAddress === formValue?.id && (
        <Grid container>
          <Chip
            style={{
              backgroundColor: '#4caf50',
              color: 'white',
              margin: '8px 0'
            }}
            label={'Default Address'}
          />
        </Grid>
      )}

      {[
        ['House Number', 'buildingNumber'],
        ['Post Code', 'postcode']
      ].map(getTextField(true))}

      {mode > 1 && (
        <>
          {formErrors.address ? (
            <Typography className={classes.errorText}>
              {formErrors.address}
            </Typography>
          ) : null}
          <Button
            color="primary"
            onClick={searchHandler}
            fullWidth
            size="large"
            variant="outlined"
            endIcon={
              loading ? <CircularProgress size={15} thickness={4} /> : null
            }
            disabled={
              !!(loading || formErrors.postcode || formErrors.buildingNumber)
            }>
            Find my Address
          </Button>
          {address.length > 0 && showSelectAddress && (
            <TextField
              select
              variant="outlined"
              label="Select Address"
              onChange={selectHandler}
              fullWidth
              value={curSelectValue}>
              <MenuItem value={0}>select address</MenuItem>
              {address.map(({ formatted_address }, i) => (
                <MenuItem key={i + 1} value={i + 1}>
                  {formatted_address.filter((v) => !!v).join(', ')}
                </MenuItem>
              ))}
            </TextField>
          )}
        </>
      )}

      {[
        ['Contact Name', 'contactName'],
        ['Phone Number', 'phoneNumber'],
        ['Building Name', 'buildingName'],
        ['Address 1', 'line1'],
        ['Address 2', 'line2'],
        ['District', 'district'],
        ['County', 'county'],
        ['Country', 'country']
      ]
        .filter((item) =>
          mode === 0
            ? formValue && !!formValue[item[1]]
            : mode === 4 || mode === 5
            ? !(item[1] === 'contactName' || item[1] === 'phoneNumber')
            : true
        )
        .map(getTextField(mode <= 2 || mode === 5 || curSelectValue > 0))}

      {mode > 1 && (
        <>
          {Object.keys(formValue).length > 1 && (
            <Button
              color="primary"
              onClick={onSave}
              fullWidth
              variant="contained"
              disabled={mode === 4 ? false : !!formErrors.phoneNumber}>
              Save
            </Button>
          )}
        </>
      )}

      {mode === 1 && formValue && (
        <Grid
          container
          item
          xs={12}
          justify="center"
          style={{ marginTop: '10px' }}>
          <ButtonGroup>
            <Button
              className={classes.edit}
              color="primary"
              size="small"
              variant="contained"
              onClick={deleteHandler}>
              Delete
            </Button>
            <Button
              className={classes.edit}
              color="primary"
              onClick={editHandler}
              size="small"
              variant="contained">
              Edit
            </Button>
            {user.defaultAddress !== formValue.id && (
              <Button
                className={classes.edit}
                color="primary"
                onClick={setAsDefault}
                size="small"
                variant="contained">
                Set as Default
              </Button>
            )}
          </ButtonGroup>
        </Grid>
      )}

      <Modal
        aria-describedby="transition-modal-description"
        aria-labelledby="transition-modal-title"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        className={classes.modal}
        closeAfterTransition
        onClose={handleClose}
        open={openDelete}>
        <Fade in={openDelete}>
          <div className={classes.paper} style={{ minWidth: '50%' }}>
            <div>
              <h2
                style={{ textAlign: 'center', margin: '10px' }}
                id="transition-modal-title">
                Are you sure you want to delete this address?
              </h2>
              <div style={{ textAlign: 'center', marginTop: '10%' }}>
                <Button
                  style={{ marginRight: '20px' }}
                  color="primary"
                  onClick={onOkDelete}
                  variant="contained">
                  Yes
                </Button>
                <Button
                  style={{ marginRight: '20px' }}
                  color="secondary"
                  onClick={handleClose}
                  variant="contained">
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-describedby="transition-modal-description"
        aria-labelledby="transition-modal-title"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        className={classes.modal}
        closeAfterTransition
        onClose={handleClose}
        open={openDefaultDelete}>
        <Fade in={openDefaultDelete}>
          <div className={classes.paper} style={{ maxWidth: '50%' }}>
            <div>
              <h2
                style={{ textAlign: 'center', margin: '10px' }}
                id="transition-modal-title">
                You can't delete the default address. Please change the default
                address before performing this action.
              </h2>
              <div style={{ textAlign: 'center', marginTop: '10%' }}>
                <Button
                  style={{ marginRight: '20px' }}
                  color="primary"
                  onClick={onOkDefaultDelete}
                  variant="contained">
                  Yes
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-describedby="transition-modal-description"
        aria-labelledby="transition-modal-title"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        className={classes.modal}
        closeAfterTransition
        onClose={handleClose}
        open={openDefault}>
        <Fade in={openDefault}>
          <div className={classes.paper} style={{ minWidth: '50%' }}>
            <div>
              <h2
                style={{ textAlign: 'center', margin: '10px' }}
                id="transition-modal-title">
                Are you sure you want to change the default address?
              </h2>
              <div style={{ textAlign: 'center', marginTop: '10%' }}>
                <Button
                  style={{ marginRight: '20px' }}
                  color="primary"
                  onClick={() => onOkDefaultAddress(formValue.id)}
                  variant="contained">
                  Yes
                </Button>
                <Button
                  style={{ marginRight: '20px' }}
                  color="secondary"
                  onClick={handleClose}
                  variant="contained">
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </Grid>
  );
};

Address.propTypes = {
  data: PropTypes.any,
  mode: PropTypes.number,
  onCancel: PropTypes.func
};

export default Address;
