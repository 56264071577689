import React from 'react';
import { Breadcrumbs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '16px 0'
  },
  blueBtn: {
    color: theme.palette.blue.main,
    textTransform: 'capitalize'
  },
  blackBtn: {
    color: 'black',
    textTransform: 'capitalize',
    cursor: 'pointer'
  }
}));

const NavigationBreadcrumbs = ({
  showShopList = false,
  links = [],
  currentLink = '',
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Breadcrumbs
      aria-label="user-flow-breadcrumb"
      className={classes.root}
      {...rest}>
      <Link to="/home" className={classes.blackBtn}>
        Home
      </Link>
      {!!localStorage.getItem('recentPostCode') && showShopList && (
        <Link
          to={`/shop/postcode/${localStorage.getItem('recentPostCode')}`}
          className={classes.blackBtn}>
          Shop List
        </Link>
      )}
      {links
        .filter((item) => item && item.label)
        .map((item, index) => (
          <Link
            className={classes.blackBtn}
            to={item?.link || ''}
            key={`navigation-breadcrumbs-items-${index}`}>
            {item.label}
          </Link>
        ))}
      <Link to="#" className={classes.blueBtn} underline="none">
        {currentLink}
      </Link>
    </Breadcrumbs>
  );
};

export default NavigationBreadcrumbs;
