import React, { useEffect } from 'react';
import { CouponForm, CouponHeader, CouponTable } from './components';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useCoupons } from 'context/coupons/couponsContext';
import { useAmplifyAuth } from 'context';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1rem'
  }
}));

const Coupons = () => {
  const classes = useStyles();
  const { dispatch } = useCoupons();
  let { actionType = '' } = useParams();
  const {
    state: { user }
  } = useAmplifyAuth();

  useEffect(() => {
    if (user?.shopId) {
      dispatch({ type: 'getCouponsByShop', payload: { shopID: user?.shopId } });
    }
  }, [user]);

  return (
    <Grid container className={classes.root}>
      <CouponHeader />
      {actionType === 'add' || actionType === 'edit' ? (
        <CouponForm />
      ) : (
        <CouponTable />
      )}
    </Grid>
  );
};

export default Coupons;
