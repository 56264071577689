import React, { useReducer, useEffect } from 'react';
import CallRoundedIcon from '@material-ui/icons/CallRounded';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  Button,
  Avatar,
  MenuItem,
  IconButton,
  CardActions,
  Typography,
  Grid,
  List
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { CustomSearchBar } from 'components/organisms';
import { useCustomers } from 'context/customers/customersContext';
import { getInitials } from 'helpers';
import { searchStringInItems } from 'common/utilFunctions';
import { useLoader } from 'layouts/loaderContext';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1.5rem',
    gap: '1rem'
  },
  filterBtn: {
    display: 'flex',
    gap: '1rem',
    ['@media (max-width:1380px)']: {
      flexDirection: 'column'
    }
  },
  profileImg: {
    height: 40,
    borderRadius: '50%',
    marginRight: '1rem'
  }
}));

const REDUCER_INITIALS = {
  page: 1,
  type: 'newest',
  search: ''
};
const filterReducer = (state, action) => {
  const { page, type, search } = action.payload;
  switch (action.type) {
    case 'updatePageNum':
      return { ...state, page };
    case 'updateUserType':
      return { ...state, type };
    case 'updateSearchBy':
      return { ...state, search };
    case 'updateAllFilters':
      return { page, type, search };
    default:
      throw new Error('Type not defined in Reducer');
  }
};

const FilterUser = () => {
  const classes = useStyles();
  const history = useHistory();
  const { loading } = useLoader();
  const { customers, dispatch } = useCustomers();
  const [filters, filterDispatch] = useReducer(filterReducer, REDUCER_INITIALS);

  const itemsPerPage = 10;

  useEffect(() => {
    dispatch({ type: 'getCustomers' });
  }, []);

  useEffect(() => {
    const searchString = history.location.search.split('&');
    if (searchString.length > 1) {
      const pgNum = +searchString[0].split('=')[1];
      const userType = searchString[1].split('=')[1];
      const search = searchString[2]?.split('=')[1] || '';

      filterDispatch({
        type: 'updateAllFilters',
        payload: { page: pgNum, type: userType, search: search }
      });
    }
  }, []);

  useEffect(() => {
    history.replace(
      `/admin/customers?${Object.entries(filters)
        .filter((item) => !!item[1])
        .map((item) => `${item[0]}=${item[1]}`)
        .join('&')}`
    );
  }, [filters]);

  const paginationHandler = (event, value) => {
    filterDispatch({ type: 'updatePageNum', payload: { page: value } });
  };

  const onSearchChange = (event) => {
    if (filters.page !== 1) {
      filterDispatch({ type: 'updatePageNum', payload: { page: 1 } });
    }
    filterDispatch({
      type: 'updateSearchBy',
      payload: { search: event.target.value.toLowerCase() }
    });
  };

  const customerMenuFilterHandler = (e) => {
    filterDispatch({
      type: 'updateUserType',
      payload: { type: e.target.value }
    });
    dispatch({ type: 'sortCustomers', payload: { sort: e.target.value } });
  };

  const onClearSearchBy = () => {
    filterDispatch({
      type: 'updateSearchBy',
      payload: { search: '' }
    });
  };

  const filterSearchCustomer = ({ name = '', phoneNumber = '' }) =>
    searchStringInItems(filters.search, [name, phoneNumber.toString()]);

  const searchedCustomers = customers.filter(filterSearchCustomer);
  const filteredCustomers = searchedCustomers.slice(
    (filters.page - 1) * itemsPerPage,
    filters.page * itemsPerPage
  );
  const noOfPages = Math.ceil(searchedCustomers.length / itemsPerPage);

  return (
    <Grid container className={classes.root}>
      <Grid
        container
        item
        xs={12}
        style={{
          paddingTop: '1rem'
        }}>
        <Grid item xs={12}>
          <Typography variant="h3">Customers List</Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justify="flex-end"
          style={{ margin: '1rem 0' }}>
          <Grid item md={3} xs={6}>
            <List>
              <ListItem>
                <TextField
                  select
                  variant="standard"
                  size="small"
                  fullWidth
                  defaultValue="newest"
                  value={filters.type}
                  onChange={customerMenuFilterHandler}>
                  <MenuItem value="newest">Newest</MenuItem>
                  <MenuItem value="oldest">Oldest</MenuItem>
                </TextField>
              </ListItem>
            </List>
          </Grid>
          <Grid container alignItems="center" item md={3} xs={6}>
            <CustomSearchBar
              value={filters.search}
              onChange={onSearchChange}
              placeholder="Search by name / ph. number"
              onClear={onClearSearchBy}
            />
          </Grid>
        </Grid>
        <Grid container item direction="column" xs={12}>
          {filteredCustomers.length > 0 ? (
            filteredCustomers.map((item) => (
              <Card
                key={`users-orders-${item.id}`}
                style={{
                  marginBottom: '0.5rem',
                  paddingTop: '0.5rem',
                  height: '8rem'
                }}>
                <CardContent>
                  <Grid
                    container
                    justify="space-between"
                    style={{ marginLeft: '1.5rem', marginBottom: '1rem' }}>
                    <Grid
                      container
                      item
                      xs={4}
                      spacing={5}
                      justify="flex-start"
                      alignItems="center"
                      direction="row">
                      <Avatar className={classes.profileImg}>
                        {getInitials(item.name)}
                      </Avatar>

                      <Link to={`/admin/customers/${item.id}`}>
                        <ListItemText
                          primary={item.name}
                          secondary={item.phoneNumber}
                        />
                      </Link>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={4}
                      alignItems="center"
                      justify="flex-end">
                      <Typography
                        align="right"
                        style={{ marginRight: '1.5rem' }}>
                        {`${item.location}  ${item.distance} `}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    item
                    xs={12}
                    style={{ padding: '1rem' }}>
                    <Grid container item xs={3}>
                      <Typography variant="body2">{item.orderDate}</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={8}
                    spacing={4}
                    justify="flex-end"
                    alignItems="center">
                    <Link to={`/admin/customers/${item.id}`}>
                      <Button
                        variant="outlined"
                        style={{ marginRight: '1rem' }}
                        color="primary"
                        size="small">
                        order history
                      </Button>
                    </Link>
                    <IconButton color="primary">
                      <a href={`tel:${item.phoneNumber}`}>
                        <CallRoundedIcon />
                      </a>
                    </IconButton>
                    <IconButton color="primary">
                      <a href={`mailTo:${item.email}`}>
                        <MailOutlineRoundedIcon />
                      </a>
                    </IconButton>
                  </Grid>
                </CardActions>
              </Card>
            ))
          ) : !loading ? (
            <Typography
              align="center"
              variant="h6"
              style={{ paddingTop: '1rem' }}>
              No customers found
            </Typography>
          ) : null}
        </Grid>
        {filteredCustomers.length > 0 ? (
          <Grid container item xs={12} justify="center">
            <Pagination
              count={noOfPages}
              page={filters.page}
              onChange={paginationHandler}
              defaultPage={1}
              color="secondary"
              shape="rounded"
            />
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default FilterUser;
