import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tabs,
  Typography
} from '@material-ui/core';
import SalesUserAddForm from './SalesUserAddForm';
import { ArrowBack, ArrowBackIos } from '@material-ui/icons';
import { useLoader } from 'layouts/loaderContext';
import { useSnackbar } from 'notistack';
import { getShopCreatedBy } from 'graphql/queries';
import { getFormattedPhoneNumber } from 'common/utilFunctions';
import moment from 'moment';
import { API, graphqlOperation } from 'aws-amplify';

const tabs = [
  {
    label: 'Update User',
    value: 0
  },
  {
    label: 'Activity',
    value: 1
  }
];

const SalesUserActivity = ({
  handleCancel,
  handleUpdateUsers = () => {},
  edit = false,
  selectedUser
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [userShops, setUserShops] = useState([]);
  const { setLoading } = useLoader();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    const sBar = enqueueSnackbar('loading shops...', {
      variant: 'info',
      persist: true
    });
    API.graphql(
      graphqlOperation(getShopCreatedBy, {
        createdBy: selectedUser.id
      })
    )
      .then((data) => {
        setUserShops(data.data.getShopCreatedBy.items);
      })
      .catch((e) => {
        enqueueSnackbar('something went wrong', { variant: 'error' });
        console.error('something went wrong', e);
      })
      .finally(() => {
        closeSnackbar(sBar);
        setLoading(false);
      });
  }, [selectedUser]);

  return (
    <Paper style={{ width: '100%' }}>
      <Grid container>
        <IconButton onClick={handleCancel}>
          <ArrowBack />
        </IconButton>
        <Tabs
          value={currentTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, newVal) => setCurrentTab(newVal)}
          aria-label="sales-user-activities">
          {tabs.map((item) => (
            <Tab
              label={item.label}
              value={item.value}
              key={`sales-user-activities-items-${item.value}`}
            />
          ))}
        </Tabs>
      </Grid>

      <Grid container>
        {currentTab === 0 ? (
          <SalesUserAddForm
            handleCancel={handleCancel}
            handleUpdateUsers={handleUpdateUsers}
            edit={edit}
            selectedUser={selectedUser}
          />
        ) : (
          <Card style={{ width: '100%' }}>
            <CardHeader
              title={'User Activities'}
              subheader={`user added ${userShops.length} ${
                userShops.length > 1 ? 'shops' : 'shop'
              }`}
            />
            {userShops.length > 0 ? (
              <CardContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {/* <TableSortLabel
                        // active={orderBy === 'shopID'}
                        // direction={orderBy === 'shopID' ? order : 'asc'}
                        // onClick={e => {
                        //   handleRequestSort(e, 'shopID');
                        // }}
                        > */}
                        Shop Id
                        {/* </TableSortLabel> */}
                      </TableCell>
                      <TableCell>
                        {/* <TableSortLabel
                        // active={orderBy === 'name'}
                        // direction={orderBy === 'name' ? order : 'asc'}
                        // onClick={e => {
                        //   handleRequestSort(e, 'name');
                        // }}
                        > */}
                        Shop Name
                        {/* </TableSortLabel> */}
                      </TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>Shop Contact</TableCell>
                      <TableCell>Created Date</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userShops
                      // .filter(filterShops)
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((Shop) => (
                        <TableRow hover key={`user-shops-items-${Shop.id}`}>
                          <TableCell>{Shop.shopID}</TableCell>
                          <TableCell>
                            <Typography variant="body1">{Shop.name}</Typography>
                          </TableCell>
                          <TableCell>
                            {Shop?.address}, {Shop?.town}, {Shop?.postcode}
                          </TableCell>
                          <TableCell>
                            {Shop.contactName}, <br /> {Shop.email}, <br />
                            {getFormattedPhoneNumber(Shop.phoneNumber)}
                          </TableCell>
                          <TableCell>
                            {Shop.createdAt
                              ? moment(Shop.createdAt).format('DD/MM/YYYY')
                              : 'N/A'}
                          </TableCell>
                          <TableCell>{Shop.status || 'pending'}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </CardContent>
            ) : null}
          </Card>
        )}
      </Grid>
    </Paper>
  );
};

export default SalesUserActivity;
