import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CardDetails } from './components';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%'
  },
  nameField: {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow:
      'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px',
    '& fieldset': {
      border: '1px solid transparent'
    }
  },
  nameFieldError: {
    '& fieldset': {
      border: '1px solid #fa755a'
    }
  },
  nameFieldContainer: { padding: '0.5rem' }
}));

const StripeCardPaymentForm = ({
  className = '',
  name = { value: '', error: false },
  handleNameChange = () => {}
}) => {
  const classes = useStyles();

  return (
    <form noValidate className={clsx(classes.fullWidth, className)}>
      <CardDetails />
      <Grid
        container
        direction="column"
        justify="space-between"
        className={classes.container}>
        <Grid item xs={12} className={classes.nameFieldContainer}>
          <TextField
            label="Card holder name"
            variant="outlined"
            size="medium"
            className={clsx(
              classes.nameField,
              !!name.error && classes.nameFieldError
            )}
            placeholder="Jenny Rosen"
            onChange={handleNameChange}
            error={!!name.error}
            value={name.value}
            fullWidth
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default StripeCardPaymentForm;
