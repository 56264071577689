import { API, graphqlOperation } from 'aws-amplify';
import {
  getOrderForwardShopByShop,
  getOrderForwardRequestByShop
} from './orderQueries';
import {
  acceptOrderForwardRequest,
  createAnOrderForwardRequest,
  updateOrderForwardRequest as updateAnOrderForwardRequest,
  updateOrderForwardShop as updateAnOrderForwardShop
} from 'graphql/mutations';
import { updateAnOrder } from './orderMutations';

export const createOrderForwardRequest = (input) =>
  new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(createAnOrderForwardRequest, input))
      .then((data) => resolve(data.data.createAnOrderForwardRequest))
      .catch((err) => reject(err));
  });

export const updateOrderForwardRequest = (input) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(updateAnOrderForwardRequest, input))
      .then((data) => resolve(data.data.updateOrderForwardRequest))
      .catch((e) => reject(e));
  });
};

export const updateOrderForwardShop = (input) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(updateAnOrderForwardShop, input))
      .then((data) => resolve(data.data.updateOrderForwardShop))
      .catch((e) => reject(e));
  });
};

export const updateOrder = (input) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(updateAnOrder, { input }))
      .then((data) => resolve(data.data.updateAnOrder))
      .catch(reject);
  });
};

export const getOrderForwardShopByShopId = (shopId) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(getOrderForwardShopByShop, { shopID: shopId }))
      .then((data) => {
        resolve(data.data.getOrderForwardShopByShop);
      })
      .catch((err) => reject(err));
  });
};

export const getOrderForwardRequestByShopId = (shopId) => {
  return new Promise((resolve, reject) => {
    API.graphql(
      graphqlOperation(getOrderForwardRequestByShop, { requestShopID: shopId })
    )
      .then((data) => {
        resolve(data.data.getOrderForwardRequestByShop);
      })
      .catch((err) => reject(err));
  });
};

export const acceptOrderForwardRequestById = (id) => {
  return new Promise((resolve, reject) => {
    API.graphql(
      graphqlOperation(acceptOrderForwardRequest, {
        orderForwardShopID: id
      })
    )
      .then((data) => {
        resolve(data.data.acceptOrderForwardRequest);
      })
      .catch((err) => reject(err));
  });
};
