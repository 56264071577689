import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useAmplifyAuth } from 'context';
import {
  Button,
  Grid,
  IconButton,
  AppBar,
  Tooltip,
  useMediaQuery,
  List,
  ListItem,
  Collapse,
  ListItemText
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { routes } from 'common/constants';
import OrdersTable from './components/OrdersTable';
import { CustomSearchBar, DocumentDeleteDialog } from 'components/organisms';
import { StyledTabs, StyledTab, TabPanel } from '../../components/molecules';
import {
  TABS,
  TAB_ORDER_TABLE_STATUS,
  TAB_ORDER_TABLE_HEADER,
  DIALOG_NAME_FOR_URL
} from './constants';
import { useOrders } from 'context/order/ordersContext';
import { DeclineDialog } from './components/dialogs/DeclineDialog';
import { ConfirmOrderDialog } from './components/dialogs/ConfirmOrderDialog';
import { ItemisedDialog } from './components/dialogs/ItemisedDialog';
import { OrderForwardDialog } from './components/dialogs/OrderForwardDialog';
import OrderDetailsDialog from './components/dialogs/OrderDetailsDialog';
import MessageDialog from 'views/Messages/MessageDialog';
import ReplayIcon from '@material-ui/icons/Replay';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper
  },
  tabsBar: {
    backgroundColor: 'transparent',
    color: '#263238',
    paddingBottom: '1rem',
    marginTop: '1rem',
    boxShadow: 'none'
  },
  tabsList: {
    width: '100%',
    border: '0.8px solid #0000003b',
    padding: 0,
    borderRadius: '0.25rem'
  },
  forwardOrderBtn: {
    marginRight: '1rem'
  }
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const Orders = () => {
  const {
    state: { user }
  } = useAmplifyAuth();
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const { tabName, orderID, actionType } = useParams();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const { orders, forwardRequests, dispatch } = useOrders();
  const [dialogType, setDialogType] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [value, setValue] = useState(-1);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [searchBy, setSearchBy] = useState('');
  const [showOrderDetails, setShowOrderDetails] = useState(false);

  useEffect(() => {
    const currentTabValue = TABS.findIndex((tab) => tab.name === tabName);
    setValue(currentTabValue >= 0 ? currentTabValue : 0);
  }, [tabName]);

  const loadOrders = (useSearchString = true, newQuery = true) => {
    if (user?.shopId || user?.shopID) {
      dispatch({
        type: 'getOrders',
        payload: {
          shopId: user.shopId || user.shopID,
          status: TABS[value].orderStatuses,
          orderId: useSearchString ? searchBy : '',
          newQuery
        }
      });
      // TODO forward order
      // dispatch({
      //   type: 'getOrderForwardShopByShopId',
      //   payload: {
      //     shopId: user?.shopId || user?.shopID,
      //     showCurrentRequests: true
      //   }
      // });
    }
  };

  useEffect(() => {
    value >= 0 && loadOrders();
  }, [user, value]);

  useEffect(() => {
    // find current tab index from tabName url prop
    const currentTabValueFromURL = TABS.findIndex(
      (tab) => tab.name === tabName
    );
    // if tab value is available & orders are available & orders are of correct statuses only set the dialog type
    if (
      value >= 0 &&
      orders.length > 0 &&
      orders.some((item) =>
        TABS.at(currentTabValueFromURL).orderStatuses.includes(item.status)
      )
    ) {
      // if orderID is available proceed to set dialog type
      if (orderID) {
        const dialogType = Object.keys(DIALOG_NAME_FOR_URL).find(
          (item) => DIALOG_NAME_FOR_URL[item] === actionType
        );
        if (!dialogType) {
          history.replace(`${TABS.at(value).route}/${orderID}/details`);
          return;
        }
        const currentOrder = orders.find(
          (order) => order.orderID.toLowerCase() === orderID.toLowerCase()
        );
        if (currentOrder?.id) {
          setSelectedOrder(currentOrder);
          dialogType === 'details' && setShowOrderDetails(true);
          setDialogType(dialogType);
        } else history.replace(TABS.at(value).route);
      } else {
        // else set dialog type as null, also remove selected order
        setDialogType((prev) => {
          prev === 'details' && setShowOrderDetails(false);
          return null;
        });
      }
    }
  }, [orders, orderID, actionType]);

  const onSearchChange = (event) => {
    if (event.key === 'Enter') loadOrders();
    else setSearchBy(event.target.value.toLowerCase());
  };

  const onSearchClear = () => {
    setSearchBy('');
    loadOrders(false);
  };

  const handleChange = (_, newValue) => {
    setValue(newValue);
    history.replace(TABS.at(newValue).route);
  };

  const handleDropdownItemClick = (id) => () => {
    handleChange({}, id);
    setOpenDropdown(false);
  };

  const handleModalClose = () => {
    setDialogType((prev) => {
      if (orderID)
        if (history.length > 10 && dialogType !== 'showMessages')
          history.goBack();
        else if (prev && prev !== 'details' && showOrderDetails)
          history.replace(`${TABS.at(value).route}/${orderID}/details`);
        else history.replace(TABS.at(value).route);
      if (prev === 'details') setShowOrderDetails(false);
      return null;
    });
  };

  const handleSubmit = (newPayload) => {
    history.replace(TABS.at(value).route);
    setShowOrderDetails(false);

    if (newPayload?.sts === 'forwarded') {
      dispatch({ type: 'createOrderForwardRequest', payload: newPayload });
    } else {
      dispatch({ type: 'updateOrder', payload: newPayload });
    }
  };

  const updateOrder = (order, sts) => {
    if (
      sts === 'inProgress' ||
      sts === 'outForDelivery' ||
      (!!order.bidID && sts === 'itemized')
    ) {
      // update order to the next status
      dispatch({ type: 'updateOrder', payload: { order, sts } });
    } else if (sts === 'pending') {
      dispatch({
        type: 'updateOrderForwardRequest',
        payload: { order, sts, shopId: user?.shopId || user?.shopID }
      });
    } else if (sts === 'rejected') {
      dispatch({
        type: 'rejectOrderForwardShop',
        payload: { order, sts, shopId: user?.shopId || user?.shopID }
      });
    } else {
      // open order related dialogs by updating the URL
      history.push(
        `${TABS.at(value).route}/${order.orderID}/${
          DIALOG_NAME_FOR_URL[sts] || ''
        }`
      );
    }
  };

  const ORDERS_TABLE_COMMON_PROPS = {
    updateOrder,
    setShowOrderDetails,
    loadOrders
  };

  return (
    <>
      <Grid className={classes.root}>
        <Grid container item justify="space-between" xs={12}>
          <Tooltip title="reload" placement="right">
            <IconButton onClick={() => loadOrders()}>
              <ReplayIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Grid item>
            {/* TODO ORDER_FORWARD */}
            {/* <Link to={routes.serviceProvider.forwardRequests}>
              <Badge
                badgeContent={forwardRequests.length || 0}
                color="secondary"
                className={classes.forwardOrderBtn}>
                <Button color="primary" variant="contained">
                  Forward Requests
                </Button>
              </Badge>
            </Link> */}
            <Link to={routes.serviceProvider.orderHistory}>
              <Button color="secondary" variant="contained">
                order history
              </Button>
            </Link>
          </Grid>
        </Grid>
        {/* top tabs bar */}
        <AppBar position="static" className={classes.tabsBar}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{ gap: '0.5rem' }}>
            {md ? (
              <Grid container item xs={8} justify="flex-start">
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  aria-label="orders-tabs">
                  {TABS.map((_item, index) => {
                    return (
                      <StyledTab
                        label={_item.label}
                        showBadge
                        badgeContent={
                          value === index
                            ? orders.filter((order) =>
                                _item.orderStatuses.includes(order.status)
                              ).length
                            : 0
                        }
                        {...a11yProps(index)}
                        key={`orders-tab-tab-items-${index}`}
                      />
                    );
                  })}
                </StyledTabs>
              </Grid>
            ) : null}
            <Grid item container xs={12} md={3} justify="flex-end">
              <CustomSearchBar
                value={searchBy}
                onChange={onSearchChange}
                onKeyDown={onSearchChange}
                placeholder="Search Order"
                onClear={onSearchClear}
                onSearch={loadOrders}
              />
            </Grid>
            {md ? null : (
              <Grid item xs={12}>
                <List className={classes.tabsList}>
                  <ListItem
                    button
                    onClick={() => setOpenDropdown((_prev) => !_prev)}>
                    <ListItemText primary={TABS[value]?.label || ''} />
                    {openDropdown ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={openDropdown} timeout="auto" unmountOnExit>
                    <List>
                      {TABS.map((item, index) => {
                        return (
                          <ListItem
                            key={`orders-tab-tab-items-${index}`}
                            button
                            onClick={handleDropdownItemClick(index)}
                            selected={value === index}>
                            <ListItemText primary={item.label} />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </List>
              </Grid>
            )}
          </Grid>
        </AppBar>

        {/* tabs */}
        {value >= 0 &&
          TABS.map((_item, index) => (
            <TabPanel
              value={value}
              index={index}
              key={`orders-tab-tabpanels-items-${index}`}>
              {_item.stages.map((_stage, i) => (
                <OrdersTable
                  stage={_stage}
                  headerTitle={TAB_ORDER_TABLE_HEADER[_stage]}
                  key={`orders-tab-tabpanel-${index}-tables-items-${i}`}
                  statuses={TAB_ORDER_TABLE_STATUS[_stage]}
                  {...ORDERS_TABLE_COMMON_PROPS}
                />
              ))}
            </TabPanel>
          ))}
      </Grid>

      {/* dialogs */}
      <DeclineDialog
        dialogType={dialogType}
        onSuccess={handleSubmit}
        handleModalClose={handleModalClose}
        order={selectedOrder}
      />
      <ConfirmOrderDialog
        dialogType={dialogType}
        onSuccess={handleSubmit}
        handleModalClose={handleModalClose}
        order={selectedOrder}
      />
      <ItemisedDialog
        dialogType={dialogType}
        onSuccess={handleSubmit}
        order={selectedOrder}
        handleModalClose={handleModalClose}
      />
      <OrderDetailsDialog
        dialogType={dialogType}
        order={selectedOrder}
        handleModalClose={handleModalClose}
        updateOrder={updateOrder}
        showOrderDetails={showOrderDetails}
        setShowOrderDetails={setShowOrderDetails}
      />
      <MessageDialog
        open={dialogType === 'showMessages'}
        handleModalClose={handleModalClose}
        order={selectedOrder}
      />
      <DocumentDeleteDialog
        open={dialogType === 'delivered'}
        handleCancel={handleModalClose}
        handleConfirm={() =>
          handleSubmit({ order: selectedOrder, sts: 'delivered' })
        }
        confirmText="mark this order as delivered"
        title={`Confirmation for #${selectedOrder?.orderID || 'order'}`}
        note={
          selectedOrder?.paymentStatus === 'paid'
            ? ''
            : 'The payment for this order has not been completed yet.'
        }
      />
      {dialogType === 'forwarded' && (
        <OrderForwardDialog
          dialogType={dialogType}
          onSuccess={handleSubmit}
          handleModalClose={handleModalClose}
          order={selectedOrder}
        />
      )}
    </>
  );
};

export default Orders;
//  newOrder;
//  accepted;
//  declined;
//  itemized;
//  inProgress;
//  readyForDelivery;
//  outForDelivery;
//  delivered;
//  cancelled;
