/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getShopBankAccountDetails = /* GraphQL */ `
  query GetShopBankAccountDetails($shopID: String) {
    getShopBankAccountDetails(shopID: $shopID) {
      id
      shopID
      name
      accountNumber
      accountSortCode
      accountHolderName
      accountNumberLastDigits
      _version
      _deleted
      createdAt
      __typename
    }
  }
`;
export const getShopUsers = /* GraphQL */ `
  query GetShopUsers {
    getShopUsers {
      UserCreateDate
      UserLastModifiedDate
      Enabled
      Attributes {
        Name
        Value
        __typename
      }
      Username
      UserStatus
      __typename
    }
  }
`;
export const getAllShopUsers = /* GraphQL */ `
  query GetAllShopUsers($shopId: String!) {
    getAllShopUsers(shopId: $shopId) {
      UserCreateDate
      UserLastModifiedDate
      Enabled
      Attributes {
        Name
        Value
        __typename
      }
      Username
      UserStatus
      __typename
    }
  }
`;
export const getSalesUsers = /* GraphQL */ `
  query GetSalesUsers {
    getSalesUsers {
      id
      groups
      picture
      username
      userId
      firstName
      lastName
      title
      defaultAddress
      email
      address
      postalCode
      phoneNumber
      createdAt
      shopID
      stripeID
      status
      language
      dateOfBirth
      documents {
        name
        documentType
        documentURL
        description
        MIMETypes
        status
        __typename
      }
      updatedAt
      favouriteShops {
        items {
          id
          shopID
          userID
          isFavourite
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const getCustomers = /* GraphQL */ `
  query GetCustomers {
    getCustomers {
      UserCreateDate
      UserLastModifiedDate
      Enabled
      Attributes {
        Name
        Value
        __typename
      }
      Username
      UserStatus
      __typename
    }
  }
`;
export const getOrderByShopIdDateOIDStatus = /* GraphQL */ `
  query GetOrderByShopIdDateOIDStatus(
    $shopId: String!
    $orderId: String
    $status: [OrderStatus]
    $createdDateRange: DateRange
    $nextToken: String
  ) {
    getOrderByShopIdDateOIDStatus(
      shopId: $shopId
      orderId: $orderId
      status: $status
      createdDateRange: $createdDateRange
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        orderID
        bidID
        orderList {
          productID
          serviceID
          category
          name
          codeName
          noOfPieces
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          totalPrice
          priceRange
          quantity
          __typename
        }
        userID
        status
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        paymentMethod
        orderCancelOrDeclineReason
        total
        variableTotal
        discount
        tax
        noOfPieces
        paidAmount
        onHoldPaymentIntentID
        onHoldPaymentAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
          distanceRangeForFree
          distanceRange
          __typename
        }
        itemizedAt
        payments {
          nextToken
          startedAt
          __typename
        }
        bid {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        forwardRequests {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        customerReview {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getIsDeliveryIssueMessagePresent = /* GraphQL */ `
  query GetIsDeliveryIssueMessagePresent($orderID: String!) {
    getIsDeliveryIssueMessagePresent(orderID: $orderID) {
      statusCode
      body
      __typename
    }
  }
`;
export const syncShopBankAccounts = /* GraphQL */ `
  query SyncShopBankAccounts(
    $filter: ModelShopBankAccountFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncShopBankAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shopID
        key
        name
        accountNumber
        accountSortCode
        accountHolderName
        accountNumberLastDigits
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserOTPS = /* GraphQL */ `
  query SyncUserOTPS(
    $filter: ModelUserOTPFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserOTPS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        OTP
        expiresAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrders = /* GraphQL */ `
  query SyncOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shopID
        orderID
        bidID
        orderList {
          productID
          serviceID
          category
          name
          codeName
          noOfPieces
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          totalPrice
          priceRange
          quantity
          __typename
        }
        userID
        status
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        paymentMethod
        orderCancelOrDeclineReason
        total
        variableTotal
        discount
        tax
        noOfPieces
        paidAmount
        onHoldPaymentIntentID
        onHoldPaymentAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
          distanceRangeForFree
          distanceRange
          __typename
        }
        itemizedAt
        payments {
          nextToken
          startedAt
          __typename
        }
        bid {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        forwardRequests {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        customerReview {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      shopID
      orderID
      bidID
      orderList {
        productID
        serviceID
        category
        name
        codeName
        noOfPieces
        description
        discount
        discountCoupon
        discountCouponID
        discountDescription
        overallDiscount
        unit
        price
        totalPrice
        priceRange
        quantity
        __typename
      }
      userID
      status
      collection {
        date
        time
        type
        address
        driver {
          userID
          firstName
          lastName
          title
          email
          phoneNumber
          __typename
        }
        instructions
        __typename
      }
      delivery {
        date
        time
        type
        address
        driver {
          userID
          firstName
          lastName
          title
          email
          phoneNumber
          __typename
        }
        instructions
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      paymentStatus
      paymentMethod
      orderCancelOrDeclineReason
      total
      variableTotal
      discount
      tax
      noOfPieces
      paidAmount
      onHoldPaymentIntentID
      onHoldPaymentAmount
      coupons
      collectionAndDeliveryFee
      currentShopFees {
        standardDeliveryFee
        AsapDeliveryFee
        minPriceForFree
        distanceRangeForFree
        distanceRange
        __typename
      }
      itemizedAt
      payments {
        items {
          id
          orderID
          shopID
          userID
          stripePaymentIntentID
          amount
          createdAt
          createdBy
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bid {
        id
        auctionID
        shopID
        createdBy
        createdAt
        totalPrice
        shippingCharges
        items {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        auction {
          id
          auctionID
          userID
          createdAt
          startDateTime
          endDateTime
          postcode
          isClosed
          total
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      forwardRequests {
        items {
          id
          orderID
          forwardReason
          requestShopID
          acceptedShopID
          createdAt
          createdBy
          status
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      userDetail {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customerReview {
        items {
          id
          shopID
          userID
          orderID
          replyID
          title
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shopID
        orderID
        bidID
        orderList {
          productID
          serviceID
          category
          name
          codeName
          noOfPieces
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          totalPrice
          priceRange
          quantity
          __typename
        }
        userID
        status
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        paymentMethod
        orderCancelOrDeclineReason
        total
        variableTotal
        discount
        tax
        noOfPieces
        paidAmount
        onHoldPaymentIntentID
        onHoldPaymentAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
          distanceRangeForFree
          distanceRange
          __typename
        }
        itemizedAt
        payments {
          nextToken
          startedAt
          __typename
        }
        bid {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        forwardRequests {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        customerReview {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrderForwardRequests = /* GraphQL */ `
  query SyncOrderForwardRequests(
    $filter: ModelOrderForwardRequestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrderForwardRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        orderID
        forwardReason
        requestShopID
        acceptedShopID
        createdAt
        createdBy
        status
        forwardShops {
          nextToken
          startedAt
          __typename
        }
        order {
          id
          shopID
          orderID
          bidID
          userID
          status
          createdAt
          updatedAt
          updatedBy
          paymentStatus
          paymentMethod
          orderCancelOrDeclineReason
          total
          variableTotal
          discount
          tax
          noOfPieces
          paidAmount
          onHoldPaymentIntentID
          onHoldPaymentAmount
          coupons
          collectionAndDeliveryFee
          itemizedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        requestShop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        acceptedShop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrderForwardRequest = /* GraphQL */ `
  query GetOrderForwardRequest($id: ID!) {
    getOrderForwardRequest(id: $id) {
      id
      orderID
      forwardReason
      requestShopID
      acceptedShopID
      createdAt
      createdBy
      status
      forwardShops {
        items {
          id
          shopID
          forwardRequestID
          status
          forwaredBy
          createdAt
          createdBy
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      order {
        id
        shopID
        orderID
        bidID
        orderList {
          productID
          serviceID
          category
          name
          codeName
          noOfPieces
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          totalPrice
          priceRange
          quantity
          __typename
        }
        userID
        status
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        paymentMethod
        orderCancelOrDeclineReason
        total
        variableTotal
        discount
        tax
        noOfPieces
        paidAmount
        onHoldPaymentIntentID
        onHoldPaymentAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
          distanceRangeForFree
          distanceRange
          __typename
        }
        itemizedAt
        payments {
          nextToken
          startedAt
          __typename
        }
        bid {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        forwardRequests {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        customerReview {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      requestShop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      acceptedShop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listOrderForwardRequests = /* GraphQL */ `
  query ListOrderForwardRequests(
    $filter: ModelOrderForwardRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderForwardRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderID
        forwardReason
        requestShopID
        acceptedShopID
        createdAt
        createdBy
        status
        forwardShops {
          nextToken
          startedAt
          __typename
        }
        order {
          id
          shopID
          orderID
          bidID
          userID
          status
          createdAt
          updatedAt
          updatedBy
          paymentStatus
          paymentMethod
          orderCancelOrDeclineReason
          total
          variableTotal
          discount
          tax
          noOfPieces
          paidAmount
          onHoldPaymentIntentID
          onHoldPaymentAmount
          coupons
          collectionAndDeliveryFee
          itemizedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        requestShop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        acceptedShop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrderForwardShops = /* GraphQL */ `
  query SyncOrderForwardShops(
    $filter: ModelOrderForwardShopFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrderForwardShops(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shopID
        forwardRequestID
        status
        forwaredBy
        createdAt
        createdBy
        forwardRequest {
          id
          orderID
          forwardReason
          requestShopID
          acceptedShopID
          createdAt
          createdBy
          status
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrderForwardShop = /* GraphQL */ `
  query GetOrderForwardShop($id: ID!) {
    getOrderForwardShop(id: $id) {
      id
      shopID
      forwardRequestID
      status
      forwaredBy
      createdAt
      createdBy
      forwardRequest {
        id
        orderID
        forwardReason
        requestShopID
        acceptedShopID
        createdAt
        createdBy
        status
        forwardShops {
          nextToken
          startedAt
          __typename
        }
        order {
          id
          shopID
          orderID
          bidID
          userID
          status
          createdAt
          updatedAt
          updatedBy
          paymentStatus
          paymentMethod
          orderCancelOrDeclineReason
          total
          variableTotal
          discount
          tax
          noOfPieces
          paidAmount
          onHoldPaymentIntentID
          onHoldPaymentAmount
          coupons
          collectionAndDeliveryFee
          itemizedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        requestShop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        acceptedShop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listOrderForwardShops = /* GraphQL */ `
  query ListOrderForwardShops(
    $filter: ModelOrderForwardShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderForwardShops(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        forwardRequestID
        status
        forwaredBy
        createdAt
        createdBy
        forwardRequest {
          id
          orderID
          forwardReason
          requestShopID
          acceptedShopID
          createdAt
          createdBy
          status
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrderHistories = /* GraphQL */ `
  query SyncOrderHistories(
    $filter: ModelOrderHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrderHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        orderID
        order {
          id
          shopID
          orderID
          bidID
          userID
          status
          createdAt
          updatedAt
          updatedBy
          paymentStatus
          paymentMethod
          orderCancelOrDeclineReason
          total
          variableTotal
          discount
          tax
          noOfPieces
          paidAmount
          onHoldPaymentIntentID
          onHoldPaymentAmount
          coupons
          collectionAndDeliveryFee
          itemizedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        status
        changeLog
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrderHistory = /* GraphQL */ `
  query GetOrderHistory($id: ID!) {
    getOrderHistory(id: $id) {
      id
      orderID
      order {
        id
        shopID
        orderID
        bidID
        orderList {
          productID
          serviceID
          category
          name
          codeName
          noOfPieces
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          totalPrice
          priceRange
          quantity
          __typename
        }
        userID
        status
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        paymentMethod
        orderCancelOrDeclineReason
        total
        variableTotal
        discount
        tax
        noOfPieces
        paidAmount
        onHoldPaymentIntentID
        onHoldPaymentAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
          distanceRangeForFree
          distanceRange
          __typename
        }
        itemizedAt
        payments {
          nextToken
          startedAt
          __typename
        }
        bid {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        forwardRequests {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        customerReview {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      userID
      status
      changeLog
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrderHistorys = /* GraphQL */ `
  query ListOrderHistorys(
    $filter: ModelOrderHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderID
        order {
          id
          shopID
          orderID
          bidID
          userID
          status
          createdAt
          updatedAt
          updatedBy
          paymentStatus
          paymentMethod
          orderCancelOrDeclineReason
          total
          variableTotal
          discount
          tax
          noOfPieces
          paidAmount
          onHoldPaymentIntentID
          onHoldPaymentAmount
          coupons
          collectionAndDeliveryFee
          itemizedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        userID
        status
        changeLog
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCounters = /* GraphQL */ `
  query SyncCounters(
    $filter: ModelCountersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCounters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        counterName
        currentValue
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCounters = /* GraphQL */ `
  query GetCounters($id: ID!) {
    getCounters(id: $id) {
      id
      counterName
      currentValue
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCounterss = /* GraphQL */ `
  query ListCounterss(
    $filter: ModelCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCounterss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        counterName
        currentValue
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMessages = /* GraphQL */ `
  query SyncMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        orderID
        sender
        shopID
        message
        messageIntent
        photo {
          name
          photoURL
          thumbURL
          MIMETypes
          __typename
        }
        status
        notificationID
        readAt
        createdAt
        order {
          id
          shopID
          orderID
          bidID
          userID
          status
          createdAt
          updatedAt
          updatedBy
          paymentStatus
          paymentMethod
          orderCancelOrDeclineReason
          total
          variableTotal
          discount
          tax
          noOfPieces
          paidAmount
          onHoldPaymentIntentID
          onHoldPaymentAmount
          coupons
          collectionAndDeliveryFee
          itemizedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        notification {
          id
          userID
          shopID
          orderID
          messageID
          isRead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        senderDetails {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      orderID
      sender
      shopID
      message
      messageIntent
      photo {
        name
        photoURL
        thumbURL
        MIMETypes
        __typename
      }
      status
      notificationID
      readAt
      createdAt
      order {
        id
        shopID
        orderID
        bidID
        orderList {
          productID
          serviceID
          category
          name
          codeName
          noOfPieces
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          totalPrice
          priceRange
          quantity
          __typename
        }
        userID
        status
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        paymentMethod
        orderCancelOrDeclineReason
        total
        variableTotal
        discount
        tax
        noOfPieces
        paidAmount
        onHoldPaymentIntentID
        onHoldPaymentAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
          distanceRangeForFree
          distanceRange
          __typename
        }
        itemizedAt
        payments {
          nextToken
          startedAt
          __typename
        }
        bid {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        forwardRequests {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        customerReview {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      notification {
        id
        userID
        shopID
        orderID
        messageID
        isRead
        createdAt
        updatedAt
        message {
          id
          orderID
          sender
          shopID
          message
          messageIntent
          status
          notificationID
          readAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      senderDetails {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderID
        sender
        shopID
        message
        messageIntent
        photo {
          name
          photoURL
          thumbURL
          MIMETypes
          __typename
        }
        status
        notificationID
        readAt
        createdAt
        order {
          id
          shopID
          orderID
          bidID
          userID
          status
          createdAt
          updatedAt
          updatedBy
          paymentStatus
          paymentMethod
          orderCancelOrDeclineReason
          total
          variableTotal
          discount
          tax
          noOfPieces
          paidAmount
          onHoldPaymentIntentID
          onHoldPaymentAmount
          coupons
          collectionAndDeliveryFee
          itemizedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        notification {
          id
          userID
          shopID
          orderID
          messageID
          isRead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        senderDetails {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMessageNotifications = /* GraphQL */ `
  query SyncMessageNotifications(
    $filter: ModelMessageNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMessageNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        shopID
        orderID
        messageID
        isRead
        createdAt
        updatedAt
        message {
          id
          orderID
          sender
          shopID
          message
          messageIntent
          status
          notificationID
          readAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMessageNotification = /* GraphQL */ `
  query GetMessageNotification($id: ID!) {
    getMessageNotification(id: $id) {
      id
      userID
      shopID
      orderID
      messageID
      isRead
      createdAt
      updatedAt
      message {
        id
        orderID
        sender
        shopID
        message
        messageIntent
        photo {
          name
          photoURL
          thumbURL
          MIMETypes
          __typename
        }
        status
        notificationID
        readAt
        createdAt
        order {
          id
          shopID
          orderID
          bidID
          userID
          status
          createdAt
          updatedAt
          updatedBy
          paymentStatus
          paymentMethod
          orderCancelOrDeclineReason
          total
          variableTotal
          discount
          tax
          noOfPieces
          paidAmount
          onHoldPaymentIntentID
          onHoldPaymentAmount
          coupons
          collectionAndDeliveryFee
          itemizedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        notification {
          id
          userID
          shopID
          orderID
          messageID
          isRead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        senderDetails {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMessageNotifications = /* GraphQL */ `
  query ListMessageNotifications(
    $filter: ModelMessageNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        shopID
        orderID
        messageID
        isRead
        createdAt
        updatedAt
        message {
          id
          orderID
          sender
          shopID
          message
          messageIntent
          status
          notificationID
          readAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncNotifications = /* GraphQL */ `
  query SyncNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        shopID
        message
        isRead
        type {
          variant
          modelID
          model
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      userID
      shopID
      message
      isRead
      type {
        variant
        modelID
        model
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        shopID
        message
        isRead
        type {
          variant
          modelID
          model
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPayments = /* GraphQL */ `
  query SyncPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPayments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        orderID
        shopID
        userID
        stripePaymentIntentID
        amount
        createdAt
        createdBy
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      orderID
      shopID
      userID
      stripePaymentIntentID
      amount
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderID
        shopID
        userID
        stripePaymentIntentID
        amount
        createdAt
        createdBy
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAuctions = /* GraphQL */ `
  query SyncAuctions(
    $filter: ModelAuctionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAuctions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        auctionID
        userID
        createdAt
        startDateTime
        endDateTime
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        postcode
        isClosed
        total
        items {
          nextToken
          startedAt
          __typename
        }
        bids {
          nextToken
          startedAt
          __typename
        }
        shops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        user {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAuction = /* GraphQL */ `
  query GetAuction($id: ID!) {
    getAuction(id: $id) {
      id
      auctionID
      userID
      createdAt
      startDateTime
      endDateTime
      collection {
        date
        time
        type
        address
        driver {
          userID
          firstName
          lastName
          title
          email
          phoneNumber
          __typename
        }
        instructions
        __typename
      }
      delivery {
        date
        time
        type
        address
        driver {
          userID
          firstName
          lastName
          title
          email
          phoneNumber
          __typename
        }
        instructions
        __typename
      }
      postcode
      isClosed
      total
      items {
        items {
          id
          auctionID
          serviceID
          name
          description
          quantity
          image
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bids {
        items {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      shops {
        items {
          id
          shopID
          auctionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const listAuctions = /* GraphQL */ `
  query ListAuctions(
    $filter: ModelAuctionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuctions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        auctionID
        userID
        createdAt
        startDateTime
        endDateTime
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        postcode
        isClosed
        total
        items {
          nextToken
          startedAt
          __typename
        }
        bids {
          nextToken
          startedAt
          __typename
        }
        shops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        user {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAuctionItems = /* GraphQL */ `
  query SyncAuctionItems(
    $filter: ModelAuctionItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAuctionItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        auctionID
        serviceID
        name
        description
        quantity
        image
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          serviceID
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAuctionItem = /* GraphQL */ `
  query GetAuctionItem($id: ID!) {
    getAuctionItem(id: $id) {
      id
      auctionID
      serviceID
      name
      description
      quantity
      image
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      service {
        id
        serviceID
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        photoGallaryImages {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listAuctionItems = /* GraphQL */ `
  query ListAuctionItems(
    $filter: ModelAuctionItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuctionItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        auctionID
        serviceID
        name
        description
        quantity
        image
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          serviceID
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBids = /* GraphQL */ `
  query SyncBids(
    $filter: ModelBidFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBids(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        auctionID
        shopID
        createdBy
        createdAt
        totalPrice
        shippingCharges
        items {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        auction {
          id
          auctionID
          userID
          createdAt
          startDateTime
          endDateTime
          postcode
          isClosed
          total
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBid = /* GraphQL */ `
  query GetBid($id: ID!) {
    getBid(id: $id) {
      id
      auctionID
      shopID
      createdBy
      createdAt
      totalPrice
      shippingCharges
      items {
        items {
          id
          bidID
          auctionItemID
          price
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          shopID
          orderID
          bidID
          userID
          status
          createdAt
          updatedAt
          updatedBy
          paymentStatus
          paymentMethod
          orderCancelOrDeclineReason
          total
          variableTotal
          discount
          tax
          noOfPieces
          paidAmount
          onHoldPaymentIntentID
          onHoldPaymentAmount
          coupons
          collectionAndDeliveryFee
          itemizedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      auction {
        id
        auctionID
        userID
        createdAt
        startDateTime
        endDateTime
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        postcode
        isClosed
        total
        items {
          nextToken
          startedAt
          __typename
        }
        bids {
          nextToken
          startedAt
          __typename
        }
        shops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        user {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listBids = /* GraphQL */ `
  query ListBids(
    $filter: ModelBidFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBids(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        auctionID
        shopID
        createdBy
        createdAt
        totalPrice
        shippingCharges
        items {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        auction {
          id
          auctionID
          userID
          createdAt
          startDateTime
          endDateTime
          postcode
          isClosed
          total
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBidItems = /* GraphQL */ `
  query SyncBidItems(
    $filter: ModelBidItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBidItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        bidID
        auctionItemID
        price
        bid {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBidItem = /* GraphQL */ `
  query GetBidItem($id: ID!) {
    getBidItem(id: $id) {
      id
      bidID
      auctionItemID
      price
      bid {
        id
        auctionID
        shopID
        createdBy
        createdAt
        totalPrice
        shippingCharges
        items {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        auction {
          id
          auctionID
          userID
          createdAt
          startDateTime
          endDateTime
          postcode
          isClosed
          total
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBidItems = /* GraphQL */ `
  query ListBidItems(
    $filter: ModelBidItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBidItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bidID
        auctionItemID
        price
        bid {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAuctionShops = /* GraphQL */ `
  query SyncAuctionShops(
    $filter: ModelAuctionShopFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAuctionShops(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shopID
        auctionID
        auction {
          id
          auctionID
          userID
          createdAt
          startDateTime
          endDateTime
          postcode
          isClosed
          total
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLastWhatsappMessages = /* GraphQL */ `
  query SyncLastWhatsappMessages(
    $filter: ModelLastWhatsappMessageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLastWhatsappMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        orderID
        message
        order {
          id
          shopID
          orderID
          bidID
          userID
          status
          createdAt
          updatedAt
          updatedBy
          paymentStatus
          paymentMethod
          orderCancelOrDeclineReason
          total
          variableTotal
          discount
          tax
          noOfPieces
          paidAmount
          onHoldPaymentIntentID
          onHoldPaymentAmount
          coupons
          collectionAndDeliveryFee
          itemizedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFavouriteShops = /* GraphQL */ `
  query SyncFavouriteShops(
    $filter: ModelFavouriteShopFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFavouriteShops(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shopID
        userID
        isFavourite
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        user {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listAddresses = /* GraphQL */ `
  query ListAddresses($params: QueryListAddressesParamsInput!) {
    listAddresses(params: $params) {
      addresses
      postcode
      latitude
      longitude
      __typename
    }
  }
`;
export const getDistanceBetweenPostcodes = /* GraphQL */ `
  query GetDistanceBetweenPostcodes(
    $params: QueryGetDistanceBetweenPostcodesParamsInput!
  ) {
    getDistanceBetweenPostcodes(params: $params) {
      from {
        addresses
        postcode
        latitude
        longitude
        __typename
      }
      to {
        addresses
        postcode
        latitude
        longitude
        __typename
      }
      metres
      __typename
    }
  }
`;
export const getOrderByOrderID = /* GraphQL */ `
  query GetOrderByOrderID(
    $orderID: String
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderByOrderID(
      orderID: $orderID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        orderID
        bidID
        orderList {
          productID
          serviceID
          category
          name
          codeName
          noOfPieces
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          totalPrice
          priceRange
          quantity
          __typename
        }
        userID
        status
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        paymentMethod
        orderCancelOrDeclineReason
        total
        variableTotal
        discount
        tax
        noOfPieces
        paidAmount
        onHoldPaymentIntentID
        onHoldPaymentAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
          distanceRangeForFree
          distanceRange
          __typename
        }
        itemizedAt
        payments {
          nextToken
          startedAt
          __typename
        }
        bid {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        forwardRequests {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        customerReview {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrderByShopId = /* GraphQL */ `
  query GetOrderByShopId(
    $shopID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderByShopId(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        orderID
        bidID
        orderList {
          productID
          serviceID
          category
          name
          codeName
          noOfPieces
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          totalPrice
          priceRange
          quantity
          __typename
        }
        userID
        status
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        paymentMethod
        orderCancelOrDeclineReason
        total
        variableTotal
        discount
        tax
        noOfPieces
        paidAmount
        onHoldPaymentIntentID
        onHoldPaymentAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
          distanceRangeForFree
          distanceRange
          __typename
        }
        itemizedAt
        payments {
          nextToken
          startedAt
          __typename
        }
        bid {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        forwardRequests {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        customerReview {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrderByUserId = /* GraphQL */ `
  query GetOrderByUserId(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderByUserId(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        orderID
        bidID
        orderList {
          productID
          serviceID
          category
          name
          codeName
          noOfPieces
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          totalPrice
          priceRange
          quantity
          __typename
        }
        userID
        status
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        paymentMethod
        orderCancelOrDeclineReason
        total
        variableTotal
        discount
        tax
        noOfPieces
        paidAmount
        onHoldPaymentIntentID
        onHoldPaymentAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
          distanceRangeForFree
          distanceRange
          __typename
        }
        itemizedAt
        payments {
          nextToken
          startedAt
          __typename
        }
        bid {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        forwardRequests {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        customerReview {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrderByBidID = /* GraphQL */ `
  query GetOrderByBidID(
    $bidID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderByBidID(
      bidID: $bidID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        orderID
        bidID
        orderList {
          productID
          serviceID
          category
          name
          codeName
          noOfPieces
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          totalPrice
          priceRange
          quantity
          __typename
        }
        userID
        status
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        paymentMethod
        orderCancelOrDeclineReason
        total
        variableTotal
        discount
        tax
        noOfPieces
        paidAmount
        onHoldPaymentIntentID
        onHoldPaymentAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
          distanceRangeForFree
          distanceRange
          __typename
        }
        itemizedAt
        payments {
          nextToken
          startedAt
          __typename
        }
        bid {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        forwardRequests {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        customerReview {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrderByPaymentIntentID = /* GraphQL */ `
  query GetOrderByPaymentIntentID(
    $onHoldPaymentIntentID: String
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderByPaymentIntentID(
      onHoldPaymentIntentID: $onHoldPaymentIntentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        orderID
        bidID
        orderList {
          productID
          serviceID
          category
          name
          codeName
          noOfPieces
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          totalPrice
          priceRange
          quantity
          __typename
        }
        userID
        status
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        paymentMethod
        orderCancelOrDeclineReason
        total
        variableTotal
        discount
        tax
        noOfPieces
        paidAmount
        onHoldPaymentIntentID
        onHoldPaymentAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
          distanceRangeForFree
          distanceRange
          __typename
        }
        itemizedAt
        payments {
          nextToken
          startedAt
          __typename
        }
        bid {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        forwardRequests {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        customerReview {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrderForwardRequestByShop = /* GraphQL */ `
  query GetOrderForwardRequestByShop(
    $requestShopID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderForwardRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderForwardRequestByShop(
      requestShopID: $requestShopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderID
        forwardReason
        requestShopID
        acceptedShopID
        createdAt
        createdBy
        status
        forwardShops {
          nextToken
          startedAt
          __typename
        }
        order {
          id
          shopID
          orderID
          bidID
          userID
          status
          createdAt
          updatedAt
          updatedBy
          paymentStatus
          paymentMethod
          orderCancelOrDeclineReason
          total
          variableTotal
          discount
          tax
          noOfPieces
          paidAmount
          onHoldPaymentIntentID
          onHoldPaymentAmount
          coupons
          collectionAndDeliveryFee
          itemizedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        requestShop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        acceptedShop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrderForwardRequestByOrder = /* GraphQL */ `
  query GetOrderForwardRequestByOrder(
    $orderID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderForwardRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderForwardRequestByOrder(
      orderID: $orderID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderID
        forwardReason
        requestShopID
        acceptedShopID
        createdAt
        createdBy
        status
        forwardShops {
          nextToken
          startedAt
          __typename
        }
        order {
          id
          shopID
          orderID
          bidID
          userID
          status
          createdAt
          updatedAt
          updatedBy
          paymentStatus
          paymentMethod
          orderCancelOrDeclineReason
          total
          variableTotal
          discount
          tax
          noOfPieces
          paidAmount
          onHoldPaymentIntentID
          onHoldPaymentAmount
          coupons
          collectionAndDeliveryFee
          itemizedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        requestShop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        acceptedShop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrderForwardShopByShop = /* GraphQL */ `
  query GetOrderForwardShopByShop(
    $shopID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderForwardShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderForwardShopByShop(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        forwardRequestID
        status
        forwaredBy
        createdAt
        createdBy
        forwardRequest {
          id
          orderID
          forwardReason
          requestShopID
          acceptedShopID
          createdAt
          createdBy
          status
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrderForwardShopByForwardRequest = /* GraphQL */ `
  query GetOrderForwardShopByForwardRequest(
    $forwardRequestID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderForwardShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderForwardShopByForwardRequest(
      forwardRequestID: $forwardRequestID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        forwardRequestID
        status
        forwaredBy
        createdAt
        createdBy
        forwardRequest {
          id
          orderID
          forwardReason
          requestShopID
          acceptedShopID
          createdAt
          createdBy
          status
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMessageByOrderID = /* GraphQL */ `
  query GetMessageByOrderID(
    $orderID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessageByOrderID(
      orderID: $orderID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderID
        sender
        shopID
        message
        messageIntent
        photo {
          name
          photoURL
          thumbURL
          MIMETypes
          __typename
        }
        status
        notificationID
        readAt
        createdAt
        order {
          id
          shopID
          orderID
          bidID
          userID
          status
          createdAt
          updatedAt
          updatedBy
          paymentStatus
          paymentMethod
          orderCancelOrDeclineReason
          total
          variableTotal
          discount
          tax
          noOfPieces
          paidAmount
          onHoldPaymentIntentID
          onHoldPaymentAmount
          coupons
          collectionAndDeliveryFee
          itemizedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        notification {
          id
          userID
          shopID
          orderID
          messageID
          isRead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        senderDetails {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMessageNotificationByUser = /* GraphQL */ `
  query GetMessageNotificationByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessageNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessageNotificationByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        shopID
        orderID
        messageID
        isRead
        createdAt
        updatedAt
        message {
          id
          orderID
          sender
          shopID
          message
          messageIntent
          status
          notificationID
          readAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMessageNotificationByShop = /* GraphQL */ `
  query GetMessageNotificationByShop(
    $shopID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessageNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessageNotificationByShop(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        shopID
        orderID
        messageID
        isRead
        createdAt
        updatedAt
        message {
          id
          orderID
          sender
          shopID
          message
          messageIntent
          status
          notificationID
          readAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getNotificationByUser = /* GraphQL */ `
  query GetNotificationByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNotificationByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        shopID
        message
        isRead
        type {
          variant
          modelID
          model
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getNotificationByShop = /* GraphQL */ `
  query GetNotificationByShop(
    $shopID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNotificationByShop(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        shopID
        message
        isRead
        type {
          variant
          modelID
          model
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPaymentByOrderId = /* GraphQL */ `
  query GetPaymentByOrderId(
    $orderID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPaymentByOrderId(
      orderID: $orderID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderID
        shopID
        userID
        stripePaymentIntentID
        amount
        createdAt
        createdBy
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPaymentByShopId = /* GraphQL */ `
  query GetPaymentByShopId(
    $shopID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPaymentByShopId(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderID
        shopID
        userID
        stripePaymentIntentID
        amount
        createdAt
        createdBy
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPaymentByUserId = /* GraphQL */ `
  query GetPaymentByUserId(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPaymentByUserId(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderID
        shopID
        userID
        stripePaymentIntentID
        amount
        createdAt
        createdBy
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPaymentByPaymentIntentID = /* GraphQL */ `
  query GetPaymentByPaymentIntentID(
    $stripePaymentIntentID: String
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPaymentByPaymentIntentID(
      stripePaymentIntentID: $stripePaymentIntentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderID
        shopID
        userID
        stripePaymentIntentID
        amount
        createdAt
        createdBy
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAuctionByUserID = /* GraphQL */ `
  query GetAuctionByUserID(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAuctionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAuctionByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        auctionID
        userID
        createdAt
        startDateTime
        endDateTime
        collection {
          date
          time
          type
          address
          instructions
          __typename
        }
        delivery {
          date
          time
          type
          address
          instructions
          __typename
        }
        postcode
        isClosed
        total
        items {
          nextToken
          startedAt
          __typename
        }
        bids {
          nextToken
          startedAt
          __typename
        }
        shops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        user {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAuctionItemByAuction = /* GraphQL */ `
  query GetAuctionItemByAuction(
    $auctionID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAuctionItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAuctionItemByAuction(
      auctionID: $auctionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        auctionID
        serviceID
        name
        description
        quantity
        image
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          serviceID
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBidByShopId = /* GraphQL */ `
  query GetBidByShopId(
    $shopID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelBidFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getBidByShopId(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        auctionID
        shopID
        createdBy
        createdAt
        totalPrice
        shippingCharges
        items {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        auction {
          id
          auctionID
          userID
          createdAt
          startDateTime
          endDateTime
          postcode
          isClosed
          total
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBidByAuctionId = /* GraphQL */ `
  query GetBidByAuctionId(
    $auctionID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelBidFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getBidByAuctionId(
      auctionID: $auctionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        auctionID
        shopID
        createdBy
        createdAt
        totalPrice
        shippingCharges
        items {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        auction {
          id
          auctionID
          userID
          createdAt
          startDateTime
          endDateTime
          postcode
          isClosed
          total
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBidItemByBid = /* GraphQL */ `
  query GetBidItemByBid(
    $bidID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelBidItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getBidItemByBid(
      bidID: $bidID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bidID
        auctionItemID
        price
        bid {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAuctionByShop = /* GraphQL */ `
  query GetAuctionByShop(
    $shopID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAuctionShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAuctionByShop(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        auctionID
        auction {
          id
          auctionID
          userID
          createdAt
          startDateTime
          endDateTime
          postcode
          isClosed
          total
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLastWhatsappMessageByOrder = /* GraphQL */ `
  query GetLastWhatsappMessageByOrder(
    $orderID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLastWhatsappMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLastWhatsappMessageByOrder(
      orderID: $orderID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderID
        message
        order {
          id
          shopID
          orderID
          bidID
          userID
          status
          createdAt
          updatedAt
          updatedBy
          paymentStatus
          paymentMethod
          orderCancelOrDeclineReason
          total
          variableTotal
          discount
          tax
          noOfPieces
          paidAmount
          onHoldPaymentIntentID
          onHoldPaymentAmount
          coupons
          collectionAndDeliveryFee
          itemizedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFavouriteShopByUser = /* GraphQL */ `
  query GetFavouriteShopByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFavouriteShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFavouriteShopByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        userID
        isFavourite
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        user {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getService = /* GraphQL */ `
  query GetService($id: ID!) {
    getService(id: $id) {
      id
      serviceID
      name
      description
      orderIndex
      type
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      items {
        items {
          id
          serviceID
          name
          codeName
          description
          image
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          codeName
          noOfPieces
          description
          unit
          price
          priceRange
          image
          tags
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      photoGallaryImages {
        items {
          id
          serviceID
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const listServices = /* GraphQL */ `
  query ListServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        serviceID
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        photoGallaryImages {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncServices = /* GraphQL */ `
  query SyncServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        serviceID
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        photoGallaryImages {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      serviceID
      name
      codeName
      description
      image
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      service {
        id
        serviceID
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        photoGallaryImages {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          codeName
          noOfPieces
          description
          unit
          price
          priceRange
          image
          tags
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        serviceID
        name
        codeName
        description
        image
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          serviceID
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getItemByService = /* GraphQL */ `
  query GetItemByService(
    $serviceID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getItemByService(
      serviceID: $serviceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serviceID
        name
        codeName
        description
        image
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          serviceID
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncItems = /* GraphQL */ `
  query SyncItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        serviceID
        name
        codeName
        description
        image
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          serviceID
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getShop = /* GraphQL */ `
  query GetShop($id: ID!) {
    getShop(id: $id) {
      id
      name
      googlePlaceId
      contactName
      address
      postcode
      town
      country
      email
      phoneNumber
      location {
        lat
        lng
        __typename
      }
      workHrs {
        closeDay
        openDay
        closeTime
        openTime
        __typename
      }
      collectionHrs {
        closeDay
        openDay
        closeTime
        openTime
        __typename
      }
      deliveryHrs {
        closeDay
        openDay
        closeTime
        openTime
        __typename
      }
      image
      minPriceForFree
      distanceRangeForFree
      distanceRange
      standardDeliveryFee
      deliveryLocations
      AsapDeliveryFee
      language
      range
      shopID
      about
      documents {
        name
        documentType
        documentURL
        description
        MIMETypes
        status
        __typename
      }
      photos {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
        serviceID
        __typename
      }
      primaryPhoto {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
        serviceID
        __typename
      }
      videos {
        name
        videoURL
        autoPlay
        thumbURL
        description
        MIMETypes
        status
        __typename
      }
      variant
      status
      isLive
      consentLetterCount
      consentLetterLastDate
      enableAsapDelivery
      collectionInstructions
      deliveryInstructions
      websiteLink
      paymentMethods
      ocrTemplateName
      totalRatingID
      createdBy
      updatedBy
      createdAt
      auctions {
        items {
          id
          shopID
          auctionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          codeName
          noOfPieces
          description
          unit
          price
          priceRange
          image
          tags
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      users {
        items {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      reviews {
        items {
          id
          shopID
          userID
          orderID
          replyID
          title
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      coupons {
        items {
          id
          shopID
          productID
          title
          description
          startDateTime
          endDateTime
          maxUsesPerUser
          discountType
          discountAmount
          discountUnit
          conditionAmount
          conditionUnit
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      rating {
        id
        rating
        ratingsCount
        ratingsSum
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listShops = /* GraphQL */ `
  query ListShops(
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShops(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getShopByShopID = /* GraphQL */ `
  query GetShopByShopID(
    $shopID: String
    $sortDirection: ModelSortDirection
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getShopByShopID(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getShopByPhoneNumber = /* GraphQL */ `
  query GetShopByPhoneNumber(
    $phoneNumber: String
    $sortDirection: ModelSortDirection
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getShopByPhoneNumber(
      phoneNumber: $phoneNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getShopCreatedBy = /* GraphQL */ `
  query GetShopCreatedBy(
    $createdBy: String
    $sortDirection: ModelSortDirection
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getShopCreatedBy(
      createdBy: $createdBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getShopByStatusCreatedDate = /* GraphQL */ `
  query GetShopByStatusCreatedDate(
    $status: ShopStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getShopByStatusCreatedDate(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncShops = /* GraphQL */ `
  query SyncShops(
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncShops(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchShops = /* GraphQL */ `
  query SearchShops(
    $searchString: String
    $status: ShopStatus
    $createdBy: String
    $createdDateRange: DateRange
    $nextToken: String
  ) {
    searchShops(
      searchString: $searchString
      status: $status
      createdBy: $createdBy
      createdDateRange: $createdDateRange
      nextToken: $nextToken
    ) {
      items {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const customSearchShops = /* GraphQL */ `
  query CustomSearchShops(
    $searchString: String
    $status: ShopStatus
    $createdBy: String
    $createdDateRange: DateRange
    $nextToken: String
  ) {
    customSearchShops(
      searchString: $searchString
      status: $status
      createdBy: $createdBy
      createdDateRange: $createdDateRange
      nextToken: $nextToken
    ) {
      items {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        shopID
        variant
        websiteLink
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShopByPostalCode = /* GraphQL */ `
  query GetShopByPostalCode($postalCode: String!, $range: String) {
    getShopByPostalCode(postalCode: $postalCode, range: $range) {
      id
      name
      googlePlaceId
      contactName
      address
      postcode
      town
      country
      email
      phoneNumber
      location {
        lat
        lng
        __typename
      }
      workHrs {
        closeDay
        openDay
        closeTime
        openTime
        __typename
      }
      collectionHrs {
        closeDay
        openDay
        closeTime
        openTime
        __typename
      }
      deliveryHrs {
        closeDay
        openDay
        closeTime
        openTime
        __typename
      }
      image
      minPriceForFree
      distanceRangeForFree
      distanceRange
      standardDeliveryFee
      deliveryLocations
      AsapDeliveryFee
      language
      range
      shopID
      about
      documents {
        name
        documentType
        documentURL
        description
        MIMETypes
        status
        __typename
      }
      photos {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
        serviceID
        __typename
      }
      primaryPhoto {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
        serviceID
        __typename
      }
      videos {
        name
        videoURL
        autoPlay
        thumbURL
        description
        MIMETypes
        status
        __typename
      }
      variant
      status
      isLive
      consentLetterCount
      consentLetterLastDate
      enableAsapDelivery
      collectionInstructions
      deliveryInstructions
      websiteLink
      paymentMethods
      ocrTemplateName
      totalRatingID
      createdBy
      updatedBy
      createdAt
      auctions {
        items {
          id
          shopID
          auctionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          codeName
          noOfPieces
          description
          unit
          price
          priceRange
          image
          tags
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      users {
        items {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      reviews {
        items {
          id
          shopID
          userID
          orderID
          replyID
          title
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      coupons {
        items {
          id
          shopID
          productID
          title
          description
          startDateTime
          endDateTime
          maxUsesPerUser
          discountType
          discountAmount
          discountUnit
          conditionAmount
          conditionUnit
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      rating {
        id
        rating
        ratingsCount
        ratingsSum
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const getLatLngByPostCode = /* GraphQL */ `
  query GetLatLngByPostCode($postalCode: String!) {
    getLatLngByPostCode(postalCode: $postalCode) {
      lat
      lng
      __typename
    }
  }
`;
export const getCurrentOpenAds = /* GraphQL */ `
  query GetCurrentOpenAds($page: Pages!) {
    getCurrentOpenAds(page: $page) {
      id
      shopID
      startDate
      endDate
      scheduledDays
      isClosed
      template
      content
      amount
      page
      priority
      orderedDate
      type
      redirectLink
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getRandomBanner = /* GraphQL */ `
  query GetRandomBanner {
    getRandomBanner {
      id
      photoURL
      mobilePhotoURL
      image
      mobileImage
      MIMETypes
      title
      color
      enabled
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      shopID
      serviceID
      itemID
      category
      name
      codeName
      noOfPieces
      description
      unit
      price
      priceRange
      image
      tags
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      service {
        id
        serviceID
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        photoGallaryImages {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      item {
        id
        serviceID
        name
        codeName
        description
        image
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          serviceID
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      coupons {
        items {
          id
          shopID
          productID
          title
          description
          startDateTime
          endDateTime
          maxUsesPerUser
          discountType
          discountAmount
          discountUnit
          conditionAmount
          conditionUnit
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shopID
        serviceID
        itemID
        category
        name
        codeName
        noOfPieces
        description
        unit
        price
        priceRange
        image
        tags
        editors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          serviceID
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        item {
          id
          serviceID
          name
          codeName
          description
          image
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getProductByShop = /* GraphQL */ `
  query GetProductByShop(
    $shopID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProductByShop(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        serviceID
        itemID
        category
        name
        codeName
        noOfPieces
        description
        unit
        price
        priceRange
        image
        tags
        editors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          serviceID
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        item {
          id
          serviceID
          name
          codeName
          description
          image
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncProducts = /* GraphQL */ `
  query SyncProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shopID
        serviceID
        itemID
        category
        name
        codeName
        noOfPieces
        description
        unit
        price
        priceRange
        image
        tags
        editors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          serviceID
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        item {
          id
          serviceID
          name
          codeName
          description
          image
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      groups
      picture
      username
      userId
      firstName
      lastName
      title
      defaultAddress
      email
      address
      postalCode
      phoneNumber
      createdAt
      shopID
      stripeID
      status
      language
      dateOfBirth
      documents {
        name
        documentType
        documentURL
        description
        MIMETypes
        status
        __typename
      }
      updatedAt
      favouriteShops {
        items {
          id
          shopID
          userID
          isFavourite
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserByShop = /* GraphQL */ `
  query GetUserByShop(
    $shopID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByShop(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const byUserEmail = /* GraphQL */ `
  query ByUserEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byUserEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserLoggedInStatus = /* GraphQL */ `
  query GetUserLoggedInStatus($id: ID!) {
    getUserLoggedInStatus(id: $id) {
      id
      userID
      lastSeenAt
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserLoggedInStatuss = /* GraphQL */ `
  query ListUserLoggedInStatuss(
    $filter: ModelUserLoggedInStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserLoggedInStatuss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        lastSeenAt
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserLoggedInStatusByUser = /* GraphQL */ `
  query GetUserLoggedInStatusByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserLoggedInStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserLoggedInStatusByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        lastSeenAt
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserLoggedInStatuses = /* GraphQL */ `
  query SyncUserLoggedInStatuses(
    $filter: ModelUserLoggedInStatusFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserLoggedInStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        lastSeenAt
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAddress = /* GraphQL */ `
  query GetAddress($id: ID!) {
    getAddress(id: $id) {
      id
      userID
      postcode
      buildingName
      buildingNumber
      line1
      line2
      town
      county
      district
      country
      latitude
      longitude
      phoneNumber
      contactName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const listAddresss = /* GraphQL */ `
  query ListAddresss(
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddresss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        postcode
        buildingName
        buildingNumber
        line1
        line2
        town
        county
        district
        country
        latitude
        longitude
        phoneNumber
        contactName
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAddresses = /* GraphQL */ `
  query SyncAddresses(
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAddresses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        postcode
        buildingName
        buildingNumber
        line1
        line2
        town
        county
        district
        country
        latitude
        longitude
        phoneNumber
        contactName
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCustomerReview = /* GraphQL */ `
  query GetCustomerReview($id: ID!) {
    getCustomerReview(id: $id) {
      id
      shopID
      userID
      orderID
      replyID
      title
      ratings {
        title
        rating
        __typename
      }
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      reply {
        id
        reviewID
        description
        createdAt
        createdBy
        updatedAt
        _version
        _deleted
        _lastChangedAt
        review {
          id
          shopID
          userID
          orderID
          replyID
          title
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listCustomerReviews = /* GraphQL */ `
  query ListCustomerReviews(
    $filter: ModelCustomerReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shopID
        userID
        orderID
        replyID
        title
        ratings {
          title
          rating
          __typename
        }
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        reply {
          id
          reviewID
          description
          createdAt
          createdBy
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCustomerReviewByShop = /* GraphQL */ `
  query GetCustomerReviewByShop(
    $shopID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCustomerReviewByShop(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        userID
        orderID
        replyID
        title
        ratings {
          title
          rating
          __typename
        }
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        reply {
          id
          reviewID
          description
          createdAt
          createdBy
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCustomerReviews = /* GraphQL */ `
  query SyncCustomerReviews(
    $filter: ModelCustomerReviewFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomerReviews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shopID
        userID
        orderID
        replyID
        title
        ratings {
          title
          rating
          __typename
        }
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        reply {
          id
          reviewID
          description
          createdAt
          createdBy
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getReviewReply = /* GraphQL */ `
  query GetReviewReply($id: ID!) {
    getReviewReply(id: $id) {
      id
      reviewID
      description
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
      review {
        id
        shopID
        userID
        orderID
        replyID
        title
        ratings {
          title
          rating
          __typename
        }
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        reply {
          id
          reviewID
          description
          createdAt
          createdBy
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listReviewReplys = /* GraphQL */ `
  query ListReviewReplys(
    $filter: ModelReviewReplyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviewReplys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reviewID
        description
        createdAt
        createdBy
        updatedAt
        _version
        _deleted
        _lastChangedAt
        review {
          id
          shopID
          userID
          orderID
          replyID
          title
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncReviewReplies = /* GraphQL */ `
  query SyncReviewReplies(
    $filter: ModelReviewReplyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReviewReplies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        reviewID
        description
        createdAt
        createdBy
        updatedAt
        _version
        _deleted
        _lastChangedAt
        review {
          id
          shopID
          userID
          orderID
          replyID
          title
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAd = /* GraphQL */ `
  query GetAd($id: ID!) {
    getAd(id: $id) {
      id
      shopID
      startDate
      endDate
      scheduledDays
      isClosed
      template
      content
      amount
      page
      priority
      orderedDate
      type
      redirectLink
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
          __typename
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
          __typename
        }
        image
        minPriceForFree
        distanceRangeForFree
        distanceRange
        standardDeliveryFee
        deliveryLocations
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
          __typename
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
          __typename
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
          __typename
        }
        variant
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        paymentMethods
        ocrTemplateName
        totalRatingID
        createdBy
        updatedBy
        createdAt
        auctions {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
          __typename
        }
        users {
          nextToken
          startedAt
          __typename
        }
        reviews {
          nextToken
          startedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        rating {
          id
          rating
          ratingsCount
          ratingsSum
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listAds = /* GraphQL */ `
  query ListAds($filter: ModelAdFilterInput, $limit: Int, $nextToken: String) {
    listAds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shopID
        startDate
        endDate
        scheduledDays
        isClosed
        template
        content
        amount
        page
        priority
        orderedDate
        type
        redirectLink
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdByPage = /* GraphQL */ `
  query GetAdByPage(
    $page: Pages
    $sortDirection: ModelSortDirection
    $filter: ModelAdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAdByPage(
      page: $page
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        startDate
        endDate
        scheduledDays
        isClosed
        template
        content
        amount
        page
        priority
        orderedDate
        type
        redirectLink
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAds = /* GraphQL */ `
  query SyncAds(
    $filter: ModelAdFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shopID
        startDate
        endDate
        scheduledDays
        isClosed
        template
        content
        amount
        page
        priority
        orderedDate
        type
        redirectLink
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getGallaryPhoto = /* GraphQL */ `
  query GetGallaryPhoto($id: ID!) {
    getGallaryPhoto(id: $id) {
      id
      serviceID
      picture {
        name
        photoURL
        thumbURL
        MIMETypes
        __typename
      }
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
  }
`;
export const listGallaryPhotos = /* GraphQL */ `
  query ListGallaryPhotos(
    $filter: ModelGallaryPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGallaryPhotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        serviceID
        picture {
          name
          photoURL
          thumbURL
          MIMETypes
          __typename
        }
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getGallaryPhotoByServiceID = /* GraphQL */ `
  query GetGallaryPhotoByServiceID(
    $serviceID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGallaryPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGallaryPhotoByServiceID(
      serviceID: $serviceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serviceID
        picture {
          name
          photoURL
          thumbURL
          MIMETypes
          __typename
        }
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncGallaryPhotos = /* GraphQL */ `
  query SyncGallaryPhotos(
    $filter: ModelGallaryPhotoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGallaryPhotos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        serviceID
        picture {
          name
          photoURL
          thumbURL
          MIMETypes
          __typename
        }
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRedirect = /* GraphQL */ `
  query GetRedirect($id: ID!) {
    getRedirect(id: $id) {
      id
      shopID
      userID
      event
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
  }
`;
export const listRedirects = /* GraphQL */ `
  query ListRedirects(
    $filter: ModelRedirectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRedirects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shopID
        userID
        event
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRedirectByShopID = /* GraphQL */ `
  query GetRedirectByShopID(
    $shopID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRedirectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRedirectByShopID(
      shopID: $shopID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        userID
        event
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRedirects = /* GraphQL */ `
  query SyncRedirects(
    $filter: ModelRedirectFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRedirects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shopID
        userID
        event
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSearchBanner = /* GraphQL */ `
  query GetSearchBanner($id: ID!) {
    getSearchBanner(id: $id) {
      id
      photoURL
      mobilePhotoURL
      image
      mobileImage
      MIMETypes
      title
      color
      enabled
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
  }
`;
export const listSearchBanners = /* GraphQL */ `
  query ListSearchBanners(
    $filter: ModelSearchBannerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSearchBanners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        photoURL
        mobilePhotoURL
        image
        mobileImage
        MIMETypes
        title
        color
        enabled
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSearchBanners = /* GraphQL */ `
  query SyncSearchBanners(
    $filter: ModelSearchBannerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSearchBanners(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        photoURL
        mobilePhotoURL
        image
        mobileImage
        MIMETypes
        title
        color
        enabled
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCoupon = /* GraphQL */ `
  query GetCoupon($id: ID!) {
    getCoupon(id: $id) {
      id
      shopID
      productID
      title
      description
      startDateTime
      endDateTime
      maxUsesPerUser
      discountType
      discountAmount
      discountUnit
      conditionAmount
      conditionUnit
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      product {
        id
        shopID
        serviceID
        itemID
        category
        name
        codeName
        noOfPieces
        description
        unit
        price
        priceRange
        image
        tags
        editors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          serviceID
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        item {
          id
          serviceID
          name
          codeName
          description
          image
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRangeForFree
          distanceRange
          standardDeliveryFee
          deliveryLocations
          AsapDeliveryFee
          language
          range
          shopID
          about
          variant
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          paymentMethods
          ocrTemplateName
          totalRatingID
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          __typename
        }
        coupons {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listCoupons = /* GraphQL */ `
  query ListCoupons(
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shopID
        productID
        title
        description
        startDateTime
        endDateTime
        maxUsesPerUser
        discountType
        discountAmount
        discountUnit
        conditionAmount
        conditionUnit
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        product {
          id
          shopID
          serviceID
          itemID
          category
          name
          codeName
          noOfPieces
          description
          unit
          price
          priceRange
          image
          tags
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCouponsByShop = /* GraphQL */ `
  query GetCouponsByShop(
    $shopID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCouponsByShop(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        productID
        title
        description
        startDateTime
        endDateTime
        maxUsesPerUser
        discountType
        discountAmount
        discountUnit
        conditionAmount
        conditionUnit
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        product {
          id
          shopID
          serviceID
          itemID
          category
          name
          codeName
          noOfPieces
          description
          unit
          price
          priceRange
          image
          tags
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCoupons = /* GraphQL */ `
  query SyncCoupons(
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCoupons(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shopID
        productID
        title
        description
        startDateTime
        endDateTime
        maxUsesPerUser
        discountType
        discountAmount
        discountUnit
        conditionAmount
        conditionUnit
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        product {
          id
          shopID
          serviceID
          itemID
          category
          name
          codeName
          noOfPieces
          description
          unit
          price
          priceRange
          image
          tags
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTotalRatings = /* GraphQL */ `
  query SyncTotalRatings(
    $filter: ModelTotalRatingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTotalRatings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        rating
        ratingsCount
        ratingsSum
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
