import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import WrapWithLayout from './WrapWithLayout';
import { useHistory } from 'react-router-dom';
import { useAmplifyAuth } from 'context';

const WrapWithProtectedLayout = ({
  component: Comp,
  layout: Layout,
  rest: props
}) => {
  const [loading, setLoading] = useState(true);
  let {
    state: { user }
  } = useAmplifyAuth();
  let history = useHistory();

  useEffect(() => {
    let timerId;
    if (user && user.username) {
      setLoading(false);
    } else {
      timerId = setTimeout(
        () =>
          history.replace(
            `/login?redirect=${props.location.pathname}${props.location.search}`
          ),
        500
      );
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [user]);

  return loading ? (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ height: '100%' }}>
      <CircularProgress />
    </Grid>
  ) : (
    <WrapWithLayout {...{ Comp, Layout, props }} />
  );
};

export default WrapWithProtectedLayout;
