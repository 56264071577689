import React, { useState } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Badge,
  IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import {
  getFormattedName,
  getFormattedPhoneNumber
} from 'common/utilFunctions';
import { useMessages } from 'context/messages/messagesContext';
import { useAmplifyAuth } from 'context';
import MessageDialog from '../../../Messages/MessageDialog';
import defaultUserImage from 'assets/png/default-image-user.png';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Image } from 'components/organisms';
import PropTypes from 'prop-types';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  avatar: { marginRight: '0.5rem' },
  customerImage: {
    height: 40,
    width: 40,
    borderRadius: '50%'
  },
  inner: {
    [theme.breakpoints.down('xs')]: {
      '& thead': { display: 'none' },
      '& td': { display: 'block', padding: '0.5rem' },
      '& tr': {
        border: '1px solid #0000003b',
        borderRadius: '0.25rem'
      },
      '& tbody': {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem'
      }
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 1050
    }
  },
  hideForMobile: {
    [theme.breakpoints.down('xs')]: {
      display: 'none !important'
    }
  },
  hideForDesktop: {
    [theme.breakpoints.up('sm')]: {
      display: 'none !important'
    }
  }
}));

const ItemizedOrderTable = ({ order }) => {
  const classes = useStyles();
  const { messageNotifications } = useMessages();
  const [showMessageDialog, setShowMessageDialog] = useState(false);
  const {
    state: { isLaundryStaff }
  } = useAmplifyAuth();
  const noOfNotifications =
    messageNotifications?.filter((_item) => _item?.orderID === order.id)
      .length || 0;

  const handleOpenMessageDialog = () => setShowMessageDialog(true);

  return (
    <>
      <PerfectScrollbar>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>NAME & CONTACT</TableCell>
                <TableCell align="center">ORDER NO</TableCell>
                <TableCell align="center" className={classes.hideForMobile}>
                  DATE OF ORDER
                </TableCell>
                <TableCell align="center" className={classes.hideForMobile}>
                  EST. DELIVERY
                </TableCell>
                {isLaundryStaff ? null : <TableCell align="center"></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={classes.tableRow} hover key={order.id}>
                <TableCell>
                  <Grid container alignItems="center">
                    <Grid
                      item
                      xs={8}
                      sm={12}
                      container
                      alignItems="center"
                      style={{ minWidth: '8.5rem' }}>
                      <Badge
                        className={classes.avatar}
                        badgeContent={
                          noOfNotifications > 0 ? (
                            <IconButton
                              size="small"
                              onClick={handleOpenMessageDialog}
                              style={{ backgroundColor: '#C2E7FF' }}>
                              <MailOutlineRoundedIcon
                                style={{ fontSize: '14px' }}
                              />
                            </IconButton>
                          ) : null
                        }>
                        <Image
                          docKey={order.userDetail?.picture}
                          onError={(e) => {
                            e.target.src = defaultUserImage;
                          }}
                          className={classes.customerImage}
                        />
                      </Badge>
                      <Typography>
                        {getFormattedName(order?.userDetail)} <br />
                        {getFormattedPhoneNumber(
                          order?.userDetail?.phoneNumber
                        )}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={12}
                      container
                      justify="flex-end"
                      className={classes.hideForDesktop}>
                      Order: #{order.orderID}
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="center" className={classes.hideForMobile}>
                  {order.orderID}
                </TableCell>
                <TableCell align="center" className={classes.hideForMobile}>
                  {moment(order.createdAt).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell align="center" className={classes.hideForMobile}>
                  {order?.delivery?.date
                    ? moment(order.delivery.date).format('DD/MM/YYYY')
                    : 'N/A'}
                </TableCell>
                {isLaundryStaff ? null : (
                  <TableCell align="center">
                    <Badge
                      variant="standard"
                      color="primary"
                      badgeContent={noOfNotifications}>
                      <Button
                        onClick={handleOpenMessageDialog}
                        endIcon={<MailOutlineRoundedIcon fontSize="small" />}
                        variant="contained"
                        fullWidth>
                        Message
                      </Button>
                    </Badge>
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </PerfectScrollbar>
      <MessageDialog
        open={showMessageDialog}
        handleModalClose={() => setShowMessageDialog(false)}
        order={order}
      />
    </>
  );
};

ItemizedOrderTable.propTypes = {
  className: PropTypes.string
};

export default ItemizedOrderTable;
