import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { useServices } from 'views/Services/serviceContext';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacing(1)}px 5px`,
    width: '93%'
  }
}));

const SearchServices = (props) => {
  const classes = useStyles();
  const { services, dispatch } = useServices();

  const { setSelectedService, setSelectedServiceId } = props;

  useEffect(() => {
    dispatch({ type: 'getService' });
  }, []);

  const {
    onChangeHandler = (event, val) => {
      setSelectedServiceId(val.id);
    },
    value
  } = props;

  return (
    <Autocomplete
      id="select-service"
      disableClearable
      className={classes.root}
      value={value}
      options={services}
      getOptionSelected={(option, value) => option?.id === value?.id}
      onChange={(event, val) => onChangeHandler(event, val)}
      getOptionLabel={(option) => {
        return `${option?.name}`;
      }}
      onInputChange={(event, newInputValue) => {
        setSelectedService(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          size="small"
          label="Select Service"
          variant="outlined"
        />
      )}
    />
  );
};

export default SearchServices;
