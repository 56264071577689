import React from 'react';
import { useShops } from 'context/shop/shopsContext';
import ShopAdminReviewMatrix from './components/ShopAdminReviewMatrix';
import ShopReviewList from 'components/ShopReview/ShopReviewList';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    padding: 24
  }
}));

const ShopAdminReviews = () => {
  const { selectedShop } = useShops();
  const classes = useStyles();
  const reviews = selectedShop?.reviews.items || [];
  const shopReviews = reviews
    .filter((review) => !review?.orderID)
    .sort((a, b) => b?._lastChangedAt - a?._lastChangedAt);

  return (
    <div className={classes.root}>
      <ShopAdminReviewMatrix />
      <ShopReviewList reviews={reviews} shopReviews={shopReviews} />
    </div>
  );
};

export default ShopAdminReviews;
