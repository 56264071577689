import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAmplifyAuth } from 'context';
import { getStorageUrl } from 'common/utilFunctions';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = (props) => {
  const [picture, setPicture] = useState('');
  const { className, ...rest } = props;
  const {
    state: { user }
  } = useAmplifyAuth();
  const classes = useStyles();

  useEffect(() => {
    let isMounted = true;
    user &&
      getStorageUrl(user.picture).then((picture) =>
        isMounted ? setPicture(picture) : null
      );

    return () => {
      isMounted = false;
    };
  }, [user]);

  return user ? (
    <Grid
      container
      direction="column"
      alignItems="center"
      {...rest}
      className={clsx(classes.root, className)}>
      <Avatar alt={user.firstName} className={classes.avatar} src={picture} />
      <Typography className={classes.name} variant="h4">
        {user.firstName} {user.lastName}
      </Typography>
      <Typography variant="body2"> ({user.username})</Typography>
    </Grid>
  ) : null;
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
