import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { getOrderByUserId } from 'context/order/orderQueries';
import { useLoader } from 'layouts/loaderContext';
import { OrderDetailContent } from 'components/organisms';

function OrderDetails() {
  let { userId, orderId } = useParams();
  const [order, setOrder] = useState({});
  const { setLoading } = useLoader();

  useEffect(() => {
    setLoading(true);
    API.graphql(
      graphqlOperation(getOrderByUserId, {
        userID: userId
      })
    )
      .then(({ data }) => {
        setOrder(
          data?.getOrderByUserId.items.find((el) => el.orderID === orderId)
        );
      })
      .catch((e) => console.log('error', e))
      .finally(() => setLoading(false));
  }, [orderId]);

  return (
    <>
      {/* order details */}
      <OrderDetailContent order={order} showBackButton={true} />
    </>
  );
}

export default OrderDetails;
