import React, { useState, useEffect } from 'react';
import Image from './Image';
import { getStorageUrl } from 'common/utilFunctions';
import noImagePic from 'assets/png/no-image-available.png';

const ShopImage = ({ shop = {}, serviceID = '', ...rest }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    let mounted = true;

    const getMounted = () => {
      return mounted;
    };

    (async () => {
      if (!shop?.id) return;
      let currentImage;
      if (serviceID) {
        currentImage = shop?.photos?.find(
          (photo) =>
            photo?.status === 'approved' && photo?.serviceID === serviceID
        );
      }
      if (!currentImage) {
        if (shop?.primaryPhoto?.photoURL) currentImage = shop.primaryPhoto;
        else {
          currentImage = shop?.photos?.find(
            (photo) => photo?.status === 'approved'
          );
        }
      }

      if (currentImage?.photoURL) {
        if (currentImage?.photoURL?.split('/').at(0) === 'images')
          currentImage.photoURL = await getStorageUrl(currentImage.photoURL);
        if (currentImage?.thumbURL?.split('/').at(0) === 'images')
          currentImage.thumbURL = await getStorageUrl(currentImage.thumbURL);
      } else {
        currentImage = { photoURL: noImagePic, MIMETypes: 'image/png' };
      }
      getMounted() && setSelectedImage(currentImage);
    })();

    return () => {
      mounted = false;
    };
  }, [shop, serviceID]);

  return (
    <Image
      docKey={selectedImage?.thumbURL || selectedImage?.photoURL}
      onError={(e) => {
        e.target.src = selectedImage?.photoURL || noImagePic;
      }}
      mimeType={selectedImage?.MIMETypes}
      loadFromStorage={false}
      {...rest}
    />
  );
};

export default ShopImage;
