import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.black
  },
  text: {
    padding: theme.spacing(3, 3),
    textAlign: 'space-around'
  },
  link: {
    color: theme.palette.white
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root)}>
      <div className={classes.text}>
        <Typography variant="body1" className={classes.link}>
          &copy;{' '}
          <Link component="a" href="/" target="_blank" className={classes.link}>
            Compare Laundry Services
          </Link>
          . 2020
        </Typography>
      </div>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
