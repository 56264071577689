import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  contentText: {
    color: 'black !important'
  },
  contentTitle: {
    color: '#C4C4C4 !important'
  },
  note: {
    color: '#777'
  }
}));

const DocumentDeleteDialog = (props) => {
  const {
    open = false,
    handleCancel = () => {},
    handleConfirm = () => {},
    confirmText = '',
    title = 'Delete',
    note = ''
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="delete-confirm-dialog-title">
      <DialogTitle
        className={classes.contentTitle}
        id="delete-confirm-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.contentText}>
          Are you sure you want to {confirmText} ?
        </DialogContentText>
        {!!note ? (
          <DialogContentText variant="subtitle2" className={classes.note}>
            {note}
          </DialogContentText>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleConfirm} color="primary">
          Yes
        </Button>
        <Button onClick={handleCancel} color="primary">
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentDeleteDialog;
