import React, { useState } from 'react';
import {
  Button,
  Grid,
  Paper,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { SHOP_OWNER_BENEFITS } from '../constants';

const useStyles = makeStyles({
  rootContainer: {},
  subHeading: {
    textAlign: 'center',
    fontSize: '16px'
  },
  launderItAbout: {
    fontSize: '21px',
    lineHeight: '1.8rem',
    padding: '1rem ',
    margin: '1rem 0'
  },
  launderItBenefits: {
    textAlign: 'justify',
    fontSize: '18px',
    lineHeight: '1.8rem'
  },
  launderItBenefitsContainer: {
    margin: '1rem 0.5rem'
  },
  customerHeading: {
    fontWeight: 800,
    color: '#1f6893',
    margin: '1rem 0'
  },
  readMoreBtn: {
    backgroundColor: '#1f6893',
    color: '#fff'
  },
  checkBox: {
    color: '#1f6893'
  },
  subHeadingTextContent: {
    fontSize: '18px',
    fontWeight: 600,
    color: '#1f6893'
  },
  readMoreSubContent: {
    fontSize: '18px',
    lineHeight: '1.8rem',
    margin: '1rem 0'
  },
  readMoreRootContainer: {
    marginBottom: '1rem'
  },
  readMoreContent: {
    fontWeight: 600,
    fontSize: 15,
    lineHeight: '1.5rem'
  },
  listIconContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
});

const ShopOwnerBenefits = () => {
  const classes = useStyles();
  const [showMore, setShowMore] = useState(false);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      container
      item
      xs={12}
      justify="center"
      alignItems="center"
      id="shop-owner-benefits"
      className={classes.rootContainer}>
      <Grid
        container
        item
        justify="space-between"
        spacing={3}
        className={classes.launderItBenefitsContainer}>
        <Grid container item justify="space-between" spacing={3}>
          {!sm ? (
            <Grid
              container
              item
              justify="center"
              alignItems="center"
              md={5}
              xs={12}>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/fR6ypoI3hQE?rel=0"
                title="Shop owners benefits youtube video"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            </Grid>
          ) : null}
          <Grid container item md={6} xs={12}>
            <Typography variant="h4" className={classes.customerHeading}>
              Shop Owners Benefits
            </Typography>
            <Typography className={classes.launderItBenefits}>
              Launder-it is not just for customers - we also provide an
              excellent platform for laundry shop owners to promote their
              businesses and run their operations effectively. By joining
              Launder-It, shops gain access to a wider customer base, allowing
              them to expand their reach and increase their visibility. Our
              platform serves as a powerful marketing tool, connecting shops
              with potential customers in their area. We understand the
              importance of efficiency and convenience in today's fast-paced
              world. That's why we offer a range of cutting-edge features
              designed to streamline your operations and enhance your customer
              experience. Whether you run a small local laundry or manage a
              chain of laundromats, we have something for everyone!
            </Typography>
            {!showMore ? (
              <Grid
                container
                item
                justify="flex-start"
                style={{ marginTop: '1rem' }}>
                <Button
                  variant="contained"
                  className={classes.readMoreBtn}
                  onClick={() => setShowMore((value) => !value)}>
                  Why join us
                </Button>
              </Grid>
            ) : null}
          </Grid>
          {sm ? (
            <Grid
              container
              item
              justify="center"
              alignItems="center"
              md={5}
              xs={12}>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/LNVvF0-KEZc?rel=0"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen></iframe>
            </Grid>
          ) : null}
        </Grid>
      </Grid>

      {showMore ? (
        <Grid container item className={classes.readMoreRootContainer}>
          <Grid container item justify="center" style={{ margin: '1rem 0' }}>
            <Typography variant="h5" className={classes.subHeadingTextContent}>
              Why Join US
            </Typography>
          </Grid>

          <Grid container item xs={12}>
            {SHOP_OWNER_BENEFITS.map((item) => {
              return (
                <Grid
                  container
                  item
                  alignItems="center"
                  key={item.id}
                  style={{ margin: '0.5rem 0' }}>
                  <Grid item xs={1} className={classes.listIconContainer}>
                    <ArrowRightIcon
                      className={classes.checkBox}
                      fontSize="large"
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <Paper style={{ padding: '0.5rem 1rem' }}>
                      <Typography
                        variant="h6"
                        className={classes.readMoreContent}>
                        {item.content}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              );
            })}
            <Grid container item justify="center">
              <Typography className={classes.readMoreSubContent}>
                Moreover, shops can benefit from our pricing comparison feature,
                enabling them to stay competitive in the market while offering
                transparent pricing to customers.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ShopOwnerBenefits;
