import React from 'react';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

const StyledTab = withStyles((theme) => ({
  root: {},
  selected: {
    borderRadius: '10px 10px 0 0',
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    borderTop: `1px solid ${theme.palette.primary.main}`,
    borderRight: `1px solid ${theme.palette.primary.main}`
  }
}))(({ showBadge, badgeContent, ...rest }) =>
  showBadge ? (
    <Badge
      badgeContent={badgeContent}
      color="primary"
      style={{ marginTop: '0.8rem', marginRight: '0.8rem' }}>
      <Tab {...rest} />
    </Badge>
  ) : (
    <Tab {...rest} />
  )
);

export default StyledTab;
