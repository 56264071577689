import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  RadioGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import AddressModal from './AddressModal';
import { CartCard } from 'components/organisms';
import { AuctionFormModal } from 'views/MyAuctions/components';
import { routes } from 'common/constants';
import PaymentOptions from './PaymentOptions';
import { useAmplifyAuth } from 'context';
import { getLatLngByPostCode } from 'graphql/queries';
import { listAddresss, getAddress } from 'graphql/customQueries';
import { getShop } from 'context/shop/shopsQueries';
import { API, graphqlOperation } from 'aws-amplify';
import { useShops } from 'context/shop/shopsContext';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ReactGA from 'react-ga4';
import RenderAddress from './RenderAddress';
import { StyledFormControlLabel } from 'components/molecules';
import '../styles.css';
import { getDistanceBetweenPostcodesInMiles } from 'common/utilFunctions';

const useStyles = makeStyles((theme) => ({
  ordersHeader: {
    width: '100%',
    textAlign: 'left',
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    lineHeight: '0.1em',
    margin: '10px 0'
  },
  heading: {
    minHeight: '4rem',
    '& > h3': {
      fontWeight: 600
    }
  },
  ordersHeaderText: {
    background: '#fff',
    paddingRight: '10px'
  },
  blueBtn: {
    color: theme.palette.blue.main
  },
  blueContainedBtn: {
    backgroundColor: theme.palette.blue.main,
    color: 'white'
  },
  greyText: {
    color: theme.palette.grey.secondary,
    fontSize: '16px',
    marginRight: '3px'
  },
  note: {
    color: theme.palette.warning.dark,
    paddingBottom: '0.25rem'
  },
  radioContainer: {
    width: '100%',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between'
    }
  },
  accordionContainer: {
    marginBottom: '0.5rem'
  },
  accordionRoot: {
    width: '100%'
  },
  accordionTitle: {
    fontWeight: 600,
    fontSize: 18
  },
  accordionContent: {
    margin: 0,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  correctIcon: {
    color: theme.palette.success.main
  },
  errorIcon: {
    color: theme.palette.warning.dark,
    animation: `$blink 1s ${theme.transitions.easing.easeInOut} 0.5s infinite`
  },
  nextBtnContainer: {
    margin: '1.25rem 0 0'
  },
  '@keyframes blink': {
    '0%': { opacity: 1 },
    '12.5%': { opacity: 1 },
    '25%': { opacity: 0.8 },
    '37.5%': { opacity: 0.6 },
    '50%': { opacity: 0.4 },
    '62.5%': { opacity: 0.6 },
    '75%': { opacity: 0.8 },
    '87.5%': { opacity: 1 },
    '100%': { opacity: 1 }
  }
}));

export default function ReviewComp() {
  const classes = useStyles();
  const { auctionID = '', action = '' } = useParams();
  const {
    state: { user }
  } = useAmplifyAuth();
  const [collectionAddress, setCollectionAddress] = useState(null);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [collectionHrs, setCollectionHrs] = React.useState([]);
  const [deliveryHrs, setDeliveryHrs] = React.useState([]);
  const [collectionTime, setCollectionTime] = React.useState(null);
  const [collectionSlot, setCollectionSlot] = React.useState(null);
  const [deliverySlot, setDeliverySlot] = React.useState(null);
  const [deliveryTime, setDeliveryTime] = React.useState(null);
  const [addressDistance, setAddressDistance] = useState({
    collection: 0,
    delivery: 0
  });
  const [addressList, setAddressList] = useState({
    loading: false,
    items: []
  });
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedShop, dispatch } = useShops();
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [show, setShow] = useState(true);
  const [status, setStatus] = useState('INITIAL');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [collectionMethod, setCollectionMethod] = useState('');
  const [deliveryMethod, setDeliveryMethod] = useState('');
  const [workingHoursMenuAnchor, setWorkingHoursMenuAnchor] = useState(null);
  const [currAddressType, setCurrAddressType] = useState('Collection');
  const [isASAP, setIsASAP] = useState(false);
  const [typeOfOrder, setTypeOfOrder] = useState('Schedule');
  const [isDefaultAddressChange, setIsDefaultAddressChange] = useState({
    isChange: false,
    option: ''
  });
  const [isASAPEnabled, setIsASAPEnabled] = useState(false);
  const [standardDeliveryFee, setStandardDeliveryFee] = useState(0);
  const [minPriceForFree, setMinPriceForFree] = useState(0);
  const [asapDeliveryFee, setAsapDeliveryFee] = useState(0);
  const [instructions, setInstructions] = useState({
    collection: null,
    delivery: null
  });
  const [showAddAuctionModal, setShowAddAuctionModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState('orderType');
  const [collectionInstructions, setCollectionInstructions] = useState('');
  const [deliveryInstructions, setDeliveryInstructions] = useState('');

  // stripe
  const [cardDetails, setCardDetails] = useState({
    selectedCardID: '',
    cardName: { value: '', error: false },
    shouldSaveCard: false,
    customerID: ''
  });

  const handleEdit = () => {
    setEdit((prevState) => !prevState);
    setShow(false);
  };

  const changeAddress = (type = 'collection') => {
    setCurrAddressType(type);
    setOpen(true);
    setAddressList({ loading: true, items: [] });
    if (user?.username) {
      API.graphql(
        graphqlOperation(listAddresss, {
          filter: { userID: { eq: user.username } },
          limit: 1000
        })
      ).then(({ data }) => {
        const _addressList = data.listAddresss.items;
        let items = [];
        if (_addressList.length > 0) {
          items = _addressList.filter((a) => !a._deleted);
        }
        setAddressList({ loading: false, items });
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setEdit((prevState) => !prevState);
  };

  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({
      hitType: 'pageview',
      page: `${history.location.pathname}${history.location.search}`,
      title: 'checkout page'
    });
  }, []);

  useEffect(() => {
    if (selectedShop?.id) {
      API.graphql(graphqlOperation(getShop, { id: selectedShop.id })).then(
        ({ data }) => {
          if (data.getShop) {
            const collectHrs = data.getShop.collectionHrs;

            if (action !== 'place-auction' && data.getShop.enableAsapDelivery)
              setIsASAPEnabled(data.getShop.enableAsapDelivery);

            if (data.getShop.minPriceForFree)
              setMinPriceForFree(data.getShop.minPriceForFree);

            if (data.getShop.standardDeliveryFee)
              setStandardDeliveryFee(data.getShop.standardDeliveryFee);

            if (data.getShop.AsapDeliveryFee)
              setAsapDeliveryFee(data.getShop.AsapDeliveryFee);

            setInstructions({
              collection: data.getShop.collectionInstructions,
              delivery: data.getShop.deliveryInstructions
            });

            if (collectHrs.length < 0) {
              setCollectionMethod('Drop');
            }
            setCollectionHrs(collectHrs);
            const delHrs = data.getShop.deliveryHrs;
            if (delHrs.length < 0) {
              setDeliveryMethod('Pickup');
            }
            setDeliveryHrs(delHrs);
          }
        }
      );
    }
  }, [selectedShop]);

  useEffect(() => {
    let mounted = true;
    if (user?.defaultAddress) {
      API.graphql(
        graphqlOperation(getAddress, { id: user.defaultAddress })
      ).then(({ data }) => {
        const _address = data?.getAddress || [];
        mounted && setCollectionAddress(_address);
        mounted && setDeliveryAddress(_address);
      });
    }
    if (!selectedShop?.id) {
      const _cart = localStorage.getItem('cart');
      const parseCartData = JSON.parse(_cart);
      const { item } = parseCartData || {};
      if (item?.items?.length > 0 && item.items.at(0).shopID) {
        dispatch({
          type: 'getSelectedShop',
          payload: { shopId: item.items.at(0).shopID }
        });
      } else {
        history.push('/home');
      }
    }
    return () => {
      mounted = false;
    };
  }, [user]);

  useEffect(() => {
    if (status === 'SUCCESS') {
      enqueueSnackbar('Order placed successfully', {
        variant: 'success',
        autoHideDuration: 3000
      });
      history.replace(routes.customer.order);
    } else if (status === 'WRONG_USER') {
      enqueueSnackbar('Try with a different account', {
        variant: 'error',
        persist: true
      });
    }
  }, [status]);

  useEffect(() => {
    if (selectedShop && collectionAddress?.postcode) {
      getDistanceBetweenPostcodesInMiles(
        selectedShop.location,
        collectionAddress.postcode
      ).then((distance) =>
        setAddressDistance((prev) => ({
          ...prev,
          collection: distance
        }))
      );
    }
  }, [selectedShop, collectionAddress]);

  useEffect(() => {
    if (selectedShop && deliveryAddress?.postcode) {
      getDistanceBetweenPostcodesInMiles(
        selectedShop.location,
        deliveryAddress.postcode
      ).then((distance) =>
        setAddressDistance((prev) => ({
          ...prev,
          delivery: distance
        }))
      );
    }
  }, [selectedShop, deliveryAddress]);

  useEffect(() => {
    if (
      (collectionMethod === 'Collection' || deliveryMethod === 'Delivery') &&
      selectedShop?.distanceRange &&
      (addressDistance.collection > selectedShop?.distanceRange ||
        addressDistance.delivery > selectedShop?.distanceRange)
    ) {
      enqueueSnackbar(
        'Your location is beyond our service area. Please get in touch with the shop for more details.',
        {
          variant: 'error',
          persist: true,
          preventDuplicate: true
        }
      );
    }
  }, [selectedShop, addressDistance, typeOfOrder]);

  const typeChangeHandler = (e, newVal, setValue = () => {}) => {
    setValue(newVal);
    if (newVal === 'Drop') {
      setCollectionSlot(null);
      setCollectionTime(null);
    }
    if (newVal === 'Pickup') {
      setDeliverySlot(null);
      setDeliveryTime(null);
    }
  };

  const handleAddAuctionModalOpen = () => setShowAddAuctionModal(true);

  const handleAddAuctionModalClose = () => setShowAddAuctionModal(false);

  const asapRadioHandler = () => {
    setIsASAP(true);
    setTypeOfOrder('ASAP');
    setCollectionMethod('');
    setDeliveryMethod('');
    setCollectionSlot(null);
    setCollectionTime(null);
    setDeliverySlot(null);
    setDeliveryTime(null);
  };

  const scheduleRadioHandler = () => {
    setIsASAP(false);
    setTypeOfOrder('Schedule');
    setCollectionMethod('');
    setDeliveryMethod('');
  };

  const handleAccordionClick = (type) => () =>
    setSelectedSection((prev) => (prev === type ? '' : type));

  const ACCORDION_FORM_SECTIONS = [
    // order type
    {
      name: 'orderType',
      title: 'Order Types',
      isValid: !!typeOfOrder,
      content: (
        <>
          <Grid container>
            <RadioGroup
              row
              aria-label="asap-schedule-radio"
              name="asap-schedule-radio"
              defaultValue="schedule"
              style={{ marginBottom: '1rem', gap: '1rem' }}
              className={classes.radioContainer}>
              <StyledFormControlLabel
                value="asap"
                checked={typeOfOrder === 'ASAP'}
                label="ASAP"
                disabled={!isASAPEnabled}
                onChange={asapRadioHandler}
              />
              <StyledFormControlLabel
                value="schedule"
                checked={typeOfOrder === 'Schedule'}
                label="Schedule"
                onChange={scheduleRadioHandler}
              />
            </RadioGroup>
          </Grid>
          <Grid container>
            {selectedShop?.enableAsapDelivery ? (
              <Typography variant="h5" className={classes.note}>
                Note : Choose 'ASAP' for your items to be collected and
                delivered on the SAME DAY.
              </Typography>
            ) : null}
            {typeOfOrder === 'Schedule' && (
              <Typography variant="h5" className={classes.note}>
                Note : Collection & Delivery - Date and Time can be changed by
                the shop
              </Typography>
            )}
          </Grid>
        </>
      ),
      next: 'collection'
    },
    // collection
    {
      name: 'collection',
      title: `${typeOfOrder} Collection Options`,
      isValid: !!collectionMethod
        ? collectionMethod === 'Collection'
          ? typeOfOrder === 'ASAP'
            ? !!collectionAddress
            : collectionAddress &&
              collectionTime &&
              collectionSlot &&
              (selectedShop?.distanceRange
                ? addressDistance.collection <= selectedShop?.distanceRange
                : true)
          : true
        : false,
      content: (
        <RenderAddress
          type="Collection"
          value={collectionMethod}
          setValue={setCollectionMethod}
          typeChangeHandler={typeChangeHandler}
          collectionAddress={collectionAddress}
          changeAddress={changeAddress}
          isASAP={isASAP}
          collectionHrs={collectionHrs}
          setCollectionSlot={setCollectionSlot}
          setCollectionTime={setCollectionTime}
          collectionTime={collectionTime}
          collectionSlot={collectionSlot}
          setIsDefaultAddressChange={setIsDefaultAddressChange}
          setWorkingHoursMenuAnchor={setWorkingHoursMenuAnchor}
          workingHoursMenuAnchor={workingHoursMenuAnchor}
          instructions={instructions}
          options={collectionHrs.length > 0 ? ['Collection', 'Drop'] : ['Drop']}
          collectionInstructions={collectionInstructions}
          setCollectionInstructions={setCollectionInstructions}
        />
      ),
      next: 'delivery'
    },
    // delivery
    {
      name: 'delivery',
      title: `${typeOfOrder} Delivery Options`,
      isValid: !!deliveryMethod
        ? deliveryMethod === 'Delivery'
          ? typeOfOrder === 'ASAP'
            ? !!deliveryAddress
            : deliveryAddress &&
              deliveryTime &&
              deliverySlot &&
              (selectedShop?.distanceRange
                ? addressDistance.delivery <= selectedShop?.distanceRange
                : true)
          : true
        : false,
      content: (
        <RenderAddress
          type="Delivery"
          value={deliveryMethod}
          setValue={setDeliveryMethod}
          typeChangeHandler={typeChangeHandler}
          deliveryAddress={deliveryAddress}
          changeAddress={changeAddress}
          isASAP={isASAP}
          deliveryHrs={deliveryHrs}
          collectionTime={collectionTime}
          collectionSlot={collectionSlot}
          setDeliverySlot={setDeliverySlot}
          setDeliveryTime={setDeliveryTime}
          setIsDefaultAddressChange={setIsDefaultAddressChange}
          setWorkingHoursMenuAnchor={setWorkingHoursMenuAnchor}
          workingHoursMenuAnchor={workingHoursMenuAnchor}
          instructions={instructions}
          options={deliveryHrs.length > 0 ? ['Delivery', 'Pickup'] : ['Pickup']}
          deliveryInstructions={deliveryInstructions}
          setDeliveryInstructions={setDeliveryInstructions}
        />
      ),
      next: 'payment'
    },
    // payment options
    {
      name: 'payment',
      title: 'Payment Options',
      isValid:
        paymentMethod === 'card'
          ? cardDetails.selectedCardID || cardDetails.cardName.value
          : paymentMethod === 'cash',
      content: (
        <PaymentOptions
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          cardDetails={cardDetails}
          setCardDetails={setCardDetails}
        />
      )
    }
  ];

  return (
    <>
      {status === 'INITIAL' ? (
        <Grid container>
          <Grid
            item
            xs={12}
            container
            alignItems="center"
            className={classes.heading}>
            <Typography variant="h3">Order Review</Typography>
          </Grid>

          {/* form */}
          <Grid item xs={12} md={8}>
            {ACCORDION_FORM_SECTIONS.filter((item) =>
              action === 'place-auction' ? item.name !== 'payment' : true
            ).map((item, index) => {
              if (action === 'place-auction' && item.name === 'delivery')
                delete item.next;
              return (
                <Grid
                  container
                  className={classes.accordionContainer}
                  key={`checkout-form-section-${item.name}-${index}`}>
                  <Accordion
                    classes={{ root: classes.accordionRoot }}
                    expanded={selectedSection === item.name}
                    onChange={handleAccordionClick(item.name)}>
                    <AccordionSummary
                      classes={{ content: classes.accordionContent }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="order-type-accordion-content">
                      <Typography
                        variant="h3"
                        id={item.name}
                        className={classes.accordionTitle}>
                        {item.title}
                      </Typography>
                      {item.isValid ? (
                        <CheckCircleOutlineOutlinedIcon
                          className={classes.correctIcon}
                        />
                      ) : (
                        <ErrorOutlineOutlinedIcon
                          className={classes.errorIcon}
                        />
                      )}
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails>
                      <Grid container>
                        {item.content}
                        {item.next && (
                          <Grid container className={classes.nextBtnContainer}>
                            <Button
                              onClick={handleAccordionClick(item.next)}
                              href={`#${item.name}`}
                              disabled={!item.isValid}
                              variant="contained"
                              color="primary"
                              size="small">
                              Next
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
            })}
          </Grid>

          {/* cart */}
          <Grid item xs={12} md={4}>
            <CartCard
              isDefaultAddressChange={isDefaultAddressChange}
              toggleCardNameError={() =>
                setCardDetails((_prev) => ({
                  ..._prev,
                  cardName: { ..._prev.cardName, error: !_prev.cardName.error }
                }))
              }
              handleAddAuctionModalOpen={handleAddAuctionModalOpen}
              order={{
                typeOfOrder,
                collectionMethod,
                deliveryMethod,
                collectionAddress,
                deliveryAddress,
                collectionSlot,
                collectionTime,
                deliverySlot,
                deliveryTime,
                standardDeliveryFee,
                minPriceForFree,
                asapDeliveryFee,
                paymentMethod,
                cardDetails,
                setStatus,
                addressDistance,
                collectionInstructions,
                deliveryInstructions
              }}
            />
          </Grid>
        </Grid>
      ) : !!(status === 'FAILURE' || status === 'WRONG_USER') ? (
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{ padding: '2rem' }}>
          <Typography variant="h1">Error in placing Order</Typography>
        </Grid>
      ) : null}
      <AddressModal
        open={open}
        handleClose={handleClose}
        currAddressType={currAddressType}
        collectionAddress={collectionAddress}
        deliveryAddress={deliveryAddress}
        addressList={addressList}
        handleEdit={handleEdit}
        show={show}
        edit={edit}
        setCollectionAddress={setCollectionAddress}
        setDeliveryAddress={setDeliveryAddress}
        setOpen={setOpen}
      />
      {!auctionID ? (
        <AuctionFormModal
          open={showAddAuctionModal}
          handleClose={handleAddAuctionModalClose}
          orderProps={{
            collectionMethod,
            deliveryMethod,
            collectionAddress,
            deliveryAddress,
            collectionSlot,
            collectionTime,
            deliverySlot,
            deliveryTime
          }}
        />
      ) : null}
    </>
  );
}
