import React, { useState } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Button,
  Grid,
  Typography,
  ListItem,
  List,
  ListItemText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import { showPrice } from 'common/utilFunctions';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  collectionDelivery: {
    margin: '0 0 1.5rem',
    gap: '0.25rem'
  },
  titleHeader: {
    textAlign: 'center',
    fontWeight: 600,
    color: '#143a69'
  },
  header: {
    margin: '0 0 0.5rem'
  },
  productsContainer: {
    gap: '0.5rem'
  },
  listRoot: {
    width: '100%',
    padding: 0,
    gap: '0.5rem'
  },
  listItemRoot: {
    padding: 0
  },
  bidItemsContainer: {
    padding: '0.5rem',
    gap: '0.5rem',
    border: `0.5px solid ${theme.palette.grey.main}`,
    borderRadius: '0.25rem',
    marginBottom: '0.5rem'
  },
  deleteBtn: {
    backgroundColor: theme.palette.error.main
  },
  placeOrderBtn: {
    color: 'white',
    backgroundColor: theme.palette.blue.main,
    textTransform: 'uppercase'
  },
  bidShopText: {
    color: theme.palette.primary.main
  },
  textHeading: {
    fontWeight: 600
  },
  expectedBidAmount: {
    fontWeight: 600,
    fontSize: 16,
    color: '#143a69'
  }
}));

const AuctionCollapse = ({
  show = false,
  auction,
  status = 1, // auction status : { 0:Pending, 1:Ongoing, 2:Over }
  className = '',
  handleDelete = () => {},
  handleCloseAuction = () => {},
  handlePlaceOrder = () => {}
}) => {
  const classes = useStyles();
  const [openedBid, setOpenedBid] = useState(null);

  const handleBidCollapseSwitch = (bidID = '') => () =>
    setOpenedBid((_prev) => (_prev === bidID ? null : bidID));

  const collectionInstructions = auction?.collection?.instructions;
  const deliveryInstructions = auction?.delivery?.instructions;

  return (
    <>
      <Collapse in={show} className={classes.root}>
        <Card className={className}>
          <CardHeader
            title={`Auction #${auction.auctionID}`}
            classes={{
              title: classes.titleHeader
            }}
          />
          <CardContent>
            <Grid container className={classes.collectionDelivery}>
              <Grid container>
                <Typography variant="h5" className={classes.textHeading}>
                  Collection:&nbsp;
                </Typography>
                <Typography variant="subtitle1">
                  {!!auction?.collection?.date
                    ? `on ${moment(auction.collection?.date).format(
                        ' MMMM Do YYYY'
                      )} at ${
                        JSON.parse(auction.collection.address)['line1']
                      }, ${JSON.parse(auction.collection.address)['town']}`
                    : 'Order will be dropped off by the customer'}
                </Typography>
              </Grid>
              {collectionInstructions && (
                <Grid
                  container
                  style={{
                    borderBottom: '1px solid #ccc',
                    paddingBottom: '0.5rem'
                  }}>
                  <Typography variant="h6" className={classes.textHeading}>
                    Instructions:&nbsp;
                  </Typography>
                  <Typography variant="subtitle1">
                    {collectionInstructions}
                  </Typography>
                </Grid>
              )}

              <Grid container>
                <Typography variant="h5" className={classes.textHeading}>
                  Delivery:&nbsp;
                </Typography>
                <Typography variant="subtitle1">
                  {!!auction?.delivery?.date
                    ? `on ${moment(auction.delivery?.date).format(
                        ' MMMM Do YYYY'
                      )} at ${JSON.parse(auction.delivery.address)['line1']}, ${
                        JSON.parse(auction.delivery.address)['town']
                      }`
                    : 'Order will be picked up by the customer'}
                </Typography>
                {deliveryInstructions && (
                  <Grid container>
                    <Typography variant="h6" className={classes.textHeading}>
                      Instructions:&nbsp;
                    </Typography>
                    <Typography variant="subtitle1">
                      {deliveryInstructions}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid container direction="column" className={classes.header}>
              <Typography variant="h4">Products</Typography>
            </Grid>
            <Grid container className={classes.productsContainer}>
              {auction.items.items.map((item, index) => (
                <Grid
                  container
                  key={`new-auction-items-${index}`}
                  alignItems="center">
                  <Grid item xs={10}>
                    <Grid container>
                      <Typography variant="h5">
                        {index + 1}. {item.name || ''}
                        &nbsp;
                      </Typography>
                      <Typography variant="h6">
                        ({item.service?.name || ''})
                      </Typography>
                    </Grid>
                    <Typography variant="subtitle2">
                      {item.description || ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} container justify="flex-end">
                    {item.quantity} items
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid
              container
              justify="flex-end"
              style={{ gap: '1rem', marginTop: '1rem' }}>
              <Typography variant="h6" className={classes.expectedBidAmount}>
                Expected Bid Total:
              </Typography>
              <Typography variant="h6" className={classes.expectedBidAmount}>
                {showPrice(auction.total)}
              </Typography>
            </Grid>
          </CardContent>

          <Divider />

          <CardContent>
            <Grid container alignItems="baseline">
              <Typography
                variant="h4"
                color="primary"
                style={{ fontWeight: 600 }}>
                Bids
              </Typography>
            </Grid>
            {auction.bids.items.length > 0 ? (
              <List className={classes.listRoot}>
                {auction.bids.items
                  .sort((_bidA, _bidB) => _bidA.totalPrice - _bidB.totalPrice)
                  .map((_bid, index) => (
                    <React.Fragment key={`auction-bid-items-${index}`}>
                      <ListItem
                        button
                        className={classes.listItemRoot}
                        onClick={handleBidCollapseSwitch(_bid.id)}>
                        <ListItemText
                          classes={{ secondary: classes.bidShopText }}
                          primary={
                            <Typography variant="h5">
                              Bid total value:
                              <strong>
                                {` ${showPrice(_bid.totalPrice)}`}
                              </strong>
                            </Typography>
                          }
                          secondary={`${_bid.shop.name} (${_bid.shop.postcode}) | ph: ${_bid.shop.phoneNumber}`}
                        />
                        {openedBid === _bid.id ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItem>

                      <Collapse in={openedBid === _bid.id}>
                        <Grid container className={classes.bidItemsContainer}>
                          {_bid.items.items.map((_bidItem, _index) => {
                            const auctionItem = auction.items.items.find(
                              (_auctionItem) =>
                                _auctionItem.id === _bidItem.auctionItemID
                            );
                            return (
                              <Grid
                                container
                                key={`bid-${_bid.id}-items-${_index}`}>
                                <Grid
                                  container
                                  item
                                  xs={10}
                                  alignItems="center">
                                  <Typography variant="subtitle1">
                                    {auctionItem?.name || ''}&nbsp;
                                  </Typography>
                                  <Typography variant="body1">
                                    x {auctionItem?.quantity || ''} items
                                  </Typography>
                                </Grid>
                                <Grid container item xs={2} justify="flex-end">
                                  <Typography variant="h6">
                                    {showPrice(_bidItem.price)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          })}

                          <Grid container justify="space-between">
                            <Grid item>
                              <Typography variant="body1">
                                Shipping Charges
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body1">
                                {showPrice(_bid.shippingCharges || 0)}
                              </Typography>
                            </Grid>
                          </Grid>

                          {!!(
                            status === 2 &&
                            auction.bids.items.every(
                              (_bid) => _bid.orders.items.length === 0
                            )
                          ) ? (
                            <>
                              <Grid container>
                                <Divider style={{ width: '100%' }} />
                              </Grid>
                              <Grid
                                container
                                justify="flex-end"
                                style={{ gap: '0.5rem' }}>
                                {!!auction.collection?.date &&
                                  new Date(auction.collection.date) <
                                    new Date() && (
                                    <Grid
                                      container
                                      alignItems="center"
                                      justify="flex-end">
                                      <InfoIcon
                                        fontSize="small"
                                        color="primary"
                                      />
                                      <Typography
                                        variant="body2"
                                        style={{ fontSize: 14 }}>
                                        &nbsp;Unable to place your bid as
                                        scheduled collection date have passed.
                                        Kindly contact the shop for assistance.
                                      </Typography>
                                    </Grid>
                                  )}

                                <Button
                                  onClick={handlePlaceOrder(openedBid)}
                                  disabled={
                                    !!auction.collection?.date
                                      ? new Date(auction.collection.date) <
                                        new Date()
                                      : false
                                  }
                                  variant="contained"
                                  className={classes.placeOrderBtn}>
                                  select bid and place order
                                </Button>
                              </Grid>
                            </>
                          ) : null}
                        </Grid>
                      </Collapse>
                    </React.Fragment>
                  ))}
              </List>
            ) : (
              <Grid container>
                <Typography variant="subtitle2">No bids places yet</Typography>
              </Grid>
            )}
          </CardContent>

          {!!(status === 0 || status === 1) ? (
            <>
              <Divider />
              <CardActions>
                <Grid
                  container
                  justify="flex-end"
                  alignItems="baseline"
                  style={{ gap: '0.5rem' }}>
                  {status === 1 && auction.bids.items.length > 0 && (
                    <Grid container justify="flex-end" alignItems="center">
                      <InfoIcon fontSize="small" style={{ color: '#edd242' }} />
                      <Typography variant="body1" style={{ fontSize: 14 }}>
                        &nbsp;Close the auction to choose the winning bid.
                      </Typography>
                    </Grid>
                  )}
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="small"
                    onClick={handleCloseAuction}>
                    Close Auction
                  </Button>
                  {status === 0 ? (
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      className={classes.deleteBtn}
                      onClick={handleDelete}>
                      Delete
                    </Button>
                  ) : null}
                </Grid>
              </CardActions>
            </>
          ) : null}
        </Card>
      </Collapse>
    </>
  );
};

export default AuctionCollapse;
