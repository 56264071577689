import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { AccountDetails, Password, AccountProfile } from './components';
import { useAmplifyAuth } from '../../context';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Settings = () => {
  const classes = useStyles();

  const {
    state: { user, isAdmin = false }
  } = useAmplifyAuth();

  return (
    user && (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item md={7} xs={12}>
            {isAdmin ? <AccountDetails /> : <AccountProfile />}
          </Grid>
          <Grid item md={5} xs={12}>
            <Password />
          </Grid>
          {!isAdmin ? (
            <Grid item md={7} xs={12}>
              <AccountDetails />
            </Grid>
          ) : null}
        </Grid>
      </div>
    )
  );
};

export default Settings;
