import React from 'react';
import { Address } from 'components/organisms';
import {
  Backdrop,
  Button,
  CircularProgress,
  Fade,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Paper,
  RadioGroup,
  Typography,
  Radio
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '1rem 0.625rem 1rem 1.25rem',
    maxWidth: '95%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '50%'
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalScroll: {
    overflowY: 'scroll',
    maxHeight: '80vh',
    paddingRight: '1rem'
  }
}));

const AddressModal = ({
  show = true,
  edit = false,
  open = false,
  handleClose = () => {},
  currAddressType,
  collectionAddress,
  setCollectionAddress = () => {},
  deliveryAddress,
  setDeliveryAddress = () => {},
  addressList,
  handleEdit = () => {},
  setOpen = () => {}
}) => {
  const classes = useStyles();

  const formatAddress = (address) => {
    const arr = [
      'buildingNumber',
      'buildingName',
      'line1',
      'district',
      'postcode',
      'phoneNumber'
    ];
    const returnValue = arr
      .map((v) => address[v])
      .filter((v) => v)
      .join(' ');
    return returnValue;
  };

  return (
    <Modal
      aria-describedby="addresses-modal-to-check-details-or-update"
      aria-labelledby="addresses-modal"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      className={classes.modal}
      closeAfterTransition
      onClose={handleClose}
      open={open}>
      <Fade in={open}>
        <Paper className={classes.paper}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{ marginBottom: '15px' }}>
            <Typography variant="h3" id="transition-modal-title">
              {((currAddressType === 'Collection' && collectionAddress) ||
                (currAddressType === 'Delivery' && deliveryAddress)) &&
              show
                ? 'Change Address'
                : 'Add Address'}
            </Typography>
            {!!addressList.items.length && !edit ? (
              <Button className={classes.blueBtn} onClick={handleEdit}>
                Add Address
              </Button>
            ) : (
              !addressList.loading && (
                <IconButton onClick={handleClose} size="small">
                  <CloseIcon fontSize="small" />
                </IconButton>
              )
            )}
          </Grid>
          <div>
            {addressList.loading ? (
              <CircularProgress />
            ) : (
              <>
                {(!addressList.items.length || edit) && (
                  <PerfectScrollbar className={classes.modalScroll}>
                    <Address
                      data={
                        currAddressType === 'Collection'
                          ? collectionAddress
                          : deliveryAddress
                      }
                      setData={
                        currAddressType === 'Collection'
                          ? setCollectionAddress
                          : setDeliveryAddress
                      }
                      mode={3}
                      onCancel={handleClose}
                    />
                  </PerfectScrollbar>
                )}
                <RadioGroup
                  aria-label="address"
                  name="address"
                  value={
                    currAddressType === 'Collection' && collectionAddress
                      ? collectionAddress.id
                      : currAddressType === 'Delivery' && deliveryAddress
                      ? deliveryAddress.id
                      : ''
                  }>
                  {!edit &&
                    addressList.items.map((d) => (
                      <FormControlLabel
                        classes={{ root: classes.fomrControlLabelRoot }}
                        control={<Radio />}
                        key={d.id}
                        label={formatAddress(d)}
                        onClick={() => {
                          currAddressType === 'Collection'
                            ? setCollectionAddress(d)
                            : setDeliveryAddress(d);
                          setOpen(false);
                        }}
                        value={d.id}
                      />
                    ))}
                </RadioGroup>
              </>
            )}
          </div>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default AddressModal;
