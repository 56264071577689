import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
  useState
} from 'react';
import { useLoader } from 'layouts/loaderContext';
import { messageNotificationsReducer } from './messageNotificationsReducer';
import * as messageAPIs from './messagesAPIs';

const MessagesContext = createContext({});

const MessagesProvider = (props) => {
  const [messageNotifications, dispatch] = useReducer(
    messageNotificationsReducer,
    []
  );
  const { setLoading } = useLoader();
  const [messageNotificationsByID, setMessageNotificationsByID] = useState(
    null
  );

  const asyncDispatch = useCallback(
    async (action) => {
      switch (action.type) {
        case 'getMessageNotificationByUserOrShop': {
          const { userID, shopID } = action.payload;
          setLoading(true);
          try {
            if (!userID && !shopID)
              throw new Error('invalid userID and shopID');

            let resp;
            const queryProps = { limit: 8000 };
            if (!!userID) {
              if (messageNotificationsByID === userID) return;
              setMessageNotificationsByID(userID);
              queryProps.userID = userID;
              resp = await messageAPIs.loadMessageNotificationsByUser(
                queryProps
              );
            }
            if (!!shopID) {
              if (messageNotificationsByID === shopID) return;
              setMessageNotificationsByID(shopID);
              queryProps.shopID = shopID;
              resp = await messageAPIs.loadMessageNotificationsByShop(
                queryProps
              );
            }

            if (resp) {
              const data = resp.items
                .filter(
                  (item) =>
                    !item._deleted && !item.isRead && !item.message?._deleted
                )
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
              dispatch({
                type: 'updateMessageNotifications',
                payload: data
              });
            }
          } catch (error) {
            console.error(
              'Something went wrong while getting notifications... ',
              error
            );
          } finally {
            setLoading(false);
          }
          break;
        }
        case 'markMessagesAsReadByOrder': {
          const { orderID } = action.payload;
          try {
            if (!orderID) throw new Error('invalid orderID');

            const notificationIDs = messageNotifications
              .filter((_item) => _item?.orderID === orderID)
              .map((_item) => _item.id);
            if (notificationIDs.length === 0) return;

            setLoading(true);

            await messageAPIs.markNotificationsAsRead({ notificationIDs });
            dispatch({
              type: 'updateMessageNotifications',
              payload: messageNotifications.filter(
                (_item) => _item.orderID !== orderID
              )
            });
          } catch (error) {
            console.error(
              'Something went wrong while marking notifications as read... ',
              error
            );
          } finally {
            setLoading(false);
          }
          break;
        }
        // fetch notification data and add to context
        case 'addNewNotification': {
          const { notificationID } = action.payload;
          try {
            if (!notificationID) return;

            const notification = await messageAPIs.getMessageNotification(
              notificationID
            );
            dispatch({
              type: 'addMessageNotifications',
              payload: [notification]
            });
          } catch (error) {
            console.error(
              'something went wrong while getting notification data... ',
              error
            );
          }
          break;
        }
        default:
          dispatch(action);
      }
    },
    [messageNotifications, messageNotificationsByID]
  );

  const value = {
    messageNotifications,
    dispatch: asyncDispatch
  };

  return (
    <MessagesContext.Provider value={value}>
      {props.children}
    </MessagesContext.Provider>
  );
};

const useMessages = () => {
  const context = useContext(MessagesContext);
  if (!(context && Object.keys(context).length)) {
    throw new Error('useMessages must be used within a MessagesContext');
  }
  return context;
};

export { useMessages, MessagesProvider };
