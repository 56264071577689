import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMessages } from 'context/messages/messagesContext';
import { useOrders } from 'context/order/ordersContext';
import { useAmplifyAuth } from 'context';
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TableSortLabel,
  TableFooter,
  Grid,
  Badge,
  IconButton,
  Link,
  useMediaQuery
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import EditIcon from '@material-ui/icons/Edit';
import PaymentUpdateDialog from './dialogs/PaymentUpdateDialog';
import {
  showPrice,
  unitFormatter,
  getFormattedName,
  getOrderTotalRating,
  getFormattedPhoneNumber
} from 'common/utilFunctions';
import { Image } from 'components/organisms';
import defaultUserImage from 'assets/png/default-image-user.png';
import { DocumentDeleteDialog } from 'components/organisms';
import moment from 'moment';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'auto',
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none'
    }
  },
  content: {
    '& th': { fontSize: '0.75rem' },
    '& td': { fontSize: '0.75rem', '& p': { fontSize: '0.75rem' } },
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      '& thead': { display: 'none' },
      '& td': { display: 'block', padding: '0.5rem' },
      '& tr': {
        border: '1px solid #0000003b',
        borderRadius: '0.25rem'
      },
      '& tbody': {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem'
      }
    }
  },
  hideForMobile: {
    [theme.breakpoints.down('xs')]: {
      display: 'none !important'
    }
  },
  hideForDesktop: {
    [theme.breakpoints.up('sm')]: {
      display: 'none !important'
    }
  },
  delinedMessage: {
    backgroundColor: theme.palette.warning.main,
    gap: '0.25rem',
    padding: '0.25rem',
    borderRadius: '0.25rem',
    '& p': { color: 'white' },
    '& svg': { color: 'white' }
  },
  inner: {
    minWidth: 1050
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  searchInput: {
    margin: theme.spacing(2),
    marginLeft: 0,
    maxWidth: 500
  },
  orderIDColumn: {
    cursor: 'pointer'
  },
  customerImage: {
    height: 40,
    width: 40,
    borderRadius: '50%'
  },
  customerName: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 150
    }
  },
  mobileFullwidthButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    '& span': {
      color: theme.palette.common.white
    }
  },
  forwardOrderButton: {
    backgroundColor: '#ffaa33',
    color: '#fff'
  },
  auctionTag: {
    backgroundColor: '#edd242',
    fontWeight: 500,
    borderRadius: 4
  }
}));

const OrderIDColumnContent = ({
  handleClick = () => {},
  isAsap = false,
  orderID = ''
}) => {
  const classes = useStyles();

  return (
    <Link
      className={classes.orderIDColumn}
      style={{
        color: isAsap ? 'red' : 'blue'
      }}
      onClick={handleClick}>
      {orderID}
    </Link>
  );
};

const OrdersTable = (props) => {
  const {
    className,
    headerTitle = '',
    statuses = [],
    stage,
    updateOrder,
    setShowOrderDetails = () => {},
    loadOrders = () => {},
    ...rest
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const {
    orders: allOrders,
    forwardRequests,
    isMoreOrdersAvailable,
    dispatch
  } = useOrders();
  const [orders, setOrders] = useState([]);
  const { messageNotifications } = useMessages();
  const {
    state: { isLaundryStaff }
  } = useAmplifyAuth();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('orderID');
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [currentOrder, setCurrentOrder] = useState();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showMessageDialog, setShowMessageDialog] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [forwardOrderActionModal, setForwardOrderActionModal] = useState({
    show: false,
    action: null
  });

  useEffect(() => {
    if ([10, 12].includes(stage)) {
      setOrders(forwardRequests);
    } else {
      setOrders(allOrders);
    }
  }, [allOrders, forwardRequests]);

  useEffect(() => {
    const result = orders?.filter(({ status: s }) =>
      !!statuses.length ? statuses.includes(s) : true
    );
    setFilteredOrders(result);
  }, [orders]);

  useEffect(() => {
    const isLastItemOnPage =
      filteredOrders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .length === 0;

    if (isLastItemOnPage && page !== 0) {
      setPage(page - 1);
    }
  }, [filteredOrders]);

  const handlePageChange = (_event, page) => {
    isMoreOrdersAvailable && loadOrders(true, false);
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleShowMesssages = (order) => updateOrder(order, 'showMessages');

  const handlePaymentClick = (order) => () => {
    setCurrentOrder(order);
    setShowPaymentModal(true);
  };

  const handleForwardRequestActionClick = (order, action) => () => {
    setSelectedOrder(order);
    setForwardOrderActionModal({ show: true, action: action });
  };

  // for sorting by property
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    dispatch({
      type: 'sortOrders',
      payload: { property, direction: newOrder }
    });
  };

  const handleOrderIDClick = (order) => () => {
    setShowOrderDetails(true);
    updateOrder(order, 'details');
  };

  const handleForwardOrderAction = () => {
    if (forwardOrderActionModal.action === 'accept') {
      updateOrder(selectedOrder, 'pending');
    } else {
      updateOrder(selectedOrder, 'rejected');
    }
    setForwardOrderActionModal({ show: false, action: null });
  };

  return (
    <>
      <Card {...rest} className={clsx(classes.root, className)}>
        {!headerTitle ? null : (
          <CardHeader
            title={headerTitle}
            classes={{ root: classes.cardHeader }}
          />
        )}
        <CardContent className={classes.content}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ minWidth: 152 }}>
                  NAME &amp; CONTACT
                </TableCell>
                <TableCell align="center" style={{ minWidth: 150 }}>
                  <TableSortLabel
                    active={orderBy === 'orderID'}
                    direction={orderBy === 'orderID' ? order : 'asc'}
                    onClick={(e) => handleRequestSort(e, 'orderID')}>
                    &nbsp; &nbsp; &nbsp; &nbsp; ORDER NO.
                  </TableSortLabel>
                </TableCell>

                <TableCell align="center" style={{ minWidth: 170 }}>
                  <TableSortLabel
                    active={orderBy === 'date'}
                    direction={orderBy === 'date' ? order : 'asc'}
                    onClick={(e) => handleRequestSort(e, 'date')}>
                    &nbsp; &nbsp; &nbsp; &nbsp; DATE OF ORDER
                  </TableSortLabel>
                </TableCell>

                {stage === 9 ? (
                  <TableCell align="center">DELIVERED ON</TableCell>
                ) : (
                  <TableCell align="center" style={{ minWidth: 160 }}>
                    <TableSortLabel
                      active={orderBy === 'deliveryDate'}
                      onClick={(e) => handleRequestSort(e, 'deliveryDate')}
                      direction={orderBy === 'deliveryDate' ? order : 'asc'}>
                      &nbsp; &nbsp; &nbsp; EST. DELIVERY
                    </TableSortLabel>
                  </TableCell>
                )}

                {[10, 11, 12].includes(stage) ? (
                  <TableCell align="center">ORDER ACCEPTED BY</TableCell>
                ) : null}

                {stage === 10 ? (
                  <TableCell align="center" style={{ minWidth: 170 }}>
                    Forward Request From
                  </TableCell>
                ) : null}

                {(stage === 7 || stage === 8 || stage === 9) && (
                  <TableCell align="center">PRICE</TableCell>
                )}

                {(stage === 1 ||
                  stage === 3 ||
                  stage === 4 ||
                  stage === 5 ||
                  stage === 6) && <TableCell align="left">PAYMENT</TableCell>}

                {isLaundryStaff ? null : stage === 9 ? null : !!(
                    stage === 7 || stage === 8
                  ) ? (
                  <TableCell align="center">CONTACT</TableCell>
                ) : !isLaundryStaff && stage === 6 ? (
                  // FIXME DRIVER
                  <TableCell align="center"></TableCell>
                ) : (
                  <TableCell></TableCell>
                )}

                {stage === 7 || stage === 8 || stage === 9 ? (
                  <TableCell align="center"> &nbsp;STATUS</TableCell>
                ) : (
                  <TableCell></TableCell>
                )}

                {stage === 9 && (
                  <TableCell align="center">CUSTOMER RATING</TableCell>
                )}
              </TableRow>
            </TableHead>
            {filteredOrders.length > 0 ? (
              <>
                <TableBody>
                  {filteredOrders
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((order) => {
                      const noOfNotifications =
                        messageNotifications?.filter(
                          (_item) => _item?.orderID === order.id
                        ).length || 0;

                      const acceptedForwardOrderShopName =
                        order?.forwardRequests?.items[0]?.acceptedShop?.name ||
                        'pending';

                      return (
                        <TableRow
                          className={classes.tableRow}
                          hover
                          key={order.id}>
                          <TableCell align="left">
                            <Grid
                              container
                              justify="space-between"
                              alignItems="center">
                              <Grid
                                item
                                xs={8}
                                sm={10}
                                md={12}
                                className={classes.customerName}
                                container
                                alignItems="center">
                                <Badge
                                  className={classes.avatar}
                                  badgeContent={
                                    noOfNotifications > 0 ? (
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          handleShowMesssages(order)
                                        }
                                        style={{ backgroundColor: '#C2E7FF' }}>
                                        <MailOutlineRoundedIcon
                                          style={{ fontSize: '14px' }}
                                        />
                                      </IconButton>
                                    ) : null
                                  }>
                                  <Image
                                    docKey={order.userDetail?.picture}
                                    onError={(e) =>
                                      (e.target.src = defaultUserImage)
                                    }
                                    className={classes.customerImage}
                                  />
                                </Badge>
                                <Typography variant="body1">
                                  {getFormattedName(order.userDetail)}
                                  <br />
                                  {getFormattedPhoneNumber(
                                    order.userDetail?.phoneNumber
                                  )}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sm={2}
                                md={12}
                                container
                                justify="flex-end"
                                alignItems="center"
                                className={classes.hideForDesktop}>
                                {order.bidID && (
                                  <Typography className={classes.auctionTag}>
                                    From Auction
                                  </Typography>
                                )}
                                <OrderIDColumnContent
                                  orderID={order.orderID}
                                  isAsap={order?.collection?.type === 'asap'}
                                  handleClick={handleOrderIDClick(order)}
                                />
                              </Grid>
                            </Grid>
                          </TableCell>

                          <TableCell
                            align="center"
                            className={classes.hideForMobile}>
                            {order.bidID && (
                              <Typography className={classes.auctionTag}>
                                From Auction
                              </Typography>
                            )}
                            <OrderIDColumnContent
                              orderID={order.orderID}
                              isAsap={order?.collection?.type === 'asap'}
                              handleClick={handleOrderIDClick(order)}
                            />
                          </TableCell>

                          <TableCell
                            align="center"
                            className={classes.hideForMobile}>
                            <Typography variant="body1">
                              {moment(order.createdAt).format('DD/MM/YYYY')}
                            </Typography>
                          </TableCell>

                          <TableCell
                            align="center"
                            className={classes.hideForMobile}>
                            {order.delivery?.date
                              ? moment(order.delivery.date).format('DD/MM/YYYY')
                              : 'N/A'}
                          </TableCell>

                          {[10, 11, 12].includes(stage) ? (
                            <TableCell align="center">
                              {acceptedForwardOrderShopName
                                ? acceptedForwardOrderShopName
                                : 'pending'}
                            </TableCell>
                          ) : null}

                          {(stage === 7 || stage === 8 || stage === 9) && (
                            <TableCell align="center">
                              {stage === 8
                                ? 'N/A'
                                : showPrice(order?.total || 0)}
                            </TableCell>
                          )}

                          {stage === 10 ? (
                            <TableCell
                              align="center"
                              style={{ fontSize: '13px' }}>
                              {order.forwardRequests?.items &&
                                order.forwardRequests?.items[0]?.requestShop
                                  ?.name}
                            </TableCell>
                          ) : null}

                          {stage === 1 ||
                          stage === 3 ||
                          stage === 4 ||
                          stage === 5 ||
                          stage === 6 ? (
                            <TableCell
                              align="left"
                              style={{
                                color:
                                  order.paymentStatus === 'paid'
                                    ? 'green'
                                    : 'black',
                                fontSize: sm ? '0.75rem' : '0.875rem'
                              }}>
                              <span className={classes.hideForDesktop}>
                                Payment:{' '}
                              </span>
                              {order.paymentStatus === 'paid' ? (
                                `Paid ${showPrice(order?.total || 0)}`
                              ) : (
                                <>
                                  {unitFormatter(order.paymentStatus) ||
                                    'Unpaid'}
                                  {!(
                                    stage === 1 ||
                                    order.paymentMethod === 'card'
                                  ) ? (
                                    <IconButton
                                      onClick={handlePaymentClick(order)}>
                                      <EditIcon fontSize="small" />
                                    </IconButton>
                                  ) : null}
                                </>
                              )}
                            </TableCell>
                          ) : null}

                          {isLaundryStaff ? null : [9, 10, 11, 12].includes(
                              stage
                            ) ? null : (
                            <TableCell align="center">
                              {stage === 6 ? (
                                <Typography>
                                  {getFormattedName(order.delivery.driver)}
                                  <br />
                                  {
                                    <a
                                      href={`tel:${getFormattedPhoneNumber(
                                        order.delivery?.driver?.phoneNumber
                                      )}`}>
                                      {getFormattedPhoneNumber(
                                        order.delivery?.driver?.phoneNumber
                                      )}
                                    </a>
                                  }
                                </Typography>
                              ) : (
                                <Badge
                                  variant="standard"
                                  color="primary"
                                  className={classes.mobileFullwidthButton}
                                  badgeContent={noOfNotifications}>
                                  <Button
                                    onClick={() => handleShowMesssages(order)}
                                    endIcon={
                                      <MailOutlineRoundedIcon fontSize="small" />
                                    }
                                    variant="contained"
                                    size="small"
                                    className={classes.mobileFullwidthButton}>
                                    Message
                                  </Button>
                                </Badge>
                              )}
                            </TableCell>
                          )}

                          {/* status column */}
                          <TableCell align="center">
                            {stage === 1 ? (
                              order.status === 'pending' ? (
                                'Waiting for customer to accept the order'
                              ) : (
                                <Grid
                                  container
                                  justify="space-evenly"
                                  style={{
                                    gap: '0.25rem',
                                    padding: '0.25rem'
                                  }}>
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      updateOrder(order, 'accepted')
                                    }
                                    variant="contained"
                                    size="small">
                                    Accept
                                  </Button>
                                  <Button
                                    color="secondary"
                                    size="small"
                                    onClick={() =>
                                      updateOrder(order, 'declined')
                                    }
                                    variant="contained">
                                    Decline
                                  </Button>
                                  {/* TODO ORDER_FORWARD */}
                                  {/* <Button
                                    className={classes.forwardOrderButton}
                                    size="small"
                                    onClick={() =>
                                      updateOrder(order, 'forwarded')
                                    }
                                    disabled={
                                      order.forwardRequests?.items[0]
                                        ?.status === 'accepted'
                                    }
                                    variant="contained">
                                    Forward
                                  </Button> */}
                                </Grid>
                              )
                            ) : null}

                            {stage === 2 && (
                              <Button
                                color="primary"
                                onClick={() => updateOrder(order, 'itemized')}
                                variant="contained"
                                size="small"
                                className={classes.mobileFullwidthButton}>
                                {!!order.bidID
                                  ? 'Move to processing'
                                  : 'Ready For Item Pricing'}
                              </Button>
                            )}

                            {stage === 3 ? (
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => updateOrder(order, 'inProgress')}
                                size="small"
                                className={classes.mobileFullwidthButton}>
                                Ready For Wash
                              </Button>
                            ) : null}

                            {stage === 4 && (
                              <Grid
                                container
                                justify="space-between"
                                alignItems="center">
                                <Typography>In progress</Typography>
                                <Button
                                  color="primary"
                                  onClick={() =>
                                    updateOrder(order, 'readyForDelivery')
                                  }
                                  variant="contained"
                                  size="small">
                                  Move to Delivery
                                </Button>
                              </Grid>
                            )}

                            {stage === 5 && (
                              <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                onClick={() =>
                                  updateOrder(order, 'outForDelivery')
                                }>
                                Proceed for Delivery
                              </Button>
                            )}

                            {stage === 6 && (
                              <>
                                {order.status === 'delivered' ? (
                                  <Typography>Delivered</Typography>
                                ) : (
                                  <Grid
                                    container
                                    direction="column"
                                    alignItems="center">
                                    <Typography>Out for Delivery</Typography>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        updateOrder(order, 'delivered')
                                      }
                                      className={classes.mobileFullwidthButton}
                                      variant="contained"
                                      size="small">
                                      Mark as Complete
                                    </Button>
                                  </Grid>
                                )}
                              </>
                            )}

                            {stage === 7 && order.status}

                            {stage === 8 && (
                              <Grid
                                container
                                className={classes.delinedMessage}
                                alignItems="center"
                                justify="center">
                                <Typography align="center">Declined</Typography>
                                <HighlightOffIcon fontSize="small" />
                              </Grid>
                            )}

                            {stage === 9 && 'delivered'}
                            {stage === 10 && (
                              <Grid
                                container
                                justify="space-evenly"
                                style={{ gap: '0.25rem', padding: '0.25rem' }}>
                                <Button
                                  color="primary"
                                  onClick={handleForwardRequestActionClick(
                                    order,
                                    'accept'
                                  )}
                                  variant="contained"
                                  size="small">
                                  Accept
                                </Button>
                                <Button
                                  color="secondary"
                                  size="small"
                                  onClick={handleForwardRequestActionClick(
                                    order,
                                    'reject'
                                  )}
                                  variant="contained">
                                  Reject
                                </Button>
                              </Grid>
                            )}
                          </TableCell>

                          {stage === 9 && (
                            <TableCell align="center">
                              {order.customerReview.items.length > 0 ? (
                                <Rating
                                  value={getOrderTotalRating(
                                    order.customerReview.items[0].ratings
                                  )}
                                  precision={0.5}
                                  readOnly
                                />
                              ) : (
                                'Not yet rated'
                              )}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={isMoreOrdersAvailable ? -1 : filteredOrders.length}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[5, 10, 25]}
                    />
                  </TableRow>
                </TableFooter>
              </>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6}>
                    <Typography variant="h6" align="center">
                      No Records Found!!!
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </CardContent>
      </Card>

      <PaymentUpdateDialog
        order={currentOrder}
        open={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
      />
      <DocumentDeleteDialog
        open={forwardOrderActionModal.show}
        handleCancel={() =>
          setForwardOrderActionModal({ show: false, action: null })
        }
        handleConfirm={handleForwardOrderAction}
        title={
          forwardOrderActionModal.action === 'accept'
            ? 'Accept forwarded order'
            : 'Reject forwarded order'
        }
        confirmText={
          forwardOrderActionModal.action === 'accept'
            ? 'accept this order'
            : 'reject this order'
        }
        note={'Note: Please verify the order details before taking any action.'}
      />
    </>
  );
};

OrdersTable.propTypes = {
  className: PropTypes.string
};

export default OrdersTable;
