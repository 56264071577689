import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  Tabs,
  Tab,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import StaffDetailsForm from './StaffDetailsForm';
import StaffDocumentationForm from './StaffDocumentationForm';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  tabRoot: {
    margin: '2px 4px',
    border: '1px solid grey',
    borderRadius: '10px'
  },
  tabSelected: {
    border: `2px solid ${theme.palette.primary.main}`
  }
}));

const AddNewStaff = ({ setShowAddForm, setShowDetails }) => {
  const [currentTab, setCurrentTab] = useState('details');
  const classes = useStyles();

  const onClose = () => {
    setShowDetails(false);
    setShowAddForm(false);
  };

  return (
    <Card style={{ boxShadow: 'none' }}>
      <Grid container justify="space-between" alignItems="flex-start">
        <Grid container item xs={12} md={6} alignItems="center">
          <IconButton onClick={onClose}>
            <ArrowBackIcon fontSize="small" />
          </IconButton>
          <Typography>Add Staff</Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={6}
          justify="flex-end"
          alignItems="center">
          <Tabs
            TabIndicatorProps={{ style: { height: 0 } }}
            value={currentTab}
            onChange={(e, newVal) => setCurrentTab(newVal)}
            indicatorColor="primary"
            variant="fullWidth"
            aria-label="staff-profile-tabs">
            <Tab
              classes={{ selected: classes.tabSelected, root: classes.tabRoot }}
              label="Setup Details"
              value={'details'}
              disabled={currentTab === 'docs'}
            />
            <Tab
              classes={{ selected: classes.tabSelected, root: classes.tabRoot }}
              label="Documentation"
              value={'docs'}
              disabled={currentTab === 'details'}
            />
          </Tabs>
        </Grid>
      </Grid>
      {currentTab === 'docs' ? (
        <StaffDocumentationForm onClose={onClose} />
      ) : (
        <StaffDetailsForm setCurrentTab={setCurrentTab} />
      )}
    </Card>
  );
};

export default AddNewStaff;
