import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { showPrice, getOrderTotal } from 'common/utilFunctions';
import { useHistory } from 'react-router-dom';
import { useOrders } from 'context/order/ordersContext';
import { useCoupons } from 'context/coupons/couponsContext';
import { useLoader } from 'layouts/loaderContext';
import { useSnackbar } from 'notistack';
import { useAmplifyAuth } from 'context';
import { routes } from 'common/constants';
import { omit } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    backgroundColor: '#194376',
    padding: '8px 24px'
  },
  orderId: {
    fontWeight: 600,
    color: '#fff'
  },
  couponsHeading: {
    fontWeight: 600,
    color: '#143a69'
  },
  coupon: {
    borderRadius: '8px',
    margin: '0.2rem 0',
    padding: '0.6rem 0.5rem',
    boxShadow: '0px 0px 3px 0px #00000033'
  },
  appliedCoupon: {
    boxShadow: '0px 0px 3px 0px #1f6893'
  },
  couponTitle: {
    fontSize: '16px',
    fontWeight: '600',
    marginBottom: '0.2rem',
    background: '#EDD242',
    background: 'linear-gradient(to bottom, #EDD242 21%, #F94E4E 50%)',

    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
  },
  couponBtn: {
    borderRadius: '8px',
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    height: 'fit-content'
  },
  applyBtn: {
    backgroundColor: theme.palette.blue.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.blue.secondary
    }
  },
  clearBtn: {
    backgroundColor: theme.palette.warning.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.warning.dark
    }
  },

  contentMain: {
    gap: '0.5rem'
  },
  couponsContent: {
    gap: '0.25rem'
  },
  applyBtn: {
    backgroundColor: theme.palette.blue.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  couponTail: {
    gap: '0.5rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end'
    }
  },
  paymentTextNote: {
    fontWeight: 600,
    color: '#e65912',
    fontSize: '13px'
  },
  paymentDue: {
    margin: '0.3rem 0 0.5rem',
    paddingTop: '0.5rem',
    borderTop: '1px solid #ccc'
  },
  boldText: {
    fontWeight: 600,
    color: '#444'
  },
  colorBoldText: {
    fontWeight: 600,
    color: '#143a69'
  },
  smallBottomMargin: {
    marginBottom: '0.5rem'
  }
}));

const OrderPaymentDialog = ({
  open = false,
  handleClose = () => {},
  setShowEditDeliveryDialog = () => {},
  order
}) => {
  const {
    state: { user }
  } = useAmplifyAuth();
  const classes = useStyles();
  const { loading } = useLoader();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { coupons = [], dispatch } = useCoupons();
  const { dispatch: ordersDispatch } = useOrders();
  const [newDiscount, setNewDiscount] = useState(0);
  const [showPayment, setShowPayment] = useState(false);
  const [newAppliedCoupons, setNewAppliedCoupons] = useState([]);
  const [taxDeduction, newOrderTotal, paymentDue] = useMemo(() => {
    const taxDeduction =
      process.env.REACT_APP_VAT === 'enabled' ? newDiscount * 0.2 : 0;
    const newOrderTotal =
      Math.round(((order?.total || 0) - newDiscount - taxDeduction) * 100) /
      100;
    const paymentDue =
      Math.round((newOrderTotal - (order?.paidAmount || 0)) * 100) / 100;
    return [taxDeduction, newOrderTotal, paymentDue];
  }, [order, newDiscount]);
  const filteredCoupons = coupons.filter(
    (_item) =>
      !_item.productID &&
      order?.coupons.every((_coupon) => JSON.parse(_coupon)?.id !== _item.id)
  );
  const shouldShowCouponsPage =
    order?.paymentStatus === 'unpaid' &&
    (!['outForDelivery', 'delivered'].includes(order?.status) ||
      filteredCoupons?.length > 0);

  useEffect(() => {
    if (open && order?.shop?.id) {
      dispatch({
        type: 'getCouponsByShop',
        payload: { shopID: order.shop.id, currentOnly: true }
      });
    }
  }, [order, open]);

  useEffect(() => {
    if (open)
      if (!shouldShowCouponsPage) setShowPayment(true);
      else setShowPayment(false);
  }, [open, coupons]);

  // for applying coupon
  const handleApplyCouponClick = (couponID = '') => () => {
    const subTotal = getOrderTotal(order);
    const coupon = coupons.find((_item) => _item.id === couponID);
    const conditionMet =
      coupon.conditionUnit === 'amount'
        ? subTotal >= coupon.conditionAmount
        : false;

    if (!conditionMet) {
      enqueueSnackbar('Not Applicable', {
        variant: 'error',
        autoHideDuration: 2000
      });
      return;
    }

    let discount = 0;
    if (coupon.discountUnit === 'percentage')
      discount = parseFloat((subTotal * coupon.discountAmount) / 100);
    else if (coupon.discountUnit === 'amount')
      discount = parseFloat(coupon.discountAmount);

    // updating cart discounts
    setNewDiscount((_prev) => _prev + discount);
    setNewAppliedCoupons((_prev) => [
      ..._prev,
      {
        ...omit(coupon, [
          'updatedAt',
          '_deleted',
          '_lastChangedAt',
          '_version'
        ]),
        discountAmountApplied: discount
      }
    ]);
  };

  // for cancelling a applied coupon
  const handleClearCouponClick = (couponID = '') => () => {
    const coupon = coupons.find((_item) => _item.id === couponID);
    const currCoupon = newAppliedCoupons.find((item) => item.id === coupon.id);

    // updating cart discounts
    setNewDiscount((_prev) => _prev - (currCoupon?.discountAmountApplied || 0));
    setNewAppliedCoupons((_prev) =>
      _prev.filter((item) => item.id !== coupon.id)
    );
  };

  // for going to payment page
  const handleOrderUpdateSuccess = (isRefunded = false) =>
    history.push(
      `${routes.customer.orderPayment}?orderID=${order.orderID}&refunded=${
        isRefunded ? 'true' : 'false'
      }`
    );

  // for handling submit
  const handleSubmit = () => {
    if (!user?.id) {
      enqueueSnackbar(
        'Something went wrong..! Please refresh and try again...',
        { variant: 'error', autoHideDuration: 3000 }
      );
      return;
    }
    const payloadOrder = {
      id: order.id,
      status: order.status,
      _version: order._version,
      discount: Math.round(((order.discount || 0) + newDiscount) * 100) / 100,
      coupons: [
        ...(order.coupons || []),
        ...newAppliedCoupons.map((_item) => JSON.stringify(_item))
      ],
      tax: Math.round((order.tax - taxDeduction) * 100) / 100,
      total: Math.round(newOrderTotal * 100) / 100
    };

    if (order?.paidAmount > 0) handleOrderUpdateSuccess(true);
    else if (payloadOrder.total > order.onHoldPaymentAmount) {
      if (newDiscount > 0)
        ordersDispatch({
          type: 'updateOrder',
          payload: {
            order: payloadOrder,
            successCallback: handleOrderUpdateSuccess
          }
        });
      else handleOrderUpdateSuccess();
    } else
      ordersDispatch({
        type: 'updateOrderAndConfirmCardPayment',
        payload: {
          order: payloadOrder,
          orderID: payloadOrder.id,
          customerID: user.id,
          skipUpdate: newDiscount <= 0,
          successCallback: handleClose,
          failureCallback: handleOrderUpdateSuccess
        }
      });
  };

  const getPaymentNote = () =>
    !order
      ? ''
      : order.paidAmount > 0
      ? `You need to pay a total of ${showPrice(
          paymentDue
        )}. The previously authorised amount of ${showPrice(
          order.onHoldPaymentAmount
        )} has already been captured by the shop admin.`
      : newOrderTotal > order.onHoldPaymentAmount
      ? `You need to pay ${showPrice(
          newOrderTotal
        )} in total. The previous payment of ${showPrice(
          order.onHoldPaymentAmount
        )} amount will be refunded.`
      : newOrderTotal < order.onHoldPaymentAmount
      ? `The ${showPrice(
          order.onHoldPaymentAmount
        )} previously authorized will be used to cover the payment, and the remaining ${showPrice(
          order.onHoldPaymentAmount - newOrderTotal
        )} will be refunded`
      : `Click Pay to finalize the payment. The total amount of ${showPrice(
          order.total
        )} has been authorised.`;

  const handleClosePaymentModal = () => {
    setNewDiscount(0);
    setNewAppliedCoupons([]);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={handleClosePaymentModal}
      aria-labelledby="order-payment-dialog">
      <DialogTitle
        disableTypography
        id="order-payment-dialog"
        className={classes.dialogHeader}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5" className={classes.orderId}>
              {order?.orderID}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClosePaymentModal} size="small">
              <CancelIcon style={{ color: '#fff' }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {!showPayment ? (
          <Grid container className={classes.contentMain}>
            {filteredCoupons?.length > 0 ? (
              <Grid container>
                <Typography variant="h5" className={classes.couponsHeading}>
                  Coupons
                </Typography>
              </Grid>
            ) : null}
            <Grid container className={classes.couponsContent}>
              {!loading ? (
                filteredCoupons?.length > 0 ? (
                  filteredCoupons.map((item, index) => {
                    const appliedCoupon = newAppliedCoupons.find(
                      (_item) => _item.id === item.id
                    );

                    return (
                      <Grid
                        container
                        key={`order-payment-card-coupons-items-${index}`}
                        className={clsx(
                          classes.coupon,
                          appliedCoupon && classes.appliedCoupon
                        )}>
                        <Grid item container direction="column" xs={12} sm={8}>
                          <Typography
                            variant="subtitle1"
                            className={classes.couponTitle}>
                            {item.title || ''}
                          </Typography>
                          <Typography variant="body2">
                            {item.description || ''}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          container
                          className={classes.couponTail}>
                          {!!appliedCoupon ? (
                            <Typography
                              variant="body1"
                              className={classes.boldText}>
                              {`Discount (-${showPrice(
                                appliedCoupon.discountAmountApplied
                              )})`}
                            </Typography>
                          ) : null}
                          <Button
                            size="small"
                            className={clsx(
                              classes.couponBtn,
                              appliedCoupon
                                ? classes.clearBtn
                                : classes.applyBtn
                            )}
                            onClick={
                              !!appliedCoupon
                                ? handleClearCouponClick(item.id)
                                : handleApplyCouponClick(item.id)
                            }
                            variant="contained">
                            {!!appliedCoupon ? 'Cancel' : 'Apply'}
                          </Button>
                        </Grid>
                      </Grid>
                    );
                  })
                ) : null
              ) : (
                <Grid container justify="center" alignItems="center">
                  <CircularProgress />
                </Grid>
              )}
            </Grid>

            {['itemized', 'inProgress', 'readyForDelivery'].includes(
              order?.status
            ) &&
            order.paymentStatus !== 'paid' &&
            order.shop?.deliveryHrs?.length > 0 ? (
              <Grid container className={classes.contentMain}>
                <Grid item xs={12}>
                  <Typography variant="h5" className={classes.couponsHeading}>
                    Mode of Delivery
                  </Typography>
                </Grid>
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  className={classes.coupon}>
                  <Grid item>
                    <Typography variant="h6" className={classes.boldText}>
                      Considering a change in your delivery method?
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Button
                      size="small"
                      variant="contained"
                      style={{ backgroundColor: '#1f6893', color: '#fff' }}
                      onClick={() => setShowEditDeliveryDialog(true)}>
                      Edit Delivery
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        ) : (
          <Grid container>
            <Grid item style={{ margin: '0.5rem 0 1rem', width: '100%' }}>
              {/* subtotal */}
              <Grid
                container
                justify="space-between"
                className={classes.smallBottomMargin}>
                <Grid item>
                  <Typography variant="h5" className={classes.boldText}>
                    Subtotal:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h5" className={classes.boldText}>
                    {showPrice(getOrderTotal(order) || 0)}
                  </Typography>
                </Grid>
              </Grid>
              {/* discount */}
              <Grid
                container
                justify="space-between"
                className={classes.smallBottomMargin}>
                <Grid item>
                  <Typography variant="h5" className={classes.boldText}>
                    Discount:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h5" className={classes.boldText}>
                    -{showPrice(order?.discount + newDiscount)}
                  </Typography>
                </Grid>
              </Grid>
              {/* tax */}
              {process.env.REACT_APP_VAT === 'enabled' ? (
                <Grid
                  container
                  justify="space-between"
                  className={classes.smallBottomMargin}>
                  <Grid item>
                    <Typography variant="h5" className={classes.boldText}>
                      VAT (20%):
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" className={classes.boldText}>
                      {showPrice(order?.tax - taxDeduction)}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
              {/* collection and delivery fee */}
              <Grid
                container
                justify="space-between"
                className={classes.smallBottomMargin}>
                <Grid item>
                  <Typography variant="h5" className={classes.boldText}>
                    Collection and Delivery fee:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h5" className={classes.boldText}>
                    {showPrice(order?.collectionAndDeliveryFee)}
                  </Typography>
                </Grid>
              </Grid>
              {/* already paid */}
              {order?.paidAmount > 0 ? (
                <Grid
                  container
                  justify="space-between"
                  className={classes.paymentDue}>
                  <Grid item>
                    <Typography variant="h5" className={classes.colorBoldText}>
                      Already Paid:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" className={classes.boldText}>
                      {showPrice(order.paidAmount)}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
              {/* payment due */}
              <Grid
                container
                justify="space-between"
                className={classes.paymentDue}>
                <Grid item>
                  <Typography variant="h5" className={classes.colorBoldText}>
                    Payment Due:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h5" className={classes.boldText}>
                    {showPrice(paymentDue)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Typography className={classes.paymentTextNote}>
                Note: {getPaymentNote()}
              </Typography>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <Grid container justify="center">
        <DialogActions>
          {showPayment && shouldShowCouponsPage ? (
            <Button
              size="small"
              onClick={() => setShowPayment(false)}
              disabled={loading}
              variant="contained">
              Back
            </Button>
          ) : null}

          {showPayment ? (
            <Button
              size="small"
              onClick={handleSubmit}
              disabled={loading}
              variant="contained"
              color="primary">
              Pay {showPrice(paymentDue)}
            </Button>
          ) : (
            <Button
              size="small"
              onClick={() => setShowPayment(true)}
              disabled={loading}
              variant="contained"
              color="primary">
              Next
            </Button>
          )}
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default OrderPaymentDialog;
