import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    fontSize: 64,
    color: 'green'
  },
  text: {
    textAlign: 'center',
    margin: '1rem 0 2rem',
    lineHeight: '1.5rem'
  }
}));
const TELEPHONE_NUMBER = '07771038880';
const EMAIL = 'info@launder-it.co.uk';

const FormSubmitted = () => {
  const classes = useStyles();
  const history = useHistory();

  const closeTabHandler = () => {
    history.replace('/home')
  };

  return (
    <Grid container justify="center" alignItems="center">
      <Grid container item xs={12} justify="center">
        <CheckCircleIcon className={classes.icon} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.text}>
          Thank you for registering! We truly appreciate your interest in our
          services. Your registration means a lot to us, and we're excited to
          have you on board. Rest assured, our team will verify all the details
          you've provided to ensure a smooth experience. We'll contact you via
          email soon to provide further details and assistance.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.text}>
          For assistance, kindly reach out to us at
          <b> {TELEPHONE_NUMBER}</b> or via email at
          <b> {EMAIL}.</b>
        </Typography>
      </Grid>
      <Button variant="contained" color="primary" onClick={closeTabHandler}>
        Close
      </Button>
    </Grid>
  );
};

export default FormSubmitted;
