import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    main: '#143A69',
    secondary: '#6D8FB4'
  },
  secondary: {
    contrastText: white,
    main: '#194376'
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  yellow: {
    contrastText: white,
    main: '#EDD242'
  },
  blue: {
    contrastText: white,
    main: '#46C6CE',
    secondary: '#A2D1D7'
  },
  grey: {
    contrastText: white,
    main: '#C4C4C4',
    secondary: '#979797'
  },
  favourite: {
    main: '#f73378',
    contrastText: black
  },
  text: {
    primary: black,
    secondary: '#C4C4C4'
  },
  divider: colors.grey[200]
};
