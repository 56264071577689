import React from 'react';
import { Grid, GridListTile } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  listTileRoot: {
    width: 'calc(100vw * 0.9) !important',
    height: '100% !important',
    borderBottom: '2px solid #ccc',
    padding: '2px 2px 12px !important',
    [theme.breakpoints.up('sm')]: {
      width: '13.875rem !important',
      borderBottom: 'none',
      padding: '2px !important'
    }
  }
}));

const ShopWrapper = React.forwardRef(
  ({ children, lg = false, ...rest }, ref) => {
    const classes = useStyles();

    return lg ? (
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} {...rest}>
        {children}
      </Grid>
    ) : (
      <GridListTile
        classes={{ root: classes.listTileRoot }}
        {...rest}
        ref={ref}>
        {children}
      </GridListTile>
    );
  }
);
export default ShopWrapper;
