const productItem = `{
    id
    price
    priceRange
    unit
    name
    codeName
    noOfPieces
    description
    itemID
    serviceID
    service {
        id
        name
    }
    item {
        id
        name
        description
        image
        codeName
    }
    image
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
}`;

const getProductByShop = /* GraphQL */ `
  query GetProductByShop(
    $shopID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProductByShop(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${productItem}
      nextToken
      startedAt
      __typename
    }
  }
`;

export default { productItem, getProductByShop };
