import React from 'react';
import { FormControlLabel, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  unCheckedStyle: {
    marginLeft: 0,
    border: '2px solid #ccc',
    borderRadius: 8,
    padding: '0 2rem 0 1rem',
    backgroundColor: '#f5f5f5',
    [theme.breakpoints.down('sm')]: {
      width: '60%',
      marginRight: 0,
      padding: '0 1rem 0 0.5rem'
    }
  },
  checkedStyle: {
    border: '2px solid #46c6ce !important',
    borderRadius: '8 !important',
    backgroundColor: '#e6faff !important'
  },
  boldText: {
    fontWeight: 600
  },
  radioRoot: {
    padding: 5
  },
  radioChecked: {
    '&.Mui-checked': {
      color: '#46c6ce'
    }
  }
}));

// TODO Only used in Checkout page
const StyledFormControlLabel = ({
  value = '',
  checked = false,
  label = '',
  disabled = false,
  onChange = () => {},
  ...rest
}) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      value={value}
      classes={{
        root: classes.unCheckedStyle,
        label: classes.boldText
      }}
      className={checked ? classes.checkedStyle : ''}
      control={
        <Radio
          color="primary"
          classes={{
            root: classes.radioRoot,
            checked: classes.radioChecked
          }}
        />
      }
      label={label}
      disabled={disabled}
      onChange={onChange}
      {...rest}
    />
  );
};

export default StyledFormControlLabel;
