import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  quantityIncDecBtnRoot: {
    minWidth: 'fit-content'
  },
  quantityIncBtn: {
    borderRadius: '0px 6px 6px 0px',
    padding: '0px 6px'
  },
  quantityDecBtn: {
    borderRadius: '6px 0px 0px 6px',
    padding: '0px 6px'
  },
  quantityValue: {
    borderRadius: '0px',
    padding: '0px 11px',
    fontWeight: 700,
    cursor: 'default',
    pointerEvents: 'none'
  }
}));

const QuantityUpDownButtons = ({
  disabled = false,
  getItemQuantity = () => {},
  increaseQuantity = () => {},
  decreaseQuantity = () => {},
  className = ''
}) => {
  const classes = useStyles();

  return (
    <>
      <Button
        variant="outlined"
        onClick={decreaseQuantity}
        disabled={disabled || getItemQuantity() === 0}
        classes={{ root: clsx(classes.quantityIncDecBtnRoot, className) }}
        className={classes.quantityDecBtn}>
        -
      </Button>
      <Button
        variant="outlined"
        classes={{
          root: clsx(classes.quantityIncDecBtnRoot, className)
        }}
        className={classes.quantityValue}>
        {getItemQuantity()}
      </Button>
      <Button
        variant="outlined"
        onClick={increaseQuantity}
        disabled={disabled}
        classes={{ root: clsx(classes.quantityIncDecBtnRoot, className) }}
        className={classes.quantityIncBtn}>
        +
      </Button>
    </>
  );
};

QuantityUpDownButtons.propTypes = {
  getItemQuantity: PropTypes.func.isRequired,
  increaseQuantity: PropTypes.func.isRequired,
  decreaseQuantity: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default QuantityUpDownButtons;
