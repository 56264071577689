import React, { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { createCustomerReview } from 'graphql/customMutations';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useOrders } from 'context/order/ordersContext';
import { useShops } from 'context/shop/shopsContext';
import { StyledRating } from 'components/molecules';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '2.5rem'
  },
  icon: {
    marginRight: '1rem',
    '@media (max-width:400px)': {
      marginRight: 0
    }
  },
  ratingTitleContainer: {
    backgroundColor: '#1f6893',
    marginBottom: '0.3rem',
    borderRadius: '4px 4px 0 0'
  },
  ratingTitleText: {
    fontWeight: 600,
    color: '#fff',
    fontSize: '1rem',
    textAlign: 'center'
  },
  ratingText: {
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'space-around'
    }
  }
}));

const ratingCategories = [
  {
    label: "Rate your laundered items' cleanliness and freshness",
    name: "Rate your laundered items' cleanliness and freshness"
  },
  {
    label: 'Rate how quickly we delivered your order',
    name: 'Rate how quickly we delivered your order'
  },
  {
    label: 'Rate your satisfaction with our laundry service',
    name: 'Rate your satisfaction with our laundry service'
  },
  {
    label:
      'Rate your likelihood to recommend our laundry service to friends or family',
    name:
      'Rate your likelihood to recommend our laundry service to friends or family'
  }
];

function OrderRatingDialog({
  order = {},
  open = false,
  handleModalClose = () => {}
}) {
  const classes = useStyles();
  const [ratings, setRatings] = useState({});
  const { dispatch } = useOrders();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedShop, dispatch: shopDispatch } = useShops();

  const isRated =
    Object.keys(ratings).filter((item) => typeof ratings[item] === 'number')
      .length >= 4;

  const successOrderDelivery = () => {
    enqueueSnackbar(
      'Thank you! Your order has been successfully moved to the order history.',
      {
        variant: 'success',
        autoHideDuration: 3000
      }
    );
  };

  const orderDeliveredHandler = () => {
    const updateOrderPayload = {
      order: { ...order },
      sts: 'delivered',
      successCallback: successOrderDelivery
    };
    dispatch({
      type: 'updateOrder',
      payload: updateOrderPayload
    });
  };

  const handleClose = () => {
    order?.status !== 'delivered' && orderDeliveredHandler();
    handleModalClose();
    setRatings({});
  };

  const handleChange = (e, newVal) => {
    if (e.target.name === 'description') {
      setRatings({ ...ratings, description: e.target.value });
    } else {
      setRatings({ ...ratings, [e.target.name]: newVal });
    }
  };

  const onSubmitOrderRating = async () => {
    const ratingsData = {
      description: ratings.description,
      ratings: Object.keys(ratings)
        .map((item) => {
          if (item !== 'description') {
            return {
              title: item,
              rating: ratings[item]
            };
          }
          return null;
        })
        .filter((item) => item)
    };
    try {
      const resp = await API.graphql(
        graphqlOperation(createCustomerReview, {
          input: {
            ...ratingsData,
            userID: order.userDetail.id,
            shopID: order.shopID,
            orderID: order.id
          }
        })
      );
      const data = resp.data.createCustomerReview;
      dispatch({
        type: 'updateOrderInContext',
        payload: { order: { ...order, customerReview: { items: [data] } } }
      });
      shopDispatch({
        type: 'updateShop',
        payload: {
          ...selectedShop,
          reviews: {
            items: [...(selectedShop.reviews?.items || []), data]
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
    order?.status !== 'delivered' && orderDeliveredHandler();
    setRatings({});
    handleModalClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle
        id="alert-dialog-title"
        className={classes.ratingTitleContainer}>
        <Typography className={classes.ratingTitleText}>
          {'Please take a moment to share your valuable feedback with us'}
        </Typography>
      </DialogTitle>

      <DialogContent>
        {ratingCategories.map((item, index) => (
          <Grid
            item
            xs={12}
            key={`order-ratings-categories-${index}`}
            style={{ margin: '0.5rem 0 0.75rem' }}>
            <Typography variant="h6" style={{ marginBottom: '0.3rem' }}>
              {`${index + 1}. ${item.label}`}
            </Typography>
            <StyledRating
              name={item.name}
              style={{ marginBottom: '0.5rem' }}
              value={ratings[item.name] || 0}
              onChange={handleChange}
            />
            <Divider />
          </Grid>
        ))}
        <TextField
          variant="outlined"
          placeholder="Write your review"
          label="Provide Feedback"
          name="description"
          value={ratings.description}
          onChange={handleChange}
          multiline
          inputProps={{
            maxLength: 1500
          }}
          rows={4}
          fullWidth
          style={{ margin: '0.625rem 0' }}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={handleClose} autoFocus>
          Close
        </Button>
        <Button
          variant="contained"
          onClick={onSubmitOrderRating}
          color="primary"
          disabled={!isRated}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OrderRatingDialog;
