import React, { useState } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/core/styles';
import validate from 'validate.js';
import { chartjs } from './helpers';
import adminTheme from './theme/AdminTheme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import ReactGA from 'react-ga4';
import { ServicesProvider } from './views/Services/serviceContext';
import { ShopsProvider } from './context/shop/shopsContext';
import { RedirectsProvider } from './context/redirect/redirectsContext';
import { ProductsProvider } from 'context/products/productsContext';
import { LoaderProvider } from './layouts/loaderContext';
import { OrdersProvider } from './context/order/ordersContext';
import { SearchBannerProvider } from 'context/searchBanner/searchBannerContext';
import { CouponsProvider } from 'context/coupons/couponsContext';
import { MessagesProvider } from 'context/messages/messagesContext';
import { CustomerContextProvider } from 'context/customers/customersContext';
import { FavouriteShopsProvider } from 'context/favouriteShops/favouriteShopsContext';
import { AuctionsProvider } from 'context/auctions/auctionsContext';
import { SnackbarProvider } from 'notistack';
import { CartProvider } from 'context/cart/cartContext';
import { AdsProvider } from 'context/ads/adsContext';
import { AmplifyAuthProvider } from 'context';
import { SelectContextProvider } from 'store';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarCloseButton } from 'components/molecules';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { NotificationsProvider } from 'context/notifications/notificationsContext';

// initializing react google analytics
ReactGA.initialize('G-JTTPYYHX1V');

// initializing history
const browserHistory = createBrowserHistory();

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

const useStyles = makeStyles(() => ({
  root: { fontSize: '4rem  !important' },
  success: { backgroundColor: 'purple', fontSize: '1rem  !important' },
  error: { backgroundColor: 'blue', fontSize: '1rem  !important' },
  info: { fontSize: '1rem  !important' },
  warning: { color: '#000 !important', fontSize: '1rem  !important' }
}));

const App = () => {
  const classes = useStyles();
  const [authState, setAuthState] = useState({
    isLoggedIn: false,
    isEmailVerified: false
  });
  const childProps = {
    isLoggedIn: authState.isLoggedIn,
    isEmailVerified: authState.isEmailVerified,
    onUserSignIn: (isEmailVerified = false) =>
      setAuthState({ isLoggedIn: true, isEmailVerified })
  };

  return (
    <Elements stripe={stripePromise}>
      <ThemeProvider theme={adminTheme}>
        <SnackbarProvider
          action={(snackbarKey) => (
            <SnackbarCloseButton snackbarKey={snackbarKey} />
          )}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          classes={{
            root: classes.root,
            variantSuccess: classes.success,
            variantError: classes.error,
            variantWarning: classes.warning,
            variantInfo: classes.info
          }}
          maxSnack={5}
          TransitionComponent={Slide}>
          <LoaderProvider>
            <AmplifyAuthProvider>
              <ServicesProvider>
                <NotificationsProvider>
                  <MessagesProvider>
                    <OrdersProvider>
                      <ShopsProvider>
                        <FavouriteShopsProvider>
                          <ProductsProvider>
                            <SelectContextProvider>
                              <CartProvider>
                                <RedirectsProvider>
                                  <AdsProvider>
                                    <AuctionsProvider>
                                      <CouponsProvider>
                                        <CustomerContextProvider>
                                          <SearchBannerProvider>
                                            <Router history={browserHistory}>
                                              <Routes childProps={childProps} />
                                            </Router>
                                          </SearchBannerProvider>
                                        </CustomerContextProvider>
                                      </CouponsProvider>
                                    </AuctionsProvider>
                                  </AdsProvider>
                                </RedirectsProvider>
                              </CartProvider>
                            </SelectContextProvider>
                          </ProductsProvider>
                        </FavouriteShopsProvider>
                      </ShopsProvider>
                    </OrdersProvider>
                  </MessagesProvider>
                </NotificationsProvider>
              </ServicesProvider>
            </AmplifyAuthProvider>
          </LoaderProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </Elements>
  );
};

export default App;
