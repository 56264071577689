/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMessageNotification = /* GraphQL */ `
  subscription OnCreateMessageNotification(
    $userID: String
    $shopID: String
    $orderID: String
  ) {
    onCreateMessageNotification(
      userID: $userID
      shopID: $shopID
      orderID: $orderID
    ) {
      id
      userID
      shopID
      orderID
      messageID
      isRead
      createdAt
      updatedAt
      message {
        id
        orderID
        sender
        shopID
        message
        messageIntent
        photo {
          name
          photoURL
          thumbURL
          MIMETypes
          __typename
        }
        status
        notificationID
        readAt
        createdAt
        order {
          id
          shopID
          orderID
          bidID
          userID
          status
          createdAt
          updatedAt
          updatedBy
          paymentStatus
          paymentMethod
          orderCancelOrDeclineReason
          total
          variableTotal
          discount
          tax
          noOfPieces
          paidAmount
          onHoldPaymentIntentID
          onHoldPaymentAmount
          coupons
          collectionAndDeliveryFee
          itemizedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        notification {
          id
          userID
          shopID
          orderID
          messageID
          isRead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        updatedAt
        senderDetails {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          stripeID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification($userID: String, $shopID: String) {
    onCreateNotification(userID: $userID, shopID: $shopID) {
      id
      userID
      shopID
      message
      isRead
      type {
        variant
        modelID
        model
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateCounters = /* GraphQL */ `
  subscription OnCreateCounters {
    onCreateCounters {
      id
      counterName
      currentValue
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCounters = /* GraphQL */ `
  subscription OnUpdateCounters {
    onUpdateCounters {
      id
      counterName
      currentValue
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
