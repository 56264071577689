import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import {
  KeyboardTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      padding: '0 1rem'
    }
  },
  mobileMargin: {
    margin: '1rem 0',
    [theme.breakpoints.up('md')]: {
      margin: 0
    }
  }
}));
const ERROR_COLOR = '#FF6666';

const OrderTimeEditBar = ({
  heading = '',
  dialogType = '',
  date = null,
  time = null,
  setTime,
  setDate,
  isError = '',
  isScheduleOrder = true
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  useEffect(() => {
    initialTime();
  }, [time]);

  const initialTime = () => {
    if (!!time.start) {
      const { start } = time;
      setStartTime(moment(start, ['h:mmA']).utc().format());
    } else {
      setStartTime(null);
    }

    if (!!time.end) {
      const { end } = time;
      setEndTime(moment(end, ['h:mmA']).utc().format());
    } else {
      setEndTime(null);
    }
  };

  const startTimeHandler = (e) => {
    setStartTime(e.currentTarget.value);
    setTime({ ...time, start: moment(e.currentTarget.value).format('h:mmA') });
  };

  const endTimeHandler = (e) => {
    setEndTime(e.currentTarget.value);
    setTime({ ...time, end: moment(e.currentTarget.value).format('h:mmA') });
  };

  const showTimeFormatSnackbar = () => {
    enqueueSnackbar(`Enter time in 12hrs format`, {
      variant: 'info',
      preventDuplicate: true,
      autoHideDuration: 2000
    });
  };

  const collectionAndDeliveryType = isScheduleOrder
    ? heading === 'Pick Up'
      ? 'Collection'
      : 'Delivery'
    : heading === 'Pick Up'
    ? 'Drop'
    : 'Pickup';

  return (
    <Grid
      container
      justify="flex-start"
      alignItems="flex-end"
      className={classes.root}>
      <Grid container item xs={6} sm={3} md={4} lg={2} alignItems="flex-end">
        <ExitToAppIcon fontSize="small" />
        <Typography
          style={{
            marginLeft: '0.2rem',
            fontWeight: 600,
            color: '#194376'
          }}>
          {collectionAndDeliveryType}
        </Typography>
      </Grid>
      <Grid container item xs={12} md={8} lg={9}>
        <form noValidate>
          <Grid container alignItems="flex-end" justify="space-between">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item md={3} xs={12}>
                <KeyboardDatePicker
                  id="date-picker-dialog"
                  label="Start Date"
                  format="DD/MM/YYYY"
                  value={date || null}
                  disabled={
                    dialogType === 'readyForDelivery' && heading === 'Pick Up'
                  }
                  onChange={(date) =>
                    setDate(moment(date).format('YYYY-MM-DDThh:mm:ss.sssZ'))
                  }
                  InputProps={{
                    style: {
                      color: isError === 'date' ? ERROR_COLOR : ''
                    }
                  }}
                  InputLabelProps={{
                    shrink: date ? true : false,
                    style: {
                      color: isError === 'date' ? ERROR_COLOR : ''
                    }
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                    style: {
                      color: isError === 'date' ? ERROR_COLOR : ''
                    }
                  }}
                  style={{ margin: '0.5rem 0.4rem 0' }}
                  disablePast={
                    !(
                      dialogType === 'readyForDelivery' && heading === 'Pick Up'
                    )
                  }
                  fullWidth
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={1}
                container
                justify="center"
                className={classes.mobileMargin}>
                <Typography style={{ margin: '0 1rem' }}>between</Typography>
              </Grid>

              <Grid item md={3} xs={5}>
                <KeyboardTimePicker
                  id="time-picker"
                  label="Start Time"
                  value={startTime}
                  disabled={
                    dialogType === 'readyForDelivery' && heading === 'Pick Up'
                  }
                  onChange={(time) =>
                    startTimeHandler({
                      currentTarget: {
                        value: moment(time).utc().format()
                      }
                    })
                  }
                  onFocus={showTimeFormatSnackbar}
                  InputLabelProps={{
                    shrink: startTime ? true : false,
                    style: {
                      color:
                        isError === heading || isError === 'oneHourGap'
                          ? ERROR_COLOR
                          : ''
                    }
                  }}
                  InputProps={{
                    style: {
                      color:
                        isError === heading || isError === 'oneHourGap'
                          ? ERROR_COLOR
                          : ''
                    }
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                    style: {
                      color: isError === heading ? ERROR_COLOR : ''
                    }
                  }}
                  style={{ margin: '0.5rem 0.6rem 0' }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={1} container justify="center">
                <Typography style={{ margin: '0 1rem' }}>to</Typography>
              </Grid>

              <Grid item md={3} xs={5}>
                <KeyboardTimePicker
                  id="time-picker"
                  label="End Time"
                  value={endTime}
                  disabled={
                    dialogType === 'readyForDelivery' && heading === 'Pick Up'
                  }
                  onChange={(time) =>
                    endTimeHandler({
                      currentTarget: {
                        value: moment(time).utc().format()
                      }
                    })
                  }
                  onFocus={showTimeFormatSnackbar}
                  InputLabelProps={{
                    shrink: endTime ? true : false,
                    style: {
                      color: isError === heading ? ERROR_COLOR : ''
                    }
                  }}
                  InputProps={{
                    style: {
                      color: isError === heading ? ERROR_COLOR : ''
                    }
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                    style: {
                      color: isError === heading ? ERROR_COLOR : ''
                    }
                  }}
                  style={{ margin: '0.5rem 0 0 0.6rem' }}
                  fullWidth
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default OrderTimeEditBar;
