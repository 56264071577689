import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MUIRichTextEditor from 'mui-rte';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    backgroundColor: '#def1f3'
  },
  root: {
    padding: '1.5rem 0',
    gap: '0.25rem',
    maxWidth: '90%',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      padding: '2.5rem 0'
    }
  },
  heading: {},
  editor: {
    color: 'black',
    fontSize: 14,
    fontFamily: 'Roboto'
  }
}));

const AboutShop = ({ currentShop }) => {
  const classes = useStyles();
  const showAbout = JSON.parse(currentShop?.about || '{}')?.blocks?.some(
    (item) => !!item?.text
  );

  const a11yProps = () =>
    !!currentShop?.about
      ? {
          defaultValue: currentShop.about
        }
      : {};

  return showAbout ? (
    <Grid className={classes.rootContainer}>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.heading}>
            About Shop
          </Typography>
        </Grid>
        {currentShop.about && (
          <Grid item xs={12}>
            <MUIRichTextEditor
              classes={{ editor: classes.editor }}
              readOnly
              toolbar={false}
              {...a11yProps()}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  ) : null;
};

export default AboutShop;
