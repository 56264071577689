import React, { useState } from 'react';
import { Button, Typography } from '@material-ui/core';

const ShopReviewDescription = ({ description = '' }) => {
  const [showMore, setShowMore] = useState(false);

  const maxWordLimit = 200;
  const isMoreText = description.length > maxWordLimit;

  const reviewDescription =
    isMoreText && !showMore
      ? description.substring(0, maxWordLimit).concat('...')
      : description;

  return (
    <Typography
      style={{
        margin: '0.75rem 0',
        fontSize: 14,
        lineHeight: '1.2rem'
      }}>
      {reviewDescription || ''}
      {isMoreText ? (
        <Button
          size="small"
          color="primary"
          onClick={() => setShowMore((prev) => !prev)}>
          {!showMore ? 'Read more' : 'Read less'}
        </Button>
      ) : null}
    </Typography>
  );
};

export default ShopReviewDescription;
