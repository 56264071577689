import React, { useState, useEffect } from 'react';
import { Image } from 'components/organisms';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { validateImageAspectRatio } from 'common/validators';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    padding: '1rem 1.5rem'
  },
  content: {
    gap: '0.75rem'
  },
  colorInputWrapper: {
    padding: '0.5rem',
    gap: '1rem',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end'
    }
  },
  bannerContainer: {
    position: 'relative'
  },
  currentBannerTitleText: {
    position: 'absolute',
    top: '30%',
    width: '50%',
    lineHeight: '3rem',
    fontSize: 45,
    fontWeight: 'bold'
  }
}));

const initialBanner = {
  photoURL: '',
  mobilePhotoURL: '',
  file: null,
  file2: null,
  title: '',
  color: '#ffffff'
};

const SearchBannerModal = ({
  open = false,
  banner = { photoURL: '', mobilePhotoURL: '', title: '', color: '#ffffff' },
  handleClose = () => {},
  handleSubmit = () => {}
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [currentBanner, setCurrentBanner] = useState(initialBanner);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (banner?.photoURL) {
      setCurrentBanner(banner);
    } else {
      setCurrentBanner(initialBanner);
    }
  }, [banner]);

  const handleChange = (e) => {
    setDisabled(false);
    setCurrentBanner((prevBanner) => ({
      ...prevBanner,
      [e.target.name]: e.target.value
    }));
  };

  const handleImageChange = (fileName) => (e) => {
    const _file = e.target.files[0];
    if (_file) {
      setDisabled(false);
      validateImageAspectRatio(
        _file,
        fileName === 'file' ? 131 / 50 : 4 / 5,
        (isValid) => {
          if (isValid) {
            const reader = new FileReader();
            reader.onload = () =>
              setCurrentBanner((prevBanner) => ({
                ...prevBanner,
                [fileName]: _file,
                [e.target.name]: reader.result
              }));
            reader.readAsDataURL(_file);
          } else {
            enqueueSnackbar(
              `The uploaded image is not matching the desired ratio.`,
              {
                variant: 'error',
                persist: true
              }
            );
          }
        }
      );
    }
  };

  const handleSubmitClick = () => handleSubmit(currentBanner);

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      onClose={handleClose}
      disableEscapeKeyDown
      aria-labelledby="search-banner-form-dialog-title">
      <DialogTitle
        id="search-banner-form-dialog-title"
        className={classes.title}>
        Add / Edit Search Banner
      </DialogTitle>
      <DialogContent>
        <Grid container className={classes.content}>
          <Grid container>
            <Grid item xs={12} md={9} style={{ padding: '0.5rem' }}>
              <TextField
                variant="outlined"
                label="Enter Title"
                size="small"
                InputLabelProps={{ shrink: true }}
                name="title"
                fullWidth
                value={currentBanner.title}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              container
              justify="flex-end"
              alignItems="center"
              className={classes.colorInputWrapper}>
              <label htmlFor="banner-modal-color-input">Font Color:</label>
              <input
                id="banner-modal-color-input"
                name="color"
                value={currentBanner.color}
                onChange={handleChange}
                type="color"
              />
            </Grid>
          </Grid>
          <Grid container justify="center">
            <input
              accept="image/*"
              id="banner-modal-mobile-photo-input"
              name="mobilePhotoURL"
              onChange={handleImageChange('file2')}
              type="file"
              hidden
            />
            <label htmlFor="banner-modal-mobile-photo-input">
              <Button
                color="primary"
                variant="contained"
                size="small"
                component="span">
                Upload Image for Mobile Screens (4 x 5)
              </Button>
            </label>
          </Grid>
          {currentBanner.mobilePhotoURL ? (
            <Grid container justify="center">
              <Image
                docKey={currentBanner.mobilePhotoURL}
                loadFromStorage={
                  currentBanner.mobilePhotoURL?.split('/').at(0) === 'images'
                }
                alt="Home page mobile image"
                width="100px"
              />
            </Grid>
          ) : null}
          <Grid container justify="center">
            <input
              accept="image/*"
              id="banner-modal-photo-input"
              name="photoURL"
              onChange={handleImageChange('file')}
              type="file"
              hidden
            />
            <label htmlFor="banner-modal-photo-input">
              <Button
                color="primary"
                variant="contained"
                size="small"
                component="span">
                Upload Image for Desktop Screens
              </Button>
            </label>
          </Grid>
          <Grid container justify="center" className={classes.bannerContainer}>
            <Typography
              className={classes.currentBannerTitleText}
              style={{ color: currentBanner?.color }}>
              {currentBanner?.title}
            </Typography>
            {currentBanner.photoURL ? (
              <Image
                docKey={currentBanner.photoURL}
                loadFromStorage={
                  currentBanner.photoURL?.split('/').at(0) === 'images'
                }
                alt="Home page image"
                width="100%"
              />
            ) : null}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSubmitClick}
          color="primary"
          variant="contained"
          disabled={disabled}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SearchBannerModal;
