import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import userFlowTheme from '../../theme/HomeTheme';
import { IconButton, Tooltip } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import {
  Navbar,
  OfferNav,
  HomeFooter,
  Copyright
} from '../../components/organisms';
import Cookies from 'views/Home/components/Cookies';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    minHeight: 'calc(100vh - 255px)',
    [theme.breakpoints.up('md')]: {
      minHeight: 'calc(100vh - 265px)'
    }
  },
  scrollIconButton: {
    display: 'none',
    position: 'fixed',
    bottom: '1rem',
    right: '1rem',
    zIndex: 99,
    boxShadow: '0 0 30px #bbb',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#ccc'
    }
  }
}));

const NonLoggedIn = (props) => {
  const classes = useStyles();
  const [showScrollBtn, setShowScrollBtn] = useState(false);

  const handleScrollEvent = () => {
    if (document.documentElement.scrollTop > 250 && !showScrollBtn)
      setShowScrollBtn(true);
    else setShowScrollBtn(false);
  };

  const handleScrollClick = () =>
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

  useEffect(() => {
    window.addEventListener('scroll', handleScrollEvent);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, []);

  return (
    <ThemeProvider theme={userFlowTheme}>
      <div className={clsx(classes.root)}>
        <OfferNav />
        <Navbar />
        <main className={classes.content}>{props.children}</main>
        <HomeFooter />
        <Copyright />
        <Cookies />
        <Tooltip title="Back to Top" placement="top" arrow>
          <IconButton
            className={classes.scrollIconButton}
            style={{ display: showScrollBtn ? 'block' : 'none' }}
            onClick={handleScrollClick}>
            <ArrowDropUpIcon />
          </IconButton>
        </Tooltip>
      </div>
    </ThemeProvider>
  );
};

NonLoggedIn.propTypes = {
  children: PropTypes.node
};

export default NonLoggedIn;
