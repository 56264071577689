import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { unitFormatter } from 'common/utilFunctions';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '2rem 0 1rem',
    rowGap: '1rem'
  },
  statusContainer: {
    border: '1px solid #444',
    padding: '0.2rem',
    borderRadius: '8px',
    textAlign: 'center',
    color: '#444'
  },
  orderCount: {
    textAlign: 'center',
    width: '100%',
    fontWeight: '500',
    fontSize: 20
  },
  orderStatus: {
    textAlign: 'center',
    width: '100%',
    marginBottom: '0.5rem',
    paddingBottom: '0.2rem',
    borderBottom: '1px solid #444',
    fontSize: 14
  },
  totalOrderText: {
    marginTop: '2rem',
    backgroundColor: '#3f51b5',
    color: '#fff',
    padding: '0.5rem',
    borderRadius: 8,
    width: '100%',
    textAlign: 'center'
  }
}));

const STATUS_AND_COLORS = {
  newOrder: '#FFFFCC',
  accepted: '#CCE6FF',
  itemized: '#FFCCE5',
  inProgress: '#B5EAD7',
  readyForDelivery: '#F5DCA6',
  outForDelivery: '#FFD8B8',
  delivered: '#C6EA9E',
  declined: '#FFB68B',
  cancelled: '#FFE7D3'
};

const ShopOrderMetrics = ({
  filteredOrders,
  filterValue,
  monthRange,
  orders
}) => {
  const classes = useStyles();

  const monthlyOrders = orders?.filter(
    (order) =>
      moment(order.createdAt).format('YYYY-MM') >= monthRange.start &&
      moment(order.createdAt).format('YYYY-MM') <= monthRange.end
  );

  return (
    <>
      <Typography variant="h4" className={classes.totalOrderText}>
        {`For the month of ${moment(monthRange.start, 'YYYY-MM').format(
          'MMMM, YYYY'
        )}`}

        {monthRange.end && monthRange.start !== monthRange.end
          ? ` to ${moment(monthRange.end, 'YYYY-MM').format('MMMM, YYYY')}.`
          : null}

        {` Total ${monthlyOrders.length} orders were received.`}
      </Typography>
      <Grid container justify="space-between" className={classes.root}>
        {Object.keys(STATUS_AND_COLORS).map((item) => {
          const filteredOrderCount = filteredOrders?.filter(
            (order) => order.status === item
          ).length;

          return (
            <Grid
              container
              item
              xs={6}
              sm={3}
              md={1}
              key={`order-${item}`}
              justify="center"
              style={{
                backgroundColor: `${
                  filterValue && filterValue !== item
                    ? '#d9d9d9'
                    : STATUS_AND_COLORS[item]
                }`
              }}
              className={classes.statusContainer}>
              <Typography variant="h5" className={classes.orderStatus}>
                {unitFormatter(item)}
              </Typography>

              <Typography className={classes.orderCount}>
                {filteredOrderCount}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default ShopOrderMetrics;
