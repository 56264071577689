import React from 'react';
import GoogleMapReact from 'google-map-react';
import { Marker } from 'components/molecules';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '12rem',
    width: '100%',
    [theme.breakpoints.up('md')]: { height: '6rem' },
    [theme.breakpoints.up('lg')]: { height: '10rem' }
  }
}));

function createMapOptions(maps) {
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.LEFT_TOP,
      style: maps.ZoomControlStyle.SMALL
    }
  };
}

const Map = (props) => {
  const classes = useStyles();
  const { state } = props;

  const defaultProps = {
    center: {
      lat: 51.53314,
      lng: -0.057032
    },
    zoom: 14
  };

  const storeLocation = {
    lat: state?.location?.lat || 51.53314,
    lng: state?.location?.lng || -0.057032
  };

  return (
    // Important! Always set the container height explicitly
    <div className={classes.root}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
        center={storeLocation}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={createMapOptions}>
        <Marker
          lat={state?.location?.lat || ''}
          lng={state?.location?.lng || ''}
          text={state?.name}
        />
      </GoogleMapReact>
    </div>
  );
};

export default Map;
