export const createAnAuction = /* GraphQL */ `
  mutation CreateAnAuction(
    $userID: ID!
    $startDateTime: AWSDateTime!
    $endDateTime: AWSDateTime!
    $collection: String!
    $delivery: String!
    $total: Float
    $postcode: String!
  ) {
    createAnAuction(
      userID: $userID
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      collection: $collection
      delivery: $delivery
      total: $total
      postcode: $postcode
    ) {
      id
      auctionID
      userID
      createdAt
      startDateTime
      endDateTime
      collection {
        date
        time
        type
        address
        driver {
          userID
          firstName
          lastName
          title
          email
          phoneNumber
        }
        instructions
      }
      delivery {
        date
        time
        type
        address
        driver {
          userID
          firstName
          lastName
          title
          email
          phoneNumber
        }
        instructions
      }
      postcode
      isClosed
      total
      items {
        items {
          id
          auctionID
          serviceID
          name
          description
          quantity
          image
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      bids {
        items {
          id
          auctionID
          shopID
          createdBy
          createdAt
          totalPrice
          shippingCharges
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      shops {
        items {
          id
          shopID
          auctionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      updatedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        stripeID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        updatedAt
        favouriteShops {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
