import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useOrders } from 'context/order/ordersContext';
import SearchShop from 'components/SearchShop';
import AdminOrdersTable from 'components/molecules/AdminOrdersTable';
import {
  ShopOverAllMetrics,
  ShopsPerformanceOrderFilters,
  ShopOrderMetrics
} from './Components';
import { useLoader } from 'layouts/loaderContext';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  searchContainer: {
    margin: '1rem 0'
  }
}));

const ShopsPerformance = () => {
  const classes = useStyles();
  const { orders, dispatch } = useOrders();
  const { loading } = useLoader();
  const [selectedShop, setSelectedShop] = useState(null);
  const [page, setPage] = useState(0);
  const [filterValue, setFilterValue] = useState('');
  const currentDate = new Date();
  const currentMonthYear = `${currentDate.getFullYear()}-${(
    currentDate.getMonth() + 1
  )
    .toString()
    .padStart(2, '0')}`;

  const [monthRange, setMonthRange] = useState({
    start: currentMonthYear,
    end: currentMonthYear
  });

  useEffect(() => {
    if (selectedShop) {
      dispatch({
        type: 'getOrders',
        payload: {
          shopId: selectedShop?.id,
          newQuery: true
        }
      });
    }
  }, [selectedShop]);

  const handleShopChange = (e, newVal) => {
    setSelectedShop(newVal);
    setPage(0);
  };
  const statusFilterHandler = (e, val) => {
    setFilterValue(e.target.value);
    setPage(0);
  };
  const clearStatusFilter = () => setFilterValue('');
  const handleMonthChange = (e) => {
    setMonthRange({
      ...monthRange,
      [e.target.name]: e.target.value
    });
    setPage(0);
  };
  const resetHandler = () => {
    setFilterValue('');
    setMonthRange({
      start: currentMonthYear,
      end: currentMonthYear
    });
  };

  const filteredOrders = orders
    ?.filter((order) => (filterValue ? order.status === filterValue : true))
    .filter(
      (order) =>
        moment(order.createdAt).format('YYYY-MM') >= monthRange.start &&
        moment(order.createdAt).format('YYYY-MM') <= monthRange.end
    );

  return (
    <Grid container className={classes.root}>
      <Grid item>
        <Typography variant="h3">Shops Performance</Typography>
      </Grid>

      <Grid
        item
        container
        alignItems="center"
        className={classes.searchContainer}>
        <SearchShop onChange={handleShopChange} value={selectedShop} />
      </Grid>

      {selectedShop ? <ShopOverAllMetrics selectedShop={selectedShop} /> : null}

      {selectedShop && orders.length > 1 && !loading ? (
        <ShopsPerformanceOrderFilters
          filterValue={filterValue}
          statusFilterHandler={statusFilterHandler}
          clearStatusFilter={clearStatusFilter}
          monthRange={monthRange}
          handleMonthChange={handleMonthChange}
          resetHandler={resetHandler}
          currentMonthYear={currentMonthYear}
        />
      ) : null}

      {selectedShop && orders.length > 1 && !loading ? (
        <ShopOrderMetrics
          filteredOrders={filteredOrders}
          filterValue={filterValue}
          monthRange={monthRange}
          orders={orders}
        />
      ) : null}

      <AdminOrdersTable
        selectedShop={selectedShop}
        filterValue={filterValue}
        monthRange={monthRange}
        page={page}
        setPage={setPage}
      />
    </Grid>
  );
};

export default ShopsPerformance;
