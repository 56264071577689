import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Grid,
  Card,
  TextField,
  CardHeader,
  Divider,
  CardContent,
  CardActions
} from '@material-ui/core';
import SearchShop from 'components/SearchShop';
import { useSnackbar } from 'notistack';
import { useLoader } from 'layouts/loaderContext';
import { useAmplifyAuth } from 'context';
import { useShops } from 'context/shop/shopsContext';
import { Link, useHistory, useParams } from 'react-router-dom';
import { routes, maxInputDateForDOB } from 'common/constants';
import { addNewShopUser } from 'graphql/mutations';

const userMandatoryFields = [
  { property: 'email', label: 'Email' },
  { property: 'shopId', label: 'ShopId' },
  { property: 'title', label: 'Title' },
  { property: 'firstName', label: 'First Name' },
  { property: 'lastName', label: 'Last Name' },
  { property: 'phoneNumber', label: 'Phone Number' },
  { property: 'groups', label: 'User Group' }
  // { property: 'dateOfBirth', label: 'Birth Date' }
];

const initialUserState = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  shopId: '',
  title: 'Mr',
  groups: 'laundryAdmin',
  dateOfBirth: '1970-01-01'
};

const useStyles = makeStyles((theme) => ({
  root: { marginTop: '20px' },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const AddUser = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { shops, dispatch } = useShops();
  const [selectedShop, setSelectedShop] = useState(null);
  const { setLoading } = useLoader();
  const history = useHistory();
  let { shopId: slug } = useParams();
  const [user, setUser] = useState(initialUserState);
  const {
    state: { user: { shopId } = {}, isAdmin }
  } = useAmplifyAuth();

  useEffect(() => {
    if (shopId) {
      dispatch({
        type: 'getSelectedShop',
        payload: { shopId: shopId }
      }).then((data) => {
        setUser({ ...user, shopId: data.id });
      });
    }
  }, [shopId]);

  const addNewUser = async () => {
    let snackBar;
    const _validate = userMandatoryFields.every(({ label, property }) => {
      if (property === 'phoneNumber') {
        if (user[property]?.length !== 13) {
          enqueueSnackbar(
            'Please enter 13 digit contact number with dial code. E.g. +447712345678',
            {
              variant: 'warning',
              preventDuplicate: true,
              persist: true
            }
          );
          return false;
        }
      } else if (!user[property]) {
        enqueueSnackbar(label + ' is a mandatory field', {
          variant: 'warning',
          preventDuplicate: true,
          persist: true
        });
        return !!user[property];
      }
      return !!user[property];
    });
    if (!_validate) {
      return;
    }
    try {
      setLoading(true);
      snackBar = enqueueSnackbar('Saving User data....', {
        variant: 'info',
        persist: true
      });
      await API.graphql(graphqlOperation(addNewShopUser, user));
      enqueueSnackbar('User created successfully', {
        variant: 'success',
        preventDuplicate: true
      });
      setSelectedShop(null);
      setUser(initialUserState);
      history.push(routes.admin.users);
    } catch (error) {
      enqueueSnackbar(error.errors[0]?.message || 'Something went wrong..!', {
        variant: 'error',
        preventDuplicate: true
      });
      console.error('Error', error);
    } finally {
      closeSnackbar(snackBar);
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    if (event.target.name === 'email')
      event.target.value = event.target.value.toLowerCase().trim();
    setUser({
      ...user,
      [event.target.name]: event.target.value
    });
  };

  const handleGroupChange = (event) => {
    setUser({
      ...user,
      groups: event.target.value
    });
  };

  const handleShopChange = (event, newValue) => {
    setSelectedShop(newValue);
    setUser({ ...user, shopId: newValue?.id || '' });
  };

  const titles = [
    {
      value: 'Mr',
      label: 'Mr'
    },
    {
      value: 'Mrs',
      label: 'Mrs'
    },
    {
      value: 'Miss',
      label: 'Miss'
    },
    {
      value: 'Ms',
      label: 'Ms'
    },
    {
      value: 'Mx',
      label: 'Mx'
    },
    {
      value: 'Sir',
      label: 'Sir'
    },
    {
      value: 'Dr',
      label: 'Dr'
    },
    {
      value: 'Lady',
      label: 'Lady'
    },
    {
      value: 'Lord',
      label: 'Lord'
    }
  ];

  useEffect(() => {
    if (slug) {
      const foundShop = shops.find((shop) => shop.id === slug);
      setSelectedShop({ ...foundShop });

      setUser({
        ...user,
        firstName: foundShop?.contactName.split(' ')[0],
        lastName: foundShop?.contactName.split(' ')[1],
        email: foundShop?.email,
        phoneNumber: foundShop?.phoneNumber,
        shopId: slug
      });
    }
  }, [slug]);

  return (
    <div>
      <Grid alignItems="flex-end" container justify="space-between" spacing={2}>
        <Grid item>
          <Typography gutterBottom variant="overline">
            New User
          </Typography>
          <Typography variant="h3">Add User</Typography>
        </Grid>
      </Grid>
      <Card className={classes.root}>
        <form autoComplete="off" noValidate>
          {' '}
          <CardHeader
            subheader="New user onboarding information"
            title="New user"
          />
          <Divider />
          <CardContent>
            {isAdmin && (
              <SearchShop onChange={handleShopChange} value={selectedShop} />
            )}
            <TextField
              fullWidth
              id="title"
              label="Select Title"
              margin="dense"
              select
              SelectProps={{
                native: true
              }}
              variant="outlined">
              {titles.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
            <TextField
              fullWidth
              id="title"
              label="Select Group"
              margin="dense"
              onChange={handleGroupChange}
              select
              SelectProps={{
                native: true
              }}
              value={user?.groups}
              variant="outlined">
              <option value={'laundryAdmin'}>Laundry Admin</option>
            </TextField>
            <TextField
              fullWidth
              label="First Name "
              margin="dense"
              name="firstName"
              onChange={handleChange}
              required
              value={user.firstName}
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Last Name "
              margin="dense"
              name="lastName"
              onChange={handleChange}
              required
              value={user.lastName}
              variant="outlined"
            />
            <Grid container spacing={3}>
              <Grid item xs={6} style={{ marginTop: '6px' }}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  margin="dense"
                  name="phoneNumber"
                  onChange={handleChange}
                  helperText={
                    user.phoneNumber?.length !== 13
                      ? 'Please enter 13 digit contact number with dial code. E.g. +447712345678'
                      : ''
                  }
                  required
                  error={user.phoneNumber?.length !== 13}
                  value={user.phoneNumber}
                  variant="outlined"
                />
              </Grid>
              {!slug && (
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Birth Date"
                    margin="dense"
                    name="dateOfBirth"
                    onChange={handleChange}
                    required
                    value={user.dateOfBirth}
                    type="date"
                    variant="outlined"
                    inputProps={{
                      max: maxInputDateForDOB
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              )}
            </Grid>

            <TextField
              fullWidth
              helperText="Email use as username for login"
              label="Email"
              margin="dense"
              name="email"
              onChange={handleChange}
              required
              value={user.email}
              variant="outlined"
            />
          </CardContent>
          <Divider />
          <CardActions style={{ padding: '16px' }}>
            <Grid container>
              <Link to={routes.admin.users}>
                <Button variant="outlined">Cancel</Button>
              </Link>
              <Button
                color="primary"
                style={{ marginLeft: '10px' }}
                onClick={addNewUser}
                variant="contained">
                Add new user
              </Button>
            </Grid>
          </CardActions>
        </form>
      </Card>
    </div>
  );
};

AddUser.propTypes = {
  className: PropTypes.string
};

export default AddUser;
