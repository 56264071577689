import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  KeyboardTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { unitFormatter } from 'common/utilFunctions';
import {
  CustomSearchBar,
  AddProductModal,
  DocumentDeleteDialog
} from 'components/organisms';
import { QuantityUpDownButtons } from 'components/molecules';
import {
  DATE_FIELDS,
  INITIAL_AUCTION,
  INITIAL_PRODUCT,
  OMMITED_ADDRESS_FIELDS,
  REQUIRED_FIELDS
} from './constants';
import { CartContext } from 'context/cart/cartContext';
import { useServices } from 'views/Services/serviceContext';
import { useAuctions } from 'context/auctions/auctionsContext';
import { useAmplifyAuth } from 'context';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import {
  routes,
  AUCTION_OPEN_ALL_DAY_HRS,
  regexPostcode
} from 'common/constants';
import DateFnsUtils from '@date-io/moment';
import { omit } from 'lodash';
import moment from 'moment';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLoader } from 'layouts/loaderContext';
import RenderAddress from 'views/Review/components/RenderAddress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { API, graphqlOperation } from 'aws-amplify';
import { listAddresss, getAddress } from 'graphql/customQueries';
import AddressModal from 'views/Review/components/AddressModal';
import { Image } from 'components/organisms';

const useStyles = makeStyles((theme) => ({
  dateTimeContainer: {
    padding: '0 0.5rem'
  },
  divider: {
    margin: '1.5rem 1rem'
  },
  rowContainer: {
    padding: '0 1rem'
  },
  productsContainer: {
    gap: '1rem',
    padding: '0.5rem 1rem'
  },
  addButton: {
    padding: '0.5rem 0',
    [theme.breakpoints.up('sm')]: {
      padding: 0
    }
  },
  backdrop: {
    zIndex: 999,
    color: '#fff'
  },
  dialogTitle: {
    fontWeight: 600
  },
  accordionContainer: {
    marginBottom: '0.5rem'
  },
  accordionRoot: {
    width: '100%'
  },
  accordionTitle: {
    fontWeight: 600,
    fontSize: 15
  },
  accordionContent: {
    margin: 0,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  correctIcon: {
    color: theme.palette.success.main
  },
  errorIcon: {
    color: theme.palette.warning.dark,
    animation: `$blink 1s ${theme.transitions.easing.easeInOut} 0.5s infinite`
  },
  nextBtnContainer: {
    margin: '1.25rem 0 0'
  },
  totalAmountTxt: { margin: '1.5rem 1rem', width: '100%', gap: '0.5rem' },
  productImg: {
    maxHeight: '60px',
    objectFit: 'contain',
    aspectRatio: '3/2'
  },
  productContainer: {
    borderBottom: '1px solid #ccc'
  },
  auctionHeadingTxt: { fontWeight: '600', color: '#fff', fontSize: '15px' },
  '@keyframes blink': {
    '0%': { opacity: 1 },
    '12.5%': { opacity: 1 },
    '25%': { opacity: 0.8 },
    '37.5%': { opacity: 0.6 },
    '50%': { opacity: 0.4 },
    '62.5%': { opacity: 0.6 },
    '75%': { opacity: 0.8 },
    '87.5%': { opacity: 1 },
    '100%': { opacity: 1 }
  }
}));

const AuctionFormModal = ({
  open = false,
  handleClose = () => {},
  createdFromShop = true,
  orderProps = {
    collectionMethod: '',
    deliveryMethod: '',
    collectionAddress: '',
    deliveryAddress: '',
    collectionTime: '',
    collectionSlot: '',
    deliverySlot: '',
    deliveryTime: ''
  }
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { services, dispatch: servicesDispatch } = useServices();
  const { dispatch } = useAuctions();
  const { cart, setCart } = useContext(CartContext);
  const {
    state: { user }
  } = useAmplifyAuth();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const [auction, setAuction] = useState(INITIAL_AUCTION);
  const [product, setProduct] = useState(INITIAL_PRODUCT);
  const [searchBy, setSearchBy] = useState('');
  const [showProductModal, setShowProductModal] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const { loading } = useLoader();

  const [selectedSection, setSelectedSection] = useState('collection');
  const [totalAmount, setTotalAmount] = useState('');
  const [auctionCollectionMethod, setAuctionCollectionMethod] = useState('');
  const [auctionDeliveryMethod, setAuctionDeliveryMethod] = useState('');
  const [collectionAddress, setCollectionAddress] = useState(null);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [collectionTime, setCollectionTime] = useState(null);
  const [collectionSlot, setCollectionSlot] = useState(null);
  const [deliverySlot, setDeliverySlot] = useState(null);
  const [deliveryTime, setDeliveryTime] = useState(null);
  const [openChangeAddressDialog, setOpenChangeAddressDialog] = useState(false);
  const [addressList, setAddressList] = useState({
    loading: false,
    items: []
  });
  const [currAddressType, setCurrAddressType] = useState('Collection');
  const [editChangeAddress, setEditChangeAddress] = useState(false);
  const [showChangeAddress, setShowChangeAddress] = useState(true);
  const [collectionInstructions, setCollectionInstructions] = useState('');
  const [deliveryInstructions, setDeliveryInstructions] = useState('');
  const [errorText, setErrorText] = useState('');

  const isValidColAndDelTime = !createdFromShop
    ? !!auctionCollectionMethod &&
      !!auctionDeliveryMethod &&
      (auctionCollectionMethod === 'Collection'
        ? !!collectionAddress && collectionTime
        : true) &&
      (auctionDeliveryMethod === 'Delivery'
        ? !!deliveryAddress && deliveryTime
        : true)
    : true;

  useEffect(() => {
    let mounted = true;
    if (user?.defaultAddress) {
      API.graphql(
        graphqlOperation(getAddress, { id: user.defaultAddress })
      ).then(({ data }) => {
        const _address = data?.getAddress || [];
        mounted && setCollectionAddress(_address);
        mounted && setDeliveryAddress(_address);
      });
    }
    return () => {
      mounted = false;
    };
  }, [user]);

  useEffect(() => {
    servicesDispatch({ type: 'getService', payload: { showLoader: false } });
  }, []);

  const typeChangeHandler = (e, newVal, setValue = () => {}) => {
    setValue(newVal);
    if (newVal === 'Drop') {
      setCollectionSlot(null);
      setCollectionTime(null);
    }
    if (newVal === 'Pickup') {
      setDeliverySlot(null);
      setDeliveryTime(null);
    }
  };

  const changeAddress = (type = 'collection') => {
    setCurrAddressType(type);
    setOpenChangeAddressDialog(true);
    setAddressList({ loading: true, items: [] });
    if (user?.username) {
      API.graphql(
        graphqlOperation(listAddresss, {
          filter: { userID: { eq: user.username } },
          limit: 1000
        })
      ).then(({ data }) => {
        const _addressList = data.listAddresss.items;
        let items = [];
        if (_addressList.length > 0) {
          items = _addressList.filter((a) => !a._deleted);
        }
        setAddressList({ loading: false, items });
      });
    }
  };

  const handleEdit = () => {
    setEditChangeAddress((prevState) => !prevState);
    setShowChangeAddress(false);
  };

  const ACCORDION_FORM_SECTIONS = [
    // collection
    {
      name: 'collection',
      title: `Collection Options`,
      isValid: !!auctionCollectionMethod
        ? auctionCollectionMethod === 'Collection'
          ? collectionAddress && collectionTime && collectionSlot
          : true
        : false,
      content: (
        <RenderAddress
          type="Collection"
          value={auctionCollectionMethod}
          setValue={setAuctionCollectionMethod}
          typeChangeHandler={typeChangeHandler}
          collectionAddress={collectionAddress}
          changeAddress={changeAddress}
          collectionHrs={AUCTION_OPEN_ALL_DAY_HRS}
          setCollectionSlot={setCollectionSlot}
          setCollectionTime={setCollectionTime}
          collectionTime={collectionTime}
          collectionSlot={collectionSlot}
          options={['Collection', 'Drop']}
          collectionInstructions={collectionInstructions}
          setCollectionInstructions={setCollectionInstructions}
        />
      ),
      next: 'delivery'
    },
    // delivery
    {
      name: 'delivery',
      title: `Delivery Options`,
      isValid: !!auctionDeliveryMethod
        ? auctionDeliveryMethod === 'Delivery'
          ? deliveryAddress && deliveryTime && deliverySlot
          : true
        : false,
      content: (
        <RenderAddress
          type="Delivery"
          value={auctionDeliveryMethod}
          setValue={setAuctionDeliveryMethod}
          typeChangeHandler={typeChangeHandler}
          changeAddress={changeAddress}
          deliveryAddress={deliveryAddress}
          deliveryHrs={AUCTION_OPEN_ALL_DAY_HRS}
          collectionTime={collectionTime}
          collectionSlot={collectionSlot}
          setDeliverySlot={setDeliverySlot}
          setDeliveryTime={setDeliveryTime}
          options={['Delivery', 'Pickup']}
          deliveryInstructions={deliveryInstructions}
          setDeliveryInstructions={setDeliveryInstructions}
        />
      ),
      next: 'next'
    }
  ];

  useEffect(() => {
    const products = cart.items.map(
      ({ quantity = 0, service, serviceID = '', ...rest }) => ({
        name: rest.name || rest.item?.name,
        description: '',
        quantity,
        service,
        serviceID
      })
    );
    setAuction((prevAuction) => ({ ...prevAuction, products }));
  }, [cart, services]);

  useEffect(() => {
    if (createdFromShop) {
      let postcode = '';

      // making collection and delivery objects
      const collection = {
        type: 'time'
      };
      if (orderProps.collectionMethod !== 'Drop') {
        const _collAddress = omit(
          orderProps.collectionAddress,
          OMMITED_ADDRESS_FIELDS
        );
        if (!!_collAddress['postcode']) postcode = _collAddress['postcode'];
        const filteredCollAddress = Object.fromEntries(
          Object.entries(_collAddress).filter(([, v]) => !!v)
        );
        collection.address = filteredCollAddress;

        if (!!(orderProps.collectionTime && orderProps.collectionSlot)) {
          collection.date = orderProps.collectionTime.format(
            'YYYY-MM-DDThh:mm:ss.sssZ'
          );
          collection.time = orderProps.collectionSlot;
        }
      }

      const delivery = {
        type: 'time'
      };
      if (orderProps.deliveryMethod !== 'Pickup') {
        const _delAddress = omit(
          orderProps.deliveryAddress,
          OMMITED_ADDRESS_FIELDS
        );
        if (!postcode && !!_delAddress['postcode'])
          postcode = _delAddress['postcode'];
        const filteredDelAddress = Object.fromEntries(
          Object.entries(_delAddress).filter(([, v]) => !!v)
        );
        delivery.address = filteredDelAddress;

        if (!!(orderProps.deliveryTime && orderProps.deliverySlot)) {
          delivery.date = orderProps.deliveryTime.format(
            'YYYY-MM-DDThh:mm:ss.sssZ'
          );
          delivery.time = orderProps.deliverySlot;
        }
      }

      setAuction((prevAuction) => ({
        ...prevAuction,
        collection: JSON.stringify(collection),
        delivery: JSON.stringify(delivery),
        postcode
      }));
    }
  }, [orderProps]);

  const validate = (postcode) => {
    if (!regexPostcode.test(postcode)) {
      setErrorText('*This is not a valid postal code!');
    } else {
      setErrorText('');
    }
  };

  // when updating auction form fields
  const handleChange = (e) => {
    const updatedAuction = { ...auction, [e.target.name]: e.target.value };
    setAuction(updatedAuction);
    if (e.target.name === 'postcode' && updatedAuction.postcode)
      validate(updatedAuction.postcode);
  };

  // when creating a new product
  const handleAddNewProductSubmit = () => {
    handleChange({
      target: {
        name: 'products',
        value: [{ ...product, quantity: 1 }, ...auction.products]
      }
    });
    setProduct(INITIAL_PRODUCT);
    handleProductModalClose();
  };

  // when a product is updated
  const handleProductChange = (e) => {
    const updatedProduct = { ...product, [e.target.name]: e.target.value };
    if (e.target.name === 'service')
      updatedProduct['serviceID'] = updatedProduct.service?.id || '';
    setProduct(updatedProduct);
  };

  // when add product button is clicked
  const handleAddProductButtonClick = () => setShowProductModal(true);

  // add product modal close
  const handleProductModalClose = () => setShowProductModal(false);

  // confirmation modal close
  const handleConfirmModalClose = () => setShowConfirmationDialog(false);

  // after an auction is created
  const handleCreateSuccess = () => {
    setAuction(INITIAL_AUCTION);
    localStorage.removeItem('cart');
    setCart({ items: [] });
    history.replace(routes.customer.auction);
  };

  // when creating auction is confirmed
  const handleCreateConfirm = () => {
    const { startDate, startTime, endDate, endTime, ...auctionInput } = auction;
    let startDateTime = startTime,
      endDateTime = endTime;
    DATE_FIELDS.forEach((item) => {
      startDateTime = moment(startDateTime).set(
        item,
        moment(startDate).get(item)
      );
    });
    DATE_FIELDS.forEach((item) => {
      endDateTime = moment(endDateTime).set(item, moment(endDate).get(item));
    });
    startDateTime = moment(startDateTime)
      .utc()
      .format('yyyy-MM-DDTHH:mm:ss.SSS[Z]');
    endDateTime = moment(endDateTime)
      .utc()
      .format('yyyy-MM-DDTHH:mm:ss.SSS[Z]');

    // date validation
    if (new Date(endDateTime) <= new Date(startDateTime)) {
      enqueueSnackbar(
        'The start date and time must be earlier than the end date and time.',
        {
          variant: 'error',
          autoHideDuration: '3000',
          persist: true
        }
      );
      return;
    }
    // date less than collection date validation
    if (
      !!orderProps.collectionTime &&
      new Date(endDateTime) >= new Date(JSON.parse(auction.collection).date)
    ) {
      enqueueSnackbar('The end date must be earlier than the collection date', {
        variant: 'error',
        autoHideDuration: '3000',
        persist: true
      });
      return;
    }

    if (!createdFromShop && collectionTime && endDateTime) {
      const auctionEndDate = new Date(endDateTime).getTime();
      const auctionCollectionDate = new Date(
        collectionTime.format('YYYY-MM-DDThh:mm:ss.sssZ')
      ).getTime();

      if (auctionEndDate >= auctionCollectionDate) {
        enqueueSnackbar(
          'The auction end date must be earlier than the order collection date',
          {
            variant: 'error',
            autoHideDuration: '3000',
            persist: true
          }
        );
        return;
      }
    }

    let auctionData = {
      userID: user?.id || user?.sub,
      startDateTime,
      endDateTime,
      total: !!totalAmount ? parseFloat(totalAmount) : null,
      ...auctionInput
    };

    if (!createdFromShop) {
      let collection = { type: 'time', instructions: collectionInstructions };
      let delivery = { type: 'time', instructions: deliveryInstructions };

      if (auctionCollectionMethod !== 'Drop') {
        const _collAddress = omit(collectionAddress, [
          '_deleted',
          '_lastChangedAt',
          '_version',
          'user',
          'userID',
          'updatedAt',
          'createdAt'
        ]);
        const filteredCollAddress = Object.fromEntries(
          Object.entries(_collAddress).filter(([, v]) => !!v)
        );

        collection.address = filteredCollAddress;
        collection.date = collectionTime.format('YYYY-MM-DDThh:mm:ss.sssZ');
        collection.time = collectionSlot;
      }

      if (auctionDeliveryMethod !== 'Pickup') {
        const _delAddress = omit(deliveryAddress, [
          '_deleted',
          '_lastChangedAt',
          '_version',
          'user',
          'userID',
          'updatedAt',
          'createdAt'
        ]);
        const filteredDelAddress = Object.fromEntries(
          Object.entries(_delAddress).filter(([, v]) => !!v)
        );

        delivery.address = filteredDelAddress;
        delivery.date = deliveryTime.format('YYYY-MM-DDThh:mm:ss.sssZ');
        delivery.time = deliverySlot;
      }
      auctionData.collection = JSON.stringify(collection);
      auctionData.delivery = JSON.stringify(delivery);
    }

    handleConfirmModalClose();
    handleCreateAuctionModal();
    dispatch({
      type: 'createAuction',
      payload: {
        auction: auctionData,
        successCallback: handleCreateSuccess
      }
    });
  };

  // when creating auction form is submitted
  const handleAuctionFormSubmit = () => {
    // empty field validation
    const emptyField = REQUIRED_FIELDS.find((field) => !auction[field]);
    if (emptyField) {
      enqueueSnackbar(`${unitFormatter(emptyField)} is a required field`, {
        variant: 'error',
        autoHideDuration: '2500'
      });
      return;
    }
    // empty products validation
    if (auction.products.length === 0) {
      enqueueSnackbar('Add at least one product for the auction', {
        variant: 'error',
        autoHideDuration: '3000'
      });
      return;
    }
    // products quantity validation
    if (auction.products.some((prod) => !prod.quantity)) {
      enqueueSnackbar('All product quantities must be more than 0', {
        variant: 'error',
        autoHideDuration: '3000'
      });
      return;
    }
    // Postal code validation
    if (!regexPostcode.test(auction.postcode)) {
      enqueueSnackbar('Please provide a valid postal code.', {
        variant: 'error',
        autoHideDuration: '3000'
      });
      return;
    }
    // collection and delivery validation
    if (!isValidColAndDelTime) {
      enqueueSnackbar('Please select a valid collection and delivery option.', {
        variant: 'error',
        autoHideDuration: '3000'
      });
      return;
    }
    // Expected bid amount validation
    if (+totalAmount <= 0) {
      enqueueSnackbar('Please enter a valid bid total amount', {
        variant: 'error',
        autoHideDuration: '3000'
      });
      return;
    }

    setShowConfirmationDialog(true);
  };

  // when products quantity updated
  const handleAuctionItemQuantityUpdate = (name = '', increase = true) => () =>
    setAuction({
      ...auction,
      products: auction.products.map((item) =>
        item.name === name
          ? {
              ...item,
              quantity: increase ? item.quantity + 1 : item.quantity - 1
            }
          : item
      )
    });

  // when a product is removed
  const handleAuctionItemRemove = (name = '') => () =>
    setAuction({
      ...auction,
      products: auction.products.filter((item) => item.name !== name)
    });

  // to get a product's quantity
  const getAuctionItemQuantity = (name = '') => () =>
    auction.products.find((item) => item.name === name)?.quantity || 0;

  // products filtering by search
  const productsFilterCallback = ({ name = '', description = '' }) =>
    !searchBy ||
    [name, description].some((item) =>
      item.toLowerCase().includes(searchBy.toLowerCase())
    );

  const handleAccordionClick = (type) => () =>
    setSelectedSection((prev) => (prev === type ? '' : type));

  const handleTotalAmountChange = (e) => {
    setTotalAmount(e.target.value);
  };

  const handleCreateAuctionModal = () => {
    setSelectedSection('collection');
    setTotalAmount('');
    if (cart.items.length < 1) setAuction(INITIAL_AUCTION);
    setAuctionCollectionMethod('');
    setAuctionDeliveryMethod('');
    handleClose();
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog
        aria-labelledby="auction-form-dialog-title"
        fullWidth
        maxWidth="md"
        disableEscapeKeyDown
        onClose={handleCreateAuctionModal}
        open={open}>
        <DialogTitle
          id="auction-form-dialog-title"
          style={{ backgroundColor: '#143a69' }}>
          <Typography className={classes.auctionHeadingTxt}>
            Create Auction
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container justify="center" alignItems="center">
            <Grid container>
              {/* start & end date-time */}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-between">
                  <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    className={classes.dateTimeContainer}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className={classes.dateTimeContainer}>
                      <KeyboardDatePicker
                        fullWidth
                        margin="normal"
                        id="date-picker-dialog"
                        label="Start Date"
                        format="DD/MM/YYYY"
                        value={auction.startDate}
                        onChange={(date) =>
                          handleChange({
                            target: {
                              name: 'startDate',
                              value: moment(date).format('YYYY-MM-DD')
                            }
                          })
                        }
                        InputLabelProps={{
                          shrink: auction.startDate ? true : false
                        }}
                        disablePast
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className={classes.dateTimeContainer}>
                      <KeyboardTimePicker
                        fullWidth
                        margin="normal"
                        id="time-picker"
                        label="Start Time"
                        value={auction.startTime}
                        onChange={(time) =>
                          handleChange({
                            target: {
                              name: 'startTime',
                              value: moment(time).format()
                            }
                          })
                        }
                        InputLabelProps={{
                          shrink: auction.startTime ? true : false
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change time'
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    className={classes.dateTimeContainer}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className={classes.dateTimeContainer}>
                      <KeyboardDatePicker
                        fullWidth
                        margin="normal"
                        id="date-picker-dialog"
                        label="End Date"
                        format="DD/MM/YYYY"
                        value={auction.endDate}
                        onChange={(date) =>
                          handleChange({
                            target: {
                              name: 'endDate',
                              value: moment(date).format('YYYY-MM-DD')
                            }
                          })
                        }
                        InputLabelProps={{
                          shrink: auction.endDate ? true : false
                        }}
                        disablePast
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className={classes.dateTimeContainer}>
                      <KeyboardTimePicker
                        fullWidth
                        margin="normal"
                        id="time-picker"
                        label="End Time"
                        value={auction.endTime}
                        onChange={(time) =>
                          handleChange({
                            target: {
                              name: 'endTime',
                              value: moment(time).format()
                            }
                          })
                        }
                        InputLabelProps={{
                          shrink: auction.endTime ? true : false
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change time'
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid
              container
              style={{
                width: '100%',
                margin: '1rem 1rem 0'
              }}
              alignItems="center">
              <TextField
                value={auction.postcode}
                name="postcode"
                label="Postcode"
                error={!!errorText}
                helperText={
                  errorText ? 'Please provide a valid postal code.' : ''
                }
                onChange={handleChange}
                placeholder="Postcode"
                size="small"
                variant="outlined"
              />
              <Grid container style={{ marginTop: '0.5rem' }}>
                <Typography variant="subtitle2">
                  Note: An auction will be initiated for shops within a 5-mile
                  radius of the postal code you enter.
                </Typography>
              </Grid>
            </Grid>

            {!createdFromShop && (
              <Grid item xs={12} style={{ margin: '1.5rem 1rem' }}>
                {ACCORDION_FORM_SECTIONS.map((item, index) => {
                  return (
                    <Grid
                      container
                      className={classes.accordionContainer}
                      key={`checkout-form-section-${item.name}-${index}`}>
                      <Accordion
                        classes={{ root: classes.accordionRoot }}
                        expanded={selectedSection === item.name}
                        onChange={handleAccordionClick(item.name)}>
                        <AccordionSummary
                          classes={{ content: classes.accordionContent }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="order-type-accordion-content">
                          <Typography
                            variant="h5"
                            id={item.name}
                            className={classes.accordionTitle}>
                            {item.title}
                          </Typography>
                          {item.isValid ? (
                            <CheckCircleOutlineOutlinedIcon
                              className={classes.correctIcon}
                            />
                          ) : (
                            <ErrorOutlineOutlinedIcon
                              className={classes.errorIcon}
                            />
                          )}
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails>
                          <Grid container>
                            {item.content}
                            {item.next && (
                              <Grid
                                container
                                className={classes.nextBtnContainer}>
                                <Button
                                  onClick={handleAccordionClick(item.next)}
                                  href={`#${item.name}`}
                                  disabled={!item.isValid}
                                  variant="contained"
                                  color="primary"
                                  size="small">
                                  Next
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  );
                })}

                <Typography variant="subtitle2" style={{ marginTop: '1rem' }}>
                  Note: Collection and Delivery date and time are subject to
                  change after placing your order. You will be notified of any
                  updates later on
                </Typography>
              </Grid>
            )}

            <Grid
              container
              justify="space-between"
              className={classes.rowContainer}
              style={{ margin: createdFromShop ? '2rem 0' : '0' }}>
              <Grid item xs={12} sm={6} md={6}>
                <CustomSearchBar
                  value={searchBy}
                  onChange={(e) => setSearchBy(e.target.value)}
                  placeholder="Search Product"
                  onClear={() => setSearchBy('')}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                container
                justify="flex-end"
                className={classes.addButton}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleAddProductButtonClick}>
                  Add Product
                </Button>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid container className={classes.rowContainer} direction="column">
              <Typography variant="h5">Products</Typography>
              <Typography variant="subtitle2">
                Note: coupons are not applicable in Auctions
              </Typography>
            </Grid>
            <Grid
              container
              className={clsx(classes.rowContainer, classes.productsContainer)}>
              {auction.products.filter(productsFilterCallback).length > 0 ? (
                auction.products
                  .filter(productsFilterCallback)
                  .map((item, index) => {
                    const productImg = item.service?.items?.items.find(
                      (prod) => prod.name === item.name
                    )?.image;

                    return (
                      <Grid
                        container
                        key={`new-auction-products-items-${index}`}
                        className={classes.productContainer}>
                        <Grid container item md={7} xs={12} alignItems="center">
                          <Grid item md={4} xs={12}>
                            <Typography variant="h5">
                              {index + 1}. {item.name || ''}
                            </Typography>
                            <Typography variant="body2">
                              {item.service.name || ''}
                            </Typography>
                          </Grid>
                          {productImg && (
                            <Grid item md={4} xs={12}>
                              <Image
                                docKey={productImg}
                                alt="Product image"
                                className={classes.productImg}
                              />
                            </Grid>
                          )}
                          <Grid item md={4} xs={12}>
                            <Typography variant="subtitle2">
                              {item.description || ''}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          md={4}
                          xs={10}
                          container
                          justify={sm ? 'flex-start' : 'flex-end'}
                          alignItems="center"
                          style={{ margin: sm ? '0.5rem 0 1rem' : '0' }}>
                          <QuantityUpDownButtons
                            getItemQuantity={getAuctionItemQuantity(item.name)}
                            increaseQuantity={handleAuctionItemQuantityUpdate(
                              item.name
                            )}
                            decreaseQuantity={handleAuctionItemQuantityUpdate(
                              item.name,
                              false
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          md={1}
                          xs={2}
                          container
                          alignItems="center"
                          justify="flex-end">
                          <IconButton
                            size="small"
                            onClick={handleAuctionItemRemove(item.name)}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })
              ) : (
                <Grid container>
                  <Typography variant="subtitle2">No results found</Typography>
                </Grid>
              )}
            </Grid>
            {auction.products.filter(productsFilterCallback).length > 0 && (
              <Grid
                className={classes.totalAmountTxt}
                container
                justify="flex-end"
                alignItems="center">
                <Typography variant="h5">Expected Bid Total: &nbsp;</Typography>
                <TextField
                  value={totalAmount}
                  name="amount"
                  label="Amount"
                  onChange={handleTotalAmountChange}
                  placeholder="Amount"
                  size="small"
                  variant="outlined"
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateAuctionModal}>Close</Button>
          <Button
            color="primary"
            onClick={handleAuctionFormSubmit}
            variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <AddProductModal
        open={showProductModal}
        addProduct={handleAddNewProductSubmit}
        handleClose={handleProductModalClose}
        handleChange={handleProductChange}
        services={services}
        products={auction.products}
        values={product}
        nameAsSelect
      />
      <DocumentDeleteDialog
        open={showConfirmationDialog}
        handleCancel={handleConfirmModalClose}
        title="Confirm Auction"
        confirmText="create this auction"
        handleConfirm={handleCreateConfirm}
        note="Note: Ensure the accuracy of all details since modifications won't be possible after saving. Click 'No' to make changes if needed."
      />
      <AddressModal
        open={openChangeAddressDialog}
        handleClose={() => setOpenChangeAddressDialog(false)}
        currAddressType={currAddressType}
        collectionAddress={collectionAddress}
        deliveryAddress={deliveryAddress}
        addressList={addressList}
        handleEdit={handleEdit}
        show={showChangeAddress}
        edit={editChangeAddress}
        setCollectionAddress={setCollectionAddress}
        setDeliveryAddress={setDeliveryAddress}
        setOpen={setOpenChangeAddressDialog}
      />
    </>
  );
};

export default AuctionFormModal;
