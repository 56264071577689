import { routes } from 'common/constants';

export const TABS = [
  {
    name: 'Scheduled Auctions',
    route: routes.serviceProvider.auctionsPending,
    filter: (_auction) =>
      !_auction.isClosed && new Date(_auction.startDateTime) > new Date()
  },
  {
    name: 'Ongoing Auctions',
    route: routes.serviceProvider.auctionsOngoing,
    filter: (_auction) =>
      !_auction.isClosed &&
      new Date(_auction.startDateTime) < new Date() &&
      new Date(_auction.endDateTime) > new Date()
  },
  {
    name: 'Expired Auctions',
    route: routes.serviceProvider.auctionsExpired,
    filter: (_auction) =>
      (_auction.isClosed || new Date(_auction.endDateTime) < new Date()) &&
      _auction.bids.items.every((_bid) => _bid.orders.items.length === 0)
  },
  {
    name: 'Completed Auctions',
    route: routes.serviceProvider.auctionCompleted,
    filter: (_auction) =>
      (_auction.isClosed || new Date(_auction.endDateTime) < new Date()) &&
      _auction.bids.items.some((_bid) => _bid.orders.items.length > 0)
  }
];
