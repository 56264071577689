import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  root: {
    boxShadow: '0px 0px 3px 0px #00000033',
    padding: '0.5rem',
    borderRadius: 8,
    gap: '1rem',
    backgroundColor: '#0093E9',
    backgroundImage: 'linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)'
  },
  cardTypeContainer: {
    backgroundColor: '#fff',
    borderRadius: 8,
    padding: '0.5rem'
  },
  cardType: {
    fontSize: 16,
    fontWeight: 600,
    color: '#444',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20
    }
  },
  cardNumber: {
    marginBottom: 10,
    color: '#fff',
    lineHeight: '1.2rem',
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      marginTop: 10
    }
  },
  expireDate: {
    fontSize: 12,
    color: '#fff',
    fontWeight: 600
  }
}));

const PaymentCardDetails = ({
  brand = '',
  last4 = 'XXXX',
  exp_month = 99,
  exp_year = 9999
}) => {
  const classes = useStyle();
  return (
    <Grid
      container
      alignItems="center"
      justify="space-between"
      className={classes.root}>
      <Grid item className={classes.cardTypeContainer}>
        <Typography className={classes.cardType}>{brand}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid item xs={12}>
          <Typography className={classes.cardNumber}>
            xxxx xxxx xxxx {last4}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.expireDate} align="right">
            {exp_month >= 10 ? '' : '0'}
            {exp_month}/{exp_year % 2000}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaymentCardDetails;
