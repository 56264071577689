import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Chip,
  Button,
  IconButton,
  Tooltip,
  useTheme,
  useMediaQuery,
  Box
} from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import { getPaymentByOrderId, getUser } from 'graphql/queries';
import { useLoader } from 'layouts/loaderContext';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import {
  showPrice,
  unitFormatter,
  getFormattedName,
  getFormattedPriceRange,
  getFormattedPhoneNumber
} from 'common/utilFunctions';
import OrderDocumentPDF from './OrderDocumentPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useHistory } from 'react-router-dom';
import { inWords, capitalizeStr } from 'common/utilFunctions';
import MobileOrderDetailDialog from './MobileOrderDetailDialog';
import OrderDetailInvoice from './OrderDetailInvoice';
import StoreIcon from '@material-ui/icons/Store';

const useStyles = makeStyles((theme) => ({
  backgroundColor: {
    backgroundColor: '#D5EEFF'
  },
  table: {
    minWidth: 700
  },
  verticalAlign: {
    verticalAlign: 'middle'
  },
  statusChipSec: {
    marginTop: '1rem',
    [theme.breakpoints.up('sm')]: {
      marginTop: '0'
    }
  },
  center: {
    textAlign: 'center'
  },
  cancelReasonBox: {
    padding: '0.5rem',
    backgroundColor: theme.palette.warning.light,
    borderRadius: '0.28rem',
    marginTop: '0.5rem'
  },
  asapBanner: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    padding: '0.2rem 0.5rem',
    borderRadius: '0.25rem'
  },
  overallDiscount: {
    padding: '0 1rem 1rem'
  },
  discountText: {
    fontSize: '0.75rem'
  },
  auctionIndicator: {
    cursor: 'pointer',
    margin: '0 0.5rem',
    padding: '0.15rem 0.5rem',
    backgroundColor: '#EDD242',
    borderRadius: '0.25rem',
    color: 'black'
  },
  orderForwardReason: {
    padding: '5px 0',
    color: '#f94e4e'
  },
  addressContainer: {
    border: '1px solid #ccc',
    padding: '0.5rem',
    borderRadius: '8px'
  },
  customerDetailsContainer: {
    marginBottom: '0.8rem',
    border: '1px solid #ccc',
    padding: '0.5rem 1rem',
    borderRadius: '8px'
  },
  instructions: {
    borderTop: '1px solid #ccc'
  },
  instructionsHeading: {
    fontWeight: 500,
    color: '#555'
  },
  instructionsContent: {
    fontSize: '13px',
    fontWeight: 500
  }
}));

const OrderDetailContent = ({
  order,
  handleClose = () => {},
  updateOrder = () => {},
  isOrdersModal = false,
  showBackButton = false,
  showClosedBtn = false
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [partiallyPaidRecord, setPartiallyPaidRecord] = useState([]);
  const [prevTotal, setPrevTotal] = useState(0);
  const { setLoading } = useLoader();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setPrevTotal(order?.total);
  }, []);

  const getPartialPaymentsRecord = async (id) => {
    setLoading(true);
    if (id) {
      const response = await API.graphql(
        graphqlOperation(getPaymentByOrderId, {
          orderID: id
        })
      );
      const data = await response.data.getPaymentByOrderId.items;
      setPartiallyPaidRecord(
        data
          .map((el) => ({
            amount: el.amount,
            date: el.createdAt,
            lastChanged: el._lastChangedAt
          }))
          .sort((a, b) => a.lastChanged - b.lastChanged)
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getPartialPaymentsRecord(order?.id);
  }, [order]);

  let balance = order?.total;

  const forwardedFrom = order?.forwardRequests?.items[0]?.requestShop.name;
  const forwardedTo =
    order?.forwardRequests?.items[0]?.acceptedShop?.name || 'pending';
  const forwardReason = order?.forwardRequests?.items[0]?.forwardReason;

  const collectionDriverDetails = order?.collection?.driver;
  const deliveryDriverDetails = order?.delivery?.driver;

  return order?.id ? (
    <Grid container>
      <Paper
        style={{
          width: '100%',
          margin: '0.4rem 0.5rem',
          padding: '0.5rem'
        }}>
        <Grid container item justify="space-between" alignItems="center">
          <Grid item sm={8} xs={12} container alignItems="center">
            {showBackButton ? (
              <IconButton size="small" onClick={() => history.goBack()}>
                <ArrowBackIcon fontSize="small" />
              </IconButton>
            ) : null}
            <Typography
              variant="h5"
              style={{
                margin: '0 0.5rem'
              }}>
              {order.orderID}
            </Typography>
            <Typography>
              ( ordered on
              {moment(order.createdAt).format(' MMMM Do YYYY')}
              {' at '}
              {moment(order.createdAt).format('hh:mm a')} )
            </Typography>
            {!!order.bidID ? (
              <Tooltip
                title="this order comes from an Auction where your shop placed a Bid"
                arrow>
                <Typography
                  variant="body2"
                  className={classes.auctionIndicator}>
                  From Auction
                </Typography>
              </Tooltip>
            ) : null}
          </Grid>
          <Grid
            item
            sm={4}
            xs={12}
            container
            justify="flex-end"
            className={classes.statusChipSec}>
            <Chip
              color="primary"
              style={{
                backgroundColor: order.status
                  ? order.status === 'declined'
                    ? 'red'
                    : order.status === 'cancelled'
                    ? 'orange'
                    : 'green'
                  : 'green',
                fontWeight: '600',
                textTransform: 'capitalize',
                marginRight: '0.5rem'
              }}
              label={order.status}
            />

            <PDFDownloadLink
              document={
                <OrderDocumentPDF order={order} payment={partiallyPaidRecord} />
              }
              fileName={`order-${order?.orderID || ''}`}>
              {({ loading, error }) =>
                !(loading || error) ? (
                  <Button variant="contained" color="secondary" size="small">
                    Print Order
                  </Button>
                ) : null
              }
            </PDFDownloadLink>
          </Grid>
        </Grid>
        {order.orderCancelOrDeclineReason && (
          <Grid container className={classes.cancelReasonBox}>
            <Typography>
              <span style={{ fontWeight: 450 }}>
                Reason for
                {order.status === 'declined' ? ' decline: ' : ' cancellation: '}
              </span>
              {order.orderCancelOrDeclineReason}
            </Typography>
          </Grid>
        )}
      </Paper>
      <Divider />
      <Paper
        style={{
          width: '100%',
          margin: '1rem 0.5rem',
          padding: '0.5rem'
        }}>
        <PerfectScrollbar
          style={{ maxHeight: sm ? '50vh' : '65vh', paddingRight: '1rem' }}>
          <Grid
            container
            justify="space-between"
            style={{ marginBottom: '1rem' }}>
            <Grid
              container
              item
              justify="flex-start"
              alignItems="flex-start"
              xs={12}
              md={6}>
              <Typography className={classes.customerDetailsContainer}>
                <span style={{ fontWeight: 600 }}>Customer Details :</span>
                <br />
                Contact Name : {getFormattedName(order?.userDetail)}
                <br />
                Email address : {order?.userDetail?.email}
                <br />
                Phone number :{' '}
                {getFormattedPhoneNumber(order?.userDetail?.phoneNumber)}
                {order?.collection?.date ? (
                  <>
                    <br />
                    <br />
                    {!!order.collection.address ? 'Collection ' : 'Drop '} Date
                    :{moment(order.collection.date).format(' MMMM Do YYYY')}
                    {' between '}
                    {order.collection.time}
                  </>
                ) : null}
              </Typography>
              {order?.collection?.type === 'asap' &&
                order?.delivery?.type === 'asap' && (
                  <Grid
                    container
                    item
                    xs={12}
                    justify="flex-start"
                    alignItems="center"
                    style={{
                      marginTop: '1rem'
                    }}>
                    <Typography className={classes.asapBanner}>
                      This is an <strong> ASAP </strong> order
                    </Typography>
                  </Grid>
                )}
            </Grid>
            {isOrdersModal ? (
              <Grid
                container
                direction="column"
                item
                xs={12}
                md={5}
                style={{ gap: '1rem' }}
                alignItems="center">
                {forwardedFrom ? (
                  <Grid container>
                    <Grid item xs={1}>
                      <StoreIcon fontSize="inherit" />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>Order placed on: {forwardedFrom}</Typography>
                      <Typography className={classes.orderForwardReason}>
                        {forwardReason}
                      </Typography>
                    </Grid>

                    <Grid item xs={1}>
                      <StoreIcon fontSize="inherit" />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>Order accepted by: {forwardedTo}</Typography>
                    </Grid>
                  </Grid>
                ) : null}

                <Grid container justify="center" alignItems="center">
                  <Grid item xs={1}>
                    <LocationOnIcon fontSize="inherit" />
                  </Grid>
                  <Grid item xs={11}>
                    {order?.collection?.address ? (
                      <Box className={classes.addressContainer}>
                        <Typography style={{ paddingBottom: '0.2rem' }}>
                          <span style={{ fontWeight: 600 }}>
                            Collection Address
                          </span>{' '}
                          :<br />
                          {JSON.parse(order?.collection?.address)['line1']}
                          {', '}
                          {JSON.parse(order?.collection?.address)['town']}
                        </Typography>
                        {order?.collection?.instructions && (
                          <Typography className={classes.instructions}>
                            <span className={classes.instructionsHeading}>
                              Instructions
                            </span>{' '}
                            :<br />
                            <span className={classes.instructionsContent}>
                              {order.collection.instructions}
                            </span>
                          </Typography>
                        )}

                        {/* {collectionDriverDetails ? (
                          <>
                            <Divider style={{ margin: '5px 0' }} />
                            <Typography>
                              Driver:{' '}
                              {getFormattedName(collectionDriverDetails)}
                              <br />
                              Phone: {collectionDriverDetails.phoneNumber}
                            </Typography>
                          </>
                        ) : null} */}
                      </Box>
                    ) : (
                      <Typography className={classes.addressContainer}>
                        <span style={{ fontWeight: 600 }}>Drop</span>: Customer.
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <Grid container justify="center" alignItems="center">
                  <Grid item xs={1}>
                    <LocationOnIcon fontSize="inherit" />
                  </Grid>
                  <Grid item xs={11}>
                    {order?.delivery?.address ? (
                      <Box className={classes.addressContainer}>
                        <Typography style={{ paddingBottom: '0.2rem' }}>
                          <span style={{ fontWeight: 600 }}>
                            Delivery Address
                          </span>{' '}
                          :<br />
                          {JSON.parse(order?.delivery?.address)['line1']}
                          {', '}
                          {JSON.parse(order?.delivery?.address)['town']}
                        </Typography>

                        {order?.delivery?.instructions && (
                          <Typography className={classes.instructions}>
                            <span className={classes.instructionsHeading}>
                              Instructions
                            </span>{' '}
                            :<br />
                            <span className={classes.instructionsContent}>
                              {order.delivery.instructions}
                            </span>
                          </Typography>
                        )}

                        {/* {deliveryDriverDetails ? (
                          <>
                            <Divider style={{ margin: '5px 0' }} />
                            <Typography>
                              Driver: {getFormattedName(deliveryDriverDetails)}
                              <br />
                              Phone: {deliveryDriverDetails.phoneNumber}
                            </Typography>
                          </>
                        ) : null} */}
                      </Box>
                    ) : (
                      <Typography className={classes.addressContainer}>
                        <span style={{ fontWeight: 600 }}>Pickup</span>:
                        Customer.
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid container item xs={12} md={5} justify="flex-end">
                {order?.shop && (
                  <Typography>
                    <span style={{ fontWeight: 'bolder' }}>
                      {order?.shop?.name}
                    </span>
                    <br />
                    {order?.shop?.address}
                    {`, ${order?.shop?.postcode || ''}`.toUpperCase()}
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>
          {sm ? (
            <MobileOrderDetailDialog
              order={order}
              partiallyPaidRecord={partiallyPaidRecord}
              prevTotal={prevTotal}
              sm={sm}
            />
          ) : (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="order-items-table">
                <TableHead>
                  <TableRow className={classes.backgroundColor}>
                    <TableCell align="left">ITEM NAME</TableCell>
                    <TableCell align="center">ITEM DESCRIPTION</TableCell>
                    <TableCell align="center">QUANTITY</TableCell>
                    {!order.bidID ? (
                      <TableCell align="center">PRICE</TableCell>
                    ) : null}
                    <TableCell align="center">DISCOUNT</TableCell>
                    <TableCell align="right">TOTAL</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.orderList.length > 0 &&
                    order.orderList.map((item, index) => (
                      <TableRow
                        key={`${item.orderID}-order-details-items-${index}`}>
                        <TableCell align="left">{item.name}</TableCell>
                        <TableCell align="center">
                          {item.description !== null ? item.description : 'N/A'}
                        </TableCell>
                        <TableCell align="center">{item.quantity}</TableCell>
                        {!order.bidID ? (
                          <TableCell align="center">
                            {`${
                              item.price
                                ? showPrice(item.price)
                                : getFormattedPriceRange(item.priceRange)
                            } ${unitFormatter(item.unit)}`.toLowerCase()}
                          </TableCell>
                        ) : null}
                        <TableCell align="center">
                          {item.discount
                            ? `${showPrice(item.discount || 0)} ${unitFormatter(
                                item.unit
                              )}`.toLowerCase()
                            : item.overallDiscount
                            ? `${showPrice(item.overallDiscount)}`
                            : 0}
                        </TableCell>
                        <TableCell align="right">
                          {item.totalPrice
                            ? showPrice(item.totalPrice)
                            : item.price
                            ? showPrice(
                                item.price * item.quantity -
                                  (item.overallDiscount || 0)
                              )
                            : getFormattedPriceRange(
                                item.priceRange,
                                item.quantity
                              )}
                        </TableCell>
                      </TableRow>
                    ))}

                  <OrderDetailInvoice
                    order={order}
                    partiallyPaidRecord={partiallyPaidRecord}
                    prevTotal={prevTotal}
                  />
                </TableBody>
              </Table>
              {order.paymentStatus === 'paid' && (
                <Typography
                  align="center"
                  style={{ padding: '0.5rem', fontWeight: 'bold' }}>
                  A {unitFormatter(order.paymentMethod)} Payment of{' '}
                  {capitalizeStr(inWords(order?.total))} is Paid.
                </Typography>
              )}
            </TableContainer>
          )}
        </PerfectScrollbar>
      </Paper>
      <Paper className={classes.backgroundColor} style={{ width: '100%' }}>
        <Grid container justify="space-between" style={{ padding: '1rem' }}>
          <Grid container item xs={12} md={7}>
            {order?.delivery?.time && (
              <>
                <LocalShippingOutlinedIcon />
                <Typography style={{ marginLeft: '1rem' }}>
                  {!!order.delivery.address ? 'Delivery ' : 'Pickup '}
                  Date :{moment(order.delivery.date).format(' MMMM Do YYYY')}
                  {' between '}
                  {order.delivery.time}
                </Typography>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            container
            justify={sm ? 'center' : 'flex-end'}
            style={{ gap: '0.75rem' }}>
            {isOrdersModal && order?.status === 'newOrder' ? (
              <>
                <Button
                  color="primary"
                  onClick={() => updateOrder(order, 'accepted')}
                  variant="contained"
                  size="small">
                  Accept
                </Button>
                <Button
                  color="secondary"
                  onClick={() => updateOrder(order, 'declined')}
                  variant="contained"
                  size="small">
                  Decline
                </Button>
                {/* TODO ORDER_FORWARD */}
                {/* {order.forwardRequests.items.length < 1 && (
                  <Button
                    color="primary"
                    style={{ backgroundColor: 'orange' }}
                    onClick={() => updateOrder(order, 'forwarded')}
                    variant="contained"
                    size="small">
                    Forward
                  </Button>
                )} */}
              </>
            ) : null}
            {isOrdersModal || showClosedBtn ? (
              <Button
                color="secondary"
                onClick={handleClose}
                variant="contained"
                size="small">
                Close
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  ) : null;
};

export default OrderDetailContent;
