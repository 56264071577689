import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {
  useStripe,
  useElements,
  CardNumberElement
} from '@stripe/react-stripe-js';
import { useOrders } from 'context/order/ordersContext';
import { useLoader } from 'layouts/loaderContext';
import { useSnackbar } from 'notistack';
import { StripeCardPaymentOptions } from 'components';
import { showPrice } from 'common/utilFunctions';
import { routes } from 'common/constants';
import OrderDetails from './components/OrderDetails';
import { HomeFooter, Navbar } from 'components/organisms';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    minHeight: '100vh'
  },
  root: {
    minHeight: '30vh',
    padding: '0.5rem',
    boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
    backgroundColor: '#fff'
  },
  container: {
    padding: '1rem',
    gap: '1rem 0'
  },
  fullWidth: {
    width: '100%'
  },
  title: {
    marginBottom: '0.5rem',
    fontWeight: 600
  },
  cardsInput: {
    margin: '1rem 0.5rem 0'
  },
  paymentContainer: {
    padding: '0 2rem 0',
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem 0'
    }
  },
  paymentHeadingText: {
    fontSize: 18,
    fontWeight: 600,
    color: '#444',
    textAlign: 'center',
    lineHeight: '1.5rem'
  },
  submitBtn: {
    margin: '0.5rem 0'
  },
  scrollBarContainer: {
    [theme.breakpoints.up('md')]: {
      maxHeight: '35rem'
    }
  }
}));

const OrderPayment = () => {
  const classes = useStyles();
  const history = useHistory();
  const { loading, setLoading } = useLoader();
  const { orders, dispatch } = useOrders();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [currentOrder, setCurrentOrder] = useState(null);
  // stripe
  const stripe = useStripe();
  const elements = useElements();
  const [cardDetails, setCardDetails] = useState({
    selectedCardID: '',
    cardName: { value: '', error: false },
    shouldSaveCard: false,
    customerID: ''
  });

  useEffect(() => {
    if (!!history.location.search) {
      const searchString = history.location.search.split('?').at(1);
      const searchStringParams = Object.fromEntries(
        searchString.split('&').map((item) => item.split('='))
      );
      const currentOrder = orders.find(
        (_order) => _order.orderID === searchStringParams.orderID
      );

      if (currentOrder) {
        if (searchStringParams.refunded === 'false')
          enqueueSnackbar(
            `Your previous authorised payment of ${showPrice(
              currentOrder.onHoldPaymentAmount
            )} will be refunded after this payment`,
            {
              variant: 'warning',
              persist: true,
              preventDuplicate: true
            }
          );
        setCurrentOrder(currentOrder);
        return;
      }
    }
    history.replace('/home');
    enqueueSnackbar('Payment Process Interrupted', {
      variant: 'error',
      autoHideDuration: 2500
    });
  }, [orders, history]);

  const handleSubmit = async () => {
    if (!stripe || !elements) return;

    if (!cardDetails.selectedCardID && !cardDetails.cardName.value) {
      setCardDetails({ ...cardDetails, cardName: { value: '', error: true } });
      enqueueSnackbar('Please enter your full name', {
        variant: 'error',
        persist: true,
        preventDuplicate: true
      });
      return;
    }

    let snackBar;
    try {
      setLoading(true);
      snackBar = enqueueSnackbar('Attempting payment, please wait...', {
        variant: 'info',
        persist: true,
        preventDuplicate: true
      });

      // getting client secret for payment
      const clientSecretResp = await API.post(
        'laundryapi',
        '/payment-intents',
        {
          body: {
            orderID: currentOrder.id,
            customerID: cardDetails.customerID
          }
        }
      );

      // confirming payment
      const confirmPaymentParams = {};
      if (cardDetails.shouldSaveCard) {
        confirmPaymentParams['setup_future_usage'] = 'off_session';
      }
      if (!!cardDetails.selectedCardID) {
        confirmPaymentParams['payment_method'] = cardDetails.selectedCardID;
      } else {
        confirmPaymentParams['payment_method'] = {
          card: elements.getElement(CardNumberElement),
          billing_details: { name: cardDetails.cardName.value }
        };
      }
      const result = await stripe.confirmCardPayment(
        clientSecretResp.client_secret,
        confirmPaymentParams
      );
      closeSnackbar(snackBar);

      if (result.error) {
        // Show error to your customer (for example, insufficient funds)
        enqueueSnackbar(result.error.message, {
          variant: 'error',
          persist: true,
          preventDuplicate: true
        });
        console.error(result.error.message);
        setLoading(false);
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
          dispatch({
            type: 'confirmOrderCardPayment',
            payload: {
              orderID: currentOrder.id,
              newPaymentIntentID: result.paymentIntent.id,
              successCallback: () => {
                history.replace(
                  `${routes.customer.order}?orderID=${currentOrder.orderID}`
                );
              }
            }
          });
        }
      }
    } catch (error) {
      setLoading(false);
      console.log('Something went wrong', error);
      enqueueSnackbar('Something went wrong', {
        variant: 'error',
        autoHideDuration: 2500
      });
    } finally {
      closeSnackbar(snackBar);
    }
  };

  return (
    <Grid container justify="center" className={classes.rootContainer}>
      <Navbar hideInSmallScreen={false} />

      <Grid
        container
        item
        alignItems="center"
        md={6}
        xs={12}
        className={classes.root}>
        <PerfectScrollbar className={classes.scrollBarContainer}>
          <OrderDetails order={currentOrder} />
        </PerfectScrollbar>
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        md={6}
        xs={12}
        className={classes.root}>
        <PerfectScrollbar className={classes.scrollBarContainer}>
          <Grid
            container
            item
            justify="center"
            className={classes.paymentContainer}>
            <Typography variant="body1" className={classes.paymentHeadingText}>
              Complete your payment using a credit or debit card.
            </Typography>
            <StripeCardPaymentOptions
              className={classes.cardsInput}
              cardDetails={cardDetails}
              setCardDetails={setCardDetails}
            />
            <Button
              fullWidth
              variant="contained"
              size="large"
              type="submit"
              color="primary"
              onClick={handleSubmit}
              className={classes.submitBtn}
              disabled={loading || !stripe}>
              Confirm Payment
            </Button>
          </Grid>
        </PerfectScrollbar>
      </Grid>

      <HomeFooter showServiceDetails={false} />
    </Grid>
  );
};

export default OrderPayment;
