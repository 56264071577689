import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DesktopAccessDisabledIcon from '@material-ui/icons/DesktopAccessDisabled';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const ICONS_BY_STATUS = {
  Pending: HourglassEmptyIcon,
  Active: CheckCircleOutlineIcon,
  Offline: DesktopAccessDisabledIcon,
  Other: ErrorOutlineIcon
};
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    height: 30,
    width: 30
  },
  pending: { backgroundColor: '#fb8c00' },
  active: { backgroundColor: '#198754' },
  offline: { backgroundColor: '#3f51b5' },
  other: { backgroundColor: '#E50000' },
  icon: {
    height: 20,
    width: 20
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: '#fb8c00',
    marginRight: theme.spacing(1)
  }
}));

const StatusShopCounts = (props) => {
  const { className, title, numbers, ...rest } = props;
  const classes = useStyles();
  const Icon = ICONS_BY_STATUS[title];

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body1">
              {`${title} Shops`}
            </Typography>
            <Typography variant="h4">{numbers} shops</Typography>
          </Grid>
          <Grid item>
            <Avatar
              className={clsx(classes.avatar, classes[title.toLowerCase()])}>
              <Icon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

StatusShopCounts.propTypes = {
  className: PropTypes.string
};

export default StatusShopCounts;
