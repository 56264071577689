import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useAmplifyAuth } from 'context';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '2rem'
  },
  blueBtn: {
    backgroundColor: theme.palette.blue?.main || theme.palette.primary.main,
    color: 'white'
  },
  subHeading: {
    marginTop: '0.75rem',
    marginBottom: '1.52rem'
  }
}));

const WriteReview = ({ reviews = [], setShowAddReviewDialog = () => {} }) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { user }
  } = useAmplifyAuth();

  const writeReviewHandler = () => {
    if (user.id) {
      setShowAddReviewDialog(true);
    } else {
      enqueueSnackbar('Something went wrong...', {
        variant: 'error',
        autoHideDuration: 1000,
        preventDuplicate: true
      });
    }
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <Typography variant="h3">Your review</Typography>
      <Typography variant="h6" className={classes.subHeading}>
        {reviews.length > 0
          ? 'Share your thoughts with other customers'
          : 'Be the first one to review this shop'}
      </Typography>
      {user && user.username ? (
        <Button
          variant="contained"
          fullWidth
          size="large"
          onClick={writeReviewHandler}
          className={classes.blueBtn}>
          WRITE REVIEW
        </Button>
      ) : (
        <Button
          variant="contained"
          fullWidth
          size="large"
          href={`/login?redirect=${history.location.pathname}`}
          className={classes.blueBtn}>
          WRITE REVIEW
        </Button>
      )}
    </Grid>
  );
};

export default WriteReview;
