import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <CancelIcon style={{ color: '#fff' }} />
    </IconButton>
  );
}

export default SnackbarCloseButton;
