import { React } from 'react';

const amplifyAuthReducer = (state, { type, payload } = {}) => {
  switch (type) {
    case 'FETCH_USER_DATA_SUCCESS': {
      const { user } = payload || {};
      let _user = { ...user };
      if (user?.shopID) {
        _user['shopId'] = user['shopID'];
      }
      const groups = payload?.user?.groups || state?.user?.groups;
      return {
        ...state,
        isLoading: false,
        isError: false,
        isAdmin: groups?.includes('admin'),
        isSales: groups?.includes('sales'),
        isLaundryAdmin: groups?.includes('laundryAdmin'),
        isLaundryStaff: groups?.includes('laundryStaff'),
        user: { ...state.user, ..._user }
      };
    }
    case 'FETCH_USER_DATA_FAILURE':
      return { ...state, isLoading: false, isError: true };
    case 'RESET_USER_DATA':
      return { ...state, user: null };
    default:
      throw new Error();
  }
};

export default amplifyAuthReducer;
