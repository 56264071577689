import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import MainBannerImage from '../../../assets/png/main_banner2.png';
import LaunderItBenefitsImage from '../../../assets/png/launder-banner.png';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  rootContainer: {},
  mainBannerImage: {
    backgroundImage: `url(${MainBannerImage})`,
    height: 300,
    marginBottom: '1rem',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  aboutUsText: {
    fontSize: '28px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: '2rem'
  },
  aboutUsSubText: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#194376',
    marginLeft: '2rem',
    backgroundColor: '#edd242',
    padding: '0.5rem',
    marginTop: '1rem',
    width: '9rem',
    borderRadius: '8px'
  },
  headingText: {
    textAlign: 'center',
    fontWeight: 800,
    marginBottom: '1rem',
    fontSize: '28px',
    color: '#1f6893'
  },
  subHeading: {
    fontWeight: 600,
    textAlign: 'center',
    fontSize: '16px'
  },
  launderItAbout: {
    fontSize: '21px',
    lineHeight: '1.8rem',
    padding: '1rem ',
    margin: '1rem 0',
    textAlign: 'justify'
  },
  launderItBenefits: {
    textAlign: 'justify',
    fontSize: '18px',
    lineHeight: '1.8rem'
  },
  launderItBenefitsContainer: {
    margin: '1rem 0.5rem 0.5rem'
  },
  launderitHeading: {
    fontWeight: 800,
    color: '#1f6893',
    marginBottom: '1rem'
  }
});

const AboutUsContent = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      justify="center"
      alignItems="center"
      xs={12}
      className={classes.rootContainer}>
      <Grid
        container
        item
        className={classes.mainBannerImage}
        alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.aboutUsText}>
            Launder-It
          </Typography>

          <Typography variant="body1" className={classes.aboutUsSubText}>
            <Link to="/home">Home</Link> / About Us
          </Typography>
        </Grid>
      </Grid>

      <Grid container item style={{ margin: '1rem 0' }}>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.headingText}>
            Launder-It
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.subHeading}>
            Efficient, Reliable, and Convenient
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        item
        alignItems="center"
        justify="space-between"
        spacing={3}
        className={classes.launderItBenefitsContainer}>
        <Grid
          container
          item
          alignItems="center"
          md={6}
          xs={12}
          style={{
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
          }}>
          <img
            src={LaunderItBenefitsImage}
            width="100%"
            style={{ objectFit: 'contain' }}
          />
        </Grid>

        <Grid container item md={6} xs={12}>
          <Typography variant="h4" className={classes.launderitHeading}>
            Welcome to launder-it.co.uk!
          </Typography>
          <Typography className={classes.launderItBenefits}>
            With over two decades of experience in the dry-cleaning industry, we
            have gained a deep understanding of the market, enabling us to cater
            to our customers' needs more effectively. we have been privileged to
            serve a diverse clientele, and through this journey, we have
            identified a significant gap in the market that thrilled to
            introduce launder-it.co.uk, a groundbreaking platform designed to
            revolutionize the way laundry businesses, tailors, key cutting
            specialists, and shoe repair experts like yourselves operate and
            reach your target audience. Our platform serves as the bridge
            between customers in need of laundry services and shops looking to
            expand their reach and streamline their operations. Our mission is
            to revolutionize the dry-cleaning industry by providing a
            cutting-edge platform that benefits both customers and shop
            administrators alike. We aim to enhance convenience, streamline
            operations, and foster a thriving community, all while delivering
            exceptional service.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AboutUsContent;
