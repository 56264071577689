import React, { useState } from 'react';

export const SelectContext=React.createContext()

export const SelectContextProvider=(props)=>{
    const [userFilterItems,setUserFilterItems]=useState([]);

    return(
        <SelectContext.Provider value={{userFilterItems,setUserFilterItems}}>
            { props.children }
        </SelectContext.Provider>
    )
}
