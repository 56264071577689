import React, { useState } from 'react';
import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useLoader } from 'layouts/loaderContext';
import { getFileUploadKey, compressImage } from 'common/utilFunctions';
import { graphqlOperation, API, Storage } from 'aws-amplify';
import { createGallaryPhoto } from 'graphql/mutations';
import { validateImageAspectRatio } from 'common/validators';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    margin: '0.8rem'
  },
  dragDropBox: {
    minHeight: '10rem',
    width: '100%',
    border: '2px dashed #C4C4C4',
    borderRadius: '0.4rem'
  },
  preview: {
    maxWidth: 100,
    maxHeight: 100,
    border: '1px solid #bbb',
    display: 'block',
    marginBottom: 8,
    marginRight: 8
  },
  topSpacing: {
    marginTop: '1rem'
  }
}));

const AddPhoto = ({ selectedServiceId, setIsAddPhoto, setGalleryPhotos }) => {
  const classes = useStyles();
  const { setLoading } = useLoader();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [photoGalleryImgs, setPhotoGalleryImgs] = useState([]);
  const [previewGalleryImg, setPreviewGalleryImg] = useState([]);
  const [photoName, setPhotoName] = useState('');

  const handleFileChange = (event) => {
    const {
      target: { value, files }
    } = event;
    const fileForUpload = files[0];
    readFile(fileForUpload || value);
  };

  const readFile = (_file) => {
    if (_file) {
      validateImageAspectRatio(_file, 3 / 2, (isValid) => {
        if (isValid) {
          const reader = new FileReader();
          reader.onload = () => {
            setPreviewGalleryImg((prevImgs) => [...prevImgs, reader.result]);
            setPhotoGalleryImgs((prevImgs) => [...prevImgs, _file]);
          };
          reader.readAsDataURL(_file);
        } else {
          enqueueSnackbar(
            `The uploaded image is not matching the desired ratio.`,
            {
              variant: 'error',
              persist: true
            }
          );
        }
      });
    }
  };

  const onCancel = () => {
    setPreviewGalleryImg([]);
    setPhotoGalleryImgs([]);
    setPhotoName('');
  };

  const onSaveHandler = async () => {
    setLoading(true);
    photoGalleryImgs.forEach(async (file) => {
      try {
        const { type: mimeType } = file;
        const compressedFile = await compressImage(file);
        const key = getFileUploadKey(compressedFile, 'images');
        const response = Storage.put(key, compressedFile, {
          contentType: mimeType,
          level: 'public'
        })
          .then(() => ({
            photoURL: key,
            MIMETypes: mimeType
          }))
          .catch((error) => {
            console.log('error', error);
            return null;
          });

        const result = await Promise.resolve(response);

        const data = await API.graphql(
          graphqlOperation(createGallaryPhoto, {
            input: {
              picture: { ...result, name: photoName },
              serviceID: selectedServiceId
            }
          })
        );
        if (data) {
          enqueueSnackbar(`Image uploaded successfully`, {
            variant: 'success',
            preventDuplicate: true,
            autoHideDuration: 2000
          });
          onCancel();
          setLoading(false);
          setIsAddPhoto(false);
          setGalleryPhotos((prevImgs) => [
            ...prevImgs,
            data.data.createGallaryPhoto
          ]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        setIsAddPhoto(false);
      }
    });
  };

  const handleNameChange = (e) => {
    setPhotoName(e.target.value);
  };

  return (
    <Paper className={classes.root}>
      <Grid container justify="space-evenly">
        <Grid container item xs={12} justify="center" alignItems="center">
          <Grid container item justify="center" xs={12}>
            {previewGalleryImg.length < 1 && (
              <Grid
                container
                item
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.dragDropBox}
                xs={12}>
                <label htmlFor="image-file">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    size="small">
                    Choose an image
                  </Button>
                </label>
                <Typography className={classes.topSpacing}>
                  Preferred image ratio <strong>3 x 2</strong>
                </Typography>
              </Grid>
            )}

            <Grid
              container
              item
              justify="center"
              style={{ padding: '1rem 0 0' }}>
              {previewGalleryImg?.length > 0 &&
                previewGalleryImg.map((img, i) => (
                  <Grid key={i} container justify="center" alignItems="center">
                    <img
                      className={classes.preview}
                      src={img}
                      alt="Product image"
                    />
                    <TextField
                      label="Image name"
                      margin="dense"
                      name="imgName"
                      onChange={handleNameChange}
                      value={photoName}
                      variant="outlined"
                    />
                  </Grid>
                ))}
              <input
                type="file"
                id="image-file"
                name="image-file"
                accept="image/*"
                onChange={handleFileChange}
                hidden
              />
            </Grid>
          </Grid>
          {previewGalleryImg.length > 0 && (
            <Grid
              container
              item
              justify="center"
              alignItems="center"
              xs={12}
              style={{ marginBottom: '1rem' }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={onCancel}
                style={{ marginRight: '0.5rem' }}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={onSaveHandler}
                disabled={photoGalleryImgs?.length < 1}>
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AddPhoto;
