import React from 'react';
import {
  List,
  ListItem,
  FormControlLabel,
  Checkbox,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import checkedIcon from '../assets/filterCheckedIcon.svg';
import uncheckedIcon from '../assets/filterUncheckedIcon.svg';
import { capitalizeStr } from 'common/utilFunctions';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  itemName: {
    marginLeft: '6px',
    fontSize: '14px',
    fontWeight: '500',
    color: 'black'
  },
  subFiltersList: {
    marginLeft: '0.5rem'
  },
  itemsList: {
    padding: 0
  }
}));

const FilterItems = ({
  filterData: data,
  shopFilters,
  updateShopFilters,
  className = ''
}) => {
  const classes = useStyles();

  return data.items?.length > 0 ? (
    <List className={clsx(classes.itemsList, className)}>
      {data.items.map((item, index) => {
        const isChecked = shopFilters[data.filterName].some(
          (id) => item.id === id
        );
        return (
          <React.Fragment key={`shop-filters-items-${item}-${index}`}>
            <ListItem disableGutters style={{ padding: 0 }}>
              <FormControlLabel
                style={{ marginLeft: '-0.5rem' }}
                checked={isChecked}
                onChange={updateShopFilters(item.id)}
                label={
                  <Typography className={classes.itemName}>
                    {capitalizeStr(item?.name)}
                  </Typography>
                }
                control={
                  <Checkbox
                    checkedIcon={<img src={checkedIcon} alt="checked" />}
                    icon={<img src={uncheckedIcon} alt="checked" />}
                  />
                }
              />
            </ListItem>
            {isChecked && item.subFilters?.length > 0 ? (
              <FilterItems
                filterData={{
                  ...data,
                  items: item.subFilters
                }}
                shopFilters={shopFilters}
                updateShopFilters={updateShopFilters}
                className={classes.subFiltersList}
              />
            ) : null}
          </React.Fragment>
        );
      })}
    </List>
  ) : null;
};

export default FilterItems;
