import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useAmplifyAuth } from 'context';
import { useShops } from 'context/shop/shopsContext';
import QRCode from 'qrcode.react';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { ShopQRCodePDF } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1rem',
    [theme.breakpoints.up('sm')]: {
      padding: '2rem'
    }
  },
  heading: {
    marginBottom: '1.5rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '3rem'
    }
  },
  shopName: {
    marginBottom: '0.5rem'
  },
  pDFViewer: {
    minHeight: '65vh'
  },
  extraTextField: {
    marginTop: '1rem',
    marginBottom: '0.5rem',
    [theme.breakpoints.up('md')]: {
      marginTop: 0
    }
  }
}));

const ShopQRCode = () => {
  const classes = useStyles();
  const {
    state: { user }
  } = useAmplifyAuth();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const { selectedShop, dispatch } = useShops();
  const [qrCodeURL, setQrCodeURL] = useState('');
  const [qrCodeImageURL, setQrCodeImageURL] = useState('');
  const [showPDF, setShowPDF] = useState(false);
  const [extraText, setExtraText] = useState('');

  useEffect(() => {
    if (!selectedShop && user?.shopId) {
      dispatch({
        type: 'getSelectedShop',
        payload: { shopId: user.shopId }
      });
    }
  }, [user]);

  useEffect(() => {
    if (!!selectedShop?.name)
      setQrCodeURL(
        `${process.env.REACT_APP_GOOGLE_SIGN_IN_REDIRECT_URL}/shop/${
          selectedShop.shopID
        }/${selectedShop.name.toLowerCase().split(' ').join('-')}`
      );
  }, [selectedShop]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const qrCodeCanvas = document.querySelector('#qrCode');
      if (qrCodeCanvas) {
        setQrCodeImageURL(qrCodeCanvas.toDataURL());
      }
    }, 100);

    return () => clearTimeout(timeout);
  }, [qrCodeURL]);

  const handleChange = (e) => setExtraText(e.target.value);

  return (
    <Grid
      container
      justify="center"
      direction="column"
      className={classes.root}>
      {/* header */}
      <Grid container className={classes.heading}>
        <Typography variant="h2">QR Code</Typography>
        {showPDF ? (
          <Button
            onClick={() => setShowPDF(false)}
            variant="contained"
            color="primary"
            size="small">
            Close
          </Button>
        ) : null}
      </Grid>
      {showPDF ? (
        <PDFViewer width="100%" height="100%" className={classes.pDFViewer}>
          <ShopQRCodePDF
            shop={selectedShop}
            qrCodeURL={qrCodeImageURL}
            adminMessage={extraText}
          />
        </PDFViewer>
      ) : (
        <Grid container justify="flex-start">
          <Grid
            container
            justify="center"
            direction="column"
            item
            xs={12}
            md={6}>
            {/* shop details */}
            <Typography
              variant="h3"
              align="center"
              className={classes.shopName}>
              {selectedShop?.name || ''}
            </Typography>
            <Typography variant="body1" align="center">{`${
              selectedShop?.address || ''
            }, ${selectedShop?.town || ''}, ${
              selectedShop?.postcode || ''
            }`}</Typography>

            {/* QR Code */}
            <Grid container justify="center" alignItems="center">
              <QRCode
                value={qrCodeURL}
                id="qrCode"
                level={'H'}
                includeMargin={true}
                size={256}
              />
            </Grid>
            <Typography variant="subtitle2" align="center">
              Scan this and place order..!
            </Typography>
          </Grid>

          {/* download qr code button block */}
          {!!selectedShop?.name ? (
            <Grid
              container
              justify="center"
              alignItems="center"
              item
              xs={12}
              md={6}
              lg={4}>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="column"
                item
                xs={12}
                sm={8}
                md={12}>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Any extra message you want to put in the pdf?"
                  value={extraText}
                  onChange={handleChange}
                  className={classes.extraTextField}
                  inputProps={{ maxLength: 72 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="body2">
                          {extraText.length}/72
                        </Typography>
                      </InputAdornment>
                    )
                  }}
                  fullWidth
                />
                {md ? (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => setShowPDF(true)}>
                    Download QR code
                  </Button>
                ) : (
                  <PDFDownloadLink
                    document={
                      <ShopQRCodePDF
                        shop={selectedShop}
                        qrCodeURL={qrCodeImageURL}
                        adminMessage={extraText}
                      />
                    }
                    fileName={`QR_Code_${selectedShop?.shopID || ''}`}>
                    {({ loading, error }) => (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!!(loading || error)}>
                        Download QR code PDF
                      </Button>
                    )}
                  </PDFDownloadLink>
                )}
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      )}
    </Grid>
  );
};

export default ShopQRCode;
