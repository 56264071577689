import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { validatePhoneNumber } from '../../../../common/validators';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Typography
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useAmplifyAuth } from '../../../../context';
import { graphqlOperation, API } from 'aws-amplify';
import { updateUserDetail } from '../../../../graphql/mutations';

const useStyles = makeStyles((theme) => ({
  root: { minWidth: '100%' },
  saveButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.text.primary
  },
  cancelButton: {
    color: theme.palette.text.secondary
  }
}));

const AccountDetails = (props) => {
  const { className, ...rest } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    state: { user },
    _refreshUser
  } = useAmplifyAuth();
  const classes = useStyles();
  const [values, setValues] = useState(user);
  const [formErrors, setFormErrors] = useState({
    phoneNumber: ''
  });
  const [enableActions, setEnableActions] = useState(false);

  useEffect(() => {
    user && setValues(user);
  }, [user]);

  const handleChange = (event) => {
    setEnableActions(true);
    if (event.target.name === 'phoneNumber') {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
      setFormErrors({
        ...formErrors,
        phoneNumber: validatePhoneNumber(event.target.value)
      });
    }
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (values.lastName === '') {
      enqueueSnackbar(<div>{'  Last name is a mandatory field.'}</div>, {
        variant: 'warning',
        preventDuplicate: true,
        persist: true
      });
      return;
    }
    if (values.firstName === '') {
      enqueueSnackbar(<div>{'  First name is a mandatory field.'}</div>, {
        variant: 'warning',
        preventDuplicate: true,
        persist: true
      });
      return;
    }
    if (values.phoneNumber === '') {
      enqueueSnackbar(<div>{'  Phone number is a mandatory field.'}</div>, {
        variant: 'warning',
        preventDuplicate: true,
        persist: true
      });
      return;
    }
    if (!!formErrors.phoneNumber) {
      enqueueSnackbar(formErrors.phoneNumber, {
        variant: 'warning',
        preventDuplicate: true,
        persist: true
      });
      return;
    }

    try {
      let sBar = enqueueSnackbar('Updating Account detail...', {
        variant: 'info',
        preventDuplicate: true
      });
      const data = await API.graphql(
        graphqlOperation(updateUserDetail, {
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber || ''
        })
      );
      setEnableActions(false);
      closeSnackbar(sBar);
      enqueueSnackbar('User updated successfully', {
        variant: 'success',
        preventDuplicate: true
      });
      _refreshUser();
    } catch (e) {
      console.error('Error', e);
    }
  };

  const onCancel = () => {
    setValues(user);
    setEnableActions(false);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate onSubmit={onSubmit}>
        <CardHeader title="Update Your Personal Details" />
        <Divider />
        <CardHeader title="Profile Information" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="First name"
                margin="dense"
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last name"
                margin="dense"
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={7} xs={12}>
              <TextField
                fullWidth
                label="Username"
                margin="dense"
                name="username"
                disabled
                value={values.username}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardHeader title="Personal Information" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                margin="dense"
                name="email"
                disabled
                value={values.email}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                margin="dense"
                name="phoneNumber"
                onChange={handleChange}
                type="text"
                error={
                  values?.phoneNumber?.length < 11 &&
                  values?.phoneNumber?.length > 0
                }
                helperText={formErrors.phoneNumber}
                value={values.phoneNumber}
                variant="outlined"
                inputProps={{ maxLength: 13 }}
                required
              />
            </Grid>
          </Grid>
          {user.createdAt && (
            <Grid container item xs={12}>
              <Typography color="textSecondary" variant="subtitle1">
                This account was created on
                {` ${moment(user.createdAt).format('MMM DD ,YYYY, h:mm:s a')}`}
              </Typography>
            </Grid>
          )}
        </CardContent>
        <Divider />
        <CardActions>
          <Grid
            container
            item
            direction="row"
            justify="flex-end"
            alignItems="center"
            xs={12}>
            <Button
              className={classes.cancelButton}
              variant="outlined"
              disabled={!enableActions}
              onClick={onCancel}>
              Cancel
            </Button>
            <Button
              className={classes.saveButton}
              variant="contained"
              disabled={!enableActions}
              type="submit">
              Save
            </Button>
          </Grid>
        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
