import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { AdsToolbar, AdsCard } from './components';
import AdsModal from './components/AdsModal';
import { useLoader } from 'layouts/loaderContext';
import { DocumentDeleteDialog } from 'components/organisms';
import { StyledTabs, StyledTab, TabPanel } from 'components/molecules';
import { Pagination } from '@material-ui/lab';
import { tabs, initialAdFilters } from './constants';
import { useAds } from 'context/ads/adsContext';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  tabPanelRoot: {
    width: '100%'
  }
}));

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`
});

const ITEMS_PER_PAGE = 6;

const AdsList = () => {
  const classes = useStyles();
  const [value, setValue] = useState('home');
  const [showAdModal, setShowAdModal] = useState(false);
  const { ads, dispatch } = useAds();
  const [selectedAd, setSelectedAd] = useState('');
  const [filters, setFilters] = useState(initialAdFilters);
  const [showResetButton, setShowResetButton] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { loading } = useLoader();
  const [confirmDialog, setConfirmDialog] = useState({
    confirmText: '',
    title: '',
    id: '',
    _version: 0
  });

  useEffect(() => {
    dispatch({ type: 'getAds' });
  }, []);

  const [page, setPage] = useState(1);
  const displayAds = ads
    .filter((ad) => ad.page === value)
    .filter((ad) =>
      filters.showCurrentAds
        ? !!(new Date(ad?.endDate) >= new Date() && !ad?.isClosed)
        : !(new Date(ad?.endDate) >= new Date() && !ad?.isClosed)
    )
    .filter((ad) => (filters.shop ? ad.shopID === filters.shop.id : true))
    .filter((ad) => (filters.type !== 'all' ? ad.type === filters.type : true))
    .filter((ad) =>
      filters.dateRange?.start && filters.dateRange?.end
        ? (filters.dateRange?.start <= ad.startDate &&
            ad.startDate <= filters.dateRange?.end) ||
          (filters.dateRange?.start <= ad.endDate &&
            ad.endDate <= filters.dateRange?.end)
        : true
    )
    .sort((a, b) => b._lastChangedAt - a._lastChangedAt);

  const filteredAds = displayAds?.slice(
    (page - 1) * ITEMS_PER_PAGE,
    page * ITEMS_PER_PAGE
  );

  const noOfPages = Math.ceil(displayAds?.length / ITEMS_PER_PAGE);

  const handleFilterUpdate = (e) => {
    setPage(1);
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    });
    setShowResetButton(true);
  };

  const handleResetFilters = () => {
    setShowResetButton(false);
    setFilters(initialAdFilters);
  };

  const paginationHandler = (event, value) => {
    setPage(value);
  };

  const handleUpdate = (id) => () => {
    setSelectedAd(id);
    setShowAdModal(true);
  };

  const handleDeleteClick = (id = '', _version = 1) => () => {
    setShowConfirmModal(true);
    setConfirmDialog({
      id,
      _version,
      confirmText: 'delete this Ad',
      title: 'Delete Ad'
    });
  };

  const handleModalCancel = () => {
    setShowConfirmModal(false);
    setConfirmDialog({
      id: '',
      _version: 0,
      confirmText: '',
      title: ''
    });
  };

  const handleDelete = (id = '', _version = 1) => () => {
    dispatch({ type: 'deleteAd', payload: { id, _version } });
    if (filteredAds.length === 1 && page !== 1) {
      setPage(page - 1);
    }
    handleModalCancel();
  };

  const handleClose = (id = '', _version = 1) => () => {
    const input = { id, _version, isClosed: true };
    dispatch({ type: 'updateAd', payload: { input } });
    if (filteredAds.length === 1 && page !== 1) {
      setPage(page - 1);
    }
  };

  const handleModalClose = () => {
    setShowAdModal(false);
    setSelectedAd('');
  };

  const handleAddBtnClick = () => {
    setShowAdModal(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPage(1);
  };

  return (
    <Grid container justify="center" className={classes.root}>
      <AdsToolbar
        handleClick={handleAddBtnClick}
        filters={filters}
        handleFilterUpdate={handleFilterUpdate}
        showResetButton={showResetButton}
        handleResetFilters={handleResetFilters}
      />

      <Grid container className={classes.content} justify="center">
        <StyledTabs value={value} onChange={handleChange}>
          {tabs.map((item, index) => (
            <StyledTab
              key={`ads-tabs-items-${index}`}
              label={item.label}
              value={item.value}
              {...a11yProps(index)}
            />
          ))}
        </StyledTabs>
      </Grid>

      {tabs.map((item, index) => (
        <TabPanel
          value={value}
          key={`ads-tabpanels-items-${index}`}
          index={item.value}
          className={classes.tabPanelRoot}>
          {!loading && filteredAds.length > 0 ? (
            <Grid container>
              {filteredAds?.map((ad) => (
                <Grid
                  item
                  key={`ads-items-${ad.id}`}
                  xs={12}
                  sm={ad?.page === 'list' ? 6 : 12}
                  lg={ad?.page === 'list' ? 4 : 12}>
                  <AdsCard
                    ad={ad}
                    handleUpdate={handleUpdate}
                    handleDelete={handleDeleteClick}
                    handleClose={handleClose}
                    ads={ads}
                  />
                </Grid>
              ))}
            </Grid>
          ) : null}
        </TabPanel>
      ))}

      {!loading && filteredAds.length === 0 ? (
        <Grid container justify="center" style={{ marginTop: '2rem' }}>
          <Typography align="center" variant="h4">
            No Ads Available
            {filters.shop ? ` for ${filters.shop.name}` : ''}
          </Typography>
        </Grid>
      ) : null}

      {noOfPages > 1 && (
        <Grid
          container
          item
          xs={12}
          justify="center"
          style={{ marginTop: '2rem' }}>
          <Pagination
            count={noOfPages}
            page={page}
            onChange={paginationHandler}
            defaultPage={1}
            color="secondary"
            shape="rounded"
          />
        </Grid>
      )}

      <AdsModal
        open={showAdModal}
        handleModalClose={handleModalClose}
        ads={ads}
        selectedAd={selectedAd}
      />
      <DocumentDeleteDialog
        open={showConfirmModal}
        title={confirmDialog.title}
        handleCancel={handleModalCancel}
        handleConfirm={handleDelete(confirmDialog.id, confirmDialog._version)}
        confirmText={confirmDialog.confirmText}
      />
    </Grid>
  );
};

export default AdsList;
