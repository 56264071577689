import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  AppBar,
  Badge,
  Hidden,
  IconButton,
  Typography,
  Switch,
  FormControlLabel,
  Chip,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Toolbar,
  Grid,
  useMediaQuery
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import InputIcon from '@material-ui/icons/Input';
import { useLoader } from 'layouts/loaderContext';
import { useAmplifyAuth } from '../../../../context';
import { useMessages } from 'context/messages/messagesContext';
import { useAuctions } from 'context/auctions/auctionsContext';
import { useShops } from 'context/shop/shopsContext';
import { API, graphqlOperation } from 'aws-amplify';
import { onCreateMessageNotification } from 'context/messages/messageSubscriptions';
import { MessageNotificationsMenu, NotificationsMenu } from './components';
import clsx from 'clsx';
import { useNotifications } from 'context/notifications/notificationsContext';
import { onCreateNotification } from 'graphql/subscriptions';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none'
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      marginRight: theme.spacing(1)
    }
  },
  headerLink: {
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 'calc(100% - 100px)'
    }
  },
  headerTitle: {
    color: 'white',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    verticalAlign: 'top',
    overflow: 'hidden'
  },
  labelStyle: {
    marginRight: '20px'
  },
  extraToolbar: {
    minHeight: '22px'
  },
  notificationsBtn: {
    '&.Mui-disabled': {
      color: 'white'
    }
  }
}));
const CHIP_COLOR_BY_STATUS = {
  pending: 'orange',
  active: 'green'
};

const StatusChip = ({ status = 'pending' }) => (
  <Chip
    color="primary"
    style={{
      backgroundColor: CHIP_COLOR_BY_STATUS[status] || 'red',
      fontWeight: '600'
    }}
    label={status}
  />
);

const Topbar = (props) => {
  const classes = useStyles();
  const { setLoading } = useLoader();
  const { className, onSidebarOpen, ...rest } = props;
  const { selectedShop, dispatch } = useShops();
  const {
    state: { user, isLaundryAdmin, isAdmin, isSales, isLaundryStaff }
  } = useAmplifyAuth();
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const [notificationsMenuEl, setNotificationsMenuEl] = useState(null);
  const { messageNotifications, dispatch: messagesDispatch } = useMessages();
  const { dispatch: auctionsDispatch } = useAuctions();
  const { notifications, dispatch: notificationDispatch } = useNotifications();
  const [showConfirmLiveDialog, setShowConfirmLiveDialog] = useState(false);
  const [newSwitchVal, setNewSwitchVal] = useState(
    selectedShop && selectedShop.isLive ? selectedShop.isLive : false
  );
  const { handleSignOut } = useAmplifyAuth();
  const sm = useMediaQuery(useTheme().breakpoints.up('sm'));

  useEffect(() => {
    let subscription;
    let subscription2;

    if (isLaundryAdmin && user?.shopId) {
      // get all notifications
      messagesDispatch({
        type: 'getMessageNotificationByUserOrShop',
        payload: { shopID: user.shopId }
      });

      // get all shop notification
      notificationDispatch({
        type: 'getNotifications',
        payload: { shopID: user.shopID || user.shopId }
      });

      // get all auctions
      auctionsDispatch({
        type: 'getAuctionsByShop',
        payload: { shopID: user.shopID || user.shopId }
      });

      // Subscribe to creation of message notifications
      subscription = API.graphql(
        graphqlOperation(onCreateMessageNotification, {
          shopID: user.shopId
        })
      ).subscribe({
        next: ({ _, value }) => {
          const newNotification = value?.data?.onCreateMessageNotification;
          // adding new notification data using a new custom get notification query to get sender details
          messagesDispatch({
            type: 'addNewNotification',
            payload: { notificationID: newNotification.id }
          });
        },
        error: (error) => console.warn(error)
      });

      // Subscribe to creation of all notifications
      subscription2 = API.graphql(
        graphqlOperation(onCreateNotification, {
          shopID: user.shopId
        })
      ).subscribe({
        next: ({ _, value }) => {
          const newNotification = value?.data?.onCreateNotification;
          notificationDispatch({
            type: 'addNewNotification',
            payload: { newNotification: newNotification }
          });
        },
        error: (error) => console.warn(error)
      });
    }

    return () => {
      subscription && subscription.unsubscribe();
      subscription2 && subscription2.unsubscribe();
    };
  }, [user]);

  const SignOut = () => {
    setLoading(true);
    handleSignOut().then(() => {
      setLoading(false);
      window.location.reload();
    });
  };

  useEffect(() => {
    if (!selectedShop && user?.shopId) {
      dispatch({
        type: 'getSelectedShop',
        payload: { shopId: user.shopId }
      });
    }
  }, [user]);

  const handleMenuClose = () => {
    setAnchorMenuEl(null);
    setNotificationsMenuEl(null);
  };

  const handleIconClick = (event, type) => {
    if (type === 'message') setAnchorMenuEl(event.currentTarget);
    else setNotificationsMenuEl(event.currentTarget);
  };

  const onSwitch = (e, newVal) => {
    setNewSwitchVal(newVal);
    setShowConfirmLiveDialog(true);
  };

  const handleChange = () => {
    const input = {
      id: selectedShop.id,
      isLive: newSwitchVal,
      _version: selectedShop._version
    };
    dispatch({
      type: 'updateShopInDB',
      payload: { input, hideSnackbar: true }
    });
    setShowConfirmLiveDialog(false);
  };

  const handleClose = () => setShowConfirmLiveDialog(false);

  return (
    <>
      <AppBar {...rest} className={clsx(classes.root, className)}>
        <Toolbar>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={12} sm={7} container alignItems="center">
              <RouterLink to="/" className={classes.headerLink}>
                <Typography
                  className={classes.headerTitle}
                  variant="h4"
                  title={
                    !!((isLaundryAdmin || isLaundryStaff) && selectedShop)
                      ? `${selectedShop.name} (${selectedShop.shopID})`
                      : null
                  }>
                  {isAdmin ? (
                    'Admin'
                  ) : isSales ? (
                    'Sales'
                  ) : !!((isLaundryAdmin || isLaundryStaff) && selectedShop) ? (
                    <>
                      {`${selectedShop.name} (${selectedShop.shopID}) `}
                      &nbsp;
                    </>
                  ) : null}
                </Typography>
              </RouterLink>
              {!!(sm && (isLaundryAdmin || isLaundryStaff)) ? (
                <StatusChip status={selectedShop?.status || 'pending'} />
              ) : null}
            </Grid>
            <Grid
              container
              justify={sm ? 'flex-end' : 'space-evenly'}
              alignItems="center"
              item
              style={{ paddingTop: sm ? 0 : '10px' }}
              md={5}
              xs={12}>
              {!sm && (isLaundryAdmin || isLaundryStaff) && (
                <StatusChip status={selectedShop?.status || 'pending'} />
              )}
              {isLaundryAdmin &&
                selectedShop &&
                selectedShop.status === 'active' && (
                  <FormControlLabel
                    labelPlacement="start"
                    classes={{ labelPlacementStart: classes.labelStyle }}
                    control={
                      <Switch
                        checked={
                          selectedShop && selectedShop.isLive
                            ? selectedShop.isLive
                            : false
                        }
                        onChange={onSwitch}
                        name="isLive"
                        inputProps={{
                          'aria-label': 'topbar-shop-isLive-switch'
                        }}
                      />
                    }
                    label={
                      <Typography style={{ color: 'white' }}>
                        {selectedShop && selectedShop.isLive
                          ? 'Online'
                          : 'Offline'}
                      </Typography>
                    }
                  />
                )}

              {process.env.REACT_APP_AUCTIONS === 'enabled' ? (
                <Grid item>
                  <IconButton
                    color="inherit"
                    className={classes.notificationsBtn}
                    onClick={(e) => handleIconClick(e, 'notification')}
                    disabled={!notifications?.length}>
                    <Badge
                      badgeContent={notifications?.length || 0}
                      color="secondary"
                      variant="standard">
                      <NotificationsNoneIcon />
                    </Badge>
                  </IconButton>
                  <NotificationsMenu
                    notificationsMenuEl={notificationsMenuEl}
                    handleClose={handleMenuClose}
                  />
                </Grid>
              ) : null}

              <Grid item>
                <IconButton
                  color="inherit"
                  className={classes.notificationsBtn}
                  onClick={(e) => handleIconClick(e, 'message')}
                  disabled={!messageNotifications?.length}>
                  <Badge
                    badgeContent={messageNotifications?.length || 0}
                    color="secondary"
                    variant="standard">
                    <MailOutlineIcon />
                  </Badge>
                </IconButton>
                <MessageNotificationsMenu
                  anchorEl={anchorMenuEl}
                  handleClose={handleMenuClose}
                />
              </Grid>
              <IconButton
                className={classes.signOutButton}
                color="inherit"
                onClick={SignOut}>
                <InputIcon />
              </IconButton>
              <Hidden lgUp>
                <IconButton color="inherit" onClick={onSidebarOpen}>
                  <MenuIcon />
                </IconButton>
              </Hidden>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {!sm && <Toolbar classes={{ root: classes.extraToolbar }} />}
      <Dialog
        open={showConfirmLiveDialog}
        onClose={handleClose}
        aria-labelledby="confirm-live-switch-dialog-title"
        aria-describedby="confirm-live-switch-dialog-description">
        <DialogTitle id="confirm-live-switch-dialog-title">
          Are you sure you want to update the shop's status to
          <strong>
            {selectedShop && selectedShop.isLive ? ' Offline' : ' Online'}?
          </strong>
        </DialogTitle>
        <DialogActions>
          <Button size="small" onClick={handleClose} variant="outlined">
            No
          </Button>
          <Button
            size="small"
            onClick={handleChange}
            color="primary"
            variant="outlined">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
