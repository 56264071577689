import React, {
  createContext,
  useCallback,
  useContext,
  useReducer
} from 'react';
import searchBackground from 'assets/png/main_banner2.png';
import searchBackgroundMobile from 'assets/png/main_banner_mobile.png';
import * as searchBannerAPIs from './searchBannerAPIs';

const defaultBanner = {
  image: searchBackground,
  mobileImage: searchBackgroundMobile,
  title: 'Search nearby dry cleaners',
  color: '#fff'
};
const SearchBannerContext = createContext({});

const searchBannerReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'updateData': {
      return payload || defaultBanner;
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

const SearchBannerProvider = (props) => {
  const [banner, dispatch] = useReducer(searchBannerReducer, {});

  const asyncDispatch = useCallback(
    async (action) => {
      switch (action.type) {
        case 'getBanner': {
          if (banner?.image) return;

          try {
            let currentBanner = await searchBannerAPIs.loadRandomBanner();
            if (!currentBanner?.image) {
              currentBanner = defaultBanner;
            }
            dispatch({
              type: 'updateData',
              payload: currentBanner
            });
          } catch (err) {
            console.error('Something went wrong... ', err);
            dispatch({ type: 'updateData' });
          }
          break;
        }
        default:
          dispatch(action);
      }
    },
    [banner]
  );

  const value = {
    banner,
    dispatch: asyncDispatch
  };

  return (
    <SearchBannerContext.Provider value={value}>
      {props.children}
    </SearchBannerContext.Provider>
  );
};

const useSearchBanner = () => {
  const context = useContext(SearchBannerContext);
  if (!(context && Object.keys(context).length)) {
    throw new Error(
      'useSearchBanner must be used within a SearchBannerContext'
    );
  }
  return context;
};

export { useSearchBanner, SearchBannerProvider };
