import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: 10
  },
  cardContainer: {
    maxWidth: 'calc(100vw * 0.9)',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '13.875rem'
    }
  }
}));

const ShopListShopCardSkeleton = () => {
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const sm = useMediaQuery(theme.breakpoints.up('sm'));

  const NUMBER_OF_SHOP_CARD = md ? 4 : sm ? 3 : 1;
  const CARD_WIDTH = md ? 3 : sm ? 4 : 12;

  return (
    <Grid container item xs={12}>
      {[...Array(4).keys()].map((item) => {
        return (
          <Grid container item style={{ marginBottom: 30 }} key={item}>
            <Skeleton
              animation="wave"
              variant="text"
              width="15%"
              height={30}
              style={{ marginBottom: 10 }}
            />
            <Grid
              container
              item
              justify={NUMBER_OF_SHOP_CARD === 1 ? 'center' : 'space-between'}>
              {[...Array(NUMBER_OF_SHOP_CARD).keys()].map((item) => {
                return (
                  <Grid
                    key={item}
                    container
                    item
                    xs={CARD_WIDTH}
                    className={classes.cardContainer}>
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      width="100%"
                      height={118}
                      style={{ marginBottom: 10 }}
                    />
                    <Skeleton animation="wave" variant="text" width="59%" />
                    <Grid container item xs={12} spacing={2}>
                      {[...Array(3).keys()].map((item) => {
                        return (
                          <Grid item xs={4} key={item}>
                            <Skeleton
                              animation="wave"
                              variant="text"
                              width="100%"
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Skeleton animation="wave" variant="text" width="100%" />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ShopListShopCardSkeleton;
