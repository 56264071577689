import { API, Auth, Storage, graphqlOperation } from 'aws-amplify';
import { v4 as uuid } from 'uuid';
import { RADIUS_OF_EARTH, num, tens } from './constants';
import { getLatLngByPostCode } from 'graphql/queries';

export const showPrice = (value = 0, separator = '') =>
  typeof value === 'number' ? `£${separator}${value.toFixed(2)}` : '';

export const capitalizeStr = (str) =>
  str
    .split(' ')
    .filter((item) => item !== '')
    .map((item) => `${item[0].toUpperCase()}${item.substr(1).toLowerCase()}`)
    .join(' ');

export const getOrderTotal = (order) =>
  order.orderList?.length > 0
    ? order.orderList
        .map(
          (item) =>
            item.totalPrice ||
            item.price * item.quantity - (item.overallDiscount || 0)
        )
        .reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0
        )
    : 0;

export const getOrderDiscountTotal = (order) => {
  let directDiscount = 0,
    couponDiscount = order.discount || 0;
  if (order.orderList?.length > 0)
    order.orderList.forEach((item) => {
      const currentDiscount = item.discount
        ? item.discount * item.quantity
        : item.overallDiscount || 0;
      const isConditional = item?.discountCoupon
        ? JSON.parse(item?.discountCoupon).discountType === 'conditional'
        : false;
      if (isConditional) {
        couponDiscount += currentDiscount;
      } else {
        directDiscount += currentDiscount;
      }
    });

  return [directDiscount, couponDiscount, directDiscount + couponDiscount];
};

export const getFormattedPhoneNumber = (phoneNumber = '') =>
  !phoneNumber
    ? ''
    : !!(phoneNumber.startsWith('44') || phoneNumber.startsWith('91'))
    ? `+${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2)}`
    : phoneNumber;

export const getTotalQuantity = (order) =>
  order.orderList?.length > 0
    ? order.orderList
        .map((item) => item.quantity)
        .reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0
        )
    : 0;

export const getOrderTotalRating = (items = []) => {
  if (items.length === 0) return 0;
  const totalRatings = items.reduce((total, curr) => {
    return total + curr.rating;
  }, 0);
  const averageRating = totalRatings / items.length;
  return +averageRating.toFixed(1);
};

export const getShopTotalRating = (items = []) => {
  if (items.length === 0) return 0;
  const totalRating = items.reduce(
    (total, currentReview) =>
      total + getOrderTotalRating(currentReview.ratings),
    0
  );
  const averageRating = totalRating / items.length;
  return +averageRating.toFixed(1);
};

export const getFileUploadKey = (file, folder = 'images') =>
  `${folder}/${uuid()}.${file.name.split('.').at(-1)}`;

export const unitFormatter = (str = '', separator = ' ') =>
  !!str
    ? `${str.charAt(0).toUpperCase()}${str.substring(1)}`.replace(
        /([a-z])([A-Z])/g,
        `$1${separator}$2`
      )
    : 'N/A';

export const getStorageUrl = async (url, accessLevel = 'public') => {
  try {
    const result = await Storage.get(url, {
      level: accessLevel
    });
    return result;
  } catch (error) {
    console.log('error', error);
    return '';
  }
};

export const isExternalUser = async () => {
  const data = await Auth.currentAuthenticatedUser();
  return !!data.attributes.identities;
};

export const getFormattedName = (user, withTitle = true) =>
  `${withTitle ? `${user?.title || ''} ` : ''}${user?.firstName || ''} ${
    user?.lastName || ''
  }`.trim();

export const uploadImage = async (file, folder = 'images') => {
  const { type: mimeType } = file;
  const key = getFileUploadKey(file, folder);
  try {
    await Storage.put(key, file, {
      contentType: mimeType,
      level: 'public'
    });
    return key;
  } catch (err) {
    console.error('error: ', err);
    return '';
  }
};

const inWordConvertion = (n = 0) => {
  if (n < 20) return num[n];
  const digit = n % 10;
  if (n < 100) return tens[~~(n / 10) - 2] + (digit ? '-' + num[digit] : '');
  if (n < 1000)
    return (
      num[~~(n / 100)] +
      ' hundred' +
      (n % 100 === 0 ? '' : ' ' + inWordConvertion(n % 100))
    );
  return (
    inWordConvertion(~~(n / 1000)) +
    ' thousand' +
    (n % 1000 !== 0 ? ' ' + inWordConvertion(n % 1000) : '')
  );
};

export const inWords = (n = 0) => {
  if (typeof n === 'number') {
    const convertNumberToString = n
      .toFixed(Number.isInteger(n) ? 0 : 2)
      .split('.');
    return `${inWordConvertion(parseInt(convertNumberToString[0]))} pounds${
      convertNumberToString.length > 1
        ? ` and ${inWordConvertion(parseInt(convertNumberToString[1]))} penny`
        : ''
    }`;
  } else {
    return '';
  }
};

export const getCollectionAndDeliveryFee = (order) =>
  showPrice(
    order?.collectionAndDeliveryFee -
      (order?.collection?.type === 'asap'
        ? order?.currentShopFees?.AsapDeliveryFee
        : 0) || 0
  );

export const getCollectionAndDeliveryFeeText = (order) =>
  `${
    !(order?.collection?.address || order?.delivery?.address)
      ? 'Collection and Delivery'
      : ''
  }${order?.collection?.address ? 'Collection' : ''}${
    order?.collection?.address && order?.delivery?.address ? ' and ' : ''
  }${order?.delivery?.address ? 'Delivery' : ''} Fee`;

export const showPartialPaymentRecord = (payments = [], total) =>
  !(payments.length === 1 && payments[0].amount === total);

export const getCustomShopProductName = (productName = '', serviceID = '') =>
  `shopProduct_${serviceID}_${productName.split(' ').join('_')}`;

// TODO Image compression Logic

const fileToDataUri = (field) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      resolve(reader.result);
    });
    reader.readAsDataURL(field);
  });
};

export const compressImage = async (_imageFile) => {
  const { name, type, size } = _imageFile;
  const imgToCompress = new Image();
  const originalImage2 = await fileToDataUri(_imageFile);
  imgToCompress.src = originalImage2;
  const sizeInKb = size / 1024;

  try {
    if (sizeInKb > 500) {
      return await compressImageHandler(imgToCompress, 0.5, 0.5, type, name);
    } else if (sizeInKb > 50) {
      return await compressImageHandler(imgToCompress, 0.8, 0.5, type, name);
    } else {
      return _imageFile;
    }
  } catch (e) {
    console.error(e);
    return _imageFile;
  }
};

const compressImageHandler = async (
  imgToCompress,
  resizingFactor,
  quality,
  type,
  name
) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  const originalWidth = imgToCompress.width;
  const originalHeight = imgToCompress.height;

  const canvasWidth = originalWidth * resizingFactor;
  const canvasHeight = originalHeight * resizingFactor;

  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  context.drawImage(
    imgToCompress,
    1,
    1,
    originalWidth * resizingFactor,
    originalHeight * resizingFactor
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(new File([blob], name));
        } else {
          reject(new Error('Erroe in compression: Blob not created!'));
        }
      },
      type,
      quality
    );
  });
};

// search a string in a list of items
export const searchStringInItems = (str = '', items = []) =>
  items.some(
    (item) =>
      item.toLowerCase().includes(str) ||
      item.toLowerCase().split(' ').join('').includes(str)
  );

export const calculatePriceWithDiscount = (price, discount, unit) => {
  let newPrice;
  try {
    newPrice =
      unit === 'amount'
        ? price - discount
        : unit === 'percentage'
        ? (price * (100 - discount)) / 100
        : price;
  } catch (error) {
    console.error('something went wrong', error);
    newPrice = price;
  }
  return newPrice;
};

export const checkCouponCondition = (
  conditionUnit = '',
  conditionAmount = 0,
  cart = [],
  productID = ''
) =>
  conditionUnit === 'noOfItems'
    ? cart.find((item) => item?.id === productID)?.quantity >= conditionAmount
    : conditionUnit === 'amount'
    ? getOrderTotal({ orderList: cart }) >= conditionAmount
    : false;

// convert text to proper url for hyperlink
export const getRedirectUrl = (link = '') =>
  !!(link.startsWith('http://') || link.startsWith('https://'))
    ? link
    : `//${link}`;

// price range input: 10-20, output: £10 - £20 or input: 10-, output: £10+
export const getFormattedPriceRange = (priceRange = '', quantity = 1) => {
  const priceRangeArr =
    priceRange
      ?.split('-')
      .filter((_item) => !!_item)
      .map((_item) => parseFloat(_item) * quantity) || [];

  if (priceRangeArr?.length === 2) {
    const isValid = priceRangeArr[0] < priceRangeArr[1];
    if (isValid) {
      return priceRangeArr.map((item) => showPrice(item)).join(' - ');
    } else {
      return `${showPrice(priceRangeArr.at(0))}+`;
    }
  } else if (priceRangeArr?.length === 1)
    return `${showPrice(priceRangeArr.at(0))}+`;
  return '';
};

// calculate total variable amount from cart items
export const calculateVariablePrice = (cartItems = []) =>
  cartItems.filter((item) => !!item.priceRange)?.length > 0
    ? cartItems
        .filter((item) => !!item.priceRange)
        .map((item) =>
          item.priceRange
            .split('-')
            .filter((price) => !!price)
            .map((price) => parseFloat(price) * item.quantity)
        )
        .reduce((p, c) => [p[0] + c[0], p[1] + (c[1] || 0)], [0, 0])
        .join('-')
    : '';

// add range to a number, eg: 20+'10-20'='30-40'
export const addRangeToPrice = (price = 0, range = '') =>
  range
    .split('-')
    .filter((item) => !!item)
    .map((item) => parseFloat(item) + price)
    .join('-');

// get slug for any string
export const getStringAsSlug = (name = '') =>
  name.trim().toLowerCase().split(/\s+/).join('-');

// get distance between postcodes in miles using Haversine formula
export const getDistanceBetweenPostcodesInMiles = async (
  location,
  postcode
) => {
  // getting lat lng for postcodes
  const selectedShopLng = location.lng;
  const selectedShopLat = location.lat;

  const resp = await API.graphql(
    graphqlOperation(getLatLngByPostCode, {
      postalCode: postcode
    })
  );

  // using for calculating distances
  const lon1 = (selectedShopLng * Math.PI) / 180;
  const lon2 = (resp.data.getLatLngByPostCode.lng * Math.PI) / 180;
  const lat1 = (selectedShopLat * Math.PI) / 180;
  const lat2 = (resp.data.getLatLngByPostCode.lat * Math.PI) / 180;
  const dlon = lon1 - lon2;
  const dlat = lat2 - lat1;
  const a =
    Math.pow(Math.sin(dlat / 2), 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);
  const c = 2 * Math.asin(Math.sqrt(a));
  const distance = Math.round(c * RADIUS_OF_EARTH * 100) / 100;
  return distance;
};

export const shouldApplyCollectionOrDeliveryFee = (
  subTotal,
  minPriceForFree,
  distance,
  distanceRangeForFree
) =>
  !!(!!minPriceForFree && !!distanceRangeForFree)
    ? subTotal < minPriceForFree || distance > distanceRangeForFree
    : !!minPriceForFree
    ? subTotal < minPriceForFree
    : !!distanceRangeForFree
    ? distance > distanceRangeForFree
    : true;
