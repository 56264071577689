import React, { useContext, useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { onCreateMessageNotification } from 'context/messages/messageSubscriptions';
import { Link, useHistory } from 'react-router-dom';
import {
  Grid,
  Button,
  Container,
  IconButton,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { StyledBadge } from 'components/molecules';
import { CartContext } from 'context/cart/cartContext';
import SignInButton from 'components/organisms/SignInButton';
import CartMenu from './CartMenu';
import LogoName from './LogoName';
import JoinLinks from './JoinLinks';
import { useAmplifyAuth } from 'context';
import { useMessages } from 'context/messages/messagesContext';
import { routes } from 'common/constants';
import CreateAuctionButton from './CreateAuctionButton';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#1f6893',
    padding: '0.75rem 0',
    [theme.breakpoints.up('md')]: {
      padding: 0
    }
  },
  container: {
    maxWidth: '100%',
    margin: '0 auto',
    padding: '0 0.5rem',
    [theme.breakpoints.up('md')]: {
      maxWidth: '90%',
      padding: 0
    }
  },
  main: {
    gap: '1rem',
    justifyContent: 'center',
    '@media (min-width:481px)': {
      justifyContent: 'space-between'
    }
  },
  cartBtn: {
    padding: '0.25rem',
    color: 'rgb(255 255 255/0.7)',
    textTransform: 'none',
    fontSize: '12px',
    minWidth: 0,
    '&.Mui-disabled': {
      color: 'rgb(255 255 255/0.7)'
    }
  },
  knowledgeBtn: {
    fontWeight: 600,
    fontSize: '13px',
    color: 'rgb(255 255 255/0.7)',
    padding: 0,
    paddingRight: '15px',
    borderRight: '1px solid rgb(255 255 255/0.2)',
    borderRadius: 0,
    textTransform: 'none'
  },
  offerNavRightDiv: {
    width: 'fit-content',
    gap: '0.25rem',
    [theme.breakpoints.up('sm')]: {
      gap: '1rem'
    }
  }
}));

const NotificationBadge = ({ size, ...props }) => (
  <StyledBadge {...props}>
    <MailOutlineIcon style={{ fontSize: size }} />
  </StyledBadge>
);

const CartBadge = ({ size, ...props }) => (
  <StyledBadge max={9999} {...props}>
    <ShoppingCartOutlinedIcon style={{ fontSize: size }} />
  </StyledBadge>
);

const OfferNav = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const { cart } = useContext(CartContext);
  const {
    state: { user }
  } = useAmplifyAuth();
  const { messageNotifications, dispatch } = useMessages();
  const [anchorEl, setAnchorEl] = useState(null);
  const cartItemsCount = cart.items?.reduce((r, o) => o.quantity + r, 0);

  useEffect(() => {
    let subscription;
    if (user?.id || user?.sub) {
      // get all notifications
      dispatch({
        type: 'getMessageNotificationByUserOrShop',
        payload: { userID: user.id || user.sub }
      });

      // Subscribe to creation of message notifications
      subscription = API.graphql(
        graphqlOperation(onCreateMessageNotification, {
          userID: user.id
        })
      ).subscribe({
        next: ({ _, value }) => {
          const newNotification = value?.data?.onCreateMessageNotification;
          dispatch({
            type: 'addMessageNotifications',
            payload: [newNotification]
          });
        },
        error: (error) => console.warn(error)
      });
    }

    return () => subscription && subscription.unsubscribe();
  }, [user]);

  const handleCartMenuSwitch = (event) => setAnchorEl(event.currentTarget);

  const handleCartOnClick = (e) => handleCartMenuSwitch(e);

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Grid container direction="row" className={classes.main}>
          <Grid
            item
            container
            alignItems="center"
            style={{ width: 'fit-content', padding: 0 }}>
            {md ? (
              <JoinLinks />
            ) : (
              <LogoName light size={md ? 'normal' : 'small'} />
            )}
          </Grid>
          <Grid
            container
            item
            justify="flex-end"
            alignItems="center"
            className={classes.offerNavRightDiv}>
            {/* create auction button */}
            {process.env.REACT_APP_AUCTIONS === 'enabled' && (
              <CreateAuctionButton />
            )}

            {/* sign up button */}
            <SignInButton />

            {/* notifications icon */}
            <IconButton
              component={Link}
              to={!messageNotifications?.length ? '#' : routes.customer.chat}
              className={classes.cartBtn}
              disabled={!messageNotifications?.length}>
              <NotificationBadge
                badgeContent={messageNotifications?.length || 0}
                size={md ? '1.3rem' : '1.5rem'}
              />
            </IconButton>

            <>
              {sm ? (
                <Button
                  startIcon={
                    <CartBadge
                      badgeContent={cartItemsCount}
                      size={md ? '1.3rem' : '1.5rem'}
                    />
                  }
                  size="small"
                  disabled={
                    history.location.pathname.split('/').at(-1) === 'checkout'
                  }
                  onClick={handleCartOnClick}
                  className={classes.cartBtn}>
                  Cart
                </Button>
              ) : (
                <IconButton
                  size="small"
                  onClick={handleCartOnClick}
                  disabled={
                    history.location.pathname.split('/').at(-1) === 'checkout'
                  }
                  className={classes.cartBtn}>
                  <CartBadge
                    badgeContent={cartItemsCount}
                    size={md ? '1.3rem' : '1.5rem'}
                  />
                </IconButton>
              )}

              {cart.items?.length > 0 && anchorEl !== null && (
                <CartMenu
                  anchorEl={anchorEl}
                  cartItems={cart.items}
                  setAnchorEl={setAnchorEl}
                />
              )}
            </>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default OfferNav;
