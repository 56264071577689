import { favouriteShopItem } from './favouriteShopsQueries';

export const createFavouriteShop = /* GraphQL */ `
  mutation CreateFavouriteShop(
    $input: CreateFavouriteShopInput!
    $condition: ModelFavouriteShopConditionInput
  ) {
    createFavouriteShop(input: $input, condition: $condition) ${favouriteShopItem}
  }
`;
export const updateFavouriteShop = /* GraphQL */ `
  mutation UpdateFavouriteShop(
    $input: UpdateFavouriteShopInput!
    $condition: ModelFavouriteShopConditionInput
  ) {
    updateFavouriteShop(input: $input, condition: $condition) ${favouriteShopItem}
  }
`;
