import {
  AppBar,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  makeStyles
} from '@material-ui/core';
import { TabPanel } from 'components/molecules';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import OrdersTable from './components/OrdersTable';
import OrderDetailsDialog from './components/dialogs/OrderDetailsDialog';
import { routes } from 'common/constants';
import { useAmplifyAuth } from 'context';
import { useOrders } from 'context/order/ordersContext';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: '12px'
  },
  title: {
    margin: '25px 0 10px'
  },
  tabsBarRoot: {
    backgroundColor: 'transparent',
    color: '#263238',
    marginTop: '2rem',
    paddingBottom: '1rem',
    boxShadow: 'none'
  },
  tabsBarContent: {
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  }
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const ForwardOrders = () => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const [dialogType, setDialogType] = useState(null);
  const [payload, setPayload] = useState({});
  const { dispatch } = useOrders();

  const {
    state: { user }
  } = useAmplifyAuth();

  const loadOrders = (useSearchString = true, newQuery = true) => {
    if (user?.shopId || user?.shopID) {
      const showCurrentRequests = tabIndex === 0 ? true : false;
      if (tabIndex === 1) {
        dispatch({
          type: 'getOrderForwardRequestByShopId',
          payload: {
            shopId: user?.shopId || user?.shopID
          }
        });
      } else {
        dispatch({
          type: 'getOrderForwardShopByShopId',
          payload: {
            shopId: user?.shopId || user?.shopID,
            showCurrentRequests
          }
        });
      }
    }
  };

  useEffect(() => {
    loadOrders();
  }, [user, tabIndex]);

  const handleChange = (event, newTabInxex) => setTabIndex(newTabInxex);

  const updateOrder = (order, sts) => {
    if (sts === 'pending') {
      dispatch({
        type: 'updateOrderForwardRequest',
        payload: { order, sts, shopId: user?.shopId || user?.shopID }
      });
    } else if (sts === 'rejected') {
      dispatch({
        type: 'rejectOrderForwardShop',
        payload: { order, sts, shopId: user?.shopId || user?.shopID }
      });
    } else {
      setPayload({ order, sts });
      setDialogType(sts);
    }
  };

  const handleModalClose = () => {
    setDialogType(null);
  };

  const ordersTableCommonProps = {
    updateOrder
  };

  return (
    <div className={classes.root}>
      <Grid container justify="space-between" style={{ rowGap: '0.5rem' }}>
        <Grid
          container
          item
          xs={12}
          sm={6}
          md={3}
          justify="flex-start"
          alignItems="center">
          <Link to={routes.serviceProvider.orders}>
            <IconButton variant="outlined" size="small">
              <ArrowBackIcon />
            </IconButton>
          </Link>
          <Typography style={{ marginLeft: '1.5rem' }} variant="h5">
            Forward Orders
          </Typography>
        </Grid>
      </Grid>
      <AppBar position="static" className={classes.tabsBarRoot}>
        <Grid
          container
          justify="space-between"
          className={classes.tabsBarContent}>
          <Grid container item xs={12} md={8} justify="flex-start">
            <Tabs
              value={tabIndex}
              onChange={handleChange}
              aria-label="simple tabs example">
              <Tab label="Forward Requests" {...a11yProps(0)} />
              <Tab label="Forwarded Orders" {...a11yProps(1)} />
              <Tab label="Missed Requests" {...a11yProps(2)} />
            </Tabs>
          </Grid>
        </Grid>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <OrdersTable
          stage={10}
          headerTitle="All Forward Requests"
          {...ordersTableCommonProps}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <OrdersTable
          stage={11}
          headerTitle="All Forwarded Orders"
          {...ordersTableCommonProps}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <OrdersTable
          stage={12}
          headerTitle="All Missed Forward Order Requests"
          {...ordersTableCommonProps}
        />
      </TabPanel>

      <OrderDetailsDialog
        dialogType={dialogType}
        order={payload.order}
        handleModalClose={handleModalClose}
      />
    </div>
  );
};

export default ForwardOrders;
