import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 300
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(2)
  },
  button: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.black,
    color: theme.palette.white,
    '&:hover': {
      color: theme.palette.black
    }
  }
}));

const Sidebar = React.forwardRef((props, ref) => {
  const { open, variant, onClose, setOpen, className, ...rest } = props;

  const classes = useStyles();

  return (
    <Drawer
      ref={ref}
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={() => setOpen(false)}
      open={open}>
      <div {...rest} className={clsx(classes.root, className)}>
        <Button className={classes.button}>Signin</Button>
        <div className={classes.content}>
          <Typography variant="h4">Create Bussiness Account</Typography>
          <Typography variant="h4">Add your Laundry Shop</Typography>
        </div>
      </div>
    </Drawer>
  );
});

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default Sidebar;
