import React, { useState, useEffect, useRef } from 'react';
import { Grid, RadioGroup, useMediaQuery } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import TinySlider from 'tiny-slider-react';
import { CarouselNavRadio } from 'components/molecules';
import TESTIMONIAL_DATA from '../constants/TestimonialContent';
import QuotesIcon from '../assets/Qoutes.png';
import 'tiny-slider/dist/tiny-slider.css';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: '#edd242',
    padding: '2.5rem 1.5rem 0',
    marginTop: '3.5rem',
    [theme.breakpoints.up('md')]: {
      padding: '2.5rem 0 0',
      marginTop: '5rem',
      paddingLeft: '5em'
    }
  },
  leftPadding: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '2.5rem'
    },
    [theme.breakpoints.up('lg')]: {
      paddingRight: '4rem'
    }
  },
  tmolHeadingTitle: {
    fontWeight: 800,
    fontSize: '1.3rem',
    lineHeight: '0.875rem',
    textAlign: 'center',
    color: theme.palette.primary.main,
    [theme.breakpoints.up('md')]: {
      textAlign: 'left'
    }
  },
  tmolHeadingText: {
    fontWeight: 600,
    fontSize: '1.2rem',
    textAlign: 'center',
    color: '#444',
    marginTop: '1.5rem',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      width: '95%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '90%'
    }
  },
  tmolItem: {
    height: 'fit-content',
    padding: '2.5rem 0 0',
    [theme.breakpoints.up('md')]: {
      padding: '0 0 2rem 2.5rem',
      minWidth: '35rem'
    }
  },
  scrollBtnsContainer: {
    position: 'relative',
    left: '37.5%',
    top: '0%',
    padding: '1rem 0 2.5rem',
    [theme.breakpoints.up('sm')]: {
      left: '45%'
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 0 2.5rem',
      left: '32%'
    }
  },
  scrollBtnsGroup: {
    gap: '0 0.25rem'
  }
}));
const SLIDER_SETTINGS = {
  items: 1,
  speed: 400,
  lazyload: false,
  mouseDrag: true,
  slideBy: 1,
  autoplay: false,
  controls: false,
  center: false,
  nav: false,
  rewind: true,
  responsive: {
    990: {
      items: 1.9
    },
    1920: {
      items: 3
    }
  }
};

const SubFooter = () => {
  const classes = useStyles();
  const theme = useTheme();
  const sliderRef = useRef(null);
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const [tmolIndex, setTmolIndex] = useState(0);
  const maxContentChar = md ? 300 : 200;

  useEffect(() => {
    const handleSliderTransitionEnd = (sliderInfo) =>
      setTmolIndex(sliderInfo.index);

    if (sliderRef.current) {
      sliderRef.current.slider.events.on(
        'transitionEnd',
        handleSliderTransitionEnd
      );
    }

    return () => {
      sliderRef.current?.slider.events.off(
        'transitionEnd',
        handleSliderTransitionEnd
      );
    };
  }, []);

  const handleChange = (e) => {
    const newIndex = parseInt(e.target.value);
    setTmolIndex(newIndex);
    sliderRef.current?.slider.goTo(newIndex);
  };

  return (
    <Grid
      container
      component="section"
      justify="space-between"
      className={classes.section}
      id="testimonial-section">
      {/* heading */}
      <Grid
        container
        direction="column"
        item
        xs={12}
        md={3}
        justify="center"
        className={classes.leftPadding}>
        <p className={classes.tmolHeadingTitle}>Testimonials</p>
        <h2 className={classes.tmolHeadingText}>
          Positive reviews endorse our service and quality. Trust their words,
          satisfaction guaranteed!
        </h2>
      </Grid>

      {/* cards */}
      <Grid
        container
        item
        xs={12}
        md={9}
        alignItems="center"
        className="tmol__lists">
        <TinySlider settings={SLIDER_SETTINGS} ref={sliderRef}>
          {TESTIMONIAL_DATA?.map((item, i) => {
            const contentData =
              item?.data?.length > maxContentChar
                ? item.data.substring(0, maxContentChar).concat('...')
                : item?.data;

            return (
              <Grid
                item
                key={`testimonials-list-item-${i}`}
                className={classes.tmolItem}>
                <div className="tmol__list">
                  <div className="tmol__listProfile">
                    <img
                      src={item.image}
                      alt="profile"
                      className="tmol__listProfileImg"
                    />
                  </div>
                  <p className="tmol__listDes">{contentData}</p>
                  <div className="tmol__listBottom">
                    <p className="tmol__author">
                      {item.name} / <span>{item.user}</span>
                    </p>
                    <img src={QuotesIcon} alt="qoutes" />
                  </div>
                </div>
              </Grid>
            );
          })}
        </TinySlider>
      </Grid>

      {/* scroll buttons */}
      <Grid
        container
        alignItems="flex-start"
        className={classes.scrollBtnsContainer}>
        <RadioGroup
          row
          value={tmolIndex}
          onChange={handleChange}
          className={classes.scrollBtnsGroup}>
          {TESTIMONIAL_DATA?.map((_, index) => (
            <CarouselNavRadio
              value={index}
              key={`subfooter-radio-group-item-${index}`}
            />
          ))}
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

export default SubFooter;
