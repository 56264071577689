import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const CARDS = [
  { name: 'Days', shortCode: 'days' },
  { name: 'Hours', shortCode: 'hrs' },
  { name: 'Minutes', shortCode: 'mins' },
  { name: 'Seconds', shortCode: 'secs' }
];
const INITIAL_TIME = {
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0
};

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    border: '5px solid white',
    borderRadius: '1rem',
    padding: '0.25rem 0',
    '& > p': {
      color: 'white'
    },
    '& > h6': {
      color: 'white'
    }
  }
}));

const Timer = ({ endTime, timerStyleType }) => {
  const classes = useStyles();
  const [time, setTime] = useState(INITIAL_TIME);

  const updateTime = () => {
    const differenceInMilliseconds = endTime - new Date();
    if (differenceInMilliseconds >= 0)
      setTime({
        Days: Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24)),
        Hours: Math.floor((differenceInMilliseconds / (1000 * 60 * 60)) % 24),
        Minutes: Math.floor((differenceInMilliseconds / 1000 / 60) % 60),
        Seconds: Math.floor((differenceInMilliseconds / 1000) % 60)
      });
  };

  useEffect(() => {
    updateTime();
    const interval = setInterval(updateTime, 1000);

    return () => clearInterval(interval);
  }, [endTime]);

  return timerStyleType === 'cards' ? (
    <Grid container>
      {CARDS.map((item, index) => (
        <Grid
          item
          xs={3}
          container
          alignItems="center"
          justify="center"
          direction="column"
          className={classes.card}
          key={`timer-cards-items-${index}`}>
          <Typography variant="subtitle1">{time[item.name]}</Typography>
          <Typography variant="body1">{item.name}</Typography>
        </Grid>
      ))}
    </Grid>
  ) : timerStyleType === 'text' ? (
    <Typography>
      {CARDS.filter((item) => time[item.name] > 0)
        .map((item) => `${time[item.name]} ${item.shortCode}`)
        .join(', ')}
    </Typography>
  ) : (
    <></>
  );
};

Timer.propTypes = {
  endTime: PropTypes.instanceOf(Date).isRequired,
  timerStyleType: PropTypes.oneOf(['cards', 'text'])
};
Timer.defaultProps = {
  timerStyleType: 'cards'
};

export default Timer;
