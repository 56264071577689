import React, { useState } from 'react';
import { Button, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { CUSTOMER_BENEFITS } from '../constants';

const useStyles = makeStyles({
  rootContainer: {},
  aboutUsText: {
    fontSize: '28px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: '2rem'
  },
  aboutUsSubText: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#edd242',
    marginLeft: '2rem',
    backgroundColor: '#1f6893',
    padding: '0.5rem',
    marginTop: '1rem',
    width: '9rem',
    borderRadius: '8px'
  },
  headingText: {
    textAlign: 'center',
    fontWeight: 800,
    marginBottom: '1rem',
    fontSize: '28px',
    color: '#1f6893'
  },
  subHeading: {
    textAlign: 'center',
    fontSize: '16px'
  },
  launderItAbout: {
    fontSize: '21px',
    lineHeight: '1.8rem',
    padding: '1rem ',
    margin: '1rem 0'
  },
  launderItBenefits: {
    textAlign: 'justify',
    fontSize: '18px',
    lineHeight: '1.8rem'
  },
  customerMoreTextContainer: {
    padding: '0 1rem',
    textAlign: 'justify',
    fontSize: '18px',
    lineHeight: '1.8rem'
  },
  launderItBenefitsContainer: {
    margin: '1rem 0.5rem 0'
  },
  customerHeading: {
    fontWeight: 800,
    color: '#1f6893',
    margin: '1rem 0'
  },
  readMoreBtn: {
    backgroundColor: '#1f6893',
    color: '#fff'
  },
  checkBox: {
    color: '#1f6893',
    marginRight: '1rem'
  },
  subHeadingTextContent: {
    fontSize: '18px',
    fontWeight: 600,
    color: '#1f6893'
  },
  customerMoreListContainer: {
    margin: '1rem 0'
  },
  listItem: {
    fontSize: 16
  },
  readMoreContent: {
    fontWeight: 600,
    fontSize: 15,
    lineHeight: '1.5rem',
    color: '#fff'
  }
});

const CustomerBenefits = () => {
  const classes = useStyles();
  const [showMore, setShowMore] = useState(false);

  return (
    <Grid
      container
      item
      xs={12}
      justify="center"
      alignItems="center"
      className={classes.rootContainer}>
      <Grid
        container
        item
        justify="space-between"
        spacing={3}
        className={classes.launderItBenefitsContainer}>
        <Grid container item justify="space-between" spacing={3}>
          <Grid container item md={6} xs={12}>
            <Typography variant="h4" className={classes.customerHeading}>
              Customer Benefits
            </Typography>
            <Typography className={classes.launderItBenefits}>
              We understand that finding a reliable laundry service can be
              time-consuming and frustrating. That's why we've developed an
              easy-to-use platform that allows customers to connect with nearby
              laundry shops effortlessly. By simply entering their postal code,
              customers can instantly find a list of reputable laundry shops in
              their area. No more driving around town or making endless phone
              calls, Launder-it brings convenience right to your fingertips.
              With Launder-it, customers can also enjoy the convenience of
              booking their laundry orders online. Our user-friendly website
              allows customers to browse through different shops, compare
              pricing, and select the one that best suits their needs. They can
              even read reviews and ratings from other customers to make an
              informed decision. you're in control of your schedule, select the
              collection and delivery date and time that works best for you.
            </Typography>
            {!showMore ? (
              <Grid
                container
                item
                justify="flex-end"
                style={{ marginTop: '1rem' }}>
                <Button
                  variant="contained"
                  className={classes.readMoreBtn}
                  onClick={() => setShowMore((value) => !value)}>
                  Why choose Launder-it
                </Button>
              </Grid>
            ) : null}
          </Grid>
          <Grid
            container
            item
            alignItems="center"
            justify="center"
            md={5}
            xs={12}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/BoODCiTTiR8?rel=0"
              title="Customer benefits YouTube video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen></iframe>
          </Grid>
        </Grid>
      </Grid>

      {showMore ? (
        <Grid container item className={classes.customerMoreTextContainer}>
          <Typography className={classes.launderItBenefits}>
            Once the order is placed, customers can sit back and relax as the
            laundry shop takes care of the rest. We also offer a tracking
            system, allowing customers to stay updated on the status of their
            orders. Plus, our messaging feature enables direct communication
            between customers and shops, ensuring a seamless experience from
            start to finish. But that's not all! We've revolutionized the way
            you book your laundry orders with our unique bidding system. Simply
            let us know your preferences and requirements, and our partnered
            laundromats will bid for your service. Choose the best offer that
            suits your convenience, and voila! Your laundry is as good as done.
          </Typography>

          <Grid
            container
            item
            xs={12}
            spacing={3}
            className={classes.customerMoreListContainer}>
            <Grid
              container
              item
              justify="center"
              style={{ margin: '1rem 0 2rem' }}>
              <Typography
                variant="h5"
                className={classes.subHeadingTextContent}>
                Why choose Launder-It ? 🤔
              </Typography>
            </Grid>

            {CUSTOMER_BENEFITS.map((item) => {
              return (
                <Grid
                  container
                  item
                  alignItems="center"
                  md={4}
                  xs={12}
                  key={item.id}>
                  <Grid item xs={1}>
                    <ArrowRightIcon
                      className={classes.checkBox}
                      fontSize="large"
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <Paper
                      style={{
                        padding: '0.5rem 1rem',
                        backgroundColor: '#616161',
                        borderRadius: '8px'
                      }}>
                      <Typography
                        variant="h6"
                        className={classes.readMoreContent}>
                        {item.content}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default CustomerBenefits;
