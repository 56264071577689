import React, { useState } from 'react';
import { Image } from 'components/organisms';
import { Grid, Box, Button, Typography, Chip, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { DocumentDeleteDialog } from 'components/organisms';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DescriptionIcon from '@material-ui/icons/Description';
import { useAmplifyAuth } from 'context';
import clsx from 'clsx';
import ViewDialog from './ViewDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1rem 0'
  },
  image: {
    height: '5rem',
    width: '5rem',
    color: theme.palette.error.light
  },
  contentItems: {
    position: 'relative'
  },
  statusChip: {
    position: 'absolute',
    right: '-1rem',
    top: '0.5rem',
    color: theme.palette.white,
    textTransform: 'capitalize'
  },
  approved: {
    backgroundColor: theme.palette.success.main
  },
  pending: {
    backgroundColor: theme.palette.warning.main
  },
  rejected: {
    backgroundColor: theme.palette.error.main
  },
  imageBox: {
    margin: '0 2rem 1rem 0'
  },
  delBtn: {
    color: theme.palette.error.main,
    margin: '0 1rem'
  }
}));

const ShopDocumentsIdAddress = (props) => {
  const {
    data,
    heading = '',
    handleUpdate = () => {},
    handleReUpload,
    handleDelete
  } = props;
  const [open, setOpen] = useState(false);
  const [elToDelete, setElToDelete] = useState(null);
  const [showViewDialog, setShowViewDialog] = useState(false);
  const classes = useStyles();
  const {
    state: { isAdmin = false }
  } = useAmplifyAuth();

  const handleClickOpen = (el) => {
    setOpen(true);
    setElToDelete(el);
  };

  const handleViewOpen = (el) => {
    setElToDelete(el);
    setShowViewDialog(true);
  };

  const handleModalClose = () => {
    setOpen(false);
    setShowViewDialog(false);
  };

  const handleDeleteConfirm = () => {
    handleDelete(elToDelete?.documentURL || '');
    handleModalClose();
  };

  const handleFileChange = (e) =>
    handleReUpload(e.target.name, e.target.files[0]);

  return (
    <>
      <Grid container>
        <Typography variant="h4">{heading}</Typography>
        <Grid container className={classes.root}>
          {data.length > 0 ? (
            data.map((el, i) => (
              <Grid
                container
                alignItems="center"
                justify="space-between"
                key={i}>
                <Box>
                  <Grid container alignItems="center">
                    <Box className={classes.imageBox}>
                      <div className={classes.contentItems}>
                        <Chip
                          size="small"
                          label={el.status || 'pending'}
                          className={clsx(
                            classes.statusChip,
                            classes[el.status || 'pending']
                          )}
                        />
                        {el.documentURL.split('.').at(-1) === 'pdf' ? (
                          <PictureAsPdfIcon className={classes.image} />
                        ) : el.MIMETypes.includes('image') ? (
                          <Image
                            docKey={el.documentURL}
                            className={classes.image}
                            accessLevel={'protected'}
                            alt={el.name}
                          />
                        ) : (
                          <DescriptionIcon className={classes.image} />
                        )}
                      </div>
                    </Box>
                    <Box style={{ marginTop: '-1rem' }}>
                      <Typography variant="h6">
                        <Link
                          onClick={() => handleViewOpen(el)}
                          style={{ cursor: 'pointer' }}>
                          {el.name}
                        </Link>
                      </Typography>
                      <Typography variant="body2">{el.description}</Typography>
                    </Box>
                  </Grid>
                </Box>
                <Box>
                  {isAdmin ? (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={el.status === 'approved'}
                        onClick={() => handleUpdate(el.documentURL, 'approved')}
                        startIcon={<CheckIcon fontSize="small" />}>
                        Approve
                      </Button>
                      <Button
                        variant="outlined"
                        style={{ marginLeft: '1rem' }}
                        color="secondary"
                        size="small"
                        disabled={el.status === 'rejected'}
                        onClick={() => handleUpdate(el.documentURL, 'rejected')}
                        startIcon={<ClearIcon fontSize="small" />}>
                        Reject
                      </Button>
                    </>
                  ) : (
                    <>
                      <input
                        id={`shop-documents-re-upload-${i}`}
                        name={el?.documentURL}
                        onChange={handleFileChange}
                        type="file"
                        accept="image/*,application/pdf"
                        hidden
                      />
                      <label htmlFor={`shop-documents-re-upload-${i}`}>
                        <Button
                          size="small"
                          component="span"
                          variant="outlined"
                          color="primary"
                          startIcon={<CloudUploadIcon />}>
                          Re-upload
                        </Button>
                      </label>
                    </>
                  )}
                  <Button
                    size="small"
                    startIcon={<DeleteIcon />}
                    variant="outlined"
                    onClick={() => handleClickOpen(el)}
                    className={classes.delBtn}>
                    Delete
                  </Button>
                </Box>
              </Grid>
            ))
          ) : (
            <Typography align="center">No Documents available</Typography>
          )}
        </Grid>
      </Grid>
      <ViewDialog
        open={showViewDialog}
        handleCancel={handleModalClose}
        docKey={elToDelete?.documentURL || ''}
        accessLevel={'protected'}
        mimeType={elToDelete?.MIMETypes || ''}
      />
      <DocumentDeleteDialog
        open={open}
        handleCancel={handleModalClose}
        handleConfirm={handleDeleteConfirm}
        confirmText="delete this document"
      />
    </>
  );
};

export default ShopDocumentsIdAddress;
