import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { API, graphqlOperation } from 'aws-amplify';
import { Link as RouterLink } from 'react-router-dom';
import { CartContext } from 'context/cart/cartContext';
import PerfectScrollbar from 'react-perfect-scrollbar';
import QuantitySelect from './QuantitySelect';
import { useAmplifyAuth } from 'context';
import {
  showPrice,
  calculateVariablePrice,
  getFormattedPriceRange,
  addRangeToPrice
} from 'common/utilFunctions';
import { validateCartCoupons } from 'common/validators';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 0.5rem'
  },
  box: {
    margin: '0.375rem 0'
  },
  closeBtn: {
    padding: 0
  },
  menuItemMainDiv: {
    marginTop: '1rem'
  },
  menuPaper: {
    height: 'fit-content'
  },
  cartMainHeadingText: {
    fontSize: '1rem',
    fontWeight: 400
  },
  div1: {
    marginTop: '0.5rem'
  },
  divider: {
    marginTop: '1rem'
  },
  footer: {
    backgroundColor: theme.palette.black,
    padding: '0.25rem 0.5rem',
    width: '100%',
    height: '3rem'
  },
  itemListContainer: {
    width: '100%',
    maxHeight: '20rem'
  },
  numberOfItemBox: {
    padding: '0.25rem 0.5rem',
    textAlign: 'center',
    borderColor: theme.palette.white,
    '& p': {
      fontSize: 13,
      color: theme.palette.white
    }
  },
  cartQuantityName: {
    paddingLeft: '0.25rem'
  },
  checkoutBtn: {
    color: 'white',
    minWidth: '9rem',
    fontSize: 14,
    '&:hover': {
      color: theme.palette.grey[400]
    }
  }
}));

/* GraphQL call to get the shop of the cart */
const getShop = `
  query GetShop($id: ID!) {
    getShop(id: $id) {
      name
      shopID
    }
  }
`;

const MenuItemList = ({
  serviceName = '',
  items = [],
  updateCart = () => {}
}) => {
  const classes = useStyles();

  return (
    <div className={classes.menuItemMainDiv}>
      <Typography className={classes.cartMainHeadingText}>
        {serviceName}
      </Typography>
      {items.length > 0
        ? items.map((item, i) => (
            <Grid
              className={classes.div1}
              container
              justify="space-between"
              alignItems="center"
              key={`cart-menu-service-items-${i}`}>
              <Grid className={classes.cartQuantityName} item xs={3}>
                <Typography variant="h6">
                  {item.name || item.item.name}
                </Typography>
              </Grid>
              <Grid item container xs={7} alignItems="center">
                <Grid item xs={5}>
                  <Typography variant="body1" align="right">
                    {!!item.price
                      ? `${showPrice(item.price)}`
                      : getFormattedPriceRange(item.priceRange)}
                    &nbsp; x &nbsp;
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <QuantitySelect item={item} updateCart={updateCart} />
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1">
                  {item.price
                    ? showPrice(item.price * item.quantity)
                    : getFormattedPriceRange(item.priceRange, item.quantity)}
                </Typography>
              </Grid>
            </Grid>
          ))
        : null}
      <Divider className={classes.divider} />
    </div>
  );
};

export default function CartMenu(props) {
  const [cartShop, setCartShop] = useState({ shopID: '', name: '' });
  const { anchorEl, setAnchorEl, cartItems } = props;
  const {
    state: { user }
  } = useAmplifyAuth();
  const classes = useStyles();
  const cartItem = cartItems.reduce((r, a) => {
    r[a.service.name] = [...(r[a.service.name] || []), a];
    return r;
  }, Object.create(null));
  const total = cartItems.reduce((r, o) => o.price * o.quantity + r, 0);
  const { cart, setCart } = useContext(CartContext);

  useEffect(() => {
    if (cart.items.length > 0)
      API.graphql(graphqlOperation(getShop, { id: cart.items[0].shopID }))
        .then(({ data }) => {
          setCartShop({ shopID: data.getShop.shopID, name: data.getShop.name });
        })
        .catch((err) => console.log('error', err));
  }, []);

  const handleCartClose = () => setAnchorEl(null);

  const updateCart = (item, q) => {
    let newCart = {
      ...cart,
      items: cart.items
        .map((cartItem) =>
          cartItem.id === item.id ? { ...item, quantity: q } : cartItem
        )
        .filter((item) => item.quantity > 0)
    };
    newCart.variablePrice = calculateVariablePrice(
      newCart.items.filter((_item) => !_item.price)
    );
    newCart = validateCartCoupons(newCart);

    localStorage.setItem(
      'cart',
      JSON.stringify({ username: user?.email || '', item: newCart })
    );
    setCart(newCart);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      elevation={1}
      getContentAnchorEl={null}
      id="simple-menu"
      keepMounted
      classes={{ paper: classes.menuPaper }}
      onClose={handleCartClose}
      open={Boolean(anchorEl)}>
      <Grid
        container
        direction="column"
        justify="space-between"
        className={classes.root}>
        <Grid container item xs={12}>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h3">Your Order</Typography>
            <IconButton onClick={handleCartClose} className={classes.closeBtn}>
              <CloseIcon size="large" />
            </IconButton>
          </Grid>
          <Grid item xs={12} className={classes.box}>
            <Divider />
          </Grid>
          <Grid container>
            <PerfectScrollbar className={classes.itemListContainer}>
              <>
                {Object.keys(cartItem).map((item, i) => (
                  <MenuItemList
                    serviceName={item}
                    items={cartItem[item]}
                    updateCart={updateCart}
                    key={`cart-menu-services-${i}`}
                  />
                ))}
              </>
            </PerfectScrollbar>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justify="space-between"
          alignItems="center"
          className={classes.footer}>
          <Box border={2} className={classes.numberOfItemBox}>
            <p>{cart.items.reduce((r, o) => o.quantity + r, 0)}</p>
          </Box>
          <RouterLink
            to={`/shop/${cartShop.shopID}/${cartShop.name
              .toLowerCase()
              .split(' ')
              .join('-')}/checkout`}>
            <Button className={classes.checkoutBtn}>CHECKOUT</Button>
          </RouterLink>
          <Box border={2} className={classes.numberOfItemBox}>
            <p>
              {!!cart.variablePrice
                ? getFormattedPriceRange(
                    addRangeToPrice(total, cart.variablePrice)
                  )
                : showPrice(total)}
            </p>
          </Box>
        </Grid>
      </Grid>
    </Menu>
  );
}
