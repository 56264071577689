import React, { useState, useCallback, useEffect } from 'react';
import { getFileUploadKey } from 'common/utilFunctions';
import { DocumentUploadDropzone } from 'components/organisms';
import ActionButtons from './ActionButtons';
import { Storage } from 'aws-amplify';
import { Paper } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useShops } from 'context/shop/shopsContext';
import { useLoader } from 'layouts/loaderContext';

function ShopDocuments({
  hideUploader,
  setSelectedShop,
  setDisabled = () => {}
}) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { setLoading } = useLoader();
  const { selectedShop, dispatch } = useShops();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setDisabled(!selectedFiles.length);
  }, [selectedFiles]);

  const onDrop = useCallback(
    (acceptedFiles) =>
      setSelectedFiles([
        ...selectedFiles,
        ...acceptedFiles.map((file) => ({
          file,
          name: '',
          documentType: '',
          description: ''
        }))
      ]),
    [selectedFiles]
  );

  const handleChange = (id) => (e) =>
    setSelectedFiles(
      selectedFiles.map((file, index) =>
        index === id ? { ...file, [e.target.name]: e.target.value } : file
      )
    );

  const onFileClose = (id) =>
    setSelectedFiles(selectedFiles.filter((item, index) => index !== id));

  const onCancel = () => setSelectedFiles([]);

  const onSubmit = async (event) => {
    event.preventDefault();
    if (
      selectedFiles.length === 0 ||
      selectedFiles.some((item) => !(item.name && item.documentType))
    ) {
      return enqueueSnackbar('All fields are mandatory', {
        variant: 'warning',
        preventDuplicate: true,
        persist: true
      });
    }
    Storage.configure({ level: 'protected' });
    setLoading(true);
    const allUrls = [];
    const uploadedFiles = selectedFiles.map(
      async ({ file, name, documentType, description }) => {
        const { type: mimeType } = file;
        const key = getFileUploadKey(file, 'documents');
        return Storage.put(key, file, {
          contentType: mimeType
        })
          .then(() => ({
            name,
            documentType,
            description,
            documentURL: key,
            MIMETypes: mimeType
          }))
          .catch((error) => {
            console.log('error', error);
            return null;
          });
      }
    );
    try {
      const results = await Promise.allSettled(uploadedFiles);
      results.forEach(
        (item) => item.status === 'fulfilled' && allUrls.push(item.value)
      );
      dispatch({
        type: 'updateShopInDB',
        payload: {
          input: {
            documents: allUrls,
            id: selectedShop.id,
            _version: selectedShop._version
          },
          successMessage: 'Documents updated successfully',
          successCallback: (data) => {
            setDisabled(true);
            hideUploader();
            setSelectedShop(data);
          }
        }
      });
    } catch (e) {
      console.log(e);
      enqueueSnackbar('Something went wrong...', {
        variant: 'error',
        preventDuplicate: true
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper style={{ boxShadow: 'none' }}>
      <DocumentUploadDropzone
        onDrop={onDrop}
        selectedFiles={selectedFiles}
        onClose={onFileClose}
        handleChange={handleChange}
      />
      <ActionButtons
        disabled={!selectedFiles.length}
        handleSubmit={onSubmit}
        handleCancel={onCancel}
      />
    </Paper>
  );
}

export default ShopDocuments;
