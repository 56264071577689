import GridList from '@material-ui/core/GridList';
import React from 'react';

const ShopListWrapper = React.forwardRef(
  ({ children, lg = false, ...rest }, ref) =>
    lg ? (
      children
    ) : (
      <GridList {...rest} ref={ref}>
        {children}
      </GridList>
    )
);

export default ShopListWrapper;
