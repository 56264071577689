import { API, graphqlOperation } from 'aws-amplify';
import { getRedirectByShopID } from 'graphql/queries';
import { createShopSiteRedirectRecord } from 'graphql/mutations';

const loadRedirectsByShopID = (payload) =>
  new Promise((resolve, reject) =>
    API.graphql(graphqlOperation(getRedirectByShopID, payload))
      .then((data) => resolve(data.data.getRedirectByShopID))
      .catch((err) => reject(err))
  );

const createRedirectRecord = (payload) =>
  new Promise((resolve, reject) =>
    API.graphql(graphqlOperation(createShopSiteRedirectRecord, payload))
      .then((data) => resolve(data.data.createShopSiteRedirectRecord))
      .catch((err) => reject(err))
  );

export { loadRedirectsByShopID, createRedirectRecord };
