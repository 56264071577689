import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Divider
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  contentBox: {
    gap: '1.5rem'
  }
}));

const MyAuctionSkeleton = ({
  status = 1 // auction status : { 0:Pending, 1:Ongoing, 2:Over }
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        title={<Skeleton variant="text" width="50%" animation="wave" />}
        subheader={<Skeleton variant="text" width="30%" animation="wave" />}
      />
      <CardContent>
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          className={classes.contentBox}>
          {status === 2 ? null : (
            <Skeleton
              variant="rect"
              height={50}
              width="100%"
              animation="wave"
            />
          )}
          {status === 0 ? null : (
            <Skeleton variant="text" width="40%" animation="wave" />
          )}
        </Grid>
      </CardContent>

      <Divider />

      <CardActions>
        <Grid container justify="flex-end">
          <Skeleton variant="rect" width={100} height={30} animation="wave" />
        </Grid>
      </CardActions>
    </Card>
  );
};

export default MyAuctionSkeleton;
