import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  InputAdornment,
  List,
  ListItem,
  MenuItem,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  fields,
  initialValues,
  endAdornments,
  requiredFields,
  notes,
  DISCOUNT_TYPES
} from './constants';
import { useServices } from 'views/Services/serviceContext';
import { useProducts } from 'context/products/productsContext';
import { useCoupons } from 'context/coupons/couponsContext';
import { useAmplifyAuth } from 'context';
import { useSnackbar } from 'notistack';
import { showPrice, unitFormatter } from 'common/utilFunctions';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import {
  KeyboardTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1rem'
  },
  content: {
    padding: '1rem',
    margin: 0
  },
  form: {
    width: '100%'
  },
  input: {
    padding: '0.5rem'
  },
  notesContainer: {
    margin: '0.5rem 0.5rem 1.5rem',
    padding: '0.5rem',
    backgroundColor: '#e1f5fe'
  },
  listRoot: {
    padding: 0
  },
  listItem: {
    paddingLeft: '0.5rem',
    paddingBottom: 0
  },
  productDropdownPrice: {
    color: theme.palette.text.secondary,
    fontSize: '0.75rem'
  },
  discountType: {
    marginTop: '1rem'
  },
  menuitem: {
    overflow: 'scroll'
  }
}));

const CouponForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const { services, dispatch: servicesDispatch } = useServices();
  const { products, dispatch: productsDispatch } = useProducts();
  const { coupons, dispatch } = useCoupons();
  const [coupon, setCoupon] = useState(initialValues);
  const { enqueueSnackbar } = useSnackbar();
  let { actionType = '' } = useParams();
  let {
    state: { user }
  } = useAmplifyAuth();

  useEffect(() => {
    servicesDispatch({ type: 'getService' });
  }, [servicesDispatch]);

  useEffect(() => {
    if (user?.shopId) {
      if (!Object.keys(products).length) {
        productsDispatch({
          type: 'getProductsByShopId',
          payload: { id: user.shopId }
        });
      }
      setCoupon({ ...coupon, shopID: user.shopId });
    }
  }, [user]);

  useEffect(() => {
    const couponID = history.location.search?.split('=')[1];

    const currCoupon = coupons.find((coupon) => coupon.id === couponID);

    if (currCoupon) {
      const customType = {
        discountFor: currCoupon.productID ? 'product' : '',
        discountType: currCoupon.discountType || '',
        discountUnit: currCoupon.discountUnit || '',
        conditionUnit: currCoupon.conditionUnit || ''
      };

      const type = DISCOUNT_TYPES.findIndex(
        (item) =>
          JSON.stringify(item.couponAttributes) === JSON.stringify(customType)
      )?.toString();

      const serviceID =
        Object.values(products)?.find(
          (product) => product.id === currCoupon.productID
        )?.serviceID || '';

      setCoupon({
        ...currCoupon,
        type,
        serviceID,
        discountFor: currCoupon.productID ? 'product' : '',
        startDate: currCoupon.startDateTime,
        startTime: currCoupon.startDateTime,
        endDate: currCoupon.endDateTime,
        endTime: currCoupon.endDateTime
      });
    }
  }, [products]);

  const handleChange = (e) => {
    const floatRegex = /^[0-9]+\.?[0-9]{0,2}$/;
    const numRegex = /^[0-9]+$/;
    const newCoupon = { ...coupon };
    const name = e.target.name;

    // update discount and condition amounts based on regex check
    if (name === 'discountAmount') {
      if (
        (coupon.discountUnit === 'noOfItems' &&
          numRegex.test(e.target.value)) ||
        (coupon.discountUnit !== 'noOfItems' &&
          floatRegex.test(e.target.value)) ||
        e.target.value === ''
      )
        newCoupon['discountAmount'] = e.target.value;
    } else if (name === 'conditionAmount') {
      if (
        (coupon.conditionUnit === 'noOfItems' &&
          numRegex.test(e.target.value)) ||
        (coupon.conditionUnit !== 'noOfItems' &&
          floatRegex.test(e.target.value)) ||
        e.target.value === ''
      )
        newCoupon['conditionAmount'] = e.target.value;
    } else {
      if (name === 'serviceID') newCoupon['productID'] = '';
      newCoupon[name] = e.target.value;
    }
    if (name === 'type')
      setCoupon({
        ...newCoupon,
        ...DISCOUNT_TYPES[e.target.value].couponAttributes
      });
    else setCoupon(newCoupon);
  };

  const handleCreateSuccess = () => {
    setCoupon(initialValues);
    history.push('/service-provider/coupons');
  };
  const handleEditSuccess = () => {
    setCoupon(initialValues);
    history.goBack();
  };

  const handleReset = () => {
    setCoupon(initialValues);
  };

  const validateCouponForm = (coupon) => {
    const missingField = Object.entries(requiredFields).find(
      ([key, _]) => !coupon[key]
    );
    if (missingField) {
      enqueueSnackbar(`${missingField[1]} is required`, {
        variant: 'error',
        autoHideDuration: 2500,
        persist: true
      });
      return false;
    }
    if (coupon.startDate >= coupon.endDate) {
      enqueueSnackbar('Enter valid dates', {
        variant: 'error',
        autoHideDuration: 2500,
        persist: true
      });
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    // data validation is needed
    e.preventDefault();

    if (!validateCouponForm(coupon)) return;

    if (coupon.discountType === 'conditional') {
      if (!coupon.conditionAmount) {
        enqueueSnackbar('Conditional amount is required', {
          variant: 'error',
          autoHideDuration: 2500,
          persist: true
        });
        return;
      }
      if (!coupon.conditionUnit) {
        enqueueSnackbar('Conditional unit is required', {
          variant: 'error',
          autoHideDuration: 2500,
          persist: true
        });
        return;
      }
    }
    const {
      type,
      discountFor,
      serviceID,
      startDate,
      startTime,
      endDate,
      endTime,
      ...rest
    } = coupon;

    const payloadCoupon = {
      startDateTime:
        startDate + 'T' + moment(startTime).utc().format('HH:mm:ss.SSS[Z]'),

      endDateTime:
        endDate + 'T' + moment(endTime).utc().format('HH:mm:ss.SSS[Z]'),

      ...rest
    };

    if (payloadCoupon.discountType === 'direct') {
      delete payloadCoupon.conditionUnit;
      delete payloadCoupon.conditionAmount;
    }
    dispatch({
      type: 'createCoupon',
      payload: {
        coupon: payloadCoupon,
        successCallback: handleCreateSuccess
      }
    });
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    const {
      id,
      title,
      description,
      startDate,
      startTime,
      endDate,
      endTime,
      _version
    } = coupon;

    if (!validateCouponForm(coupon)) return;

    const payloadCoupon = {
      id,
      title,
      description,

      startDateTime:
        startDate.split('T')[0] +
        'T' +
        moment(startTime).utc().format('HH:mm:ss.SSS[Z]'),

      endDateTime:
        endDate.split('T')[0] +
        'T' +
        moment(endTime).utc().format('HH:mm:ss.SSS[Z]'),

      _version
    };

    dispatch({
      type: 'updateCoupon',
      payload: {
        coupon: payloadCoupon,
        successCallback: handleEditSuccess
      }
    });
  };

  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.content} component={Paper}>
        <form noValidate className={classes.form}>
          <Grid container>
            {/* notes */}
            <Grid
              container
              justify="flex-end"
              component={Paper}
              className={classes.notesContainer}>
              <Grid item xs={12}>
                <Typography variant="h6">Notes</Typography>
                <List className={classes.listRoot}>
                  {notes.map((item, index) => (
                    <ListItem
                      className={classes.listItem}
                      key={`coupons-form-notes-items-${index}`}>
                      <Typography variant="body2">
                        {index + 1}. {item}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>

            {/* title, desc */}
            {fields.map((item, index) => (
              <Grid
                item
                xs={12}
                md={item.md || 6}
                key={`coupons-form-fields-items-${index}`}
                className={classes.input}>
                <TextField
                  type={'text'}
                  name={item.name}
                  label={item.label}
                  value={coupon[item.name]}
                  placeholder={item.placeholder || ''}
                  onChange={handleChange}
                  multiline={item.multiline || false}
                  rows={item.rows || 1}
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
            ))}

            {/* start & end date-time */}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid
                container
                justify="space-between"
                style={{ padding: '0.5rem 0.5rem' }}>
                <Grid
                  container
                  item
                  xs={12}
                  lg={6}
                  style={{ padding: '0 0.5rem' }}>
                  <Grid item xs={12} lg={6}>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      id="date-picker-dialog"
                      label="Start Date"
                      format="DD/MM/YYYY"
                      value={coupon.startDate}
                      onChange={(date) =>
                        handleChange({
                          target: {
                            name: 'startDate',
                            value: moment(date).format('YYYY-MM-DD')
                          }
                        })
                      }
                      InputLabelProps={{
                        shrink: coupon.startDate ? true : false
                      }}
                      disablePast
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <KeyboardTimePicker
                      fullWidth
                      margin="normal"
                      id="time-picker"
                      label="Start Time"
                      value={coupon.startTime}
                      onChange={(time) =>
                        handleChange({
                          target: {
                            name: 'startTime',
                            value: moment(time).format()
                          }
                        })
                      }
                      InputLabelProps={{
                        shrink: coupon.startTime ? true : false
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change time'
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  lg={6}
                  style={{ padding: '0 0.5rem' }}>
                  <Grid item xs={12} lg={6}>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      id="date-picker-dialog"
                      label="End Date"
                      format="DD/MM/YYYY"
                      value={coupon.endDate}
                      onChange={(date) =>
                        handleChange({
                          target: {
                            name: 'endDate',
                            value: moment(date).format('YYYY-MM-DD')
                          }
                        })
                      }
                      InputLabelProps={{
                        shrink: coupon.endDate ? true : false
                      }}
                      disablePast
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <KeyboardTimePicker
                      fullWidth
                      margin="normal"
                      id="time-picker"
                      label="End Time"
                      value={coupon.endTime}
                      onChange={(time) =>
                        handleChange({
                          target: {
                            name: 'endTime',
                            value: moment(time).format()
                          }
                        })
                      }
                      InputLabelProps={{
                        shrink: coupon.endTime ? true : false
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change time'
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>

            {/* discount type select */}
            <Grid
              container
              justify="center"
              className={clsx(classes.input, classes.discountType)}>
              <Grid item xs={12}>
                <TextField
                  select
                  name="type"
                  variant="outlined"
                  size="small"
                  label="Select Discount Type"
                  disabled={actionType === 'edit' ? true : false}
                  value={coupon.type}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  fullWidth>
                  {DISCOUNT_TYPES.map((item, index) => (
                    <MenuItem
                      classes={{
                        root: classes.menuitem
                      }}
                      value={`${index}`}
                      key={`discount-types-items-${index}`}>
                      {`${index + 1} - ${item.name}`}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            {/* select product */}
            {!!(
              coupon.discountFor === 'product' &&
              services.length > 0 &&
              Object.keys(products).length > 0 &&
              (!!coupon.productID ? !!coupon.serviceID : true)
            ) ? (
              <>
                <Grid item xs={12} md={6} className={classes.input}>
                  <TextField
                    select
                    name="serviceID"
                    variant="outlined"
                    size="small"
                    label="Select Service"
                    disabled={actionType === 'edit' ? true : false}
                    value={coupon.serviceID}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    fullWidth>
                    {services
                      .filter((_service) =>
                        Object.values(products).some(
                          (prod) => prod.serviceID === _service.id
                        )
                      )
                      .map((item, index) => (
                        <MenuItem
                          value={item?.id || ''}
                          key={`coupons-form-services-items-${index}`}>
                          {item?.name || ''}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={6} className={classes.input}>
                  <TextField
                    select
                    name="productID"
                    variant="outlined"
                    size="small"
                    label="Select Product"
                    value={coupon.productID}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    disabled={
                      !coupon.serviceID || actionType === 'edit' ? true : false
                    }
                    fullWidth>
                    {Object.values(products)
                      .filter(
                        (item) =>
                          !!item.price &&
                          item.serviceID === coupon.serviceID &&
                          coupons.every((_coupon) =>
                            actionType === 'add'
                              ? _coupon.productID !== item.id
                              : true
                          )
                      )
                      .map((item, index) => (
                        <MenuItem
                          value={item.id || ''}
                          key={`coupons-form-products-items-${index}`}>
                          {item?.name || item?.item?.name || ''}
                          <span className={classes.productDropdownPrice}>
                            &nbsp; ( price: {showPrice(item.price)}{' '}
                            {unitFormatter(item.unit, '/')?.toLowerCase()} )
                          </span>
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </>
            ) : null}

            {/* conditional discount condition details */}
            {coupon.conditionUnit ? (
              <Grid
                container
                justify="space-between"
                alignItems="center"
                className={classes.input}>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    {coupon.conditionUnit === 'amount'
                      ? 'Minimum amount spent'
                      : coupon.conditionUnit === 'noOfItems'
                      ? 'Minimum no of products ordered'
                      : ''}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="conditionAmount"
                    variant="outlined"
                    size="small"
                    value={coupon.conditionAmount}
                    disabled={actionType === 'edit' ? true : false}
                    onChange={handleChange}
                    inputProps={{ min: 0 }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography variant="body2">
                            {endAdornments[coupon.conditionUnit] || ''}
                          </Typography>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            ) : null}

            {/* direct discount details */}
            {coupon.discountUnit ? (
              <Grid
                container
                justify="space-between"
                alignItems="center"
                className={classes.input}>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    Discount {unitFormatter(coupon.discountUnit).toLowerCase()}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="discountAmount"
                    variant="outlined"
                    size="small"
                    disabled={actionType === 'edit' ? true : false}
                    value={coupon.discountAmount}
                    onChange={handleChange}
                    inputProps={{ min: 0 }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography variant="body2">
                            {endAdornments[coupon.discountUnit] || ''}
                          </Typography>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            ) : null}

            {!!coupon.type ? (
              <Grid
                container
                justify="flex-end"
                className={classes.input}
                style={{
                  gap: '1rem'
                }}>
                {actionType === 'add' ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={handleReset}>
                    reset
                  </Button>
                ) : null}
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={
                    actionType === 'add' ? handleSubmit : handleEditSubmit
                  }>
                  save
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default CouponForm;

{
  /* max no of uses per user */
}
{
  /* TODO: backend implementation required */
}
{
  /* <Grid
              container
              justify="space-between"
              alignItems="center"
              className={classes.input}>
              <Grid item xs={10}>
                <Typography variant="body1">Max. use per / user</Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  name="maxUsesPerUser"
                  variant="outlined"
                  type="number"
                  size="small"
                  value={coupon.maxUsesPerUser}
                  onChange={handleChange}
                  inputProps={{ min: 0 }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
            </Grid> */
}
