const notificationsReducer = (state = [], action) => {
  const { type, payload } = action;

  switch (type) {
    case 'updateData': {
      const sortedNotification = payload?.sort(
        (a, b) => b._lastChangedAt - a._lastChangedAt
      );
      return sortedNotification || [];
    }
    case 'addNewNotification': {
      return [payload.newNotification, ...state];
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

export default notificationsReducer;
