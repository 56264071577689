import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  CircularProgress,
  Drawer,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { NavigationBreadcrumbs, CustomSearchBar } from 'components/organisms';
import { useOrders } from 'context/order/ordersContext';
import { useHistory } from 'react-router-dom';
import { useLoader } from 'layouts/loaderContext';
import { useAmplifyAuth } from 'context';
import { Header, ChatContactBox, ChatMessageBox } from './components';
import ChatShopDetailBox from './components/ChatShopDetailBox';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    borderTop: '1px solid #0000001A'
  },
  root: {
    overflow: 'hidden',
    marginBottom: '100px',
    maxWidth: '90%',
    margin: '0 auto'
  },
  inputRoot: {
    '& fieldset': {
      border: '2px solid #0000003b',
      borderRadius: '0.25rem'
    }
  },
  searchBox: {},
  searchIcon: {
    color: theme.palette.grey[400]
  },
  leftPanel: {
    gap: '0.5rem'
  },
  drawerRoot: {
    padding: '1rem 0.5rem',
    maxWidth: '80%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '60%'
    }
  }
}));

const Chat = () => {
  const {
    state: { user }
  } = useAmplifyAuth();
  const classes = useStyles();
  const { loading } = useLoader();
  const { orders, dispatch } = useOrders();
  const [orderActive, setOrderActive] = useState(null);
  const [searchBy, setSearchBy] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);
  const history = useHistory();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const filteredOrders = orders.filter((order) =>
    order?.orderID?.toLowerCase().includes(searchBy.trim().toLowerCase())
  );

  useEffect(() => {
    !!history.location.search &&
      setSearchBy(history.location.search.split('=').at(1));
  }, []);

  useEffect(() => {
    if (user?.id || user?.sub) {
      dispatch({
        type: 'getOrdersByUserId',
        payload: {
          userId: user.id || user.sub,
          sortBy: 'lastNotificationTime'
        }
      });
    }
  }, [user]);

  useEffect(() => {
    let mounted = true;
    if (orders.length > 0) mounted && setOrderActive(orders[0]);

    return () => {
      mounted = false;
    };
  }, [orders]);

  useEffect(() => {
    let mounted = true;
    if (filteredOrders.length === 1)
      mounted && setOrderActive(filteredOrders[0]);

    return () => {
      mounted = false;
    };
  }, [filteredOrders]);

  const updateSearchToURL = (searchBy = '') =>
    !!searchBy
      ? history.replace(`${history.location.pathname}?orderID=${searchBy}`)
      : history.replace(history.location.pathname);

  const handleSearchChange = (e) => {
    setSearchBy(e.target.value);
    updateSearchToURL(e.target.value);
  };

  const handleSearchClear = () => {
    setSearchBy('');
    updateSearchToURL();
  };

  const renderLeftPanel = () => (
    <Grid container item xs={12} md={4} lg={3} className={classes.leftPanel}>
      <CustomSearchBar
        value={searchBy}
        onChange={handleSearchChange}
        placeholder="Search"
        InputLabelProps={{ shrink: true }}
        onClear={handleSearchClear}
        classes={{ root: classes.inputRoot }}
      />

      <ChatContactBox
        orderActive={orderActive}
        setOrderActive={setOrderActive}
        setOpenDrawer={setOpenDrawer}
        orders={filteredOrders}
      />
    </Grid>
  );

  return (
    <Grid className={classes.rootContainer}>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <NavigationBreadcrumbs currentLink="Chat" />
        </Grid>
        <Grid item xs={12}>
          <Header content="Chat" />
        </Grid>

        {orders.length > 0 ? (
          <Grid container item spacing={2}>
            {md ? (
              renderLeftPanel()
            ) : (
              <Drawer
                anchor="left"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                classes={{ paper: classes.drawerRoot }}>
                {renderLeftPanel()}
              </Drawer>
            )}

            {orderActive ? (
              <>
                <Grid item xs={12} md={8} lg={6}>
                  <ChatMessageBox
                    orderActive={orderActive}
                    setOpenDrawer={setOpenDrawer}
                  />
                </Grid>

                {lg ? (
                  <Grid item xs={12} lg={3}>
                    <ChatShopDetailBox orderActive={orderActive} />
                  </Grid>
                ) : null}
              </>
            ) : null}
          </Grid>
        ) : (
          <Grid container justify="center">
            {loading ? (
              <CircularProgress color="primary" />
            ) : (
              <Typography variant="h6">
                Place orders to chat regarding your order...
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Chat;
