import React from 'react';
import { Grid, RadioGroup, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StripeCardPaymentOptions } from 'components';
import { StyledFormControlLabel } from 'components/molecules';
import { useShops } from 'context/shop/shopsContext';
import { paymentMethods } from 'common/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '1rem'
  },
  cardNote: {
    padding: '0.5rem 0',
    color: theme.palette.warning.dark
  },
  radioContainer: {
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      gap: '1rem'
    }
  }
}));

const PaymentOptions = ({
  paymentMethod = '',
  setPaymentMethod = () => {},
  cardDetails = {},
  setCardDetails = () => {}
}) => {
  const classes = useStyles();
  const { selectedShop } = useShops();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} style={{ marginTop: '10px' }}>
        <RadioGroup
          row
          aria-label="payment-options-radio"
          name="payment-options-radio"
          value={paymentMethod}
          onChange={(_, newVal) => setPaymentMethod(newVal)}
          className={classes.radioContainer}>
          {paymentMethods.map((item, index) => (
            <StyledFormControlLabel
              key={`checkout-page-payment-methods-${index}`}
              value={item.value}
              checked={paymentMethod === item.value}
              label={item.label}
              disabled={
                !!selectedShop?.paymentMethods &&
                !selectedShop?.paymentMethods?.includes(item.value)
              }
            />
          ))}
        </RadioGroup>
      </Grid>
      {paymentMethod === 'card' ? (
        <Grid container>
          <Grid item xs={12}>
            <StripeCardPaymentOptions
              cardDetails={cardDetails}
              setCardDetails={setCardDetails}
            />
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default PaymentOptions;
