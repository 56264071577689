export const shopItemQuery = `
    id
    name
    googlePlaceId
    contactName
    address
    postcode
    town
    country
    email
    phoneNumber
    location {
      lat
      lng
    }
    workHrs {
      closeDay
      openDay
      closeTime
      openTime
    }
    collectionHrs {
      closeDay
      openDay
      closeTime
      openTime
    }
    deliveryHrs {
      closeDay
      openDay
      closeTime
      openTime
    }
    image
    minPriceForFree
    distanceRangeForFree
    distanceRange
    standardDeliveryFee
    AsapDeliveryFee
    language
    range
    shopID
    about
    documents {
      name
      documentType
      documentURL
      description
      MIMETypes
      status
    }
    photos {
      name
      photoURL
      thumbURL
      description
      MIMETypes
      status
      serviceID
    }
    primaryPhoto {
      name
      photoURL
      thumbURL
      description
      MIMETypes
      status
      serviceID
    }
    videos {
      name
      videoURL
      autoPlay
      thumbURL
      description
      MIMETypes
      status
    }
    variant
    status
    isLive
    consentLetterCount
    consentLetterLastDate
    enableAsapDelivery
    collectionInstructions
    deliveryInstructions
    websiteLink
    paymentMethods
    createdBy
    updatedBy
    createdAt
    _version
    _deleted
    _lastChangedAt
    updatedAt
    products {
        items {
            id
            name
            codeName
            shopID
            description
            unit
            image      
            serviceID
            service {
                id
                name
            }
            itemID
            price
            priceRange
            item {
                name
                image
                description
            }
            _deleted
        }
        nextToken
    }
    reviews {
        items {
            id
            shopID
            userID
            orderID
            user {
                id
                firstName
                username
                lastName
                picture
            }
            title
            ratings {
                title
                rating
            }
            replyID
            reply {
                id
                reviewID
                description
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
            }
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
        }
        nextToken
        startedAt
    }
    rating {
        rating
        ratingsCount
        ratingsSum
    }
    coupons {
        items {
            id
            shopID
            productID
            title
            description
            startDateTime
            endDateTime
            maxUsesPerUser
            discountType
            discountAmount
            discountUnit
            conditionAmount
            conditionUnit
            createdAt
            _version
            _deleted
            _lastChangedAt
            updatedAt
        }
        nextToken
        startedAt
    }
`;

export const shopReviewReplyQuery = `{
    id
    reviewID
    description
    createdAt
    createdBy
    updatedAt
    _version
    _deleted
    _lastChangedAt
}`;

export const listShops = /* GraphQL */ `
  query ListShops(
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShops(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ${shopItemQuery}
      }
      nextToken
      startedAt
    }
  }
`;
export const getShop = /* GraphQL */ `
  query GetShop($id: ID!) {
    getShop(id: $id) {
      ${shopItemQuery}
    }
  }
`;
export const getShopByShopID = /* GraphQL */ `
  query GetShopByShopID(
    $shopID: String
    $sortDirection: ModelSortDirection
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getShopByShopID(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${shopItemQuery}
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getShopByPostalCode = /* GraphQL */ `
  query GetShopByPostalCode($postalCode: String!, $range: String) {
    getShopByPostalCode(postalCode: $postalCode, range: $range) {
      ${shopItemQuery}
    }
  }
`;
export const searchShops = /* GraphQL */ `
  query SearchShops(
    $searchString: String
    $status: ShopStatus
    $createdBy: String
    $createdDateRange: DateRange
    $nextToken: String
  ) {
    searchShops(
      searchString: $searchString
      status: $status
      createdBy: $createdBy
      createdDateRange: $createdDateRange
      nextToken: $nextToken
    ) {
      items {
        ${shopItemQuery}
      }
      nextToken
      __typename
    }
  }
`;

export const getCustomerReviewByShop = /* GraphQL */ `
  query GetCustomerReviewByShop(
    $shopID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCustomerReviewByShop(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        userID
        orderID
        replyID
        title
        ratings {
          title
          rating
        }
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          picture
          username
          userId
          firstName
          lastName
          title
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        reply {
          id
          reviewID
          description
          createdAt
          createdBy
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
