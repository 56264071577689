import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  TextField,
  Checkbox,
  Typography,
  InputAdornment,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import WorkingHours from '../../Shops/components/ShopDetails/WorkingHours';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { languages, paymentMethods } from 'common/constants';
import ActionButtons from './ActionButtons';
import { useAmplifyAuth } from 'context';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles(() => ({
  root: {},
  languageHeader: {
    paddingBottom: 0
  },
  tooltip: {
    fontSize: '14px'
  }
}));

const CollectionAndDeliveryHours = (props) => {
  const {
    validation,
    className,
    google,
    value,
    index,
    shopId,
    pageType,
    formErrors,
    onCancel,
    handleSubmit,
    handleChange,
    disabled,
    handleNumberChange,
    enablePriceText,
    setEnablePriceText,
    selectedShop,
    setDisabled,
    setSelectedShop,
    isSelfOnboarding = false,
    ...rest
  } = props;
  const classes = useStyles();
  const {
    state: { isAdmin = false, isSales = false }
  } = useAmplifyAuth();
  const workingDays = selectedShop?.workHrs?.map((day) => day.openDay).sort();
  const isCollectionAndDeliveryHoursAvailable =
    selectedShop &&
    (selectedShop['collectionHrs']?.length > 0 ||
      selectedShop['deliveryHrs']?.length > 0);

  const minPriceFreeCheckboxHandler = () => {
    setEnablePriceText(!enablePriceText);
    setSelectedShop({
      ...selectedShop,
      minPriceForFree: 0,
      distanceRangeForFree: 0
    });
    if (!isAdmin && !isSales) setDisabled(false);
  };

  return (
    <>
      <WorkingHours
        sorryMsg="No collection"
        subheader="The information about the collection timings"
        title="Collection Hours"
        type="collectionHrs"
        hours={(selectedShop && selectedShop['collectionHrs']) || []}
        selectedShop={selectedShop}
        setSelectedShop={setSelectedShop}
        setDisabled={setDisabled}
        workingDays={workingDays}
      />
      <Divider />
      <WorkingHours
        sorryMsg="No delivery"
        subheader="The information about the delivery timings"
        title="Delivery Hours"
        type="deliveryHrs"
        hours={(selectedShop && selectedShop['deliveryHrs']) || []}
        selectedShop={selectedShop}
        setSelectedShop={setSelectedShop}
        setDisabled={setDisabled}
        workingDays={workingDays}
      />
      {!isSelfOnboarding && (
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid item xs={12} md={8}>
                <TextField
                  margin="dense"
                  label="Standard collection or delivery fee"
                  placeholder="Enter fee"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    )
                  }}
                  name="standardDeliveryFee"
                  onChange={handleNumberChange}
                  required
                  disabled={!isCollectionAndDeliveryHoursAvailable}
                  value={selectedShop?.standardDeliveryFee || ''}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item md={12} xs={12} container alignItems="center">
              <Tooltip
                title="You now have the flexibility to customize the collection and delivery range based on your preferences. If you wish to cover the entire UK, simply set this range to 0"
                placement="top"
                arrow
                classes={{ tooltip: classes.tooltip }}>
                <InfoIcon fontSize="small" color="primary" />
              </Tooltip>
              <Typography
                component="span"
                variant="body1"
                style={{ margin: '0 1rem' }}>
                Collection and delivery distance range upto
              </Typography>
              <TextField
                style={{ width: 100 }}
                size="small"
                disabled={!isCollectionAndDeliveryHoursAvailable}
                error={
                  isCollectionAndDeliveryHoursAvailable &&
                  !selectedShop.distanceRange
                }
                margin="dense"
                name="distanceRange"
                onChange={handleNumberChange}
                required
                value={selectedShop?.distanceRange || ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">miles</InputAdornment>
                  )
                }}
                variant="outlined"
              />
            </Grid>
            {/* <Grid container item xs={12} md={10} alignItems="center">
            <Typography variant="body1">
              Location names or Postcodes to where collection and delivery is
              available (enter a comma separated list of places):
            </Typography>
            <TextField
              size="small"
              value={selectedShop?.deliveryLocations || ''}
              disabled={!isCollectionAndDeliveryHoursAvailable}
              placeholder="eg: Canning Town, Bow, Eastvillage, Forest Gate, Silver Town, Manor Park, Hackney Wick."
              name="deliveryLocations"
              onChange={handleChange}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid> */}
            <Grid item md={12} xs={12} container alignItems="center">
              <Checkbox
                checked={enablePriceText}
                checkedIcon={checkedIcon}
                disabled={!isCollectionAndDeliveryHoursAvailable}
                icon={icon}
                onChange={minPriceFreeCheckboxHandler}
                style={{
                  marginRight: 0,
                  marginLeft: 0,
                  padding: 0,
                  verticalAlign: 'middle'
                }}
              />
              <Typography
                component="span"
                style={{ marginRight: '1rem', marginLeft: '0.5rem' }}
                variant="body1">
                Free collection and delivery for order amount starting from
              </Typography>
              <TextField
                disabled={
                  !(enablePriceText && isCollectionAndDeliveryHoursAvailable)
                }
                margin="dense"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">£</InputAdornment>
                  )
                }}
                name="minPriceForFree"
                onChange={handleNumberChange}
                style={{ width: 80 }}
                required
                value={selectedShop?.minPriceForFree || ''}
                variant="outlined"
                size="small"
              />
            </Grid>
            {enablePriceText ? (
              <Grid item md={12} xs={12} container alignItems="center">
                <Tooltip
                  title="You have the freedom to define the range within which collection and delivery services are offered without incurring any additional costs"
                  placement="top"
                  arrow
                  classes={{ tooltip: classes.tooltip }}>
                  <InfoIcon fontSize="small" color="primary" />
                </Tooltip>
                <Typography
                  component="span"
                  variant="body1"
                  style={{ margin: '0 1rem' }}>
                  Free Collection and delivery distance range upto
                </Typography>
                <TextField
                  style={{ width: 100 }}
                  size="small"
                  disabled={!isCollectionAndDeliveryHoursAvailable}
                  margin="dense"
                  name="distanceRangeForFree"
                  onChange={handleNumberChange}
                  required
                  value={selectedShop?.distanceRangeForFree || ''}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">miles</InputAdornment>
                    )
                  }}
                  variant="outlined"
                />
              </Grid>
            ) : null}
            <Grid item xs={12} container alignItems="center">
              <FormControlLabel
                control={
                  <Switch
                    checked={selectedShop?.enableAsapDelivery || false}
                    onChange={handleChange}
                    name="enableAsapDelivery"
                    color="primary"
                  />
                }
                style={{ margin: 0 }}
                label="Enable/Disable ASAP option for same-day collection and delivery."
                labelPlacement="start"
              />
            </Grid>
            {selectedShop?.enableAsapDelivery && (
              <Grid item xs={12}>
                <Grid item xs={12} md={8}>
                  <TextField
                    margin="dense"
                    label="ASAP fee"
                    placeholder="Enter fee"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      )
                    }}
                    name="AsapDeliveryFee"
                    onChange={handleNumberChange}
                    required
                    value={selectedShop?.AsapDeliveryFee || ''}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </CardContent>
      )}
      {!isSelfOnboarding && (
        <>
          <CardHeader
            className={classes.languageHeader}
            subheader="Choose the language which the shop person speak?"
            title="Language"
          />
          <CardContent>
            <Grid container>
              <Grid item xs={12} md={8} lg={6}>
                <Autocomplete
                  getOptionLabel={(option) => option}
                  multiple
                  name="language"
                  onChange={(e, newValue) =>
                    handleChange({ target: { name: 'language' } }, newValue)
                  }
                  options={languages}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="language"
                      variant="outlined"
                    />
                  )}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        checked={selected}
                        checkedIcon={checkedIcon}
                        icon={icon}
                        style={{ marginRight: 8 }}
                      />
                      {option}
                    </React.Fragment>
                  )}
                  value={(selectedShop && selectedShop.language) || []}
                  disableClearable
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardHeader
            className={classes.languageHeader}
            title="Payment Methods"
            subheader="Choose the payment methods you want to enable"
          />
          <CardContent>
            <Grid container>
              <Grid item xs={12} md={8} lg={6}>
                <Autocomplete
                  multiple
                  name="paymentMethods"
                  getOptionLabel={(option) =>
                    option.label ||
                    paymentMethods.find((method) => method.value === option)
                      ?.label ||
                    option
                  }
                  getOptionSelected={(option, value) => option.value === value}
                  onChange={(_, newValue) => {
                    handleChange(
                      { target: { name: 'paymentMethods' } },
                      newValue.map((item) => item.value || item)
                    );
                  }}
                  options={paymentMethods}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="payment methods"
                      variant="outlined"
                    />
                  )}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        checked={selected}
                        checkedIcon={checkedIcon}
                        icon={icon}
                        style={{ marginRight: 8 }}
                      />
                      {option.label}
                    </React.Fragment>
                  )}
                  value={(selectedShop && selectedShop.paymentMethods) || []}
                  disableClearable
                />
              </Grid>
            </Grid>
          </CardContent>
        </>
      )}
      {!isAdmin && !isSales && !isSelfOnboarding ? (
        <CardActions>
          <ActionButtons
            handleSubmit={handleSubmit}
            handleCancel={onCancel}
            disabled={disabled}
          />
        </CardActions>
      ) : null}
    </>
  );
};

export default CollectionAndDeliveryHours;
