import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAuctions } from 'context/auctions/auctionsContext';
import { useServices } from 'views/Services/serviceContext';
import { useAmplifyAuth } from 'context';
import { showPrice } from 'common/utilFunctions';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    marginBottom: '0.5rem'
  },
  dialogTitle: {
    backgroundColor: '#1a237e',
    marginBottom: '1rem'
  },
  whiteTxt: { color: '#fff' },
  bidsContainer: {
    gap: '1rem'
  },
  bidContainer: {
    gap: '0.325rem'
  },
  bidItemsContainer: {
    gap: '0.25rem'
  },
  contentContainer: {
    padding: '0 1.5rem 0.5rem'
  }
}));

const MyBidsModal = ({
  open = false,
  handleClose = () => {},
  auctionID = ''
}) => {
  const classes = useStyles();
  const {
    state: { user }
  } = useAmplifyAuth();
  const { auctions = [] } = useAuctions();
  const { dispatch: dispatchServices } = useServices();
  const [auction, setAuction] = useState(null);
  const myBids = auction?.bids?.items?.filter(
    (_bid) => _bid.shopID === user.shopID || _bid.shopID === user.shopId
  );

  useEffect(() => {
    dispatchServices({ type: 'getService' });
  }, []);

  useEffect(() => {
    setAuction(auctions.find((item) => item?.id === auctionID));
  }, [auctionID]);

  return (
    <Dialog
      aria-labelledby="my-bids-dialog-title"
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      open={open}>
      <DialogTitle
        id="my-bids-dialog-title"
        disableTypography
        className={classes.dialogTitle}>
        <Typography variant="h5" className={classes.whiteTxt}>
          Auction #{auction?.auctionID || ''}
        </Typography>
        <Typography variant="body1" className={classes.whiteTxt}>
          Postal code: {auction?.postcode || ''}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <Grid container className={classes.headerContainer}>
          <Typography variant="h5">Bids</Typography>
        </Grid>
        <Grid container className={classes.bidsContainer}>
          {myBids?.length > 0 ? (
            myBids.map((_bid, index) => (
              <Grid
                container
                key={`my-bids-items-${index}`}
                className={classes.bidContainer}>
                <Grid container justify="space-between">
                  <Typography variant="subtitle1">
                    {index + 1}. Bid placed on{' '}
                    {new Date(_bid.createdAt).toLocaleDateString()} at{' '}
                    {new Date(_bid.createdAt).toLocaleTimeString()}
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>{` ${showPrice(_bid.totalPrice)}`}</strong>
                  </Typography>
                </Grid>
                <Grid container className={classes.bidItemsContainer}>
                  {_bid.items.items.map((_bidItem, _index) => {
                    const auctionItem = auction.items.items.find(
                      (_auctionItem) =>
                        _auctionItem.id === _bidItem.auctionItemID
                    );
                    return (
                      <Grid container key={`bid-${_bid.id}-items-${_index}`}>
                        <Grid container item xs={10} alignItems="center">
                          <Typography variant="body2">
                            {auctionItem?.name || ''}&nbsp; x{' '}
                            {auctionItem?.quantity || ''} items
                          </Typography>
                        </Grid>
                        <Grid container item xs={2} justify="flex-end">
                          <Typography variant="h6">
                            {showPrice(_bidItem.price)}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography>Shipping Charges</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {showPrice(_bid.shippingCharges || 0)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))
          ) : (
            <Typography variant="body2">No bids placed yet</Typography>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify="center">
          <Button
            onClick={handleClose}
            size="small"
            variant="contained"
            color="primary">
            Close
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default MyBidsModal;
