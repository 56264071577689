export const auctionsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'updateData': {
      return payload || [];
    }
    case 'addData': {
      return [...payload, ...state];
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};
