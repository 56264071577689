import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
  Button
} from '@material-ui/core';
import { getFileUploadKey, getStorageUrl } from 'common/utilFunctions';
import { useAmplifyAuth } from '../../../../context';
import { Storage, graphqlOperation, API } from 'aws-amplify';
import { updateUser } from '../../../../graphql/mutations';
import { useSnackbar } from 'notistack';
import { useLoader } from 'layouts/loaderContext';

const useStyles = makeStyles(theme => ({
  root: {},
  details: {
    display: 'flex'
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  }
}));

const AccountProfile = props => {
  const { className, ...rest } = props;
  const { setLoading } = useLoader();
  const classes = useStyles();
  const {
    state: { user }
  } = useAmplifyAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [values, setValues] = useState({});

  useEffect(() => {
    user &&
      getStorageUrl(user.picture).then(picture =>
        setValues({ picture, _version: user._version })
      );
  }, [user]);

  const onCancel = async () => {
    try {
      const input = {
        id: user.id,
        _version: values._version,
        picture: ''
      };
      API.graphql(graphqlOperation(updateUser, { input })).then(data => {
        setValues({
          picture: data.data.updateUser.picture,
          _version: data.data.updateUser._version
        });
        enqueueSnackbar('Profile picture removed', {
          variant: 'success',
          preventDuplicate: true
        });
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const onSubmit = async e => {
    const file = e.target.files[0];
    const { type: mimeType } = file;
    const key = getFileUploadKey(file, 'images');
    const input = {
      id: user.id,
      _version: values._version,
      picture: key
    };
    try {
      setLoading(true);
      await Storage.put(key, file, {
        contentType: mimeType,
        level: 'public'
      });
      API.graphql(graphqlOperation(updateUser, { input })).then(async data => {
        const picture = await getStorageUrl(data.data.updateUser.picture);
        setValues({
          picture,
          _version: data.data.updateUser._version
        });
        enqueueSnackbar('Profile picture updated', {
          variant: 'success',
          preventDuplicate: true
        });
      });
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography gutterBottom variant="h3">
              Username: {user.username}
            </Typography>
            <Typography gutterBottom variant="h2">
              {user.firstName} {user.lastName}
            </Typography>
            <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body1">
              {user.city}, {user.country}
            </Typography>
            <Typography
              className={classes.dateText}
              color="textSecondary"
              variant="body1">
              {moment().format('hh:mm A')} ({user.timezone || 'GTM-0'})
            </Typography>
          </div>
          <Avatar className={classes.avatar} src={values.picture || ''} />
        </div>
      </CardContent>
      <Divider />
      <CardActions>
        <input
          accept="image/*"
          id="admin-profile-pic-upload-btn"
          name="image"
          onChange={onSubmit}
          type="file"
          hidden
        />
        <label htmlFor="admin-profile-pic-upload-btn">
          <Button
            color="primary"
            variant="text"
            className={classes.uploadButton}
            component="span">
            Upload picture
          </Button>
        </label>
        <Button variant="text" onClick={onCancel} disabled={!values.picture}>
          Remove picture
        </Button>
      </CardActions>
    </Card>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string
};

export default AccountProfile;
