import React from 'react';
import { Typography, Link, Grid } from '@material-ui/core';

const Copyright = () => {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{
        backgroundColor: '#143A69',
        padding: '1.5rem 0'
      }}>
      <Typography
        variant="body1"
        style={{
          color: '#C4C4C4'
        }}>
        &copy;{' '}
        <Link
          component="a"
          href="/"
          target="_blank"
          style={{
            color: '#C4C4C4'
          }}>
          Launder-it.co.uk . 2020
        </Link>
      </Typography>
    </Grid>
  );
};

export default Copyright;
