import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import EntryFormTabPanel from './EntryFormTabPanel';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  }
}));

// temporarily hiding postal code search
const ShopDetails = () => {
  // let { actionType } = useParams();
  // const [showShopSearchPanel, setShowShopSearchPanel] = useState(false);
  // const isAddNewPage = actionType === 'add';

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction="row">
        {/* {isAddNewPage ? (
          <Grid item xs={6}>
            {showShopSearchPanel ? (
              <SearchShopTabPanel />
            ) : (
              <Grid
                container
                direction="column"
                style={{ gap: '2rem', paddingTop: '2rem' }}>
                <Typography variant="subtitle1">
                  <strong>
                    Do you have the details of the shop you want to add?
                  </strong>
                  <br />
                  <br />
                  If yes then please add your shop manually by entering the
                  details.
                  <br />
                  Otherwise
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => setShowShopSearchPanel(true)}>
                    click here
                  </Button>
                  to search shop by postal code.
                </Typography>
              </Grid>
            )}
          </Grid>
        ) : null} */}
        <Grid item xs={12}>
          <EntryFormTabPanel />
        </Grid>
      </Grid>
    </div>
  );
};

ShopDetails.propTypes = {
  className: PropTypes.string
};

export default ShopDetails;
